import { mapActions, mapGetters, mapMutations } from "vuex"
import startMomentHelper from '../modules/startMomentHelper'
import courseClient from '../api/courseClient'
import SearchQueryBuilder from '../api/OData/searchQueryBuilder'
import ODataQueryFilter from '../api/OData/odataQueryFilter'
import ODataFilterOperations from '../api/OData/odataFilterOperations'
import DateUtils from '../utils/DateUtils'

const mixin = {
    computed: {
        ...mapGetters('startMomentsModule', [
            'startMoments', 
            'assortmentId', 
            'courseId',
            'totalCount',
            'pageNumber',
            'facets',
            'orders',
            'top',
            'showNoStartMomentsMessage'
        ]),
        ...mapGetters({
            isFetchingStartmoments: 'startMomentsModule/isLoading'
        }),
        ...mapGetters('accountModule', ['finalStartMomentDate'])
    },
    beforeDestroy() {
        this.RESET_STATE()
    },
    methods: {
        ...mapActions('startMomentsModule', [ 
            'initializeStartMomentsAndOrders',
            'setFacetsAndSearchStartMoments',
            'goToPage'
        ]),
        ...mapMutations('startMomentsModule', ['SET_IS_SEARCHING_START_MOMENTS', 'RESET_STATE']),
        async getChosenStartMoments(firstStartMomentId, secondStartMomentId, includeFinalStartDateFilter = false) {
            if(!firstStartMomentId && !secondStartMomentId) return []

            try {
                this.SET_IS_SEARCHING_START_MOMENTS(true)

                const dataQuery = new SearchQueryBuilder()
                    .setTop(firstStartMomentId && secondStartMomentId ? 2 : 1)
                    .addFilter(new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS,firstStartMomentId))
         
                if(secondStartMomentId) {
                    dataQuery
                        .or()
                        .addFilter( new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS, secondStartMomentId))
                }

                dataQuery
                    .and()
                    .addFilter(new ODataQueryFilter('productReference', ODataFilterOperations.EQUALS, `course:${this.courseId}`))
                    .addFilter(new ODataQueryFilter('assortmentId', ODataFilterOperations.EQUALS, this.assortmentId))
                    .addDynamicFilter(`fullDate ge ${DateUtils.parseToISOString(DateUtils.today)}`)

                const { results } = await courseClient.searchStartMoments(dataQuery.build())
                  
                if(results?.length) {
                    let mappedStartMoments = startMomentHelper.mapStartMoments(results, this.limitIncompanyEnrollmentsAndIsIncompany, this.productType)
                    const selectedStartMomentIds = []
                    if (firstStartMomentId) selectedStartMomentIds.push(firstStartMomentId)
                    if (secondStartMomentId) selectedStartMomentIds.push(secondStartMomentId)

                    // Put the first and second startmoments in the right order
                    mappedStartMoments = selectedStartMomentIds.map(smId => mappedStartMoments.find(sm => sm.startMomentId === smId))
                    if (this.finalStartMomentDate && includeFinalStartDateFilter) {
                        mappedStartMoments = mappedStartMoments.filter(sm => sm.fullDate < this.finalStartMomentDate)
                    }
                    const startMomentsWithOrders = startMomentHelper.addOrderTotalsToStartMoments(mappedStartMoments, this.orders)
                    
                    return startMomentsWithOrders
                }

                return []
            } catch (error) {
                throw new Error(error)
            } finally {
                this.SET_IS_SEARCHING_START_MOMENTS(false)
            }
        }
    }
}

export default mixin
<template>
  <div class="c-enrollment-step">
    <h4 class="u-textcolor-primary">Leidinggevende kiezen</h4>
    <p>Voor deze ontwikkelactiviteit is goedkeuring van een leidinggevende nodig. Kies hier een leidinggevende die wordt genotificeerd over uw inschrijving.</p>

    <div class="row mb-1">
      <div class="col-12 col-md-7">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isFetchingTeamLeaders"
            variant="small"
            :overlay="!selectFirstApproverWithSearchInput ? 'white' : null"
            color="primary"
          />
        </transition>
        <div v-if="selectFirstApproverWithSearchInput">
          <recess-autocomplete
            :value="selectedTeamLeaderEmail"
            data-test="team-leader-input"
            label-text="Leidinggevende"
            placeholder="Naam of e-mail"
            item-key="userId"
            item-text="emailAddress"
            item-sub-text="fullName"
            :error-message="validationError"
            :on-query="queryTeamLeader"
            @input="val => setTeamLeader(val)"
          />
        </div>
        <recess-select
          v-else
          v-model="formData.assignedApproverId"
          data-test="team-leader-input"
          label-text="Leidinggevende"
          :default-value="formData.assignedApproverId"
          :options="teamLeaderOptions"
        />
      </div>
      <div
        v-if="errorMessage"
        class="col-12"
      >
        <span data-test="error-message" class="o-error-text mt-3">{{ errorMessage }}</span>
      </div>
    </div>

    <recess-divider />

    <enrollment-step-buttons 
      v-on="{ ...$listeners, 'next-step': confirmStep }" 
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'

export default {
    components: {
        LoaderComponent,
        EnrollmentStepButtons
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            errorMessage: ''
        }
    },
    validations() {
        return {
            formData: {
                assignedApproverId: { required }
            },
            selectedTeamLeaderEmail: { 
                required: requiredIf(() => this.selectFirstApproverWithSearchInput),
                isMatchingTeamleaderEmail() { 
                    if(this.selectFirstApproverWithSearchInput) {
                        return this.isMatchingTeamleaderEmail
                    }
                    return true
                }
            }
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['teamLeaders', 'formData']),
        ...mapGetters('accountModule', ["selectFirstApproverWithSearchInput"]),
        ...mapGetters('enrollmentModule',['potentialTeamLeaders', 'isFetchingTeamLeaders', 'selectedTeamLeaderEmail']),
        validationError() {
            if(this.selectedTeamLeaderEmail?.length >= 3 && !this.isMatchingTeamleaderEmail && !this.isFetchingTeamLeaders  && !this.potentialTeamLeaders.length) {
                return 'Er zijn geen resultaten gevonden'
            }

            if(!this.$v.selectedTeamLeaderEmail.required && this.$v.selectedTeamLeaderEmail.$error) {
                return 'Leidinggevende is verplicht'
            }

            if(this.selectedTeamLeaderEmail?.length >= 3 && !this.isFetchingTeamLeaders && !this.isMatchingTeamleaderEmail) {
                return 'Kies een leidinggevende uit de lijst' 
            }
          
            return ''
        },
        isMatchingTeamleaderEmail() {
            return this.potentialTeamLeaders?.some(x => x.emailAddress?.toLowerCase() === this.selectedTeamLeaderEmail?.toLowerCase())
        },
        teamLeaderOptions() {
            if(this.selectFirstApproverWithSearchInput) return null

            if (!this.teamLeaders || this.teamLeaders.length === 0) {
                this.setErrorMessage(
                    'Er konden geen leidinggevenden worden opgehaald. probeer het later opnieuw.'
                )
                return null
            }

            return this.teamLeaders.map(teamLeader => {
                let displayText = `${teamLeader.firstName} `
                if (teamLeader.middleName) displayText += `${teamLeader.middleName} `
                displayText += `${teamLeader.lastName} (${teamLeader.emailAddress})`
                return {
                    value: teamLeader.userId,
                    displayText
                }
            })
        }
    },
    async created() {
        if(this.selectFirstApproverWithSearchInput) {
            await this.setSearchInputDefaultValue()
        } else {
            await this.setDefaultTeamLeader()
        }
        this.$gtag.pageview({ page_path: '/inschrijven-student-leidinggevende' })
    },
    methods: {
        ...mapActions('enrollmentModule', ['searchTeamleader', 'setSearchInputDefaultValue', 'setDefaultTeamLeader']),
        ...mapMutations('enrollmentModule', ['setSelectedTeamLeaderEmail', 'setAssignedApproverId']),
        confirmStep() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            this.$emit('next-step')
        },
        setTeamLeader(teamLeaderEmail) {
            const teamleader = this.potentialTeamLeaders?.find(u => u.emailAddress?.toLowerCase() === teamLeaderEmail?.toLowerCase())
            if(teamleader){
                this.setAssignedApproverId(teamleader.userId)
                this.setSelectedTeamLeaderEmail(teamleader.emailAddress)
            }
        },  
        setErrorMessage(err) {
            if (err) this.errorMessage = err
        },
        async queryTeamLeader(query) {
            if (!query) return []

            await this.searchTeamleader(query)

            const items = this.potentialTeamLeaders.filter(
                item => item.emailAddress.toLowerCase().indexOf(query.toLowerCase()) > -1
                || item.firstName.toLowerCase().indexOf(query.toLowerCase()) > -1
                || item.lastName.toLowerCase().indexOf(query.toLowerCase()) > -1
            )

            return items
        }
    }
}
</script>

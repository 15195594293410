<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div v-if="course">
    <!-- Learning method -->
    <template
      v-if="course.learningMethod && 
        ((course.learningMethod.typeDisplayValue || course.learningMethod.type) || 
          (course.learningMethod.formatDisplayValue || course.learningMethod.format))"
    >
      <span class="font-weight-bold">Leermethode:</span>
      <p>
        {{ learningMethodFormatText }} {{ learningMethodTypeText }}
      </p>
    </template>

    <!-- Course Description -->
    <template v-if="descriptionContent && descriptionContent.length">
      <h3>Over de ontwikkelactiviteit</h3>
      <div
        v-for="(content, index) in descriptionContent"
        :key="`descriptionContent${index}`"
        class="c-course-content__wrapper"
      >
        <div
          v-if="content.descriptionHtml || content.description"
          class="c-course-content__block"
          v-html="content.descriptionHtml || content.description"
        ></div>
      </div>
    </template>

    <!-- Content blocks -->
    <template v-if="programContent && programContent.length">
      <div
        v-for="(content, index) in programContent"
        :key="`programContent${index}`"
        class="c-course-content__wrapper"
      >
        <h3 v-if="content.heading">{{ content.heading }}</h3>
        <div
          v-if="content.bodyHtml || content.body"
          class="c-course-content__block"
          v-html="content.bodyHtml || content.body"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('coursePageModule', ['course']),
        ...mapGetters('coursePageModule', ['programContent', 'descriptionContent']),
        learningMethodFormatText() {
            return this.course?.learningMethod?.formatDisplayValue || this.course?.learningMethod?.format
        },
        learningMethodTypeText() {
            if(!this.course?.learningMethod?.typeDisplayValue && !this.course?.learningMethod?.type) return null
            
            // if there is a format, display the type in brackets, otherwise, lose the brackets
            return this.course?.learningMethod?.formatDisplayValue || this.course?.learningMethod?.format ? 
                `(${this.course?.learningMethod?.typeDisplayValue || this.course?.learningMethod?.type})` : 
                this.course?.learningMethod?.typeDisplayValue || this.course?.learningMethod?.type
        }
    }
}
</script>
const projectRequestTypes = {
    LicenseFee: 1,
    DevelopmentCost: 2,
    IncompanyWithStartmoments: 3,
    IncompanyWithoutStartmoments: 4
}

export const projectRequestTypeValues = {
    [projectRequestTypes.LicenseFee]: { value: projectRequestTypes.LicenseFee, displayText: 'Licentiekosten' },
    [projectRequestTypes.DevelopmentCost]: { value: projectRequestTypes.DevelopmentCost, displayText: 'Ontwikkelkosten' },
    [projectRequestTypes.IncompanyWithStartmoments]: { value: projectRequestTypes.IncompanyWithStartmoments, displayText: 'Incompany met startmomenten' },
    [projectRequestTypes.IncompanyWithoutStartmoments]: { value: projectRequestTypes.IncompanyWithoutStartmoments, displayText: 'Incompany zonder startmomenten' }
}

export default projectRequestTypes
export function getSessionTokenKey() {
    const keys = Object.keys(sessionStorage)
    return keys.find(e => e.indexOf('oidc.user:') === 0)
}

export function getSessionToken() {
    const key = getSessionTokenKey()
    if (!key) throw new Error("session token not found")

    return JSON.parse(sessionStorage[key])
}

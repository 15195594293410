const projectCostCenterType = {
    Approver: 1,
    Central: 2
}

export const projectCostCenterTypeValues = {
    [projectCostCenterType.Approver]: { value: projectCostCenterType.Approver, displayText: 'Beoordelaar' },
    [projectCostCenterType.Central]: { value: projectCostCenterType.Central, displayText: 'Centraal' }
}

export default projectCostCenterType
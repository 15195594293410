<template v-if="carouselList && carouselList.length">
  <hooper
    data-test="attachments-preview-carousel"
    :settings="getHooperSettings"
    :height="250"
  >
    <slide
      v-for="(carouselItem, index) in carouselList"
      :key="index"
      :index="index"
    >

      <carousel-item>
        <template #body>
          <div class="c-thumb">
            <a 
              class="d-flex justify-content-center c-thumb__item"
              :href="carouselItem.url" 
              target="_blank"
            >
              <carousel-image   
                :is-rounded="false" 
                :image="carouselItem.imageUrl"
                :file-name="getFileName(carouselItem.description)"
                :is-small="showSmallImage"
                :is-thumbnail="carouselItem.isThumbnail"
              />
            </a>
          </div>
        </template>
      </carousel-item>

    </slide>
    <hooper-navigation
      v-if="showNavigations"
      slot="hooper-addons"
      data-test="navigation"
    >
      <template #hooper-prev>
        <recess-icon
          icon="arrow-left"
          color="secondary"
          :height="25"
          :width="25"
        />
      </template>
      <template #hooper-next>
        <recess-icon
          icon="arrow-right"
          color="secondary"
          :height="25"
          :width="25"
        />
      </template>
    </hooper-navigation>

  </hooper>
</template>

<script>
import { computed } from 'vue'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'
import useMedia from '../../../composables/useMediaQueries'
import mediaQueries from '../../../constants/mediaQueries'
import CarouselItem from "../../atoms/CarouselItem/CarouselItem.vue"
import CarouselImage from '../../atoms/CarouselImage/CarouselImage.vue'
import { hooperSettings } from '../../../constants/carouselSettings'
import carouselHelper from '../../../modules/carouselHelper'
import StringUtils from '../../../utils/StringUtils'

export default {
    components: {
        Hooper,
        Slide,
        HooperNavigation,
        CarouselItem,
        CarouselImage
    },
    props: {
        attachments: {
            type: Array,
            default: () => []
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        videoThumbnails:{
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const isMobile = useMedia(mediaQueries.SMALL)

        const carouselList = computed(() => {
            const videoList1 = props.videoThumbnails?.map(videoLink => { return { isThumbnail : true, imageUrl : videoLink.thumbnailUrl, url : videoLink.url, description : '' } })
            const attachmentList1 = props.attachments?.map(attachment => { return { isThumbnail : false, imageUrl : attachment.url, url : attachment.url, description : attachment.description } })

            const list = []
            if(videoList1?.length > 0) list.push(...videoList1)
            if(attachmentList1?.length > 0) list.push(...attachmentList1)

            return list
        })

        const showSmallImage = computed(() => {
            return props.isSmall || isMobile.value
        })

        const showNavigations = computed(() => {
            return showSmallImage.value ? 
                carouselHelper.showNavigationsSmall(carouselList.value) : 
                carouselHelper.showNavigations(carouselList.value)
        })

        const getHooperSettings = computed(() => {
            if(showSmallImage.value) {
                return {
                    trimWhiteSpace: true,
                    breakpoints: {
                        1120: {
                            itemsToShow: 4,
                            itemsToSlide: 4
                        },
                        850: {
                            itemsToShow: 3,
                            itemsToSlide: 3
                        },
                        600: {
                            itemsToShow: 2,
                            itemsToSlide: 2
                        },
                        0: {
                            itemsToShow: 1,
                            itemsToSlide: 1
                        }
                    }
                }
            }

            return hooperSettings
        })

        const getFileName = fileName => {
            return StringUtils.removeDateTime(fileName)
        }
        
        return {
            showNavigations,
            carouselList,
            getHooperSettings,
            showSmallImage,
            getFileName
        }
    }
}
</script>
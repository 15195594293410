import SearchQuery from './searchQuery'
import BaseDataQueryBuilder from './baseQueryBuilder'

export default class SearchQueryBuilder extends BaseDataQueryBuilder {
    constructor() {
        super(new SearchQuery())
    }

    setSkip(skip) {
        this.DataQuery.setSkip(skip)
        return this
    }

    setTop(top) {
        this.DataQuery.setTop(top)
        return this
    }

    setSelects(fields) {
        this.DataQuery.setSelects(fields)
        return this
    }

    setFacets(facets) {
        this.DataQuery.setFacets(facets)
        return this
    }

    setOrderBy(orderBy) {
        this.DataQuery.setOrderBy(orderBy)
        return this
    }
}

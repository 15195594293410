<template>
  <recess-modal
    modal-size="small"
    :show-modal="showEditUserConfirmation"
    data-test="edit-user-confirmation-modal"
    @close="$emit('close')"
  >
    <div
      slot="modal-body"
      class="d-flex justify-content-center"
    >
      <p>Uw wijzigingen zijn doorgevoerd.</p>
    </div>
    <div
      slot="modal-footer"
      class="d-flex justify-content-center"
    >
      <recess-button
        class="qa-close-modal-button"
        variant="primary"
        title="Sluit venster"
        data-test="edit-user-confirmation-modal-close-button"
        @click="$emit('close')"
      />
    </div>
  </recess-modal>
</template>

<script>
export default {
    props: {
        showEditUserConfirmation: {
            type: Boolean,
            default: false
        }
    }
}
</script>

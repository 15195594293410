import Vue, { provide } from 'vue'

import RecessUi from '@valtech/recess-ui'
import VueBus from 'vue-bus'
import Vuelidate from 'vuelidate'
import VueObserveVisibility from 'vue-observe-visibility'
import VueKindergarten from 'vue-kindergarten'
import VueGtag from 'vue-gtag'
import Donut from 'vue-css-donut-chart'
import VueStarRating from 'vue-star-rating'

import App from './App.vue'

import createRouter from './router'
import store from './store'

import 'vue-css-donut-chart/dist/vcdonut.css'
import { mapPerimeterChilds } from './utils/MapUtils'

export default function createApp() {
    // create store and router instances
    const router = createRouter()

    Vue.use(VueBus)
    Vue.use(Vuelidate)
    Vue.use(VueObserveVisibility)
    Vue.use(VueKindergarten, {
        child: globalStore => mapPerimeterChilds(globalStore)
    })
    Vue.use(RecessUi)
    Vue.use(Donut)

    // Initialize gtag.js using vue-gtag package
    // https://github.com/MatteoGabriele/vue-gtag
    Vue.use(
        VueGtag,
        {
            config: {
                id: 'UA-156841652-1',
                params: {
                    anonymize_ip: true
                }
            }
        },
        router
    )

    // @ts-ignore
    // Hack to force library to be compiled to ES5
    // eslint-disable-next-line no-undef
    Vue.component('StarRating', VueStarRating)

    const app = new Vue({
        router,
        // Setup an instance of the VUEX store to be able to inject it in a component that use composition API
        setup() {
            provide("store", store)
        },
        store,
        render: h => h(App)
    })

    return { app, router, store }
}

<template>
  <section class="c-selected-filters">
    <div class="c-selected-filters__content">
      <div class="c-selected-filters__title">
        <span class="c-selected-filters__label">Gekozen filters: {{ !isFacetsSelected ? 'Er zijn nog geen filters gekozen' : null }}</span>
      </div>
      <div v-if="isFacetsSelected" class="c-selected-filters__list">
        <div v-for="facetKey in Object.keys(selectedFacets)" :key="facetKey">
          <span 
            v-for="facetValue in selectedFacets[facetKey]" 
            :key="facetValue.key"  
            class="c-selected-filters__item" 
            @click="$emit('remove-facet', facetValue, facetKey)">
            {{ capitalize(facetValue.display) }}
          </span>
        </div>
      </div>
      <div v-if="isFacetsSelected" class="c-selected-filters__remove">
        <u class="c-selected-filters__label" @click="$emit('reset')">Alle filters hersellen</u>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue'
import capitalize from 'lodash/capitalize'

export default {
    props: {
        selectedFacets: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
        const isFacetsSelected = computed(() => Object.keys(props.selectedFacets)?.some(key => props.selectedFacets[key]?.length))
        
        return {
            isFacetsSelected,
            capitalize
        }
    }
}
</script>

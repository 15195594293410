<template>
    <ul v-if="limitOfVisiblePages > 1" ref="paginationElement" class="c-pagination">
        <!-- Go to first page button -->
        <li v-if="currentPage > 1" class="c-pagination__item c-pagination__item--custom">
            <a class="c-pagination__link" @click.prevent="goToPage(1)">|&lt;</a>
        </li>

        <!-- Go to previous page button -->
        <li v-if="currentPage > 1" class="c-pagination__item c-pagination__item--custom">
            <a class="c-pagination__link" @click.prevent="goToPage(currentPage - 1)">&lt;</a>
        </li>

        <!-- First ellipsis -->
        <li v-if="showFirstEllipsis" class="c-pagination__item c-pagination__item--ellipsis">
            <a
                class="c-pagination__link"
                data-test="first-ellipsis"
                @click.prevent="goToPage(currentPage)"
                >&#46;&#46;&#46;</a
            >
        </li>

        <!-- Pages-->
        <li
            v-for="(page, index) in pages"
            :key="index"
            :class="[page.className, 'c-pagination__item', getPageClass(page.number)]"
        >
            <a class="c-pagination__link" @click.prevent="goToPage(page.number)">{{
                page.number
            }}</a>
        </li>

        <!-- Last ellipsis -->
        <li v-if="showLastEllipsis" class="c-pagination__item c-pagination__item--ellipsis">
            <a class="c-pagination__link" @click.prevent="goToPage(currentPage)">&#46;&#46;&#46;</a>
        </li>

        <!-- Go to next page button -->
        <li v-if="currentPage < totalPages" class="c-pagination__item c-pagination__item--custom">
            <a class="c-pagination__link" @click.prevent="goToPage(currentPage + 1)">&gt;</a>
        </li>

        <!-- Go to last page button -->
        <li v-if="currentPage < totalPages" class="c-pagination__item c-pagination__item--custom">
            <a class="c-pagination__link" @click.prevent="goToPage(totalPages)">&gt;|</a>
        </li>
    </ul>
</template>

<script>
/* https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/mixins/pagination.js helped with the logic of this component! */

import visibilityClassesMixin from '../../../mixins/visibility-classes-mixin'
import mixinRemoveFromQuery from '../../../mixins/remove-from-querystring-mixin'

export default {
    mixins: [visibilityClassesMixin, mixinRemoveFromQuery],
    props: {
        totalPages: {
            type: Number,
            default: 1
        },
        currentPageNumber: {
            type: Number,
            default: 1
        },
        limitOfVisiblePagesNumber: {
            type: Number,
            default: 11
        },
        ellipsisThreshold: {
            // Threshold of totalVisiblePages for when we start/stop showing ellipsis.
            // For example, if we have 10 pages and a threshold of 3, the last ellipsis will stop showing when you reach page 7.
            type: Number,
            default: 3
        }
    },
    computed: {
        pages() {
            /* Here we will handle the showing/hiding of the ellipsis and our list of pages that are visible.
             * Depending on where the currentPage is in our list of pages (totalPages),
             * depends on what ellipsis we will show/hide.
             * Eg. We only want to show the last ellipsis when we are at the beginning or middle of the list of pages.
             * */

            /* Calculating where we are on the list of pages,
             * Then showing/hiding the ellipsis and pages accordingly.
             *  */
            let visiblePages = this.limitOfVisiblePages
            let startingNumber = 1

            if (this.totalPages > this.limitOfVisiblePages) {
                // No point manipulating the list if there are less pages than the limit.

                if (this.nearBeginningOfPageList) {
                    visiblePages = this.limitOfVisiblePages - 1
                } else if (this.nearEndOfPageList) {
                    visiblePages = this.limitOfVisiblePages - 1
                    startingNumber = this.totalPages - visiblePages + 1
                } else {
                    // Around the middle of our list of pages
                    visiblePages = this.limitOfVisiblePages - 2
                    startingNumber = this.currentPage - Math.floor(visiblePages / 2)
                }

                /* Checks */
                if (startingNumber > this.totalPages - visiblePages) {
                    startingNumber = this.totalPages - visiblePages + 1
                }
            }

            /* Checks */
            if (startingNumber < 1) {
                startingNumber = 1
            }

            /* Now we know how many pages to show and from what index to start from,
             * we can generate a new list of pages objects and return it!
             * We include className for later on when we deal with mobile classes.
             * */
            const pages = Array(visiblePages)
                .fill()
                .map((_, i) => {
                    return { number: startingNumber + i, className: null }
                })

            /* On mobile we want to show less pages */
            const index = this.currentPage - startingNumber
            const classes = this.getVisibleClasses(false, true, true)

            if (index === 0) {
                for (let i = 3; i < pages.length; i += 1) {
                    pages[i].className = classes
                }
            } else if (index === pages.length - 1) {
                // Keep rightmost 3 buttons visible
                for (let i = 0; i < pages.length - 3; i += 1) {
                    pages[i].className = classes
                }
            } else {
                // hide left button(s)
                for (let i = 0; i < index - 1; i += 1) {
                    pages[i].className = classes
                }
                // hide right button(s)
                for (let i = pages.length - 1; i > index + 1; i -= 1) {
                    pages[i].className = classes
                }
            }

            return pages
        },
        limitOfVisiblePages() {
            if (this.totalPages < this.limitOfVisiblePagesNumber) {
                return this.totalPages
            }
            return this.limitOfVisiblePagesNumber
        },
        nearBeginningOfPageList() {
            return (
                this.currentPage < this.limitOfVisiblePages - 1 &&
                this.limitOfVisiblePages > this.ellipsisThreshold
            )
        },
        nearEndOfPageList() {
            return (
                this.totalPages - this.currentPage + 2 < this.limitOfVisiblePages &&
                this.limitOfVisiblePages > this.ellipsisThreshold
            )
        },
        showFirstEllipsis() {
            if (this.totalPages > this.limitOfVisiblePages) {
                return !this.nearBeginningOfPageList
            }
            return false
        },
        showLastEllipsis() {
            if (this.totalPages > this.limitOfVisiblePages) {
                return !this.nearEndOfPageList
            }
            return false
        },
        currentPage() {
            // Page number cant be lower then one, so in that case return 1
            if (this.currentPageNumber < 1) {
                return 1
            }
            // Page number cant be higher then totalPages, so in that case return totalPages
            if (this.currentPageNumber > this.totalPages) {
                return this.totalPages
            }
            // Otherwise return the current page number (its fine)
            return this.currentPageNumber
        }
    },
    watch: {
        currentPageNumber() {
            // Scroll to the top of the table
            const parentElement = this.$refs.paginationElement?.parentElement
            const scrollTarget = parentElement?.querySelector('.c-recess-table') ?? parentElement

            const top = scrollTarget?.getBoundingClientRect()?.top

            if (top) {
                window.scrollTo({
                    top,
                    behavior: 'smooth'
                })
            }
        }
    },
    methods: {
        goToPage(pageNumber) {
            this.$emit('goToPage', pageNumber)
        },
        getPageClass(number) {
            // If the number is the current page number, return the active page class
            return number === this.currentPage ? 'c-pagination__item--active' : ''
        }
    }
}
</script>

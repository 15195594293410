<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div class="c-contact-info">
    <h4 class="c-contact-info__title">Contact</h4>
    <template v-if="$isAllowed('displayContactInformationForStudents')">
      <p>Wij zijn telefonisch bereikbaar tijdens kantooruren: maandag t/m vrijdag van 09:00 tot 17:00 uur.</p>
      <template v-if="accountContactInfo">
        <transition name="fade">
          <a
            v-if="accountContactInfo.phoneNumber"
            :href="`tel:${accountContactInfo.phoneNumber}`"
            data-test="phone-number-txt"
            class="c-contact-info__icon c-contact-info__icon--phone"
          >{{ accountContactInfo.phoneNumber }}</a>
        </transition>
        <transition name="fade">
          <p
            v-if="accountContactInfo.email"
            class="c-contact-info__email-wrapper"
          >
            U kunt ook een
            <a
              :href="`mailto:${accountContactInfo.email}`"
              class="c-contact-info__email"
              data-test="email-txt"
            >e-mail</a>
            sturen
          </p>
        </transition>
      </template>
    </template>
    <div
      v-else-if="$isAllowed('displayContactAlternativeText')"
      class="qa-contact-alternative-text"
      data-test="alternative-text"
      v-html="contactAlternativeText"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'

export default {
    perimeters: [featuresPerimeter],
    computed: {
        ...mapGetters('accountModule', ['accountContactInfo', 'contactAlternativeText'])
    }
}
</script>

import axios from 'axios'
import { computed, reactive, readonly, ref, watch } from 'vue'
import { scrollTo } from 'vue-scrollto'
import ODataFilterOperations from '../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../api/OData/odataQueryFilter'
import projectRequestClient from '../api/projectRequestClient'
import providerClient from '../api/providerClient'
import userClient from '../api/userClient'
import { PROJECTAPPROVER } from '../constants/roleIds'
import useStore from './useStore'
import useValidation from './useValidation'
import projectCostCenterType from '../constants/projectRequestCostCenterType'
import projectRequestTypes from '../constants/projectRequestTypes'
import DateUtils from '../utils/DateUtils'

export default function useCreateProjectRequest() {
    const defaultErrorMessages = {
        money: {
            required: 'Prijs is verplicht',
            invalid: 'Prijs is ongeldig'
        },
        minParticipants: 'Minimum aantal deelnemers is ongeldig',
        maxParticipants: 'Maximum aantal deelnemers is ongeldig',
        numberOfStartmoments: 'Aantal startmomenten is ongeldig'
    }

    const errorMessages = {
        name: 'Naam projectaanvraag is verplicht',
        type: 'Type projectaanvraag is verplicht',
        assignedApproverEmail: 'Goedkeurder is verplicht',
        basePriceWithoutVat: {
            required: defaultErrorMessages.money.required,
            money: defaultErrorMessages.money.invalid
        },
        costCenterType: 'Te factureren kostenplaats is verplicht',
        costCenter: 'Kostenplaats is verplicht',
        providerName: 'Leverancier is verplicht',
        preferredExecutionDate: {
            required: 'Gewenste uitvoerdatum is verplicht',
            isFuture: 'Gewenste uitvoerdatum is ongeldig'
        },
        pricePerPersonWithoutVat: {
            required: defaultErrorMessages.money.required,
            money: defaultErrorMessages.money.invalid
        },
        listPriceWithoutVat: {
            required: defaultErrorMessages.money.required,
            money: defaultErrorMessages.money.invalid
        },
        minParticipants: {
            required: 'Minimum aantal deelnemers is verplicht',
            integer: defaultErrorMessages.minParticipants,
            minValue: defaultErrorMessages.minParticipants,
            maxValue: defaultErrorMessages.minParticipants
        },
        maxParticipants: {
            required: 'Maximum aantal deelnemers is verplicht',
            integer: defaultErrorMessages.maxParticipants,
            minValue: defaultErrorMessages.maxParticipants,
            maxValue: defaultErrorMessages.maxParticipants
        },
        numberOfStartmoments: {
            required: 'Aantal startmomenten is verplicht',
            integer: defaultErrorMessages.numberOfStartmoments,
            minValue: defaultErrorMessages.numberOfStartmoments,
            maxValue: defaultErrorMessages.numberOfStartmoments
        }
    }

    const store = useStore()
    const { handleErrorMessage, validateForm } = useValidation("formData", errorMessages)
    const potentialsProjectApprovers = ref([])
    const setPotentialsProjectApprovers = newValue => { potentialsProjectApprovers.value = newValue }
    const showConfirmationModal = ref(false)
    const setShowConfirmationModal = newValue => { showConfirmationModal.value = newValue }
    const showFailedPostErrorMessage = ref(false)
    const setShowFailedPostErrorMessage = newValue => { showFailedPostErrorMessage.value = newValue }
    const accountProviders = ref([])
    const setAccountProviders = newValue => { accountProviders.value = newValue }
    const isSubmitting = ref(false)
    const setIsSubmitting = newValue => { isSubmitting.value = newValue }

    const formData = reactive({
        name: null,
        type: null,
        approverComment: null,
        basePriceWithoutVat: null,
        description: null,
        costCenterType: projectCostCenterType.Approver,
        costCenter: null,
        assignedApproverEmail: null,
        providerName: null,
        offerFile: null,
        preferredExecutionDate: null,
        minParticipants: null,
        maxParticipants: null,
        pricePerPersonWithoutVat: null,
        listPriceWithoutVat: null,
        numberOfStartmoments: null
    })

    const userPersonalDetails = computed(() => {
        return [
            store.getters["userModule/userDepartment"]?.name && { label: "Afdeling", values: [store.getters["userModule/userDepartment"]?.name] },
            { label: "Naam", values: [store.getters["userModule/fullName"]] },
            { label: "E-mail", values: [store.getters["userModule/emailAddress"]] },
            { label: "Telefoonnummer", values: [store.getters["userModule/telephone"]] }
        ]
    })

    const isIncompanyType = computed(() => formData.type === projectRequestTypes.IncompanyWithStartmoments || formData.type === projectRequestTypes.IncompanyWithoutStartmoments)
    const totalPrice = computed(() => formData.pricePerPersonWithoutVat)

    const searchProjectApprovers = async query => {
        try {
            userClient.cancelFetchSearch()
            if (!query) return []

            const filters = [
                {
                    keys: ['firstName', 'middleName', 'lastName', 'emailAddress'],
                    operator: ODataFilterOperations.CONTAINS,
                    value: query,
                    keyCombination: 'or'
                },
                {
                    keys: 'accountId',
                    operator: ODataFilterOperations.EQUALS,
                    value: store.getters["accountModule/accountId"]
                },
                {
                    keys: 'isEnabled',
                    operator: ODataFilterOperations.EQUALS,
                    value: true
                },
                {
                    keys: 'userId',
                    operator: ODataFilterOperations.NOTEQUALS,
                    value: store.getters["userModule/userId"]
                }
            ]

            const oDataFilters = filters.map(f => new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination))
            const dataQuery = new ODataQueryBuilder()
                .setSort('firstName', 'asc')
                .setPagination(0, 5)
                .addFilters(oDataFilters)
                .addDynamicFilter(`userRoles/any(r: r/role/roleId eq ${PROJECTAPPROVER})`)
                .build()

            const users = (await userClient.searchUsersBasicInfo(dataQuery))?.items
            setPotentialsProjectApprovers(users)
            return users?.map(e => ({
                id: e.userId,
                email: e.emailAddress,
                name: `${e.fullName} ${e.employeeNumber ? `- ${e.employeeNumber}` : ''}`
            }))
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error(err)
            }
            return []
        }
    }

    const fetchAccountProviders = async () => {
        try {
            setAccountProviders(await providerClient.search())
        }
        catch (err) {
            if (!axios.isCancel(err)) {
                console.error(err)
            }
        }
    }

    const buildPostProjectRequestBody = () => {
        const matchedApprover = potentialsProjectApprovers
            .value?.find(potentialApprover => potentialApprover.emailAddress.toLowerCase() === formData.assignedApproverEmail?.toLowerCase())

        const matchedProvider = accountProviders
            .value?.find(provider => provider.name === formData.providerName)

        return {
            name: formData.name,
            type: formData.type,
            approverComment: formData.approverComment,
            basePriceWithoutVat: formData.basePriceWithoutVat,
            description: formData.description,
            costCenterType: formData.costCenterType,
            costCenter: formData.costCenter,
            assignedApproverId: matchedApprover?.userId,
            edumsProviderId: matchedProvider?.id,
            offerFile: formData.offerFile,
            preferredExecutionDate: formData.preferredExecutionDate 
                ? DateUtils.parseToISOString(formData.preferredExecutionDate)
                : null,
            minParticipants: formData.minParticipants,
            maxParticipants: formData.maxParticipants,
            pricePerPersonWithoutVat: formData.pricePerPersonWithoutVat,
            listPriceWithoutVat: formData.listPriceWithoutVat,
            numberOfStartmoments: formData.numberOfStartmoments
        }
    }

    const handleSubmit = async () => {
        if (!validateForm()) {
            scrollTo(`body`)
            return
        }

        try {
            setIsSubmitting(true)
            setShowFailedPostErrorMessage(false)
            const requestBody = buildPostProjectRequestBody()

            await projectRequestClient.post(requestBody)
            setShowConfirmationModal(true)
        } catch (error) {
            setShowFailedPostErrorMessage(true)
            throw new Error("Failed to post project request", { cause: error })
        } finally {
            setIsSubmitting(false)
        }
    }

    const setApproverCostCenter = approverEmail => {
        if (!approverEmail)
            return

        const approverCostCenter = potentialsProjectApprovers.value?.find(x => x.emailAddress === approverEmail)?.costCenter
        formData.costCenter = approverCostCenter ?? null
    }

    watch(() => formData.assignedApproverEmail, newValue => {
        if (formData.costCenterType !== projectCostCenterType.Approver)
            return

        setApproverCostCenter(newValue)
    })

    watch(() => formData.costCenterType, newValue => {
        formData.costCenter = null

        if (newValue === projectCostCenterType.Approver) {
            setApproverCostCenter(formData.assignedApproverEmail)
        }
    })

    return {
        handleSubmit,
        userPersonalDetails,
        formData,
        handleErrorMessage,
        searchProjectApprovers,
        setShowConfirmationModal,
        fetchAccountProviders,
        accountProviders,
        showConfirmationModal: readonly(showConfirmationModal),
        showFailedPostErrorMessage: readonly(showFailedPostErrorMessage),
        isSubmitting: readonly(isSubmitting),
        isIncompanyType,
        totalPrice
    }
}
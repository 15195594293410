/* eslint-disable import/prefer-default-export */
import { getOplzClient } from './apiClient'

const baseRoute = '/assorted-products'

export const getAssortedProducts = async oDataQuery => {
    const client = await getOplzClient()
    return client.post(`${baseRoute}`, oDataQuery).then(response => {
        return response?.data
    })
}
<template>
  <div v-if="facets || selectedPeersFilter">
    <slot name="facets-title"></slot>
    <div class="c-facet">
      <!-- If you change something on the course filtering/facet components, check if you also have to make changes to the peersFilter -->
      <course-search-peers-filter v-if="!searchFromTeamAssortments" />
      <!-- Hide course search location radius on prd -->
      <course-search-location-radius />
      <template v-for="facet in facets">
        <div
          v-if="displayFacet(facet)"
          :key="facet.key"
          class="c-facet__item"
          :data-test="`facet-${facet.key}`"
        >
          <!-- Desktop view -->
          <course-search-facet
            :fetch-result-after-select="true"
            :facet="facet"
            class="d-none d-md-block"
          >
            <h5
              slot="facet-title"
              class="c-facet__title"
            >
              {{ facet.display }}
            </h5>
          </course-search-facet>

          <!-- Mobile view -->
          <recess-accordion
            :active="getFilterToCollapse(facet)"
            class="d-md-none"
            data-test="filter-accordion"
          >
            <template #accordion-title>
              <h4 class="c-facet__title mb-0">{{ facet.display }}</h4>
            </template>
            <template #accordion-content>
              <course-search-facet :facet="facet" />
            </template>
          </recess-accordion>
        </div>
      </template>
    </div>
    <div
      class="c-facet__footer"
      data-test="facet-footer"
    >
      <slot name="facets-footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { jsMediaQueriesMixin } from '../../../../mixins/mediaqueries-mixin'
import CourseSearchFacet from './CourseSearchFacet.vue'
import CourseSearchPeersFilter from './CourseSearchPeersFilter.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import CourseSearchLocationRadius from './CourseSearchLocationRadius.vue'
import { PRICE } from '../../../../constants/facetKeys'

export default {
    name: 'CourseSearchFacets',
    components: { CourseSearchFacet, CourseSearchPeersFilter, CourseSearchLocationRadius },
    perimeters: [featuresPerimeter],
    mixins: [jsMediaQueriesMixin],
    computed: {
        ...mapState('searchModule', ['facets', 'searchFromTeamAssortments', 'selectedPeersFilter', 'hiddenFacets'])
    },
    methods: {
        // Read encoded filters from the url and open the accordion which contains selected filters
        getFilterToCollapse(facet) {
            try {
                const encodedFacetFilters = this.$route.query.facetFilters
                if (encodedFacetFilters?.length) {
                    const decodedFacetFilters = JSON.parse(
                        Buffer.from(encodedFacetFilters, 'base64').toString()
                    )

                    return decodedFacetFilters.some(filter => filter.key === facet.key)
                }

                return false
            } catch (error) {
                console.error(error)
                // Clear facet filter if invalid base64
                this.$router.replace({query: {...this.$route.query, facetFilters: undefined }})
                return false
            }
        },
        displayFacet(facet) {
            return !(facet.key === PRICE && !this.$isAllowed('displayPricingInformation')) 
            && !this.hiddenFacets?.includes(facet.key)
            && facet.values?.length
        }
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
    <div>
        <recess-divider :display-medium-viewport="false" :display-small-viewport="false" />
        <loader-component v-if="isLoading" overlay="white" color="primary" />
        <p data-test="explanation-text" class="p-0 pl-md-4 col-12 col-md-8">
            {{ explanationText }}
        </p>

        <transition name="fade">
            <div v-if="activeCourses && activeCourses.length">
                <recess-table>
                    <recess-table-head>
                        <recess-table-row>
                            <recess-table-head-item class="flex-grow-2">
                                Ontwikkelactiviteit
                            </recess-table-head-item>

                            <recess-table-head-item>Startdatum</recess-table-head-item>

                            <recess-table-head-item>Status</recess-table-head-item>

                            <recess-table-head-item
                                v-if="$isAllowed('displayCompletedColumn')"
                                class="flex-shrink-2"
                            >
                                Voltooid
                            </recess-table-head-item>

                            <recess-table-head-item>
                                Beoordeel de ontwikkelactiviteit
                            </recess-table-head-item>

                            <recess-table-head-item class="flex-grow-2" />
                        </recess-table-row>
                    </recess-table-head>

                    <recess-table-body>
                        <recess-table-row
                            v-for="(activeCourse, index) in activeCourses"
                            :key="index"
                            :data-test="
                                activeCourse.eLearningSubscriptionId
                                    ? `eLearningSubscription-${activeCourse.eduMSCourseId}`
                                    : undefined
                            "
                        >
                            <recess-table-cell data-label="Ontwikkelactiviteit" class="flex-grow-2">
                                <router-link
                                    v-if="!activeCourse.deletedFromAssortment"
                                    :to="{
                                        name: 'course',
                                        query: {
                                            course: activeCourse.eduMSCourseId,
                                            assortment: activeCourse.assortmentId
                                        }
                                    }"
                                >
                                    <span v-html="getCourseNameAndProvider(activeCourse)"></span>
                                </router-link>

                                <span v-else v-html="getCourseNameAndProvider(activeCourse)"></span>
                            </recess-table-cell>

                            <recess-table-cell data-label="Startdatum">
                                {{ format(activeCourse.acceptedStartDate) }}
                                {{ activeCourse.location ? `(${activeCourse.location})` : '' }}
                            </recess-table-cell>

                            <recess-table-cell data-label="Status">
                                {{ getStatusDisplayValue(activeCourse) }}
                            </recess-table-cell>

                            <recess-table-cell
                                v-if="$isAllowed('displayCompletedColumn')"
                                data-label="Voltooid"
                                class="d-flex justify-content-between flex-shrink-2"
                            >
                                <component
                                    :is="
                                        !activeCourse.isAllowedToToggleComplete
                                            ? 'recess-tooltip'
                                            : 'div'
                                    "
                                    v-if="!activeCourse.completedByFulfillment"
                                    class="u-position-relative"
                                >
                                    <recess-toggle-input
                                        v-model="activeCourse.completedByStudent"
                                        data-test="complete-toggle"
                                        :disabled="!activeCourse.isAllowedToToggleComplete"
                                        @input="(val) => toggleCompletion(activeCourse, index)"
                                    />
                                    <template #content>
                                        <p>
                                            U kunt uw ontwikkelactiviteit alleen op status afgerond
                                            zetten wanneer de startdatum + de theoretische duur van
                                            de ontwikkelactiviteit in het verleden liggen.
                                        </p>
                                    </template>
                                    <loader-component
                                        v-if="isTogglingCompletion[index]"
                                        variant="small"
                                        color="primary"
                                    />
                                </component>
                                <div v-else class="d-flex">
                                    <recess-tooltip>
                                        <span data-test="completed-text"> Ja </span>
                                        <recess-icon icon="check" />
                                        <template #content> Gevalideerd </template>
                                    </recess-tooltip>
                                </div>
                            </recess-table-cell>

                            <recess-table-cell>
                                <star-rating
                                    :class="[
                                        'd-none d-lg-block',
                                        {
                                            'u-cursor-not-allowed':
                                                activeCourse.deletedFromAssortment
                                        }
                                    ]"
                                    :increment="0.5"
                                    :show-rating="false"
                                    :star-size="22"
                                    :read-only="activeCourse.deletedFromAssortment"
                                    :rating="
                                        activeCourse.userRating && activeCourse.userRating.rating
                                            ? (activeCourse.userRating.rating / 100) * 5
                                            : 0
                                    "
                                    active-color="RGB(var(--r-color-secondary))"
                                    @rating-selected="
                                        (rating) => changeRating(activeCourse, rating * 10 * 2)
                                    "
                                />
                                <star-rating
                                    class="d-lg-none d-flex justify-content-center justify-content-md-end"
                                    :inline="true"
                                    :max-rating="10"
                                    :show-rating="false"
                                    :read-only="activeCourse.deletedFromAssortment"
                                    :star-size="28"
                                    :rating="
                                        activeCourse.userRating && activeCourse.userRating.rating
                                            ? activeCourse.userRating.rating / 10
                                            : 0
                                    "
                                    active-color="RGB(var(--r-color-secondary))"
                                    @rating-selected="
                                        (rating) => changeRating(activeCourse, rating * 10)
                                    "
                                />
                            </recess-table-cell>

                            <recess-table-cell class="d-flex align-items-start flex-grow-2">
                                <div class="d-flex table-options-flex-column">
                                    <recess-button
                                        v-if="activeCourse.oleUrl"
                                        :url="activeCourse.oleUrl"
                                        title="Naar LMS"
                                        target="_blank"
                                        variant="secondary"
                                        data-test="options-ole-url"
                                    />

                                    <recess-button
                                        v-if="
                                            $isAllowed('displayPERemindersFeature') &&
                                            !$isAllowed('displayLearningResult') &&
                                            activeCourse.isAllowedToToggleComplete
                                        "
                                        title="+ Leerresultaat"
                                        variant="tertiary"
                                        data-test="options-learning-result"
                                        @click="navigateToLearningResult(activeCourse.courseName)"
                                    />
                                </div>

                                <recess-icon
                                    v-if="activeCourse.eLearningSubscriptionId"
                                    data-test="options-delete"
                                    icon="bin"
                                    class="pl-2"
                                    @click="
                                        removeELearningFromActiveCourses(
                                            activeCourse.eLearningSubscriptionId,
                                            index
                                        )
                                    "
                                />
                            </recess-table-cell>
                        </recess-table-row>
                    </recess-table-body>
                </recess-table>
            </div>
        </transition>

        <recess-alert
            v-if="showNoActiveCourseMessage"
            text="Er zijn op dit moment geen gestarte ontwikkelactiviteiten."
        />

        <recess-alert
            v-if="showErrorMessage"
            type="error"
            text="Er is een fout opgetreden. Probeer het later opnieuw."
        />
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { scrollTo } from 'vue-scrollto'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import enrollmentClient from '../../../api/enrollmentClient'
import courseClient from '../../../api/courseClient'
import DateUtils from '../../../utils/DateUtils'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import permanentEducationPerimeter from '../../../perimeters/permanentEducationPerimeter'
import eLearningSubscriptionClient from '../../../api/eLearningSubscriptionClient'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import { searchPermanentEducation } from '../../../api/permanentEducationClient'
import useStore from '../../../composables/useStore'
import useRouter from '../../../composables/useRouter'
import activeCourseStatus from '../../../constants/activeCourseStatus'

export default {
    perimeters: [featuresPerimeter, permanentEducationPerimeter],
    components: { LoaderComponent },
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    setup() {
        const store = useStore()
        const router = useRouter()

        const activeCourses = ref(null)
        const isFetchingActiveCourses = ref(false)
        const isPostingRating = ref(false)
        const showNoActiveCourseMessage = ref(false)
        const isRemovingELearning = ref(false)
        const showErrorMessage = ref(false)
        const isFetchingLearningResult = ref(false)
        const isTogglingCompletion = ref({})

        const userId = computed(() => store.getters['userModule/userId'])

        const isLoading = computed(
            () =>
                isFetchingActiveCourses.value ||
                isPostingRating.value ||
                isRemovingELearning.value ||
                isFetchingLearningResult.value
        )

        const getCourseNameAndProvider = (course) =>
            `<b>${course.courseName}</b><br />${course.providerName}`

        const getStatusDisplayValue = (course) => activeCourseStatus[course.status]?.displayValue ?? ''

        async function fetchActiveCourses() {
            try {
                // Start loading
                isFetchingActiveCourses.value = true
                showNoActiveCourseMessage.value = false
                activeCourses.value = await enrollmentClient.getActiveCourses()

                if (!activeCourses.value?.length) {
                    showNoActiveCourseMessage.value = true
                }
            } catch (err) {
                throw new Error(err)
            } finally {
                isFetchingActiveCourses.value = false
            }
        }

        async function changeRating(course, rating) {
            if (course.deletedFromAssortment) return

            try {
                isPostingRating.value = true

                if (course.userRating?.rating) {
                    await courseClient.patchCourseRating(course.userRating.id, rating)
                } else {
                    await courseClient.postCourseRating(course.eduMSCourseId, rating)
                }

                await fetchActiveCourses()
            } catch (err) {
                showErrorMessage.value = true
                scrollTo('body')
                throw new Error(err)
            } finally {
                isPostingRating.value = false
            }
        }

        async function removeELearningFromActiveCourses(eLearningSubscriptionId, index) {
            // Start loading animation
            isRemovingELearning.value = true

            try {
                // Do the request
                const response = await eLearningSubscriptionClient.softDeleteELearningSubscription(
                    eLearningSubscriptionId
                )

                if (response) {
                    // Remove course from activecourses list
                    activeCourses.value.splice(index, 1)
                }
            } catch (error) {
                console.error(error)
            } finally {
                isRemovingELearning.value = false
            }
        }

        async function toggleCompletion(activeCourse, index) {
            try {
                if (!activeCourse.eLearningSubscriptionId && !activeCourse.enrollmentId) {
                    return
                }

                isTogglingCompletion.value[index] = true

                if (activeCourse.eLearningSubscriptionId)
                    await eLearningSubscriptionClient.toggleCompletion(
                        activeCourse.eLearningSubscriptionId
                    )
                else await enrollmentClient.toggleCompletion(activeCourse.enrollmentId)
            } catch (error) {
                throw new Error(error)
            } finally {
                isTogglingCompletion.value = {}
            }
        }

        async function getLearningResultByCourseName(courseName) {
            try {
                isFetchingLearningResult.value = true

                // Check if learning result with course name exists
                const dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 1)
                    .addFilter(
                        new ODataQueryFilter('userId', ODataFilterOperations.EQUALS, userId.value)
                    )
                    .addFilter(
                        new ODataQueryFilter('courseName', ODataFilterOperations.EQUALS, courseName)
                    )

                const response = await searchPermanentEducation(dataQuery.build())
                return response?.items?.[0]
            } catch {
                return null
            } finally {
                isFetchingLearningResult.value = false
            }
        }

        async function navigateToLearningResult(courseName) {
            const learningResult = await getLearningResultByCourseName(courseName)
            if (learningResult) {
                // exists -> navigate to learning result details
                const learningResultId = learningResult.permanentEducationId
                router.push({
                    name: 'edit-permanent-education',
                    params: { id: learningResultId, fromActiveCourses: true }
                })
            } else {
                // not exists -> navigate to learning result create
                router.push({
                    name: 'create-permanent-education',
                    params: {
                        defaultCourseName: courseName
                    }
                })
            }
        }

        onMounted(async () => {
            await fetchActiveCourses()
        })

        return {
            isLoading,
            activeCourses,
            showNoActiveCourseMessage,
            showErrorMessage,
            isTogglingCompletion,
            format: (e) => DateUtils.formatDate(e),
            getCourseNameAndProvider,
            getStatusDisplayValue,
            changeRating,
            removeELearningFromActiveCourses,
            toggleCompletion,
            navigateToLearningResult
        }
    }
}
</script>

function isValidColor(companyColor) {
    return Number.isInteger(companyColor) && (companyColor >= 0 && companyColor <= 255)
}

function updateTheme(acc) {
    const htmlElements = document.getElementsByTagName('html')
    if (htmlElements) {
        const style = htmlElements[0].style

        if (
            isValidColor(acc.colorPrimaryR) &&
            isValidColor(acc.colorPrimaryG) &&
            isValidColor(acc.colorPrimaryB)
        ) {
            style.setProperty(
                '--color-primary',
                `${acc.colorPrimaryR}, ${acc.colorPrimaryG}, ${acc.colorPrimaryB}`
            )
        }

        if (
            isValidColor(acc.colorSecondaryR) &&
            isValidColor(acc.colorSecondaryG) &&
            isValidColor(acc.colorSecondaryB)
        ) {
            style.setProperty(
                '--color-secondary',
                `${acc.colorSecondaryR}, ${acc.colorSecondaryG}, ${acc.colorSecondaryB}`
            )
        }

        if (
            isValidColor(acc.colorBannerTextR) &&
            isValidColor(acc.colorBannerTextG) &&
            isValidColor(acc.colorBannerTextB)
        ) {
            style.setProperty(
                '--color-banner-text',
                `${acc.colorBannerTextR}, ${acc.colorBannerTextG}, ${acc.colorBannerTextB}`
            )
        }
    }
}

function initializeThemer(store) {
    store.watch(
        state => {
            return state.accountModule.account
        },
        newAccount => {
            if (newAccount) {
                updateTheme(newAccount)
            }
        },
        {
            deep: true
        }
    )
}

export default {
    initializeThemer
}

import orderStatus from './orderStatus'
import {CANCELLED, REJECTEDEMPLOYER, DECLINEDUNVERIFIED, REJECTEDEMPLOYEE, CANCELED_VERIFICATION_TIME_OVERDUE} from './enrollmentStatus'

export const INVALIDCODE = orderStatus.Invalid.code
export const REJECTEDACCOUNTCODE = orderStatus.RejectedAccount.code
export const REJECTEDPROVIDERCODE = orderStatus.RejectedProvider.code
export const CANCELLEDACCOUNTCODE = orderStatus.CancelledAccount.code
export const CANCELLEDADMINCODE = orderStatus.CancelledAdmin.code
export const CREDITEDCODE = orderStatus.Credited.code
export const CANCELLEDWITHCOSTSSCODE = orderStatus.CancelledWithCosts.code

export const edumsInvalidOrderStatuses =  [
    INVALIDCODE,
    REJECTEDACCOUNTCODE,
    REJECTEDPROVIDERCODE,
    CANCELLEDACCOUNTCODE,
    CANCELLEDADMINCODE,
    CREDITEDCODE,
    CANCELLEDWITHCOSTSSCODE
]

export const oplzInvalidStatuses = [
    CANCELLED, 
    REJECTEDEMPLOYER,
    DECLINEDUNVERIFIED,
    REJECTEDEMPLOYEE,
    CANCELED_VERIFICATION_TIME_OVERDUE
]
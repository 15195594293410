<template>
  <div>
    <h4 class="u-textcolor-primary">Beoordelaar(s) kiezen</h4>

    <recess-list class="px-0">
      <recess-list-row>
        <recess-list-item class="col col-6">
          <h5>Afdeling</h5>
        </recess-list-item>
        <recess-list-item class="col col-6">
          <h5>Beoordelaar</h5>
        </recess-list-item>
      </recess-list-row>
      <recess-divider show-line />
      <div
        v-for="(company, index) in selectedStudentsCompanies"
        :key="index"
      >
        <recess-list-row>
          <recess-list-item class="col col-6">
            <p class="mb-0">{{ company.name }}</p>
          </recess-list-item>
          <recess-list-item class="col col-6">
            <div v-if="selectFirstApproverWithSearchInput">
              <transition
                name="fade"
                mode="out-in"
              >
                <loader-component
                  v-if="isLoading[company.companyId]"
                  variant="small"
                  color="primary"
                />
              </transition>
              <recess-autocomplete
                v-model="teamleadersFormData[company.companyId]"
                placeholder="Naam of e-mail"
                item-key="userId"
                item-text="emailAddress"
                item-sub-text="fullName"
                data-test="team-leader-input"
                :error-message="validationError(company.companyId)"
                :on-query="query => searchTeamleader(query, company.companyId)"
              />
            </div>
            <recess-select
              v-else
              select-option-text="Selecteer beoordelaar..."
              :default-value="selectedCompanyTeamleaders[company.companyId] && selectedCompanyTeamleaders[company.companyId].userId || null"
              :options="sortedCompanyTeamleaders[company.companyId] || null"
              @input="teamleaderUserId => assignCompanyTeamleader(company.companyId, teamleaderUserId)"
            />
          </recess-list-item>
        </recess-list-row>
        <recess-divider show-line />
      </div>
    </recess-list>

    <enrollment-step-buttons 
      :show-next-button="validateSelectedTeamleaders()"
      v-on="{ ...$listeners, 'next-step': submit }" 
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import userClient from '../../../../api/userClient'
import ODataFilterOperations from '../../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../../api/OData/odataQueryFilter'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'

export default {
    name: 'EnrollmentStepTeamLeaders',
    components: {
        LoaderComponent,
        EnrollmentStepButtons
    },
    data() {
        return {
            teamleaders: {},
            isLoading: {},
            teamleadersFormData: {}
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['teamleadersPerCompany']),
        ...mapState('userModule', ['user']),
        ...mapGetters('enrollmentModule', [
            'selectedStudentsCompanies',
            'selectedCompanyTeamleaders',
            'selectedStudentsCompanyIds'
        ]),
        ...mapGetters('accountModule', ["selectFirstApproverWithSearchInput"]),

        sortedCompanyTeamleaders() {
            if (this.teamleadersPerCompany) {
                const returnValue = {}
                Object.entries(this.teamleadersPerCompany).forEach(([key, value]) => {
                    returnValue[key] = value
                        .map(teamleader => {
                            return {
                                value: teamleader.userId,
                                displayText: teamleader.fullName
                            }
                        })
                        // Sort teamleaders alphabetically
                        .sort((a, b) => {
                            if (a.displayText.toLowerCase() < b.displayText.toLowerCase()) {
                                return -1
                            }
                            if (a.displayText.toLowerCase() > b.displayText.toLowerCase()) {
                                return 1
                            }
                            return 0
                        })
                        .sort((a, b) => {
                            if (a.displayText.toLowerCase() < b.displayText.toLowerCase()) {
                                return -1
                            }
                            if (a.displayText.toLowerCase() > b.displayText.toLowerCase()) {
                                return 1
                            }
                            return 0
                        })
                })
                return returnValue
            }
            return null
        }
    },
    mounted() {
        if(this.selectFirstApproverWithSearchInput) {
            this.setInputDefaultValues()
        } else {
            this.getTeamleadersByCompanyIds(this.selectedStudentsCompanyIds)
        }
    },
    methods: {
        ...mapMutations('enrollmentModule', ['selectCompanyTeamleader']),
        ...mapActions('enrollmentModule', ['getTeamleadersByCompanyIds']),
        validateSelectedTeamleaders() {
            if(this.selectFirstApproverWithSearchInput) {
                return this.selectedStudentsCompanies?.every(c => {
                    return this.isMatchingUserEmail(c.companyId, this.teamleadersFormData[c.companyId])
                })
            } 
          
            return this.selectedStudentsCompanies.every(
                company => this.selectedCompanyTeamleaders[company.companyId]
            )
        },
        setInputDefaultValues() {
            Object.keys(this.selectedCompanyTeamleaders).forEach(async k => {
                this.teamleadersFormData = {
                    ...this.teamleadersFormData,
                    [k] : this.selectedCompanyTeamleaders[k].emailAddress
                }
                this.isLoading[k] = true
                await this.searchTeamleader(this.selectedCompanyTeamleaders[k].emailAddress, k )
                this.isLoading = {}
            })
        },
        isMatchingUserEmail(companyId, email) {
            return this.teamleaders[companyId]?.some(x => x.emailAddress === email)
        },
        async searchTeamleader(val, companyId) {
            try {
                // Cancel previous pending request
                userClient.cancelFetchSearch()

                const filters = [
                    {
                        keys: 'user/accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.user.accountId
                    },
                    {
                        keys: ['user/firstName', 'user/middleName', 'user/lastName', 'user/emailAddress'],
                        operator: ODataFilterOperations.CONTAINS,
                        value: val,
                        keyCombination: 'or'
                    },
                    {
                        keys: 'companyId',
                        operator: ODataFilterOperations.EQUALS,
                        value: +companyId
                    }
                ]
                const oDataFilters = filters.map(f => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('user/firstName', 'asc')
                    .setPagination(0, 5)
                    .addFilters(oDataFilters)
                    .build()

                const response = await userClient.getCompanyTeamleaders(dataQuery)

                this.teamleaders[companyId] = response?.items ?? []
                this.isLoading = {}
                return response?.items ?? []
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    this.isLoading = {}
                    throw new Error(error)
                }
                return []
            } 
        },
        validationError(companyId) {
            if(!this.isLoading[companyId] && this.teamleadersFormData[companyId]?.length >= 3 && !this.isMatchingUserEmail(companyId, this.teamleadersFormData[companyId]) && !this.teamleaders[companyId]?.length) {
                return 'Er zijn geen resultaten gevonden'
            }

            if(!this.isLoading[companyId] && this.teamleadersFormData[companyId]?.length >= 3 && !this.isMatchingUserEmail(companyId, this.teamleadersFormData[companyId])) {
                return 'Kies een leidinggevende uit de lijst' 
            }
          
            return ''
        },
        assignCompanyTeamleader(companyId, userId) {
            const user = this.teamleadersPerCompany[companyId]?.find(u => u.userId === userId)
            this.selectCompanyTeamleader({user, companyId})
        },
        submit() {
            if(this.selectFirstApproverWithSearchInput) {
                this.selectedStudentsCompanies.forEach(c => {
                    const user = this.teamleaders[c.companyId]?.find(u =>  u.emailAddress === this.teamleadersFormData[c.companyId])

                    if(user) {
                        this.selectCompanyTeamleader({user, companyId: c.companyId})
                    }
                })
                this.$emit('next-step')
            } else {
                this.$emit('next-step')
            }
        }
    }
}
</script>


<template>
  <div>
    <menu-button
      :is-open="isNavigationOpen"
      class="c-menu-button__user"
      data-test="user-menu-toggle"
      @onOpen="open"
      @onClose="close"
      @click="isNavigationOpen = !isNavigationOpen"
    >
      <div class="c-menu-button__close"></div>
      <img
        alt="Profile icon"
        :src="profileIcon"
        class="c-menu-button__custom-icon"
      />
    </menu-button>

    <div
      :class="['c-mobile-navigation c-user-mobile-navigation ', {'is-active': isNavigationOpen}]"
    >
      <div 
        class="c-mobile-navigation__list"
        data-test="user-menu-small"
      >
        <small-navigation-item>
          <router-link :to="{ name: 'profile-page' }">Mijn profiel</router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayBudgets')">
          <router-link :to="{ name: 'my-budgets' }">Mijn budgetten</router-link>
        </small-navigation-item>
        <small-navigation-item>
          <router-link :to="{ name: 'my-development', query: { 'my-development-tab': myDevelopmentTabs.WISHLIST.key } }">{{ myDevelopmentTabs.WISHLIST.displayValue }}</router-link>
        </small-navigation-item>
        <small-navigation-item
          v-if="sharedIds && sharedIds.length > 1"
          class="u-cursor-pointer"
        >
          <a @click.prevent="openAccountSelection()">Wissel van account</a>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayChangePasswordLink')">
          <router-link :to="{ name: 'forgot-password' }">Wachtwoord wijzigen</router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayLogoutLink')">
          <logout-component />
        </small-navigation-item>
      </div>
    </div>
    <account-selection-component
      :is-visible="showAccountSelection"
      show-close-button
      @close="showAccountSelection = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuButton from '../../../atoms/MenuButton/MenuButton.vue'
import SmallNavigationItem from './SmallNavigationItem.vue'
import LogoutComponent from '../../../atoms/LogoutComponent/LogoutComponent.vue'
import profileIcon from '../../../../assets/images/icons/icon-profile.png'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import { myDevelopmentTabs } from '../../../../constants/tabNames'
import AccountSelectionComponent from '../../../molecules/AccountSelectionComponent/AccountSelectionComponent.vue'

export default {
    perimeters: [featuresPerimeter],
    components: { SmallNavigationItem, MenuButton, LogoutComponent, AccountSelectionComponent },
    data() {
        return {
            isNavigationOpen: false,
            profileIcon,
            myDevelopmentTabs,
            showAccountSelection: false
        }
    },
    computed: {
        ...mapGetters('userModule', ['sharedIds'])
    },
    watch: {
        $route() {
            // When the route change, and the side menu is open, close it
            if (this.isNavigationOpen) {
                this.close()
            }
        }
    },
    methods: {
        open() {
            this.isNavigationOpen = true
            this.$bus.emit('disable-app-scroll', 'noMobletScroll')
        },
        close() {
            this.isNavigationOpen = false
            this.$bus.emit('enable-app-scroll', 'noMobletScroll')
        },
        openAccountSelection() {
            this.showAccountSelection = true
            this.close()
        }
    }
}
</script>

<template>
  <recess-card variant="variant1" class="mt-2 d-flex justify-content-between align-items-center">
    <section class="d-flex align-items-center">
      <strong class="u-text-break">{{ documentNameDisplayValue }}</strong>
      <recess-tooltip v-if="hasDuplicatedFileName">
        <recess-icon
          class="mr-2 mr-md-0 ml-md-2"
          icon="danger"
          color="warning"
        />
        <template #content>
          <span>Een bestand met dezelfde naam bestaat al.</span>
        </template>
      </recess-tooltip>
    </section>

    <section class="d-flex align-items-center">
      <recess-button
        v-if="documentUrl"
        data-test="download-btn"
        :title="downloadBtnTitle"
        variant="tertiary"
        :url="documentUrl"
        :disabled="disabled"
        target="_blank"
      />
      <recess-icon
        v-if="showDeleteButton"
        icon="bin"
        class="ml-2"
        data-test="delete-btn"
        @click="$emit('deleted')"          
      />
      <slot name="buttons" ></slot>
    </section>
  </recess-card>
</template>

<script>
import { computed } from 'vue'
import StringUtils from '../../../utils/StringUtils'

export default {
    props: {
        documentUrl: {
            type: String,
            default: null
        },
        documentName: {
            type: String,
            default: null
        },
        downloadBtnTitle: {
            type: String,
            default: 'Bekijk'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showDeleteButton: {
            type: Boolean,
            default: true
        },
        hasDuplicatedFileName: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const documentNameDisplayValue = computed(() => {
            return StringUtils.removeDateTime(props.documentName)
        })

        return {
            documentNameDisplayValue
        }
    }
}
</script>
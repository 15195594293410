<template>
  <div class="c-enrollment-step">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <h4 class="u-textcolor-primary">Betalingsgegevens</h4>
    <p>Onderstaande informatie hebben wij nodig om de factuur te kunnen sturen. De meeste velden zijn al voor u ingevuld met de gegevens die uit stap twee. Wanneer de ontwikkelactiviteit niet door u zelf wordt betaald, geeft u de gegevens op van het betalende bedrijf. Velden met een * zijn verplicht.</p>

    <div class="o-grid">
      <!-- Billing address option -->
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-select
          :value="billingAddressOption"
          label-text="Wie betaalt?"
          data-test="billing-option-input"
          :default-value="billingAddressOption"
          :options="billingOptions"
          @input="setBillingAddressOption"
        />
      </div>
    </div>

    <template v-if="billingAddressOption === billingAddressOptions.MYEMPLOYER">
      <div class="o-grid">
        <!-- Initials -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.firstName"
            data-test="first-name-input"
            label-text="Voornaam *"
            :error-message="getErrorMessage('firstName')"
          />
        </div>
        <!-- MiddleName text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.middleName"
            data-test="middle-name-input"
            label-text="Tussenvoegsel(s)"
          />
        </div>
        <!-- LastName text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.lastName"
            data-test="last-name-input"
            label-text="Achternaam *"
            :error-message="getErrorMessage('lastName')"
          />
        </div>
        <!-- Email text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.emailAddress"
            data-test="email-address-input"
            label-text="E-mailadres *"
            :error-message="getErrorMessage('emailAddress')"
          />
        </div>
        <!-- Phone number text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model.trim="formData.billingAddress.phoneNumber"
            label-text="Telefoonnummer *"
            data-test="phone-number-input"
            :error-message="getErrorMessage('phoneNumber')"
          />
        </div>
      </div>

      <h4 class="u-textcolor-primary">Adresgegevens</h4>

      <div class="o-grid">
        <!-- Company name -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.companyName"
            data-test="company-name-input"
            label-text="Bedrijfsnaam"
          />
        </div>
      </div>

      <div class="o-grid u-position-relative">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isFetchingAddress"
            variant="small"
            color="primary"
            overlay="white"
          />
        </transition>
        <!-- Address PostalCode text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.postalCode"
            label-text="Postcode *"
            data-test="postal-code-input"
            :error-message="getErrorMessage('postalCode')"
            @blur="getAddress()"
          />
        </div>
        <!-- Address HouseNumber text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.number"
            label-text="Huisnummer *"
            data-test="house-number-input"
            :error-message="getErrorMessage('number')"
            @blur="getAddress()"
          />
        </div>
        <!-- Address HouseNumberAddition text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.numberAdditive"
            data-test="house-number-additive-input"
            label-text="Huisnummer toevoeging"
          />
        </div>
        <!-- Street name -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.street"
            label-text="Straat *"
            data-test="street-input"
            :error-message="getErrorMessage('street')"
          />
        </div>
        <!-- Address City	text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.billingAddress.city"
            label-text="Plaats *"
            data-test="city-input"
            :error-message="getErrorMessage('city')"
          />
        </div>
        <!-- Address CountryCode text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-select
            v-model="formData.billingAddress.country"
            label-text="Land *"
            data-test="country-input"
            :default-value="formData.billingAddress.country || 'NL'"
            :options="countries"
            :error-message="getErrorMessage('country')"
            @input="(value) => $emit('input', value)"
          />
        </div>
      </div>
    </template>
    <recess-list
      v-else
      class="p-0 col-12 col-md-6"
    >
      <recess-list-row>
        <span class="font-weight-bold">Voornaam</span>
        <span>{{ formData.firstName }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Tussenvoegsel(s)</span>
        <span>{{ formData.middleName }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Achternaam</span>
        <span>{{ formData.lastName }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">E-mailadres</span>
        <span>{{ formData.emailAddress }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Telefoonnummer</span>
        <span>{{ formData.telephone }}</span>
      </recess-list-row>

      <h4 class="u-textcolor-primary mt-3 mb-2">Adresgegevens</h4>

      <recess-list-row>
        <span class="font-weight-bold">Postcode</span>
        <span>{{ formData.postalCode }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Straat</span>
        <span>{{ formData.street }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Huisnummer</span>
        <span>{{ formData.number }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Huisnummer toevoeging</span>
        <span>{{ formData.numberAdditive }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Plaats</span>
        <span>{{ formData.city }}</span>
      </recess-list-row>
      <recess-list-row>
        <span class="font-weight-bold">Land</span>
        <span>{{ formDataCountry }}</span>
      </recess-list-row>
    </recess-list>

    <enrollment-step-buttons v-on="{ ...$listeners, 'next-step': confirmStep }" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import { validationMessages, isValidEmail, isValidPhoneNumber } from '../../../../modules/validationsHelper'
import * as billingAddressOptions from '../../../../constants/billingAddressOptions'
import { getAddress, cancelFetchAddress } from "../../../../api/addressLookupClient"
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import enrollmentClient from '../../../../api/enrollmentClient'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'

export default {
    components: {
        LoaderComponent,
        EnrollmentStepButtons
    },
    validations() {
        return {
            formData: {
                billingAddress: {
                    firstName: { required },
                    lastName: { required },
                    city: { required },
                    country: { required },
                    postalCode: { required },
                    number: { required, numeric },
                    street: { required },
                    emailAddress: { required, isValidEmail },
                    phoneNumber: { required, isValidPhoneNumber }
                }
            }
        }
    },
    data() {
        return {
            validationMessages,
            billingAddressOptions,
            billingOptions: [
                {
                    value: billingAddressOptions.MYSELF,
                    displayText: 'Ik betaal zelf'
                },
                {
                    value: billingAddressOptions.MYEMPLOYER,
                    displayText: 'Eigen bedrijf betaalt / Mijn werkgever betaalt'
                }
            ],
            isFetchingAddress: false,
            isLoading: false
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['formData', 'countries', 'billingAddressOption']),
        formDataCountry() {
            return this.countries.find(x => x.value === this.formData.country)?.displayText
        }
    },
    mounted() {
        this.$gtag.pageview({ page_path: '/inschrijven-student-betaling' })
        this.tryLoadCopyEnrollmentAddress()
    },
    methods: {
        ...mapMutations('enrollmentModule', ['setBillingAddressOption']),
        async getAddress() {
            try {
                // Cancel previous request
                cancelFetchAddress()
                this.isFetchingAddress = true
                if (this.formData.billingAddress.postalCode 
                 && this.formData.billingAddress.number
                 && !this.$v.formData.billingAddress.postalCode.$invalid 
                 && !this.$v.formData.billingAddress.number.$invalid) {
                    const response = await getAddress(this.formData.billingAddress.postalCode, this.formData.billingAddress.number)

                    if(response) {
                        this.formData.billingAddress.street = response.streetName
                        this.formData.billingAddress.city = response.city
                        this.formData.billingAddress.country = "NL"
                    }
                }
            } catch {
                // No need to throw error to the console
            } finally {
                this.isFetchingAddress = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData.billingAddress[fieldName]
            const fieldValue = this.formData.billingAddress[fieldName]

            // Check Required
            if (fieldValidation && fieldValidation.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }

            // Check Invalid
            if (fieldValidation && fieldValidation.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        },
        confirmStep() {
            this.$v.$touch()
            if (
                this.billingAddressOption === billingAddressOptions.MYEMPLOYER &&
                this.$v.$invalid
            ) {
                return
            }
            this.$emit('next-step')
        },
        /** Try to load alternative billing address */
        async tryLoadCopyEnrollmentAddress() {
            const id = this.$route.query?.enrollment
            if (!id) return

            try {
                this.isLoading = true
                const res = await enrollmentClient.getAlternativeBillingAddress(id)
                if (!res) return

                this.formData.billingAddress = {
                    ...res,
                    number: typeof res.houseNumber === 'number' ? `${res.houseNumber}` : '',
                    numberAdditive: res.houseNumberAdditive
                }

                this.setBillingAddressOption(this.billingAddressOptions.MYEMPLOYER)
            } catch {
                /** Ignore errors */
            }
            this.isLoading = false
        }
    }
}
</script>

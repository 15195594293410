<template>
  <form @submit.prevent="addSubRecord">
    <fieldset class="row">
      <legend class="d-none">Sub record detail</legend>

      <recess-select
        v-model="subRecord.completed"
        :options="yesOrNoOptions"
        :error-message="getErrorMessage('completed')"
        class="col-lg-4 col-md-6 col-12 mb-3"
        select-option-text="Maak een keuze"
        data-test="upload-sub-record-completed"
        label-text="Afgerond *"
      />
    
      <recess-date-picker 
        ref="dateCompleted"
        v-model="subRecord.dateCompleted"
        :disabled="!subRecord.completed"
        class="col-lg-4 col-md-6 col-12 mb-3"
        :error-message="getErrorMessage('dateCompleted')"
        placeholder="dd-mm-yyyy"
        label-text="Datum afgerond"
        data-test="upload-sub-record-completed-date"
        @input="resetAlerts"
      />
    
      <recess-select
        v-model="subRecord.examStatus"
        :options="examStatusOptions"
        class="col-lg-4 col-md-6 col-12 mb-3"
        select-option-text="Maak een keuze"
        data-test="upload-sub-record-exam-status"
        label-text="Examen Status"
      />
    
      <recess-date-picker 
        ref="startDate"
        v-model="subRecord.startDate"
        :error-message="getErrorMessage('startDate')"
        class="col-lg-4 col-md-6 col-12 mb-3"
        placeholder="dd-mm-yyyy"
        label-text="Ingangsdatum"
        data-test="upload-sub-record-start-date"
        @input="resetAlerts"
      />
        
      <recess-date-picker 
        ref="expiryDate"
        v-model="subRecord.expiryDate"
        :error-message="getErrorMessage('expiryDate')"
        class="col-lg-4 col-md-6 col-12 mb-3"
        placeholder="dd-mm-yyyy"
        label-text="Vervaldatum"
        data-test="upload-sub-record-expiry-date"
        @input="resetAlerts"
      />
        
      <recess-select
        v-model="subRecord.emailReminderPeriod"
        :default-value="subRecord.emailReminderPeriod"
        :options="emailReminderPeriodOptions"
        :disabled="!subRecord.expiryDate"
        class="col-lg-4 col-md-6 col-12 mb-3"
        select-option-text="Maak een keuze"
        data-test="upload-sub-record-email-reminder"
        label-text="Hoelang van tevoren wilt u herinnerd worden?"
        @input="resetAlerts"
      />
    </fieldset>
    
    <fieldset>
      <legend class="d-none">Upload certification</legend>

      <recess-file-upload
        v-if="showUpload"
        ref="uploadFileInput"
        label-text="Kopie diploma/certificaat"
        :max-file-to-upload="maxFilesToUpload"
        :allowed-extensions="allowedExtensions"
        :placeholder-text="'.pdf .tiff .zip .jpg .png'"
        data-test="new-sub-record-file-upload-input"
        @upload="uploadCertificate"
      />
    </fieldset>
    
    <div class="d-flex justify-content-end pt-4">
      <recess-button
        title="Nieuwe toevoegen"
        variant="secondary"
        data-test="submit-sub-record"
        type="submit"
      />
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { examStatusOptions } from '../../../constants/examStatusOptions'
import DateUtils from '../../../utils/DateUtils'
import { validationMessages, isValidDate, isLaterThanStartDate } from '../../../modules/validationsHelper'
import permanentEducationEmailReminderPeriod from '../../../constants/permanentEducationEmailReminderPeriod'

export default {
    props: {
        maxFileSize: {
            type: String,
            default: '5000000'
        },
        inputLabel: {
            type: String,
            default: 'Kies bestand'
        },
        maxFilesToUpload: {
            type: Number,
            default: 5
        },
        placeholderText: {
            type: String,
            default: '.pdf .tiff .zip .jpg .png'
        },
        showUploadError: {
            type: Boolean,
            default: false
        },
        showSuccessUploadMessage: {
            type: Boolean,
            default: false
        },
        allowedExtensions: {
            type: Array,
            default: () => {
                return ['pdf', 'tif', 'tiff', 'zip', 'jpg', 'png']
            }
        },
        showUpload: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            subRecord: {
                certifications: [],
                startDate: null,
                expiryDate: null,
                emailReminderPeriod: null,
                completed: null,
                examStatus: null,
                dateCompleted: null
            },
            examStatusOptions,
            yesOrNoOptions: [
                {
                    displayText: 'Ja',
                    value: true
                },
                {
                    displayText: 'Nee',
                    value: false
                }
            ],
            emailReminderPeriodOptions: [
                {
                    displayText: '2 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.TWO_MONTHS
                },
                {
                    displayText: '3 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.THREE_MONTHS
                },
                {
                    displayText: '4 maanden voor vervaldatum',
                    value:  permanentEducationEmailReminderPeriod.FOUR_MONTHS
                },
                {
                    displayText: '5 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.FIVE_MONTHS
                },
                {
                    displayText: '6 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.SIX_MONTHS
                }
            ],
            isUploadingDocument: false,
            isSuccess: false,
            isError: false,
            validationMessages
        }
    },
    watch: {
        subRecord: {
            handler(newValue) {
                if(!newValue.expiryDate) this.subRecord.emailReminderPeriod = null
            },
            deep: true
        }
    },
    validations() {
        return {
            subRecord: {
                startDate: { isValidDate},
                dateCompleted: { 
                    isValidDate,
                    isLaterThanStartDate: val => isLaterThanStartDate(val, this.subRecord)
                },
                expiryDate: { 
                    isValidDate,
                    isLaterThanStartDate: val => isLaterThanStartDate(val, this.subRecord)
                },
                completed: { required }
            }
        }
    },
    methods: {
        resetAlerts() {
            this.isSuccess = false
            this.isError = false
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.subRecord[fieldName]
            const fieldValue = this.subRecord[fieldName]
            
            // Check Invalid
            if (fieldValidation?.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }
            // Check Required
            if (fieldValidation?.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }

            return null
        },
        uploadCertificate(filesToUpload) {
            this.isUploadingDocument = true
            this.subRecord.certifications = filesToUpload.map(file => {
                if (
                    !file?.base64FileString ||
                        !file?.structure?.name 
                ) {
                    return undefined
                }

                return {
                    fileData: file?.base64FileString,
                    fileName: file?.structure?.name
                }
            }).filter(file => !!file)
        },
        addSubRecord() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            /** @type {import('../../../models').PEItemRequest} */
            const createSubRecordRequest = {
                startDate: DateUtils.parseToISOString(this.subRecord.startDate),
                expiryDate: DateUtils.parseToISOString(this.subRecord.expiryDate),
                emailReminderPeriod: this.subRecord.emailReminderPeriod ?? permanentEducationEmailReminderPeriod.NONE,
                certifications: this.subRecord.certifications,
                completed: this.subRecord.completed,
                examStatus: this.subRecord.examStatus,
                dateCompleted: DateUtils.parseToISOString(this.subRecord.dateCompleted)

            }
            
            this.$emit('addSubRecord', createSubRecordRequest)
            this.reset()
        },
        reset() {
            this.$v.$reset()
            if (this.$refs.uploadFileInput) {
                this.$refs.uploadFileInput.selectedFiles = []
            }
            this.subRecord.certifications = []
            this.subRecord.startDate = ''
            this.subRecord.expiryDate = ''
            this.subRecord.dateCompleted = ''
            this.$refs.startDate.selectedDate = ''
            this.$refs.expiryDate.selectedDate = ''
            this.$refs.dateCompleted.selectedDate = ''
        }
    }
}
</script>

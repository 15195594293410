<template>
  <recess-card variant="variant1">
    <div class="d-flex">
      <recess-button
        class="mr-3 "
        :title="toggleButtonText"
        data-test="toggle-filter-btn"
        variant="secondary"
        :disabled="mIsDisabled"
        @click="mIsOpen = !mIsOpen"
      />
      <div class="d-flex justify-content-between flex-grow-1">
        <div class="d-flex align-items-center">
          <a
            v-if="showResetFilters"
            href
            @click.prevent="$emit('reset-filters')"
          >Verwijder filters</a>
        </div>
        <div>
          <slot name="right"></slot>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div
        v-show="mIsOpen"
        class="row"
      >
        <div class="col-12 col-lg-6">
          <recess-divider />
          <slot name="filters"></slot>
          <recess-divider />
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </recess-card>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        showResetFilters: {
            type: Boolean,
            default: false
        },
        toggleButtonText: {
            type: String,
            default: 'Filteren'
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mIsOpen: this.isOpen,
            mIsDisabled : this.isDisabled
        }
    },
    watch: {
        isDisabled(newValue) {
            this.mIsDisabled = newValue
        }
    }
}
</script>


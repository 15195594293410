import { UserManager, Log } from 'oidc-client-ts'
import { setCookie, removeCookie } from './cookie-helper'
import queryStringHelper from './queryStringHelper'

let userManagerCore = null

export default {
    initializeUserManager(settings) {
        if (userManagerCore === null) {
            // Set logger for dev environment
            if (process.env.NODE_ENV !== 'production')
                Log.setLogger(console)

            userManagerCore = new UserManager({
                ...settings,
                redirect_uri: `${window.location.origin}${settings.redirect_uri}`,
                post_logout_redirect_uri: `${window.location.origin}${settings.post_logout_redirect_uri}`,
                silent_redirect_uri: `${window.location.origin}${settings.silent_redirect_uri}`,
                automaticSilentRenew: true,
                clockSkewInSeconds: 480,
                response_type: 'code',
                monitorSession: true,
                includeIdTokenInSilentRenew: true,
                revokeTokensOnSignout: true,
                filterProtocolClaims: true
            })

            userManagerCore.events.addUserLoaded(loadedUser => {
                setCookie('Authorization', loadedUser.access_token)
            })

            userManagerCore.events.addUserUnloaded(() => {
                removeCookie('Authorization')
            })

            userManagerCore.events.addUserSignedOut(() => {
                const redirectPath = sessionStorage.getItem('oidc_active_route')
                removeCookie('Authorization')
                userManagerCore.removeUser()
                this.signIn(redirectPath || "/")
            })
        }
    },
    signIn(redirectPath) {
        sessionStorage.setItem('oidc_active_route', redirectPath)
        const ref = queryStringHelper.getQueryParam('ref')
        const options = {}
        if (ref) {
            options.acr_values = `idp:${ref}`
        }
        userManagerCore.signinRedirect(options)
    }
}

export const getUserManager = () => userManagerCore

<template>
  <div>
    <transition name="fade" mode="out-in">
      <loader-component v-if="isFetchingProjectRequest" color="primary" overlay="white" />
    </transition>

    <recess-divider />
    <h1>{{ pageTitle }}</h1>

    <template v-if="!isFetchingProjectRequest && !isFetchingDetailsError">
      <!-- User info -->
      <recess-divider />
      <details-display-component :details="userInfo" title="Persoonlijke gegevens" />

      <!-- project info -->
      <recess-divider />
      <details-display-component :details="projectInfo" title="Uitvoerder van het project" />

      <!-- Provider info -->
      <recess-divider />
      <details-display-component :details="providerInfo" title="Gegevens van leveranciers" />

      <!-- Participants info -->
      <recess-divider v-if="isTypeIncompany"/>
      <details-display-component v-if="isTypeIncompany" :details="participantsInfo" title="Deelnemers" />

      <!-- Pricing info -->
      <recess-divider />
      <details-display-component :details="pricingInfo" title="Financieel" />

      <!-- Approver info -->
      <recess-divider />
      <details-display-component :details="approvalInfo" title="Goedkeuring" />

      <!-- Action buttons -->
      <recess-divider />
      <section class="d-flex justify-content-between flex-column flex-md-row">
        <recess-button
          title="Terug"
          data-test="back-button"
          variant="tertiary"
          class="mb-3 mb-md-0"
          @click="back"
        />
        <div>
          <project-request-cancellation
            v-if="$isAllowed('cancelProjectRequest', projectRequest) && isDetailsPage"
            :project-request="projectRequest"
            @submit="back"
          />
          <project-request-approval
            v-if="$isAllowed('approveProjectRequest', projectRequest) && isApprovalPage"
            :project-request="projectRequest"
            :approval-form-data="formFields"
            :validate-approval="validateForm"
            @submit="back"
            @error="(isError) => (isApprovalError = isError)"
          />
        </div>
      </section>
    </template>

    <template v-if="isFetchingDetailsError || isApprovalError">
      <recess-divider />
      <recess-alert
        type="error"
        text="Er is een fout opgetreden. Probeer het later opnieuw."
      />
    </template>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { required } from 'vuelidate/lib/validators'
import ProjectRequestApproval from '../ProjectRequestApproval/ProjectRequestApproval.vue'
import useRoute from '../../../../composables/useRoute'
import useRouter from '../../../../composables/useRouter'
import useProjectRequestDetails from '../../../../composables/useProjectRequestDetails'
import DetailsDisplayComponent from '../../../molecules/DetailsDisplayComponent/DetailsDisplayComponent.vue'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import projectRequestPerimeter from '../../../../perimeters/projectRequestPerimeter'
import ProjectRequestCancellation from '../ProjectRequestCancellation/ProjectRequestCancellation.vue'

export default {
    components: {
        DetailsDisplayComponent,
        LoaderComponent,
        ProjectRequestCancellation,
        ProjectRequestApproval
    },
    perimeters: [projectRequestPerimeter],
    props: {
        pageTitle: {
            type: String,
            default: ''
        },
        showActionButtons: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const isApprovalError = ref(false)
        const route = useRoute()
        const router = useRouter()

        const isApprovalPage = computed(() => {
            return route.value?.name === 'project-request-approval'
        })

        const {
            getProjectRequestById,
            userInfo,
            projectInfo,
            providerInfo,
            projectRequest,
            participantsInfo,
            pricingInfo,
            approvalInfo,
            isFetchingProjectRequest,
            isFetchingDetailsError,
            isTypeIncompany,
            formFields,
            validateForm
        } = useProjectRequestDetails(isApprovalPage.value)

        const projectRequestId = computed(() => {
            return route.value.params?.id
        })

        const isDetailsPage = computed(() => {
            return route.value?.name === 'project-request-detail'
        })

        const listingView = computed(() => {
            return route.value.query?.listingView
        })

        const back = () => {
            router.push({
                name: 'my-project-requests',
                query: {
                    'project-requests-tab': listingView.value
                }
            })
        }

        onMounted(async () => {
            await getProjectRequestById(projectRequestId.value)
        })

        return {
            userInfo,
            projectInfo,
            providerInfo,
            participantsInfo,
            pricingInfo,
            approvalInfo,
            isFetchingProjectRequest,
            isFetchingDetailsError,
            listingView,
            projectRequest,
            isApprovalPage,
            isDetailsPage,
            isApprovalError,
            isTypeIncompany,
            formFields,
            back,
            validateForm
        }
    },
    validations() {
        return {
            formFields: {
                purchaseOrderNumber: { required },
                costCenter: { required }
            }
        }
    }
}
</script>


import { createSandbox } from 'vue-kindergarten'
import { mapPerimeterChilds } from '../utils/MapUtils'
import store from '../store'
 
/**
 * Create perimeter sandbox object
 * 
 * @param {import('../models').Perimeter[]} perimeters  
 * @returns {import('../models').Sandbox} 
 */
function sandboxFactory(perimeters) {
    return createSandbox(mapPerimeterChilds(store), {
        perimeters: [
            ...perimeters
        ]
    })
}

export default {
    sandboxFactory
}
<template>
  <div></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    computed: {
        ...mapGetters('userModule', ['legalAgreementAcceptedDate']),
        ...mapState('userModule', ['isUserFetched'])
    },
    watch: {
        isUserFetched: {
            handler(newValue) {
                if (newValue && !this.legalAgreementAcceptedDate) {
                    this.$router.replace({ name: 'legal-agreements' })
                }
            },
            immediate: true
        }
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div
    class="c-texttoggle"
    :class="{ 'is-open': isOpen }"
  >
    <div
      class="c-texttoggle__title"
      data-test="text-toggle-title"
      @click="toggle"
    >
      {{ title }}
    </div>
    <transition name="slide-fade">
      <div
        v-if="isOpen"
        class="c-texttoggle__text"
        data-test="text-toggle-content"
        @click="toggle"
        v-html="text"
      ></div>
    </transition>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        openedByDefault: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: this.openedByDefault
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>

import { getOplzClient } from './apiClient'

/** @typedef {import('../models').UserFeatureSettings} UserFeatureSettings */
/** @typedef {import('../models').UserFeatureSettingsRequest} UserFeatureSettingsRequest */
/** @typedef {import('../models').PatchRequest} PatchRequest */

const baseUrl = '/user-feature-settings'

/**
 * Retrieve user feature settings
 * 
 * @param {number} id 
 * @returns {Promise<UserFeatureSettings>}} 
 */
const get = async id => {
    return (await getOplzClient())
        .get(`${baseUrl}/${id}`)
        .then(res => res.data)
}

/**
 * Post user feature settings
 * 
 * @param {UserFeatureSettingsRequest} userFeatureSettingsRequest 
 * @returns {Promise<UserFeatureSettings>}} 
 */
const post = async userFeatureSettingsRequest => {
    return (await getOplzClient())
        .post(baseUrl, userFeatureSettingsRequest)
        .then(res => res?.data)
}

/**
 * Patch user feature settings
 * 
 * @param {number} id 
 * @param {PatchRequest[]} patchRequest
 * @returns {Promise<UserFeatureSettings>}} 
 */
const patch = async (id, patchRequest) => {
    return (await getOplzClient())
        .patch(`${baseUrl}/${id}`, patchRequest)
        .then(res => res?.data)
}

export default {
    get,
    post,
    patch
}
<template>
  <div>
    <recess-modal
      :show-modal="showCancelEnrollmentModal"
      @close="$emit('close')"
    >
      <template slot="modal-title">
        <h3>Inschrijving annuleren</h3>
      </template>
      <template slot="modal-body">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isLoading"
            overlay="white"
            color="primary"
            is-fixed
          />
        </transition>
        <p>Geef een verplichte reden op.</p>
        <recess-select
          v-model="cancellationReasonCode"
          :default-value="cancellationReasonCode"
          :error-message="requiredErrorMessage"
          label-text="Reden voor annulering *"
          :options="cancellationReasons"
          :prefill-with-first-option=false
        />
        <div class="u-textcolor-red mt-3">
          <h6 class="mb-0">Let op!</h6>
          <p data-test="post-enrollment-cancellation-warning-text">{{ postEnrollmentCancellationWarningText }}</p>
        </div>
      </template>
      <div
        slot="modal-footer"
        class="d-flex flex-column"
      >
        <div>
          <recess-button
            :disabled="isLoading"
            class="u-position-right col-12 col-md-auto"
            title="Bevestigen"
            variant="primary"
            data-test="confirm-btn"
            @click="confirmCancel"
          />
        </div>
        <recess-alert
          v-if="showErrorMessage"
          type="error"
          class="col-12 mt-3"
          text="Er is iets misgegaan. Probeer het later nog een keer of neem contact op met een administrator."
        />
      </div>
    </recess-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import enrollmentClient from '../../../api/enrollmentClient'

export default {
    components: {
        LoaderComponent
    },
    props: {
        showCancelEnrollmentModal: {
            type: Boolean,
            default: false
        },
        enrollmentId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            cancellationReasonCode: null,
            cancellationReasons: [],
            showErrorMessage: false,
            isFetchingCancellationReasons: false,
            isCancellingEnrollment: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['postEnrollmentCancellationWarningText']),
        isLoading() {
            return this.isFetchingCancellationReasons || this.isCancellingEnrollment
        },
        requiredErrorMessage() {
            if (this.$v.cancellationReasonCode.$error) return 'Reden voor afkeuring is verplicht'
            return null
        }
    },
    validations: {
        cancellationReasonCode: { required }
    },
    created() {
        this.fetchCancellationReasons()
    },
    methods: {
        fetchCancellationReasons() {
            this.isFetchingCancellationReasons = true
            enrollmentClient
                .getCancellationReasons()
                .then(response => {
                    if (!response) {
                        this.showErrorMessage = true
                        return
                    }

                    this.cancellationReasons = response
                        .filter(e => e.canUseForFirstApproval || e.canUseForSecondApproval)
                        .map(rejectionReason => {
                            return {
                                value: rejectionReason.codeName,
                                displayText: rejectionReason.displayValue
                            }
                        })
                })
                .catch(err => {
                    console.error(err)
                    this.showErrorMessage = true
                })
                .then(() => {
                    this.isFetchingCancellationReasons = false
                })
        },
        async confirmCancel() {
            try {
                this.isCancellingEnrollment = true
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                const request = {
                    enrollmentId: this.enrollmentId,
                    cancelReason: this.cancellationReasonCode
                }
               
                await enrollmentClient.cancelEnrollment(request)
                  
                this.$emit('close')
                this.$router.push({ name: 'my-team', query: this.$route.query })
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isCancellingEnrollment = false
            }
        }
    }
}
</script>

import BasePerimeter from './BasePerimeter'
import * as enrollmentStatus from '../constants/enrollmentStatus'
import orderStatus from '../constants/orderStatus'

export default new BasePerimeter({
    purpose: 'enrollmentAuthorization',

    can: {
        approveEnrollmentAsFirstApprover(enrollment) {
            return (
                enrollment.user &&
                enrollment.statusId === enrollmentStatus.PENDINGAPPROVAL &&
                ((this.isTeamLeaderOfCompany(enrollment.user.companyId) &&
                this.isTeamLeader()) || 
                this.enrollmentIsDelegatedToUserAsFirstApprover(enrollment.enrollmentId)
                )
            )
        },
        approveEnrollmentAsSecondApprover(enrollment) {
            return (
                enrollment.user &&
                enrollment.statusId === enrollmentStatus.PENDINGSECONDAPPROVAL &&
                this.isSameAccount(enrollment.user.accountId) &&
                ((this.isSecondApprover() &&
                (this.isAssignedSecondApprover(enrollment.assignedSecondApproverId) ||
                    this.child?.accountSettings?.secondApprovalAccountWide))
                || 
                (this.enrollmentIsDelegatedToUserAsSecondApprover(enrollment.enrollmentId)))
            )
        },
        verifyEnrollment(enrollment) {
            return (
                enrollment.user &&
                enrollment.statusId === enrollmentStatus.PENDINGSTUDENTVERIFICATION &&
                this.isOwnEnrollment(enrollment.user.userId)
            )
        },
        cancelEnrollment(enrollment) {
            return (
                enrollment.user &&
                (enrollment.statusId !== enrollmentStatus.CANCELLED &&
                    enrollment.statusId !== enrollmentStatus.REJECTEDEMPLOYER &&
                    enrollment.statusId !== enrollmentStatus.REJECTEDEMPLOYEE &&
                    enrollment.statusId !== enrollmentStatus.DECLINEDUNVERIFIED &&
                    enrollment.statusId !== enrollmentStatus.CANCELED_VERIFICATION_TIME_OVERDUE) &&
                this.isSameAccount(enrollment.user.accountId) &&
                (this.isCoordinator() || (this.isTeamLeader() && this.isTeamLeaderOfCompany(enrollment.user.companyId)))
            )
        },
        updateStartmoments(enrollment) {
            return (
                enrollment && 
                enrollment.user &&
                enrollment.eduMSOrderStatus &&
                enrollment.isStartmomentUpdatesEnabled &&
                (enrollment.eduMSOrderStatus === orderStatus.PendingAccount.code ||
                    enrollment.eduMSOrderStatus === orderStatus.PendingProvider.code) &&
                this.isOwnEnrollment(enrollment.user.userId)
            )
        }
    }
})

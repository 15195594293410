import { ref, computed, readonly } from 'vue'
import StringUtils from '../utils/StringUtils'
import socialLearningClient from '../api/socialLearningClient'
import { mapFileUploadsToRequests } from '../utils/MapUtils'
import { allowedAttachmentExtensions  } from '../constants/socialLearningPostAllowedExtensions'

export default function useUploadAttachment(context) {
    const uploadFileInput = ref(null)
    const isAttachmentRemovalConfirmationPopupVisible = ref(false)
    const deleteAttachmentIndex = ref(undefined)
    const attachments = ref([])
    const documentsToUpload = ref([])
    const isMaxFilesLimitReached = ref(false)
    const maxFilesPerPost = 5

    const maxFilesUploadErrorText = computed(() => {
        return `Het maximum van ${maxFilesPerPost} uploads is bereikt.`
    }) 

    const setAttachments = value => {
        attachments.value = value
    }
    const setDeleteAttachmentIndex = value => {
        deleteAttachmentIndex.value = value
    }
    const setIsAttachmentRemovalConfirmationPopupVisible = value => {
        isAttachmentRemovalConfirmationPopupVisible.value = value
    }
    const setIsMaxFilesLimitReached = value => {
        isMaxFilesLimitReached.value = value
    }

    const hasDuplicatesAttachmentFileName = fileName => {
        return attachments.value?.some(attachment => StringUtils.removeDateTime(attachment.description) === fileName)
    }
    const hasDuplicatedDocumentsToUploadFileName = fileName => {
        const existingDocumentsToUpload = documentsToUpload.value?.filter(f => f.fileName === fileName)

        return existingDocumentsToUpload?.length > 1 && !existingDocumentsToUpload?.every(f => f.hasDuplicatedFileName)
    }
    const hasDuplicatedFileName = fileName => {
        return hasDuplicatesAttachmentFileName(fileName) || hasDuplicatedDocumentsToUploadFileName(fileName) 
    }
    const verifyAndResetMaxFilesLimitReached = () => {
        if(attachments.value?.length + documentsToUpload.value?.length <= maxFilesPerPost) {
            setIsMaxFilesLimitReached(false)
        }
    }
    const updateDuplicatedFileNames = fileName => {
        const fileNameWithoutDateTime = StringUtils.removeDateTime(fileName)

        documentsToUpload.value?.forEach(f => {
            if (f.fileName === fileNameWithoutDateTime) f.hasDuplicatedFileName = hasDuplicatedFileName(f.fileName)
        })
    }
    const removeUpload = (fileName, documentIndex) => {
        documentsToUpload.value = documentsToUpload.value?.filter((_, index) => index !== documentIndex)

        updateDuplicatedFileNames(fileName)
        verifyAndResetMaxFilesLimitReached()
    }

    const onClickDeleteAttachment = attachmentIndex => {
        setDeleteAttachmentIndex(attachmentIndex)
        setIsAttachmentRemovalConfirmationPopupVisible(true)
    }
    const deleteAttachment = () => {
        const attachmentToDelete = attachments.value?.find((_, index) => index === deleteAttachmentIndex.value)
        const filteredAttachments = attachments.value?.filter((_, index) => index !== deleteAttachmentIndex.value)

        setIsAttachmentRemovalConfirmationPopupVisible(false)
        setAttachments(filteredAttachments)
        setDeleteAttachmentIndex(undefined)

        verifyAndResetMaxFilesLimitReached()
        updateDuplicatedFileNames(attachmentToDelete.description)
    }      

    const getUploadedAttachments = async () => {
        try {
            context.emit("is-uploading", true)
            
            const uploadedAttachments = await socialLearningClient.uploadAttachments(documentsToUpload.value)

            return uploadedAttachments?.map(({fileName, url}) => {
                return {
                    description: fileName,
                    url
                }
            })
        } catch(error) {
            throw new Error(`Failed to upload attachments with error: ${error}`)
        } finally {
            context.emit("is-uploading", false)
        }
    }
    const setDocumentsToUpload = files => {        
        if(!files?.length) documentsToUpload.value = []                

        setIsMaxFilesLimitReached(false)

        if(uploadFileInput.value) uploadFileInput.value.selectedFiles = []

        if(attachments.value?.length + documentsToUpload.value?.length + files.length > maxFilesPerPost) {
            setIsMaxFilesLimitReached(true)
            return
        }

        const docsToUpload = mapFileUploadsToRequests(files)

        docsToUpload.forEach(f => {
            f.fileName = StringUtils.removeDateTime(f.fileName)
            documentsToUpload.value.push(f)
            f.hasDuplicatedFileName = hasDuplicatedFileName(f.fileName)
        })
    }

    return {
        isAttachmentRemovalConfirmationPopupVisible: readonly(isAttachmentRemovalConfirmationPopupVisible),
        maxFilesUploadErrorText: readonly(maxFilesUploadErrorText),
        attachments,
        allowedAttachmentExtensions,
        documentsToUpload,
        uploadFileInput,
        isMaxFilesLimitReached,
        setDocumentsToUpload,
        getUploadedAttachments,
        setAttachments,
        onClickDeleteAttachment,
        setIsAttachmentRemovalConfirmationPopupVisible,
        removeUpload,
        deleteAttachment
    }
}
<template>
  <div>
    <span class="font-weight-bold">Delen van ontwikkelresultaten met werk- of opdrachtgever (indien aanwezig)</span>
  
    <learning-result-sharing-team-leader-form 
      :learning-result-sharing-type="learningResultSharingType"
      @selected-learning-result-options="value => setSelectedLearningResultOptions(value)" 
      @sharing-type-selected="value => setLearningResultSharingType(value)"
      @toggle-learning-result-option="({optionKey, isOptionChecked}) => toggleSelectedLearningResultOption(optionKey, isOptionChecked)" 
    />
    
    <enrollment-step-buttons  
      v-on="{ ...$listeners, 'next-step': confirmStep }" 
    />
  </div>
</template>
  
<script>
import { ref } from 'vue'
import learningResultSharingTypeEnum from '../../../../../constants/learningResultSharingTypeEnum'
import EnrollmentStepButtons from '../EnrollmentStepButtons.vue'
import LearningResultSharingTeamLeaderForm from '../../../LearningResultSharing/LearningResultSharingTeamLeaderForm.vue'
import useStore from '../../../../../composables/useStore'
  
export default {
    components: {
        EnrollmentStepButtons,
        LearningResultSharingTeamLeaderForm
    },
    emits: ['next-step'],
    setup(_, { emit }) {
        const store = useStore()

        const learningResultSharingType = ref(null)

        const setLearningResultSharingType = value =>  {
            learningResultSharingType.value = value
            store.commit('enrollmentModule/setLearningResultSharingType', value)
        }
        const setSelectedLearningResultOptions = value => store.commit('enrollmentModule/setSelectedLearningResultOptions', value)
        const toggleSelectedLearningResultOption = (optionKey, isOptionChecked) => store.commit('enrollmentModule/toggleSelectedLearningResultOption', {optionKey, isOptionChecked})

        const confirmStep = () => {
            // Set learning result sharing type to not required in case no options is selected when learningResultSharingType is Employer
            if (learningResultSharingType.value === learningResultSharingTypeEnum.Employer 
            && Object.keys(store.state.enrollmentModule.selectedLearningResultOptions)
                ?.every(key => !store.state.enrollmentModule.selectedLearningResultOptions?.[key])) {
                learningResultSharingType.value = learningResultSharingTypeEnum.None
                store.commit('enrollmentModule/setLearningResultSharingType', learningResultSharingType.value)
            }

            emit('next-step')
        }
  
        return {
            setLearningResultSharingType,
            setSelectedLearningResultOptions,
            toggleSelectedLearningResultOption,
            confirmStep,
            learningResultSharingType
        }
    }
}
</script>
<template>
  <div>
    <h4 class="font-weight-bold" data-test="check-list-title">{{ title }}</h4>
    <div v-for="(listItem,index) in checkListItems" :key="index" class="d-flex align-items-center mb-2">
          
      <recess-icon 
        icon="check"
        color="green"
        :height="17"
        :width="17"
      />
      <span class="u-text-small" data-test="check-list-item">{{listItem}}</span>
          
    </div>
  </div>    
</template>

<script>

export default {
    name: 'CheckList',
    props: {
        checkListItems: {
            type: Array,
            default: () => {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        }
    } 
}
</script>

import projectRequestStatus from '../constants/projectRequestStatus'
import BasePerimeter from './BasePerimeter'
import featuresPerimeter from './featuresPerimeter'

export default new BasePerimeter({
    purpose: 'projectRequests',

    can: {
        createProjectRequest() {
            return this.isProjectInitiator() && !featuresPerimeter.isProjectRequestDisabled()
        },

        approveProjectRequest(projectRequest) {
            return (
                projectRequest?.assignedApprover?.id &&
                projectRequest?.status?.id === projectRequestStatus.PENDING_APPROVAL &&
                (
                    this.isAssignedProjectApprover(projectRequest?.assignedApprover?.id) &&
                    this.isProjectApprover()
                )
            )
        },

        cancelProjectRequest(projectRequest) {
            const validStatuses = [projectRequestStatus.PENDING_APPROVAL, projectRequestStatus.READY_FOR_ORDER]

            return (
                this.isProjectInitiator() && 
                projectRequest?.initiator?.id === this.child.user?.userId && 
                validStatuses?.includes(projectRequest?.status?.id)
            )
        }
    }
})

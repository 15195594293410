<template>
  <div>
    <recess-modal
      :show-modal="showRecommendCourseModal"
      @close="$emit('close')"
    >
      <template #modal-title>
        <h3>{{ courseName }} aanbevelen</h3>
      </template>
      <template #modal-body>
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isLoading"
            is-fixed
            overlay="white"
            color="primary"
          />
        </transition>
      
        <p>
          Beveel deze ontwikkelactiviteit aan aan uw collega.
        </p>

        <div class="row">
          <div class="col-12 col-md-6">
            <recess-autocomplete
              v-model="selectedUser"
              label-text="Collega"
              placeholder="Naam of e-mail"
              item-key="userId"
              item-text="emailAddress"
              item-sub-text="fullName"
              data-test="search-user-input"
              :error-message="!$v.selectedUser.isMatchingUserEmail && selectedUser.length > 3 ? 'Kies een werknemer uit de lijst of laat het veld leeg' :''"
              :on-query="queryUser"
            />
          </div>
        </div>
      </template>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <recess-button
            :disabled="!selectedUser || $v.$invalid"
            data-test="submit-button"
            variant="primary"
            title="Versturen"
            @click="sendNotifications()"
          />
        </div>
        <recess-alert
          v-if="showErrorMessage"
          type="error"
          text="Er is een fout opgetreden. Probeer het later opnieuw."
          class="mt-4"
        />
      </template>
    </recess-modal>
  
    <recess-modal
      modal-size="small"
      :show-modal="showConfirmationMessage"
      @close="closeModal()"
    >
      <template #modal-body>
        Uw aanbeveling is verzonden naar {{ selectedUserFullName }}.
      </template>

      <div
        slot="modal-footer"
        class="d-flex justify-content-center"
      >
        <recess-button
          class="qa-close-modal-button"
          variant="primary"
          title="Sluit venster"
          @click="closeModal()"
        />
      </div>
    </recess-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import courseClient from '../../../api/courseClient'
import userClient from '../../../api/userClient'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import * as shareCourseScopes from '../../../constants/shareCourseScopes'

export default {
    components: { LoaderComponent },
    props: {
        showRecommendCourseModal: {
            type: Boolean,
            default: false
        },
        courseName: {
            type: String,
            default: null
        },
        courseId: {
            type: String,
            default: null
        },
        assortmentId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showConfirmationMessage:false,
            selectedUser: null,
            usersInAccount: [],
            showErrorMessage: false,
            isLoading: false
        }
    },
    validations() {
        return {
            selectedUser: { 
                isMatchingUserEmail() {
                    if (!this.selectedUser) return true
                    return this.isMatchingUserEmail
                }}
        }

    },
    computed:  {
        ...mapState('userModule', ['user']),
        ...mapGetters('accountModule', ['shareCourseScope']),
        isMatchingUserEmail() {
            return this.usersInAccount?.some(x => x.emailAddress === this.selectedUser)
        },
        selectedUserFullName() {
            const matchedUser = this.usersInAccount?.find(x => x.emailAddress === this.selectedUser)
            return matchedUser?.fullName
        }
    },
    methods: {
        async sendNotifications() {
            try {
                this.showErrorMessage = false
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                this.isLoading = true
                const recipientUser = this.usersInAccount?.find(user => user.emailAddress === this.selectedUser)
                if (!this.courseId || !recipientUser) return

                const request = {
                    courseName: this.courseName,
                    recipientUserId: recipientUser.userId,
                    assortmentId: this.assortmentId
                }

                const response = await courseClient.recommendCourse(this.courseId, request)

                if(response) {
                    this.$emit('close')
                    this.showConfirmationMessage = true
                } else {
                    this.showErrorMessage = true
                }
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
           
        },
        closeModal() {
            this.selectedUser = null
            this.usersInAccount = []
            this.showConfirmationMessage = false
        },
        async queryUser(query) {
            if (!query) return []

            // Cancel previous pending request
            userClient.cancelFetchSearch()

            const filters = [
                {
                    keys: ['firstName', 'middleName', 'lastName', 'emailAddress'],
                    operator: ODataFilterOperations.CONTAINS,
                    value: query,
                    keyCombination: 'or'
                },
                {
                    keys: 'emailAddress',
                    operator: ODataFilterOperations.NOTEQUALS,
                    value: this.user.emailAddress
                },
                {
                    keys: 'isEnabled',
                    operator: ODataFilterOperations.EQUALS,
                    value: true
                }
            ]

            // Add filters depending on share course scope setting
            if(this.shareCourseScope === shareCourseScopes.COMPANYWIDE) {
                filters.push({
                    keys: 'companyId',
                    operator: ODataFilterOperations.EQUALS,
                    value: this.user.companyId
                })
            } else if(this.shareCourseScope === shareCourseScopes.ACCOUNTWIDE) {
                filters.push({
                    keys: 'accountId',
                    operator: ODataFilterOperations.EQUALS,
                    value: this.user.accountId
                })
            }

            const oDataFilters = filters.map(f => {
                return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
            })

            const dataQuery = new ODataQueryBuilder()
                .setSort('firstName', 'asc')
                .setPagination(0, 9999)
                .addFilters(oDataFilters)
                .build()

            try {
                const response = await userClient.searchUsersBasicInfo(dataQuery)
                this.usersInAccount = response?.items ?? []
                return this.usersInAccount
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    console.error(error)
                }
                return []
            }
        }
    }
}
</script>
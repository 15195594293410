<template>
  <div v-if="facets && facets.length">
    <div class="d-flex flex-column flex-md-row"> 
      <facet-filter
        v-for="facet in facets"
        :key="facet.key"
        :facet="facet"
        :selected-facets="selectedFacets"
        class="w-100 mr-md-4 mb-4 mb-md-0"
        @checked="(facet, isChecked, facetKey) => toggleFacet(facet, isChecked, facetKey)"
      />
    </div>
  
    <recess-divider variant="xsmall" />

    <selected-facet-filters 
      :key="selectedFacetsCount"
      :selected-facets="selectedFacets"
      @reset="resetFacets"
      @remove-facet="(facet, facetKey) => removeFacet(facet, facetKey)"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import FacetFilter from './FacetFilter.vue'
import SelectedFacetFilters from './SelectedFacetFilters.vue'

export default {
    components: {
        FacetFilter,
        SelectedFacetFilters
    },
    props: {
        facets: {
            type: Array,
            default: () => []
        }
    },
    setup(_, { emit }) {
        const selectedFacets = ref({})
        const selectedFacetsCount = ref(0)

        const removeFacet = (facetValue, facetKey) => {
            selectedFacets.value[facetKey] = selectedFacets.value?.[facetKey]?.filter(sf => sf !== facetValue)
            selectedFacetsCount.value -= 1 
            
            emit('facet-change', selectedFacets.value)
        }
        const toggleFacet = (facetValue, isChecked, facetKey) => {
            if (isChecked) {

                if (selectedFacets.value[facetKey]) selectedFacets.value[facetKey].push(facetValue)
                else selectedFacets.value[facetKey] = [facetValue]

                selectedFacetsCount.value += 1
            } else {
                selectedFacets.value[facetKey] = selectedFacets.value?.[facetKey]?.filter(sf => sf.key !== facetValue.key)

                if(!selectedFacets.value[facetKey]?.length) delete selectedFacets.value[facetKey]

                selectedFacetsCount.value -= 1
            }
           
            emit('facet-change', selectedFacets.value)
        }
        const resetFacets = () => {
            selectedFacets.value = {}

            emit('facet-change', selectedFacets.value)
        }

        return {
            selectedFacets,
            toggleFacet,
            removeFacet,
            resetFacets,
            selectedFacetsCount
        }
    }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('recess-divider',{attrs:{"display-medium-viewport":false,"display-small-viewport":false}}),(_vm.isLoading)?_c('loader-component',{attrs:{"overlay":"white","color":"primary"}}):_vm._e(),_c('p',{staticClass:"p-0 pl-md-4 col-12 col-md-8",attrs:{"data-test":"explanation-text"}},[_vm._v(" "+_vm._s(_vm.explanationText)+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.activeCourses && _vm.activeCourses.length)?_c('div',[_c('recess-table',[_c('recess-table-head',[_c('recess-table-row',[_c('recess-table-head-item',{staticClass:"flex-grow-2"},[_vm._v(" Ontwikkelactiviteit ")]),_c('recess-table-head-item',[_vm._v("Startdatum")]),_c('recess-table-head-item',[_vm._v("Status")]),(_vm.$isAllowed('displayCompletedColumn'))?_c('recess-table-head-item',{staticClass:"flex-shrink-2"},[_vm._v(" Voltooid ")]):_vm._e(),_c('recess-table-head-item',[_vm._v(" Beoordeel de ontwikkelactiviteit ")]),_c('recess-table-head-item',{staticClass:"flex-grow-2"})],1)],1),_c('recess-table-body',_vm._l((_vm.activeCourses),function(activeCourse,index){return _c('recess-table-row',{key:index,attrs:{"data-test":activeCourse.eLearningSubscriptionId
                                ? `eLearningSubscription-${activeCourse.eduMSCourseId}`
                                : undefined}},[_c('recess-table-cell',{staticClass:"flex-grow-2",attrs:{"data-label":"Ontwikkelactiviteit"}},[(!activeCourse.deletedFromAssortment)?_c('router-link',{attrs:{"to":{
                                    name: 'course',
                                    query: {
                                        course: activeCourse.eduMSCourseId,
                                        assortment: activeCourse.assortmentId
                                    }
                                }}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getCourseNameAndProvider(activeCourse))}})]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.getCourseNameAndProvider(activeCourse))}})],1),_c('recess-table-cell',{attrs:{"data-label":"Startdatum"}},[_vm._v(" "+_vm._s(_vm.format(activeCourse.acceptedStartDate))+" "+_vm._s(activeCourse.location ? `(${activeCourse.location})` : '')+" ")]),_c('recess-table-cell',{attrs:{"data-label":"Status"}},[_vm._v(" "+_vm._s(_vm.getStatusDisplayValue(activeCourse))+" ")]),(_vm.$isAllowed('displayCompletedColumn'))?_c('recess-table-cell',{staticClass:"d-flex justify-content-between flex-shrink-2",attrs:{"data-label":"Voltooid"}},[(!activeCourse.completedByFulfillment)?_c(!activeCourse.isAllowedToToggleComplete
                                        ? 'recess-tooltip'
                                        : 'div',{tag:"component",staticClass:"u-position-relative",scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v(" U kunt uw ontwikkelactiviteit alleen op status afgerond zetten wanneer de startdatum + de theoretische duur van de ontwikkelactiviteit in het verleden liggen. ")])]},proxy:true}],null,true)},[_c('recess-toggle-input',{attrs:{"data-test":"complete-toggle","disabled":!activeCourse.isAllowedToToggleComplete},on:{"input":(val) => _vm.toggleCompletion(activeCourse, index)},model:{value:(activeCourse.completedByStudent),callback:function ($$v) {_vm.$set(activeCourse, "completedByStudent", $$v)},expression:"activeCourse.completedByStudent"}}),(_vm.isTogglingCompletion[index])?_c('loader-component',{attrs:{"variant":"small","color":"primary"}}):_vm._e()],1):_c('div',{staticClass:"d-flex"},[_c('recess-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Gevalideerd ")]},proxy:true}],null,true)},[_c('span',{attrs:{"data-test":"completed-text"}},[_vm._v(" Ja ")]),_c('recess-icon',{attrs:{"icon":"check"}})],1)],1)],1):_vm._e(),_c('recess-table-cell',[_c('star-rating',{class:[
                                    'd-none d-lg-block',
                                    {
                                        'u-cursor-not-allowed':
                                            activeCourse.deletedFromAssortment
                                    }
                                ],attrs:{"increment":0.5,"show-rating":false,"star-size":22,"read-only":activeCourse.deletedFromAssortment,"rating":activeCourse.userRating && activeCourse.userRating.rating
                                        ? (activeCourse.userRating.rating / 100) * 5
                                        : 0,"active-color":"RGB(var(--r-color-secondary))"},on:{"rating-selected":(rating) => _vm.changeRating(activeCourse, rating * 10 * 2)}}),_c('star-rating',{staticClass:"d-lg-none d-flex justify-content-center justify-content-md-end",attrs:{"inline":true,"max-rating":10,"show-rating":false,"read-only":activeCourse.deletedFromAssortment,"star-size":28,"rating":activeCourse.userRating && activeCourse.userRating.rating
                                        ? activeCourse.userRating.rating / 10
                                        : 0,"active-color":"RGB(var(--r-color-secondary))"},on:{"rating-selected":(rating) => _vm.changeRating(activeCourse, rating * 10)}})],1),_c('recess-table-cell',{staticClass:"d-flex align-items-start flex-grow-2"},[_c('div',{staticClass:"d-flex table-options-flex-column"},[(activeCourse.oleUrl)?_c('recess-button',{attrs:{"url":activeCourse.oleUrl,"title":"Naar LMS","target":"_blank","variant":"secondary","data-test":"options-ole-url"}}):_vm._e(),(
                                        _vm.$isAllowed('displayPERemindersFeature') &&
                                        !_vm.$isAllowed('displayLearningResult') &&
                                        activeCourse.isAllowedToToggleComplete
                                    )?_c('recess-button',{attrs:{"title":"+ Leerresultaat","variant":"tertiary","data-test":"options-learning-result"},on:{"click":function($event){return _vm.navigateToLearningResult(activeCourse.courseName)}}}):_vm._e()],1),(activeCourse.eLearningSubscriptionId)?_c('recess-icon',{staticClass:"pl-2",attrs:{"data-test":"options-delete","icon":"bin"},on:{"click":function($event){return _vm.removeELearningFromActiveCourses(
                                        activeCourse.eLearningSubscriptionId,
                                        index
                                    )}}}):_vm._e()],1)],1)}),1)],1)],1):_vm._e()]),(_vm.showNoActiveCourseMessage)?_c('recess-alert',{attrs:{"text":"Er zijn op dit moment geen gestarte ontwikkelactiviteiten."}}):_vm._e(),(_vm.showErrorMessage)?_c('recess-alert',{attrs:{"type":"error","text":"Er is een fout opgetreden. Probeer het later opnieuw."}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
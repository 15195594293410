<template>
  <div>
    <recess-dropdown data-test="user-menu">
      <template #dropdown-user-icon>
        <img
          alt="Profile icon"
          :src="profileIcon"
        />
      </template>

      <template #dropdown-title>
        <h5
          v-show="user.fullName"
          class="c-header__user-name"
        >
          {{ user.fullName }}
          <span
            v-if="user.company && user.company.name && user.company.name != '-'"
            class="fw-regular u-textcolor-blue-grey"
          >({{ user.company.name }})</span>
        </h5>
      </template>
      <template #dropdown-content>
        <router-link
          :to="{ name: 'profile-page' }"
          class="c-side-navigation__link"
        >
          Mijn profiel
        </router-link>

        <recess-divider
          v-if="$isAllowed('displayBudgets')"
          show-line
        />

        <router-link
          v-if="$isAllowed('displayBudgets')"
          :to="{ name: 'my-budgets' }"
          class="c-side-navigation__link"
        >
          Mijn budgetten
        </router-link>

        <recess-divider
          show-line
        />

        <router-link
          :to="{ name: 'my-development', query: { 'my-development-tab': myDevelopmentTabs.WISHLIST.key } }"
          class="c-side-navigation__link"
        >
          {{ myDevelopmentTabs.WISHLIST.displayValue }}
        </router-link>

        <template v-if="sharedIds && sharedIds.length > 1">
          <recess-divider
            show-line
          />
          <a
            class="c-side-navigation__link u-cursor-pointer"
            @click.prevent="showAccountSelection = true"
          >
            Wissel van account
          </a>
        </template>

        <recess-divider
          v-if="$isAllowed('displayChangePasswordLink')"
          show-line
        />

        <router-link
          v-if="$isAllowed('displayChangePasswordLink')"
          :to="{ name: 'forgot-password' }"
          class="c-side-navigation__link"
        >
          Wachtwoord wijzigen
        </router-link>

        <recess-divider
          v-if="$isAllowed('displayLogoutLink')"
          show-line
        />

        <logout-component
          v-if="$isAllowed('displayLogoutLink')"
          class="c-side-navigation__link"
        />
      </template>
    </recess-dropdown>
    <account-selection-component
      :is-visible="showAccountSelection"
      show-close-button
      @close="showAccountSelection = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import profileIcon from '../../../../assets/images/icons/icon-profile.png'
import LogoutComponent from '../../../atoms/LogoutComponent/LogoutComponent.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import { myDevelopmentTabs } from '../../../../constants/tabNames'
import AccountSelectionComponent from '../../../molecules/AccountSelectionComponent/AccountSelectionComponent.vue'

export default {
    perimeters: [featuresPerimeter],
    components: { LogoutComponent, AccountSelectionComponent },
    data() {
        return {
            profileIcon,
            myDevelopmentTabs,
            showAccountSelection: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapGetters('userModule', ['sharedIds'])
    }
}
</script>

<template>
  <div>
    <transition name="fade">
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        :is-fixed="true"
      />
    </transition>

    <a
      class="u-cursor-pointer u-textcolor-red"
      @click="logoutAction"
    >Logout</a>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LoaderComponent from '../LoaderComponent/LoaderComponent.vue'
import { getUserManager } from '../../../modules/oidc-client'
import { impersonateSignoutAndRedirect } from '../../../api/AuthenticationClient'

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        ...mapGetters('userModule', ['isUserImpersonating', 'idServerToken']),
        ...mapGetters('oplzConfigurationModule', ['oplzAdminUrl']),
        ...mapGetters('configurationModule', ['oidcConfig'])
    },
    created() {},
    methods: {
        async logoutAction() {
            this.isLoading = true

            try {
                if (this.isUserImpersonating) {
                    await this.impersonateLogout()
                } else {
                    await this.logoutSession()
                }
            } catch (err) {
                this.isLoading = false
                console.error(err)
            }
        },
        async logoutSession() {
            await getUserManager().signoutRedirect()
            sessionStorage.removeItem('account_selected')
            this.isLoading = false
        },
        async impersonateLogout() {
            await impersonateSignoutAndRedirect(
                this.oidcConfig.authority, 
                this.idServerToken, 
                this.oplzAdminUrl)
            this.isLoading = false
        }
    }
}
</script>

export const TABLE_QUERY_PARAM_KEY = 'my-team-active-courses-tab'
export const DEFAULT_TABLE_SORT = { key: 'AcceptedStartDate', direction: 'desc' }
export const TABLE_HEADERS_CONFIG = [
    {
        key: 'user',
        sortable: true,
        sortKey: 'FirstName',
        displayValue: 'Medewerker'
    },
    {
        key: 'course',
        sortable: true,
        sortKey: 'CourseName',
        displayValue: 'Ontwikkelactiviteit'
    },
    {
        key: 'date',
        sortable: true,
        sortKey: 'AcceptedStartDate',
        displayValue: 'Startdatum'
    },
    {
        key: 'status',
        sortable: false,
        displayValue: 'Status'
    }
]

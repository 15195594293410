export default {
    HEALTHCARE: 1,
    WELFARE: 2,
    PERSONAL_CARE: 3,
    RETAIL: 4,
    BUSINESS_SERVICES: 5,
    HUMAN_RESOURCES: 6,
    FACILITY_SERVICES: 7,
    FINANCIAL_SERVICES: 8,
    DESIGN_AND_DEVELOPMENT: 9,
    INSTALLATION_REPAIR_AND_MAINTENANCE: 10,
    JUSTICE: 11,
    SECURITY_AND_DEFENSE: 12,
    PUBLIC_ADMINISTRATION: 13,
    AGRICULTURE: 14,
    NATURE: 15,
    FISHERIES: 16,
    MEDIA_AND_JOURNALISM: 17,
    COMMUNICATION_AND_MARKETING: 18,
    EDUCATION: 19,
    CULTURE: 20,
    SCIENCE: 21,
    TECHNOLOGY: 22,
    PRODUCTION: 23,
    CONSTRUCTION: 24,
    TOURISM: 25,
    RECREATION: 26,
    HOSPITALITY: 27,
    WAREHOUSE_AND_STORAGE: 28,
    POST_AND_PARCEL: 29,
    SEA_INLAND_AND_AIR_TRANSPORTATION: 30,
    ROAD_TRANSPORT: 31,
    RAILWAYS: 32
}
import * as sanitizeHtml from 'sanitize-html'

export default class StringUtils {
    constructor() {
        throw new Error('This is a static class')
    }

    /**
     * remove the dash separator and the following format dateTime from a string: yyyy-MM-dd-HHmmss
     * 
     * @param {string} value 
     * @return {string} 
    */
    static removeDateTime(value) {
        return value?.replace(/-(\d{4})-(\d{2})-(\d{2})-(\d+)/g, "")
    }

    /**
     * 
     * @param {string} value 
     * @param {string[]} allowedTags 
     * @returns 
     */
    static sanitizeHtmlText(value, allowedTags = null) {
        if (!value) return value

        const options = allowedTags ? { allowedTags } : undefined
        return sanitizeHtml(value, options)?.trim()
    }
}

<!-- eslint-disable vue/no-v-html -->
<template>
  <header
    v-if="course"
    class="c-search-result__item-header"
  >
    <div class="c-search-result__item-header-text">
      <h4
        class="c-search-result__item-title"
        data-test="search-result-item-title"
        v-html="course.title"
      ></h4>

      <section
        :class="['d-flex justify-content-between align-items-center', { 'w-100' : isColumnView }
        ]">
        <div class="c-rating-holder d-flex align-items-center">
          <feedback-stars-component :score="course.rating || 0" />
          <span data-test="review-count" class="ml-2 u-textcolor-black u-text-small">({{ course.reviewCount || 0 }})</span>
        </div>
        <course-search-item-logo 
          v-if="isColumnView && !isSmallView"
          :provider-logo-url="course.providerLogoUrl" 
          :provider-name="course.providerName"
          class="pl-2"
        />
      </section>
    </div>
    
    <img
      v-if="(isColumnView || isSmallView) && segmentImageUrl && $isAllowed('displaySegmentImages')"
      data-test="segment-image"
      class="c-search-result__item-header-image"
      :src="segmentImageUrl"
      alt="segment image"
    />
  </header>
</template>

<script>
import { cutOff } from '../../../../modules/textFormatter'
import FeedbackStarsComponent from '../../../molecules/FeedbackStarsComponent/FeedbackStarsComponent.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import useCourseSearchItem from '../../../../composables/useCourseSearchItem'
import CourseSearchItemLogo from './CourseSearchItemLogo.vue'

export default {
    perimeters: [featuresPerimeter],
    components: {
        FeedbackStarsComponent,
        CourseSearchItemLogo
    },
    props: {
        course: {
            type: Object,
            default: () => {}
        },
        isSmallView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { 
            isColumnView,
            segmentImageUrl
        } = useCourseSearchItem(props.course)

        return {
            cutOff,
            isColumnView,
            segmentImageUrl
        }
    }
}
</script>
export function getGuaranteeStringValue(value){
    return value || 'n.b'
}

export function getGuaranteeIntValue(value){
    return value || -1
}

export function getGuaranteeDateTimeValue(value) {
    return value || new Date()
}

export function getUserPersonalData(value) {
    return value === 'n.b' ? null : value
}

export function getGuaranteeCountryValue(value) {
    return value ?? 'NL' 
}
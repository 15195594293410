<template>
  <div
    class="c-facet__item"
    data-test="facet-peers"
  >
    <!-- Desktop view -->
    <div class="d-none d-md-block">
      <h5 class="c-facet__title">
        Wat anderen doen
      </h5>
      <div class="c-facet__content">
        <div
          v-for="filter in peerFilters"
          :key="filter.key"
          data-test="facet-options"
          class="c-facet__options"
        >
          <recess-checkbox-input
            :disabled="isLoading || (!!selectedPeersFilter && selectedPeersFilter !== filter.key)"
            :label-text="filter.displayValue"
            :default-checked="selectedPeersFilter === filter.key"
            @input="(checked) => peersFilter(filter, checked)"
          />
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <recess-accordion
      :active="true"
      class="d-md-none"
    >
      <template #accordion-title>
        <h4 class="c-facet__title mb-0">Wat anderen doen</h4>
      </template>
      <template #accordion-content>
        <div
          v-for="filter in peerFilters"
          :key="filter.key"
          class="c-facet__options"
        >
          <recess-checkbox-input
            :disabled="isLoading"
            :label-text="filter.displayValue"
            :default-checked="selectedPeersFilter === filter.key"
            @input="(checked) => peersFilter(filter, checked)"
          />
        </div>
      </template>
    </recess-accordion>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { searchPagePeers } from '../../../../constants/customFacets'

export default {
    data() {
        return {
            peerFilters: searchPagePeers
        }
    },
    computed: {
        ...mapState('searchModule', ['isLoading', 'selectedPeersFilter'])
    },
    methods: {
        ...mapActions('searchModule', ['setPeersFilter']),
        peersFilter(filter, check) {
            if(check) {
                this.$gtag.event('my_peers_toggled', {
                    'event_label': filter.display
                })
            }
            this.setPeersFilter({ filterValue: filter.key, checked: check})
        }
    }
}
</script>

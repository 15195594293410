<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="c-search-result__item-body">
    <header
      class="w-100 d-flex justify-content-between mb-3"
      @click.stop
    >
      <ul class="c-search-result__item-list">
        <li
          v-if="learningMethodFormatText || learningMethodTypeText"
          data-test="learning-method"
          class="c-search-result__list-item"
        >
          {{ learningMethodFormatText }} {{ learningMethodTypeText }}
        </li>
        <li
          v-if="course.courseDuration"
          data-test="duration"
          class="c-search-result__list-item"
        >
          {{ course.courseDuration }} {{ course.courseDurationUnit }}
        </li>
        <li
          v-if="course.level_nl"
          data-test="level"
          class="c-search-result__list-item"
        >
          {{ course.level_nl }}
        </li>
        <li 
          v-if="course.startMomentsArray && course.startMomentsArray.length"
          class="c-search-result__list-item"
          data-test="earliest-startmoment-date"
        >
          Vanaf {{ formatDate(course.startMomentsArray[0].fullDate) }}, {{ course.startMomentsArray.length }} startmomenten
        </li>
      </ul>
      <aside
        v-if="!isColumnView && !isEnrollmentFlow && !isSmallView"
        class="d-none d-md-block"
      >
        <recess-checkbox-input
          label-text="Vergelijk"
          :default-checked="isCourseToCompareChecked"
          data-test="compare-course-input"
          :disabled="!isCourseToCompareChecked && coursesToCompareCount >= 3"
          @input="() => toggleCourseIdToCompare()"
        />
      </aside>
    </header>
    
    <div class="c-search-result__item-content w-100">
      <article class="c-search-result__item-description">
        <p
          v-if="course.content && !isSmallView"
          class="c-search-result__item-intro-text"
          v-html="cutOff(course.content, 200)"
        ></p>
        
        <div class="d-flex justify-content-between">
          <course-search-item-logo
            v-if="!isColumnView || isSmallView"
            :provider-logo-url="course.providerLogoUrl"
            :provider-name="course.providerName"
          />
          <course-search-item-actions 
            v-if="isSmallView"
            :is-small-view="isSmallView"
            :course="course"
            :show-share-action="false"
            :show-compare-action="false"
            v-on="{ ...$listeners }"
          />
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import useCourseSearchItem from '../../../../composables/useCourseSearchItem'
import { cutOff } from '../../../../modules/textFormatter'
import DateUtils from '../../../../utils/DateUtils'
import CourseSearchItemLogo from './CourseSearchItemLogo.vue'
import CourseSearchItemActions from './CourseSearchItemActions.vue'

export default {
    components: {
        CourseSearchItemLogo,
        CourseSearchItemActions
    },
    props: {
        course: {
            type: Object,
            default: () => {}
        },    
        isSmallView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const {
            isColumnView,
            coursesToCompareCount,
            isEnrollmentFlow,
            isCourseToCompareChecked,
            isOnWishlist,
            learningMethodFormatText,
            learningMethodTypeText,
            toggleCourseIdToCompare,
            updateUserWishList
        } = useCourseSearchItem(props.course)
      
        const formatDate = date => DateUtils.formatDate(date)

        return {
            cutOff,
            isColumnView,
            isOnWishlist,
            isCourseToCompareChecked,
            coursesToCompareCount,
            isEnrollmentFlow,
            learningMethodFormatText,
            learningMethodTypeText,
            toggleCourseIdToCompare,
            formatDate,
            updateUserWishList
        }
    }
}
</script>
<template>
  <lightbox-component
    :is-visible="isVisible"
    :show-close-button="false"
  >
    <p>
      Uw projectaanvraag is succesvol ontvangen.
    </p>
        
    <p>
      De fiatteur moet uw aanvraag nog goedkeuren. In uw projectenoverzicht kunt u op elk moment de status van uw projectaanvraag zien.
      Mocht de status van uw aanvraag wijzigen, dan ontvangt u een e-mailnotificatie.
    </p>
      
    <div class="d-flex flex-md-row flex-column">
      <recess-button
        class="w-100 w-md-auto mb-3 mb-md-0"
        variant="secondary"
        data-test="back-button"
        title="Terug naar home"
        @click="handleBackButtonClick"
      />
      <recess-button
        class="ml-md-2 w-100 w-md-auto"
        variant="primary"
        title="Naar mijn projectaanvragen"
        data-test="go-to-project-requests-button"
        @click="handleToMyProjectButtonClick"
      />
    </div>
   
  </lightbox-component>
</template>

<script>
import LightboxComponent from '../../../../molecules/LightboxComponent/LightboxComponent.vue'
import useRouter from '../../../../../composables/useRouter'

export default {
    components: {
        LightboxComponent
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(_, { emit }) {
        const router = useRouter()
  
        const handleBackButtonClick = () => {
            router.replace({ name: 'home'})
            emit('close')
        }   

        const handleToMyProjectButtonClick = () => {
            router.replace({ name: 'my-project-requests'})
            emit('close')
        }

        return {
            handleBackButtonClick,
            handleToMyProjectButtonClick
        }
    }
}
</script>
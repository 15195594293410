export const PENDINGAPPROVAL = 1
export const PENDINGSECONDAPPROVAL = 2
export const CANCELLED =  3
export const REJECTEDEMPLOYER = 4
export const READYFORORDER = 5
export const REJECTEDEMPLOYEE = 6
export const ORDERACCEPTED = 7
export const PENDINGSTUDENTVERIFICATION = 8
export const PENDINGSTUDYCONTRACTAGREEMENT = 9
export const INPROGRESS = 10
export const DECLINEDUNVERIFIED = 11
export const CANCELED_VERIFICATION_TIME_OVERDUE = 14

export default {
    PENDINGAPPROVAL,
    PENDINGSECONDAPPROVAL,
    CANCELLED,
    REJECTEDEMPLOYER,
    READYFORORDER,
    REJECTEDEMPLOYEE,
    ORDERACCEPTED,
    PENDINGSTUDENTVERIFICATION,
    PENDINGSTUDYCONTRACTAGREEMENT,
    INPROGRESS,
    DECLINEDUNVERIFIED,
    CANCELED_VERIFICATION_TIME_OVERDUE
}
<template>
  <div :class="alertClass" role="alert">
    <p 
      v-html="text"
      :data-test="dataTest">
    </p>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'AlertMessage',
  props: {
    text: {
      type: String,
      required: true
    },
    dataTest: {
      type: String,
      required: true
    },
    alertType: {
      type: String,
      default: 'info',
      validator: value => ['info', 'success', 'warning', 'danger'].includes(value)
    }
  },
  setup(props) {
    const alertClass = computed(() => `alert alert-${props.alertType}`)
    
    return {
      alertClass
    }
  }
}
</script>

<style scoped>
.alert {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 10px 0;
}

.alert-info {
  border-top: 2px solid;
  border-right: 3px solid;
  border-bottom: 3px solid;
  border-left: 7px solid;
  border-color: #131942;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
</style>

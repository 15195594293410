import trimHtml from 'trim-html'

export const cutOff = (html, atChar, suffix = '...') => {
    if (!html) return null

    return trimHtml(html, {
        limit: atChar,
        wordBreak: false,
        preserveTags: true,
        suffix,
        moreLink: false
    })?.html
}

export default {
    cutOff
}

<template>
  <div v-if="startMoment">
    <recess-tooltip
      v-if="displayStartGuaranteed"
    >
      <component
        :is="componentToRender"
        class="d-flex flex-column"
        data-test="start-moment-events-link"
        @click="mapEventsAndshowEventCalendar"
      >
        <div
          class="d-flex flex-row align-items-center"
        >
          <div class="d-flex flex-column">
            <time :datetime="startMoment.displayDate" data-test="start-moment-date">{{ startMoment.displayDate }}</time>
            <time 
              v-if="startMoment.displayTime"
              :datetime="startMoment.displayTime"
              data-test="start-moment-time"
            >{{ startMoment.displayTime }}</time>
          </div>
          <recess-icon
            class="p-0"
            :width="28"
            :height="28"
            data-test="start-guaranteed-icon"
            icon="calendar-check"
          />
        </div>       
      </component>

      <template #content>
        <span>Dit startmoment gaat gegarandeerd door op deze datum en locatie.</span>
      </template>
    </recess-tooltip>
    <component
      :is="componentToRender"
      v-else
      class="d-flex flex-column"
      data-test="start-moment-events-link"
      @click="mapEventsAndshowEventCalendar"
    >
      <span>{{ startMoment.displayDate }}</span>
      <span v-if="startMoment.displayTime" data-test="start-moment-time">{{ startMoment.displayTime }}</span>
    </component>

    <event-calendar
      :show-event-calendar="eventCalendarVisibility"
      :start-moment="startMoment"
      :course-name="courseName"
      :events="events"
      data-test="start-moment-events-calendar"
      @close="eventCalendarVisibility = false"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import startMomentHelper from '../../../modules/startMomentHelper'
import EventCalendar from '../EventCalendar/EventCalendar.vue'
import * as displayStartMomentsTypes from '../../../constants/displayStartMomentsTypes'
import useStore from '../../../composables/useStore'

export default {
    components: {
        EventCalendar
    },
    props: {
        startMoment: {
            type: Object,
            default: () => null
        },
        course: {
            type: Object,
            default: () => {}
        }

    },
    setup(props) {
        const store = useStore()
        const events = ref([])
        
        const hasEvents = computed(() => {
            const matchedStartMoment = props.course?.startMoments?.find(sm => sm.externalId === props.startMoment?.startMomentId)

            return !!matchedStartMoment?.modules?.length
        })

        const eventCalendarVisibility = ref(false)
        const mapEventsAndshowEventCalendar = () => {
            if(!hasEvents.value || !props.course?.startMoments) return

            const matchedStartMoment = props.course?.startMoments?.find(sm => sm.externalId === props.startMoment?.startMomentId)

            events.value = startMomentHelper.mapEvents(matchedStartMoment)
            eventCalendarVisibility.value = true
        }
        
        const displayStartGuaranteed = computed(() => {
            const displayStartMomentsType =  store?.getters['accountModule/displayStartMomentsType']
            return props.startMoment?.startGuaranteed && displayStartMomentsType === displayStartMomentsTypes.ALLSTARTMOMENTSWITHDISTINCTION
        })

        const courseName = computed(() => {
            return props.course?.name
        })

        const componentToRender = computed(() => {
            return hasEvents?.value ? 'a' : 'div'
        })
         
        return {
            mapEventsAndshowEventCalendar,
            courseName,
            eventCalendarVisibility,
            displayStartGuaranteed,
            events,
            componentToRender
        }
    }
}

</script>
import BasePerimeter from './BasePerimeter'
import { getSessionToken } from '../utils/SessionTokenUtils'


export default new BasePerimeter({
    purpose: 'route',

    can: {
        routeMyTeamPage() {
            return this.isCapableOfTeamLeading()
        },
        routeApprovalPage() {
            return this.isTeamLeader() || this.isSecondApprover() || this.isDelegatee()
        },
        routeApprovalStudyContractPage(userId) {
            return this.isOwnEnrollment(userId)
        },
        routeAsCoordinator() {
            return this.isCoordinator()
        },
        routeAsAccountReporter() {
            return this.isAccountReporter()
        },
        routeAsTeamleader() {
            return this.isTeamLeader() && !this.isCoordinator()
        },
        routeApprovalAbcPage() {
            return this.isTeamLeader() && this.child?.accountSettings?.abcApprovalRequired
        },
        routeAccountSelectionPage() {
            const selectedAccountCode = sessionStorage.getItem('account_selected') 
            return this.child?.claims?.shared_id?.length > 1 && (!selectedAccountCode || getSessionToken()?.profile?.account_code !== selectedAccountCode)
        },
        routeProjectRequestsPage() {
            return this.isProjectInitiator() || this.isProjectApprover()
        }
    }
})

function showNavigations(slideItems) {
    const length = slideItems.length
    const width = window.innerWidth

    if (width >= 992) return length > 3
    if (width >= 576) return length > 2
    return length > 1
}

function showNavigationsSmall(slideItems) {
    const length = slideItems.length
    const width = window.innerWidth

    if (width >= 992) return length > 4
    if (width >= 576) return length > 3
    return length > 1
}

export default {
    showNavigations,
    showNavigationsSmall
}
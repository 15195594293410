<template>
  <hooper
    :settings="hooperSettings"
    :height="250"
    class="d-grid u-overflow-hidden"  
  >
    <slide
      v-for="(course, index) in courses"  
      :key="index"
      class="c-search-result__item-wrapper--small"
    >
      <recess-card 
        bordered 
        has-hover
        class="c-search-result__item--small" 
        @click.native="redirectToCourse(course.id, course.assortmentId)"
      >
        <course-search-item-header 
          :course="course"
          is-small-view
        />
  
        <course-search-item-body 
          :course="course"
          is-small-view
          @toggle-assign-lp="id => $emit('toggle-assign-lp', id)"
        />
  
        <course-search-item-footer 
          :course="course"
          is-small-view
        >
          <template #search-item-action-buttons>
            <recess-button
              title="Bekijk details"
              variant="tertiary"
              class="w-100"
              @click="redirectToCourse(course.id, course.assortmentId)"
            />
          </template>
        </course-search-item-footer>
      </recess-card>
    </slide>
    <hooper-navigation
      v-if="showNavigation"
      slot="hooper-addons"
      data-test="navigation"
    >
      <template #hooper-prev>
        <recess-icon
          icon="arrow-left"
          color="secondary"
          class="c-ai-suggestion-carousel__icon-left"
          :height="25"
          :width="25"
        />
      </template>
      <template #hooper-next>
        <recess-icon
          icon="arrow-right"
          color="secondary"
          :height="25"
          class="c-ai-suggestion-carousel__icon-right"
          :width="25"
        />
      </template>
    </hooper-navigation>
  </hooper>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'
import { computed } from 'vue'
import useMedia from '../../../composables/useMediaQueries'
import mediaQueries from '../../../constants/mediaQueries'
import CourseSearchItemHeader from "../CourseSearchComponent/CourseSearchItems/CourseSearchItemHeader.vue"
import CourseSearchItemBody from "../CourseSearchComponent/CourseSearchItems/CourseSearchItemBody.vue"
import CourseSearchItemFooter from "../CourseSearchComponent/CourseSearchItems/CourseSearchItemFooter.vue"

export default {
    components: {
        CourseSearchItemHeader,
        CourseSearchItemBody,
        CourseSearchItemFooter,
        Hooper,
        Slide,
        HooperNavigation
    },
    props: {
        courses: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const isMobileOrTablet = useMedia(mediaQueries.MEDIUM)

        const hooperSettings = {
          
            breakpoints: {
                1120: {
                    itemsToShow: 2,
                    itemsToSlide: 2
                },
                750: {
                    itemsToShow: 1,
                    itemsToSlide: 1
                },
                0: {
                    itemsToShow: 1,
                    itemsToSlide: 1
                }
            }
        }

        const showNavigation = computed(() => {
            return isMobileOrTablet.value ? props.courses?.length > 1 : props.courses?.length > 2
        })

        return {
            showNavigation,
            hooperSettings
        }
    },
    methods: {
        redirectToCourse(courseId, assortmentId) {
            this.$gtag.event('ai_course', { course_id: courseId })
            this.$router.push({name: 'course', query: { course: courseId, assortment: assortmentId }})
        }
    }
}
</script>

import { computed, readonly, ref, reactive } from "vue"
import projectRequestClient from "../api/projectRequestClient"
import projectRequestStatus from "../constants/projectRequestStatus"
import projectRequestTypes, { projectRequestTypeValues } from "../constants/projectRequestTypes"
import priceFormatter from "../modules/priceFormatter"
import DateUtils from "../utils/DateUtils"
import useValidation from "./useValidation"

const errorMessages = {
    purchaseOrderNumber: {
        required: 'PO nummer is verplicht'
    },
    costCenter: {
        required: 'Kostenplaats is verplicht'
    }
}

export default function useProjectRequestDetails(isApprovalPage) {
    const isFetchingDetailsError = ref(false)
    const setIsFetchingDetailsError = newValue => { isFetchingDetailsError.value = newValue }
    const projectRequest = ref({})
    const setProjectRequest = newValue => { projectRequest.value = newValue }
    const isFetchingProjectRequest = ref(false)
    const setIsFetchingProjectRequest = newValue => { isFetchingProjectRequest.value = newValue }

    const formFields = reactive({
        purchaseOrderNumber: null,
        costCenter: null
    })

    const { handleErrorMessage, validateForm } = useValidation("formFields", errorMessages)

    const isTypeIncompanyWithStartmoments = computed(() => {
        return projectRequestTypes[projectRequest.value.type] === projectRequestTypes.IncompanyWithStartmoments
    })

    const isTypeIncompanyWithoutStartmoments = computed(() => {
        return projectRequestTypes[projectRequest.value.type] === projectRequestTypes.IncompanyWithoutStartmoments
    })

    const isTypeIncompany = computed(() => {
        return isTypeIncompanyWithStartmoments.value || isTypeIncompanyWithoutStartmoments.value
    })

    const getStatusChangeInfo = (currentStatus, projectRequestStatusHistories) => {
        const statusChange = projectRequestStatusHistories?.find(x => x.status.id === currentStatus.id)
        if (!statusChange) {
            return '-'
        }

        const statusChangeInfoValues = [statusChange.status.description]

        const statusChangeDate = DateUtils.formatDate(statusChange.dateCreated)
        if (statusChangeDate) {
            statusChangeInfoValues.push(statusChangeDate)
        }

        if (statusChange.user) {
            const statusChangeUsername = `${statusChange.user?.firstName} ${statusChange.user?.middleName ?? ""} ${statusChange.user?.lastName}`
            statusChangeInfoValues.push(statusChangeUsername)
        }

        if (statusChange.reason) {
            statusChangeInfoValues.push(statusChange.reason)
        }

        if (statusChange.remark) {
            statusChangeInfoValues.push(statusChange.remark)
        }

        return statusChangeInfoValues
    }

    const userInfo = computed(() => {
        if (!projectRequest.value?.id) return []

        const { initiator } = projectRequest.value

        return [
            { label: "Afdeling", values: [initiator?.department] },
            { label: "Naam", values: [`${initiator?.firstName} ${initiator?.middleName ?? ""} ${initiator?.lastName}`] },
            { label: "E-mailadres", values: [initiator?.emailAddress] },
            { label: "Telefoonnummer", values: [initiator?.phoneNumber] }
        ]
    })

    const projectInfo = computed(() => {
        if (!projectRequest.value?.id) return []

        const { name, description, type, preferredExecutionDate, numberOfStartmoments, projectRequestStatusHistories, status } = projectRequest.value

        const projectInformation = [
            { label: "Naam projectaanvraag", values: [name] },
            { label: "Type projectaanvraag", values: [projectRequestTypeValues[projectRequestTypes[type]]?.displayText] },
            { label: "Beschrijving projectaanvraag", values: [description] }            
        ]
        
        if (isTypeIncompany.value) {
            projectInformation.push({ label: "Gewenste uitvoerdatum", values: [preferredExecutionDate] })
            if (isTypeIncompanyWithStartmoments.value) {
                projectInformation.push({ label: "Aantal startmomenten", values: [numberOfStartmoments] })
            }
        }

        projectInformation.push({ label: "Laatste statuswijziging", values: getStatusChangeInfo(status, projectRequestStatusHistories) })

        return projectInformation
    })

    const providerInfo = computed(() => {
        if (!projectRequest.value?.id) return []

        const { providerName, attachments } = projectRequest.value
        // Get the first attachment as for now we only support one file upload
        const attachment = attachments?.[0]

        const result = [{ label: "Leverancier", values: [providerName] }]

        if (attachment) {
            result.push({ label: "Project aanvraag offerte bijlage", values: [attachment], type: "Download" })
        }

        return result
    })

    const participantsInfo = computed(() => {
        if (!projectRequest.value?.id) return []

        const { minParticipants, maxParticipants } = projectRequest.value

        return [
            { label: "Minimum aantal deelnemers", values: [minParticipants] },
            { label: "Maximum aantal deelnemers", values: [maxParticipants] }
        ]
    })

    const pricingInfo = computed(() => {
        if (!projectRequest.value?.id) return []

        const { basePriceWithoutVat, pricePerPersonWithoutVat, listPriceWithoutVat, totalPriceWithoutVat } = projectRequest.value

        const pricing = [
            { label: "Vaste prijs (excl. BTW)", values: [priceFormatter.formatPrice(basePriceWithoutVat)] }
        ]

        if (isTypeIncompany.value) {
            pricing.push(
                { label: "Prijs per deelnemer (excl. BTW)", values: [priceFormatter.formatPrice(pricePerPersonWithoutVat)] },
                { label: "Weergave prijs (per deelnemer, excl. BTW)", values: [priceFormatter.formatPrice(listPriceWithoutVat)] },
                { label: "Totaal o.b.v. max. deelnemers (excl. BTW)", values: [priceFormatter.formatPrice(totalPriceWithoutVat)] }
            )
        }

        return pricing
    })

    const approvalInfo = computed(() => {
        if (!projectRequest.value?.id) return []

        const { assignedApprover, approverComment, status, projectRequestStatusHistories, costCenter, purchaseOrderNumber } = projectRequest.value

        const approverValues = [
            `${assignedApprover?.firstName} ${assignedApprover?.middleName ?? ""} ${assignedApprover?.lastName}`,
            assignedApprover?.emailAddress
        ]

        const infoValues = [{ label: "Beoordelaar", values: approverValues }]

        if (status.id === projectRequestStatus.PENDING_APPROVAL) {
            infoValues.push({ label: "Aanvullende informatie/opmerking", values: [approverComment] })
        }

        if (isApprovalPage) {
            // Field should be an input
            infoValues.push({
                label: "PO nummer",
                values: [formFields.purchaseOrderNumber],
                type: 'Input',
                options: {
                    required: true,
                    handleErrorMessage: handleErrorMessage('purchaseOrderNumber'),
                    onInput: val => { formFields.purchaseOrderNumber = val }
                }
            },{
                label: "Kostenplaats",
                values: [formFields.costCenter],
                type: 'Input',
                options: {
                    required: true,
                    handleErrorMessage: handleErrorMessage('costCenter'),
                    onInput: val => { formFields.costCenter = val }
                }
            })
        } else {
            infoValues.push(
                { label: "PO nummer", values: [purchaseOrderNumber] },
                { label: "Kostenplaats", values: [costCenter] })
        }

        if (status.id !== projectRequestStatus.REJECTED_EMPLOYER && status.id !== projectRequestStatus.READY_FOR_ORDER) {
            // If has not been approved or rejected
            return infoValues
        }

        // Adds the approval/rejection date
        const statusChange = projectRequestStatusHistories?.find(x => x.status.id === status.id)

        const formattedApprovalRejectionDate = DateUtils.formatDate(statusChange?.dateCreated)
        if (formattedApprovalRejectionDate) {
            approverValues.push(formattedApprovalRejectionDate)
        }

        if (status.id === projectRequestStatus.REJECTED_EMPLOYER && statusChange.remark) {
            approverValues.push(statusChange.remark)
        }


        return infoValues
    })

    const getProjectRequestById = async projectId => {
        try {
            setIsFetchingProjectRequest(true)

            const response = await projectRequestClient.getById(projectId)
            setProjectRequest(response)
            formFields.purchaseOrderNumber = response.purchaseOrderNumber
            formFields.costCenter = response.costCenter
            setIsFetchingDetailsError(false)
        } catch (error) {
            setIsFetchingDetailsError(true)
            throw new Error("Failed to retrieve project request", { cause: error })
        } finally {
            setIsFetchingProjectRequest(false)
        }
    }

    return {
        projectRequest: readonly(projectRequest),
        isFetchingProjectRequest: readonly(isFetchingProjectRequest),
        userInfo,
        projectInfo,
        providerInfo,
        participantsInfo,
        pricingInfo,
        approvalInfo,
        isTypeIncompany,
        formFields: readonly(formFields),
        setIsFetchingDetailsError,
        getProjectRequestById,
        validateForm
    }
}
<template>
  <div v-if="enrollReasonDisplayValue" class="c-enrollment-reason-summary">
    <table
      aria-label="Enrollment reason"
      class="c-enrollment-reason-summary__table w-100"
    >
      <tbody>
        <tr>
          <th
            scope="row"
            class="c-enrollment-reason-summary__column-label"
          >
            Reden van inschrijving
          </th>
          <td class="c-enrollment-reason-summary__column-value">
            <div data-test="enroll-reason-summary">{{ enrollReasonDisplayValue }}</div>
            <div v-if="enrollReasonMessage">{{ enrollReasonMessage }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
export default {
    props: {
        enrollReasonDisplayValue: {
            type: String,
            default: null
        },
        enrollReasonMessage: {
            type: String,
            default: null
        }
    }
}
</script>
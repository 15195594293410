<template>
  <!-- Display price -->
  <table
    v-if="enrollment"
    aria-label="Course price detail"
    class="w-100"
  >
    <tbody>
      <tr
        v-if="enrollment.displayPrice.listPrice || enrollment.displayPrice.totalPrice"
      >
        <th
          scope="row"
          class="text-left"
        >
          Prijs
        </th>
        <td class="d-flex col-12 col-md-5 justify-content-end">
          <!-- 
                  When we don't display the VAT and discount separately, they are included in the list price,
                  which means it is the same as the total price. Since the BE is setting the List price to 0 we display the total price.
                 -->
          <span data-test="list-price">
            {{ 
              enrollment.displayPrice.totalPrice 
                || enrollment.displayPrice.listPrice 
                || enrollment.displayPrice.listPrice === 0
                || enrollment.displayPrice.totalPrice === 0 
                ? formatPrice(enrollment.displayPrice.listPrice || enrollment.displayPrice.totalPrice )
                : 'Onbekend' 
            }}
          </span>
        </td>
      </tr>
      <tr v-if="enrollment.displayPrice.discount">
        <th
          scope="row"
          class="text-left"
        >
          Korting
        </th>
        <td class="d-flex col-12 col-md-5 justify-content-end">
          <span data-test="price-discount">- {{ formatPrice(enrollment.displayPrice.discount) }}</span>
        </td>
      </tr>
      <tr v-if="enrollment.displayPrice.vat">
        <th
          scope="row"
          class="text-left"
        >
          BTW
        </th>
        <td class="d-flex col-12 col-md-5 justify-content-end">
          <span data-test="price-vat">{{ formatPrice(enrollment.displayPrice.vat) }}</span>
        </td>
      </tr>
            
      <template v-if="budgetTransactions && budgetTransactions.length">
        <tr v-if="enrollment.displayPrice.vat || enrollment.displayPrice.discount">
          <th
            scope="row"
            class="font-weight-bold text-left"
          >
            Totaal:
          </th>
          <td>
            <recess-divider
              variant="xsmall"
              show-line
              class="col-12 col-md-5"
            />
            <span class="d-flex col-12 col-md-5 justify-content-end">{{ formatPrice(enrollment.displayPrice.totalPrice) }}</span>
          </td>
        </tr>
        <recess-divider variant="xsmall" />
        <span class="font-weight-bold">Aftrek door gebruik van budget(ten):</span>
        <tr
          v-for="(budgetTransaction, index) in budgetTransactions"
          :key="index"
        >
          <th
            v-if="budgetTransaction.budget"
            scope="row"
            class="text-left"
          >
            {{ budgetTransaction.budget.name }}
            {{ budgetTransaction.budget.approvalRequired ? '(goedkeuring noodzakelijk)' : '' }}
          </th>
          <td class="d-flex col-12 col-md-5 justify-content-end">
            <span>- {{ formatPrice(budgetTransaction.amount) }}</span>
          </td>
        </tr>
      </template>
      <tr>
        <th
          v-if="enrollment.displayPrice.listPrice || enrollment.displayPrice.totalPrice"
          scope="row"
          class="text-left"
        >
          <span
            class="font-weight-bold"
          >{{ courseTotalPriceText }}</span>
        </th>

        <td class="font-weight-bold">
          <recess-divider
            variant="xsmall"
            show-line
            class="col-12 col-md-5"
          />
          <div
            class="col-12 col-md-5 justify-content-end d-flex align-items-baseline"
          >
            <span
              v-if="enrollment.displayPrice.totalPrice"
              data-test="total-price-text"
              class="font-italic u-text-small mr-1 mr-md-2"
            >({{ vatText }})</span>
            <span data-test="total-price">{{ enrollment.displayPrice.totalPrice || enrollment.displayPrice.totalPrice == 0 ? formatPrice(enrollment.displayPrice.totalPrice - totalUsedBudget) : 'Onbekend' }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'
import priceFormatter from '../../../../modules/priceFormatter'
import * as enrollmentStatus from '../../../../constants/enrollmentStatus'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import coursePriceHelper from '../../../../modules/coursePriceHelper'

export default {
    props: {
        enrollment: {
            type: Object,
            default: null
        }
    },
    perimeters: [featuresPerimeter],
    computed: {
        ...mapGetters('accountModule', ['displayVatInPrices']),
        budgetTransactions() {
            return this.enrollment?.budgetTransactions?.reduce((acc, b) => {
                const existingTransaction = acc.find(eb => eb.budgetId === b.budgetId)
                if (existingTransaction) existingTransaction.amount += b.amount
                else acc.push(b)

                return acc
            }, [])?.filter(b => b.amount > 0) 
        },
        totalUsedBudget() {
            return this.budgetTransactions?.reduce((acc, transaction) =>  acc + transaction.amount, 0)
        },
        vatText() {
            return coursePriceHelper.getVatText(this.displayVatInPrices)
        },
        courseTotalPriceText() {
            const status = this.enrollment?.statusId
            return status === enrollmentStatus.PENDINGAPPROVAL || 
            status ===  enrollmentStatus.PENDINGSECONDAPPROVAL ||
            status === enrollmentStatus.PENDINGSTUDENTVERIFICATION 
                ? 'Goed te keuren bedrag' 
                : "Goedgekeurde bedrag"
        }
    },
    methods: {
        formatPrice: priceFormatter.formatPriceWithZeros
    }
}
</script>
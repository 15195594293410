<template>
  <recess-layout-static v-if="$isAllowed('displaySocialLearning')">
    <transition name="fade">
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>
    <recess-divider />
    <recess-button
      title="Terug"
      icon="back"
      variant="variant4"
      :url="{ name: 'search-posts'}"
    />
    <recess-divider />
    <h1 data-test="post-title">Nieuwe bijdrage</h1>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="mb-4">Deel uw kennis/ervaring met collega's of stel een vraag waarmee uw collega's u kunnen helpen. Deze bijdrages en vragen zijn door iedereen te raadplegen dus stel uw vraag bij voorkeur op zo'n manier dat andere collega's mogelijk ook iets hebben aan het antwoord. Om het vinden van uw bijdrage te vereenvoudigen moet u minimaal één van de opties hieronder selecteren.</p>
        <create-edit-form 
          @submit="post => createPost(post)"
          @is-uploading="val => isLoading = val"
        />
      </div>
      <check-list class="col-4 d-none d-md-block" :check-list-items="postHints" :title="postHintsTitle"/>
    </div>
    <recess-divider variant="large" />
    <recess-alert v-if="showErrorMessage" class="mb-5" type="error" text="Er is een fout opgetreden. Probeer het later opnieuw." />

    <message-modal 
      message="Uw bijdrage is succesvol verwerkt en zal over enkele momenten zichtbaar zijn." 
      :is-visible="showSuccessMessage" 
    >
      <template #buttons>
        <recess-button
          variant="tertiary"
          data-test="to-detail-page"
          title="Terug"
          @click="closeAndRedirectDetailPage()"
        />
        <recess-button
          class="ml-2"
          variant="primary"
          data-test="to-search-page"
          title="Naar de zoekpagina"
          @click="closeAndRedirectSearchPage()"
        />
      </template>
    </message-modal>
  </recess-layout-static>
</template>

<script>
import CreateEditForm from '../../organisms/SocialLearning/Post/CreateEditForm.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import socialLearningClient from '../../../api/socialLearningClient'
import CheckList from '../../atoms/CheckListComponent/CheckList.vue'
import { postHintsTitle, postHints } from '../../../constants/postHints'
import MessageModal from '../../atoms/MessageModal/MessageModal.vue'
import postSuccessMessageMixin from '../../../mixins/post-success-message-mixin'

export default {
    name: 'PostCreate',
    perimeters: [featuresPerimeter],
    components: {
        CreateEditForm,
        LoaderComponent,
        CheckList,
        MessageModal
    },
    mixins: [ postSuccessMessageMixin ],
    data() {
        return {
            isLoading: false,
            showErrorMessage: false,
            postId: null,
            postHints,
            postHintsTitle
        }
    },
    methods: {
        async createPost(post) {
            try {
                this.showErrorMessage = false
                this.isLoading = true
                const createdPost = await socialLearningClient.createPost(post)

                this.postId = createdPost.id
                this.showSuccessMessage = true
            } catch (error) {
                this.showErrorMessage = true
                throw new Error("Failed to create post", error)             
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>
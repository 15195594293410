<template>
  <div>
    <menu-button
      v-if="$isAllowed('displayNavigationBar')"
      :is-open="isNavigationOpen"
      class="c-menu-button--burger"
      @onOpen="open"
      @onClose="close"
      @click="isNavigationOpen = !isNavigationOpen"
    />

    <div :class="['c-mobile-navigation', {'is-active': isNavigationOpen}]">
      <div class="c-mobile-navigation__list">
        <small-navigation-item v-if="$isAllowed('displayHomeLink')">
          <router-link :to="{ name: 'home' }">{{ navigationItemTexts.DASHBOARD }}</router-link>
        </small-navigation-item>
        <small-navigation-item v-show="$isAllowed('routeMyTeamPage')">
          <router-link
            :to="{ name: 'my-team', query: { 'my-team-tab': $route.query['my-team-tab'], tableDataQueries: $route.query.tableDataQueries} }"
          >
            {{ navigationItemTexts.MY_TEAM }}
          </router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayMyDevelopmentLink')">
          <router-link
            :to="{ name: 'my-development', query: { 'my-development-tab': $route.query['my-development-tab'], tableDataQueries: $route.query.tableDataQueries}}"
          >
            {{ navigationItemTexts.MY_DEVELOPMENT }}
          </router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displaySocialLearning')">
          <router-link
            :to="{ name: 'search-posts' }"
            class="c-recess-nav__link"
          >
            {{ navigationItemTexts.SOCIAL_LEARNING }}
          </router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('checkDelegatedActions')">
          <router-link
            :to="{ name: 'my-actions', query: { 'my-actions-tab': $route.query['my-actions-tab'], tableDataQueries: $route.query.tableDataQueries}}"
          >
            {{ navigationItemTexts.DELEGATION_ACTIONS }}
          </router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayReportLink')">
          <router-link :to="{ name: 'report-dashboard' }">{{ navigationItemTexts.REPORTS }}</router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayProjectRequest') && $isAllowed('routeProjectRequestsPage')">
          <router-link :to="{name: 'my-project-requests'}">{{ navigationItemTexts.PROJECT_REQUESTS }}</router-link>
        </small-navigation-item>
        <small-navigation-item v-if="$isAllowed('displayFAQLink')">
          <router-link :to="{ name: 'faq' }">{{ navigationItemTexts.FAQ }}</router-link>
        </small-navigation-item>
        <small-navigation-item
          v-if="$isAllowed('displayContactInformation') && $isAllowed('displayContactInformationForStudents')"
          data-test="nav-contact"
        >
          <recess-accordion
            v-if="$isAllowed('displayContactSubmenu')"
            variant="variant-2"
            class="border-0"
          >
            <template
              #accordion-title
            >
              <span
                class="u-textcolor-secondary"
              >
                {{ navigationItemTexts.CONTACT }}
              </span>
            </template>
            <template
              #accordion-content
            >
              <recess-divider />
              <router-link
                :to="{name: 'contact'}"
                class="u-textcolor-primary pl-4"
              >
                {{ navigationItemTexts.GENERAL_CONTACT }}
              </router-link>
              <recess-divider 
                v-if="$isAllowed('displayABCRequestStandard')"
                show-line
              />
              <router-link
                v-if="$isAllowed('displayABCRequestStandard')"
                :to="{name: 'abc-request'}"
                class="u-textcolor-primary pl-4"
              >
                {{ navigationItemTexts.ABC_REQUEST }}
              </router-link>
            </template>
          </recess-accordion>
          <router-link
            v-else
            :to="{name: 'contact'}"
          >
            {{ navigationItemTexts.CONTACT }}
          </router-link>
        </small-navigation-item>
      </div>
    </div>
  </div>
</template>

<script>
import MenuButton from '../../../atoms/MenuButton/MenuButton.vue'
import SmallNavigationItem from './SmallNavigationItem.vue'

import routePerimeter from '../../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import delegatePerimeter from '../../../../perimeters/DelegatePerimeter'
import navigationItemTexts from '../../../../constants/navigationItemTexts'

export default {
    components: { SmallNavigationItem, MenuButton },
    perimeters: [routePerimeter, featuresPerimeter, delegatePerimeter],
    data() {
        return {
            isNavigationOpen: false,
            navigationItemTexts
        }
    },
    watch: {
        $route() {
            // When the route change, and the side menu is open, close it
            if (this.isNavigationOpen) {
                this.close()
            }
        }
    },
    methods: {
        open() {
            this.isNavigationOpen = true
            this.$bus.emit('disable-app-scroll', 'noMobletScroll')
        },
        close() {
            this.isNavigationOpen = false
            this.$bus.emit('enable-app-scroll', 'noMobletScroll')
        }
    }
}
</script>

<template>
  <div>
    <transition name="fade">
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>
    <h4 v-if="!editing">Mijn Reactie</h4>
    <recess-divider variant="xsmall"/>
    <div class="row">
      <div :class="[ editing ? 'col-12 col-md-12' : 'col-12 col-md-8' ]">
        <recess-rich-text-editor 
          v-model="form.content" 
          data-test="reply-content-input" 
          :border-color="editing ? 'secondary' : null"
          class="s-post-content" 
          :error-message="$v.form.content.$error ? 'Je antwoord mag niet leeg zijn' : ''" />
      </div>

      <check-list
        v-if="!editing" 
        :class="[ editing ? 'col-0 d-none d-md-block' : 'col-4 d-none d-md-block' ]"
        data-test="reply-hints-check-list"
        :check-list-items="replyHints" :title="replyHintsTitle"/>
    </div>
        
    <recess-divider variant="xsmall"/>

    <attachment-form  
      :reset-file-upload="resetFileUpload"
      :initial-attachments="form.attachments" 
      @uploaded=" newDocumentsToUpload=> documentsToUpload = newDocumentsToUpload"
      @removed="newUploadedAttachments => newAttachments = newUploadedAttachments"
    />

    <recess-divider variant="xsmall"/>
    
    <recess-button class="mr-2 mb-2 mb-md-0 col-12 col-md-auto" title="Annuleren" variant="tertiary"  data-test="cancel-reply-btn" @click.native="cancel()" />

    <recess-button class="u-min-width-200 u-min-width-200 col-12 col-md-auto" :title="submitButtonTitle" variant="primary" data-test="submit-reply-btn" @click.native="submit()" />
    
    <recess-alert v-if="showErrorMessage" class="mt-3" type="error" text="Er is een fout opgetreden. Probeer het later opnieuw." />
  
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import isEqual from 'lodash/isEqual'
import socialLearningClient from '../../../../api/socialLearningClient'
import CheckList from '../../../atoms/CheckListComponent/CheckList.vue'
import { replyHints } from '../../../../constants/replyHints'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import AttachmentForm from '../Attachment/CreateEditForm.vue'

export default {
    components: {
        CheckList,
        LoaderComponent,
        AttachmentForm
    },
    props: {
        reply: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                id: "",
                postId: "",                
                content: "",
                attachments: []
            },
            editing: false,
            replyHints,
            replyHintsTitle: "Hints",
            showErrorMessage: false,
            isLoading: false,
            newAttachments: [],
            documentsToUpload: [],
            resetFileUpload: false
        }
    },
    computed:{
        postId() {
            return this.$route.params?.id
        },
        submitButtonTitle() {
            return this.editing ? 'Wijzigingen opslaan' : 'Reageren'
        },
        isDirty() {
            if(this.editing) {
                const isFormChanged = Object.keys(this.form).some(key => this.form[key] !== this.reply[key])
                const isAttachmentsRemoved = !isEqual(this.reply.attachments, this.newAttachments)
                const isNewFileUploaded = !!this.documentsToUpload?.length
            
                return isFormChanged || isAttachmentsRemoved || isNewFileUploaded
            }
             
            return this.documentsToUpload?.length || this.form.content !== ""
        }
    },
    validations() {
        return {
            form: {
                content: { required }
            }
        }
    },
    mounted() {
        if(this.reply){
            this.form = {...this.reply}
            this.editing = true
        }
    },
    methods: {
        async submit() {
            if (this.editing) await this.updateReply()
            else await this.createReply()
        },
        cancel() {
            if (this.editing) this.confirmAndCancelEdit()
            else this.reset()
        },
        async createReply() {
            this.form.postId = this.postId

            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            try {
                this.resetFileUpload = false
                this.isLoading = true
         
                if(this.documentsToUpload?.length) {
                    this.form.attachments = await this.getUploadedAttachments(this.documentsToUpload)
                }
           
                await socialLearningClient.createReply(this.form)
                this.reset()
                this.$emit('reload')
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        reset() {
            this.$v?.$reset()
            this.showErrorMessage = false
            this.form.content = ""
            this.form.attachments = []
            this.documentsToUpload = []
            this.resetFileUpload = true
        },
        async updateReply() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            try {
                this.isLoading = true
                this.showErrorMessage = false
                
                const patchArray = []
                if (this.form.content !== this.reply.content) {
                    patchArray.push({
                        op: 'replace',
                        path: `/content`,
                        value: this.form.content
                    })
                }
                if (this.newAttachments !== this.reply.attachments) {
                    const originalAttachments = this.reply.attachments
                    const deletedAttachments = originalAttachments.filter(oa => !this.newAttachments.includes(oa))
                    
                    // Repeated patch remove using array index changes the position of array elements (shifts left for every remove operation). 
                    // So sending the array index in reverse order to avoid wrong items being removed due to the previous remove operation.
                    deletedAttachments.reverse().forEach(attachment => {
                        patchArray.push({
                            op: 'remove',
                            path: `/attachments/${originalAttachments.indexOf(attachment)}`
                        })
                    })
                }

                if(this.documentsToUpload?.length){
                    const newAttachments = await this.getUploadedAttachments(this.documentsToUpload)

                    newAttachments.forEach(attachment => {                        
                        patchArray.push({
                            op: 'add',
                            path: `/attachments/-`,
                            value: attachment
                        })
                    })
                }

                await socialLearningClient.updateReply(patchArray,this.postId, this.reply.id)

                this.editing = false
                this.$emit('resetEditing')
                // redirect to the post details page after update
                this.$emit('reload')
            } catch (error) {
                this.showErrorMessage = true
                throw new Error("Failed to update post", error)             
            } finally {
                this.isLoading = false
            }
        },
        async getUploadedAttachments(documentsToUpload) {
            try {
                this.$emit('is-uploading', true)
                
                const uploadedAttachments = await socialLearningClient.uploadAttachments(documentsToUpload)

                return uploadedAttachments?.map(({fileName, url}) => {
                    return {
                        description: fileName,
                        url
                    }
                })
            } catch (error) {
                throw new Error("Failed to upload attachments", error)
            } finally {
                this.$emit('is-uploading', false)
            }
        },
        confirmAndCancelEdit() {
            if(this.isDirty) {
                const answer = window.confirm(
                    'Weet u zeker dat u deze pagina wilt verlaten? U hebt mogelijk niet-opgeslagen wijzigingen.'
                ) 
                if (!answer) 
                    return false
            }
            this.editing = false
            this.$emit('resetEditing')
            return true
        }
    }
}
</script>
<template>
  <recess-card v-if="Object.keys(post).length" bordered has-hover :to="{name: 'post-details', params: {id: post.id}}">
    <div class="c-social-learning__post-header" data-test="learning-post-header">
      <h4 class="mb-0 c-social-learning__post-title">{{ post.title }}</h4>
  
      <recess-divider variant="xsmall" />

      <div v-if="tags.length" class="c-social-learning__post-tag-group">
        <div v-for="(tag, index) in tags" :key="index" class="c-social-learning__post-tag" data-test="learning-post-tag">
          <span class="u-text-small">{{ tag.displayValue }}</span>
        </div>
      </div>
    </div>

    <recess-divider variant="xsmall" />

    <!-- eslint-disable-next-line vue/no-v-html, Content is sanitized  -->
    <p class="c-social-learning__post-content s-post-content" data-test="learning-post-content" v-html="content" ></p>

    <recess-divider show-line />

    <div class="d-flex justify-content-between">
      <p data-test="post-creation-date-author">{{creationDate}} <strong v-if="post.authorName"> {{ post.authorName }}</strong></p>
      <p class="pr-2" data-test="replies-count"><strong>{{ post.totalReplies }}</strong> reacties</p>
    </div>
  </recess-card>
</template>

<script>
import { computed } from 'vue'
import { cutOff } from '../../../../modules/textFormatter'
import DateUtils from '../../../../utils/DateUtils'

export default {
    props: {
        post: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
        const tags = computed(() => props.post.tags)
        const creationDate = computed(() => DateUtils.formatDateTime(props.post.creationDate))
        const content = computed(() => cutOff(props.post.content, 200))

        return {
            tags,
            creationDate,
            content
        }
    }
}
</script>

<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isApproving"
        overlay="white"
        color="primary"
      />
    </transition>

    <template v-if="enrollment">
      <h4 class="u-textcolor-primary">Autorisatie</h4>
      <table
        aria-label="Approver information"
        class="w-100"
      >
        <tbody>
          <tr v-if="enrollment.approver">
            <th
              scope="row"
              class="text-left"
            >
              Eerste goedkeurder
            </th>
            <td class="w-50">
              {{ enrollment.approver.fullName }}
              <span v-if="enrollment.approver.emailAddress">({{ enrollment.approver.emailAddress }})</span>
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Eerste goedkeur datum
            </th>
            <td>{{ format(enrollment.dateTimeApproval) }}</td>
          </tr>
          <tr v-if="assignedSecondApproverName">
            <th
              scope="row"
              class="text-left"
            >
              Toegewezen tweede beoordelaar
            </th>
            <td>
              {{ assignedSecondApproverName }}
              <span v-if="assignedSecondApproverEmail">({{ assignedSecondApproverEmail }})</span>
            </td>
          </tr>
          <tr v-if="$isAllowed('displayCostCenter')">
            <th
              scope="row"
              class="text-left"
            >
              Kostenplaats
            </th>
            <td>{{ enrollment.costCenter }}</td>
          </tr>
          <tr v-if="$isAllowed('displayPONumber')">
            <th
              scope="row"
              class="text-left"
            >
              PO
            </th>
            <td>{{ enrollment.purchaseOrderNumber }}</td>
          </tr>
          <tr v-if="enrollment.studyContractTemplate">
            <th
              scope="row"
              class="text-left"
            >
              Studieovereenkomst
            </th>
            <td>{{ enrollment.studyContractTemplate.name }}</td>
          </tr>
          <tr v-if="$isAllowed('displayKeyAccounts') && enrollment.keyAccount && enrollment.keyAccount.name">
            <th
              scope="row"
              class="text-left"
            >
              Key-Account
            </th>
            <td>{{ enrollment.keyAccount.name }}</td>
          </tr>
          <tr v-if="isSubsidyEnabled && enrollment.isSubsidized">
            <th
              scope="row"
              class="text-left"
            >
              Subsidie
            </th>
            <td>Ja</td>
          </tr>
        </tbody>
      </table>

      <recess-divider />

      <template v-if="!isApproving">
        <recess-button
          :disabled="!enrollmentHasValidStartMoments"
          data-test="approve-button"
          class="u-position-right ml-4 mb-3 mb-md-0 col-12 col-md-auto"
          title="Goedkeuren"
          variant="primary"
          @click="approve"
        />
        <recess-button
          data-test="reject-button"
          class="u-position-right mb-3 mb-md-0 col-12 col-md-auto"
          title="Afkeuren"
          variant="tertiary"
          @click="$emit('reject')"
        />

        <recess-button
          title="Terug"
          variant="tertiary"
          class="col-12 col-md-auto"
          @click="$emit('redirect')"
        />
      </template>
      <recess-alert
        v-show="showServerErrorMessage"
        class="mt-3"
        type="error"
        text="Er is iets misgegaan. Probeer het later nog een keer of neem contact op met een administrator."
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import EnrollmentClient from '../../../../api/enrollmentClient'
import DateUtils from '../../../../utils/DateUtils'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'

export default {
    components: {
        LoaderComponent
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            isApproving: false,
            showServerErrorMessage: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['isSubsidyEnabled']),
        ...mapGetters('enrollmentModule', ['enrollmentHasValidStartMoments']),
        ...mapState('enrollmentModule', ['enrollment']),
        assignedSecondApproverName() {
            return this.enrollment?.assignedSecondApprover?.fullName
        },
        assignedSecondApproverEmail() {
            return this.enrollment?.assignedSecondApprover?.emailAddress
        }
    },
    methods: {
        approve() {
            this.isApproving = true

            const enrollmentId =
                this.enrollment.enrollmentId || this.$route.query.enrollment || null

            EnrollmentClient.secondApproveEnrollment(enrollmentId)
                .then(response => {
                    if (!response) {
                        this.showServerErrorMessage = true
                        return
                    }

                    this.$emit('redirect')
                })
                .catch(err => {
                    console.error(err)
                    this.showServerErrorMessage = true
                })
                .then(() => {
                    this.isApproving = false
                })
        },
        format: e => DateUtils.formatDate(e)
    }
}
</script>

import { getOplzClient } from './apiClient'

const baseUrl = '/account-custom-text'

const getCustomTexts = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, data).then(response => {
        return response?.data
    })
}

export default {
    getCustomTexts
}

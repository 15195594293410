<template>
  <div v-if="$isAllowed('displayBudgets')">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetchingBudget"
        overlay="white"
        color="primary"
      />
    </transition>
    <h1>Wijzigingen {{ decodeURIComponent($route.query.name) }}</h1>
    <p>Op deze pagina ziet u alle wijzigingen die gedaan zijn binnen dit budget.</p>
    <transition name="fade">
      <div v-if="budgetChanges">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="(header,index) in budgetChangesHeaders"
                :key="index"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="(budgetChange, index) in budgetChanges"
              :key="index"
            >
              <recess-table-cell :data-label="budgetChangesHeaders[0].title">
                <span
                  v-if="budgetChange.changeType == budgetChangeTypes.BUDGET_CHANGE"
                  :class="{'font-weight-bold': isChanged(budgetChange, 'BudgetName') }"
                >{{ budgetChange.budgetName }}</span>
                <div v-if="budgetChange.changeType == budgetChangeTypes.BUDGET_TRANSACTION">
                  <router-link
                    v-if="budgetChange.eduMSCourseId"
                    :to="{ name: 'course', query: { course: budgetChange.eduMSCourseId, assortment: budgetChange.assortmentId }}"
                  >
                    {{ budgetChange.courseName }}
                  </router-link>
                  <span v-else>
                    {{ budgetChange.externalReference }}
                  </span>
                </div>
              </recess-table-cell>
              <recess-table-cell :data-label="budgetChangesHeaders[1].title">
                <span
                  :class="{'font-weight-bold': isChanged(budgetChange, 'StartDate') }"
                >{{ budgetChange.startDate ? formatDate(budgetChange.startDate) : "" }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="budgetChangesHeaders[2].title">
                <span
                  :class="{'font-weight-bold': isChanged(budgetChange, 'EndDate') }"
                >{{ budgetChange.endDate ? formatDate(budgetChange.endDate) : "" }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="budgetChangesHeaders[3].title">
                <span
                  v-if="budgetChange.changeType == budgetChangeTypes.BUDGET_CHANGE"
                  :class="{'font-weight-bold': isChanged(budgetChange, 'BaseBudget') }"
                >{{ formatPrice(budgetChange.baseBudget) }}</span>
              </recess-table-cell>
              <recess-table-cell
                :data-label="budgetChangesHeaders[4].title"
              >
                <div class="d-flex flex-column font-weight-bold">
                  <span
                    v-if="budgetChange.changeType == budgetChangeTypes.BUDGET_TRANSACTION"
                  >{{ formatPrice(budgetChange.transactionAmount * -1) }}</span>
                  <span
                    v-if="getTransactionTypeText(budgetChange)"
                    data-test="budget-transaction-type"
                  >{{ getTransactionTypeText(budgetChange) }}</span>
                </div>
              </recess-table-cell>
              <recess-table-cell :data-label="budgetChangesHeaders[5].title">
                <span>{{ formatPrice(budgetChange.availableBudget) }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="budgetChangesHeaders[6].title">
                <span>{{ budgetChange.modifiedBy }}</span>
              </recess-table-cell>
              <recess-table-cell
                :data-label="budgetChangesHeaders[7].title"
              >
                <span>{{ formatDateTime(budgetChange.changeDate) }}</span>
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <recess-divider />
      </div>
    </transition>
    <recess-alert
      v-if="!budgetChanges && !isFetchingBudget"
      text="Het budget kan niet gevonden worden."
    />
  </div>
</template>

<script>
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { getBudgetChanges } from '../../../api/budgetClient'
import DateUtils from '../../../utils/DateUtils'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import priceFormatter from '../../../modules/priceFormatter'
import budgetChangeTypes from '../../../constants/budgetChangeTypes'

export default {
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent
    },
    data() {
        return {
            isFetchingBudget: false,
            budgetChanges: {},
            budgetChangesHeaders: [
                { key: 'name', title: 'Naam budget / Ontwikkelactiviteit / Ref' },
                { key: 'startDate', title: 'Ingangsdatum budget' },
                { key: 'endDate', title: 'Einddatum budget' },
                { key: 'startAmount', title: 'Startbedrag' },
                { key: 'transaction', title: 'Transactie' },
                { key: 'currentAmount', title: 'Resterend budget' },
                { key: 'modifiedByUser', title: 'Wijziging gedaan door' },
                { key: 'dateOfChange', title: 'Datum wijziging' }
            ],
            budgetChangeTypes
        }
    },
    created() {
        this.fetchBudget()
    },
    methods: {
        async fetchBudget() {
            try {
                this.isFetchingBudget = true
                this.budgetChanges = await getBudgetChanges(this.$route?.params?.id)
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isFetchingBudget = false
            }
        },
        isChanged(budgetChange, field) {
            return budgetChange.changedFields?.includes(field)
        },
        getTransactionTypeText(transaction) {
            const isNegativeTransaction = transaction.transactionAmount < 0
            // External transactions never have an enrollment associated, and so a courseId 
            const isExternalTransaction = !transaction.eduMSCourseId

            if(isNegativeTransaction && isExternalTransaction)
                return `(Aanpassing budgethouder)`
            
            if(isNegativeTransaction)
                return `(Teruggestort vanwege annulering)`

            return null
        },
        formatDate: e => DateUtils.formatDate(e),
        formatDateTime: t => DateUtils.formatDateTime(t),
        formatPrice: priceFormatter.formatPrice
    }
}
</script>


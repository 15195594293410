<template>
  <div>
    <transition name="fade">
      <loader-component
        v-if="isLoading"
        is-fixed
        overlay="white"
        color="primary"
      />
    </transition>
    <template v-if="course">
      <!-- Course title -->
      <div class="py-5 u-bg--variant5">
        <recess-layout-static>
          <div class="row">
            <div class="col-12 col-md-10">
              <h1 class="mb-0">{{ course.name }}</h1>
            </div>
          </div>
        </recess-layout-static>
      </div>

      <!-- Course content -->
      <recess-layout-static>
        <div class="row">
          <recess-divider />

          <!-- Course top-left summary  -->
          <div class="col-12 col-lg-4">
            <recess-card bordered>
              <recess-list class="p-0">
                <recess-list-row>
                  <!-- Rating stars -->
                  <div class="c-rating-holder d-flex align-items-center">
                    <feedback-stars-component :score="courseRating && courseRating.rating || 0" />

                    <span class="ml-2">({{ courseRating && courseRating.count || 0 }})</span>
                  </div>
                  <!-- Wishlist toggle -->
                  <wish-list-toggle-component
                    :course-id="course.id"
                    :is-on-wish-list="course.isOnWishlist"
                    @wish-list-toggled="updateUserWishList"
                  />
                </recess-list-row>
              </recess-list>
              <recess-divider show-line />
              <!-- Summary general info -->
              <course-summary-info :course="course" />

              <recess-divider
                v-if="$isAllowed('displayPricingInformation')"
                show-line
              />
              <!-- Price info -->
              <price-summary-component
                v-if="$isAllowed('displayPricingInformation')"
                :course-id="course.id"
              />
              <template v-if="showEnrollmentButton">
                <recess-divider show-line />
                <recess-button
                  title="Inschrijven"
                  variant="secondary"
                  class="w-100"
                  data-test="next-step-button"
                  @click="$emit('course-selected', course)"
                />
              </template>
            </recess-card>

            <recess-divider />

            <!-- Contact info desktop -->
            <div class="d-none d-lg-block">
              <contact-teaser-component />
            </div>
          </div>

          <!-- Tabs content -->
          <div class="col-12 col-lg-8">
            <!-- Course page tabs (desktop) -->
            <course-page-main>
              <template #course-page-tabs>
                <course-page-tabs>
                  <template #planning-content>
                    <course-page-content-planning>
                      <template #startmoments-content>
                        <start-moments-listing-table 
                          v-if="showStartmoments && mappedStartMoments && mappedStartMoments.length"
                          :course="course"
                          :start-moments="mappedStartMoments"
                          :table-header="startMomentsTableHeader"
                          :nr-of-people-to-enroll="selectedStudents && selectedStudents.length"
                          :show-capacity="showCapacity"
                          :assortment-id="courseAssortment"
                          :facets="facets"
                          :total-count="totalCount"
                          :page-number="pageNumber"
                          :page-size="top"
                          @pagination-clicked="val => onPaginationClicked(val)"
                          @facet-change="selectedFacets => setFacets(selectedFacets)"
                        >
                          <template
                            slot="startmoment-action-button"
                            slot-scope="props">
                            <recess-button
                              title="Inschrijven"
                              class="col-12 col-md-auto"
                              :disabled="props.startMoment.blockFullyBookedIncompanyStartMoment"
                              variant="primary"
                              @click="addStartMomentAndSelectCourse(props.startMoment)"
                            />
                          </template>
                        </start-moments-listing-table>
                        
                        <recess-alert
                          v-if="showNoStartMomentsMessage"
                          text="Indien u de knop 'inschrijven' ziet, kunt u zich inschrijven voor de ontwikkelactiviteit. 
      Als deze knop niet zichtbaar is dan zijn er geen startmomenten beschikbaar om op in te schrijven. 
      Indien u een groep van 5 collega's of meer heeft, die de ontwikkelactiviteit willen volgen, 
      bestaat de mogelijkheid tot het organiseren van een incompany ontwikkelactiviteit. Neem daarvoor contact met ons op."
                        />
                      </template>
                    </course-page-content-planning>
                  </template>
                </course-page-tabs>
              </template>
              <!-- Course page accordion (mobile) -->
              <template #course-page-accordion>
                <course-page-accordion>
                  <template #planning-content>
                    <course-page-content-planning>
                      <template #startmoments-content>
                        <start-moments-listing-table 
                          v-if="showStartmoments && mappedStartMoments && mappedStartMoments.length"
                          :start-moments="mappedStartMoments"
                          :table-header="startMomentsTableHeader"
                          :nr-of-people-to-enroll="selectedStudents && selectedStudents.length"
                          :show-capacity="showCapacity"
                          :course="course"
                          :assortment-id="courseAssortment"
                          :facets="facets"
                          :total-count="totalCount"
                          :page-number="pageNumber"
                          :page-size="top"
                          @facet-change="selectedFacets => setFacets(selectedFacets)"
                          @pagination-clicked="val => onPaginationClicked(val)"
                        >
                          <template
                            slot="startmoment-action-button"
                            slot-scope="props">
                            <recess-button
                              title="Inschrijven"
                              :disabled="props.startMoment.blockFullyBookedIncompanyStartMoment"
                              variant="primary"
                              class="w-100"
                              @click="addStartMomentAndSelectCourse(props.startMoment)"
                            />
                          </template>
                        </start-moments-listing-table>
                        
                        <recess-alert
                          v-if="showNoStartMomentsMessage"
                          text="Indien u de knop 'inschrijven' ziet, kunt u zich inschrijven voor de ontwikkelactiviteit. 
      Als deze knop niet zichtbaar is dan zijn er geen startmomenten beschikbaar om op in te schrijven. 
      Indien u een groep van 5 collega's of meer heeft, die de ontwikkelactiviteit willen volgen, 
      bestaat de mogelijkheid tot het organiseren van een incompany ontwikkelactiviteit. Neem daarvoor contact met ons op."
                        />
                      </template>
                    </course-page-content-planning>
                  </template>
                </course-page-accordion>
              </template>
            </course-page-main>
          </div>

          <!-- Contact info mobile-->
          <div class="col-12 d-lg-none">
            <recess-divider />
            <contact-teaser-component />
          </div>
        </div>

        <recess-divider />
      </recess-layout-static>
    </template>

    <recess-layout-static>
      <recess-alert
        v-if="showErrorMessage"
        class="my-5"
        type="error"
        text="Deze ontwikkelactiviteit is op dit moment niet (meer) beschikbaar. Excuus voor het ongemak."
      />
    </recess-layout-static>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import LoaderComponent from '../../../../atoms/LoaderComponent/LoaderComponent.vue'
import FeedbackStarsComponent from '../../../../molecules/FeedbackStarsComponent/FeedbackStarsComponent.vue'
import CourseSummaryInfo from '../../../CoursePage/CourseSummaryInfo.vue'
import CoursePageMain from '../../../CoursePage/CoursePageMain.vue'
import CoursePageTabs from '../../../CoursePage/CoursePageTabs.vue'
import CoursePageContentPlanning from '../../../CoursePage/CoursePageContentPlanning.vue'
import CoursePageAccordion from '../../../CoursePage/CoursePageAccordion.vue'
import PriceSummaryComponent from '../../../PriceSummaryComponent/PriceSummaryComponent.vue'
import ContactTeaserComponent from '../../../../molecules/ContactTeaserComponent/ContactTeaserComponent.vue'
import WishListToggleComponent from '../../../WishListToggleComponent/WishListToggleComponent.vue'
import courseClient from '../../../../../api/courseClient'
import * as courseLearningMethodTypes from '../../../../../constants/courseLearningMethodTypes'
import startMomentHelper from '../../../../../modules/startMomentHelper'
import featuresPerimeter from '../../../../../perimeters/featuresPerimeter'
import EnrollmentSearchStartMomentsMixin from '../../../../../mixins/enrollment-search-start-moments-mixin'
import StartMomentsListingTable from '../../../StartMomentsListingTable/StartMomentsListingTable.vue'

export default {
    name: 'CoursePage',
    components: {
        CoursePageMain,
        FeedbackStarsComponent,
        LoaderComponent,
        CourseSummaryInfo,
        CoursePageTabs,
        CoursePageContentPlanning,
        StartMomentsListingTable,
        CoursePageAccordion,
        PriceSummaryComponent,
        WishListToggleComponent,
        ContactTeaserComponent
    },
    mixins: [ EnrollmentSearchStartMomentsMixin ],
    perimeters: [featuresPerimeter],
    data() {
        return {
            showErrorMessage: false,
            isFetchingCourse: false,
            isFetchingCourseRating: false,
            courseRating: null
        }
    },
    computed: {
        ...mapState('coursePageModule', ['course']),
        ...mapGetters('accountModule', ['displayVatInPrices']),
        ...mapGetters('coursePageModule', ['productType']),
        ...mapGetters('enrollmentModule', ['selectedStudents', 'selectedStartMomentIds']),
        isLoading() {
            return this.isFetchingCourse || this.isFetchingCourseRating
        },
        isELearning() {
            return this.course?.learningMethod?.type?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED
        },
        showEnrollmentButton() {
            return (
                this.course?.startMoments?.length || this.isELearning
            )
        },
        showNoStartMomentsMessage() {
            return !this.showStartmoments && !this.startMoments?.length
        },
        startMomentsTableHeader() {                    
            return startMomentHelper.getStartMomentPlanningHeader(this.showCapacity, this.displayVatInPrices, this.$isAllowed('displayPricingInformation'))
        },
        mappedStartMoments() {
            return startMomentHelper.mapStartMoments(this.startMoments, this.limitIncompanyEnrollmentsAndIsIncompany, this.productType)
        }
    },
    mounted() {
        this.initialize()
    },
    destroyed() {
        this.SET_COURSE(null)
        this.SET_PRICE(null)
    },
    methods: {
        ...mapMutations('coursePageModule', ['SET_COURSE', 'SET_PRICE']),
        ...mapMutations('userModule', ['toggleUserCourseWishListItem']),
        ...mapMutations('enrollmentModule', [
            'setSelectedStartMoment',
            'clearStartMoments',
            'setCourse',
            'setCourseAssortment'
        ]),
        ...mapActions('enrollmentModule', ['checkApprovalRequired']),
        ...mapMutations({
            setEnrollmentModuleCourse: 'enrollmentModule/setCourse',
            resetStartMomentsState: 'startMomentsModule/RESET_STATE'
        }),
        async initialize() {
            const courseId = this.$route.query.course
            if (!courseId) {
                this.showErrorMessage = true
                return
            }

            await this.setAssortment()
            await this.initializeCourseData(courseId)
        },
        async initializeCourseData(courseId) {
            try {
                this.isFetchingCourse = true

                const [ course, price ] = await Promise.all([
                    courseClient.getCourse(courseId), 
                    courseClient.getPrice(courseId, null, this.courseAssortment),
                    this.fetchCourseRating(courseId)
                ])

                if (!course) {
                    this.showErrorMessage = true
                    return
                }

                await this.SET_COURSE(course)
                await this.SET_PRICE(price)
                this.setEnrollmentModuleCourse(course)
                if(course.startMoments?.length) {
                    await this.initializeStartMoments()
                }
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isFetchingCourse = false
            }
        },
        async fetchCourseRating(courseId) {
            try {
                this.isFetchingCourseRating = true
            
                const courseRating = await courseClient.getCourseRating(courseId)

                this.courseRating = courseRating
            } catch {
            // do nothing
            } finally {
                this.isFetchingCourseRating = false
            }
        },
        updateUserWishList() {
            const newWishListItem = {
                $id: null,
                courseId: this.course.id,
                userId: null
            }
            this.toggleUserCourseWishListItem(newWishListItem)
        },
        async setAssortment() {
            const assortmentId = this.courseAssortment || this.$route.query?.assortment
            await this.setCourseAssortment(assortmentId)
        },
        async addStartMomentAndSelectCourse(startMoment) {        
            await this.setAssortment()
            
            // Always reset startMoments when a startmoment is selected from the planning
            this.clearStartMoments()
            this.resetStartMomentsState()
            this.setSelectedStartMoment(startMoment)

            await this.checkApprovalRequired(this.selectedStartMomentIds)

            this.$emit('next-step')
        }
    }
}
</script>
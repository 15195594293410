export default class ODataQuery {
    constructor(skip, take, filter, orderBy, include, fields) {
        this.skip = skip
        this.take = take
        this.filter = filter
        this.orderBy = orderBy
        this.include = include
        this.fields = fields
    }

    setSort(fields, direction) {
        if(!Array.isArray(fields)) {
            this.orderBy = `${fields} ${direction}`
        }
        else if(fields.length > 0){
            let order = ''
            fields.forEach(field => {
                order += `${field} ${direction}, `
            })
            // trim ending
            this.orderBy = order.slice(0, -2)
        }
    }

    setPagination(skip, take) {
        this.skip = skip
        this.take = take
    }

    setIncludes(includes) {
        this.include = includes
    }

    setFields(fields) {
        this.fields = fields
    }
}

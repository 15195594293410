<template>
  <div>
    <recess-divider />
    <h2>Welke e-mails wil je ontvangen?</h2>
    <span>
      Selecteer hieronder van welke meldingen je graag een e-mailbericht wilt ontvangen in je
      eigen mailbox.
    </span>
    <recess-table>
      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="showLoader"
          variant="small"
          overlay="white"
          color="primary"
        />
      </transition>
      <recess-table-head>
        <recess-table-row>
          <recess-table-head-item />
          <recess-table-head-item />
        </recess-table-row>
      </recess-table-head>
      <recess-table-body>
        <recess-table-row
          v-for="(email, index) in emailTemplates"
          :key="index"
          class="d-flex "
        >
          <recess-table-cell class="text-left">
            {{ email.description }}
          </recess-table-cell>
          <recess-table-cell class="d-flex align-items-center justify-content-end">
            <recess-toggle-input
              v-model="email.isActive"
              @input="toggleEmailTemplate(email)"
            />
          </recess-table-cell>
        </recess-table-row>
      </recess-table-body>
    </recess-table>
  </div>
</template>

<script>
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import emailTemplateClient from "../../../api/emailTemplateClient"

export default {
    components: {
        LoaderComponent
    },
    props: {
        disabledEmailTemplates: {
            type: Array,
            default: () => []
        },
        emailTemplates: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showLoader: false,
            mDisabledEmailTemplates: []
        }
    },
    watch: {
        disabledEmailTemplates: {
            handler(newValue) {
                if (newValue) {
                    this.mDisabledEmailTemplates = newValue
                }
            },
            immediate: true
        }
    },
    methods: {
        async toggleEmailTemplate(email) {
            try {
                this.showLoader = true
                if(this.mDisabledEmailTemplates.includes(email.templateId)) {
                    this.mDisabledEmailTemplates = this.mDisabledEmailTemplates.filter(x => x !== email.templateId)
                } else  {
                    this.mDisabledEmailTemplates.push(email.templateId)
                }

                if(!this.mDisabledEmailTemplates.length) {
                    this.$emit('enable-all-emails')
                    return
                }

                await emailTemplateClient.toggleEmailTemplate(this.mDisabledEmailTemplates)
            } catch (error) {
                throw new Error(error)
            } finally {
                this.showLoader = false
            }
        } 
    }
}
</script>
<template>
  <div
    class="c-banner"
    :class="bannerHeightClass"
    data-test="banner"
  >
    <div
      v-if="isfixed"
      class="c-banner__placeholder"
    ></div>
    <div
      class="c-banner__wrapper"
      :class="[imageAlignmentClass, { 'is-fixed': isfixed }]"
    >
      <div class="c-banner__container">
        <picture
          class="c-banner__picture"
          :class="{ 'is-playing': isPlaying }"
        >
          <source
            v-if="xSmallImage"
            :media="mediaQueries.xSmall"
            :srcset="xSmallImage"
          />
          <source
            v-if="smallImage"
            :media="mediaQueries.small"
            :srcset="smallImage"
          />
          <source
            v-if="mediumImage"
            :media="mediaQueries.medium"
            :srcset="mediumImage"
          />
          <source
            v-if="largeImage"
            :media="mediaQueries.large"
            :srcset="largeImage"
          />
          <source
            v-if="xLargeImage"
            :media="mediaQueries.xLarge"
            :srcset="xLargeImage"
          />
          <img
            :src="imageFallback"
            class="c-banner__image"
            alt
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import { cssMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'

export default {
    mixins: [cssMediaQueriesMixin],
    props: {
        // DataSource
        imageAlignment: {
            type: String,
            default: 'right',
            validator(value) {
                const allowedAlignments = ['left', 'right', 'centered']
                return allowedAlignments.indexOf(value) > -1
            }
        },
        xSmallImage: {
            type: String,
            default: null
        },
        smallImage: {
            type: String,
            default: null
        },
        mediumImage: {
            type: String,
            default: null
        },
        largeImage: {
            type: String,
            default: null
        },
        xLargeImage: {
            type: String,
            default: null
        },
        youtubeId: {
            type: String,
            default: ''
        },
        // Settings
        playerWidth: {
            type: String,
            default: '1920'
        },
        playerHeight: {
            type: String,
            default: '1080'
        },
        // Properties
        isfixed: {
            type: Boolean,
            default: false
        },
        bannerHeight: {
            type: String,
            default: 'low',
            validator(value) {
                const allowedHeights = ['low', 'medium', 'high']
                return allowedHeights.indexOf(value) > -1
            }
        }
    },
    data() {
        return {
            player: null,
            isPlaying: false,
            loopInterval: null,

            playerVars: {
                modestbranding: 1,
                showinfo: 0,
                autoplay: 1,
                controls: 0,
                rel: 0
            }
        }
    },
    computed: {
        getPlayerHeight() {
            // Increase the video height by 200px because there is a margin-top set by styling to hide stuff in the video player
            return parseInt(this.playerHeight, 10) + 200
        },
        shouldShowPlayer() {
            return this.youtubeId
        },
        imageAlignmentClass() {
            const imageAlignment = this.imageAlignment.toLowerCase()
            return `c-banner--image-${imageAlignment}`
        },
        bannerHeightClass() {
            const bannerHeight = this.bannerHeight.toLowerCase()
            return `c-banner--height-${bannerHeight}`
        },
        imageFallback() {
            return (
                this.xLargeImage ||
                this.largeImage ||
                this.mediumImage ||
                this.smallImage ||
                this.xSmallImage ||
                ''
            )
        }
    },
    watch: {
        youtubeId(newValue, oldValue) {
            // If there is no video on the new page, stop playing
            if (!this.shouldShowPlayer) return this.stop()
            // If there is another video on the page, change the video
            if (newValue !== oldValue) return this.change(newValue)

            return null
        }
    },
    methods: {
        ready(player) {
            const target = player.target

            this.player = target
            // This interval will make the video loop, this custom code prevents a 'black screen' because it seeks back to the beginning of the video
            this.loopInterval = setInterval(() => {
                if (this.isPlaying) {
                    if (target.getCurrentTime() / target.getDuration() > 0.98) {
                        target.seekTo(0)
                    }
                }
            }, 100)
        },
        playing() {
            // The player is playing a video.
            this.updatePlayerState(true)
        },
        change(videoId) {
            // when you change the value, the player will also change.
            // If you would like to change `playerVars`, please change it before you change `videoId`.
            // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
            // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
            this.videoId = videoId
            // Change isPlaying to false because we want to see the banner while changing the video
            this.updatePlayerState(false)
        },
        stop() {
            // Stop the player
            this.player.stopVideo()
            this.updatePlayerState(false)
        },
        pause() {
            // Pause the player
            this.player.pauseVideo()
            this.updatePlayerState(false)
        },
        updatePlayerState(isPlaying) {
            this.isPlaying = this.player != null && isPlaying
        }
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />
    
    <p
      data-test="explanation-text"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      {{ explanationText }}
    </p>

    <transition name="fade">
      <div
        v-if="visitedCourses && visitedCourses.length"
      >
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="headerKey in Object.keys(headers)"
                :key="headers[headerKey].key"
              >
                {{ headers[headerKey].title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="visitedCourse in visitedCourses"
              :key="visitedCourse.id"
              :is-disabled="visitedCourse.deleted"
            >
              <recess-table-cell :data-label="headers.courseName.title">
                <router-link
                  v-if="!visitedCourse.deleted"
                  :to="getCourseUrl(visitedCourse)"
                  data-test="visited-course-name"
                >
                  <span v-html="visitedCourse.courseName"></span>
                </router-link>
                <span v-else v-html="visitedCourse.courseName"></span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers.provider.title">
                <template v-if="showProviderInfo(visitedCourse)">
                  <img
                    v-if="visitedCourse.providerLogoUrl"
                    class="u-max-width-100 u-max-height-40"
                    alt="Provider logo"
                    :src="visitedCourse.providerLogoUrl"
                  />
                  <span v-else>{{ visitedCourse.providerName }}</span>
                </template>
                <span v-else>-</span>
              </recess-table-cell>

              <recess-table-cell
                v-if="$isAllowed('displayPricingInformation')"
                :data-label="headers.price.title"
              >
                <span data-test="price_with_or_without_vat">
                  {{ priceText(visitedCourse) }}
                </span>
                <span
                  v-if="visitedCourse.price"
                  data-test="vat_incl_or_excl"
                  class="font-italic ml-2 u-text-small"
                >{{ priceVatValue(visitedCourse.vatIncluded) }}</span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers.duration.title">
                <span>
                  {{ durationText(visitedCourse) }}
                </span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers.dateModified.title">
                <span>
                  {{ formatDateTime(visitedCourse.utcDateModified) }}
                </span>
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
      </div>
    </transition>

    <recess-alert
      v-if="showNoVisitedCoursesMessage"
      text="Er zijn geen resultaten."
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import DateUtils from '../../../utils/DateUtils'
import priceFormatter from '../../../modules/priceFormatter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import { getVisitedCourses } from '../../../api/visitedCoursesClient'

export default {
    name: "MyRecentlyVisitedCourses",
    perimeters: [ featuresPerimeter ],
    components: {
        LoaderComponent
    },
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            headers: {
                courseName: { key: 'courseName', title: 'Ontwikkelactiviteit' }, 
                provider: { key: 'provider', title: 'Uitgevoerd door' },
                price: { key: 'price', title: 'Prijs' },
                duration: { key: 'duration', title: 'Duur' },
                dateModified: { key: 'utcDateModified', title: 'Datum bekeken' }
            },
            visitedCourses: [],
            showNoVisitedCoursesMessage: false,
            isLoading: false
        }
    },
    computed: {
        ...mapGetters('oleProvidersModule', ['showProviderPrice'])
    },
    mounted() {
        this.fetchVisitedCourses()
        if(!this.$isAllowed('displayPricingInformation')) delete this.headers.price
    },
    methods: {
        async fetchVisitedCourses() {
            try {
                this.isLoading = true
                const result = await getVisitedCourses()

                if(!result?.length) {
                    this.showNoVisitedCoursesMessage = true
                } else {
                    this.visitedCourses = result
                }
            } catch (error) {
                this.showNoVisitedCoursesMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        getCourseUrl(visitedCourse) {
            return { 
                name: 'course', 
                query: { 
                    course: visitedCourse.eduMSCourseId, 
                    assortment: visitedCourse.assortmentId ?? undefined
                }
            }
        },
        showProviderInfo(visitedCourse) {
            return visitedCourse.providerLogoUrl || visitedCourse.providerName
        },
        durationText(visitedCourse) {
            return visitedCourse.courseDuration && visitedCourse.courseDurationUnit 
                ? `${visitedCourse.courseDuration} ${visitedCourse.courseDurationUnit}` 
                : '-'
        },
        priceText(visitedCourse) {
            const priceValue = this.showProviderPrice(visitedCourse) ? visitedCourse.price : 0
            return visitedCourse.price
                ? `${this.formatPrice(priceValue)}`
                : '-'
        },
        priceVatValue(vatIncluded) {
            return coursePriceHelper.getVatText(vatIncluded)
        },
        formatDateTime: t => DateUtils.formatDateTime(t),
        formatPrice: priceFormatter.formatPrice
    }
}
</script>
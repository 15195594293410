<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="$isAllowed('displayLearningPath')"
    class="c-learning-path"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetchingLearningPath"
        overlay="white"
        color="primary"
      />
    </transition>
    <!-- Go back button -->
    <recess-layout-static :size="1700">
      <ArrowBackSVG />
      <recess-button
        class="u-textcolor-primary"
        title="Ga terug"
        variant="variant4"
        :url="previousRoute"
      />
    </recess-layout-static>
    <recess-divider />
    <template v-if="learningPath && $isAllowed('viewMyOwnLearningPath', learningPath) ">
      <recess-layout-static>
        <!-- Learning path title and description -->
        <div class="row">
          <div :class="[{'col-md-8' : (iconPath || displayCompletedLeaningPathStyle) }, 'col-12']">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-md-row flex-column ">
                <h2>{{ learningPath.title }}</h2>
              </div>
              <!-- Show edit/delete icon if is personal lp -->
              <div
                v-if="learningPath.isPersonal"
                class="c-learning-path__icons mb-2"
              >
                <recess-icon
                  class="qa-edit-lp__edit-icon"
                  icon="edit"
                  color="primary"
                  @click.native="$router.replace({ name: 'edit-learning-path', params: {id: learningPath.id}})"
                />
                <recess-divider
                  vertical
                  show-line
                />
                <recess-icon
                  class="qa-edit-lp__delete-icon"
                  icon="bin"
                  color="primary"
                  @click.native="showDeleteLearningPathModal = true"
                />
              </div>
            </div>
            <!-- Icon mobile -->
            <div
              v-if="iconPath || displayCompletedLeaningPathStyle"
              class="d-md-none my-2 d-flex justify-content-center align-items-center"
            >
              <div class="c-learning-path__logo">
                <img
                  v-if="iconPath"
                  alt="Learning path icon"
                  class="c-learning-path__icon--medium"
                  :src="iconPath"
                />
                <div
                  v-if=" displayCompletedLeaningPathStyle"
                  :class="[{ 'u-position-absolute' : iconPath }, 'c-learning-path__icon--success']"
                >
                  <recess-icon
                    icon="check"
                    color="white"
                    cursor="default"
                  />
                </div>
              </div>
            </div>
            <h6 v-if="learningPath.description">{{ learningPath.description }}</h6>
          </div>
          <!-- Icon desktop -->
          <div
            v-if="iconPath || displayCompletedLeaningPathStyle"
            :class="[{ 'align-items-center' : iconPath },'col-4 d-none d-md-flex justify-content-center']"
          >
            <div class="c-learning-path__logo">
              <img
                v-if="iconPath"
                alt="Learning path icon"
                class="c-learning-path__icon--medium"
                :src="iconPath"
              />
              <div
                v-if=" displayCompletedLeaningPathStyle"
                :class="[{ 'u-position-absolute' : iconPath }, 'c-learning-path__icon--success']"
              >
                <recess-icon
                  icon="check"
                  color="white"
                  cursor="default"
                  :height="35"
                  :width="35"
                />
              </div>
            </div>
          </div>
        </div>
        
        <recess-divider class="pt-0" />

        <recess-card
          variant="variant1"
        >
          <!-- Learning path type and actions buttons -->
          <div class="c-learning-path__bar row">
            <div
              v-if="learningPathProcess"
              class="col-md-4"
            >
              <div class="d-none d-md-block">
                <span>{{ learningPathProcess.finished }} voltooid /</span>
                <span
                  class="font-weight-bold"
                >{{ learningPathProcess.left }} resterend</span>
              </div>
              <div class="d-md-none d-flex no-gutters">
                <span
                  class="font-weight-bold col-7"
                >{{ learningPathProcess.finished }} resterend</span>
                <span class="col-5">{{ learningPathProcess.left }} voltooid</span>
              </div>
            </div>
            <div
              v-if="!learningPath.isPersonal"
              class="col-md-4 mt-2 mt-md-0 d-flex d-md-block no-gutters"
            >
              <span class="font-weight-bold mr-md-1 col-7 col-md-auto">U bent toegewezen door:</span>
              <span class="col-5 col-md-auto">{{ learningPath.assignedByUserFullName }}</span>
            </div>
            <div class="col-md-4 d-flex no-gutters justify-content-md-end mt-2 mt-md-0">
              <span class="font-weight-bold mr-md-1 col-7 col-md-auto">Aanmaakdatum:</span>
              <span class="col-5 col-md-auto">{{ formatDate(learningPath.endDate) || 'N.v.t' }}</span>
            </div>
          </div>
        </recess-card>
        <recess-divider />
      </recess-layout-static>
      <div class="u-bg--variant-gray1">
        <recess-divider />
        <recess-layout-static>
          <div v-if="learningPathCourses.length">
            <template v-for="(item, i) in learningPathCourses">
              <!-- Leaning path course -->
              <recess-card
                v-if="item.course"
                :key="i"
                :has-hover="!item.isEnrolled || (item.isEnrolled && !isActiveEnrollment(item.latestEnrollmentStatus) && !item.isCompleted)"
                :to="!item.isEnrolled || (item.isEnrolled && !isActiveEnrollment(item.latestEnrollmentStatus) && !item.isCompleted) ? { name: 'course', query: { course: item.course.id, assortment: item.course.assortmentId } } : null"
                :class="['c-learning-path__item pl-lg-0 d-lg-flex', {'is-completed' : item.isCompleted}, {'c-learning-path__item--no-order-restriction' : !learningPath.hasObligatedOrder}]"
              >
                <!-- Course Title and reviews -->
                <div class="col-12 col-lg-1 c-learning-path__counter-wrapper px-lg-0">
                  <div
                    v-if="learningPath.hasObligatedOrder"
                    class="c-learning-path__counter-line"
                  ></div>
                  <div
                    class="c-learning-path__counter-number"
                  >
                    {{ learningPath.hasObligatedOrder ? i + 1 : null }}
                  </div>
                </div>

                <div class="col-12 col-lg-11 px-0 pt-2 pt-lg-0">
                  <div class="c-learning-path__header row">
                    <div class="col-lg-9">
                      <h4><span v-html="item.course.title"></span></h4>
                      <div
                        v-if="item.isEnrolled && item.latestEnrollmentStatus && item.latestEnrollmentStatus.displayValue"
                        class="d-flex align-items-center mb-3"
                      >
                        <RecessIcon
                          icon="danger"
                          cursor="default"
                          class="mr-2"
                        />
                        <span>{{ item.latestEnrollmentStatus.displayValue }}</span>
                      </div>
                    </div>
                    <div class="c-learning-path__rating col-lg-3">
                      <feedback-stars-component :score="item.course.rating || 0" />
                      <span class="ml-2">{{ item.course.reviewCount || 0 }} reviews</span>
                    </div>
                  </div>
                
                  <recess-divider
                    class="pt-lg-0"
                    show-line
                  />

                  <!-- Course general info -->
                  <ul class="c-learning-path__list">
                    <li
                      v-if="(item.course.learningMethodTypeDisplayValue || item.course.learningMethodType) ||
                        (item.course.learningMethodFormatDisplayValue || item.course.learningMethodFormat)"
                      class="c-learning-path__list-text"
                    >
                      {{ learningMethodFormatText(item.course) }} {{ learningMethodTypeText(item.course) }}
                    </li>
                    <li
                      v-if="item.course.courseDuration && item.course.courseDurationUnit"
                      class="c-learning-path__list-text"
                    >
                      {{ item.course.courseDuration }} {{ item.course.courseDurationUnit }}
                    </li>

                    <li
                      v-if="item.course.level_nl"
                      class="c-learning-path__list-text"
                    >
                      {{ item.course.level_nl }}
                    </li>
                  </ul>

                  <!-- Course description -->
                  <div class="row c-learning-path__body">
                    <p
                      class="col-lg-6 col-xl-7 d-none d-lg-block"
                      v-html="cutOff(item.course.content, 150)"
                    ></p>

                    <!-- Course price -->
                    <div class="col-12 col-lg-6 col-xl-5 c-learning-path__info">
                      <div class="c-learning-path__actions">
                        <div
                          v-if="item.course.providerLogoUrl || item.course.providerName"
                          class="d-lg-none"
                        >
                          <img
                            v-if="item.course.providerLogoUrl"
                            alt="Provider logo"
                            class="c-learning-path__provider-logo mb-3"
                            :src="item.course.providerLogoUrl"
                          />
                          <span
                            v-else
                            class="c-learning-path__provider-name"
                          >{{ item.course.providerName }}</span>
                        </div>
                        <div 
                          v-if="$isAllowed('displayPricingInformation')" 
                          class="c-learning-path__price"
                        >
                          <h4 
                            data-test="course-price"
                            class="c-learning-path__price-value"
                          >
                            {{ formatPrice(item.course.price) }}
                          </h4>
                          <span 
                            data-test="vat_incl_or_excl"
                            class="c-learning-path__price-vat ml-3 font-italic"
                          >{{ getVatText(item.course.vatIncluded) }}</span>
                        </div>
                      </div>

                      <div
                        v-if="!item.isCompleted"
                        class="d-sm-flex mt-3 mt-lg-0 justify-content-sm-end"
                        @click.prevent.stop
                      >
                        <enrollment-button-component
                          :course="getEnrollmentButtonData(item.course)"
                          button-variant="primary"
                          :disabled="buttonDisabled(i) || (item.isEnrolled && isActiveEnrollment(item.latestEnrollmentStatus))"
                          :is-e-learning="isELearningCourse(item.course)"
                          class="mr-sm-2 mb-2 mb-sm-0"
                        />
                        <div>
                          <recess-button
                            title="Markeren als voltooid"
                            :disabled="buttonDisabled(i)"
                            variant="secondary"
                            class="w-100"
                            data-test="complete-lp-btn"
                            @click="setCompletedCourseAndShowMessage(item.id)"
                          />
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex mt-lg-0 justify-content-end"
                      >
                        <span
                          data-test="completed-course-text"
                          class="u-textcolor-secondary font-weight-bold"
                        >Voltooid</span>
                        <recess-icon
                          icon="check"
                          color="secondary"
                          cursor="default"
                          :width="13"
                          :height="10"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Logo and icons Desktop-->
                  <div class="d-none d-lg-flex c-learning-path__actions">
                    <div v-if="item.course.providerLogoUrl || item.course.providerName">
                      <img
                        v-if="item.course.providerLogoUrl"
                        alt="Provider logo"
                        class="c-learning-path__provider-logo"
                        :src="item.course.providerLogoUrl"
                      />
                      <span
                        v-else
                        class="c-learning-path__provider-name"
                      >{{ item.course.providerName }}</span>
                    </div>
                  </div>
                </div>
              </recess-card>
              <recess-divider :key="`divider ${i}`" />
            </template>
          </div>

          <span class="js-scroll-bottom"></span>

          <!-- Deleted courses -->
          <div v-if="deletedLearningPathCourses.length">
            <template v-for="(item, i) in deletedLearningPathCourses">
              <recess-card
                v-if="item.course"
                :key="i"
                class="d-flex flex-column flex-lg-row"
              >
                <!-- Course Title -->
                <div class="col-12 col-lg-1 c-learning-path__counter-wrapper px-lg-0">
                  <div
                    class="c-learning-path__counter-number"
                  ></div>
                </div>
                <div class="col-12 col-lg-11 px-0 pt-2 pt-lg-0">
                  <div class="c-learning-path__header row">
                    <h4 class="col-lg-9">{{ item.course.title }}</h4>
                  </div>

                  <recess-divider
                    class="pt-lg-0"
                    show-line
                  />
                  
                  <!-- Course learning method -->
                  <ul class="c-learning-path__list">
                    <li
                      v-if="item.course.LearningMethodDisplayValue"
                      class="c-learning-path__list-text"
                    >
                      {{ item.course.LearningMethodDisplayValue }}
                    </li>
                  </ul>

                  <!-- Course description -->
                  <div class="row c-learning-path__body">
                    <p
                      class="col-lg-6 col-xl-7 d-none d-lg-block"
                      v-html="cutOff(item.course.content, 200)"
                    ></p>

                    <!-- Provider name (mobile) and not available anymore text -->
                    <div class="col-12 col-lg-6 col-xl-5 c-learning-path__info">
                      <div class="c-learning-path__actions">
                        <div
                          v-if="item.course.providerName"
                          class="d-lg-none"
                        >
                          <span
                            class="c-learning-path__provider-name"
                          >{{ item.course.providerName }}</span>
                        </div>
                        <span class="u-textcolor-blue-grey font-weight-bold">Niet meer beschikbaar</span>
                      </div>
                      <div
                        v-if="item.isCompleted"
                        class="d-flex mt-3 justify-content-end"
                      >
                        <span class="u-textcolor-secondary font-weight-bold">Voltooid</span>
                        <recess-icon
                          icon="check"
                          color="secondary"
                          cursor="default"
                          :width="13"
                          :height="10"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Provider name Desktop-->
                  <div class="d-none d-lg-flex c-learning-path__actions">
                    <div v-if="item.course.providerName">
                      <span
                        class="c-learning-path__provider-name"
                      >{{ item.course.providerName }}</span>
                    </div>
                  </div>
                </div>
              </recess-card>
              <recess-divider :key="`divider ${i}`" />
            </template>
          </div>

          <template v-if="!deletedLearningPathCourses.length && !learningPathCourses.length">
            <recess-alert text="Er zijn momenteel geen ontwikkelactiviteiten in dit leerpad." />
          </template>
        </recess-layout-static>
        <recess-divider />
      </div>
    </template>
    <recess-layout-static
      v-if="!isLoading && (showErrorMessage || !$isAllowed('viewMyOwnLearningPath', learningPath))"
    >
      <recess-alert text="Uw leerpad is niet bekend. Probeer een ander leerpad." />
      <recess-divider />
    </recess-layout-static>

    <!-- Complete course confirmation modal -->
    <recess-modal
      modal-size="small"
      :show-modal="showCompleteLearningPathMessage"
      @close="showCompleteLearningPathMessage = false"
    >
      <div
        slot="modal-body"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <loader-component
          v-if="isCompletingLearningPathCourse"
          overlay="white"
          color="primary"
          is-fixed
        />
        <p>Weet u zeker dat u de ontwikkelactiviteit als voltooid wilt markeren?</p>
        <div>
          <recess-button
            class="mr-2"
            variant="primary"
            title="Ja"
            data-test="confirm-complete-btn"
            @click="markCourseAsDone()"
          />
          <recess-button
            variant="primary"
            title="Nee"
            @click="showCompleteLearningPathMessage = false"
          />
        </div>
      </div>
    </recess-modal>

    <!-- Completed lp success message -->
    <recess-modal
      :show-modal="showCompletedLPMessage"
      modal-size="small"
      @close="showCompletedLPMessage = false"
    >
      <template #modal-body>
        <p>Gefeliciteerd met het afronden van uw leerpad!</p>
      </template>
      <template #modal-footer>
        <recess-button
          title="Ok"
          variant="primary"
          class="u-position-right"
          @click="showCompletedLPMessage = false"
        />
      </template>
    </recess-modal>

    <!-- Delete lp modal -->
    <delete-learning-path-confirmation
      :show-confirmation-message="showDeleteLearningPathModal"
      :learning-path="learningPath"
      @close:first-confirmation="showDeleteLearningPathModal = false"
    />
  </div>
</template>

<script>
import { scrollTo } from 'vue-scrollto'
import { cutOff } from '../../../modules/textFormatter'
import ArrowBackSVG from '../../organisms/IconComponents/ArrowBackSVG.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import FeedbackStarsComponent from '../../molecules/FeedbackStarsComponent/FeedbackStarsComponent.vue'
import { getLearningPathById, completeLearningPathCourse } from '../../../api/learningPathClient'
import priceFormatter from '../../../modules/priceFormatter'
import DateUtils from '../../../utils/DateUtils'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import EnrollmentButtonComponent from '../../organisms/EnrollmentButtonComponent/EnrollmentButtonComponent.vue'
import DeleteLearningPathConfirmation from '../../organisms/LearningPath/DeleteLearningPathConfirmation.vue'
import * as courseLearningMethodTypes from '../../../constants/courseLearningMethodTypes'
import * as enrollmentStatus from '../../../constants/enrollmentStatus'
import orderStatus from '../../../constants/orderStatus'
import { myDevelopmentTabs } from '../../../constants/tabNames'
import { getFilePathByName } from '../../../modules/assetsHelper'
import coursePriceHelper from '../../../modules/coursePriceHelper'

export default {
    perimeters: [learningPathPerimeter, featuresPerimeter],
    name: 'LearningPathDetails',
    components: {
        FeedbackStarsComponent,
        ArrowBackSVG,
        LoaderComponent,
        EnrollmentButtonComponent,
        DeleteLearningPathConfirmation
    },
    beforeRouteEnter(_, from, next) {
        next(vm => {
            vm.previousRoute = from.name ? from : { name: 'my-development', query: { 'my-development-tab': myDevelopmentTabs.LEARNINGPATHS.key }}
        })
    },
    props: {
        scrollToBottom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isFetchingLearningPath: false,
            isCompletingLearningPathCourse: false,
            showErrorMessage: false,
            learningPath: null,
            showCompleteLearningPathMessage: false,
            completedLearningPathCourseId: null,
            showDeleteLearningPathModal: false,
            showCompletedLPMessage: false,
            previousRoute: null,
            myDevelopmentTabs
        }
    },
    computed: {
        iconPath() {
            return getFilePathByName('images/learningPath', this.learningPath?.iconName)
        },
        isLoading() {
            return this.isFetchingLearningPath || this.isCompletingLearningPathCourse
        },
        learningPathProcess() {
            return {
                finished: this.learningPath?.courses?.filter(course => course.isCompleted).length ?? 0,
                left: this.learningPathCourses?.length - this.learningPathCourses?.filter(course => course.isCompleted)?.length ?? 0
            }
        },
        learningPathCourses() {
            // Dynamically sort the courses by order
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const sortedCourses = this.learningPath?.courses?.sort((a, b) => a.order - b.order)
            return sortedCourses?.reduce((acc, item) => {
                if (item.course && !item.course.deleted) acc.push(item)
                return acc
            }, [])
        },
        deletedLearningPathCourses() {
            return this.learningPath?.courses?.reduce((acc, item) => {
                if(item.course?.deleted) acc.push(item)
                return acc
            }, [])
        },
        displayCompletedLeaningPathStyle() {
            return this.$isAllowed('displayCompletedLearningPathStyle') 
            && this.learningPathCourses?.every(x => x.isCompleted)
            && this.learningPathCourses.length
        }
    },
    async created() {
        await this.fetchLearningPath()
        this.$nextTick(() => {
            if (this.scrollToBottom) {
                scrollTo('.js-scroll-bottom', {
                    offset: -500,
                    duration: -1
                })
            }
        })
    },
    methods: {
        getVatText(vatIncluded) {
            return coursePriceHelper.getVatText(vatIncluded)
        },
        buttonDisabled(index) {
            // If course not obligated show buttons for all courses
            if (!this.learningPath.hasObligatedOrder) return false

            const completedExistingCourses = this.learningPathCourses?.filter(course => course.isCompleted)
            return index !== completedExistingCourses?.length 
        },
        isActiveEnrollment(lastEnrollmentStatus) {
            const invalidStatuses = [
                enrollmentStatus.CANCELLED, 
                enrollmentStatus.REJECTEDEMPLOYER, 
                enrollmentStatus.REJECTEDEMPLOYEE,
                enrollmentStatus.CANCELED_VERIFICATION_TIME_OVERDUE,
                orderStatus.Invalid.codeName, 
                orderStatus.RejectedProvider.codeName, 
                orderStatus.Credited.codeName,
                orderStatus.CancelledAdmin.codeName,
                orderStatus.CancelledWithCosts.codeName  
            ]
            return !invalidStatuses.includes(lastEnrollmentStatus?.statusCode)
        },
        setCompletedCourseAndShowMessage(courseId) {
            this.completedLearningPathCourseId = courseId
            this.showCompleteLearningPathMessage = true
        },
        getEnrollmentButtonData(course) {
            return {
                id: course.id,
                startMoments: course.startMoments,
                externalId: course.externalId,
                providerName: course.providerName,
                providerId: course.providerId,
                courseName: course.title,
                oleUrl: course.oleUrl,
                showZeroPrice: course.showZeroPrice,
                assortmentId: course.assortmentId
            }
        },
        isELearningCourse(course) {
            return course?.learningMethodType?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED
        },
        async fetchLearningPath() {
            try {
                this.isFetchingLearningPath = true
                const id = this.$route?.params?.id
                this.learningPath = await getLearningPathById(id)
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.isFetchingLearningPath = false
            }
        },
        async markCourseAsDone() {
            try {
                if(!this.completedLearningPathCourseId) return
                this.isCompletingLearningPathCourse = true

                await completeLearningPathCourse(this.completedLearningPathCourseId)
                await this.fetchLearningPath()

                if(this.displayCompletedLeaningPathStyle){
                    this.showCompletedLPMessage = true
                }
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isCompletingLearningPathCourse = false
                this.showCompleteLearningPathMessage = false
            }
        },
        learningMethodFormatText(course) {
            return course?.learningMethodFormatDisplayValue || course?.learningMethodFormat
        },
        learningMethodTypeText(course) {
            if(!course?.learningMethodTypeDisplayValue && !course?.learningMethodType) return null
            
            // if there is a format, display the type in brackets, otherwise, lose the brackets
            return course?.learningMethodFormatDisplayValue || course?.learningMethodFormat ? 
                `(${course?.learningMethodTypeDisplayValue || course?.learningMethodType})` : 
                course?.learningMethodTypeDisplayValue || course?.learningMethodType
        },
        formatPrice: priceFormatter.formatPrice,
        formatDate: e => DateUtils.formatDate(e),
        cutOff
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div class="d-none d-md-block">
    <!-- Go back button -->
    <recess-divider />
    <recess-layout-static
      :size="1700"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>
      <recess-button
        title="Ga terug"
        icon="back"
        variant="variant4"
        :url="{ name: 'search'}"
      />

      <recess-divider />
    
      <h1>Vergelijk ontwikkelactiviteiten</h1>
      <recess-divider />
      <template v-if="coursesToCompare.length">
        <!-- General information -->
        <recess-accordion
          variant="variant-3"
          active
        >
          <template #accordion-title>
            Kenmerken
          </template> 
          <template #accordion-content>
            <div class="row d-flex align-items-end justify-content-end">
              <div
                v-for="(course, index) in coursesToCompare"
                :key="`provider${index}`"
                :class="colSize"
              >
                <div
                  v-if="course.embedded && course.embedded.provider"
                >
                  <img
                    v-if="course.embedded.provider.logoUrl"
                    class="u-max-width-100"
                    alt="Provider logo"
                    :src="course.embedded.provider.logoUrl"
                  />
                </div>
                <p class="pt-2">
                  {{ course.embedded.provider.name }}
                </p>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <!-- Name -->
              <div :class="[' font-weight-bold', colSize]">
                Naam ontwikkelactiviteit
              </div>
              <div
                v-for="(course, index) in coursesToCompare"
                :key="`name${index}`"
                :class="['pb-3', colSize]"
              >
                {{ course.name }}
              </div>

              <!-- Level -->
              <template v-if="showLevels">
                <div
                  :class="[' pb-3 font-weight-bold', colSize]"
                >
                  Opleidingsniveau
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="`level${index}`"
                  :class="['pb-3', colSize]"
                >
                  {{ course.educationLevelCodeDisplayValue }}
                </div>
              </template>

              <!-- Learning method -->
              <template v-if="showLearningMethods">
                <div
                  :class="['pb-3 font-weight-bold', colSize]"
                >
                  Leermethode
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="`type${index}`"
                  :class="['pb-3', colSize]"
                >
                  {{ course.learningMethod && course.learningMethod.typeDisplayValue }}
                </div>
              </template>

              <!-- Diploma type -->
              <template v-if="showDiplomaTypes">
                <div
                  :class="['pb-3 font-weight-bold', colSize]"
                >
                  Certificering
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="`diploma${index}`"
                  :class="['pb-3', colSize]"
                >
                  {{ course.diplomaTypeDisplayValue }}
                </div>
              </template>
              
              <!-- Duration value -->
              <template v-if="showDurationValues">
                <div
                  :class="['pb-3 font-weight-bold', colSize]"
                >
                  Doorlooptijd
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="`duration${index}`"
                  :class="['pb-3', colSize]"
                >
                  <span v-if="course.learningMethod && course.learningMethod.duration">
                    {{ course.learningMethod.duration.value }} {{ course.learningMethod.duration.unit }}
                  </span> 
                </div>
              </template>

              <!-- Total price -->
              <template v-if="showPrices">
                <div
                  :class="['pb-3 font-weight-bold', colSize]"
                >
                  Investering
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="`price${index}`"
                  :class="['pb-3', colSize]"
                >
                  <span>{{ getPriceValue(course) }}</span>
                  <em v-if="showPrice(course)">{{ getVatText(course) }}</em>
                </div>
              </template>

              <!-- Review rating -->
              <template v-if="showRatings">
                <div
                  :class="['pb-3 font-weight-bold', colSize]"
                >
                  Beoordeling
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="`rating${index}`"
                  :class="['pb-3 c-rating-holder', colSize]"
                >
                  <div class="d-flex align-items-center">
                    <feedback-stars-component :score="course.rating || 0" />
                    <span class="ml-2 u-textcolor-black">({{ course.reviewCount || 0 }})</span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </recess-accordion>
        <!-- Start moments -->
        <recess-accordion
          v-if="showStartMoments"
          variant="variant-3"
          :is-disabled="isLoadingStartMoments"
        >
          <template #accordion-title>
            Startmomenten en locaties
            <transition
              name="fade"
              mode="out-in"
            >
              <loader-component
                v-if="isLoadingStartMoments"
                overlay="white"
                color="primary"
                variant="small"
              />
            </transition>
          </template> 
          <template #accordion-content>
            <div class="row d-flex">
              <div :class="['font-weight-bold', colSize]">
                {{ startmomentsHeader }}
              </div>
              <div
                v-for="(course, index) in coursesToCompare"
                :key="`startMoments${index}`"
                :class="colSize"
              >
                <div class="d-flex flex-column">
                  <div
                    v-for="startMoment in course.startMoments"
                    :key="startMoment.externalId"
                    class="d-flex flex-column pb-2 pb-lg-0 flex-lg-row align-items-lg-center justify-content-lg-between"
                  >
                    <span class="col-3">{{ startMoment.city }}</span>
                    <span
                      v-if="$isAllowed('displayPricingInformation') && startMoment.priceInfo"
                      class="col-4 d-flex justify-content-end"
                      data-test="price-with-vat-text"
                    >{{ formatPrice(startMoment.priceInfo.totalPrice) }} </span>
                    <div class="d-flex">
                      <recess-tooltip
                        v-if="startMoment.startGuaranteed && displayStartGuaranteed"
                        class="u-cursor-pointer pr-2"
                      >
                        <recess-icon
                          class="p-0"
                          :width="25"
                          :height="25"
                          icon="calendar-check"
                        />
                        <template #content>
                          <span>Dit startmoment gaat gegarandeerd door op deze datum en locatie.</span>
                        </template>
                      </recess-tooltip>

                      <router-link
                        v-if="showClickableStartMoment(course)"
                        :to="getEnrollmentWithStartMomentUrl(course, startMoment.externalId)"
                      >
                        {{ formatDate(startMoment.fullDate) }}
                      </router-link>
                      <span v-else>{{ formatDate(startMoment.fullDate) }}</span>
                    </div>
                  </div>

                  <template v-if="course.hasMoreStartMoments">
                    <recess-divider variant="xsmall" />
                    <recess-button  
                      variant="tertiary" 
                      title="Bekijk meer" 
                      :url="getCoursePageUrl(course)"
                      target="_blank"
                    />
                  </template>
                </div>
              </div>
            </div>
          </template>  
        </recess-accordion>
        <!-- Tab content -->
        <recess-accordion
          v-if="showTabContent"
          variant="variant-3"
        >
          <template #accordion-title>
            Uitgebreide informatie
          </template> 
          <template #accordion-content>
            <div>
              <!-- General tab -->
              <div
                v-if="showGeneralContent"
                class="d-flex"
              >
                <div
                  :class="['font-weight-bold', colSize]"
                >
                  Algemene informatie 
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="index"
                  :class="['u-text-break', colSize]"
                >
                  <!-- Intro content -->
                  <div
                    v-for="(content, introIndex) in course.content.intro"
                    :key="`intro${introIndex}`"
                  >
                    <p
                      v-if="content.introHtml || content.intro"
                      v-html="content.introHtml || content.intro"
                    ></p>
                  </div>

                  <!-- General content -->
                  <template v-if="course.content.general">
                    <div
                      v-for="(content, generalIndex) in course.content.general"
                      :key="`generalContent${generalIndex}`"
                    >
                      <h3 v-if="content.heading">{{ content.heading }}</h3>
                      <p
                        v-if="content.bodyHtml || content.body"
                        v-html="content.bodyHtml || content.body"
                      ></p>
                    </div>
                  </template>

                  <!-- Rest content -->
                  <template v-if="course.content.rest">
                    <div
                      v-for="(content, restIindex) in course.content.rest"
                      :key="`restContent${restIindex}`"
                    >
                      <h3 v-if="content.heading">{{ content.heading }}</h3>
                      <p
                        v-if="content.bodyHtml || content.body"
                        v-html="content.bodyHtml || content.body"
                      ></p>
                    </div>
                  </template>
                </div>
              </div>

              <!-- Programma tab -->
              <div
                v-if="showProgramContent"
                class="d-flex"
              >
                <div
                  :class="['font-weight-bold', colSize]"
                >
                  Programma
                </div>
                <div
                  v-for="(course, index) in coursesToCompare"
                  :key="index"
                  :class="['u-text-break', colSize]"
                >
                  <!-- Course Description -->
                  <template v-if="course.content.description && course.content.description.length">
                    <h3>Over de ontwikkelactiviteit</h3>
                    <div
                      v-for="(content, descriptionIndex) in course.content.description"
                      :key="`descriptionContent${descriptionIndex}`"
                    >
                      <p
                        v-if="content.descriptionHtml || content.description"
                        v-html="content.descriptionHtml || content.description"
                      ></p>
                    </div>
                  </template>

                  <!-- Content blocks -->
                  <template v-if="course.content.program && course.content.program.length">
                    <div
                      v-for="(content, programIndex) in course.content.program"
                      :key="`programContent${programIndex}`"
                    >
                      <h3 v-if="content.heading">{{ content.heading }}</h3>
                      <p
                        v-if="content.bodyHtml || content.body"
                        v-html="content.bodyHtml || content.body"
                      ></p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>  
        </recess-accordion>
        <!-- Investment content -->
        <recess-accordion
          v-if="showPrices"
          variant="variant-3"
          :is-disabled="isLoadingPrice"
        >
          <template #accordion-title>
            Investering
            <transition
              name="fade"
              mode="out-in"
            >
              <loader-component
                v-if="isLoadingPrice"
                overlay="white"
                color="primary"
                variant="small"
              />
            </transition>
          </template> 
          <template #accordion-content>
            <div
              class="row d-flex"
              aria-label="Courses table"
            >
              <div :class="['pb-3 font-weight-bold', colSize]">
                Investering
              </div>
                
              <div
                v-for="(course, index) in coursesToCompare"
                :key="`investment${index}`"
                :class="colSize"
              >
                <template v-if="course.content.invoiceItems && course.content.invoiceItems.length">
                  <div
                    v-for="(invoiceItem, invoiceItemIndex) in course.content.invoiceItems"
                    :key="`invoiceItem${invoiceItemIndex}`"
                    class="d-flex flex-column pb-2 flex-lg-row align-items-lg-center justify-content-lg-between pb-lg-0"
                  >
                    <span> {{ invoiceItem.costTypeDisplayValue }}</span>
                    <span> {{ getInvoiceItemCalculatedPrice(invoiceItem, course.isVatExempt, displayVatInPrices) || "-" }}</span>
                  </div>
                  <div class="d-flex flex-column pb-2 flex-lg-row align-items-lg-center justify-content-lg-between pb-lg-0 font-weight-bold">
                    <strong>Totaal <em v-if="showPrice(course)">{{ getVatText(course) }}</em></strong>
                    <div>
                      <span>{{ getPriceValue(course) }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>  
        </recess-accordion>
        <recess-card v-if="!searchFromTeamAssortments">
          <div
            class="row d-flex"
            aria-label="Courses table"
          >
            <div :class="colSize">
            </div>
            <div
              v-for="(course, index) in coursesToCompare"
              :key="`enroll${index}`"
              :class="colSize"
            >
              <enrollment-button-component
                v-if="course.userHasAccess"
                :course="getenrollmentButtonData(course)"
                :is-e-learning="isELearning(course)"
                class="w-100 pt-3"
              />
            </div>
          </div>
        </recess-card>
      </template>
      <recess-alert
        v-if="showErrorMessage"
        type="error"
        text="Er is een fout opgetreden. Probeer het later opnieuw."
      />
      <recess-divider />
    </recess-layout-static>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import SearchQueryBuilder from '../../../api/OData/searchQueryBuilder'
import courseClient from "../../../api/courseClient"
import * as courseLearningMethodTypes from '../../../constants/courseLearningMethodTypes'
import * as displayStartMomentsType from '../../../constants/displayStartMomentsTypes'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import priceFormatter from '../../../modules/priceFormatter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import DateUtils from '../../../utils/DateUtils'
import LoaderComponent from "../../atoms/LoaderComponent/LoaderComponent.vue"
import FeedbackStarsComponent from '../../molecules/FeedbackStarsComponent/FeedbackStarsComponent.vue'
import tabKeys from '../../organisms/CoursePage/tabKeys'
import EnrollmentButtonComponent from '../../organisms/EnrollmentButtonComponent/EnrollmentButtonComponent.vue'
import facetClient from '../../../api/facetClient'
import { ASSORTMENTNAME } from '../../../constants/facetKeys'

export default {
    name: "CompareCourses",
    components: {
        LoaderComponent,
        EnrollmentButtonComponent,
        FeedbackStarsComponent
    },
    perimeters: [featuresPerimeter, routePerimeter],
    data() {
        return {
            isLoading: false,
            showErrorMessage: false,
            isLoadingPrice: false,
            isLoadingStartMoments: false,
            startMomentsToRender: 100
        }
    },
    computed: {
        ...mapGetters('accountModule', ['displayStartMomentsType', 'displayVatInPrices', 'finalStartMomentDate']),
        ...mapGetters('compareCoursesModule', ['coursesToCompare']),
        displayStartGuaranteed() {
            return this.displayStartMomentsType === displayStartMomentsType.ALLSTARTMOMENTSWITHDISTINCTION
        },
        showPrices() {
            return this.$isAllowed('displayPricingInformation') && this.coursesToCompare?.some(x => x.price)
              
        },
        showRatings() {
            return this.coursesToCompare?.some(x => 'reviewCount' in x)
        },
        showStartMoments() {
            return this.coursesToCompare?.some(x => x.startMoments?.length)
        },
        showLevels() {
            return this.coursesToCompare?.some(x => !!x.educationLevelCodeDisplayValue)
        },
        showLearningMethods() {
            return this.coursesToCompare?.some(x => !!x.learningMethod?.typeDisplayValue)
        },
        showDiplomaTypes() {
            return this.coursesToCompare?.some(x => !!x.diplomaTypeDisplayValue)
        },
        showDurationValues() {
            return this.coursesToCompare?.some(x => !!x.learningMethod?.duration?.value || !!x.learningMethod?.duration?.unit)
        },
        showGeneralContent() {
            return this.coursesToCompare?.some(x =>  x.content?.intro?.length || x.content?.general?.length || x.content?.rest?.length)
        },
        showProgramContent() {
            return this.coursesToCompare?.some(x => x.content?.description?.length || x.content?.program?.length)
        },
        showTabContent() {
            return this.showGeneralContent || this.showProgramContent
        },
        colSize() {
            switch(this.coursesToCompare?.length) {
                case 3:
                    return "col-3"
                case 2:
                    return "col-4"
                default:
                    return "col-6"
            }
        },
        searchFromTeamAssortments() {
            return this.$route.query?.searchFromTeamAssortments && this.$isAllowed('routeAsTeamleader')
        },
        vatText() {
            return coursePriceHelper.getVatText(this.displayVatInPrices)
        },        
        startmomentsHeader() {
            const headerTitleWithPrice = `Locaties / price (${this.vatText}) / startmomenten`
            const headerTitleWithoutPrice = 'Locaties / startmomenten'
            return `${ this.$isAllowed('displayPricingInformation') ? headerTitleWithPrice : headerTitleWithoutPrice }`
        }
    },
    async mounted() {
        // TODO: Refactor readability of this method
        try {
            this.isLoading = true
            if(!this.$route.params) return

            const coursePromises = []
            const assortedProductFilter = {
                filters: [{
                    name: "productReference",
                    values: []
                }],
                searchFromTeamAssortments: this.searchFromTeamAssortments,
                includeFinalStartMomentDateFilter: !!this.finalStartMomentDate,
                includeCoursesOutsideAssortmentFilter: true,
                top: 0
            }
            const { course1Id, course1AssortmentId, course2Id, course2AssortmentId, course3Id, course3AssortmentId } = this.$route.query
           
            const courseIds = [
                {
                    id: course1Id,
                    assortmentId: course1AssortmentId
                },
                {
                    id: course2Id,
                    assortmentId: course2AssortmentId
                },
                {
                    id: course3Id,
                    assortmentId: course3AssortmentId
                } 
            ]
            
            courseIds.forEach(course => {
                if(course.id) {
                    coursePromises.push(courseClient.getCourse(course.id))
                    assortedProductFilter.filters[0].values.push(`course:${course.id}`)
                    assortedProductFilter.top += 1
                }
            })

            // Multiply by the total number of assortments to make sure we retrieve all courses, even in scenarios where there are duplicates.
            assortedProductFilter.top *= await this.getAssortmentsLength()
            
            // Workaround (supported by IE) to not fail the whole request in case something goes wrong when fetching a course
            const coursesResult = await Promise.all(coursePromises.map(p => p.catch(e => e)))
                .then(result => {
                    if(result?.every(x => x.isAxiosError)) {
                        this.showErrorMessage = true
                        return null
                    }

                    return result.filter(x => !x.isAxiosError)
                })
            if(!coursesResult?.length) {
                this.showErrorMessage = true
                return 
            }

            const assortedProductResult = await courseClient.fetchSearch(assortedProductFilter)
                .catch(e => console.error(e))

            await this.mapCoursesToCompare(coursesResult, courseIds, assortedProductResult)
            await this.setCoursesToCompare(coursesResult)
        } catch (error) {
            console.error(error)
        } finally {
            this.isLoading = false
        }
    },
    beforeDestroy() {
        this.resetState()
    },
    methods: {
        ...mapMutations('compareCoursesModule', ['resetState', 'setCoursesToCompare', 'toggleCourseIdToCompare']),
        async getAssortmentsLength() {
            try {
                const assortments = await facetClient.getFacets([ASSORTMENTNAME])
                return assortments?.assortmentName?.length || 0
            } catch (error) {
                console.error(error)
                return 0
            }
        },
        async mapCoursesToCompare(courses, courseIds, assortedProductResult) {
            // eslint-disable-next-line no-restricted-syntax
            for (const course of courses) {
                const matchedCourse = courseIds.find(x => x.id === course.id)

                if(matchedCourse) {
                    const courseFromAssortedProduct = assortedProductResult?.data?.results?.find(x => x.id === course.id)
                    course.assortmentId = matchedCourse.assortmentId
                    course.userHasAccess = !!courseFromAssortedProduct
                    
                    if(courseFromAssortedProduct) {
                        course.price = courseFromAssortedProduct.price
                        course.rating = courseFromAssortedProduct.rating
                        course.reviewCount = courseFromAssortedProduct.reviewCount
                        course.vatIncluded = courseFromAssortedProduct.vatIncluded
                              
                        // eslint-disable-next-line no-await-in-loop
                        const { isVatExempt, invoiceItems } = await this.fetchCoursePrice(matchedCourse)
                        course.isVatExempt = isVatExempt
                        course.content.invoiceItems = invoiceItems
                    }

                    if(course.startMoments?.length) {
                        // eslint-disable-next-line no-await-in-loop
                        const startMomentsResponse = await this.fetchCourseStartMoments(course.id, course.assortmentId)

                        course.startMoments = startMomentsResponse?.results || []
                        course.hasMoreStartMoments = startMomentsResponse?.count > this.startMomentsToRender
                    }
                }
            }

            return courses
        },
        async fetchCoursePrice(course) {
            try {
                this.isLoadingPrice = true

                const priceResponse = await courseClient.getPrice(course.id, this.searchFromTeamAssortments, course.assortmentId)
                        
                return {
                    isVatExempt: priceResponse?.vatExempt,
                    invoiceItems: priceResponse?.cost?.details[0]?.invoiceItems
                }
            } catch (error) {
                console.error(error)
                return {}
            } finally {
                this.isLoadingPrice = false
            }
        },
        async fetchCourseStartMoments(courseId, assortmentId) {
            try {
                this.isLoadingStartMoments = true 

                const dataQuery = new SearchQueryBuilder()
                    .setTop(this.startMomentsToRender)
                    .setSelects([
                        'externalId', 
                        'city', 
                        'amountWithVatAndWithDiscount', 
                        'amountWithoutVatWithDiscount',
                        'amountWithVatWithoutDiscount',
                        'amountWithoutVatWithoutDiscount',
                        'vatWithDiscount',
                        'vatWithoutDiscount',
                        'discountWithVat',
                        'discountWithoutVat',
                        'startGuaranteed', 
                        'fullDate'
                    ])
                    .addFilter(new ODataQueryFilter('productReference', ODataFilterOperations.EQUALS, `course:${courseId}`))
                    .addFilter(new ODataQueryFilter('assortmentId', ODataFilterOperations.EQUALS, assortmentId))
                    .addDynamicFilter(`fullDate ge ${DateUtils.parseToISOString(DateUtils.today)}`)
  
                const finalStartMomentDate = DateUtils.formatDateTime(this.finalStartMomentDate, true)
                const formattedFinalStartMomentDate = DateUtils.parseToISOString(finalStartMomentDate)
                if (finalStartMomentDate) {
                    dataQuery.addDynamicFilter(` fullDate lt ${formattedFinalStartMomentDate}`)
                }
                
                const response = await courseClient.searchStartMoments(dataQuery.build())
                  
                return response
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(`Failed to fetch start moments with error: ${error}`)
                }
                return []
            } finally {
                this.isLoadingStartMoments = false
            }
        },
        showClickableStartMoment(course) {
            return !this.searchFromTeamAssortments && course.userHasAccess
        },
        getVatText(course) {
            return ` (${coursePriceHelper.getVatText(course?.vatIncluded)})`
        },
        getPriceValue(course) {
            return this.showPrice(course) ? this.formatPrice(course.price) : "-"
        },
        showPrice(course) {
            return course?.price || course?.price === 0
        },
        getEnrollmentWithStartMomentUrl(course, startMomentId) {
            return this.$isAllowed('routeAsCoordinator')
                ? { name: 'enrollment', params: { type: 'coordinator' }, query: { course: course.id, assortment: course.assortmentId, startmoment: startMomentId } }
                : { name: 'enrollment', params: { type: 'student' }, query: { course: course.id, assortment: course.assortmentId, startmoment: startMomentId } }
        },
        getCoursePageUrl(course) {
            return {
                name: 'course',
                query: { course: course.id, assortment: course.assortmentId, 'course-page-tab': tabKeys.PLANNING }
            }
        },  
        getenrollmentButtonData(course) {
            return {
                id: course.id,
                startMoments: course.startMoments,
                externalId: course.externalId,
                providerName: course.providerName,
                providerId: course.providerId,
                courseName: course.title,
                oleUrl: course.oleUrl,
                showZeroPrice: course.showZeroPrice,
                assortmentId: course.assortmentId,
                isInUserAssortments: course.isInUserAssortments
            }
        },
        isELearning(course) {
            return course?.learningMethod?.type?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED
        },
        getInvoiceItemCalculatedPrice: coursePriceHelper.getInvoiceItemCalculatedPrice,
        formatPrice: priceFormatter.formatPrice,
        formatDate: date => DateUtils.formatDate(date)
    }
}
</script>

<template>
  <div :class="['c-search-toolbar']">
    <div>
      <recess-button
        class="mr-3"
        title="Filter ontwikkelactiviteiten"
        variant="secondary"
        data-test="toggle-facets"
        @click="toggleFacets()"
      />

      <a
        v-if="showResetFilterButton"
        href
        data-test="search-toolbar-a"
        @click.prevent="$emit('clear-filters')"
      >
        Verwijder filters</a>
    </div>

    <div class="c-search-toolbar__settings mt-3 mt-md-0">
      <recess-radio-buttons
        v-show="currentSorting.fieldName"
        data-test="sorting-inputs"
        class="c-search-toolbar__setting-input--sort-direction mr-2"
      >
        <recess-radio-input
          name="sortDirection"
          :value="currentSorting.direction"
          :label-text="ascText"
          data-test="radio-input-asc"
          class="mb-0"
          :default-checked="currentSorting.direction === 'asc'"
          variant="button"
          @change="(value) => setSortDirection(value)"
        />
        <recess-radio-input
          name="sortDirection"
          :value="currentSorting.direction"
          :default-checked="currentSorting.direction === 'desc'"
          :label-text="descText"
          class="mb-0"
          data-test="radio-input-desc"
          variant="button"
          @change="(value) => setSortDirection(value)"
        />
      </recess-radio-buttons>

      <recess-select
        :options="sortFields"
        :default-value="sortFields[0].value"
        class="c-search-toolbar__setting-input--sorting mr-2"
        data-test="search-toolbar-sorting"
        @input="(value) => setSorting(value)"
      />

      <recess-select
        :options="pageSizeFields"
        :default-value="pageSize"
        class="c-search-toolbar__setting-input--page-size mr-0 mr-sm-3"
        data-test="search-setting-page-size"
        @input="(value) => handlePageSize(value)"
      />

      <div class="d-flex pt-3 pt-md-0">
        <div
          class="d-none d-sm-flex ie-fix-svg"
          data-test="search-toolbar-block-view"
          @click="setIsColumnView(true)"
        >
          <GridViewSVG
            :css-class="['c-search-toolbar__setting-input--block-view mr-2', { 'active' : isColumnView }]"
          />
        </div>

        <div
          class="d-none d-sm-flex ie-fix-svg"
          data-test="search-toolbar-list-view"
          @click="setIsColumnView(false)"
        >
          <ListViewSVG
            :css-class="['c-search-toolbar__setting-input--list-view mr-2', { 'active' : !isColumnView }]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ListViewSVG from '../IconComponents/ListViewSVG.vue'
import GridViewSVG from '../IconComponents/GridViewSVG.vue'
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'

export default {
    components: {
        ListViewSVG,
        GridViewSVG
    },
    data() {
        return {
            showFacets: false,
            sortFields: [
                { 
                    value: '', 
                    displayText: 'Meest relevant' 
                },
                {
                    value: 'Title',
                    displayText: 'Naam ontwikkelactiviteit',
                    ascText: 'a - z',
                    descText: 'z - a',
                    defaultSortDirection: ODataSortingDirections.ASC
                },
                {
                    value: 'ProviderName',
                    displayText: 'Opleider',
                    ascText: 'a - z',
                    descText: 'z - a',
                    defaultSortDirection: ODataSortingDirections.ASC
                },
                {
                    value: 'Price',
                    displayText: 'Prijs',
                    ascText: 'laag - hoog',
                    descText: 'hoog - laag',
                    defaultSortDirection: ODataSortingDirections.ASC
                },
                {
                    value: 'Rating',
                    displayText: 'Beoordeling',
                    ascText: 'laag - hoog',
                    descText: 'hoog - laag',
                    defaultSortDirection: ODataSortingDirections.DESC
                },
                {
                    value: 'CourseDurationInDays',
                    displayText: 'Duur ontwikkelactiviteit',
                    ascText: 'kort - lang',
                    descText: 'lang - kort',
                    defaultSortDirection: ODataSortingDirections.ASC
                },
                {
                    value: 'Level_nl',
                    displayText: 'Niveau ontwikkelactiviteit',
                    ascText: 'a - z',
                    descText: 'z - a',
                    defaultSortDirection: ODataSortingDirections.ASC
                }
            ],
            currentSorting: {
                fieldName: '',
                direction: ODataSortingDirections.ASC
            },
            ascText: 'a - z',
            descText: 'z - a',
            pageSizeFields: [
                { value: 10, displayText: '10' },
                { value: 20, displayText: '20' },
                { value: 40, displayText: '40' },
                { value: 80, displayText: '80' },
                { value: 120, displayText: '120' }
            ]
        }
    },
    computed: {
        ...mapState('searchModule', ['pageSize', 'sorting', 'isColumnView', 'selectedFilters', 'selectedPeersFilter', 'locationRadiusFilters']),
        showResetFilterButton() {
            return !!(this.selectedFilters?.length || this.selectedPeersFilter?.length || this.locationRadiusFilters?.length) 
        }
    },
    methods: {
        ...mapMutations('searchModule', ['setIsColumnView']),
        ...mapActions('searchModule', [
            'handlePageSize',
            'handleSorting'
        ]),
        toggleFacets() {
            this.showFacets = !this.showFacets
            this.$emit('toggleFacets')

            if (this.showFacets)
                this.$gtag.event('filter_toggle', {})
        },
  
        setSorting(sorting) {
           
            this.currentSorting.fieldName = sorting
            this.currentSorting.direction = this.sortFields.find(x => x?.value === sorting)?.defaultSortDirection
            
            const currentSortingOption = this.sortFields.find(x => x?.value === this.currentSorting?.fieldName)  
            this.ascText = currentSortingOption?.ascText ?? 'a - z'
            this.descText = currentSortingOption?.descText ?? 'z - a'

            this.handleSorting([this.currentSorting])
        },
        setSortDirection(sortDirection) {
            this.currentSorting.direction = sortDirection === ODataSortingDirections.ASC 
                ? ODataSortingDirections.DESC 
                : ODataSortingDirections.ASC
            this.handleSorting([this.currentSorting])
        }
    }
}
</script>

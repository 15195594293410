import BasePerimeter from './BasePerimeter'
import * as enrollmentStatus from '../constants/enrollmentStatus'
import * as studyContractStatus from '../constants/studyContractStatus'

export default new BasePerimeter({
    purpose: 'studyContractAuthorization',

    can: {
        reviewStudyContract(studyContract) {
            return (
                studyContract?.enrollment?.statusId ===
          enrollmentStatus.PENDINGSTUDYCONTRACTAGREEMENT &&
        this.isOwnEnrollment(studyContract.enrollment.user.userId)
            )
        },
        downloadStudyContract(studyContract) {
            return studyContract?.state === (studyContractStatus.APPROVED || studyContractStatus.REJECTED)
        },
        uploadStudyContract(studyContract) {
            return this.isOwnEnrollment(studyContract?.enrollment?.user?.userId) && studyContract?.state === studyContractStatus.APPROVED
        },
        downloadUploadedStudyContract(studyContract, uploadedStudyContractUrl) {
            return (this.isCoordinator() || this.isTeamLeader()
             || this.isOwnEnrollment(studyContract?.enrollment?.user?.userId)) 
             && uploadedStudyContractUrl
             && studyContract?.state === studyContractStatus.APPROVED
        }
    }
})

<template>
  <div v-if="filters && filters.length">
    <slot name="facet-title"></slot>
    <div class="c-facet__content">
      <div
        v-for="facetFilter in filters"
        :key="facetFilter.key"
        class="c-facet__options"
      >
        <recess-checkbox-input
          :disabled="isLoading || facetFilter.count === 0"
          :label-text="getDisplayValue(facetFilter)"
          :default-checked="isSelectedFilter(facet.key, facetFilter.key)"
          data-test="facet-options"
          @input="(checked) => onFilterOptionChange(facetFilter, checked)"
        />
      </div>

      <template v-if="showMoreFilters">
        <div
          v-if="truncateFilters"
          class="c-facet__show-all"
          data-test="show-all-btn"
          @click="showHiddenFilters()"
        >
          &#x2b; Toon {{ getTruncateFiltersLength }} meer
        </div>

        <div
          v-else-if="!truncateFilters "
          class="c-facet__show-all"
          data-test="show-less-btn"
          @click="hideHiddenFilters()"
        >
          &#x2d; Toon minder
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import facetFilterHelper from '../../../../modules/facetFilterHelper'

export default {
    name: 'CourseSearchFacet',
    props: {
        facetsTruncate: {
            type: Boolean,
            default: true
        },
        facetsMaxVisible: {
            type: Number,
            default: 6
        },
        facet: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    data() {
        return {
            maxVisibleFilters: this.facetsMaxVisible,
            truncateFilters: this.facetsTruncate
        }
    },
    computed: {
        ...mapState('searchModule', ['isLoading', 'selectedFilters']),
        ...mapGetters('searchModule', ['isSelectedFilter']),
        filters() {
            if(!this.facet?.values?.length) return []

            return this.truncateFilters
                ? this.facet.values.slice(0, this.maxVisibleFilters)
                : this.facet.values
        },
        showMoreFilters() {
            return (
                this.facet?.values?.length > this.maxVisibleFilters
            )
        },
        getTruncateFiltersLength() {
            return this.truncateFilters && this.facet?.values?.length
                ? this.facet.values.length - this.maxVisibleFilters
                : ''
        },
        isSearchResultView() {
            return this.$route?.name === 'search'
        }
    },
    methods: {
        ...mapActions('searchModule', ['setFilter']),
        async onFilterOptionChange(selectedFacetFilter, checked) {
            if(checked) {
                this.dispatchGtagEvent()
            }

            const mappedSelectedFacetFilter = {
                key: this.facet.key,
                value: selectedFacetFilter.key,
                checked
            }

            await this.setFilter({
                facetName: mappedSelectedFacetFilter.key,
                filterValue: mappedSelectedFacetFilter.value,
                checked,
                fetchResults: !this.isSearchResultView
            })

            if(this.isSearchResultView) await this.attachSelectedFilterToUrl(mappedSelectedFacetFilter)
        },
        dispatchGtagEvent() {
            const isSelected = this.selectedFilters.some(f => f.name.toLowerCase() === this.facet.key.toLowerCase())
            if(!isSelected) {
                this.$gtag.event('facet_toggled', {
                    'event_label': this.facet.display
                })
            }
        },
        async attachSelectedFilterToUrl(selectedFacetFilter) {
            const encodedExistingFacetFilters = this.$route.query.facetFilters
            
            await this.$router.replace({
                query: {
                    ...this.$route.query,
                    facetFilters: facetFilterHelper.getFacetFiltersAsBase64(selectedFacetFilter,  encodedExistingFacetFilters)
                }
            })
        },
        showHiddenFilters() {
            this.truncateFilters = false
        },
        hideHiddenFilters() {
            this.truncateFilters = true
        },
        getDisplayValue(facet) {
            // First check if the display value is there
            if (facet.display) return facet.count ? `${facet.display} (${facet.count})` : facet.display

            // Otherwise check if the key value is there
            if (facet.key) return facet.count ? `${facet.key} (${facet.count})` : facet.key

            // Otherwise return an empty string..
            return ''
        }
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div class="c-sub-record">
    <recess-accordion :data-test="`${subRecordIndex}-sub-record`">
      <template #accordion-title>
        <div 
          class="row c-accordion-header"
          data-test="sub-record-header"
        >
          <span 
            class="c-accordion-header__item c-accordion-header__item--1"
            data-test="sub-record-completed"
          >
            <p class="font-weight-bold u-textcolor-secondary">
              Afgerond:
            </p>
            {{ item.completed ? 'Ja' : 'Nee' }}
          </span>
              
          <span 
            class="c-accordion-header__item c-accordion-header__item--2"
            data-test="sub-record-completed-date"
          >
            <p class="font-weight-bold u-textcolor-secondary">
              Datum afgerond: 
            </p>

            {{ item.dateCompleted || '-' }}
          </span>

          <span 
            class="c-accordion-header__item c-accordion-header__item--3"
            data-test="sub-record-passed-exam"
          >
            <p class="font-weight-bold u-textcolor-secondary">

              Behaald: 
            </p>

            {{ passedExamText }}
          </span>
                
          <recess-tooltip
            v-if="isCreatedByProvider"
            class="c-accordion-header__item c-accordion-header__item--button"
          >
            <recess-icon
              icon="danger"
              color="warning"
              data-test="warning-icon"
            />
            <template #content>
              <span class="d-block u-max-width-500">{{ isCreatedByProviderTooltipText }}</span>
            </template>
          </recess-tooltip>

          <recess-icon
            v-else-if="$isAllowed('deletePEReminderSubRecord', {subRecord: item, assignedUserId}) || isNewSubRecord"
            icon="bin"
            class="c-accordion-header__item c-accordion-header__item--button"
            data-test="delete-sub-record"
            color="primary"
            @click.native="deleteSubRecord"
          />

        </div>
      </template>
      
      <template #accordion-content>
        <form @submit.prevent="triggerSave">
          <fieldset class="row">
            <legend class="d-none">Sub record detail</legend>

            <recess-select
              v-model="item.completed"
              :default-value="item.completed"
              :options="yesOrNoOptions"
              :disabled="!showSaveButton"
              :error-message="getErrorMessage('completed')"
              class="col-lg-4 col-md-6 col-12 mb-3"
              select-option-text="Maak een keuze"
              data-test="sub-record-completed-input"
              label-text="Afgerond *"
            />
      
            <recess-date-picker 
              v-model="item.dateCompleted"
              :default-value="item.dateCompleted"
              :disabled="!item.completed || !showSaveButton"
              :error-message="getErrorMessage('dateCompleted')"
              class="col-lg-4 col-md-6 col-12 mb-3"
              placeholder="dd-mm-yyyy"
              label-text="Datum afgerond"
              data-test="sub-record-completed-date-input"
            />
      
            <recess-select
              v-model="item.examStatus"
              :default-value="passedExamDefaultValue"
              :options="examStatusOptions"
              :disabled="isPassedExamDisabled"
              class="col-lg-4 col-md-6 col-12 mb-3"
              select-option-text="Maak een keuze"
              data-test="sub-record-exam-status-input"
              :label-text="`Examen Status ${ $isAllowed('displayExamStatusNoConsentIndication', item) ? '**' : ''}`"
            />
      
            <recess-date-picker 
              v-model="item.startDate"
              :default-value="item.startDate"
              :error-message="getErrorMessage('startDate')"
              :disabled="!showSaveButton"
              class="col-lg-4 col-md-6 col-12 mb-3"
              placeholder="dd-mm-yyyy"
              label-text="Ingangsdatum"
              data-test="sub-record-start-date-input"
              @input="resetAlerts"
            />
  
            <recess-date-picker 
              v-model="item.expiryDate" 
              :default-value="item.expiryDate"
              :error-message="getErrorMessage('expiryDate')"
              class="col-lg-4 col-md-6 col-12 mb-3"
              placeholder="dd-mm-yyyy"
              :disabled="!showSaveButton"
              label-text="Vervaldatum"
              data-test="sub-record-expiry-date-input"
              @input="resetAlerts"
            />
  
            <recess-select
              v-model="item.emailReminderPeriod"
              :default-value="item.emailReminderPeriod"
              :options="emailReminderPeriodOptions"
              :disabled="!item.expiryDate || !showSaveButton"
              class="col-lg-4 col-md-6 col-12 mb-3"
              select-option-text="Maak een keuze"
              data-test="sub-record-email-reminder-input"
              label-text="Hoelang van tevoren wilt u herinnerd worden?"
              @input="resetAlerts"
            />

            <template v-if="isCreatedByProvider">
              <recess-input
                :value="progressPercentageValue"
                disabled
                class="col-lg-4 col-md-6 col-12 mb-3"
                data-test="sub-record-percentages-input"
                :label-text="`Percentage behaald ${!progressPercentageConsent ? '**' : ''}`"
              />
  
              <recess-input
                :value="completionAtRiskValue"
                disabled
                class="col-lg-4 col-md-6 col-12 mb-3"
                data-test="sub-record-progress-input"
                :label-text="`Voortgang in gevaar ${!completionAtRiskConsent ? '**' : ''}`"
              />

              <recess-input
                :value="points"
                disabled
                class="col-lg-4 col-md-6 col-12 mb-3"
                data-test="sub-record-points"
                label-text="PE Punten"
              />
            </template>
          </fieldset>
  
          <recess-divider v-if="showUpload || hasUploadedCertificates" show-line />
  
          <fieldset>
            <legend class="d-none">Upload certification</legend>
            
            <recess-file-upload
              v-if="showUpload"
              ref="uploadFileInput"
              data-test="sub-record-upload-input"
              :disabled="!showSaveButton"
              :label-text="`Kies diploma/certificaat bestand ${$isAllowed('displayDiplomaNoConsentIndication', item) ? '**' : ''}`"
              :allowed-extensions="['pdf', 'tif', 'tiff', 'zip', 'jpg', 'png']"
              :placeholder-text="'.pdf .tiff .zip .jpg .png'"
              @upload="uploadDocument"
            />
            <div v-if="$isAllowed('displayUploadedCertificates', item)">

              <template v-if="providerDiploma">
                <label class="ml-2">Diploma/certificaat</label>
                <uploaded-file-component
                  :document-url="providerDiploma.url"
                  :document-name="providerDiploma.name"
                  :show-delete-button="false"
                >
                  <template #buttons>
                    <recess-tooltip
                    >
                      <recess-icon
                        icon="danger"
                        color="warning"
                        data-test="warning-icon"
                        class="ml-2"
                      />
                      <template #content>
                        <span class="d-block u-max-width-500">Als er geen toestemming is gegeven voor het delen van een kopie of diploma dan kan alleen de Coördinator van uw organisatie deze naderhand nog handmatig toevoegen.</span>
                      </template>
                    </recess-tooltip>
                  </template>
                </uploaded-file-component>         
              </template>

              <template v-else-if="item.certifications || item.certificationPaths">
                <template v-if="item.certifications && item.certifications.length">
                  <div 
                    v-for="(cert, index) in item.certifications"
                    :key="index"
                    data-test="download-certification-file"
                  >
                    <uploaded-file-component
                      :document-url="cert.fileData"
                      :document-name="cert.fileName"
                      disabled
                      @deleted="removeUpload(cert)"
                    />
                  </div>
                </template>
      
                <template v-if="item.certificationPaths && item.certificationPaths.length">
                  <label v-if="!showUpload" class="ml-2">Diploma/certificaat</label>
                  <div 
                    v-for="certificate in item.certificationPaths"
                    :key="certificate"
                  >
                    <uploaded-file-component
                      :document-url="certificate"
                      :document-name="buildName(certificate, entityId)"
                      :show-delete-button="showUpload"
                      @deleted="deleteCertificate(certificate)"
                    />
                  </div>
                </template>
              </template>
            </div>
          </fieldset>
                  
          <recess-divider />
          
          <div class="d-flex align-items-center justify-content-between">
            <small
              v-if="isCreatedByProvider && displayNoConsentWarningText"
              data-test="consent-not-given-text" 
              class="u-text-small">
              <strong>**</strong> Er is geen toestemming gegeven voor het delen van dit leerresultaat
            </small>
            <recess-button
              v-if="showSaveButton"
              class="ml-auto"
              title="Opslaan"
              variant="secondary"
              data-test="save-sub-record"
              type="submit"
            />
          </div>
        </form>

        <div
          v-if="isCreatedByProvider && !!item.enrollment"
          data-test="enrollment-listing">          
          <recess-divider variant="xsmall" />
          <recess-table>
            <recess-table-head>
              <recess-table-row>
                <template v-for="header in enrollmentTableHeaders">
                  <recess-table-head-item
                    v-if="displayEnrollmentTableHeaderCell(header.key, useNetPrice)"
                    :key="header.key"
                  >
                    {{ header.title }}
                  </recess-table-head-item>
                </template>
              </recess-table-row>
            </recess-table-head>
            <recess-table-body>
              <recess-table-row>
                <recess-table-cell
                  :data-label="enrollmentTableHeaders[0].title"
                >
                  <router-link
                    :to="{ name: 'course', query: { course: item.enrollment.eduMSCourseId, assortment: item.enrollment.assortmentId }}"
                  >
                    <span v-html="item.enrollment.courseName"></span>
                  </router-link>
                </recess-table-cell>
                <recess-table-cell
                  :data-label="enrollmentTableHeaders[1].title"
                >
                  {{ formatDate(item.enrollment.dateTimeCreated) }}
                </recess-table-cell>
                <recess-table-cell
                  v-if="$isAllowed('displayPricingInformation')"
                  :data-label="enrollmentTableHeaders[2].title"
                >
                  <span data-test="price-with-or-without-vat">{{ getPriceWithOrWithoutVat(displayVatInPrices, item.enrollment.displayPrice) }}</span>
                </recess-table-cell>
                <recess-table-cell 
                  :data-label="enrollmentTableHeaders[3].title">{{ item.enrollment.courseVariant }}</recess-table-cell>
                <recess-table-cell
                  :data-label="enrollmentTableHeaders[4].title"
                >
                  {{ item.enrollment.currentStatus ? item.enrollment.currentStatus : '' }}
                </recess-table-cell>
                <recess-table-cell class="d-flex justify-content-end">
                  <div>
                    <recess-button
                      data-test="enrollment-details-button"
                      :url="{ name: 'enrollment-details', query: { enrollment: item.enrollmentId }}"
                      title="Bekijk"
                      variant="tertiary"
                    />
                  </div>
                </recess-table-cell>
              </recess-table-row>
            </recess-table-body>
          </recess-table>
        </div>
      </template>
    </recess-accordion>
    
    <recess-divider variant="xsmall" />

    <remove-item-confirmation-component
      :is-visible="removePESubRecordConfirmationVisibility"
      confirmation-text="Weet u zeker dat u deze subrecord wilt verwijderen?"
      @close="removePESubRecordConfirmationVisibility = false"
      @remove="deleteSubRecordById"
    />

    <remove-item-confirmation-component
      :is-visible="removeDocumentConfirmationVisibility"
      confirmation-text="Weet u zeker dat u deze certificatie wilt verwijderen?"
      @close="removeDocumentConfirmationVisibility = false"
      @remove="removeCertificate"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import DateUtils from '../../../utils/DateUtils'
import { validationMessages, isValidDate, isLaterThanStartDate } from '../../../modules/validationsHelper'
import { mapFileUploadsToRequests } from '../../../utils/MapUtils'
import RemoveItemConfirmationComponent from "../RemoveItemConfirmationComponent/RemoveItemConfirmationComponent.vue"
import UploadedFileComponent from '../UploadedFileComponent/UploadedFileComponent.vue'
import permanentEducationPerimeter from '../../../perimeters/permanentEducationPerimeter'
import permanentEducationEmailReminderPeriod from '../../../constants/permanentEducationEmailReminderPeriod'
import enrollmentsTableHelper from '../../../modules/enrollmentsTableHelper'
import examStatus from '../../../constants/examStatus'
import priceFormatter from '../../../modules/priceFormatter'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import { examStatusOptions } from '../../../constants/examStatusOptions'

export default {
    perimeters: [permanentEducationPerimeter, featuresPerimeter],
    components: {
        RemoveItemConfirmationComponent,
        UploadedFileComponent
    },
    props: {
        subRecord: {
            type: Object,
            required: true
        },
        showPopup: {
            type: Boolean,
            default: true
        },
        showSaveButton: {
            type: Boolean,
            default: true
        },
        showUpload: {
            type: Boolean,
            default: true
        },
        assignedUserId: {
            type: Number,
            default: null
        },
        subRecordIndex: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            item: {
                certifications: [],
                startDate: null,
                expiryDate: null,
                emailReminderPeriod: null,
                completed: null,
                examStatus: null,
                dateCompleted: null,
                enrollment: null,
                points: null,
                coordinatorSetExamStatus: null
            },
            examStatusOptions,
            yesOrNoOptions: [
                {
                    displayText: 'Ja',
                    value: true
                },
                {
                    displayText: 'Nee',
                    value: false
                }
            ],
            emailReminderPeriodOptions: [
                {
                    displayText: '2 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.TWO_MONTHS
                },
                {
                    displayText: '3 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.THREE_MONTHS
                },
                {
                    displayText: '4 maanden voor vervaldatum',
                    value:  permanentEducationEmailReminderPeriod.FOUR_MONTHS
                },
                {
                    displayText: '5 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.FIVE_MONTHS
                },
                {
                    displayText: '6 maanden voor vervaldatum',
                    value: permanentEducationEmailReminderPeriod.SIX_MONTHS
                }
            ],
            isUploadingDocument: false,
            isSuccess: false,
            isError: false,
            validationMessages,
            removePESubRecordConfirmationVisibility: false,
            removeDocumentConfirmationVisibility: false,
            toDelete: undefined
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId']),
        ...mapGetters('accountModule', ['displayVatInPrices', 'useNetPrice']),
        hasUploadedCertificates() {
            return this.providerDiploma || !!this.item.certificationPaths?.length
        },
        providerDiploma() {
            const { providerDiplomaName, providerDiplomaFile } = this.item
            if (!providerDiplomaName || !providerDiplomaFile) return null

            return {
                url: providerDiplomaFile,
                name: providerDiplomaName
            }
        },
        enrollmentMutualLearningResult() {
            return this.item?.enrollment?.enrollmentMutualLearningResult
        },
        displayNoConsentWarningText() {
            return this.$isAllowed('displayNoConsentWarningText', this.item)
        },
        progressPercentageConsent() {
            return this.enrollmentMutualLearningResult?.progressPercentageConsent
        },  
        diplomaConsent() {
            return this.enrollmentMutualLearningResult?.diplomaConsent
        },
        passedExamConsent() {
            return this.enrollmentMutualLearningResult?.passedExamConsent
        },
        completionAtRiskConsent() {
            return this.enrollmentMutualLearningResult?.completionAtRiskConsent
        },
        passedExamDefaultValue() {
            if (!this.displayPassedExamValue) return null
            if (this.hasCoordinatorSetExamStatusValue) {
                return this.item.coordinatorSetExamStatus
            }

            return this.item.examStatus
        },
        hasCoordinatorSetExamStatusValue() {
            return this.isCreatedByProvider && !this.item.hasProviderUpdatedExamStatus && this.item.coordinatorSetExamStatus !== null
        },
        progressPercentageValue() {
            if(!this.item.progressPercentage || !this.progressPercentageConsent) return null

            return `${this.item.progressPercentage}%`
        },  
        completionAtRiskValue() {
            if (this.item.completionAtRisk === null || this.item.completionAtRisk === undefined || !this.completionAtRiskConsent) return null

            return this.item.completionAtRisk  ? 'Ja' : 'Nee'
        },
        points() {
            return this.item.points
        },
        isCreatedByProviderTooltipText() {
            let baseText = 'Let op, dit record is automatisch toegevoegd voor het delen van leerresultaten door de uitvoerder van de ontwikkelactiviteit.'
            if (!this.showSaveButton) baseText += ' Dit betekent dat u hier zelf geen wijzigingen op kunt aanbrengen.'

            return baseText
        },
        isNewSubRecord() {
            return this.subRecord?.isNewSubRecord
        },
        isPassedExamDisabled() {
            return !this.$isAllowed('updateExamStatus', { subRecord: this.item, assignedUserId: this.assignedUserId })
        },
        displayPassedExamValue() {
            return this.$isAllowed('displayPassedExamValue', { subRecord: this.subRecord, assignedUserId: this.assignedUserId })
        },
        passedExamText() {
            if (!this.displayPassedExamValue) return '-'
            const status = this.hasCoordinatorSetExamStatusValue ? this.item.coordinatorSetExamStatus : this.item.examStatus
            switch (status) {
                case examStatus.PASSED:
                    return 'Ja'
                case examStatus.FAILED:
                    return 'Nee'
                case examStatus.NOTAPPLICABLE:
                    return 'n.v.t'
                default:
                    return '-'
            }
        },
        entity() {
            const entityValue = {
                ...this.item,
                dateCompleted: DateUtils.parseToISOString(this.item.dateCompleted),
                expiryDate: DateUtils.parseToISOString(this.item.expiryDate),
                startDate: DateUtils.parseToISOString(this.item.startDate),
                emailReminderPeriod: this.item.emailReminderPeriod ?? permanentEducationEmailReminderPeriod.NONE
            }

            // Save the coordinator set value for exam status in the PE item entity to be used later if neededs
            if (this.isCreatedByProvider && !this.item.hasProviderUpdatedExamStatus && this.item.examStatus != null) {
                entityValue.coordinatorSetExamStatus = this.item.examStatus
            }

            return entityValue
        },
        entityId() {
            return this.subRecord.permanentEducationItemId
        },
        isCreatedByProvider() {
            return !this.subRecord.isImported && !this.subRecord.createdByUserId && !this.subRecord.isNewSubRecord
        },
        enrollmentTableHeaders() {
            return enrollmentsTableHelper.getEnrollmentTableHeaders(this.useNetPrice, this.displayVatInPrices)
        }
    },
    watch: {
        subRecord(value) { 
            this.updateItem(value)
        },
        item: {
            handler(newValue) {
                if(!newValue.completed) this.item.dateCompleted = null
                if(!newValue.expiryDate) this.item.emailReminderPeriod = null
            },
            deep: true
        }
    },
    created() {
        this.updateItem(this.subRecord)
    },
    validations() {
        return {
            item: {
                startDate: { isValidDate },
                dateCompleted: { 
                    isValidDate,
                    isLaterThanStartDate: val => isLaterThanStartDate(val, this.item)
                },
                expiryDate: { 
                    isValidDate, 
                    isLaterThanStartDate: val => isLaterThanStartDate(val, this.item)
                },
                completed: { required }
            }
        }
    },
    methods: {
        triggerUpdate() {
            if (this.showSaveButton) { return }
            this.$v.$touch()
            if (this.$v.$invalid) { return }
            this.$emit('update', this.entity)
        },
        triggerSave() {
            if (!this.showSaveButton) { return }
            this.$v.$touch()
            if (this.$v.$invalid) { return }
            this.$emit('save', this.entity)
        },
        deleteCertificate(path) {
            this.toDelete = {
                path,
                itemId: this.subRecord.permanentEducationItemId
            }
            this.removeDocumentConfirmationVisibility = true
        },
        removeCertificate() {
            this.removeDocumentConfirmationVisibility = false
            this.$emit('remove-certificate', this.toDelete)
            this.toDelete = undefined
        },
        uploadDocument(docs) {
            const toUpload = mapFileUploadsToRequests(docs)
            if (!this.item?.certifications) {
                this.item.certifications = []
            }
            toUpload.forEach(f => {
                if (this.item?.certifications?.find(c => c.fileName === f.fileName)) {
                    return
                }
                this.item.certifications.push(f)
            })
            this.$refs.uploadFileInput.selectedFiles = []
            this.triggerUpdate()
        },
        removeUpload(cert) {
            this.item.certifications = this.item.certifications.filter(c => c.fileName !== cert.fileName)
            this.$emit('remove-upload', cert)
        },
        deleteSubRecord(event) {
            event.stopPropagation()
            if (this.showPopup) {
                this.removePESubRecordConfirmationVisibility = true
            } else {
                this.$emit('delete-sub-record', this.subRecord)
            }
        },
        deleteSubRecordById() {
            this.$emit('delete-sub-record', this.subRecord.permanentEducationItemId)
            this.removePESubRecordConfirmationVisibility = false
        },
        getErrorMessage(fieldName) {            
            const fieldValidation = this.$v.item[fieldName]
            const fieldValue = this.item[fieldName]
            // Check Invalid
            if (fieldValidation?.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }
            // Check Required
            if (fieldValidation?.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }

            return null
        },
        updateItem(value) {
            this.item = {
                ...value,
                certifications: value.certifications ?? [],
                startDate: value.startDate ? DateUtils.formatDate(value.startDate) : null,
                expiryDate: value.expiryDate ? DateUtils.formatDate(value.expiryDate) : null,
                dateCompleted: value.dateCompleted ? DateUtils.formatDate(value.dateCompleted) : null,
                emailReminderPeriod: value.emailReminderPeriod ?? permanentEducationEmailReminderPeriod.NONE
            }
        },
        resetAlerts() {
            this.isSuccess = false
            this.isError = false
            this.triggerUpdate()
        },
        buildName(url, id) {
            const name = url?.slice(url?.lastIndexOf('/') + 1, url?.lastIndexOf('?'))
            const prefix = `${id}_`
            return name?.startsWith(prefix) ? name.slice(prefix.length) : name
        },
        displayEnrollmentTableHeaderCell: (headerKey, useNetPrice) => enrollmentsTableHelper.displayHeaderCell(headerKey, useNetPrice),
        formatDate: e => DateUtils.formatDate(e),
        formatPrice: priceFormatter.formatPrice,
        getPriceWithOrWithoutVat: coursePriceHelper.getPriceWithOrWithoutVat
    }
}
</script>
<template>
  <div
    class="c-searchinput qa-search"
  >
    <div
      class="c-searchinput__wrapper"
      data-test="search-input-wrapper"
    >
      <recess-input
        v-model="searchQuery"
        data-test="search-course-input"
        :placeholder-text="placeholder"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />

      <div
        v-if="showDefaultIcon"
        class="c-searchinput__button qa-search-button"
        data-test="search-input-button"
        @click="$emit('button-click')"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'SearchInput',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            searchQuery: this.value || ''
        }
    },
    watch: {
        searchQuery(newValue) {
            this.$emit('input', newValue)
        },
        value(newValue) {
            this.searchQuery = newValue
        }
    }
}
</script>
import { getOplzClient } from './apiClient'

const baseUrl = '/quick-links'

/**
 * fetch quick links for an account
 * 
 * @param {number} accountId 
 * @returns {Promise<any[]>} List of quicklinks
 */
export async function loadQuickLinks(accountId) {
    const client = await getOplzClient()
    const res = await client.get(`${baseUrl}?accountId=${accountId}`)
    return res.data
}

export default {
    loadQuickLinks
}
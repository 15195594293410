import { getOplzClient } from './apiClient'

const baseUrl = '/project-requests'

const post = async projectRequest => {
    const client = await getOplzClient()

    return client.post(baseUrl, projectRequest)
        .then(res => res?.data)
}

const getById = async id => {
    const client = await getOplzClient()

    return client.get(`${baseUrl}/${id}`)
        .then(res => res?.data)
}

// TODO: re-evaluate the default value of the paramenters
const get = async (filter, orderBy, skip = 0, take = 10) => {
    const client = await getOplzClient()

    const params = new URLSearchParams()
    if (filter) {
        params.append('filter', filter)
    }
    if (orderBy) {
        params.append('orderBy', orderBy)
    }
    if (skip !== null) {
        params.append('skip', skip)
    }
    if (take) {
        params.append('take', take)
    }

    const url = `${baseUrl}?${params.toString()}`
    return client.get(url).then(res => res.data)
}

const approve = async (projectRequestId, payload) => {
    const client = await getOplzClient()

    return client.post(`${baseUrl}/${projectRequestId}/approve`, payload)
        .then(res => res?.data)
}

const reject = async rejectProjectRequest => {
    const client = await getOplzClient()

    return client.post(`${baseUrl}/${rejectProjectRequest.id}/reject`, rejectProjectRequest)
        .then(res => res?.data)
}

const cancel = async (projectRequestId, payload) => {
    const client = await getOplzClient()

    return client.post(`${baseUrl}/${projectRequestId}/cancel`, payload)
        .then(res => res?.data)
}

const getPONumberByPurchaseAgreementId = async purchaseAgreementId => {
    const client = await getOplzClient()

    return client.get(`${baseUrl}/${purchaseAgreementId}/purchase-order-number`)
        .then(res => res?.data)
}

export default {
    post,
    get,
    getById,
    approve,
    reject,
    cancel,
    getPONumberByPurchaseAgreementId
}
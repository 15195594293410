<template>
  <recess-list
    v-if="course"
    class="p-0"
  >
    <recess-list-row v-if="course.educationLevelCodeDisplayValue">
      <span class="font-weight-bold">Niveau</span>
      <span class="text-right">{{ course.educationLevelCodeDisplayValue }}</span>
    </recess-list-row>
    <recess-list-row v-if="durationText">
      <span class="font-weight-bold">Duur</span>
      <span class="text-right">{{ durationText }}</span>
    </recess-list-row>
    <recess-list-row
      v-if="course.learningMethod && 
        ((course.learningMethod.typeDisplayValue || course.learningMethod.type ) ||
          (course.learningMethod.formatDisplayValue || course.learningMethod.format ))"
    >
      <span class="font-weight-bold">Leermethode</span>
      <span class="text-right">
        {{ learningMethodFormatText }} {{ learningMethodTypeText }}
      </span>
    </recess-list-row>
    <recess-list-row
      v-if="assortmentName"
    >
      <span class="font-weight-bold">Assortiment</span>
      <span class="text-right">{{ assortmentName }}</span>
    </recess-list-row>
    <recess-list-row v-if="Object.keys(provider).length && (provider.name || provider.logoUrl)">
      <img
        v-if="provider.logoUrl"
        alt="Provider logo"
        class="c-summary__provider-logo"
        data-test="provider-logo"
        :src="provider.logoUrl"
      />
      <span
        v-else
        class="c-summary__provider-name"
      >{{ provider.name }}</span>
    </recess-list-row>
  </recess-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        course: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters('coursePageModule', ['assortmentName']),
        durationText() {
            const durationValue = this.course?.learningMethod?.duration?.value
            const durationUnit = this.course?.learningMethod?.duration?.unit
            return durationValue && durationUnit ? `${durationValue} ${durationUnit}` : null
        },
        provider() {
            if (this.course?.embedded?.provider) {
                const { name, logoUrl } = this.course.embedded.provider

                return {
                    name,
                    logoUrl
                }
            }
            return null
        },
        learningMethodFormatText() {
            return this.course?.learningMethod?.formatDisplayValue || this.course?.learningMethod?.format
        },
        learningMethodTypeText() {
            if(!this.course?.learningMethod?.typeDisplayValue && !this.course?.learningMethod?.type) return null
            
            // if there is a format, display the type in brackets, otherwise, lose the brackets
            return this.course?.learningMethod?.formatDisplayValue || this.course?.learningMethod?.format ? 
                `(${this.course?.learningMethod?.typeDisplayValue || this.course?.learningMethod?.type})` : 
                this.course?.learningMethod?.typeDisplayValue || this.course?.learningMethod?.type
        }
    }
}
</script>


<template>
  <div
    class="c-facet__item"
    data-test="facet-location-radius"
  >
    <!-- Desktop view -->
    <div class="d-none d-md-block">
      <h5 class="c-facet__title">
        Plaats
      </h5>
      <recess-autocomplete
        v-model="city"
        class="c-facet__item--autocomplete"
        label-text="Stad"
        item-text="cityText"
        data-test="search-city"
        :on-query="queryCity"
        @input = "submitFilterByLocation"
      />
      <recess-select v-model="radius" :default-value="radius" label-text="Straal" :options="radiusOptions" @input="submitFilterByLocation"/>
    </div>
    
    <!-- Mobile view -->
    <recess-accordion
      class="d-md-none"
    >
      <template #accordion-title>
        <h4 class="c-facet__title mb-0">
          Plaats
        </h4>
      </template>
      <template #accordion-content>
        <recess-autocomplete
          v-model="city"
          label-text="Stad"
          item-text="cityText"
          data-test="search-city"
          :on-query="queryCity"
          @input = "submitFilterByLocation"
        />
        <recess-select v-model="radius" :default-value="radius" label-text="Straal" :options="radiusOptions" @input="submitFilterByLocation"/>
      </template>
    </recess-accordion> 
  </div>
</template>

<script>
import axios from 'axios'
import { inject, onMounted, computed } from 'vue'
import isEqual from 'lodash/isEqual'
import geoLocationClient from '../../../../api/geoLocationClient'
import useRouter from '../../../../composables/useRouter'
import useRoute from '../../../../composables/useRoute'

export default {
    setup() {
        const store = inject("store")
        const router = useRouter()
        const route = useRoute()

        const city = computed({
            get: () => store.state.searchModule.locationFilterCity,
            set: val =>  store.commit('searchModule/setLocationFilterCity', val)
        })
        const radius = computed({
            get: () => store.state.searchModule.locationFilterRadius,
            set: val =>  store.commit('searchModule/setLocationFilterRadius', val)
        })

        const radiusOptions = [
            {
                displayText: '-',
                value: null
            },
            {
                displayText: '20 Km',
                value: '20'
            },
            {
                displayText: '50 Km',
                value: '50'
            },
            {
                displayText: '100 Km',
                value: '100'
            },
            {
                displayText: '200 Km',
                value: '200'
            }
        ]
       
        onMounted(async () => {
            // Set default city value 
            const userCity = store.getters['userModule/city']

            if(userCity) {
                const response = await geoLocationClient.getCityList(userCity)
                await store.commit('searchModule/setLocationFilterCity', response?.findIndex(element => element.toLowerCase() === userCity.toLowerCase()) > -1 ? userCity : '')
                store.commit('searchModule/setLocationFilterRadius', null)
            }
        })

        const searchCitiesByRadius = async () => {
            try {
                const cities = await geoLocationClient.search(city.value, radius.value)
                
                const mappedCities = cities?.map(c => c.name)

                return mappedCities
            } catch (error) {
                throw new Error(error)
            }
        }
        
        const hasLocationFiltersChanged = newLocationFilters => {
            const previousLocationFilters = store.state.searchModule.locationRadiusFilters

            return !isEqual(previousLocationFilters, newLocationFilters)
        }

        const submitFilterByLocation = async () => {
            if(!city.value) return

            let citiesInRange = []
            if(radius.value && city.value)
                citiesInRange = await searchCitiesByRadius()
                
            let includeLocationRadiusFilter = false
            if(radius.value) includeLocationRadiusFilter = true
            store.commit('searchModule/setIncludeLocationRadiusFilter', includeLocationRadiusFilter)
            
            if(hasLocationFiltersChanged(citiesInRange)) {
                // Reset previous selected facet and clear query string
                router.replace({...route.value, query: {...route.value.query, facetFilters: undefined }})
                await store.commit('searchModule/removeFilters')
                await store.commit('searchModule/setLocationRadiusFilter', citiesInRange)
                await store.dispatch('searchModule/fetchResults')
            }
        }

        const queryCity = async newValue => {
            // Cancel previous request
            geoLocationClient.cancelFetchCityList()
            
            if (!newValue || newValue.length < 3) return []
            try {
                const response = await geoLocationClient.getCityList(newValue)
                const cityList = (response ?? []).map(e => ({
                    cityText: e
                }))

                return cityList
                    
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    console.error(error)
                }
                return []
            }
        }

        return {
            city,
            radius,
            radiusOptions,
            submitFilterByLocation,
            queryCity
        }
    }
}
</script>
import { ref, readonly, computed, reactive, watch } from 'vue'
import projectRequestClient from '../api/projectRequestClient'
import odataFilterOperations from "../api/OData/odataFilterOperations"
import ODataQueryBuilder from '../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../api/OData/odataQueryFilter'
import useRoute from './useRoute'
import useStore from './useStore'
import useRouter from './useRouter'
import projectRequestListingViews from '../constants/projectRequestListingViews'
import projectRequestStatusClient from '../api/projectRequestStatusClient'
import useTableData from './useTableData'

const tabQuery = 'project-requests-tab'
const defaultPageSize = 10
const defaultFilters = {
    id: {
        key: 'id',
        value: '',
        labelText: "Zoek op projectid",
        operator: odataFilterOperations.EQUALS
    },
    name: {
        key: 'name',
        value: '',
        labelText: "Zoek op naam projectaanvraag",
        operator: odataFilterOperations.CONTAINS
    },
    status: {
        key: 'status/id',
        value: '',
        labelText: "Zoek op status",
        operator: odataFilterOperations.EQUALS
    },
    userFullName: {
        key: [],
        value: '',
        operator: odataFilterOperations.CONTAINS,
        keyCombination: 'or'
    },
    userEmail: {
        key: '',
        value: '',
        operator: odataFilterOperations.CONTAINS
    }
}

export default function useProjectRequestListing() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const isProjectInitiator = store.getters["userModule/isProjectInitiator"]
    const isProjectApprover = store.getters["userModule/isProjectApprover"]
    const userId = store.getters["userModule/userId"]

    const {
        totalCount, setTotalCount,
        pageSize, setPageSize,
        skip, setSkip,
        take, setTake,
        sort, setSort,
        pageIndex, setPageIndex,
        data, setData,
        totalPages,
        resetPagination
    } = useTableData({
        defaultPageSize,
        defaultSort: { key: 'dateCreated', direction: 'desc' }
    })

    const isError = ref(false)
    const setIsError = newValue => { isError.value = newValue }
    const projectRequestListingView = ref(null)
    const setProjectRequestsListingView = newValue => { projectRequestListingView.value = newValue }

    const isFetchingProjectRequests = ref(false)
    const setIsFetchingProjectRequests = newValue => { isFetchingProjectRequests.value = newValue }

    const noProjectRequests = ref(false)
    const setNoProjectRequests = newValue => { noProjectRequests.value = newValue }

    const projectRequestStatusOptions = ref([])
    const setProjectRequestStatusOptions = newValue => { projectRequestStatusOptions.value = newValue }
    const isFetchingProjectRequestStatuses = ref(false)
    const setIsFetchingProjectRequestStatuses = newValue => { isFetchingProjectRequestStatuses.value = newValue }

    const filters = reactive({ ...defaultFilters })

    const tableHeaders = computed(() => {
        const baseHeaders = [
            { key: "id", title: "Projectid", sortable: true },
            { key: 'name', title: 'Naam projectaanvraag', sortable: true },
            { key: 'dateCreated', title: 'Creatiedatum', sortable: true },
            { key: 'providerName', title: 'Leverancier' },
            { key: 'status', title: 'Status' },
            { key: 'actions', title: '' }
        ]

        const userHeader = { key: "user", title: "" }

        if (projectRequestListingView.value === projectRequestListingViews.PROJECTINITIATOR) {
            userHeader.title = 'Beoordelaar'
        } else if (projectRequestListingView.value === projectRequestListingViews.PROJECTAPPROVER) {
            userHeader.title = 'Gemaakt door'
        }

        baseHeaders.splice(4, 0, userHeader)

        return baseHeaders
    })

    const showTabs = computed(() => (isProjectApprover && isProjectInitiator))
    const isLoadingData = computed(() => (isFetchingProjectRequestStatuses.value || isFetchingProjectRequests.value))

    const mapProjectRequest = projectRequest => {
        const mappedProjectRequest = {
            name: projectRequest.name,
            id: projectRequest.id,
            providerName: projectRequest.providerName,
            status: {
                id: projectRequest.status?.id,
                description: projectRequest.status?.description
            },
            assignedApproverId: projectRequest.assignedApprover?.id,
            dateCreated: projectRequest.dateCreated
        }

        const setUserDetails = (user, emailAddressKey, fullNameKey) => {
            mappedProjectRequest[emailAddressKey] = user.emailAddress
            mappedProjectRequest[fullNameKey] = `${user.firstName} ${user.middleName ?? ''} ${user.lastName}`
        }

        if (projectRequestListingView.value === projectRequestListingViews.PROJECTINITIATOR && projectRequest.assignedApprover) {
            setUserDetails(projectRequest.assignedApprover, 'userEmailAddress', 'userFullName')
        } else if (projectRequestListingView.value === projectRequestListingViews.PROJECTAPPROVER && projectRequest.initiator) {
            setUserDetails(projectRequest.initiator, 'userEmailAddress', 'userFullName')
        }

        return mappedProjectRequest
    }

    const generateDataQuery = () => {
        const dataQuery = new ODataQueryBuilder()
            .setPagination(skip.value, take.value)

        if (sort.value?.key && sort.value?.direction) {
            dataQuery.setSort(sort.value.key, sort.value.direction)
        }

        Object.keys(filters).forEach(key => {
            const filter = filters[key]

            if (filter.value) {
                dataQuery
                    .addFilter(new ODataQueryFilter(filter.key, filter.operator, filter.value, filter.keyCombination))
            }
        })

        if (projectRequestListingView.value === projectRequestListingViews.PROJECTINITIATOR && isProjectInitiator) {
            dataQuery.addFilter(new ODataQueryFilter("user/userId", odataFilterOperations.EQUALS, userId))
        } else if (projectRequestListingView.value === projectRequestListingViews.PROJECTAPPROVER && isProjectApprover) {
            dataQuery.addFilter(new ODataQueryFilter("approver/userId", odataFilterOperations.EQUALS, userId))
        }

        return dataQuery.build()
    }

    const getProjectRequests = async () => {
        try {
            setIsFetchingProjectRequests(true)

            const dataQuery = generateDataQuery()
            const response = await projectRequestClient.get(dataQuery.filter, dataQuery.orderBy, dataQuery.skip, dataQuery.take)

            setData(response?.items?.map(projectRequest => mapProjectRequest(projectRequest)))
            setPageIndex(response?.pageIndex)
            setTake(response?.pageSize)
            setSkip(skip.value += take.value * pageIndex.value)
            setTotalCount(response?.totalCount)
            setNoProjectRequests(response?.totalCount === 0)
        } catch (error) {
            setIsError(true)
            throw new Error("Failed to retrieve project requests", { cause: error })
        } finally {
            setIsFetchingProjectRequests(false)
        }
    }

    const getProjectRequestStatuses = async () => {
        try {
            setIsFetchingProjectRequestStatuses(true)

            const response = await projectRequestStatusClient.get()
            const options = response.map(status => {
                return {
                    displayText: status.description,
                    value: status.id
                }
            })

            // Add first option as empty value to reset filters
            options.unshift({
                displayText: "",
                value: ""
            })
            setProjectRequestStatusOptions(options)
        } catch (error) {
            console.error("Failed to retrieve project request statuses", { cause: error })
        } finally {
            setIsFetchingProjectRequestStatuses(false)
        }
    }

    const setTabAndListingView = listingView => {
        router.replace({ ...route.value, query: { [tabQuery]: listingView } })
        setProjectRequestsListingView(listingView)
    }

    const initializeProjectRequestListingView = listingView => {
        const isValidView = Object.values(projectRequestListingViews).includes(listingView)

        if (isValidView) {
            if ((listingView === projectRequestListingViews.PROJECTAPPROVER && isProjectApprover) ||
                (listingView === projectRequestListingViews.PROJECTINITIATOR && isProjectInitiator)) {
                setTabAndListingView(listingView)
            }
        }
        else if (isProjectApprover && isProjectInitiator) {
            setTabAndListingView(projectRequestListingViews.PROJECTINITIATOR)
        } else if (isProjectApprover && !isProjectInitiator) {
            setProjectRequestsListingView(projectRequestListingViews.PROJECTAPPROVER)
        } else if (isProjectInitiator && !isProjectApprover) {
            setProjectRequestsListingView(projectRequestListingViews.PROJECTINITIATOR)
        }
    }

    const handleFilterSubmit = async () => {
        resetPagination(pageSize.value)
        await getProjectRequests()
    }

    const handlePaginationClick = async pageNumber => {
        setSkip(take.value * (pageNumber - 1))
        await getProjectRequests()
    }

    const resetFilters = () => Object.keys(filters).forEach(key => {
        filters[key].value = ""
    })

    const handleFiltersReset = async () => {
        resetFilters()
        resetPagination(pageSize.value)
        setData([])

        await getProjectRequests()
    }

    const refetchPojectRequests = async pSize => {
        resetPagination(pSize)
        await getProjectRequests()
    }

    watch(pageSize, refetchPojectRequests)

    watch(sort, () => refetchPojectRequests(pageSize.value))

    watch(projectRequestListingView, newValue => {
        if (newValue === projectRequestListingViews.PROJECTINITIATOR) {
            filters.userFullName.key = ['approver/firstName', 'approver/middleName', 'approver/lastName']
            filters.userFullName.labelText = "Zoek op beoordelaar naam"
            filters.userEmail.key = 'approver/emailAddress'
            filters.userEmail.labelText = "Zoek op beoordelaar e-mail"

        } else if (newValue === projectRequestListingViews.PROJECTAPPROVER) {
            filters.userFullName.key = ['user/firstName', 'user/middleName', 'user/lastName']
            filters.userFullName.labelText = "Gemaakt door naam"
            filters.userEmail.key = 'user/emailAddress'
            filters.userEmail.labelText = "Gemaakt door e-mail"
        }
    })

    watch(
        () => route.value.query[tabQuery],
        async (newTab, oldTab) => {
            if (!oldTab) return
            initializeProjectRequestListingView(newTab)
            resetFilters()
            setData([])

            await refetchPojectRequests(pageSize.value)
        })

    return {
        isLoadingData: readonly(isLoadingData),
        isFetchingProjectRequests: readonly(isFetchingProjectRequests),
        take: readonly(take),
        sort: readonly(sort),
        totalCount: readonly(totalCount),
        pageSize: readonly(pageSize),
        pageIndex: readonly(pageIndex),
        isError: readonly(isError),
        noProjectRequests: readonly(noProjectRequests),
        filters,
        projectRequests: data,
        totalPages,
        tableHeaders,
        showTabs,
        projectRequestStatusOptions,
        projectRequestListingView,
        handleFilterSubmit,
        setPageSize,
        setSort,
        handlePaginationClick,
        handleFiltersReset,
        initializeProjectRequestListingView,
        getProjectRequestStatuses,
        getProjectRequests
    }
}
import get from 'lodash/get'
import { getOplzClient } from './apiClient'

const baseUrl = '/studycontract'

const getStudyContractDocument = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}/document`).then(response => {
        return get(response, 'data', null)
    })
}

const getStudyContracts = async dataQuery => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, dataQuery).then(response => {
        return get(response, 'data', null)
    })
}

const approve = async id => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/approve`).then(response => {
        return get(response, 'data', null)
    })
}

const reject = async (id, reason) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/reject`, reason).then(response => {
        return get(response, 'data', null)
    })
}

const uploadStudyContract = async (id, data) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/upload`, data ).then(response => {
        return response?.data
    })
}

export default {
    getStudyContractDocument,
    getStudyContracts,
    approve,
    reject,
    uploadStudyContract
}

<template>
  <div>
    <!-- Desktop -->
    <div class="c-footer-listing d-md-block d-none">
      <h4 class="c-footer-listing__title">{{ title }}</h4>
      <transition name="fade">
        <div
          v-if="facetValues && facetValues.length"
          :class="`u-column u-column-lg-${columnNumbers} c-footer-listing__list`"
        >
          <ul>
            <li
              v-for="(facetValue, index) in facetValues"
              :key="index"
            >
              <span
                class="c-footer-listing__list-item"
                data-test="footer-listing-list-item"
                @click="onFacetClick(facetValue)"
              >
                {{ facetValue }}
              </span>
            </li>
          </ul>
        </div>
      </transition>
      <p v-if="showNoItemsMessage && noItemMessage">{{ noItemMessage }}</p>
    </div>

    <!-- Mobile -->
    <div class="c-footer-listing d-md-none">
      <recess-accordion variant="variant-2">
        <template #accordion-title>
          <h4 class="c-footer-listing__title mb-0">{{ title }}</h4>
        </template>
        <template #accordion-content>
          <div
            v-if="facetValues && facetValues.length"
            :class="`u-column u-column-lg-${columnNumbers} c-footer-listing__list`"
          >
            <ul>
              <li
                v-for="(facetValue, index) in facetValues"
                :key="index"          
              >
                <span
                  class="c-footer-listing__list-item"
                  data-test="footer-listing-list-item"
                  @click="onFacetClick(facetValue)"
                >
                  {{ facetValue }}
                </span>
              </li>
            </ul>
          </div>
          <p v-if="showNoItemsMessage && noItemMessage">{{ noItemMessage }}</p>
        </template>
      </recess-accordion>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import facetFilterHelper from '../../../modules/facetFilterHelper'

export default {
    props: {
        title: {
            type: String,
            default: 'Vakgebieden'
        },
        facet: {
            type: Object,
            required: true,
            default: () => {}
        },
        noItemMessage: {
            type: String,
            default: null
        },
        columnNumbers: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            showNoItemsMessage: false
        }
    },
    computed: {
        facetValues() {
            return this.facet?.values
        }
    },
    watch: {
        facet: {
            handler(newVal) {
                if(!newVal || !newVal.values?.length) {
                    this.showNoItemsMessage = true
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions('searchModule', ['resetLocationRadiusFilters']),
        async onFacetClick(selectedFacetFilter) {
            const mappedSelectedFacetFilter = {
                key: this.facet.key,
                value: selectedFacetFilter,
                checked: true
            }
            this.resetLocationRadiusFilters()

            await this.$router.push({
                name: "search",
                query: {
                    ...this.$route.query,
                    facetFilters: facetFilterHelper.getFacetFiltersAsBase64(mappedSelectedFacetFilter)
                }
            })
        }
    }
}
</script>
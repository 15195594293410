import { getOplzClient } from './apiClient'

const baseUrl = '/email-templates'

const getToggleableEmailTemplates = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/toggleable`).then(response => {
        return response?.data
    })
}

const getDisabledEmailTemplates = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/disabled`).then(response => {
        return response?.data
    })
}

const toggleEmailTemplate = async data => {
    const client = await getOplzClient()
    return client.put(`${baseUrl}/toggle`, data).then(response => {
        return response?.data
    })
}

export default {
    getToggleableEmailTemplates,
    getDisabledEmailTemplates,
    toggleEmailTemplate
}

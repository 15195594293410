<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div v-if="course">
    <template v-if="course.diplomaTypeDisplayValue || course.diplomaType">
      <span class="font-weight-bold">Type certificering:</span>
      <p>{{ course.diplomaTypeDisplayValue || course.diplomaType }}</p>
    </template>

    <template v-if="course.croho">
      <span class="font-weight-bold">Croho code ontwikkelactiviteit:</span>
      <p>{{ course.croho }}</p>
    </template>

    <template v-if="course.crebo">
      <span class="font-weight-bold">Crebo code ontwikkelactiviteit:</span>
      <p>{{ course.crebo }}</p>
    </template>

    <template v-if="certificationContent">
      <div
        v-for="(content, index) in certificationContent"
        :key="`certificationContent${index}`"
        class="c-course-content__wrapper"
      >
        <h3 v-if="content.heading">{{ content.heading }}</h3>
        <div
          v-if="content.bodyHtml || content.body"
          class="c-course-content__block"
          v-html="content.bodyHtml || content.body"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('coursePageModule', ['course']),
        ...mapGetters('coursePageModule', ['certificationContent'])
    }
}
</script>

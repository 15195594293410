<template>
  <div class="c-social-learning__sorting">
    <recess-radio-buttons class="pr-3">
      <recess-radio-input
        class="mb-0"
        name="sortDirection"
        :value="odataSortingDirections.ASC"
        :default-checked="currentSorting.direction === odataSortingDirections.ASC"
        :label-text="getLabelText(odataSortingDirections.ASC)"
        variant="button"
        :disabled="!totalCount"  
        data-test="asc-input"
        @change="direction => setSortDirection(direction)"
      />
      <recess-radio-input
        class="mb-0"
        :value="odataSortingDirections.DESC"
        :default-checked="currentSorting.direction === odataSortingDirections.DESC"
        name="sortDirection"
        :label-text="getLabelText(odataSortingDirections.DESC)"
        variant="button"
        data-test="desc-input"
        :disabled="!totalCount"  
        @change="direction => setSortDirection(direction)"
      />
    </recess-radio-buttons>
    <recess-select 
      v-model="currentSorting.key" 
      :default-value="currentSorting.key" 
      :options="sortingOptions" 
      :disabled="!totalCount"  
      @input="key => setSorting(key)"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import odataSortingDirections from '../../../../api/OData/odataSortingDirections'
import usePagination from '../../../../composables/usePagination'

export default {
    setup() {
        const { sorting, totalCount } = usePagination()

        const currentSorting = ref({
            key: 'CreationDate',
            direction: odataSortingDirections.DESC
        })

        const sortingOptions = [
            {
                displayText: 'Creatiedatum',
                value: 'CreationDate',
                ascText: 'oud-nieuw',
                descText: 'nieuw-oud'
            },
            {
                displayText: 'Titel ',
                value:  'Title',
                ascText: 'a - z',
                descText: 'z - a'
            }
        ]
        
        const getLabelText = sortDirection => {
            const currentSortingOption = sortingOptions.find(x => x.value === currentSorting.value.key)

            return sortDirection === odataSortingDirections.ASC ? currentSortingOption?.ascText : currentSortingOption?.descText
        }

        const setSorting = key => {
            if (key) currentSorting.value.key = key
            sorting.value = `${currentSorting.value.key} ${currentSorting.value.direction}`
        }
        
        const setSortDirection = direction => {
            currentSorting.value.direction = direction
            setSorting()
        }

        return {
            sorting,
            sortingOptions,
            totalCount,
            currentSorting,
            odataSortingDirections,
            setSortDirection,
            setSorting,
            getLabelText
        }
    }
}
</script>

import ODataQuery from './odataQuery'
import BaseDataQueryBuilder from './baseQueryBuilder'

export default class DataQueryBuilder extends BaseDataQueryBuilder {
    constructor() {
        super(new ODataQuery())
    }

    setSort(fields, direction) {
        this.DataQuery.setSort(fields, direction)
        return this
    }

    setPagination(skip, take) {
        this.DataQuery.setPagination(skip, take)
        return this
    }

    setIncludes(includes) {
        this.DataQuery.setIncludes(includes)
        return this
    }

    setFields(fields) {
        this.DataQuery.setFields(fields)
        return this
    }
}

<template>
  <div v-if="isVisible || showEnrollmentFlow" :class="['u-position-relative', { 'u-min-height-50' : checkingIfUserHasAccess }]">
    <transition name="fade">
      <loader-component
        v-if="checkingIfUserHasAccess"
        overlay="white"
        color="primary"
      />
    </transition>  
    <template v-if="isVisible && !checkingIfUserHasAccess">
      <!-- If the provider of the course has an external link to enroll/subscribe, open that url in a new tab, programmatically -->
      <recess-button
        v-if="isSubscription(course.providerId) && course.oleUrl && !showEnrollmentFlow"
        :title="buttonType.displayValue"
        :variant="buttonVariant"
        :disabled="disabled"
        class="w-100"
        data-test="start-button"
        @click.native.prevent="triggerElearningSubscription"
      />

      <!-- Otherwise, just use the regular enrollment url -->
      <recess-button
        v-else
        :title="buttonType.displayValue"
        :url="getEnrollmentUrl(course)"
        :disabled="disabled"
        :variant="buttonVariant"
        class="w-100"
        data-test="enroll-button"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import eLearningSubscriptionClient from '../../../api/eLearningSubscriptionClient'
import { errorMessages } from '../../../constants/errorMessages'
import routePerimeter from '../../../perimeters/routePerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    components: {
        LoaderComponent
    },
    perimeters: [routePerimeter],
    props: {
        course: {
            type: Object,
            default() {
                return null
            }
        },
        isELearning: {
            type: Boolean,
            default: false
        },
        buttonVariant: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            buttonTypes: [
                {
                    key: 'enroll',
                    displayValue: 'Inschrijven'
                },
                {
                    key: 'subscribe',
                    displayValue: 'Starten'
                }
            ]
        }
    },
    computed: {
        ...mapState('coursePageModule', ['checkingIfUserHasAccess', 'userHasAccess']),
        ...mapGetters('accountModule', ['isAccountEnabled', 'noaCompanyLicense']),
        ...mapGetters('userModule', ['userAssortmentIds']),
        ...mapGetters('oleProvidersModule', ['isSubscription', 'isNoaProvider']),
        showEnrollmentFlow() {
            return !this.noaCompanyLicense && !this.course.showZeroPrice 
        },
        isVisible() {
            // If the account is disabled, the user can still access the application, but cannot enroll anymore
            if (!this.isAccountEnabled || !this.userHasAccess || this.course.isInUserAssortments === false) return false
            
            // if the button type is 'enroll' we we have to make sure there is at least one start moment or is an elearning, otherwise we don't show the button
            if (this.buttonType.key === 'enroll') {
                const hasStartMoments = !!this.course.startMoments && this.course.startMoments.length > 0
                if(!this.isELearning && !hasStartMoments) {
                    this.SET_CANNOT_ENROLL_MESSAGE(errorMessages.CourseNotValidStartMoments)
                }
                return hasStartMoments || this.isELearning
            }
            // any other button type should show based on if course is available on user assortment
            return true
        },
        buttonType() {
            return this.isSubscription(this.course.providerId) && !this.showEnrollmentFlow
                ? this.buttonTypes.find(x => x.key === 'subscribe')
                : this.buttonTypes.find(x => x.key === 'enroll')
        }
    },
    destroyed() {
        this.SET_CANNOT_ENROLL_MESSAGE(null)
    },
    methods: {
        ...mapMutations('coursePageModule', ['SET_CANNOT_ENROLL_MESSAGE']),
        getEnrollmentUrl(course) {
            return this.$isAllowed('routeAsCoordinator')
                ? { name: 'enrollment', params: { type: 'coordinator' }, query: { course: course.id, assortment: course.assortmentId } }
                : { name: 'enrollment', params: { type: 'student' }, query: { course: course.id, assortment: course.assortmentId } }
        },
        triggerElearningSubscription() {
            if (this.isSubscription(this.course.providerId)) {
                // First add this course to the active courses
                const request = {
                    courseId: this.course.id,
                    eduMSExternalCourseId: this.course.externalId,
                    providerName: this.course.providerName,
                    courseName: this.course.courseName
                }
                eLearningSubscriptionClient
                    .postELearningSubscription(request)
                    .catch(err => console.error(err))

                // Then redirect to subscription course page to let user enroll
                const newWindow = window.open()

                if (newWindow) {
                    // Set opener to null and noreferrer to avoid abusing of window.opener.
                    // We programatically set them instead of using inline noreferrer and noopener to support IE/Safari
                    newWindow.opener = null
                    newWindow.rel = 'noreferrer'
                    newWindow.target = '_blank'
                    newWindow.location = this.course.oleUrl
                }
            }
        }
    }
}
</script>
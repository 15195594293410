<template>
  <lightbox-component
    :is-visible="showAssignLearningPath"
    @close="$emit('close')"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <h3>Voeg cursus toe aan leerpad</h3>
    
    <!-- Tab navigation  -->
    <recess-tab-navigation
      v-if="$isAllowed('assignLearningPath')"
      name="assign-learning-path"
    >
      <recess-tab-navigation-item
        :tab-key="assignLearningPathCourseTabs.TEAM.key"
      >
        {{ assignLearningPathCourseTabs.TEAM.displayValue }}
      </recess-tab-navigation-item>

      <recess-tab-navigation-item
        :tab-key="assignLearningPathCourseTabs.PERSONAL.key"
      >
        {{ assignLearningPathCourseTabs.PERSONAL.displayValue }}
      </recess-tab-navigation-item>
    </recess-tab-navigation>
    
    <recess-card
      variant="variant1"
      class="my-4"
    >
      <div class="d-flex justify-content-end">
        <recess-button
          :title="createLearningPathText"
          variant="primary"
          @click="openCreateLearningPathModal()"
        />
      </div>
    </recess-card>
    
    <recess-alert
      v-if="showErrorMessage"
      type="error"
      :text="errorMessage"
    />
    <recess-table v-if="learningPaths && learningPaths.length">
      <recess-table-head>
        <recess-table-row>
          <recess-table-head-item
            v-for="(header,index) in headers"
            :key="index"
            :sortable="header.isSortable"
            :sort-direction="getSortDirection(header)"
            @click.native="setSorting(header)"
          >
            {{ header.title }}
          </recess-table-head-item>
        </recess-table-row>
      </recess-table-head>
      <recess-table-body>
        <recess-table-row
          v-for="learningPath in learningPaths"
          :key="learningPath.id"
        >
          <recess-table-cell :data-label="headers[0].title">
            <span class="font-weight-bold">{{ learningPath.title }}</span>
          </recess-table-cell>
          <recess-table-cell :data-label="headers[1].title">
            <span>{{ learningPath.utcDateCreated ? formatDate(learningPath.utcDateCreated) : "-" }}</span>
          </recess-table-cell>
          <recess-table-cell
            v-if="showCreatedByColumn"
            :data-label="headers[2].title"
          >
            <span>{{ learningPath.createdByFullName }}</span>
          </recess-table-cell>
          <recess-table-cell>
            <recess-button
              title="Voeg toe"
              variant="secondary"
              @click="postLearningPathCourse(learningPath)"
            />
          </recess-table-cell>
        </recess-table-row>
      </recess-table-body>
    </recess-table>
    <recess-alert
      v-if="(!learningPaths || !learningPaths.length) && !isFetchingLearningPaths"
      text="Er zijn op dit moment geen leerpaden."
    />
  </lightbox-component>
</template>

<script>
import { mapGetters } from 'vuex'
import LightboxComponent from '../../molecules/LightboxComponent/LightboxComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { searchLearningPathsBasicInfo, assignLearningPathCourse } from '../../../api/learningPathClient'
import { assignLearningPathCourseTabs } from '../../../constants/tabNames'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import DateUtils from '../../../utils/DateUtils'
import { COORDINATOR } from '../../../constants/roleIds'

export default {
    perimeters: [learningPathPerimeter],
    components: {
        LightboxComponent,
        LoaderComponent
    },
    props: {
        showAssignLearningPath: {
            type: Boolean,
            default: false
        },
        courseId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            learningPaths: null,
            isFetchingLearningPaths: false,
            isAssigningCourse: false,
            showErrorMessage: false,
            errorMessage: 'Er is een fout opgetreden. Probeer het later opnieuw.',
            sorting: {
                key: 'utcDateCreated',
                direction: ODataSortingDirections.DESC
            },
            assignLearningPathCourseTabs
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId', 'isCoordinator']),
        ...mapGetters('accountModule', ['accountId']),
        headers() {
            const headers = [
                { key: 'title', title: 'Naam', isSortable: true },
                { key: 'utcDateCreated', title: 'Aanmaakdatum', isSortable: true },
            
                {}
            ]

            if(this.showCreatedByColumn) headers.splice(2, 0, { key: 'createdByFullName', title: 'Gemaakt door'})

            return headers
        },
        isLoading() {
            return this.isFetchingLearningPaths || this.isAssigningCourse
        },
        showCreatedByColumn() {
            return this.isCoordinator && this.isTeamTab
        },
        isPersonalTab() {
            return this.$route?.query['assign-learning-path-tab'] === assignLearningPathCourseTabs.PERSONAL.key
        },
        isTeamTab() {
            return this.$route?.query['assign-learning-path-tab'] === assignLearningPathCourseTabs.TEAM.key
        },
        createLearningPathText() {
            if (this.$isAllowed('assignLearningPath') && this.isTeamTab)
                return 'Maak nieuw team leerpad'

            if (this.$isAllowed('assignLearningPath') && this.isPersonalTab)
                return 'Maak nieuw persoonlijke leerpad'

            return 'Maak nieuw leerpad'
        }
    },
    watch: {
        showAssignLearningPath(newVal) {
            let lpTabKey
            if (newVal) lpTabKey = this.$isAllowed('assignLearningPath') ? assignLearningPathCourseTabs.TEAM.key : assignLearningPathCourseTabs.PERSONAL.key
            
            this.$router.replace({ 
                query: { 
                    ...this.$route.query, 
                    'assign-learning-path-tab': lpTabKey
                },
                params: { ...this.$route.params } 
            })
        },
        $route(to, from) {
            if (this.showAssignLearningPath && to?.query['assign-learning-path-tab'] !== from?.query['assign-learning-path-tab']) {
                this.fetchLearningPaths()
            }
        }
    },
    methods: {
        async openCreateLearningPathModal() {
            await this.$router.replace({
                query: { ...this.$route.query, open: 'nieuw-leerpad', course: this.courseId },
                params: { isPersonal: !(this.$isAllowed('assignLearningPath') && this.isTeamTab ) }
            })
            this.$emit('close')
        },
        async postLearningPathCourse(learningPath) {
            try {
                this.showErrorMessage = false
                this.isAssigningCourse = true
                if (!this.courseId || !learningPath?.id) return

                await assignLearningPathCourse(learningPath.id, { courseId: this.courseId })

                this.$router.replace(  {
                    name: learningPath.isPersonal ? 'learning-path-details' : 'edit-learning-path',
                    params: {
                        id: learningPath.id,
                        scrollToBottom: true
                    }
                })
            } catch (error) {
                if (error.response?.data) {
                    this.errorMessage = error.response?.data
                } else {
                    this.errorMessage = 'Er is een fout opgetreden. Probeer het later opnieuw.'
                }
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isAssigningCourse = false
            }
        },
        buildFetchLearningPathDataQuery() {
            const dataQuery = new ODataQueryBuilder()
                .setSort(this.sorting.key, this.sorting.direction)
                .addFilter(new ODataQueryFilter( 'createdById', ODataFilterOperations.EQUALS, this.userId))

            if(this.$isAllowed('assignLearningPath') && this.isTeamTab) {
                dataQuery.addFilter(new ODataQueryFilter( 'isPersonal', ODataFilterOperations.EQUALS, false))

                if (this.isCoordinator) {
                    dataQuery
                        .or()
                        .addFilter(
                            new ODataQueryFilter(
                                'onlyManageableByTheCreator',
                                ODataFilterOperations.EQUALS,
                                false
                            )
                        )
                        .addFilter(
                            new ODataQueryFilter(
                                'createdBy/accountId',
                                ODataFilterOperations.EQUALS,
                                this.accountId
                            )
                        )
                        .addDynamicFilter(`createdBy/userRoles/any(r: r/role/roleId eq ${COORDINATOR})`)
                }
            }

            if(this.$isAllowed('assignLearningPath') && this.isPersonalTab) {
                dataQuery.addFilter(new ODataQueryFilter( 'isPersonal', ODataFilterOperations.EQUALS, true))
            }

            return dataQuery.build()
        },
        async fetchLearningPaths() {
            try {
                this.showErrorMessage = false
                this.isFetchingLearningPaths = true
                if (!this.userId) {
                    return
                }

                const dataQuery = this.buildFetchLearningPathDataQuery()

                this.learningPaths = await searchLearningPathsBasicInfo(dataQuery)
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isFetchingLearningPaths = false
            }
        },
        setSorting(header) {
            if (this.sorting.key === header.key) {
                this.sorting.direction =
                    this.sorting.direction === ODataSortingDirections.ASC
                        ? ODataSortingDirections.DESC
                        : ODataSortingDirections.ASC
            } else {
                this.sorting.direction = ODataSortingDirections.DESC
            }
            this.sorting.key = header.key
            this.fetchLearningPaths()
        },
        getSortDirection(header) {
            if (!header.key) return null

            if (this.sorting.key !== header.key) {
                return null
            }

            return this.sorting.direction ? this.sorting.direction.toLowerCase() : null
        },
        formatDate: e => DateUtils.formatDate(e)
    }
}
</script>



/**
 * get first match component, null if not found
 * 
 * @param {import('vue-router/types/router').VueRouter} router 
 */
function getMatchedComponent(router) {
    if (!router) {
        return null
    }
    const matchedComponents = router.getMatchedComponents()
    if (matchedComponents && matchedComponents.length) {
        return matchedComponents[0]
    }
    return null
}

/**
 * check if object has fetchPageDataAsync function
 * 
 * @param {any} comp 
 * @returns {comp is import('./types').FetchDataObj }}
 */
function isFetchData(comp) {
    return comp && typeof comp.fetchPageDataAsync === 'function'
}

/**
 * set router url and wait for async hooks to resolve
 * 
 * @param {import('vue-router/types/router').VueRouter} router 
 * @param {import('vuex').Store} store
 * @param {import('./types').AuthHandler} authHandler 
 * @returns { Promise<boolean> }
 */
export default function initRouter(router, store, authHandler) {
    return new Promise((res, rej) => {
        // handle authentication when required
        router.beforeEach((to, _, next) => {
            // Type checking to prevent error on logout
            if (typeof authHandler === 'function')
                authHandler(to, next)
            else 
                next()
        })

        router.onReady(
            () => {
                const comp = getMatchedComponent(router)
                if (!comp) { return rej() }

                // If component has a fetchData method,
                // we want to wait for that before continuing
                if (isFetchData(comp)) {
                    console.warn('Is this called call')
                    return comp.fetchPageDataAsync({
                        store,
                        route: router.currentRoute
                    })
                        .then(() => res(true))
                        .catch(err => rej(err))
                }
                
                return res(true)
            },
            err => rej(err)
        )
    })
}
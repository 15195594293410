<template>
  <div>
    <recess-layout-static>
      <recess-divider />
      <course-search-component />
      <recess-divider />
    </recess-layout-static>

    <create-learning-path-component />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CourseSearchComponent from '../../organisms/CourseSearchComponent/CourseSearchComponent.vue'
import CreateLearningPathComponent from '../../organisms/LearningPath/CreateLearningPathComponent.vue'
import routePerimeter from '../../../perimeters/routePerimeter'

export default {
    perimeters: [ routePerimeter ],
    components: { CourseSearchComponent, CreateLearningPathComponent },
    props: {
        searchFromTeamAssortments: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if(this.$isAllowed('routeAsTeamleader')) this.setSearchFromTeamAssortments(this.searchFromTeamAssortments)
        else this.setSearchFromTeamAssortments(false)
    },
    methods: {
        ...mapMutations("searchModule", ['setSearchFromTeamAssortments'])
    }
}
</script>

<template>
  <div>
    <recess-modal
      class="qa-delete-lp__first"
      modal-size="small"
      :show-modal="showConfirmationMessage"
      @close="$emit('close:first-confirmation')"
    >
      <div
        slot="modal-body"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <loader-component
          v-if="isDeleting"
          overlay="white"
          color="primary"
          is-fixed
        />
        <p>Weet u zeker dat u het leerpad wilt verwijderen?</p>
        <div>
          <recess-button
            variant="tertiary"
            title="Nee"
            @click="$emit('close:first-confirmation')"
          />
          <recess-button
            class="ml-2 qa-delete-lp__first-submit"
            variant="primary"
            title="Ja"
            @click="submitFirstConfirmationMessage()"
          />
        </div>
      </div>
    </recess-modal>
    <recess-modal
      class="qa-delete-lp__second"
      modal-size="small"
      :show-modal="showExtraConfirmationMessage"
      @close="closeExtraConfirmationMessage"
    >
      <div
        slot="modal-body"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <loader-component
          v-if="isDeleting"
          overlay="white"
          color="primary"
          is-fixed
        />
        <p>
          Pas op! Dit leerpad is op dit moment actief en toegewezen aan één of meerdere gebruikers. Als u het leerpad verwijdert verdwijnt deze voor alle gebruikers die het leerpad hebben gevolgd of deze aan het volgen zijn.
          Weet u zeker dat u dit leerpad wilt verwijderen?
        </p>
        <div>
          <recess-button
            variant="tertiary"
            title="Nee"
            @click="closeExtraConfirmationMessage"
          />
          <recess-button
            class="ml-2 qa-delete-lp__second-submit"
            variant="primary"
            title="Ja"
            @click="deleteLearningPath()"
          />
        </div>
      </div>
    </recess-modal>
  </div>
</template>

<script>
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { deleteLearningPath } from '../../../api/learningPathClient'
import { myTeamTabs } from '../../../constants/tabNames'

export default {
    components: {
        LoaderComponent
    },
    props: {
        showConfirmationMessage: {
            type: Boolean,
            default: false
        },
        learningPath: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            isDeleting: false,
            showExtraConfirmationMessage: false
        }
    },
    methods: {
        async submitFirstConfirmationMessage() {
            if (this.learningPath?.hasUsersAssigned && !this.learningPath?.isPersonal) {
                this.$emit('close:first-confirmation')
                this.showExtraConfirmationMessage = true
            } else {
                await this.deleteLearningPath()
            }
        },
        async deleteLearningPath() {
            try {
                this.isDeleting = true
                if (!this.learningPath?.id) return
                await deleteLearningPath(this.learningPath.id)

                const redirectUrl = this.learningPath.isPersonal ?
                    {
                        name: 'my-development', 
                        query: { 'my-development-tab': myTeamTabs.LEARNINGPATHS.key }
                    } :
                    {
                        name: 'my-team', 
                        query: { 'my-team-tab': myTeamTabs.LEARNINGPATHS.key }
                    }

                this.$router.replace(redirectUrl)
                this.showExtraConfirmationMessage = false
                this.$emit('deleted')
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isDeleting = false
            }
        },
        closeExtraConfirmationMessage() {
            this.showExtraConfirmationMessage = false
            this.$emit('close:first-confirmation')
        }
    }
}
</script>

import learningResultSharingTypes from "./learningResultSharingTypes"
import learningResultSharingOptions from "./learningResultSharingOptions"

export default {
    [learningResultSharingTypes.LEGAL.key]: {
        [learningResultSharingOptions.PASSEDEXAMCONSENT.key]: 'legalPassedExamConsent',
        [learningResultSharingOptions.DIPLOMACONSENT.key]: 'legalDiplomaConsent',
        [learningResultSharingOptions.COMPLETIONATRISKCONSENT.key]: 'legalCompletionAtRiskConsent',
        [learningResultSharingOptions.PROGRESSPERCENTAGECONSENT.key]: 'legalProgressPercentageConsent'
    },
    [learningResultSharingTypes.EMPLOYER.key]: {
        [learningResultSharingOptions.PASSEDEXAMCONSENT.key]: 'employerPassedExamConsent',
        [learningResultSharingOptions.DIPLOMACONSENT.key]: 'employerDiplomaConsent',
        [learningResultSharingOptions.COMPLETIONATRISKCONSENT.key] : 'employerCompletionAtRiskConsent',
        [learningResultSharingOptions.PROGRESSPERCENTAGECONSENT.key]: 'employerProgressPercentageConsent'
    },
    [learningResultSharingTypes.SELECTEDBYAPPROVER.key]: {
        [learningResultSharingOptions.PASSEDEXAMCONSENT.key]: 'passedExamConsent',
        [learningResultSharingOptions.DIPLOMACONSENT.key]: 'diplomaConsent',
        [learningResultSharingOptions.COMPLETIONATRISKCONSENT.key] : 'completionAtRiskConsent',
        [learningResultSharingOptions.PROGRESSPERCENTAGECONSENT.key]: 'progressPercentageConsent'
    }
}
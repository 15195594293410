export const TEAMLEADER = 'TEAMLEADER'
export const SECONDAPPROVER = 'SECONDAPPROVER'
export const COMPANYMANAGER = 'COMPANYMANAGER'
export const ACCOUNTMANAGER = 'ACCOUNTMANAGER'
export const LEARNANDDEVELOPEMENTMANAGER = 'LEARNANDDEVELOPEMENTMANAGER'
export const ACCOUNTADMIN = 'ACCOUNTADMIN'
export const COORDINATOR = 'COORDINATOR'
export const ACCOUNTREPORTER = 'ACCOUNTREPORTER'
export const STUDENT = 'STUDENT'
export const PROJECTINITIATOR = 'PROJECTINITIATOR'
export const PROJECTAPPROVER = 'PROJECTAPPROVER'

export default {
    TEAMLEADER,
    SECONDAPPROVER,
    COMPANYMANAGER,
    ACCOUNTMANAGER,
    LEARNANDDEVELOPEMENTMANAGER,
    ACCOUNTADMIN,
    COORDINATOR,
    STUDENT,
    PROJECTAPPROVER,
    PROJECTINITIATOR
}

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div
    v-if="show"
    data-test="autocomplete-dropdown"
    class="c-autocomplete"
  >
    <div
      v-if="showCloseButton"
      class="c-autocomplete__close"
      @click="$emit('close')"
    ></div>

    <div
      v-if="title"
      class="c-autocomplete__title"
    >
      {{ title }}
    </div>

    <ul class="c-autocomplete__list">
      <li
        v-for="(item, index) in limitedSuggestions"
        :key="index"
        class="c-autocomplete__item"
      >
        <span
          v-focus="index === currentFocusIndex"
          :class="{'focus': index === currentFocusIndex}"
          class="c-autocomplete__link"
          data-test="suggestion-item-text"
          tabindex="-1"
          @click="itemSelected(item)"
          @keydown.enter="itemSelected(item)"
          v-html="item.title"
        >
        </span>
      </li>
    </ul>

    <div
      v-if="buttonTitle"
      class="c-autocomplete__show-all"
      data-test="autocomplete-show-all"
      @click="$emit('close')"
    >
      <recess-button
        :title="buttonTitle"
        variant="primary"
        :url="`/Zoekresultaten.html?q=${searchQuery}`"
      />
    </div>
  </div>
</template>

<script>
import focusDirective from '../../../mixins/focus-directive'

export default {
    name: 'AutocompleteList',
    mixins: [focusDirective],
    props: {
        title: {
            type: String,
            default: ''
        },
        buttonTitle: {
            type: String,
            default: ''
        },
        suggestions: {
            type: Array,
            default: () => []
        },
        searchQuery: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        showCloseButton: {
            type: Boolean,
            default: false
        },
        showBorder: {
            type: Boolean,
            default: false
        },
        visibilityClasses: {
            type: String,
            default: ''
        },
        maxSuggestions: {
            type: Number,
            default: 5
        }
    },
    data() {
        return {
            currentFocusIndex: -1
        }
    },
    computed: {
        limitedSuggestions() {
            return this.suggestions.slice(0, this.maxSuggestions)
        }
    },
    watch: {
        show(newValue) {
            if (!newValue) {
                this.currentFocusIndex = -1
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.focusHandler)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.focusHandler)
    },
    methods: {
        focusHandler(e) {
            const upKeys = ['ArrowUp', 'Up']
            const downKeys = ['ArrowDown', 'Down']

            if (downKeys.includes(e.key) && this.show) {
                e.preventDefault()
                this.changeFocus(1)
            } else if (upKeys.includes(e.key) && this.show) {
                e.preventDefault()
                this.changeFocus(-1)
            }
        },
        changeFocus(value) {
            let newIndex = this.currentFocusIndex + value

            if (newIndex === this.limitedSuggestions.length) {
                newIndex = 0
            }
            if (newIndex < 0) {
                newIndex = this.limitedSuggestions.length - 1
            }

            this.currentFocusIndex = newIndex
        },
        itemSelected(item) {
            this.$emit('itemSelected', item)
        }
    }
}
</script>
<template>
  <div class="u-position-relative">
    <recess-divider />
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetchingUsers"
        overlay="white"
        color="primary"
      />
    </transition>

    <p
      v-if="!isEnrollmentFlow"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      Dit is een overzicht van alle medewerkers die binnen uw team vallen.
    </p>
    <recess-alert
      v-if="isUserFilterDisabled"
      class="mt-5"
      data-test="error-txt"
      type="error"
      text="Er moet minimaal één afdeling zijn ingesteld waaraan u leiding geeft om teamleden te kunnen zien/selecteren."
    />
    <table-filters-component
      :show-reset-filters="isSelectedStudentsFiltersFilled"
      :is-disabled="isUserFilterDisabled"
      toggle-button-text="Filter medewerkers"
      @reset-filters="removeSelectedStudentsFilters"
    >
      <recess-select
        slot="right"
        :options="selectedStudentsPagination.pageSizeFields"
        :default-value="selectedStudentsPagination.pageSize"
        data-test="pagination-input"
        class="c-enrollment-step__toolbar-select--page-size u-position-right"
        @input="value => setSelectedStudentsPageSize(value)"
      />
      <template #filters>
        <recess-input
          v-for="filter in selectedStudentsFilters"
          :key="filter.id"
          :label-text="filter.labelText"
          :value="filter.value"
          :data-test="`student-filter-by-${filter.id}`"
          class="mb-3"
          @keyup.enter.native="getUsersFromFilter"
          @input="value => setSelectedStudentsFilter({value: value, id: filter.id})"
        />

        <recess-multi-select
          v-model="peStatusFilter"
          :selected-options="selectedPEStatusFilter"
          :options="peStatusOptions"
          select-option-text="Maak een keuze"
          data-test="student-filter-by-pe-status"
          label-text="Zoek op PE status"
        />
      </template>
      <template #footer>
        <recess-button
          title="Filter"
          variant="primary"
          data-test="filter-btn"
          class="col-12 col-sm-auto"
          @click="getUsersFromFilter"
        />
      </template>
    </table-filters-component>

    <h5
      v-if="isEnrollmentFlow"
      class="mt-4 mb-3 font-weight-normal"
    >
      {{ selectedStudents.length }} Geselecteerd
    </h5>

    <recess-divider v-if="!isEnrollmentFlow" />

    <template v-if="users && users.length">
      <recess-table>
        <recess-table-head>
          <recess-table-row>
            <recess-table-head-item
              v-for="headerItem in tableHeader"
              :key="headerItem.key"
            >
              {{ headerItem.displayTitle }}
            </recess-table-head-item>
          </recess-table-row>
        </recess-table-head>
        <recess-table-body data-test="student-list-table">
          <recess-table-row
            v-for="user in users"
            :key="user.userId"
          >
            <recess-table-cell
              :data-label="tableHeader[0].displayTitle"
            >
              <div class="d-flex flex-column">
                <span> {{ user.fullName || '-' }}</span>
                <span class="u-textcolor-grey-dark">{{ user.emailAddress }}</span>
              </div>
            </recess-table-cell>

            <recess-table-cell
              :data-label="tableHeader[1].displayTitle"
            >
              {{ (user.company && user.company.name) || '-' }}
            </recess-table-cell>

            <recess-table-cell
              :data-label="tableHeader[2].displayTitle"
            >
              <div :class="['d-flex align-items-center', {'justify-content-end': isMobile}]">
                <span 
                  :class="['c-pe-status', getBackgroundColorClass(user.peStatus)]"
                  data-test="pe-status-color"
                ></span>
                <span 
                  class="pl-2"
                  data-test="pe-status-text"
                >
                  {{ displayPEStatusTitle(user.peStatus) || '-' }}
                </span>
              </div>
            </recess-table-cell>

            <recess-table-cell
              v-if="$isAllowed('displayCostCenter')"
              :data-label="tableHeader[3].displayTitle"
            >
              {{ user.costCenter || '-' }}
            </recess-table-cell>

            <recess-table-cell v-if="isAbleToSelectUsers">
              <recess-checkbox-input
                data-test="select-student-input"
                :default-checked="isInSelectedStudents(user.userId)"
                label-text="Toevoegen"
                @input="checked => checkboxHandler(checked, user)"
              />
            </recess-table-cell>
          </recess-table-row>
        </recess-table-body>
      </recess-table>

      <recess-divider />
   
      <pagination-component
        :current-page-number="selectedStudentsPagination.currentPage"
        :total-pages="selectedStudentsPagination.totalPages"
        @goToPage="(pageIndex) => setSelectedStudentsCurrentPage(pageIndex)"
      />
    </template>

    <recess-alert
      v-if="selectedStudentsPagination.totalCount == 0 && !isFetchingUsers"
      text="Er zijn geen studenten beschikbaar."
    />

    <recess-card
      v-if="showSelectedUserActionBar"
      color-variant="secondary"
      data-test="selected-students-bar"
      class="c-student-list__selected d-flex flex-column flex-sm-row justify-content-between align-items-sm-center"
    >
      <h4
        data-test="selected-students-counter"
        class="mb-0 u-textcolor-white"
      >
        {{ selectedStudents.length }} Geselecteerd
      </h4>

      <div class="pt-4 pt-sm-0">
        <recess-button
          class="mr-3"
          variant="primary"
          title="Annuleren"
          @click="removeStudents"
        />

        <recess-button
          class="mr-3"
          variant="primary"
          title="Inschrijven"
          data-test="start-enrollment-button"
          @click="goToTeamLeaderEnrollment"
        />
      </div>
    </recess-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { jsMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import TableFiltersComponent from './TableFiltersComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import * as enrollmentTypes from '../../../constants/enrollmentTypes'
import * as enrollmentSteps from '../../../constants/enrollmentSteps'
import * as peStatusUtils from '../../../utils/PEStatusUtils'

export default {
    components: { PaginationComponent, TableFiltersComponent, LoaderComponent },
    mixins: [jsMediaQueriesMixin],
    perimeters: [featuresPerimeter, routePerimeter],
    data() {
        return {
            peStatusOptions: peStatusUtils.getStatusOptions()
        }    
    },
    computed: {
        ...mapState('enrollmentModule', [
            'enrollmentType',
            'users',
            'selectedStudentsPagination',
            'isFetchingUsers',
            'isUserFilterDisabled',
            'selectedPEStatusFilter'
        ]),
        ...mapGetters('enrollmentModule', ['selectedStudents','selectedStudentsFilters', 'totalSteps']),
        isSelectedStudentsFiltersFilled() {
            return (
                this.selectedStudentsFilters.filter(ssf => {
                    return !!ssf.value
                }).length > 0 || !!this.selectedPEStatusFilter
            )
        },
        peStatusFilter: {
            get() {
                return this.selectedPEStatusFilter
            },
            set(value) {
                this.setSelectedPEStatusFilter(value)
            }
        },
        tableHeader() {
            const tableHeader = [
                {
                    key: 'fullNameAndEmailAddress',
                    displayTitle: 'Medewerker'
                },
                {
                    key: 'companyName',
                    displayTitle: 'Afdeling'
                },
                {
                    key: 'peStatus',
                    displayTitle: 'Leerresultaten'
                }
            ]

            if(this.$isAllowed('displayCostCenter')) tableHeader.push({
                key: 'costCenter',
                displayTitle: 'Kostenplaats'
            })
            
            // Push empty object for the checkboxes column
            if(this.isAbleToSelectUsers) tableHeader.push({})
           
            return tableHeader
        },
        isEnrollmentFlow() {
            return !!this.$route.params.type
        },
        isAbleToSelectUsers() {
            return this.isEnrollmentFlow || this.$isAllowed('routeAsTeamleader')
        },
        showSelectedUserActionBar() {
            return !this.isEnrollmentFlow && this.selectedStudents?.length
        }
    },
    created() {
        if(!this.isEnrollmentFlow) {
            if(this.$isAllowed('routeAsTeamleader')) this.setEnrollmentType(enrollmentTypes.TEAMLEADER)
            if(this.$isAllowed('routeAsCoordinator')) this.setEnrollmentType(enrollmentTypes.COORDINATOR)
            // Set first step
            this.setCurrentStepCode(this.totalSteps?.[0]?.code)
        }

        this.getUsers()
    },
    beforeDestroy() {
        if(!this.isEnrollmentFlow)
            this.resetState()
    },
    methods: {
        ...mapMutations('enrollmentModule', [
            'addStudent',
            'removeStudent',
            'setSelectedStudentsFilter',
            'resetState',
            'removeStudents',
            'setCurrentStepCode',
            'setSelectedPEStatusFilter',
            'setEnrollmentType'
        ]),
        ...mapActions('enrollmentModule', [
            'getUsers',
            'removeSelectedStudentsFilters',
            'setSelectedStudentsPageSize',
            'setSelectedStudentsCurrentPage'
        ]),
        checkboxHandler(checked, user) {
            return checked ? this.addStudent(user) : this.removeStudent(user.userId)
        },
        isInSelectedStudents(userId) {
            return this.selectedStudents.filter(student => student.userId === userId).length > 0
        },
        getUsersFromFilter() {
            // already triggers a fetch
            this.setSelectedStudentsCurrentPage(1)
        },
        async goToTeamLeaderEnrollment() {
            this.setCurrentStepCode(enrollmentSteps.SELECTCOURSE.code)
            await this.$router.replace({ name: 'enrollment', params: { type: enrollmentTypes.TEAMLEADER }})
        },
        displayPEStatusTitle(permanentEducationStatus) {
            return peStatusUtils.getStatusName(permanentEducationStatus)
        },
        getBackgroundColorClass(permanentEducationStatus) {
            return peStatusUtils.getColorClass(permanentEducationStatus)
        }
    }
}
</script>
export const cssMediaQueriesMixin = {
    data() {
        return {
            mediaQueries: {
                xSmall: '(max-width: 575px)',
                small: '(max-width: 767px)',
                medium: '(max-width: 991px)',
                large: '(max-width: 1199px)',
                xLarge: '(min-width: 1392px)'
            }
        }
    }
}

export const jsMediaQueries = {
    xSmall: 0,
    small: 576,
    medium: 768,
    large: 992,
    xLarge: 1200,
    xxLarge: 1392
}

export const jsMediaQueriesMixin = {
    data() { return { jsMediaQueries } },
    computed: {
        isMobile() {
            const windowWidth = window ? window.innerWidth : 0
            return windowWidth <= this.jsMediaQueries.large
        }
    }
}

export default {
    cssMediaQueriesMixin,
    jsMediaQueriesMixin
}

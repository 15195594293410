<template>
  <div
    v-if="$isAllowed('displayBudgets') && budgets && budgets.length"
    class="c-my-budgets"
  >
    <recess-divider />
    
    <!-- Title -->
    <h4>Mijn budgetten</h4>

    <!-- Carousel with Budgets -->
    <div>
      <!-- Loader -->
      <transition name="fade">
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>

      <hooper
        ref="hooper"
        :settings="hooperSettings"
        :height="250"
      >
        <slide
          v-for="(budget, index) in budgets"
          :key="index"
          :index="index"
        >
          <div
            data-test="budget-slider"
            class="u-cursor-pointer"
            @click="$router.push({ name: 'my-budgets' })"
          >
            <vc-donut
              foreground="#D7E9E7"
              :size="180"
              unit="px"
              :thickness="15"
              class="c-my-budgets__donut"
              :sections="[{ value: getBudgetPerc(budget) }]"
              :auto-adjust-text-size="true"
            >
              <h3 class="c-my-budgets__amount mb-md-2">{{ formatPriceShort(budget.currentAmount) }}</h3>
              <h5 class="c-my-budgets__percentage mb-0">{{ getBudgetPerc(budget) }}%</h5>
            </vc-donut>
            <span class="d-flex justify-content-center my-2 u-textcolor-blue-grey text-center">{{ budget.name }}</span>
          </div>
        </slide>
        <hooper-navigation
          v-if="showNavigations(budgets)"
          slot="hooper-addons"
        >
          <template #hooper-prev>
            <recess-icon
              icon="arrow-left"
              color="secondary"
              :height="25"
              :width="25"
            />
          </template>
          <template #hooper-next>
            <recess-icon
              icon="arrow-right"
              color="secondary"
              :height="25"
              :width="25"
            />
          </template>
        </hooper-navigation>
      </hooper>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import { searchBudgets } from '../../../api/budgetClient'
import priceFormatter from '../../../modules/priceFormatter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import DateUtils from '../../../utils/DateUtils'
import { hooperSettings } from '../../../constants/carouselSettings'
import carouselHelper from '../../../modules/carouselHelper'

export default {
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent,
        Hooper,
        Slide,
        HooperNavigation
    },
    data() {
        return {
            isLoading: false,
            budgets: [],
            hooperSettings
        }
    },
    computed: {
        ...mapState('userModule', ['user', 'isUserFetched'])
    },
    watch: {
        isUserFetched(newValue) {
            if (newValue && !this.budgets.length) {
                this.fetchBudgets()
            }
        }
    },
    created() {
        this.fetchBudgets()
    },
    methods: {
        getBudgetPerc(budget) {
            const perc = budget.currentAmountPercentage
            if (!Number.isFinite(perc)) {
                return 0
            }
            return perc
        },
        async fetchBudgets() {
            try {
                this.isLoading = true
                if (!this.user?.userId) {
                    return
                }

                const currentDate = DateUtils.now.toISOString()
                const oDataQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .setSort('endDate', 'asc')
                    .addFilter(
                        new ODataQueryFilter(
                            'userId',
                            ODataFilterOperations.EQUALS,
                            this.user.userId
                        )
                    )
                    .addFilter(
                        new ODataQueryFilter(
                            'isActive',
                            ODataFilterOperations.EQUALS,
                            true
                        )
                    )
                    .addDynamicFilter(`(endDate gt ${currentDate} or endDate eq null)`)
                    .addDynamicFilter(`(startDate lt ${currentDate} or startDate eq null)`)
                    .build()

                const response = await searchBudgets(oDataQuery)
               
                this.budgets = response?.items?.map(budget => {
                    return { 
                        ...budget, 
                        currentAmountPercentage: budget?.currentAmount > budget?.startAmount 
                            ? 100 
                            : Math.round((budget?.currentAmount / budget?.startAmount) * 100) 
                    }
                })
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isLoading = false
            }
        },
        formatPriceShort: priceFormatter.formatPriceShort,
        showNavigations: carouselHelper.showNavigations
    }
}
</script>
<template>
  <recess-modal
    :show-modal="showSavePersonalDataModal"
    @close="$emit('close')"
  >
    <template slot="modal-title">
      <h3>U heeft uw persoonlijke gegevens gewijzigd</h3>
    </template>
    <template slot="modal-body">
      <p class="mb-3">
        Het lijkt er op dat u uw persoonlijke gegevens hebt gewijzigd. Wilt u dit ook opslaan in uw profiel? 
        Bij uw volgende inschrijving zullen wij deze waardes automatisch voor u invullen.
      </p>
      <p class="mb-2 font-italic u-text-small">
        Let op: Een wijziging van uw e-mailadres slaan wij niet op in uw profiel omdat het e-mailadres ook uw gebruikersnaam is. 
        Wel zullen alle e-mails omtrent deze inschrijving verzonden worden naar dit e-mailadres.
      </p>
    </template>
    <div
      slot="modal-footer"
    >
      <recess-button
        class="u-position-right col-12 col-md-auto mb-3 mb-md-0 ml-3"
        title="Persoonlijke gegevens opslaan"
        variant="primary"
        data-test="personal-data-save"
        @click.native="$emit('submit', { willUpdateUserData: true })"
      />
      <recess-button
        class="u-position-right col-12 col-md-auto"
        title="Verder zonder opslaan"
        variant="secondary"
        data-test="personal-data-dont-save"
        @click.native="$emit('submit', { willUpdateUserData: false })"
      />
    </div>
  </recess-modal>
</template>

<script>
export default {
    props: {
        showSavePersonalDataModal: {
            default: false,
            type: Boolean
        }
    }
}
</script>
<template>
  <recess-layout-static
    v-if="$isAllowed('checkDelegatedActions')"
    :size="1700"
  >
    <recess-divider />
    <h1>Toegewezen acties</h1>

    <my-delegated-enrollments-table />

    <recess-divider />
  </recess-layout-static>
</template>

<script>
import MyDelegatedEnrollmentsTable from '../../organisms/Tables/MyDelegatedEnrollmentsTable.vue'
import delegatePerimeter from '../../../perimeters/DelegatePerimeter'

export default {
    name: 'MyTeam',
    components: {
        MyDelegatedEnrollmentsTable
    },
    perimeters: [delegatePerimeter]
}
</script>


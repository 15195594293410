import axios from 'axios'
import { getGeoLocationClient } from './apiClient'

const baseRoute = '/city'

const FetchCityListCancelToken = axios.CancelToken
let fetchCityListCancel

const search = async (cityName, radius)  => {
    const client = await getGeoLocationClient()
    const response = await client.get(`${baseRoute}/filtered`, 
        { 
            params: { 
                radius,
                cityName,
                country: 'NL',
                limit: 200
            } 
        }
    )

    return response?.data
}

const getCityList = async cityName  => {
    const client = await getGeoLocationClient()
    const response = await client.get(`${baseRoute}/list`, 
        { 
            params: { 
                cityName,
                country: 'NL',
                top: 10
            },
            cancelToken: new FetchCityListCancelToken(c => {
                // An executor function receives a cancel function as a parameter
                fetchCityListCancel = c
            }) 
        }
    )

    return response?.data
}

const cancelFetchCityList = () => {
    if (fetchCityListCancel !== undefined) {
        fetchCityListCancel()
    }
}

export default {
    search,
    getCityList,
    cancelFetchCityList
}

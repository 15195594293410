import qs from 'qs'
import { getOplzClient } from './apiClient'

const baseUrl = '/enrollments'

const getEnrollment = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}`).then(response => {
        return response?.data
    })
}

const getEnrollments = async dataQuery => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, dataQuery).then(response => {
        return response?.data
    })
}

const postEnrollment = async enrollment => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}`, enrollment).then(response => {
        return response?.data
    })
}

const postUnverifiedEnrollments = async unverifiedEnrollments => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/unverified`, unverifiedEnrollments).then(response => {
        return response?.data
    })
}

const verifyEnrollment = async (data, id) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/verify`, data).then(response => {
        return response?.data
    })
}

const getEnrollmentDeclineReasons = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/decline-reasons`).then(response => {
        return response?.data
    })
}

const getEnrollmentEnrollReasons = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/enroll-reasons`).then(response => response?.data)
}

const declineEnrollmentVerification = async (data, id) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/decline`, data).then(response => {
        return response?.data
    })
}

const getActiveCourses = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/active`).then(response => {
        return response?.data
    })
}

const getMyTeamActiveCourses = async (dataQuery) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/my-team/active`, dataQuery).then(response => {
        return response?.data
    })
}

const getEnrollmentRejectionReasons = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}/rejection-reasons`).then(response => {
        return response?.data
    })
}

const getCancellationReasons = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/cancellation-reasons`).then(response => {
        return response?.data
    })
}

const approveEnrollment = async request => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${request.enrollmentId}/approve`, request).then(response => {
        return response?.data
    })
}

const secondApproveEnrollment = async id => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/second-approve`).then(response => {
        return response?.data
    })
}

const rejectEnrollment = async request => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${request.enrollmentId}/reject`, request).then(response => {
        return response?.data
    })
}

const cancelEnrollment = async request => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${request.enrollmentId}/cancel`, request).then(response => {
        return response?.data
    })
}

const updateStartmoments = async (id, request) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/start-moments`, request).then(response => {
        return response?.data
    })
}

const getAlternativeBillingAddress = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}/alternative-billing-address`).then(response => {
        return response?.data
    })
}

const getEnrollmentStatuses = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/statuses`).then(response => {
        return response?.data
    })
}

const toggleCompletion = async id => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/toggle-completion`).then(response => {
        return response?.data
    })
}

// TODO: Refactor this to use an object instead of a list of parameters
const checkApprovalRequired = async (
    courseId,
    assortmentId, 
    selectedStartmoments = null, 
    enrollmentId = null,
    userIds = null,
    useTeamLeaderAssortments = false,
    isCourseLinkedToProject = null
) => {    
    const client = await getOplzClient() 
    const url = `${baseUrl}/checkApproval/${courseId}`

    return client.get(url, {
        params: {
            ...selectedStartmoments && { selectedStartmoments },
            ...userIds && { userIds },
            ...useTeamLeaderAssortments && { useTeamLeaderAssortments },
            ...assortmentId && { assortmentId },
            ...enrollmentId && { enrollmentId },
            ...isCourseLinkedToProject && { isCourseLinkedToProject }
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat'})
        }
    }).then(response => {
        return response?.data
    })
}

const updateLearningResultSharingConsents = async (id, jsonPatchDocument) => {
    const client = await getOplzClient()
    return client.patch(`${baseUrl}/${id}/update-consents`, jsonPatchDocument).then(response => response?.data)
}

export default {
    getEnrollment,
    getEnrollments,
    postEnrollment,
    postUnverifiedEnrollments,
    verifyEnrollment,
    getEnrollmentDeclineReasons,
    declineEnrollmentVerification,
    getActiveCourses,
    getEnrollmentRejectionReasons,
    getCancellationReasons,
    approveEnrollment,
    secondApproveEnrollment,
    rejectEnrollment,
    cancelEnrollment,
    updateStartmoments,
    getEnrollmentStatuses,
    checkApprovalRequired,
    getEnrollmentEnrollReasons,
    getAlternativeBillingAddress,
    toggleCompletion,
    updateLearningResultSharingConsents,
    getMyTeamActiveCourses
}

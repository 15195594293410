<template>
  <section class="c-home-ai-wizard">
    <header class="d-flex align-items-center">
      <h4>Persoonlijke ontwikkelsuggesties</h4>
      <strong class="c-home-ai-wizard__beta mb-2 ml-3">BETA</strong>
    </header>

    <recess-card variant="variant1" class="d-flex align-items-center justify-content-between">
      <recess-button variant="secondary" title="Start AI hulpwizard" @click="showAiEducationAdvisorForm = true" />
      <img src="../../../assets/images/icons/ai.png" alt="ai icon" class="c-home-ai-wizard__icon" />
    </recess-card>
   
    <ai-education-advisor-form :is-visible="showAiEducationAdvisorForm" @close="showAiEducationAdvisorForm = false" />
  </section>
</template>

<script>
import { ref } from 'vue' 
import AiEducationAdvisorForm from '../../molecules/AiEducationAdvisorForm/AiEducationAdvisorForm.vue'

export default {
    components: {
        AiEducationAdvisorForm
    },
    setup() {
        const showAiEducationAdvisorForm = ref(false)

        return {
            showAiEducationAdvisorForm
        }
    }
}
</script>
<template>
  <recess-layout-static v-if="$isAllowed('displaySocialLearning')">
    <transition name="fade">
      <loader-component
        v-if="isFetchingPost || isUpdatingPost"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>
    <recess-divider />
    <recess-button
      title="Terug"
      icon="back"
      variant="variant4"
      :url="backUrl"
    />
    <recess-divider />
    <h1 data-test="post-edit-title">Bijdrage wijzigen</h1>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="mb-4">Op deze pagina kunt u uw bijdrage (opnieuw) wijzigen.</p>
        <create-edit-form 
          v-if="post" 
          :initial-post="post"
          :back-url="{name: 'post-details', params: {id: post.id}}"
          submit-btn-text="Opslaan"
          @is-uploading="val => isUpdatingPost = val"
          @submit="updatePost"
          @cancel="cancelEdit"
        />

        <recess-alert
          v-if="showNoPostFound"
          data-test="no-post-found-alert"
          text="Dit bericht kan niet gevonden worden. Probeer het later opnieuw."
        />
      </div>
      <check-list class="col-4 d-none d-md-block" :check-list-items="postHints" :title="postHintsTitle"/>
    </div>
    <recess-divider variant="large" />
    
    <recess-alert v-if="showErrorMessage" class="mb-5" type="error" text="Er is een fout opgetreden. Probeer het later opnieuw." />

    <message-modal 
      v-if="showSuccessMessage"
      message="Uw aanpassing is succesvol verwerkt en zal over enkele momenten zichtbaar zijn." 
      is-visible
    >
      <template #buttons>
        <recess-button
          variant="tertiary"
          title="Terug"
          data-test="to-detail-page"
          @click="closeAndRedirectDetailPage()"
        />
        <recess-button
          class="ml-2"
          variant="primary"
          title="Naar de zoekpagina"
          data-test="to-search-page"
          @click="closeAndRedirectSearchPage()"
        />
      </template>
    </message-modal>
  </recess-layout-static>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CreateEditForm from '../../organisms/SocialLearning/Post/CreateEditForm.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import socialLearningClient from '../../../api/socialLearningClient'
import CheckList from '../../atoms/CheckListComponent/CheckList.vue'
import { postHintsTitle, postHints } from '../../../constants/postHints'
import MessageModal from '../../atoms/MessageModal/MessageModal.vue'
import postSuccessMessageMixin from '../../../mixins/post-success-message-mixin'

export default {
    name: 'PostEdit',
    perimeters: [routePerimeter, featuresPerimeter],
    components: {
        CreateEditForm,
        LoaderComponent,
        CheckList,
        MessageModal
    },    
    mixins: [ postSuccessMessageMixin ],
    beforeRouteLeave(to, from, next) {
        if(this.showBeforeRouteLeaveMessage) {
            const answer = window.confirm(
                'Weet u zeker dat u deze pagina wilt verlaten? U hebt mogelijk niet-opgeslagen wijzigingen.'
            )

            return answer ? next() : next(false)
        }
        return next()
    },
    data() {
        return {
            showErrorMessage: false,
            showNoPostFound: false,
            postHints,
            postHintsTitle,
            showNoPostMessage: false,
            isUpdatingPost: false,
            isPageLeaveConfirmed: false,
            isUpdated: false
        }
    },
    computed: {
        ...mapGetters('socialLearningPostModule', ['post', 'isFetchingPost']),
        showBeforeRouteLeaveMessage() {
            return !this.isUpdated && this.$refs.editForm?.isDirty

        },
        backUrl() {
            return { name: 'post-details', params: { id: this.postId }}
        },
        postId() {
            return this.$route.params?.id
        }
    },
    created() {
        this.initialize()        
    },
    beforeDestroy() {
        if(window) {
            window.removeEventListener('beforeunload', this.beforeWindowUnload)
        }
    },
    methods: {
        ...mapActions('socialLearningPostModule', ['fetchPost']),
        async initialize() {
            if (!this.post) {
                if (!this.postId) return
                try {
                    await this.fetchPost(this.postId)
                  
                    if (!this.post) this.showNoPostFound = true
                } catch (error) {
                    this.showNoPostFound = true
                    throw new Error(error)
                } 
            }
        },
        async updatePost(postDetails) {
            try {
                this.showErrorMessage = false
                const patchArray = []
                
                if (postDetails.content !== this.post.content) {
                    patchArray.push({
                        op: 'replace',
                        path: `/content`,
                        value: postDetails.content
                    })
                }
                if (postDetails.title !== this.post.title) {
                    patchArray.push({
                        op: 'replace',
                        path: `/title`,
                        value: postDetails.title
                    })
                }
                if (postDetails.attachments !== this.post.attachments) {
                    const originalAttachments = this.post.attachments
                    const deletedAttachments = originalAttachments.filter(oa => !postDetails.attachments.includes(oa))
                    
                    // Repeated patch remove using array index changes the position of array elements (shifts left for every remove operation). 
                    // So sending the array index in reverse order to avoid wrong items being removed due to the previous remove operation.
                    deletedAttachments.reverse().forEach(attachment => {
                        patchArray.push({
                            op: 'remove',
                            path: `/attachments/${originalAttachments.indexOf(attachment)}`,
                            value: attachment
                        })
                    })

                    const newAttachments = postDetails.attachments?.filter(pda => !deletedAttachments?.includes(pda) && !this.post.attachments?.includes(pda))
                    newAttachments.forEach(attachment => {                        
                        patchArray.push({
                            op: 'add',
                            path: `/attachments/-`,
                            value: attachment
                        })
                    })
                }

                if (postDetails.tags !== this.post.tags) {
                    const originalTags = this.post.tags
                    const deletedTags = originalTags.filter(ot => !postDetails.tags.some(pdt => (ot.value === pdt.value) && (ot.type === pdt.type)))

                    // Repeated patch remove using array index changes the position of array elements (shifts left for every remove operation). 
                    // So sending the array index in reverse order to avoid wrong items being removed due to the previous remove operation.
                    deletedTags.reverse().forEach(tag => {
                        patchArray.push({
                            op: 'remove',
                            path: `/tags/${originalTags.indexOf(tag)}`,
                            value: tag
                        })
                    })

                    const newTags = postDetails.tags?.filter(pdt => !deletedTags?.some(dt => 
                        dt.value === pdt.value && dt.displayValue === pdt.displayValue && dt.type === pdt.type) && !originalTags.some(ot => ot.value === pdt.value && ot.displayValue === pdt.displayValue && ot.type === pdt.type))

                    newTags.forEach(tag => {                        
                        patchArray.push({
                            op: 'add',
                            path: `/tags/-`,
                            value: tag
                        })
                    })                   
                }
                
                if (patchArray.length) {
                    this.isUpdatingPost = true
                    await socialLearningClient.updatePost(patchArray, this.post.id)
                }   
                
                this.showSuccessMessage = true
                this.isUpdated = true
            } catch (error) {
                this.showErrorMessage = true
                throw new Error("Failed to update post", error)             
            } finally {
                this.isUpdatingPost = false
            }
        },
        cancelEdit() {
            this.$router.push(this.backUrl)
        }
    }
}
</script>
export const powerBiServiceStatus = {
    failed: 'failed',
    succeeded: 'succeeded'
}

export const powerBiRenderStatus = {
    unloaded: 'unloaded',
    refreshing: 'refreshing',
    loading: 'loading',
    ready: 'ready',
    error: 'error'
}
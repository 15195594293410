import { helpers } from 'vuelidate/lib/validators'
import DateUtils from '../utils/DateUtils'

// Validation to check if date has DD-MM-YYYY format
export const isValidDate = helpers.regex(
    'date',
    /^(0[1-9]|1\d|2\d|3[01])[-](0[1-9]|1[012])[-]((?:19|20)\d{2})\s*$/
)

// Validation to check if date < than current date and > 1900
export function isPastDate(value) {
    if (!value) return true
    const date = DateUtils.parseFormatedString(value)
    return DateUtils.isLater(DateUtils.now, date)
        && date.getFullYear() > 1900
}

// Validation to check if user has more than 12 years and not older than 90
export function isValidBirthdayDate(value) {
    if (!value) return true

    const date = DateUtils.parseFormatedString(value)
    const age = DateUtils.getAge(date)
    return age > 12 && age < 90
}

// Validation to check if date > current date
export function isFuture(value) {
    if (!value) return true
    const date = DateUtils.parseFormatedString(value)
    return DateUtils.isLater(date, DateUtils.now)
}

export function isLaterThanStartDate(value, vm) {
    if (!value || !vm.startDate) return true
    const endDate = DateUtils.parseFormatedString(value)
    if (!DateUtils.isValidDate(endDate)) return true
    const startDate = DateUtils.parseFormatedString(vm?.startDate)
    return DateUtils.isLater(endDate, startDate)
}

export const isValidPhoneNumber = helpers.withParams(
    { type: 'telephone' },
    value => {
        if (!value) return true
        
        // Validation for phone number, make sure + and () are also allowed
        const phoneRegex = /^[+]?[0-9]*[ ]?[(]?[0-9]{1,4}[)]?[0-9\s.]*$/

        if (!phoneRegex.test(value)) {
            return false
        }

        // Remove all non-digit characters and check if the total number of digits is between 7 and 15
        const digits = value.replace(/\D/g, '')
        if (digits.length < 7 || digits.length > 15) {
            return false
        }

        return true
    }
)

// Validation for password, make sure contains a capital letter, a number and a symbol
export const isValidPassword = helpers.regex(
    'password',
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{3,}$/
)

export const isValidEmail = helpers.regex(
    'email',
    // TODO: review expression - remove special chars? [A-z]
    // À-ž = \u00C0-\u017F
    /^[a-zA-Z0-9\u00C0-\u017F.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9\u00C0-\u017F](?:[a-zA-Z0-9\u00C0-\u017F-]{0,61}[a-zA-Z0-9\u00C0-\u017F])?(?:\.[a-zA-Z0-9\u00C0-\u017F](?:[a-zA-Z0-9\u00C0-\u017F-]{0,61}[a-zA-Z0-9\u00C0-\u017F])?)*$/
)

export const isValidMoney = helpers.regex('money', /^(\d{0,16}\.\d{0,2}|\d{0,16}|\.\d{0,2})$/)

// Error Messages
export const validationMessages = {
    required: {
        assignedApproverId: 'Kies een leidinggevende',
        firstName: 'Voornaam is verplicht',
        lastName: 'Achternaam is verplicht',
        email: 'E-mailadres is verplicht',
        emailAddress: 'E-mailadres is verplicht',
        birthday: 'Geboortedatum is verplicht',
        birthDay: 'Geboortedatum is verplicht',
        birthplace: 'Geboorteplaats is verplicht',
        birthPlace: 'Geboorteplaats is verplicht',
        initials: 'Initialen is verplicht',
        gender: 'Aanhef is verplicht',
        postalCode: 'Postcode is verplicht',
        number: 'Huisnummer is verplicht',
        city: 'Plaats is verplicht',
        country: 'Land is verplicht',
        street: 'Straat is verplicht',
        courseName: 'Ontwikkelactiviteit is een verplicht veld.',
        courseNewName: 'Ontwikkelactiviteit is een verplicht veld.',
        expiryDate: 'Vervaldatum is een verplicht veld.',
        emailReminderPeriod: 'Herinnering is een verplicht veld.',
        telephone: 'Telefoonnummer is verplicht',
        phoneNumber: 'Telefoonnummer is verplicht',
        addressPostalCode: 'Postcode is verplicht',
        addressHouseNumber: 'Huisnummer is verplicht',
        addressCity: 'Plaats is verplicht',
        addressCountryCode: 'Land is verplicht',
        addressStreet: 'Straat is verplicht',
        currentAmount: 'Overig te besteden bedrag is verplicht',
        name: 'Naam is verplicht',
        startAmount: 'Te besteden bedrag is verplicht',
        providerName: 'Laverancier is verplicht',
        courseUrl: 'Internet adres van de ontwikkelactiviteit is verplicht',
        meetings: 'Bijeenkomsten is verplicht',
        startDate: 'Startdatum is verplicht',
        isOwnRequest: 'Dit veld is verplicht',
        isExistingCourse: 'Dit veld is verplicht',
        courseLocation: 'Locatie is verplicht',
        completed: 'Afgerond is verplicht'
    },
    invalid: {
        number: 'Huisnummer mag alleen cijfers bevatten',
        numberAdditive:'Huisnummer toevoeging mag maximaal 25 tekens bevatten',
        birthday: 'Geboortedatum is ongeldig',
        telephone: 'Telefoonnummer is ongeldig',
        courseName: 'Ontwikkelactiviteit heeft geen geldige waarde.',
        expiryDate: 'Vervaldatum heeft geen geldige waarde.',
        dateCompleted: 'Datum afgerond heeft geen geldige waarde.',
        emailReminderPeriod: 'Herinnering heeft geen geldige waarde.',
        emailAddress: 'E-mailadres is ongeldig',
        email: 'E-mailadres is ongeldig',
        providerEmailAddress: 'E-mailadres leverancier is ongeldig',
        addressHouseNumber: 'Huisnummer mag alleen cijfers bevatten',
        addressHouseNumberAdditive:'Huisnummer toevoeging mag maximaal 25 tekens bevatten',
        birthDay: 'Geboortedatum is ongeldig',
        birthPlace: 'Geboorteplaats is ongeldig',
        phoneNumber: 'Telefoonnummer is ongeldig',
        currentAmount: 'Overig te besteden bedrag heeft geen geldige waarde',
        startAmount: 'Overig te besteden bedrag heeft geen geldige waarde',
        startDate: 'Startdatum is ongeldig',
        endDate: 'Einddatum is ongeldig',
        providerPhoneNumber: 'Telefonnummer is ongeldig',
        totalCost: 'Totale kosten bedrag heeft geen geldige waarde',
        otherCost: 'Overige kosten bedrag heeft geen geldige waarde',
        coursePrice: 'Ontwikkelactiviteitskosten bedrag heeft geen geldige waarde'
    }
}

export function isNotEmptyValue(value) {
    return value !== null && value !== undefined && value !== ''
}

export default {
    isValidDate,
    isPastDate,
    validationMessages,
    isValidPhoneNumber,
    isFuture,
    isNotEmptyValue,
    isLaterThanStartDate,
    isValidEmail,
    isValidMoney
}

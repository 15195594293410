/** DateTime logic and helpers */
export default class DateUtils {

    /**
     * Return last date of the year
     * @returns {Date} */
    static get endOfYear() {
        return new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)
    }

    /**
     * Current date
     * @returns {Date} */
    static get now() {
        return new Date()
    }

    /**
     * Current date with default mid night 00:00:00
     * @returns {string} */
    static get today() {
        const currentDate = this.now
        currentDate.setHours(0,0,0,0)
        return `${this.formatDateTime(currentDate, true)}`
    }

    constructor() {
        throw new Error("DateUtils is a static class")
    }

    /**
     * Convert ISO string to Date object
     * 
     * @param {string} dateTime 
     * @returns {Date}
     */
    static fromISODateTime(dateTime) {
        return dateTime?.indexOf("T") > -1 ? new Date(dateTime.replace("Z", "")) : new Date('')
    }

    /** 
     * Convert Date object to ISO string 
     * 
     * @param {Date} dateTime
     * @returns {string | undefined}
     */
    static toISODateTime(dateTime) {
        return this.isValidDate(dateTime) ? dateTime.toISOString() : undefined
    }

    /** 
     * Convert UTC ISO string to local time
     * 
     * @param {Date} date
     * @returns {Date | undefined}
     */
    static addTimezoneOffset(date) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000 )
    }

    /** 
     * Check if is a valid Date 
     * 
     * @param {Date|string} [date]
     * @returns {boolean}
     */
    static isValidDate(date) {
        if (!date) return false
        const dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        return !Number.isNaN(dateTime.getTime())
    }

    /** 
     * If valid date, return in format dd-MM-yyyy 
     * 
     * @param {Date|string} [date]
     * @returns {string}
     */
    static formatDate(date) {
        if (!date) return ''
        let dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
       
        if (!this.isValidDate(dateTime)) return ''

        dateTime = this.addTimezoneOffset(dateTime)
        const day = dateTime.getDate()
        const month = dateTime.getMonth() + 1
        const year = dateTime.getFullYear()

        return `${this.paddingZero(day)}-${this.paddingZero(month)}-${year}`
    }

    /** 
     * If valid date, return in format HH:mm
     * 
     * @param {Date|string} [date]
     * @returns {string}
     */
    static formatTime(date, addSeconds = false) {
        if (!date) return ''
        let dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        if (!this.isValidDate(dateTime)) return ''

        dateTime = this.addTimezoneOffset(dateTime)
        const hours = dateTime.getHours()
        const minutes = dateTime.getMinutes()

        let formattedTimeString = `${this.paddingZero(hours)}:${this.paddingZero(minutes)}`
        if (addSeconds) {
            const seconds = dateTime.getSeconds()
            formattedTimeString += `:${this.paddingZero(seconds)}`
        }
        return formattedTimeString
    }

    /** 
     * If valid date, return in format dd-MM-yyyy HH:mm:ss 
     * 
     * @param {Date|string} [date]
     * @returns {string}
     */
    static formatDateTime(date, addSeconds = false) {
        if (!date) return ''
        const dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        if (!this.isValidDate(dateTime)) return ''

        const dateStr = this.formatDate(dateTime)
        const timeStr = this.formatTime(dateTime, addSeconds)

        return `${dateStr} ${timeStr}`
    }

    /** 
     * Parse Date from formated string: dd-MM-yyyy HH:mm:ss 
     * 
     * @param {string} dateTime
     * @returns {Date | undefined}
     */
    static parseFormatedString(dateTime) {
        const match = this.regexParseString(dateTime)
        if (!match) return undefined
        const hasTime = dateTime.indexOf(' ') > -1

        const day = parseInt(match[1], 10)
        const month = parseInt(match[2], 10) - 1
        const year = parseInt(match[3], 10)
        const hours = hasTime ? parseInt(match[4], 10) : 12
        const minutes = hasTime ? parseInt(match[5], 10) : 0
        const seconds = hasTime ? parseInt(match[6], 10) : 0

        if (month > 11 || month < 0) return undefined
        if (day < 1 || day > 31) return undefined
        if (hours < 0 || hours > 23) return undefined
        if (minutes < 0 || minutes > 59) return undefined
        if (seconds < 0 || seconds > 59) return undefined

        return new Date(year, month, day, hours, minutes, seconds)
    }

    static regexParseString(dateTime) {
        if (!dateTime) return undefined
        const parseRegexDateTime = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/
        const parseRegexDate = /^(\d{2})-(\d{2})-(\d{4})$/

        const hasTime = dateTime.indexOf(' ') > -1

        return hasTime ? parseRegexDateTime.exec(dateTime) : parseRegexDate.exec(dateTime)
    }

    /**
     * 
     * @param {string} dateTime 
     * @returns { string | undefined }
     */
    static parseToISOString(dateTime) {
        const date = this.parseFormatedString(dateTime)
        return this.isValidDate(date) ? date.toISOString() : undefined
    }

    /**
     * check if is a date in format yyyy-MM-dd
     * 
     * @param {string} [date] 
     * @returns {boolean}
     */
    static isIsoDate(date) {
        return /^\d{4}-\d{2}-\d{2}$/.test(date || '')
    }

    /** 
     * Convert Date from formated string: dd-MM-yyyy to yyyy-MM-dd 
     * 
     * @param {string} [formatedDate]
     * @returns {string|undefined}
     */
    static convertToISODate(formatedDate) {
        if (!formatedDate) return undefined
        if (this.isIsoDate(formatedDate)) return formatedDate
        const date = this.parseFormatedString(formatedDate)
        if (!this.isValidDate(date)) return undefined

        return date?.toISOString().substring(0, 10)
    }

    /** 
     * Check if date is later than reference 
     * 
     * @param {Date|string} [date]
     * @param {Date|string} [reference]
     * @returns {boolean}
     */
    static isLater(date, reference) {
        if (!date || !reference) return false
        const dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        const referenceTime = typeof reference === "string" ? this.fromISODateTime(reference) : reference
        return dateTime.getTime() > referenceTime.getTime()
    }

    /**
     * Compare 2 dates <= -1 if date1 is before date2, 0 if date1 is equal to date2, >= 1 if date1 is after date2
     * 
     * @param {Date|string} [date1] 
     * @param {Date|string} [date2] 
     * @returns {Number}
     */
    static compare(date1, date2) {
        if (!date1 && !date2) return 0
        if (!date1) return -1
        if (!date2) return 1

        const d1 = typeof date1 === "string" ? this.fromISODateTime(date1) : date1
        const d2 = typeof date2 === "string" ? this.fromISODateTime(date2) : date2

        return d1.getTime() - d2.getTime()
    }

    /**
     * Parse dates in format dd-MM-yyyy and compare them
     * @param {string} date1 
     * @param {string} date2 
     * @returns {Number}
     */
    static parseAndCompare(date1, date2) {
        if (!date1 && !date2) return 0
        if (!date1) return -1
        if (!date2) return 1

        const d1 = this.parseFormatedString(date1)
        const d2 = this.parseFormatedString(date2)

        return this.compare(d1, d2)
    }

    /**
     * return year date
     * @param {Date|string} [date]
     * @returns {number | undefined}
     */
    static getYear(date) {
        if (!date) return undefined
        const dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        if (!this.isValidDate(dateTime)) return undefined
        return dateTime.getFullYear()
    }

    /**
     * return month date
     * @param {Date|string} [date]
     * @returns {number | undefined}
     */
    static getMonth(date) {
        if (!date) return undefined
        const dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        if (!this.isValidDate(dateTime)) return undefined
        return dateTime.getMonth()
    }

    /**
     * Calculates age from date of birth
     * @param {Date|string} [date] 
     * @returns {number | undefined}
     */
    static getAge(date) {
        if (!date) return undefined
        const dateTime = typeof date === "string" ? this.fromISODateTime(date) : date
        if (!this.isValidDate(dateTime)) return undefined
        const now = this.now
        const age = now.getFullYear() - dateTime.getFullYear()
        
        if (dateTime > (new Date(now.getFullYear() - age, now.getMonth(), now.getDate())))
        {
            return age - 1
        }
        return age
    }

    /**
     * Calculates date couting teh days from now
     * @param {number} days 
     * @returns {Date}
     */
    static daysFromNow(days) {
        const date = this.now.getTime() + (days * 24 * 60 * 60 * 1000)
        return new Date(date)
    }

    /**
     * Ensure 2 digit number by adding 0 in front
     * @param {Number} value 
     * @returns {String}
     */
    static paddingZero(value) {
        return value < 10 ? `0${value}` : value.toString()
    }
}

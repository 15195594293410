export default {
    PendingValidation: { code: 10, codeName: 'PendingValidation', display: 'In afwachting van validatie' },
    Invalid: { code: 20, codeName: 'Invalid', display: 'Foutieve order' },
    PendingAccount: { code: 30, codeName: 'PendingAccount', display: 'Aangevraagd' },
    RejectedAccount: { code: 40, codeName: 'RejectedAccount', display: 'Afgekeurd door klant' },
    PendingProvider: { code: 50, codeName: 'PendingProvider', display: 'Goed te keuren door provider' },
    RejectedProvider: { code: 60, codeName: 'RejectedProvider', display: 'Afgekeurd door provider' },
    AcceptedProvider: { code: 65, codeName: 'AcceptedProvider', display: 'Goedgekeurd door provider' },
    CancelledAccount: { code: 70, codeName: 'CancelledAccount', display: 'Geannuleerd door klant' },
    CancelledAdmin: { code: 75, codeName: 'CancelledAdmin', display: 'Geannuleerd door admin'},
    PendingInvoice: { code: 80, codeName: 'PendingInvoice', display: 'In afwachting van factuur' },
    Invoiced: { code: 90, codeName: 'Invoiced', display: 'Gefactureerd' },
    Paid: { code: 93, codeName: 'Paid', display: 'Betaald' },
    PendingCredited: { code: 95, codeName: 'PendingCredited', display: 'In afwachting van creditatie' },
    Credited: { code: 100, codeName: 'Credited', display: 'Gecrediteerd' },
    NotInvoiced: { code: 110, codeName: 'NotInvoiced', display: 'Niet factureren' },
    InvoicedByThirdParty: { code: 111, codeName: 'InvoicedByThirdParty', display: 'Gefactureerd door derde partij' },
    CancelledWithCosts: { code: 120, codeName: 'CancelledWithCosts', display: 'Geannuleerd met kosten' }
}

export const getFilePathByName = (fileFolder, fileName, extension = 'png') => {
    try {
        if(fileFolder && fileName && extension) {
            /* eslint-disable global-require, import/no-dynamic-require */
            return require(`../assets/${fileFolder}/${fileName}.${extension}`)
        }
        return null
    } catch (err) {
        console.error(err)
        return null
    }
}

export default {
    getFilePathByName
}
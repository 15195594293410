export const hooperSettings = {
    trimWhiteSpace: true,
    breakpoints: {
        1120: {
            itemsToShow: 3,
            itemsToSlide: 3
        },
        750: {
            itemsToShow: 2,
            itemsToSlide: 2
        },
        0: {
            itemsToShow: 1,
            itemsToSlide: 1
        }
    }
}

export default hooperSettings
<template>
  <recess-layout-static v-if="$isAllowed('displaySocialLearning')">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetchingPosts"
        overlay="white"
        color="primary"
      />
    </transition>

    <recess-divider />

    <div class="d-flex justify-content-md-between flex-column flex-md-row">
      <div>
        <h3 data-test="social-learning-search-title">Welkom bij het Kennisplein</h3>
        <p>Deel uw kennis en expertise met uw collega's of vraag om hulp bij een specifiek probleem</p>
      </div>

      <div class="d-flex">
        <h4 class="mb-0 pb-0 pr-2">{{ totalCount }} </h4>
        <h4 class="mb-0 pb-0 font-weight-normal">Zoekresultaten</h4>
      </div>
    </div>

    <recess-divider :display-medium-viewport="false" :display-large-viewport="false"  />  

    <search-input @search="search" />

    <recess-divider />  

    <recess-card variant="variant1" bordered class="d-flex justify-content-between align-items-md-center flex-column flex-md-row">
      <recess-button 
        :url="{ name: 'create-new-post' }"
        variant="primary" 
        title="Nieuw" 
        data-test="create-new-post"
      />

      <recess-divider variant="xsmall" :display-medium-viewport="false" :display-large-viewport="false" />

      <sorting />
    </recess-card>

    <recess-divider />  

    <div v-for="post in posts" :key="post.id">
      <post-item :post="post" />
      <recess-divider variant="xsmall" />
    </div>
    
    <recess-divider variant="xsmall" />

    <div v-if="hasMorePosts" class="d-flex justify-content-center">
      <pagination />
    </div>

    <recess-divider variant="xsmall" />
  </recess-layout-static>
</template>

<script>
import { computed, onMounted, ref, watch, onBeforeUnmount } from 'vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import SearchInput from '../../organisms/SocialLearning/Search/SearchInput.vue'
import Sorting from '../../organisms/SocialLearning/Search/Sorting.vue'
import PostItem from '../../organisms/SocialLearning/Search/PostItem.vue'
import Pagination from '../../organisms/SocialLearning/Search/Pagination.vue'
import usePagination from '../../../composables/usePagination'
import useRoute from '../../../composables/useRoute'
import socialLearningClient from '../../../api/socialLearningClient'
import LoaderComponent from "../../atoms/LoaderComponent/LoaderComponent.vue"
import odataSortingDirections from '../../../api/OData/odataSortingDirections'

export default {
    name: "SocialLearningSearch",
    perimeters: [featuresPerimeter],
    components: { SearchInput, Sorting, PostItem, Pagination, LoaderComponent },
    setup() {
        const route = useRoute()
        
        const totalPosts = ref(0)

        const { remainingItems, top, skip, sorting, totalCount, resetPagination } = usePagination({
            totalCount: computed(() => totalPosts.value),
            sorting: `CreationDate ${odataSortingDirections.DESC}`
        })
   
        const hasMorePosts = computed(() => {
            return remainingItems.value > 0
        })

        const dataQuery = computed(() => {
            return {
                search: route.value.query?.q ?? "",
                top: top.value,
                skip: skip.value,
                orderBy: new Array(sorting.value),
                select: [
                    "Title",
                    "Content",
                    "TotalReplies",
                    "Tags",
                    "CreationDate",
                    "AuthorName",
                    "Id"
                ]
            }
        })

        const posts = ref([])
        const isFetchingPosts = ref(false)
        const search = async () => {
            try {
                isFetchingPosts.value = true
                
                const documentSearchResult = await socialLearningClient.searchPosts(dataQuery.value)
                totalPosts.value = documentSearchResult?.count
                posts.value = documentSearchResult?.results?.map(result => result.document)
            } catch (err) {
                throw new Error(err)
            } finally {
                isFetchingPosts.value = false
            }
        }

        watch([sorting, top], async () => {
            await search()
        })

        onMounted(async() => {
            await search()
        })

        onBeforeUnmount(() => {
            resetPagination()
            posts.value = []
            totalPosts.value = 0
        })

        return {
            totalCount,
            posts,
            hasMorePosts,
            dataQuery,
            isFetchingPosts,
            search
        }
    }
}
</script>

<template>
  <recess-layout-static>
    <NotFoundSVG />
    <div class="d-flex justify-content-center">
      <div>
        <h1>Oeps de pagina is weg...</h1>
        <div class="text-center">
          Klik
          <router-link :to="{ name: 'home' }">hier</router-link> om terug te gaan naar home.
        </div>
      </div>
    </div>
  </recess-layout-static>
</template>

<script>
import NotFoundSVG from '../../atoms/NotFoundSVG/NotFoundSVG.vue'

export default {
    components: {
        NotFoundSVG
    }
}
</script>

<template>
  <div></div>
</template>

<script>
import { getUserManager } from '../../../modules/oidc-client'

export default {
    name: 'OidcCallback',
    mounted() {
        getUserManager().signinSilentCallback()
    }
}
</script>
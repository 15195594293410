<template>
  <recess-search-input 
    v-model="searchQuery" 
    placeholder="Zoek op titel of onderwerp" 
    button-icon-color="white" 
    variant="secondary" 
    :size="!isMobileOrTablet ? 'large' : null"
    @keyup-enter="resetTopAndSearch"
    @button-click="resetTopAndSearch"
  />
</template>

<script>
import { onMounted, ref,watch } from 'vue'
import useRouter from '../../../../composables/useRouter'
import useRoute from '../../../../composables/useRoute'
import usePagination from '../../../../composables/usePagination'
import useMedia from '../../../../composables/useMediaQueries'
import mediaQueries from '../../../../constants/mediaQueries'

export default {
    emits: ['search'],
    setup(_, { emit }) {
        const router = useRouter()
        const route = useRoute()
        const isMobileOrTablet = useMedia(mediaQueries.MEDIUM)

        const searchQuery = ref("")
        watch(searchQuery, q => {
            router.replace({ query: { ...q && { q } }})
        })

        const { top, initialTop } = usePagination()
        const resetTopAndSearch = () => {
            top.value = initialTop
            emit('search')
        }

        onMounted(() => {
            const existingQuery = route.value.query?.q
            if(existingQuery) searchQuery.value = existingQuery
        })

        return {
            searchQuery,
            resetTopAndSearch,
            isMobileOrTablet
        }
    }
}
</script>

<template>
  <lightbox-component
    class="c-event-calendar"
    :is-visible="showEventCalendar"
    @close="$emit('close')"
  >
    <h3>Lesrooster voor {{ courseName }}</h3>

    <div class="d-flex">
      <p
        v-if="startMomentLocation"
      > In regio {{ startMomentLocation }}</p>
      <p
        v-if="startMomentLocation && 
          startMomentMonth && 
          startMomentYear"
      >, {{ startMomentMonth }} {{ startMomentYear }}</p>
    </div>
    <!-- Event calendar table -->
    <recess-table>
      <recess-table-head>
        <recess-table-row>
          <recess-table-head-item
            v-for="header in headers"
            :key="header.key"
            :sortable="header.isSortable"
            :sort-direction="getSortDirection(header)"
            @click.native="header.isSortable ? setSorting(header) : null"
          >
            {{ header.title }}
          </recess-table-head-item>
        </recess-table-row>
      </recess-table-head>
      <recess-table-body>
        <recess-table-row
          v-for="(event, index) in sortedEvents"
          :key="event[index]"
          data-test="startmoment-event"
        >
          <recess-table-cell :data-label="headers[0].title">
            <span
              v-if="event.eventDateDisplayDayNr && event.eventDateDisplayMonth && event.eventDateDisplayYear"
              class="mr-2"
            >{{ event.eventDateDisplayDayNr }} {{ event.eventDateDisplayMonth }} {{ event.eventDateDisplayYear }}</span> 
            <span v-if="event.displayStartTime && event.displayEndTime">{{ event.displayStartTime }} - {{ event.displayEndTime }}</span>
          </recess-table-cell>
          <recess-table-cell :data-label="headers[1].title">
            <span>{{ event.moduleName }}</span>
          </recess-table-cell>
          <recess-table-cell :data-label="headers[2].title">
            <span>{{ event.city }}</span>
          </recess-table-cell>
        </recess-table-row>
      </recess-table-body>
    </recess-table>

    <div
      slot="modal-footer"
      class="d-flex flex-column"
    >
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
  </lightbox-component>
</template>

<script>
import LightboxComponent from "../LightboxComponent/LightboxComponent.vue"
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'
import DateUtils from '../../../utils/DateUtils'


export default {
    components: {
        LightboxComponent
    },
    props: {
        showEventCalendar: {
            type: Boolean,
            default: false
        },
        courseName: {
            type: String,
            default: null
        },
        startMoment: {
            type: Object,
            default: null
        },
        events: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showErrorMessage: false,
            isLoading: false,
            headers: [
                { key: 'eventDate', title: 'Datum', isSortable: true }, 
                { key: 'moduleName', title: 'Module', isSortable: true }, 
                { key: 'Location', title: 'Locatie', isSortable: false }
            ],
            sorting: {
                key: 'eventDate',
                direction: ODataSortingDirections.DESC
            }
        }
    },
    computed: {
        sortedEvents() {
            const eventsToSort = [...this.events]
          
            return eventsToSort?.sort((a, b) => {
                const isAsc = this.sorting.direction === ODataSortingDirections.ASC 
                if(a[this.sorting.key] < b[this.sorting.key]) return isAsc ? 1 : -1
                if(a[this.sorting.key] > b[this.sorting.key]) return isAsc ? -1 : 1
                return 0
            }) ?? []
        },
        startMomentLocation() {
            return this.startMoment?.city
        },

        startMomentMonth() {
            return this.startMoment?.startDate?.displayMonth
        },
        startMomentYear() {
            return this.startMoment?.startDate?.startMomentYear
        }
    },
    methods: {
        getSortDirection(header) {
            if (!header.key) return null

            if (this.sorting.key !== header.key) {
                return null
            }

            return this.sorting.direction ? this.sorting.direction.toLowerCase() : null
        },
        setSorting(header) {
            if (this.sorting.key === header.key) {
                this.sorting.direction =
                    this.sorting.direction === ODataSortingDirections.ASC
                        ? ODataSortingDirections.DESC
                        : ODataSortingDirections.ASC
            } else {
                this.sorting.direction = ODataSortingDirections.DESC
            }
            this.sorting.key = header.key
        },
        getTime: t => DateUtils.formatTime(t)
    }
}
</script>

import { getOplzClient } from './apiClient'

const baseUrl = '/contact'

const postContactForm = contactForm => {
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            c.post(`${baseUrl}`, contactForm)
                .then(response => {
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    })
}

export default {
    postContactForm
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['c-carousel__image', 
           { 
             'c-carousel__image--empty': !_vm.isValidImage || _vm.isInvalidThumbnailImage, 
             'c-carousel__image--rounded' : _vm.isRounded,
             'c-carousel__image--small' : _vm.isSmall
           }
  ]},[(_vm.isThumbnail)?_c('span',{staticClass:"c-thumb__icon-play"}):_vm._e(),(_vm.isValidImage && !_vm.isInvalidThumbnailImage)?_c('img',{class:['c-carousel__image-item', { 'is-active' : _vm.showContent && _vm.isValidImage }],attrs:{"src":_vm.image,"alt":"Carousel image","data-test":"carousel-img"}}):_vm._e(),(_vm.showContent)?[(_vm.fileName)?_c('span',{staticClass:"c-carousel__file-name"},[_vm._v(_vm._s(_vm.fileName))]):_vm._e(),(_vm.showDeleteIcon)?_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('recess-icon',{staticClass:"c-carousel__delete-icon",attrs:{"icon":"bin","color":"black","height":20,"width":20},on:{"click":function($event){return _vm.$emit('delete')}}})],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
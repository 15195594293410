<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />
    <transition name="fade">
      <div v-if="notifications && notifications.length">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
                :sortable="header.isSortable"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="notification in notifications"
              :key="notification.notificationId"
              :data-test="`notification-id-${notification.notificationId}`"
            >
              <recess-table-cell :data-label="headers[0].title">
                <span
                  :class="{'font-weight-bold' : !notification.isRead}"
                >{{ formatDateTime(notification.timestamp) }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[1].title">
                <span
                  data-test="notification-item"
                  :class="['a', {'font-weight-bold' : !notification.isRead}]"
                  @click="onNotificationClick(notification)"
                  v-html="notification.message"
                ></span>
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
      </div>
    </transition>
    <recess-alert
      v-if="showNoResultsMessage"
      :show-alert="true"
      text="Er zijn geen resultaten."
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import userClient from '../../../api/userClient'
import DateUtils from '../../../utils/DateUtils'

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
            headers: [
                { key: 'Date', title: 'Datum' },
                { key: 'Notification', title: 'Notificatie' }
            ],
            notifications: null,
            notificationClicked: null,
            isLoading: true,
            showNoResultsMessage: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        userId() {
            return this.user.userId
        }
    },
    watch: {
        userId() {
            if (this.user.userId) {
                this.initialize()
            }
        }
    },
    mounted() {
        this.initialize()
    },
    destroyed() {
        if (this.notificationClicked) {
            userClient.setNotificationAsRead(this.userId, this.notificationClicked.notificationId)
        }
    },
    methods: {
        initialize() {
            if (this.userId) {
                this.isLoading = true

                userClient
                    .getNotifications(this.userId, null, true)
                    .then(response => {
                        this.showNoResultsMessage = false
                        this.notifications = response.data

                        if (!this.notifications || !this.notifications.length)
                            this.showNoResultsMessage = true
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .then(() => {
                        this.isLoading = false
                    })
            }
        },
        onNotificationClick(notification) {
            this.notificationClicked = notification
            this.$router.push(notification.url)
        },
        formatDateTime: e => DateUtils.formatDateTime(e)
    }
}
</script>
import axios from 'axios'
import DataQueryBuilder from '../../api/DataQuery/dataQueryBuilder'
import DataQueryFilter from '../../api/DataQuery/dataQueryFilter'
import DataQueryFilterOperator from '../../api/DataQuery/dataQueryFilterOperator'
import ODataFilterOperations from '../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../api/OData/odataQueryFilter'
import odataSortingDirections from '../../api/OData/odataSortingDirections'
import { searchBudgets } from '../../api/budgetClient'
import courseClient from '../../api/courseClient'
import enrollmentClient from '../../api/enrollmentClient'
import keyAccountClient from '../../api/keyAccountClient'
import studyContractTemplateClient from '../../api/studyContractTemplateClient'
import userClient from '../../api/userClient'
import * as approvalTypes from '../../constants/approvalNeededTypes'
import * as billingAddressOptions from '../../constants/billingAddressOptions'
import courseAccessStatuses from '../../constants/courseAccessStatuses'
import * as courseLearningMethodTypes from '../../constants/courseLearningMethodTypes'
import * as enrollmentSteps from '../../constants/enrollmentSteps'
import * as enrollmentTypes from '../../constants/enrollmentTypes'
import { errorMessages } from '../../constants/errorMessages'
import * as featureToggles from '../../constants/featureToggles'
import { edumsInvalidOrderStatuses, oplzInvalidStatuses } from '../../constants/invalidStatuses'
import * as productTypes from '../../constants/productTypes'
import courseAccessHelper from '../../modules/courseAccessHelper'
import { getGuaranteeIntValue, getGuaranteeStringValue } from '../../modules/enrollmentsHelper'
import numberFormatter from '../../modules/numberFormatter'
import startMomentHelper from '../../modules/startMomentHelper'
import DateUtils from '../../utils/DateUtils'
import { mapCourseAccessErrorMessage } from '../../utils/MapUtils'
import * as PEStatusUtils from '../../utils/PEStatusUtils'

const enrollmentModule = {
    namespaced: true,
    state() {
        return {
            copiedEnrollment: null,
            enrollmentType: null,
            isError: false,
            isFinished: false,
            course: null,
            courseAssortmentId: null,
            diplomaType: '',
            courseTitle: '',
            isELearning: false,
            isFetchingCourse: false,
            isFetchingCoursePrice: false,
            isFetchingCountries: false,
            isFetchingEnrollment: false,
            isFetchingTeamLeaders: false,
            isFetchingRejectionReasons: false,
            isFetchingUsers: false,
            isFetchingOrders: false,
            isFetchingTeamleaders: false,
            isFetchingStudyContractTemplates: false,
            isFetchingSecondApprovers: false,
            isFetchingEnrollmentsForCourse: false,
            isSendingEnrollments: false,
            isRejectingEnrollment: false,
            isCheckingApprovalRequired: false,
            isSendingEnrollment: false,
            isFetchingStudyContractTemplateDocument: false,
            isCheckingUserAccess: false,
            approvalRequired: false,
            userCanEnroll: true,
            courseAccessErrorMessage: null,
            willUpdateUserData: false,
            users: [],
            teamleadersPerCompany: {},
            currentStepCode: '',
            countries: [{ displayValue: 'Nederland', value: 'NL' }],
            teamLeaders: [],
            rejectionReasons: [],
            enrollReasons: [],
            secondApproversOptions: [],
            studyContractOptions: [],
            enrollmentsForCourse: [],
            billingAddressOption: billingAddressOptions.MYSELF,
            studyContractTemplateDocument: null,
            selectedTeamLeaderEmail: null,
            useAlternativeCostCenter: false,
            teamLeaderSteps: enrollmentSteps.TEAMLEADERSTEPS,
            studentSteps: enrollmentSteps.STUDENTSTEPS,
            coordinatorSteps: enrollmentSteps.COORDINATORSTEPS,
            agreesToShare: undefined,
            prices: null,
            potentialTeamLeaders: [],
            formData: {
                selectedStartMomentIds: [],
                firstName: null,
                middleName: null,
                lastName: null,
                birthday: null,
                birthplace: null,
                emailAddress: null,
                telephone: null,
                postalCode: null,
                number: null,
                numberAdditive: null,
                street: null,
                city: null,
                country: null,
                students: [],
                assignedApproverId: null,
                selectedTeamleaderPerCompany: {},
                costCenter: null,
                purchaseOrderNumber: null,
                studyContractTemplateId: null,
                secondApproverId: null,
                isStartmomentSelectionEnabled: true,
                enrollReasonCode: null,
                enrollReasonMessage: null,
                isSubsidized: false,
                billingAddress: {
                    companyName: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    emailAddress: null,
                    number: null,
                    numberAdditive: null,
                    postalCode: null,
                    city: null,
                    country: null,
                    phoneNumber: null,
                    street: null
                },
                budgets: [],
                keyAccountId: null
            },
            selectedStudentsFilters: [
                {
                    labelText: 'Zoek op naam medewerker',
                    id: 'FullName',
                    keys: ['firstName', 'middleName', 'lastName'],
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    keyCombination: 'or'
                },
                {
                    labelText: 'Zoek op e-mail',
                    id: 'EmailAddress',
                    keys: 'emailAddress',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                },
                {
                    labelText: 'Zoek op afdeling',
                    id: 'CompanyName',
                    keys: 'company/name',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                },
                {
                    labelText: 'Zoek op kostenplaats',
                    id: 'CostCenter',
                    keys: 'costCenter',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                },
                {
                    labelText: 'Zoek op personeelsnummer',
                    id: 'EmployeeNumber',
                    keys: 'employeeNumber',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                },
                {
                    labelText: 'Zoek op organisatieonderdeel',
                    id: 'Department',
                    keys: 'userDepartment/name',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                }
            ],
            selectedPEStatusFilter: [],
            selectedStudentsPagination: {
                pageSize: 20,
                currentPage: 1,
                totalCount: 0,
                totalPages: 0,
                pageSizeFields: [
                    { value: 10, displayText: '10' },
                    { value: 20, displayText: '20' },
                    { value: 40, displayText: '40' },
                    { value: 80, displayText: '80' },
                    { value: 120, displayText: '120' }
                ]
            },
            errorMessage: '',
            defaultErrorMessage: 'Oeps, er is een onverwachte fout opgetreden.',
            keyAccounts: [],
            enrollment: null,
            isUserFilterDisabled: false,
            selectedLearningResultOptions: null,
            learningResultSharingType: null,
            selectedStartMoments: []
        }
    },
    getters: {
        course(state) {
            return state.course
        },
        selectedTeamLeaderEmail(state) {
            return state.selectedTeamLeaderEmail
        },
        copiedEnrollment(state) {
            return state.copiedEnrollment
        },
        courseVariant(state) {
            return state.course?.startMoments?.find(sm => sm.externalId === state.formData.selectedStartMomentIds[0])?.displayType
        },
        selectedStudentsIds(state) {
            return state.formData?.students?.map(s => s.userId)
        },
        courseDayOfWeek(state) {
            return state.selectedStartMoments?.[0]?.dayOfWeekDisplayValue
        },
        coursePrice(state, _, __, rootGetters) {
            const useNetPrice = rootGetters['accountModule/useNetPrice']
            const displayVatInPrices = rootGetters['accountModule/displayVatInPrices']

            return numberFormatter.roundToTwo(useNetPrice || displayVatInPrices ? state.prices?.totalPrice : state.prices?.totalPriceWithoutVAT)
        },
        selectedStartMoments(state) {
            return state.selectedStartMoments

        },
        showBudgetStep(state, getters, rootState) {
            return !!(state.enrollmentType === enrollmentTypes.STUDENT
                && state.formData.budgets?.length
                && rootState?.accountModule?.accountSettings?.isBudgetEnabled
                && getters.coursePrice > 0)
        },
        courseHasStartMoments(state) {
            return !!(state?.course?.startMoments?.length)
        },
        showTeamLeaderStep(state, getters) {
            return state.approvalRequired && !getters.showBudgetStep
        },
        keyAccounts(state) {
            return state.keyAccounts
        },
        errorMessage(state) {
            return state.errorMessage
        },
        enrollmentType(state) {
            return state.enrollmentType
        },
        isFetchingTeamLeaders(state) {
            return state.isFetchingTeamLeaders
        },
        potentialTeamLeaders(state) {
            return state.potentialTeamLeaders
        },
        selectedStudentsFilters(state, _, rootState) {
            return rootState?.accountModule?.account?.accountDisabledFeatures?.includes(
                featureToggles.COSTCENTER
            )
                ? state.selectedStudentsFilters?.filter(f => f.id !== 'CostCenter')
                : state.selectedStudentsFilters
        },
        selectedPEStatusFilter(state) {
            return state.selectedPEStatusFilter
        },
        currentStep(state) {
            let currentStep = null
            switch (state.enrollmentType) {
                case enrollmentTypes.TEAMLEADER:
                    currentStep = state.teamLeaderSteps.find(
                        step => step.code === state.currentStepCode
                    )
                    break
                case enrollmentTypes.COORDINATOR:
                    currentStep = state.coordinatorSteps.find(
                        step => step.code === state.currentStepCode
                    )
                    break
                case enrollmentTypes.STUDENT:
                    currentStep = state.studentSteps.find(
                        step => step.code === state.currentStepCode
                    )
                    break
                default:
                    break
            }
            return currentStep
        },
        selectedStartMomentIds(state) {
            return state.formData.selectedStartMomentIds
        },
        selectedStudents(state) {
            return state.formData.students
        },
        selectedStudentsCompanies(state) {
            // Get unique array of companies
            return Array.from(
                new Map(
                    state.formData.students.map(s => [
                        'companyId' in s ? s.companyId : s.company,
                        { companyId: s.companyId, name: s.company?.name }
                    ])
                ).values()
            )
        },
        // Get unique array of company id's
        selectedStudentsCompanyIds(state) {
            return [
                ...new Set(
                    state.formData.students.map(student => {
                        return student.companyId
                    })
                )
            ]
        },
        selectedCompanyTeamleaders(state) {
            return state.formData.selectedTeamleaderPerCompany
        },
        isSelfPlannedCourse(state) {
            return state.course?.learningMethod?.type?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED
        },
        totalSteps(state, getters, rootState, rootGetters) {
            let steps = null
            switch (state.enrollmentType) {
                case enrollmentTypes.STUDENT:
                    steps = state.studentSteps
                    break
                case enrollmentTypes.COORDINATOR:
                    steps = state.coordinatorSteps
                    break
                case enrollmentTypes.TEAMLEADER:
                    steps = state.teamLeaderSteps
                    break
                default:
                    break
            }

            if (steps) {
                if (!getters.courseHasStartMoments)
                    steps = steps.filter(step => step.code !== enrollmentSteps.STARTMOMENTS.code)

                if (!getters.showTeamLeaderStep)
                    steps = steps.filter(step => step.code !== enrollmentSteps.TEAMLEADER.code)

                if (!rootState?.accountModule?.accountSettings?.optionalAlternativeBillingAddress)
                    steps = steps.filter(step => step.code !== enrollmentSteps.BILLINGADDRESS.code)

                if (!getters.showBudgetStep)
                    steps = steps.filter(step => step.code !== enrollmentSteps.BUDGETS.code)

                if (!rootGetters?.['accountModule/isLearningResultEnabled'])
                    steps = steps.filter(step => step.code !== enrollmentSteps.LEARNINGRESULTS.code)
            }

            return steps
        },
        isLoading(state) {
            return (
                state.isFetchingCourse ||
                state.isFetchingCoursePrice ||
                state.isFetchingTeamleaders ||
                state.isCheckingApprovalRequired ||
                state.isSendingEnrollment ||
                state.isFetchingCountries ||
                state.isFetchingUsers ||
                state.isSendingEnrollments ||
                state.isFetchingStudyContractTemplates ||
                state.isFetchingSecondApprovers ||
                state.isFetchingOrders ||
                state.isCheckingUserAccess ||
                state.isFetchingEnrollment
            )
        },
        courseId(state) {
            return state.course?.id
        },
        costCenter(state) {
            return state.formData.costCenter
        },
        courseName(state) {
            return state.course && state.course.name
        },
        courseAssortment(state) {
            return state.courseAssortmentId
        },
        purchaseOrderNumber(state) {
            return state.formData.purchaseOrderNumber
        },
        blockBecauseOfInsufficientBudget(state, getters, rootState) {
            // This is only for student enrollment. So if the enrollment type is of any other, don't block the enrollment.
            if (state.enrollmentType !== enrollmentTypes.STUDENT) {
                return false
            }
            const availableBudgetAmount = state.formData.budgets?.reduce(
                (acc, budget) => acc + budget.currentAmount,
                0
            )
            const insufficientBudget = availableBudgetAmount < getters.coursePrice

            return (
                rootState?.accountModule?.accountSettings?.blockEnrollmentInsufficientBudget &&
                insufficientBudget
            )
        },
        selectedSecondApprover(state) {
            let selectedSecondApprover
            state.secondApproversOptions.forEach(secondApprover => {
                if (secondApprover.value === state.formData.secondApproverId)
                    selectedSecondApprover = secondApprover.displayText
            })
            return selectedSecondApprover
        },
        selectedStudyContract(state) {
            let selectedStudyContract
            state.studyContractOptions.forEach(studyContract => {
                if (studyContract.value === state.formData.studyContractTemplateId)
                    selectedStudyContract = studyContract.displayText
            })
            return selectedStudyContract
        },
        selectedStudentsId(state) {
            return state.formData.students.map(student => student.userId)
        },
        courseProductType(state) {
            return state.course?.productType
        },
        providerId(state) {
            return state.course?.providerId
        },
        showStartmoments(state) {
            return !!state.course?.startMoments?.length
        },
        showCapacity(_, getters, rootState) {
            const showCapacityIconForAllCoursesSetting = rootState?.accountModule?.accountSettings?.showCapacityIconForAllCourses
            return startMomentHelper.showCapacityDetail(showCapacityIconForAllCoursesSetting, getters.courseProductType)
        },
        // Limit incompany enrollments means if this is an incompany course, limit/block the start moments have reached their max capacity
        limitIncompanyEnrollmentsAndIsIncompany(_, getters, rootgetters) {
            return rootgetters?.accountModule?.accountSettings?.limitIncompanyEnrollments && getters.courseProductType === productTypes.INCOMPANY
        },
        filteredEnrollmentsForCourse(state) {
            // this getter represents a list of the enrollmentsForCourse, but then filtered out the invalid statusses and added
            // a few properties like the location and the display date of the selected start moment.

            return state.enrollmentsForCourse.reduce((acc, enrollment) => {
                // Filter out invalid status
                // Filter out NOA course
                if (
                    !oplzInvalidStatuses.includes(enrollment?.statusId) &&
                    !edumsInvalidOrderStatuses.includes(enrollment?.eduMSOrderStatus) &&
                    !enrollment?.eduMSExternalCourseId?.includes('NOA-')
                ) {
                    // Get location and display date and add it to the list
                    const startMoment = state.course?.startMoments?.find(
                        y => y.externalId === enrollment.eduMSFirstStartMomentId
                    )
                    const location = startMoment?.city

                    acc.push({
                        ...enrollment,
                        location,
                        displayDate: startMoment?.startDate?.displayDate
                    })
                }
                return acc
            }, [])
        },
        useAlternativeBilling(state) {
            return state.billingAddressOption === billingAddressOptions.MYEMPLOYER
        },
        isSecondApprovalRequired(state) {
            return state.approvalRequired === approvalTypes.SECONDAPPROVAL
        },
        // These next two getters (enrollmentStartMomentsWithValue, invalidStartMoments and hasValidStartMoments) not built for the enrollment flow itself.
        // This was built for other enrollment related actions, like approvals. Be careful with using these in the enrollment flow.
        enrollmentStartMomentsWithValue(state) {
            return [
                state.enrollment?.eduMSFirstStartMomentId,
                state.enrollment?.eduMSSecondStartMomentId
            ].filter(x => !!x)
        },
        enrollmentHasValidStartMoments(state, getters) {
            // If it's an e-learning and there's no start moments provided, then it's fine, because an e-learning doesn't NEED chosen start moments.
            if (
                getters.isSelfPlannedCourse &&
                !getters.enrollmentStartMomentsWithValue.length
            )
                return true

            // If it's not an e-learning, or if it's an e-learning but the student chose start moments, make sure the first start moment is always filled, and there is at least one valid one.
            return (
                !!state.enrollment?.eduMSFirstStartMomentId &&
                getters.enrollmentStartMomentsWithValue.some(x =>
                    state.course?.startMoments?.some(sm => sm.externalId === x)
                )
            )
        },
        showHighestStartmomentPriceWarning(_, getters) {
            const selectedStartmomentPrices = getters.selectedStartMoments?.map(ssm => ssm.priceInfo.totalPrice)
            return getters.selectedStartMoments?.length > 1 && Math.min(...selectedStartmomentPrices) !== Math.max(...selectedStartmomentPrices)
        },
        courseAccessErrorMessage(state) {
            return state.courseAccessErrorMessage
        }
    },
    mutations: {
        resetState(state) {
            state.agreesToShare = undefined
            state.copiedEnrollment = null
            state.enrollmentType = null
            state.isError = false
            state.isFinished = false
            state.course = null
            state.courseAssortmentId = null
            state.diplomaType = ''
            state.courseTitle = ''
            state.isELearning = false
            state.isFetchingCourse = false
            state.isFetchingCoursePrice = false
            state.isFetchingCountries = false
            state.isFetchingTeamLeaders = false
            state.isFetchingRejectionReasons = false
            state.isFetchingUsers = false
            state.isUserFilterDisabled = false
            state.isFetchingTeamleaders = false
            state.isFetchingEnrollmentsForCourse = false
            state.isRejectingEnrollment = false
            state.isCheckingApprovalRequired = false
            state.isSendingEnrollments = false
            state.isFetchingStudyContractTemplates = false
            state.isFetchingSecondApprovers = false
            state.isFetchingStudyContractTemplateDocument = false
            state.isCheckingUserAccess = false
            state.userCanEnroll = true
            state.courseAccessErrorMessage = null
            state.willUpdateUserData = false
            state.users = []
            state.teamleadersPerCompany = {}
            state.approvalRequired = false
            state.isSendingEnrollment = false
            state.currentStepCode = ''
            state.studyContractOptions = []
            state.enrollmentsForCourse = []
            state.billingAddressOption = billingAddressOptions.MYSELF
            state.studyContractTemplateDocument = null
            state.countries = [{ displayValue: 'Nederland', value: 'NL' }]
            state.teamLeaders = []
            state.rejectionReasons = []
            state.enrollReasons = []
            state.prices = null
            state.selectedTeamLeaderEmail = null
            state.useAlternativeCostCenter = false
            state.formData = {
                selectedStartMomentIds: [],
                firstName: null,
                middleName: null,
                lastName: null,
                birthday: null,
                birthplace: null,
                emailAddress: null,
                telephone: null,
                postalCode: null,
                number: null,
                numberAdditive: null,
                street: null,
                city: null,
                country: null,
                assignedApproverId: null,
                students: [],
                selectedTeamleaderPerCompany: {},
                costCenter: null,
                purchaseOrderNumber: null,
                studyContractTemplateId: null,
                secondApproverId: null,
                isStartmomentSelectionEnabled: true,
                isSubsidized: false,
                enrollReasonCode: null,
                enrollReasonMessage: null,
                billingAddress: {
                    companyName: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    emailAddress: null,
                    number: null,
                    numberAdditive: null,
                    postalCode: null,
                    city: null,
                    country: null,
                    phoneNumber: null,
                    street: null
                },
                budgets: [],
                keyAccountId: null
            }
            state.selectedStudentsFilters.forEach(ssf => {
                ssf.value = ''
            })
            state.selectedStudentsPagination.pageSize = 20
            state.selectedStudentsPagination.currentPage = 1
            state.selectedStudentsPagination.totalCount = 0
            state.selectedStudentsPagination.totalPages = 0
            state.errorMessage = ''
            state.keyAccounts = []
            state.selectedLearningResultOptions = null
            state.learningResultSharingType = null
            state.selectedStartMoments = []
        },
        setAgreesToShare(state, agreesToShare) {
            state.agreesToShare = agreesToShare
        },
        setCopiedEnrollment(state, copiedEnrollment) {
            state.copiedEnrollment = copiedEnrollment
        },
        setLearningResultSharingType(state, learningResultSharingType) {
            state.learningResultSharingType = learningResultSharingType
        },
        toggleSelectedLearningResultOption(state, { optionKey, isOptionChecked }) {
            state.selectedLearningResultOptions[optionKey] = isOptionChecked
        },
        setSelectedLearningResultOptions(state, selectedLearningResultOptions) {
            state.selectedLearningResultOptions = selectedLearningResultOptions ? { ...selectedLearningResultOptions } : null
        },
        setUseAlternativeCostCenter(state, useAlternativeCostCenter) {
            state.useAlternativeCostCenter = useAlternativeCostCenter
        },
        setKeyAccounts(state, keyAccounts) {
            state.keyAccounts = keyAccounts
        },
        setErrorMessage(state, errorMessage) {
            state.errorMessage = errorMessage
        },
        setFormData(state, enrollmentData) {
            state.formData = { ...state.formData, ...enrollmentData }
        },
        setEnrollmentType(state, enrollmentType) {
            state.enrollmentType = enrollmentType ?? enrollmentTypes.STUDENT
        },
        setSelectedTeamLeaderEmail(state, selectedTeamLeaderEmail) {
            state.selectedTeamLeaderEmail = selectedTeamLeaderEmail
        },
        setAssignedApproverId(state, assignedApproverId) {
            state.formData.assignedApproverId = assignedApproverId
        },
        setEnrollReasonCode(state, enrollReasonCode) {
            state.formData.enrollReasonCode = enrollReasonCode
        },
        setEnrollReasonMessage(state, enrollReasonMessage) {
            state.formData.enrollReasonMessage = enrollReasonMessage
        },
        setCourse(state, course) {
            state.course = course
        },
        setUserBudgets(state, budgets) {
            state.formData.budgets = budgets?.map(budget => {
                return {
                    name: budget.name,
                    endDate: budget.endDate,
                    currentAmount: budget.currentAmount,
                    approvalRequired: budget.approvalRequired,
                    selectedAmount: null,
                    id: budget.id
                }
            })
        },
        setPotentialTeamLeaders(state, potentialTeamLeaders) {
            state.potentialTeamLeaders = potentialTeamLeaders
        },
        setIsError(state, isError) {
            state.isError = isError
        },
        setDiplomaType(state, diplomaType) {
            state.diplomaType = diplomaType
        },
        setCourseTitle(state, courseTitle) {
            state.courseTitle = courseTitle
        },
        setCourseAssortment(state, courseAssortmentId) {
            state.courseAssortmentId = courseAssortmentId
        },
        setIsELearning(state, isELearning) {
            state.isELearning = isELearning
        },
        setIsFetchingCourse(state, isFetchingCourse) {
            state.isFetchingCourse = isFetchingCourse
        },
        setIsFetchingCoursePrice(state, isFetchingCoursePrice) {
            state.isFetchingCoursePrice = isFetchingCoursePrice
        },
        setIsFetchingCountries(state, isFetchingCountries) {
            state.isFetchingCountries = isFetchingCountries
        },
        setIsFetchingTeamLeaders(state, isFetchingTeamLeaders) {
            state.isFetchingTeamLeaders = isFetchingTeamLeaders
        },
        setIsFetchingRejectionReasons(state, isFetchingRejectionReasons) {
            state.isFetchingRejectionReasons = isFetchingRejectionReasons
        },
        setIsFetchingStudyContractTemplates(state, isFetchingStudyContractTemplates) {
            state.isFetchingStudyContractTemplates = isFetchingStudyContractTemplates
        },
        setIsFetchingSecondApprovers(state, isFetchingSecondApprovers) {
            state.isFetchingSecondApprovers = isFetchingSecondApprovers
        },
        setIsFetchingOrder(state, isFetchingOrders) {
            state.isFetchingOrders = isFetchingOrders
        },
        setIsFetchingUsers(state, isFetchingUsers) {
            state.isFetchingUsers = isFetchingUsers
        },
        setIsFetchingTeamleaders(state, isFetchingTeamleaders) {
            state.isFetchingTeamleaders = isFetchingTeamleaders
        },
        setIsFetchingStudyContractTemplateDocument(state, isLoading) {
            state.isFetchingStudyContractTemplateDocument = isLoading
        },
        setIsFetchingEnrollmentsForCourse(state, isFetchingEnrollmentsForCourse) {
            state.isFetchingEnrollmentsForCourse = isFetchingEnrollmentsForCourse
        },
        setIsSendingEnrollment(state, isSendingEnrollment) {
            state.isSendingEnrollment = isSendingEnrollment
        },
        setIsRejectingEnrollment(state, isRejectingEnrollment) {
            state.isRejectingEnrollment = isRejectingEnrollment
        },
        setIsCheckingApprovalRequired(state, isCheckingApprovalRequired) {
            state.isCheckingApprovalRequired = isCheckingApprovalRequired
        },
        setIsCheckingUserAccess(state, isCheckingUserAccess) {
            state.isCheckingUserAccess = isCheckingUserAccess
        },
        setUserAccess(state, userAccess) {
            state.userCanEnroll = userAccess
        },
        setCourseAccessErrorMessage(state, errorMessage) {
            state.courseAccessErrorMessage = errorMessage
        },
        setIsSendingEnrollments(state, isSendingEnrollments) {
            state.isSendingEnrollments = isSendingEnrollments
        },
        setCurrentStepCode(state, currentStep) {
            state.currentStepCode = currentStep
        },
        setSelectedStartMoment(state, startMoment) {
            const startMomentToAdd = startMomentHelper.mapSelectedStartMoment(startMoment, state.formData.selectedStartMomentIds)

            state.formData.selectedStartMomentIds.push(startMomentToAdd.startMomentId)
            state.selectedStartMoments.push(startMomentToAdd)
        },
        setApprovalRequired(state, approvalRequired) {
            state.approvalRequired = approvalRequired
        },
        setBillingAddressOption(state, option) {
            state.billingAddressOption = option
        },
        clearSelectedStartMoment(state, startMomentIndex) {
            state.formData.selectedStartMomentIds.splice(startMomentIndex, 1)
            state.selectedStartMoments.splice(startMomentIndex, 1)
        },
        clearStartMoments(state) {
            state.formData.selectedStartMomentIds = []
            state.selectedStartMoments = []
        },
        setUser(state, user) {
            state.formData.firstName = user.firstName
            state.formData.middleName = user.middleName
            state.formData.lastName = user.lastName
            state.formData.birthday = user.birthday
            state.formData.birthplace = user.birthplace
            state.formData.emailAddress = user.emailAddress
            state.formData.telephone = user.telephone
            state.formData.postalCode = user.postalCode
            state.formData.number = user.number
            state.formData.numberAdditive = user.numberAdditive
            state.formData.street = user.street
            state.formData.city = user.city
            state.formData.country = user.country ?? 'NL'
            state.formData.billingAddress.country = user.country
        },
        setCountries(state, countries) {
            state.countries = countries
        },
        setSteps(state, newSteps) {
            state.steps = newSteps
        },
        setTeamLeaders(state, teamLeaders) {
            state.teamLeaders = teamLeaders
        },
        setRejectionReasons(state, rejectionReasons) {
            state.rejectionReasons = rejectionReasons
        },
        setUsers(state, users) {
            state.users = users
        },
        setTeamleadersPerCompany(state, teamleadersPerCompany) {
            state.teamleadersPerCompany = teamleadersPerCompany
        },
        addStudent(state, student) {
            state.formData.students.push(student)
        },
        removeStudent(state, studentId) {
            state.formData.students = state.formData.students.filter(
                student => student.userId !== studentId
            )
        },
        removeStudents(state) {
            state.formData.students = []
        },
        selectCompanyTeamleader(state, { user, companyId }) {
            state.formData.selectedTeamleaderPerCompany = {
                ...state.formData.selectedTeamleaderPerCompany,
                ...{
                    [companyId]: {
                        userId: user.userId,
                        fullName: user.fullName,
                        emailAddress: user.emailAddress
                    }
                }
            }
        },
        setSelectedStudentsFilter(state, { value, id }) {
            state.selectedStudentsFilters.forEach(ssf => {
                if (ssf.id === id) ssf.value = value
            })
        },
        setSelectedPEStatusFilter(state, values) {
            state.selectedPEStatusFilter = values
        },
        clearSelectedStudentsFilters(state) {
            state.selectedStudentsFilters.forEach(ssf => {
                ssf.value = ''
            })
            state.selectedPEStatusFilter = []
        },
        setSelectedStudentsPaginationPageSize(state, pageSize) {
            state.selectedStudentsPagination.pageSize = pageSize
        },
        setSelectedStudentsPaginationCurrentPage(state, currentPage) {
            state.selectedStudentsPagination.currentPage = currentPage
        },
        setSelectedStudentsPaginationTotalCount(state, totalCount) {
            state.selectedStudentsPagination.totalCount = totalCount
            state.selectedStudentsPagination.totalPages = Math.ceil(
                totalCount / state.selectedStudentsPagination.pageSize
            )
        },
        setIsFinished(state, isFinished) {
            state.isFinished = isFinished
        },
        setCoursePrice(state, prices) {
            state.prices = prices
        },
        setNextStep(state, stepArray) {
            const currentStepIndex = stepArray.findIndex(i => i.code === state.currentStepCode)
            const nextStepCode = stepArray[currentStepIndex + 1]
                ? stepArray[currentStepIndex + 1].code
                : state.currentStepCode
            state.currentStepCode = nextStepCode
        },
        setPreviousStep(state, stepArray) {
            const currentStepIndex = stepArray.findIndex(i => i.code === state.currentStepCode)
            const previousStepCode = stepArray[currentStepIndex - 1]
                ? stepArray[currentStepIndex - 1].code
                : state.currentStepCode
            state.currentStepCode = previousStepCode
        },
        setStudyContractTemplateDocument(state, studyContractTemplateDocument) {
            state.studyContractTemplateDocument = studyContractTemplateDocument
        },
        setEnrollmentsForCourse(state, enrollments) {
            state.enrollmentsForCourse = enrollments
        },
        setSecondApproverId(state, secondApproverId) {
            state.formData.secondApproverId = secondApproverId
        },
        setWillUpdateUserData(state, willUpdateUserData) {
            state.willUpdateUserData = willUpdateUserData
        },
        setIsFetchingEnrollment(state, isFetchingEnrollment) {
            state.isFetchingEnrollment = isFetchingEnrollment
        },
        setEnrollment(state, enrollment) {
            state.enrollment = enrollment
        },
        setEnrollReasons(state, reasons) {
            state.enrollReasons = reasons
        },
        setIsUserFilterDisabled(state, isUserFilterDisabled) {
            state.isUserFilterDisabled = isUserFilterDisabled
        }
    },
    actions: {
        nextStep({ commit, getters }) {
            commit('setNextStep', getters.totalSteps)
        },
        previousStep({ commit, getters }) {
            commit('setPreviousStep', getters.totalSteps)
        },
        async getCourse({ commit, state, getters, dispatch, rootGetters }, id) {
            try {
                commit('setIsFetchingCourse', true)

                const response = await courseClient.getCourse(id)

                await commit('setCourse', response)

                // If we're not in an enrollment step (aka enrollment type is null), then we just need the course information, nothing else.
                if (!getters.enrollmentType) return response

                // for team leader, when going back may need to clear existing selected startmoments
                if (state.course?.id !== response?.id) await commit('clearStartMoments')

                await commit('setDiplomaType', response?.diplomaType)
                await commit('setCourseTitle', response?.name)

                if (getters.isSelfPlannedCourse) commit('setIsELearning', true)
                else commit('setIsELearning', false)

                const finalStartMomentDate = rootGetters['accountModule/finalStartMomentDate']
                const promises = [dispatch('checkUserAccess', !!finalStartMomentDate)]

                if (!state.course?.startMoments?.length)
                    promises.push(dispatch('checkApprovalRequired'))

                await Promise.all(promises)

                return response
            } catch (error) {
                commit('setIsError', true)
                throw new Error(error)
            } finally {
                commit('setIsFetchingCourse', false)
            }
        },
        async getCoursePrice({ commit, state, getters }) {
            try {
                commit('setIsFetchingCoursePrice', true)
                let priceInfo
                // Check if start moments are chosen on enrollment. If so fetch the highest price of the chosen startmoments else the normal way from course getPrice
                if (getters.selectedStartMoments?.length) {
                    const highestPricedStartmoment = getters.selectedStartMoments.reduce((previous, current) => {
                        return previous.priceInfo.totalPrice > current.priceInfo.totalPrice ? previous : current
                    })

                    priceInfo = highestPricedStartmoment.priceInfo
                }
                else {
                    priceInfo = state.prices ? state.prices : await courseClient.getPrice(state.course?.id, null, state.courseAssortmentId)
                }

                commit('setCoursePrice', priceInfo)
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingCoursePrice', false)
            }
        },
        async getCountries({ commit }) {
            try {
                commit('setIsFetchingCountries', true)

                const response = await userClient.getCountries()

                const mappedCountries = response.map(country => {
                    return {
                        value: country.value,
                        displayText: country.displayValue
                    }
                })

                commit('setCountries', mappedCountries)
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingCountries', false)
            }
        },
        async searchTeamleader({ commit, rootState }, val) {
            try {
                commit('setIsFetchingTeamLeaders', true)
                // Cancel previous pending request
                userClient.cancelFetchSearch()

                const filters = [
                    {
                        keys: 'user/accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: rootState?.userModule?.user?.accountId
                    },
                    {
                        keys: [
                            'user/firstName',
                            'user/middleName',
                            'user/lastName',
                            'user/emailAddress'
                        ],
                        operator: ODataFilterOperations.CONTAINS,
                        value: val,
                        keyCombination: 'or'
                    },
                    {
                        keys: 'companyId',
                        operator: ODataFilterOperations.EQUALS,
                        value: rootState?.userModule?.user?.companyId
                    }
                ]
                const oDataFilters = filters.map(f => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('user/firstName', 'asc')
                    .setPagination(0, 5)
                    .addFilters(oDataFilters)
                    .build()

                const response = await userClient.getCompanyTeamleaders(dataQuery)

                commit('setPotentialTeamLeaders', response?.items)
                commit('setIsFetchingTeamLeaders', false)
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    commit('setIsFetchingTeamLeaders', false)
                    throw new Error(error)
                }
            }
        },
        async checkUserAccess({ commit, state }, finalStartMomentDate) {
            if (state.enrollmentType !== enrollmentTypes.STUDENT) return

            commit('setIsCheckingUserAccess', true)

            const courseAccessStatus = await courseAccessHelper.doesUserHaveAccess(state.course?.id, state.courseAssortmentId, finalStartMomentDate)
            const hasUserAccess = courseAccessStatus === courseAccessStatuses.HAS_ACCESS
            commit('setUserAccess', hasUserAccess)
            if (!hasUserAccess) commit('setCourseAccessErrorMessage', mapCourseAccessErrorMessage(courseAccessStatus))

            commit('setIsCheckingUserAccess', false)
        },
        async checkApprovalRequired({ commit, state, getters, dispatch, rootGetters }, selectedStartMomentIds) {
            try {
                commit('setIsCheckingApprovalRequired', true)

                const useTeamLeaderAssortments = getters.enrollmenType === enrollmentTypes.TEAMLEADER
                const isCourseLinkedToProject = !!state.course?.purchaseAgreementId
                const response = await enrollmentClient.checkApprovalRequired(
                    state.course?.id,
                    state.courseAssortmentId,
                    selectedStartMomentIds,
                    null,
                    getters.selectedStudentsIds,
                    useTeamLeaderAssortments,
                    isCourseLinkedToProject
                )

                commit('setApprovalRequired', response)

                if (
                    response &&
                    state.enrollmentType === enrollmentTypes.STUDENT &&
                    !rootGetters['accountModule/selectFirstApproverWithSearchInput']
                ) {
                    dispatch('getTeamLeaders')
                }
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsCheckingApprovalRequired', false)
            }
        },
        async getTeamLeaders({ commit, rootState }) {
            try {
                commit('setIsFetchingTeamLeaders', true)

                const filters = [
                    {
                        keys: 'user/accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: rootState?.userModule?.user?.accountId
                    },
                    {
                        keys: 'companyId',
                        operator: ODataFilterOperations.EQUALS,
                        value: rootState?.userModule?.user?.companyId
                    }
                ]

                const oDataFilters = filters.map(f => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('user/firstName', 'asc')
                    .setPagination(0, 9999)
                    .addFilters(oDataFilters)
                    .build()

                const response = await userClient.getCompanyTeamleaders(dataQuery)

                commit('setTeamLeaders', response.items)
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingTeamLeaders', false)
            }
        },
        getUsers({ commit, state, rootGetters, rootState }) {

            const companyTeamleadersIds = rootGetters['userModule/companyTeamleadersIds'].join(', ')
            const doesUserHasIdServerAdminRole = rootGetters['userModule/doesUserHasIdServerAdminRole']

            if (!doesUserHasIdServerAdminRole && state.enrollmentType === enrollmentTypes.TEAMLEADER && companyTeamleadersIds?.length === 0) {
                commit('setUsers', [])
                commit('setSelectedStudentsPaginationTotalCount', 0)
                commit('setIsUserFilterDisabled', true)
            } else {
                commit('setIsUserFilterDisabled', false)
                commit('setIsFetchingUsers', true)
                const filters = state.selectedStudentsFilters.map(ssf => {
                    return new ODataQueryFilter(ssf.keys, ssf.operator, ssf.value, ssf.keyCombination)
                })

                const fields = [
                    'firstName',
                    'middleName',
                    'lastName',
                    'costCenter',
                    'telephone',
                    'emailAddress',
                    'birthplace',
                    'street',
                    'postalCode',
                    'numberAdditive',
                    'number',
                    'country',
                    'city',
                    'birthday',
                    'company.name',
                    'assortments',
                    'userPEStatus'
                ].join(', ')

                let dataQuery = new ODataQueryBuilder()
                    .setSort('firstName', 'asc')
                    .setPagination(
                        state.selectedStudentsPagination.pageSize *
                        (state.selectedStudentsPagination.currentPage - 1),
                        state.selectedStudentsPagination.pageSize
                    )
                    .addFilters(filters)
                    .setFields(fields)

                const peStatusFilters = PEStatusUtils.buildODataQueryFilterForAll(state.selectedPEStatusFilter)
                if (peStatusFilters) { dataQuery.addDynamicFilter(peStatusFilters) }

                if (state.enrollmentType === enrollmentTypes.TEAMLEADER) {
                    dataQuery.addFilter(
                        new ODataQueryFilter(
                            'companyId',
                            ODataFilterOperations.IN,
                            companyTeamleadersIds
                        )
                    )
                    dataQuery.addFilter(
                        new ODataQueryFilter(
                            'userId',
                            ODataFilterOperations.NOTEQUALS,
                            rootGetters['userModule/userId']
                        )
                    )
                }
                if (state.enrollmentType === enrollmentTypes.COORDINATOR) {
                    dataQuery.addFilter(
                        new ODataQueryFilter(
                            'accountId',
                            ODataFilterOperations.EQUALS,
                            rootState?.accountModule?.account?.accountId
                        )
                    )
                }
                // filter only by enabled users
                dataQuery.addFilter(
                    new ODataQueryFilter('isEnabled', ODataFilterOperations.EQUALS, true)
                )
                dataQuery = dataQuery.build()
                userClient
                    .searchUsers(dataQuery)
                    .then(response => {
                        commit('setUsers', response?.items ?? [])
                        commit('setSelectedStudentsPaginationTotalCount', response?.totalCount ?? 0)
                    })
                    .catch(error => console.error(error))
                    .then(() => {
                        commit('setIsFetchingUsers', false)
                    })
            }
        },
        getStudyContractTemplates({ commit, state }) {
            commit('setIsFetchingStudyContractTemplates', true)
            const dataQuery = new DataQueryBuilder().setPagination(0, 999).build()
            studyContractTemplateClient
                .getStudyContractTemplates(dataQuery)
                .then(response => {
                    if (response) {
                        const mappedStudyContracts = response.items.map(studyContract => {
                            return {
                                displayText: studyContract.name,
                                value: studyContract.studyContractTemplateId
                            }
                        })
                        state.studyContractOptions = [
                            { value: 'notApplicable', displayText: 'Niet van toepassing' },
                            ...mappedStudyContracts
                        ]
                    }
                })
                .catch(error => console.error(error))
                .then(() => {
                    commit('setIsFetchingStudyContractTemplates', false)
                })
        },
        fetchStudyContractTemplateDocument({ commit }, templateId) {
            commit('setIsFetchingStudyContractTemplateDocument', true)
            studyContractTemplateClient
                .getStudyContractTemplateDocument(templateId)
                .then(response => {
                    if (!response) return
                    commit('setStudyContractTemplateDocument', response?.url)
                })
                .catch(err => {
                    console.error(err)
                })
                .then(() => {
                    commit('setIsFetchingStudyContractTemplateDocument', false)
                })
        },
        getSecondApprovers({ commit, state }) {
            commit('setIsFetchingSecondApprovers', true)
            userClient
                .getSecondApprovers()
                .then(response => {
                    if (response) {
                        state.secondApproversOptions = response.map(secondApprover => {
                            const displayText = `${secondApprover.firstName || ''} 
                          ${secondApprover.middleName || ''} 
                          ${secondApprover.lastName || ''} 
                          (${secondApprover.emailAddress || '-'})`
                            return {
                                value: secondApprover.userId,
                                displayText
                            }
                        })
                    }
                })
                .catch(error => console.error(error))
                .then(() => {
                    commit('setIsFetchingSecondApprovers', false)
                })
        },
        getTeamleadersByCompanyIds({ commit, rootState }, companyIds) {
            commit('setIsFetchingTeamleaders', true)
            const filters = [
                {
                    keys: 'user/accountId',
                    operator: ODataFilterOperations.EQUALS,
                    value: rootState?.userModule?.user?.accountId
                },
                {
                    keys: 'companyId',
                    operator: ODataFilterOperations.EQUALS,
                    value: companyIds,
                    valueCombination: 'or'
                }
            ]

            const oDataFilters = filters.map(f => {
                return new ODataQueryFilter(f.keys, f.operator, f.value, null, f.valueCombination)
            })

            const dataQuery = new ODataQueryBuilder()
                .setSort('user/firstName', 'asc')
                .setPagination(0, 9999)
                .addFilters(oDataFilters)
                .build()

            userClient
                .getCompanyTeamleaders(dataQuery)
                .then(response => {
                    const teamleaders = response?.items ?? []
                    const teamleadersPerCompany = teamleaders.reduce((acc, teamleader) => {
                        // Attach teamleaders to all companies they are part of
                        teamleader.companyIds.forEach(companyId => {
                            if (!acc[companyId]) {
                                acc[companyId] = []
                            }
                            acc[companyId].push({ ...teamleader })
                        })

                        return acc
                    }, {})

                    commit('setTeamleadersPerCompany', teamleadersPerCompany)
                })
                .catch(error => console.error(error))
                .then(() => {
                    commit('setIsFetchingTeamleaders', false)
                })
        },
        async getEnrollmentRejectionReasons({ commit }, enrollmentId) {
            try {
                commit('setIsFetchingRejectionReasons', true)
                const rejectionReasons = await enrollmentClient.getEnrollmentRejectionReasons(enrollmentId)

                return rejectionReasons
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingRejectionReasons', false)
            }
        },
        async sendEnrollment({ commit, state, getters, dispatch, rootGetters }) {
            try {
                commit('setIsSendingEnrollment', true)

                const postData = {
                    phoneNumber: getGuaranteeStringValue(state.formData.telephone),
                    middleName: state.formData.middleName,
                    lastName: state.formData.lastName,
                    firstName: state.formData.firstName,
                    email: state.formData.emailAddress,
                    eduMSFirstStartMomentId: state.formData.selectedStartMomentIds[0] || null,
                    eduMSSecondStartMomentId: state.formData.selectedStartMomentIds[1] || null,
                    courseVariant: getters.courseVariant,
                    dayOfWeek: getters.courseDayOfWeek,
                    eduMSCourseId: state.course?.id,
                    birthPlace: getGuaranteeStringValue(state.formData.birthplace),
                    addressStreet: getGuaranteeStringValue(state.formData.street),
                    addressPostalCode: getGuaranteeStringValue(state.formData.postalCode),
                    addressHouseNumberAdditive: state.formData.numberAdditive,
                    addressHouseNumber: getGuaranteeIntValue(state.formData.number),
                    addressCountryCode: getGuaranteeStringValue(state.formData.country),
                    addressCity: getGuaranteeStringValue(state.formData.city),
                    birthDay: DateUtils.parseToISOString(state.formData.birthday),
                    assignedApproverId: state.formData.assignedApproverId,
                    billingAddress: state.formData.billingAddress,
                    keyAccountId: state.formData.keyAccountId,
                    assortmentId: state.courseAssortmentId,
                    enrollReasonCode: state.formData.enrollReasonCode,
                    enrollReasonMessage: state.formData.enrollReasonMessage
                }

                if (
                    rootGetters['accountModule/hasAlternativeBillingAddress'] &&
                    state.billingAddressOption === billingAddressOptions.MYSELF
                ) {
                    postData.billingAddress.firstName = postData.firstName
                    postData.billingAddress.middleName = postData.middleName
                    postData.billingAddress.lastName = postData.lastName
                    postData.billingAddress.emailAddress = postData.email
                    postData.billingAddress.number = postData.addressHouseNumber
                    postData.billingAddress.numberAdditive = postData.addressHouseNumberAdditive
                    postData.billingAddress.postalCode = postData.addressPostalCode
                    postData.billingAddress.city = postData.addressCity
                    postData.billingAddress.country = postData.addressCountryCode
                    postData.billingAddress.phoneNumber = postData.phoneNumber
                    postData.billingAddress.street = postData.addressStreet
                }

                if (
                    rootGetters['accountModule/isBudgetEnabled'] &&
                    state.formData.budgets?.length
                ) {
                    postData.budgets = state.formData.budgets.reduce(
                        (result, { id, selectedAmount }) =>
                            +selectedAmount
                                ? result.concat({ id, amount: selectedAmount })
                                : result,
                        []
                    )
                }

                if (rootGetters['accountModule/isLearningResultEnabled']) {
                    postData.enrollmentLearningResult = {
                        ...state.selectedLearningResultOptions,
                        agreesToShare: state.agreesToShare
                    }
                }

                return await enrollmentClient.postEnrollment(postData)
            } catch (error) {
                const errorKey = Object.keys(errorMessages).find(
                    key => key === error?.response?.data
                )
                if (errorKey) {
                    commit('setErrorMessage', errorMessages[errorKey])
                } else {
                    commit('setErrorMessage', state.defaultErrorMessage)
                }

                commit('setIsError', true)
                throw new Error(error)
            } finally {
                // At the end, make sure to update the user. If this request fails, it's not a big deal. That's why it's outside of the try/catch
                commit('setIsSendingEnrollment', false)
                commit('setIsFinished', true)
                const isBirthDateInputDisplayed = !!state.formData.birthday
                if (state.willUpdateUserData) dispatch('userModule/updateUser', isBirthDateInputDisplayed, { root: true })
            }
        },
        async getEnrollReasons({ commit }) {
            try {
                const reasons = await enrollmentClient.getEnrollmentEnrollReasons()
                commit('setEnrollReasons', reasons?.map(reason => {
                    return {
                        displayText: reason.displayValue,
                        value: reason.codeName
                    }
                }))
            } catch (err) {
                throw new Error("Failed to fetch enroll reasons", err)
            }
        },
        rejectEnrollment({ commit }, { enrollmentId, rejectionReasonCode, rejectionMessage }) {
            commit('setIsRejectingEnrollment', true)
            const rejectionData = {
                enrollmentId,
                rejectionReasonCode,
                rejectionMessage
            }
            return new Promise((resolve, reject) => {
                enrollmentClient
                    .rejectEnrollment(rejectionData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .then(() => {
                        commit('setIsRejectingEnrollment', false)
                    })
            })
        },
        removeSelectedStudentsFilters({ commit, dispatch }) {
            commit('clearSelectedStudentsFilters')
            dispatch('getUsers')
        },
        setSelectedStudentsPageSize({ commit, dispatch }, pageSize) {
            commit('setSelectedStudentsPaginationCurrentPage', 1)
            commit('setSelectedStudentsPaginationPageSize', pageSize)
            dispatch('getUsers')
        },
        setSelectedStudentsCurrentPage({ commit, dispatch }, currentPage) {
            commit('setSelectedStudentsPaginationCurrentPage', currentPage)
            dispatch('getUsers')
        },
        async sendEnrollments({ commit, state, getters, rootState, rootGetters }) {
            commit('setIsSendingEnrollments', true)

            const enrollments = state.formData.students.map(student => {
                const unverifiedEnrollmentRequest = {
                    userId: student.userId,
                    phoneNumber: getGuaranteeStringValue(student.telephone),
                    middleName: student.middleName,
                    lastName: student.lastName,
                    firstName: student.firstName,
                    email: student.emailAddress,
                    birthPlace: getGuaranteeStringValue(student.birthplace),
                    addressStreet: getGuaranteeStringValue(student.street),
                    addressPostalCode: getGuaranteeStringValue(student.postalCode),
                    addressHouseNumberAdditive: student.numberAdditive,
                    addressHouseNumber: getGuaranteeIntValue(student.number),
                    addressCountryCode: getGuaranteeStringValue(student.country),
                    addressCity: getGuaranteeStringValue(student.city),
                    birthDay: student.birthday,
                    eduMSFirstStartMomentId: state.formData.selectedStartMomentIds[0] || null,
                    eduMSSecondStartMomentId: state.formData.selectedStartMomentIds[1] || null,
                    assignedApproverId: null,
                    eduMSCourseId: state.course?.id,
                    courseVariant: getters.courseVariant,
                    dayOfWeek: getters.courseDayOfWeek,
                    purchaseOrderNumber: state.formData.purchaseOrderNumber,
                    costCenter: state.formData.costCenter,
                    isSubsidized: state.formData.isSubsidized,
                    studyContractTemplateId:
                        rootState?.accountModule?.accountSettings?.alternativeStudyContractFlow ||
                            state.formData.studyContractTemplateId === 'notApplicable'
                            ? null
                            : state.formData.studyContractTemplateId,
                    assignedSecondApproverId: state.formData.secondApproverId,
                    isStartmomentUpdatesEnabled: state.formData.selectedStartMomentIds?.length
                        ? state.formData.isStartmomentSelectionEnabled
                        : false,
                    keyAccountId:
                        state.formData.keyAccountId === 'notApplicable'
                            ? null
                            : state.formData.keyAccountId,
                    assortmentId: state.courseAssortmentId
                }

                if (rootGetters['accountModule/isLearningResultEnabled'] && state.enrollmentType !== enrollmentTypes.COORDINATOR) {
                    unverifiedEnrollmentRequest.enrollmentTeamLeaderLearningResult = {
                        ...state.selectedLearningResultOptions,
                        learningResultSharingType: state.learningResultSharingType
                    }
                }

                if (state.approvalRequired !== 0 && state.formData.selectedTeamleaderPerCompany?.[student.companyId]?.userId) {
                    unverifiedEnrollmentRequest.assignedApproverId = state.formData.selectedTeamleaderPerCompany[student.companyId]?.userId
                }

                return unverifiedEnrollmentRequest
            })

            const unverifiedEnrollmentsRequest = {
                enrollments,
                useAlternativeCostCenter:
                    state.enrollmenType === enrollmentTypes.TEAMLEADER
                        ? state.useAlternativeCostCenter
                        : false
            }

            if (state.enrollmentType === enrollmentTypes.TEAMLEADER) {
                unverifiedEnrollmentsRequest.isApproved = true
            }

            try {
                return await enrollmentClient.postUnverifiedEnrollments(unverifiedEnrollmentsRequest)
            } catch (error) {
                const errorKey = Object.keys(errorMessages).find(
                    key => key === error?.response?.data?.errorCodeString
                )
                if (errorKey) {
                    commit('setErrorMessage', errorMessages[errorKey])
                } else {
                    commit('setErrorMessage', state.defaultErrorMessage)
                }

                commit('setIsError', true)
                throw new Error(error)
            } finally {
                commit('setIsSendingEnrollments', false)
                commit('setIsFinished', true)
            }
        },
        getEnrollmentsForCourse({ commit, state, rootGetters }, courseId = null) {
            return new Promise((resolve, reject) => {
                commit('setIsFetchingEnrollmentsForCourse', true)
                const id = courseId || state.course?.id
                if (!id) reject(new Error('CourseId is not known.'))
                if (!rootGetters['userModule/userId']) reject(new Error('UserId is not known.'))

                const dataQuery = new DataQueryBuilder()
                    .setPagination(0, 999)
                    .addFilter(
                        new DataQueryFilter('eduMSCourseId', DataQueryFilterOperator.EQUALS, id)
                    )
                    .addFilter(
                        new DataQueryFilter(
                            'userId',
                            DataQueryFilterOperator.EQUALS,
                            rootGetters['userModule/userId']
                        )
                    )
                    .build()

                enrollmentClient
                    .getEnrollments(dataQuery)
                    .then(response => {
                        if (!response) resolve(null)

                        const enrollments = response.items
                        commit('setEnrollmentsForCourse', enrollments)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
                    .then(() => {
                        commit('setIsFetchingEnrollmentsForCourse', false)
                    })
            })
        },
        async getKeyAccounts({ commit, rootGetters }) {
            try {
                const dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 999)
                    .setSort("name", odataSortingDirections.ASC)
                    .addFilter(
                        new ODataQueryFilter('isEnabled', ODataFilterOperations.EQUALS, true)
                    )
                    .and()
                    .addFilter(
                        new ODataQueryFilter(
                            'accountId',
                            ODataFilterOperations.EQUALS,
                            rootGetters['accountModule/accountId']
                        )
                    )
                    .build()
                const keyAccountSearch = await keyAccountClient.searchKeyAccounts(dataQuery)

                commit('setKeyAccounts', keyAccountSearch?.items)
            } catch (error) {
                throw new Error(error)
            }
        },
        async getUserBudgets({ commit, rootGetters }) {
            try {
                const filters = [
                    {
                        keys: 'userId',
                        operator: ODataFilterOperations.EQUALS,
                        value: rootGetters['userModule/userId']
                    },
                    {
                        keys: 'isActive',
                        operator: ODataFilterOperations.EQUALS,
                        value: true
                    },
                    {
                        keys: 'currentAmount',
                        operator: ODataFilterOperations.GREATERTHAN,
                        value: 0
                    },
                    {
                        keys: 'startDate',
                        operator: ODataFilterOperations.LESSTHAN,
                        value: new Date()
                    }
                ]

                const oDataFilters = filters.map(f => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
                })

                const oDataQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .setSort('endDate', 'asc')
                    .addFilters(oDataFilters)
                    .and()
                    .addDynamicFilter(`endDate gt ${new Date().toISOString()} or endDate eq null`)
                    .build()

                const response = await searchBudgets(oDataQuery)

                commit('setUserBudgets', response.items)
            } catch (error) {
                throw new Error(error)
            }
        },
        async getEnrollment({ commit }, enrollmentId) {
            commit('setIsFetchingEnrollment', true)

            try {
                const enrollment = await enrollmentClient.getEnrollment(enrollmentId)
                commit('setEnrollment', enrollment)
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingEnrollment', false)
            }
        },
        async setSearchInputDefaultValue({ commit, state, dispatch }) {
            try {
                // If assigned approver is already selected fetch user to get email address
                if (!state.formData.assignedApproverId) {
                    await dispatch('setDefaultTeamLeader')
                } else {
                    commit('setIsFetchingTeamLeaders', true)
                    const dataQuery = new ODataQueryBuilder()
                        .setPagination(0, 1)
                        .addFilter(new ODataQueryFilter('userId', ODataFilterOperations.EQUALS, state.formData.assignedApproverId))
                        .setFields('emailAddress, firstName, middleName, lastName, companyTeamLeaders')
                        .build()

                    const response = await userClient.searchUsers(dataQuery)
                    commit('setPotentialTeamLeaders', response?.items)

                    const potentialTeamLeader = state.potentialTeamLeaders?.find(u => u.userId === state.formData.assignedApproverId)
                    commit('setSelectedTeamLeaderEmail', potentialTeamLeader?.emailAddress ?? null)
                }
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingTeamLeaders', false)
            }
        },
        async setDefaultTeamLeader({ commit, state, rootGetters }) {
            try {
                const defaultTeamLeaderEmail = rootGetters['userModule/defaultTeamLeaderEmail']
                const selectFirstApproverWithSearchInput = rootGetters['accountModule/selectFirstApproverWithSearchInput']
                const accountId = rootGetters['accountModule/accountId']

                if (!selectFirstApproverWithSearchInput && !state.formData.assignedApproverId) {
                    const teamLeader = state.teamLeaders.find(
                        item => item.emailAddress?.toLowerCase() === defaultTeamLeaderEmail?.toLowerCase()
                    )
                    if (teamLeader) {
                        state.formData.assignedApproverId = teamLeader.userId
                    }
                    return
                }

                if (defaultTeamLeaderEmail) {
                    commit('setIsFetchingTeamLeaders', true)
                    const dataQuery = new ODataQueryBuilder()
                        .setPagination(0, 1)
                        .addFilter(new ODataQueryFilter('emailAddress', ODataFilterOperations.EQUALS, defaultTeamLeaderEmail))
                        .and()
                        .addFilter(new ODataQueryFilter('accountId', ODataFilterOperations.EQUALS, accountId))
                        .setFields('emailAddress, firstName, middleName, lastName, companyTeamLeaders')
                        .build()

                    const response = await userClient.searchUsers(dataQuery)
                    commit('setPotentialTeamLeaders', response?.items)

                    const teamleader = response.items?.find(u => u.emailAddress?.toLowerCase() === defaultTeamLeaderEmail?.toLowerCase())
                    if (teamleader) {
                        commit('setAssignedApproverId', teamleader.userId)
                        commit('setSelectedTeamLeaderEmail', teamleader.emailAddress)
                    }
                }
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingTeamLeaders', false)
            }
        }
    }
}

export default enrollmentModule

<template>
    <div>
        <recess-textarea-input
            :value="value"
            :rows="2"
            :maxlength="maxlength"
            :label-text="labelText"
            @input="(val) => $emit('input', val)"
        />
        <small v-if="maxlength" class="u-text-small">
            {{ value?.length ?? 0 }} / {{ maxlength }}
        </small>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null
        },
        labelText: {
            type: String,
            default: null
        },
        maxlength: {
            type: Number,
            default: null
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        }
    },
    emits: ['input'],
    setup() {}
}
</script>

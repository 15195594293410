import { getOplzClient } from './apiClient'

const baseUrl = '/requests-outside-assorted-products'

const searchRequestsOutsideAssortedProducts = async oDataDto => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, oDataDto).then(response => {
        return response?.data
    })
}

const getRequestOutsideAssortedProductsById = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}`).then(response => {
        return response?.data
    })
}

const approveRequestOutsideAssortedProductsById = async id => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/approve`).then(response => {
        return response?.data
    })
}

const rejectRequestOutsideAssortedProductsById = async id => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/reject`).then(response => {
        return response?.data
    })
}

const postRequestOutsideAssortedProducts = async requestObject => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}`, requestObject).then(response => {
        return response
    })
}

export default {
    searchRequestsOutsideAssortedProducts,
    postRequestOutsideAssortedProducts,
    getRequestOutsideAssortedProductsById,
    approveRequestOutsideAssortedProductsById,
    rejectRequestOutsideAssortedProductsById
}

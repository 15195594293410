import { getAll } from '../../api/oleProvidersClient'
import { NOA } from '../../constants/oleProviderTypes'

export default {
    namespaced: true,
    state() {
        return {
            oleProviders: []
        }
    },
    getters: {
        oleProviders(state) {
            return state.oleProviders
        },
        isOleProvider(state) {
            return id => state.oleProviders
                .some(p => p.providerId?.toLowerCase() === id?.toLowerCase())
        },
        providerById(state) {
            return id => state.oleProviders
                .find(p => p.providerId?.toLowerCase() === id?.toLowerCase())
        },
        isNoaProvider(_, getters) {
            return id => {
                const provider = getters.providerById(id)
                return provider?.providerType === NOA
            }
        },
        isSubscription(_, getters) {
            return id => {
                const provider = getters.providerById(id)
                return !!provider?.supportsSubscription
            }
        },
        showProviderPrice(_, getters, __, rootGetters) {
            return course => {
                const isNoaProvider = getters.isNoaProvider(course.providerId)
                const hasNoaLicence = rootGetters['accountModule/noaCompanyLicense']
                return !isNoaProvider || hasNoaLicence || !course.showZeroPrice
            }
        }
    },
    mutations: {
        SET_PROVIDERS(state, newProviders) {
            state.oleProviders = newProviders || []
        }
    },
    actions: {
        async fetchProviders({ commit }) {
            try {
                const response = await getAll()
                commit('SET_PROVIDERS', response)
            } catch (err) {
                console.error('Error loading ole providers', err)
            }
        }
    }
}
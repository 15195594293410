const mixin = {
    data() {
        return {
            showSuccessMessage: false
        }
    },
    methods: {
        closeAndRedirectSearchPage() {
            this.showSuccessMessage = false
            this.$router.replace({ name: 'search-posts'})
        },
        closeAndRedirectDetailPage() {
            this.showSuccessMessage = false
            this.$router.replace({ name: 'post-details', params: { id: this.postId }})
        }
    }
}

export default mixin
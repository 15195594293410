import BasePerimeter from './BasePerimeter'

export default new BasePerimeter({
    purpose: 'learningResultSharingAuthorization',

    can: {
        editLearningResultSharingConsents() {
            return !(this.isCapableOfTeamLeading() || this.isAccountReporter())
        },
        consideredAsTeamLeader() {
            return this.isCapableOfTeamLeading()
        }
    }
})

const mixin = {
    methods: {
        getLeftoverQueryParams(queryParams) {
            if (this.$router && queryParams && queryParams instanceof Array) {
                const newQueryParams = {}
                Object.keys(this.$route.query)
                    .filter(key => {
                        const find = queryParams.find(keyFind => {
                            return key === keyFind
                        })
                        return !find
                    })
                    .forEach(key => {
                        newQueryParams[key] = this.$route.query[key]
                    })
                return newQueryParams
            }
            console.warn(
                '[warn] removeFromQueryString: The provided queryParams should be an array of strings.'
            )
            return {}
        }
    }
}

export default mixin

import { getOplzClient } from './apiClient'

const baseUrl = '/key-accounts'

const searchKeyAccounts = async dataQuery => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, dataQuery).then(dataQuery).then(response => {
        return response?.data
    })
}

export default {
    searchKeyAccounts
}

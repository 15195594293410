<template>
  <fieldset v-if="mLearningResult">
    <legend v-if="showLabel" data-test="display-name" class="u-textcolor-primary font-weight-bold pb-2">{{ mLearningResult.displayName }}</legend>

    <recess-checkbox-input
      v-for="option in mLearningResult.options"
      :key="option.key"
      :label-text="option.displayName"
      :default-checked="isChecked(option.key)"
      :disabled="!isAllowedToUpdateOption(option.key) || !editable"
      class="pb-2 font-weight-normal"
      :data-test="`learning-result-checkbox-${option.key}`"
      @input="isChecked => setOption(option.key, isChecked)"
    />
  </fieldset>
</template>

<script>
import { ref } from 'vue'
import learningResultSharingTypes from '../../../constants/learningResultSharingTypes'
import learningResultSharingOptionsTypes from "../../../constants/learningResultSharingOptionsTypes"

export default {
    props: {
        learningResult: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            default: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        defaultOptions: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, { emit }) {
        const unauthorizedKeys = [ learningResultSharingTypes.LEGAL.key ] 

        const unauthorizedFields = [ 
            learningResultSharingOptionsTypes.LEGAL.PASSEDEXAMCONSENT, 
            learningResultSharingOptionsTypes.LEGAL.PROGRESSPERCENTAGECONSENT,
            learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PASSEDEXAMCONSENT,
            learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PROGRESSPERCENTAGECONSENT  
        ]
        const mLearningResult = ref(props.learningResult)
        
        const isAllowedToUpdateOption = optionKey =>  !(unauthorizedKeys.includes(mLearningResult.value?.key) && unauthorizedFields.includes(optionKey))

        const isChecked = option => props.defaultOptions?.[option]

        const setOption = (optionKey, isOptionChecked) => {
           
            if(!isAllowedToUpdateOption(optionKey)) return

            emit('input', { optionKey, isOptionChecked })
        }

        return {
            mLearningResult,
            isChecked,
            setOption,
            isAllowedToUpdateOption
        }
    }
}
</script>
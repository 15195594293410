<template>
  <div>
    <lightbox-component
      :is-visible="showCreateLearningPathModal && $isAllowed('displayLearningPath')"
      @close="closeLearningPathModal"
    >
      <h3>Maak een nieuw leerpad aan</h3>

      <div class="row">
        <div class="col-12 col-md-8">
          <!-- body -->
          <recess-input
            v-model="formData.title"
            :has-error="$v.formData.title.$error"
            :error-message="$v.formData.title.$error ? 'De naam van het leerpad is verplicht.' : ''"
            class="mb-3"
            data-test="lp-title-input"
            label-text="Geef uw leerpad een naam *"
          />

          <recess-date-picker
            v-model="formData.endDate"
            placeholder="dd-mm-jjjj"
            data-test="lp-end-date-input"
            class="mb-3"
            :error-message="$v.formData.endDate.$invalid ? 'Einddatum heeft geen geldige waarde' : ''"
            label-text="Geef een einddatum op (optioneel)"
          />
      
          <recess-select 
            v-if="icons"
            v-model="selectedIcon"
            label-text="Selecteer een afbeelding"
            class="mb-3"
            :options="icons"
          />

          <recess-textarea-input
            v-model="formData.description"
            data-test="lp-description-input"
            class="mb-3"
            label-text="Geef een korte omschrijving van het leerpad (doel, thema) in maximaal 500 tekens"
          />

          <div
            v-if="!isPersonalLearningPath"
            class="mb-3 d-flex flex-column"
          >
            <!-- TODO: For now only coordinator can set OnlyManageableByTheCreator, 
                remove this check when implementing this story https://evident-digital.atlassian.net/browse/OPLZ-19345 -->
            <recess-toggle-input
              v-if="isCoordinator"
              v-model="formData.onlyManageableByTheCreator"
              class="pb-2"
              data-test="only-manageable-by-creator-input"
            >
              <template #label>
                <div class="d-flex align-items-center">
                  <span class="mr-2">Alleen aan te passen door mijzelf</span>
                  <recess-tooltip>
                    <template #content>
                      <span class="d-block u-max-width-500">
                        Het leerpad is standaard alleen aanpasbaar door u zelf. Door deze setting te de-activeren kunnen collega's, met dezelfde rol als u, 
                        uw leerpad ook bewerken (bijv. indien u coordinator bent, kan een andere coordinator het leerpad aanpassen).
                      </span>
                    </template>
                  </recess-tooltip>
                </div>
              </template>
            </recess-toggle-input>
            <recess-toggle-input
              v-model="formData.hasObligatedOrder"
              label-text="Verplichte volgorde"
              data-test="obligated-order"
            />
          </div>
        </div>
        <div class="col-12 col-md-4 mb-3 mb-md-0 d-flex justify-content-center align-items-center ">
          <transition
            name="fade"
            mode="out-in"
          >
            <img
              v-if="selectedIcon"
              alt="Learning path icon"
              class="c-learning-path__icon"
              :src="selectedIcon"
            />
          </transition>
        </div>
      </div>

      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
          is-fixed
        />
      </transition>
      <div class="d-flex justify-content-end">
        <recess-button
          title="Bewaar leerpad"
          variant="primary"
          data-test="submit-lp-creation-btn"
          class="col-12 col-sm-auto"
          @click="createLearningPath"
        />
      </div>
      <recess-alert
        v-if="showErrorMessage"
        type="error"
        text="Er is iets misgegaan. Probeer het later nog een keer."
        class="mt-3"
      />
    </lightbox-component>
    <recess-modal
      :show-modal="showOptionsModal"
      @close="closeOptionsModal"
    >
      <div
        slot="modal-body"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <p>Succesvol leerpad gemaakt!</p>
        <div>
          <recess-button
            title="Terug naar leerpaden"
            variant="primary"
            class="c-learning-path__options-button mb-2"
            @click="closeOptionsModal"
          />
          <recess-button
            title="Zoek cursus"
            variant="primary"
            class="c-learning-path__options-button ml-2"
            @click="toCourseSearchPage"
          />
        </div>
      </div>
    </recess-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../../molecules/LightboxComponent/LightboxComponent.vue'
import { createLearningPath } from '../../../api/learningPathClient'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import { myTeamTabs, myDevelopmentTabs } from '../../../constants/tabNames'
import { isFuture } from '../../../modules/validationsHelper'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import DateUtils from '../../../utils/DateUtils'

export default {
    name: "CreateLearningPath",
    perimeters: [learningPathPerimeter, featuresPerimeter],
    components: {
        LoaderComponent,
        LightboxComponent
    },
    validations() {
        return {
            formData: {
                title: { required },
                endDate: { isFuture }
            }
        }
    },
    data() {
        return {
            isLoading: false,
            showErrorMessage: false,
            showCreateLearningPathModal: false,
            formData: {
                title: null,
                endDate: null,
                description: null,
                hasObligatedOrder: false,
                onlyManageableByTheCreator: true
            },
            icons: null,
            selectedIcon: null,
            showOptionsModal: false
        }
    },
    computed:  {
        ...mapGetters('userModule', ['userId', 'isCoordinator']),
        isPersonalLearningPath() {
            return !this.$isAllowed('createLearningPathAsManagement') || this.$route?.params?.isPersonal
        }
    },
    watch: {
        $route() {
            this.setModalVisibility()
        }
    },
    created() {
        this.setModalVisibility()
        this.getIcons()
    },
    methods: {
        setModalVisibility() {
            if (this.$route?.query?.open === 'nieuw-leerpad') {
                this.showCreateLearningPathModal = true
                this.formData = {
                    title: null,
                    endDate: null,
                    description: null,
                    hasObligatedOrder: false,
                    onlyManageableByTheCreator: true
                }
            }
        },
        getIcons() {
            // Get learning path icons context
            const staticIcons = require.context('../../../assets/images/learningPath')

            // Set the icons options and push empty option
            this.icons = [{displayText: "", value: null}, ...staticIcons.keys().map(key =>{
                return {
                    // Extract the name of the icon as the key value contain the absolute path like /name.png
                    displayText: key.substring(key.lastIndexOf("/") + 1, key.lastIndexOf(".")),
                    value: staticIcons(key)
                }
            })]
        },
        closeLearningPathModal() {
            this.showCreationForm = true
            this.showCreateLearningPathModal = false
            // If we are on the course page keep the courseId in the query
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    open: undefined,
                    course: this.$route.name === 'course' ? this.$route.query.course : undefined
                }
            })
            this.selectedIcon = null
        },
        closeOptionsModal() {
            this.showOptionsModal = false
            this.$emit('close')
        },
        async createLearningPath() {
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }

                this.showErrorMessage = false
                this.isLoading = true

                if (this.$route.query?.course) {
                    this.formData.courseId = this.$route.query.course
                }

                // Get the icon name if is selected
                if (this.selectedIcon) {
                    this.formData.iconName = this.icons?.find(icon => icon.value === this.selectedIcon)?.displayText ?? null
                }

                this.formData.endDate = DateUtils.parseToISOString(this.formData.endDate)

                if (this.isPersonalLearningPath) {
                    this.formData.isPersonal = true
                }

                await createLearningPath(this.formData)

                // only redirect if a course id was passed
                if(this.$route.query?.course){
                    const redirectPage = this.isPersonalLearningPath ?
                        {
                            name: 'my-development',
                            query: { 'my-development-tab': myTeamTabs.LEARNINGPATHS.key }
                        } : {
                            name: 'my-team',
                            query: { 'my-team-tab': myDevelopmentTabs.LEARNINGPATHS.key }
                        }
                        
                    this.$router.replace(redirectPage)
                }
                else
                {
                    // show the button options after learning path creation
                    this.showCreateLearningPathModal = false
                    this.showOptionsModal = true
                }
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            }finally {
                this.isLoading = false
            }
        },
        toCourseSearchPage() {
            this.$router.replace({ name: 'search', params: {
                searchFromTeamAssortments: true
            } })
        }
    }
}
</script>
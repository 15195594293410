export const PENDINGAPPROVAL = 0
export const APPROVED = 1
export const REJECTED = 2
export const CANCELED = 3

export default {
    PENDINGAPPROVAL,
    APPROVED,
    REJECTED,
    CANCELED
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('recess-table',[_c('recess-table-head',[_c('recess-table-row',[_vm._l((_vm.tableHeaders),function(header){return [(_vm.tableHeaders)?_c('recess-table-head-item',{key:header.key,attrs:{"sortable":header.sortable,"sort-direction":_vm.getSortDirection(header)},nativeOn:{"click":function($event){return _vm.setSorting(header)}}},[_vm._v(" "+_vm._s(header.title)+" ")]):_vm._e()]})],2)],1),_c('recess-table-body',_vm._l((_vm.projectRequests),function(projectRequest){return _c('recess-table-row',{key:projectRequest.id},[_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[0].title}},[_vm._v(_vm._s(projectRequest.id))]),_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[1].title}},[_vm._v(_vm._s(projectRequest.name))]),_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[2].title}},[_vm._v(" "+_vm._s(_vm.formatDate(projectRequest.dateCreated))+" ")]),_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[3].title}},[_vm._v(_vm._s(projectRequest.providerName))]),_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[4].title}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(projectRequest.userFullName))]),_c('span',{staticClass:"u-textcolor-grey-dark"},[_vm._v(_vm._s(projectRequest.userEmailAddress))])])]),_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[5].title}},[_vm._v(_vm._s(projectRequest.status.description))]),_c('recess-table-cell',{attrs:{"data-label":_vm.tableHeaders[6].title}},[_c('div',[(_vm.showApprovalButton(projectRequest))?_c('recess-button',{staticClass:"col-12 col-md-auto",attrs:{"url":{
                name: 'project-request-approval',
                params: { id: projectRequest.id },
                query: { listingView: _vm.listingView }
              },"title":"Beoordelen","variant":"primary"}}):_c('recess-button',{staticClass:"col-12 col-md-auto",attrs:{"url":{
                name: 'project-request-detail',
                params: { id: projectRequest.id },
                query: { listingView: _vm.listingView }
              },"title":"Bekijk","variant":"tertiary"}})],1)])],1)}),1)],1),_c('pagination-component',{staticClass:"mt-4",attrs:{"current-page-number":_vm.currentPage,"total-pages":_vm.totalPages},on:{"goToPage":(pageIndex) => _vm.$emit('on-pagination-click', pageIndex)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import tabKeys from '../../components/organisms/CoursePage/tabKeys'
import * as courseHeadings from '../../modules/courseHeadings'

const compareCoursesModule = {
    namespaced: true,

    state() {
        return {
            coursesToCompare: [],
            courseIdsToCompare: []
        }
    },
    getters: {
        coursesToCompare: state => {
            return state.coursesToCompare
        },
        courseIdsToCompare: state => {
            return state.courseIdsToCompare
        },
        coursesToCompareCount: state => {
            return state.courseIdsToCompare.length
        }
    },
    mutations: {
        resetState(state) {
            state.coursesToCompare = []
            state.courseIdsToCompare = []
        },
        toggleCourseIdToCompare(state, course) {
            if(state.courseIdsToCompare?.find(x => x.id === course.id)) {
                state.courseIdsToCompare = state.courseIdsToCompare?.filter(x => x.id !== course.id)
            } else {
                const { id, assortmentId } = course
                state.courseIdsToCompare.push({
                    id,
                    assortmentId
                })
            }
        },
        setCoursesToCompare(state, courses) {
            // Map courses content and investment
            courses.forEach(course => {
                if(course.content) {
                    course.content = {
                        intro: courseHeadings.introContent(course.content),
                        general: courseHeadings.getContentForHeading(course.content, tabKeys.GENERAL),
                        rest: courseHeadings.restContent(course.content),
                        description: courseHeadings.descriptionContent(course.content),
                        program: courseHeadings.getContentForHeading(course.content, tabKeys.PROGRAM),
                        investment: courseHeadings.getContentForHeading(course.content, tabKeys.INVESTMENT),
                        invoiceItems: course.content.invoiceItems
                    }
                }
            })

            state.coursesToCompare = courses
        }
    }
}

export default compareCoursesModule

<template>
  <recess-modal
    class="qa-decline-unverified-enrollment"
    show-modal
    @close="$emit('close')"
  >
    <template slot="modal-title">
      <h3>Inschrijvingsverzoek afwijzen</h3>
    </template>
    <template slot="modal-body">
      <p>Geef een verplichte reden op.</p>
      <recess-select
        v-model="declineReason.code"
        :error-message="requiredErrorMessage"
        label-text="Reden voor annulering *"
        :options="enrollmentDeclineReasons"
        :prefill-with-first-option=false
      />
      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>
    </template>
    <div
      slot="modal-footer"
      class="d-flex flex-column"
    >
      <div>
        <recess-button
          class="u-position-right col-12 col-md-auto"
          title="Bevestigen"
          variant="primary"
          @click="decline"
        />
      </div>
      <recess-alert
        v-if="isDeclineError"
        :text="`${declineErrorMessage} Probeer het later nog een keer of neem contact op met een administrator.`"
        type="error"
        class="col-12 mt-3"
      />
    </div>
  </recess-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    components: {
        LoaderComponent
    },
    validations: {
        declineReason: { 
            code: { required }
        }
    },
    props: {
        showDeclineModal: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        ...mapState('verificationEnrollmentModule', [
            'isDeclineError',
            'declineErrorMessage',
            'enrollmentDeclineReasons',
            'declineReason',
            'isFetchingEnrollmentDeclineReasons',
            'isDecliningEnrollmentVerification'
        ]),
        isLoading() {
            return this.isFetchingEnrollmentDeclineReasons || this.isDecliningEnrollmentVerification
        },
        requiredErrorMessage() {
            if (this.$v.declineReason.code.$error) return 'Reden voor annulering is verplicht'
            return null
        }
    },
    created() {
        this.fetchDeclineReasons()
    },
    methods: {
        ...mapActions('verificationEnrollmentModule', ['fetchDeclineReasons', 'declineEnrollmentVerification']),
        async decline() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
            
            try {
                await this.declineEnrollmentVerification().then(() => {
                    // Disable the submit button so the user cannot try to verify again after clicking away the modal.
                    this.$emit('disable-action-buttons')
                    this.$router.push({ name: 'my-development', query: { 'my-development-tab': 'enrollments' } })
                })
            } catch (error) {
                throw new Error(error)
            }
        }
    }
}
</script>

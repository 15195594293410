export default {
    PASSEDEXAMCONSENT: {
        displayName: 'Succesvol afgerond',
        key: 'PASSEDEXAMCONSENT'
    },
    DIPLOMACONSENT: {
        displayName: "Kopie van diploma of certificaat",
        key: 'DIPLOMACONSENT'
    },
    COMPLETIONATRISKCONSENT: {
        displayName: "Signaal dat afronding onder druk staat",
        key: "COMPLETIONATRISKCONSENT"
    },
    PROGRESSPERCENTAGECONSENT: {
        displayName: "Percentage stof doorlopen",
        key: "PROGRESSPERCENTAGECONSENT"
    }
}
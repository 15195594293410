<template>
    <div v-if="$isAllowed('displayABCRequest')">
        <!-- Go back button -->
        <recess-layout-static :size="1700">
            <ArrowBackSVG />
            <recess-button
                class="u-textcolor-primary"
                title="Ga terug"
                variant="variant4"
                :url="previousRoute"
            />
        </recess-layout-static>

        <recess-divider />

        <recess-layout-static :size="1200">
            <transition name="fade" mode="out-in">
                <loader-component v-if="isLoading" overlay="white" color="primary" />
            </transition>

            <recess-divider />
            <template v-if="isIncompany">
                <h1 data-test="abc-request-form-title">
                    U vraagt een incompanyuitvoering aan op basis van een bestaande
                    ontwikkelactiviteit uit het open leer aanbod.
                </h1>
                <p data-test="abc-request-form-description-1">
                    U heeft hiervoor een groep van minimaal 8 collega's, die samen deze
                    ontwikkelactiviteit willen volgen als incompany.
                </p>
                <p data-test="abc-request-form-description-2">
                    Om de aanmelding zo goed mogelijk te laten verlopen hebben wij onderstaande
                    informatie nodig. Na ontvangst zullen wij uw aanvraag binnen 2 dagen in
                    behandeling nemen.
                </p>
            </template>
            <template v-else>
                <h1 data-test="abc-request-form-title">
                    Ik weet welke ontwikkelactiviteit ik wil volgen
                </h1>
                <p data-test="abc-request-form-description-1">
                    Staat de ontwikkelactiviteit die u zoekt niet in het leeraanbod? Dan kunt u via
                    dit formulier de door u gewenste ontwikkelactiviteit aanvragen (alle
                    ontwikkelactiviteiten in Nederland). U kunt, indien u zich dit wenst, u ook door
                    ons laten adviseren over een ontwikkelactiviteit die past bij uw wensen of
                    wijzen op een passend alternatief binnen het open aanbod.
                </p>
                <p data-test="abc-request-form-description-2">
                    Bij een groepsontwikkelactiviteit zullen we contact met u opnemen om af te
                    stemmen wat de wensen zijn zoals het reserveren van een zaal, lunch e.d. Mocht
                    er al een offerte en/of deelnemerslijst zijn, voeg deze dan toe als bijlage.
                </p>
                <p data-test="abc-request-form-description-3">
                    Om de aanmelding en financiële verwerking zo goed mogelijk te laten verlopen
                    hebben wij onderstaande informatie nodig. Na ontvangst zullen wij uw aanvraag
                    binnen 2 dagen in behandeling nemen.
                </p>
            </template>

            <h4>Uw gegevens</h4>

            <!-- Prefilled data -->
            <div class="row d-flex flex-column flex-md-row js-scroll-to-form">
                <span class="col-6 font-weight-bold mb-2 mb-md-0">Bedrijfsnaam</span>
                <span class="col-6">{{ account.name }}</span>
            </div>

            <div class="row d-flex flex-column flex-md-row">
                <span class="col-6 font-weight-bold mb-2 mb-md-0">Naam</span>
                <span class="col-6">{{ user.fullName }}</span>
            </div>

            <div v-if="user.jobName" class="row d-flex flex-column flex-md-row">
                <span class="col-6 font-weight-bold mb-2 mb-md-0">Functie</span>
                <span class="col-6">{{ user.jobName }}</span>
            </div>

            <div class="row d-flex flex-column flex-md-row">
                <span class="col-6 font-weight-bold mb-2 mb-md-0">E-mailadres</span>
                <span class="col-6">{{ user.emailAddress }}</span>
            </div>

            <recess-divider variant="xsmall" />

            <div class="row d-flex flex-column flex-md-row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Aanhef *</span>
                    <div class="d-flex flex-column">
                        <recess-radio-input
                            name="gender"
                            class="font-weight-normal"
                            value="M"
                            label-text="Dhr."
                            data-test="gender-option"
                            @change="(val) => (formData.gender = val)"
                        />
                        <recess-radio-input
                            name="gender"
                            class="font-weight-normal"
                            value="F"
                            label-text="Mevr."
                            @change="(val) => (formData.gender = val)"
                        />
                    </div>
                    <span
                        v-if="!!getErrorMessage('gender')"
                        class="u-error-text p-0 m-0"
                        data-test="error-text"
                        >{{ getErrorMessage('gender') }}</span
                    >
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Voor wie is de ontwikkelactiviteit? *</span>
                    <div class="d-flex flex-column">
                        <recess-radio-input
                            v-if="!isIncompany"
                            name="isOwnRequest"
                            class="font-weight-normal"
                            :value="true"
                            label-text="Voor mijzelf"
                            data-test="student-option"
                            @change="(val) => (formData.isOwnRequest = val)"
                        />
                        <recess-radio-input
                            :value="false"
                            :default-checked="isIncompany"
                            name="isOwnRequest"
                            class="font-weight-normal"
                            label-text="Voor mijn medewerkers/groepsaanvraag"
                            @change="(val) => (formData.isOwnRequest = val)"
                        />
                    </div>
                    <span
                        v-if="!!getErrorMessage('isOwnRequest')"
                        class="u-error-text p-0 m-0"
                        data-test="error-text"
                        >{{ getErrorMessage('isOwnRequest') }}</span
                    >
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <div class="row d-flex flex-column flex-md-row">
                <div
                    v-if="!isIncompany"
                    class="col-12 col-md-6 d-flex flex-column"
                    data-test="abc-request-offer-options"
                >
                    <div class="d-flex align-items-center mb-2">
                        <span class="font-weight-bold mr-2">Heeft u al een offerte?</span>
                        <recess-tooltip>
                            <template #content>
                                <p>
                                    Bij een groepsontwikkelactiviteit zullen we contact met u
                                    opnemen om af te stemmen wat de wensen<br />
                                    zijn zoals het reserveren van een zaal, lunch e.d. Mocht er al
                                    een offerte en/of deelnemerslijst zijn,<br />
                                    voeg deze dan toe als bijlage.
                                </p>
                            </template>
                        </recess-tooltip>
                    </div>
                    <div class="d-flex flex-column">
                        <recess-radio-input
                            name="hasQuote"
                            class="font-weight-normal"
                            :value="true"
                            label-text="Ik heb al een offerte"
                            data-test="offer-option"
                            @change="(val) => (formData.hasQuote = val)"
                        />
                        <recess-radio-input
                            name="hasQuote"
                            :value="false"
                            class="font-weight-normal"
                            label-text="Ik heb geen offerte"
                            @change="(val) => (formData.hasQuote = val)"
                        />
                    </div>
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2"
                        >Om wat voor type ontwikkelactiviteit gaat het? *</span
                    >
                    <div class="d-flex flex-column">
                        <recess-radio-input
                            name="isExistingCourse"
                            class="font-weight-normal"
                            :value="true"
                            :default-checked="isIncompany"
                            label-text="Bestaande ontwikkelactiviteit"
                            data-test="existing-course-option"
                            @change="(val) => (formData.isExistingCourse = val)"
                        />
                        <recess-radio-input
                            v-if="!isIncompany"
                            name="isExistingCourse"
                            :value="false"
                            data-test="non-existing-course-option"
                            class="font-weight-normal"
                            label-text="Maatwerk ontwikkelactiviteit"
                            @change="(val) => (formData.isExistingCourse = val)"
                        />
                    </div>
                    <span
                        v-if="!!getErrorMessage('isExistingCourse')"
                        class="u-error-text p-0 m-0"
                        data-test="error-text"
                        >{{ getErrorMessage('isExistingCourse') }}</span
                    >
                </div>
            </div>

            <recess-divider variant="xsmall" />
            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Telefoonnummer</span>
                    <recess-input
                        v-model.trim="formData.phoneNumber"
                        :error-message="getErrorMessage('phoneNumber')"
                    />
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div v-if="showTeamleaderSelection" class="col-12 col-md-6 d-flex flex-column">
                    <transition name="fade" mode="out-in">
                        <loader-component
                            v-if="isFetchingTeamLeaders"
                            custom-class="mt-3"
                            variant="small"
                            :overlay="
                                !accountSettings.selectFirstApproverWithSearchInput ? 'white' : null
                            "
                            color="primary"
                        />
                    </transition>
                    <span class="font-weight-bold mb-2"
                        >Kies een leidinggevende die deze aanvraag goed moet keuren *</span
                    >
                    <div v-if="accountSettings.selectFirstApproverWithSearchInput">
                        <recess-autocomplete
                            v-model="teamLeaderEmail"
                            data-test="team-leader-input"
                            placeholder="Naam of e-mail"
                            item-key="userId"
                            item-text="emailAddress"
                            item-sub-text="fullName"
                            :error-message="searchTeamleaderValidationError"
                            :on-query="queryTeamLeader"
                        />
                    </div>
                    <recess-select
                        v-else
                        v-model="formData.assignedApproverId"
                        :error-message="getErrorMessage('assignedApproverId')"
                        :default-value="formData.assignedApproverId"
                        :options="teamleaders"
                        class="qa-course-request__assigned-approver"
                        data-test="teamleader-input"
                    />
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <h4>Gegevens ontwikkelactiviteit leverancier</h4>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Naam leverancier *</span>
                    <recess-input
                        v-model="formData.providerName"
                        :error-message="getErrorMessage('providerName')"
                        data-test="provider-name"
                    />
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2"
                        >Internet adres van de ontwikkelactiviteit *</span
                    >
                    <recess-input
                        v-model="formData.courseUrl"
                        :error-message="getErrorMessage('courseUrl')"
                        data-test="training-internet-address"
                    />
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <div v-if="!isIncompany" class="row" data-test="abc-request-provider-contacts">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Telefoonnummer</span>
                    <recess-input
                        v-model="formData.providerPhoneNumber"
                        data-test="abc-request-provider-phone"
                        :error-message="getErrorMessage('providerPhoneNumber')"
                    />
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">E-mailadres leverancier</span>
                    <recess-input
                        v-model="formData.providerEmailAddress"
                        data-test="abc-request-provider-email"
                        :error-message="getErrorMessage('providerEmailAddress')"
                    />
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <h4>Gegevens gewenste ontwikkelactiviteit</h4>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Naam of omschrijving *</span>
                    <recess-input
                        v-model="formData.courseName"
                        :error-message="getErrorMessage('courseName')"
                        data-test="course-name"
                    />
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Startdatum *</span>
                    <recess-date-picker
                        v-model="formData.startDate"
                        :error-message="getErrorMessage('startDate')"
                        data-test="start-date"
                    />
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2">Einddatum</span>
                    <recess-date-picker
                        v-model="formData.endDate"
                        :error-message="getErrorMessage('endDate')"
                    />
                </div>

                <recess-divider
                    variant="xsmall"
                    :display-large-viewport="false"
                    :display-medium-viewport="false"
                />

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="font-weight-bold mb-2"
                        >Indien een fysieke locatie; graag locatie vermelden</span
                    >
                    <recess-input v-model="formData.physicalLocation" />
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                        <span class="font-weight-bold mr-2">Bijeenkomsten *</span>
                        <recess-tooltip>
                            <template #content>
                                <p>
                                    Noteer hier de bijeenkomsten als de ontwikkelactiviteit uit
                                    meerdere lessen bestaat. Alle data noteren s.v.p.
                                </p>
                            </template>
                        </recess-tooltip>
                    </div>
                    <recess-textarea-input
                        v-model="formData.meetings"
                        :rows="2"
                        :error-message="getErrorMessage('meetings')"
                        data-test="course-meetings"
                    />
                </div>
            </div>

            <recess-divider variant="xsmall" />

            <div class="d-flex flex-column">
                <span class="font-weight-bold mb-2">Locatie *</span>
                <div class="d-flex flex-column">
                    <recess-radio-input
                        name="location"
                        class="font-weight-normal"
                        :value="1"
                        label-text="U heeft zelf een locatie geregeld"
                        @change="(val) => (formData.courseLocation = val)"
                    />
                    <recess-radio-input
                        name="location"
                        :value="2"
                        class="font-weight-normal"
                        label-text="De opleider heeft locatie geregeld"
                        data-test="course-location"
                        @change="(val) => (formData.courseLocation = val)"
                    />
                    <recess-radio-input
                        v-if="!isIncompany"
                        name="location"
                        class="font-weight-normal"
                        :value="3"
                        label-text="Locatie is virtueel"
                        data-test="abc-request-location-virtual-option"
                        @change="(val) => (formData.courseLocation = val)"
                    />
                    <recess-radio-input
                        v-if="!isIncompany"
                        name="location"
                        :value="4"
                        class="font-weight-normal"
                        label-text="Locatie is nog niet bekend"
                        data-test="abc-request-location-not-known-option"
                        @change="(val) => (formData.courseLocation = val)"
                    />
                </div>
                <span
                    v-if="!!getErrorMessage('courseLocation')"
                    class="u-error-text p-0 m-0"
                    data-test="error-text"
                    >{{ getErrorMessage('courseLocation') }}</span
                >
            </div>

            <template v-if="!isIncompany">
                <recess-divider variant="xsmall" />

                <h4>Kosten (alle bedragen exclusief BTW)</h4>

                <recess-divider variant="xsmall" />

                <div class="row" data-test="abc-request-costs-section">
                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="font-weight-bold mb-2"
                            >ontwikkelactiviteitskosten per persoon</span
                        >
                        <recess-input
                            v-model="formData.coursePrice"
                            type="number"
                            :error-message="getErrorMessage('coursePrice')"
                        />
                    </div>

                    <recess-divider
                        variant="xsmall"
                        :display-large-viewport="false"
                        :display-medium-viewport="false"
                    />

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <div class="d-flex align-items-center mb-2">
                            <span class="font-weight-bold mr-2">Overige kosten</span>
                            <recess-tooltip>
                                <template #content>
                                    <p>
                                        B.v lesmateriaal, arrangementskosten, examen, inschrijving.
                                    </p>
                                </template>
                            </recess-tooltip>
                        </div>
                        <recess-input
                            v-model="formData.otherCost"
                            type="number"
                            :error-message="getErrorMessage('otherCost')"
                        />
                    </div>
                </div>

                <recess-divider variant="xsmall" />

                <div class="d-flex flex-column">
                    <span class="font-weight-bold mb-2">Totale kosten</span>
                    <div class="row">
                        <recess-input
                            v-model="formData.totalCost"
                            type="number"
                            class="col-12 col-md-6"
                            :error-message="getErrorMessage('totalCost')"
                        />
                    </div>
                </div>

                <recess-divider variant="xsmall" />

                <div class="d-flex flex-column" data-test="abc-request-reason-section">
                    <div class="d-flex align-items-center mb-2">
                        <span class="font-weight-bold mr-2">Reden aanvraag buiten catalogus</span>
                        <recess-tooltip>
                            <template #content>
                                <p>
                                    Veel ontwikkelactiviteiten worden aangeboden in de catalogus.
                                    Heeft u gekeken of de ontwikkelactiviteit van uw keuze hierin
                                    wordt aangeboden?
                                </p>
                            </template>
                        </recess-tooltip>
                    </div>
                    <div class="d-flex flex-column">
                        <recess-radio-input
                            name="reason"
                            class="font-weight-normal"
                            :value="1"
                            label-text="Gewenste leverancier staat niet in de catalogus"
                            @input="(val) => (formData.requestOutsideAssortedProductsReason = val)"
                        />
                        <recess-radio-input
                            name="reason"
                            :value="2"
                            class="font-weight-normal"
                            label-text="Leverancier wel bekend maar ontwikkelactiviteit staat niet in de catalogus"
                            @input="(val) => (formData.requestOutsideAssortedProductsReason = val)"
                        />
                    </div>
                </div>

                <recess-divider variant="xsmall" />

                <recess-card class="u-bg-gray-medium-light" data-test="abc-request-files-section">
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold mb-2"
                            >Voeg een deelnemerslijst toe (indien van toepassing)</span
                        >
                        <recess-file-upload
                            max-size-file="5000000"
                            :max-file-to-upload="1"
                            :allowed-extensions="allowedExtensions"
                            @upload="
                                (uploadedFile) => {
                                    if (uploadedFile.length && uploadedFile[0].structure) {
                                        formData.participantList.fileName =
                                            uploadedFile[0].structure.name
                                        formData.participantList.fileData =
                                            uploadedFile[0].base64FileString
                                    } else {
                                        formData.participantList.fileName = null
                                        formData.participantList.fileData = null
                                    }
                                }
                            "
                        />
                    </div>

                    <recess-divider variant="xsmall" />

                    <div class="d-flex flex-column">
                        <div class="d-flex align-items-center mb-2">
                            <span class="font-weight-bold mr-2"
                                >Voeg een andere bijlage toe, bijvoorbeeld een offerte (indien van
                                toepassing)</span
                            >
                            <recess-tooltip>
                                <template #content>
                                    <p>
                                        Let op: offertes en facturen dienen voorzien te zijn van een
                                        BTW specificatie, anders kunnen ze niet in behandeling
                                        worden genomen.
                                    </p>
                                </template>
                            </recess-tooltip>
                        </div>
                        <recess-file-upload
                            max-size-file="5000000"
                            :max-file-to-upload="1"
                            :allowed-extensions="allowedExtensions"
                            @upload="
                                (uploadedFile) => {
                                    if (uploadedFile.length && uploadedFile[0].structure) {
                                        formData.otherAttachment.fileName =
                                            uploadedFile[0].structure.name
                                        formData.otherAttachment.fileData =
                                            uploadedFile[0].base64FileString
                                    } else {
                                        formData.otherAttachment.fileName = null
                                        formData.otherAttachment.fileData = null
                                    }
                                }
                            "
                        />
                    </div>
                </recess-card>
            </template>
            <recess-divider />

            <div class="d-flex justify-content-end">
                <recess-button
                    variant="primary"
                    title="Aanvragen"
                    data-test="submit-button"
                    @click="sendRequest()"
                />
            </div>
            <recess-alert
                v-if="showErrorMessage"
                type="error"
                text="Er is een fout opgetreden. Probeer het later opnieuw."
                class="mt-4"
            />

            <recess-divider />

            <!-- Modal for the 'volgen' confirmation message -->
            <recess-modal
                modal-size="small"
                :show-modal="showSuccessMessage"
                @close="() => $router.replace(previousRoute)"
            >
                <div slot="modal-body" class="d-flex justify-content-center">
                    Uw aanvraag is gelukt.
                </div>
                <div slot="modal-footer" class="d-flex justify-content-center">
                    <recess-button
                        class="qa-close-modal-button"
                        variant="primary"
                        title="Sluit venster"
                        :url="previousRoute"
                    />
                </div>
            </recess-modal>
        </recess-layout-static>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, decimal, requiredIf } from 'vuelidate/lib/validators'
import { scrollTo } from 'vue-scrollto'
import axios from 'axios'
import userClient from '../../../api/userClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import requestsOutsideAssortedProductsClient from '../../../api/requestOutsideAssortedProductsClient'
import {
    validationMessages,
    isValidDate,
    isValidPhoneNumber,
    isValidEmail
} from '../../../modules/validationsHelper'
import ArrowBackSVG from '../../organisms/IconComponents/ArrowBackSVG.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'

export default {
    name: 'RequestOutsideAssortedProducts',
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent,
        ArrowBackSVG
    },
    props: {
        course: {
            type: Object,
            default: null
        }
    },
    validations() {
        return {
            formData: {
                courseName: { required },
                gender: { required },
                isOwnRequest: { required },
                isExistingCourse: { required },
                coursePrice: { decimal },
                otherCost: { decimal },
                totalCost: { decimal },
                providerName: { required },
                courseUrl: { required },
                startDate: { required, isValidDate },
                endDate: { isValidDate },
                meetings: { required },
                courseLocation: { required },
                assignedApproverId: {
                    required: requiredIf(() => {
                        return (
                            this.accountSettings.abcApprovalRequired && this.showTeamleaderSelection
                        )
                    })
                },
                providerPhoneNumber: { isValidPhoneNumber },
                providerEmailAddress: { isValidEmail },
                phoneNumber: { isValidPhoneNumber }
            },
            teamLeaderEmail: {
                required: requiredIf(
                    () =>
                        this.showTeamleaderSelection &&
                        this.accountSettings?.selectFirstApproverWithSearchInput
                ),
                isMatchingTeamleaderEmail() {
                    if (
                        this.showTeamleaderSelection &&
                        this.accountSettings?.selectFirstApproverWithSearchInput
                    ) {
                        return this.isMatchingTeamleaderEmail
                    }
                    return true
                }
            }
        }
    },
    data() {
        return {
            formData: {
                gender: null,
                phoneNumber: null,
                isOwnRequest: this.course ? false : null,
                hasQuote: null,
                isExistingCourse: this.course ? false : null,
                providerPhoneNumber: null,
                providerEmailAddress: null,
                courseName: this.course?.name ?? null,
                courseUrl: this.course?.url ?? null,
                coursePrice: null,
                otherCost: null,
                totalCost: null,
                requestOutsideAssortedProductsReason: null,
                providerName: this.course?.providerName ?? null,
                startDate: null,
                endDate: null,
                courseLocation: null,
                physicalLocation: null,
                meetings: null,
                assignedApproverId: null,
                participantList: {
                    fileName: null,
                    fileData: null
                },
                otherAttachment: {
                    fileName: null,
                    fileData: null
                }
            },
            teamleaders: [],
            isLoading: false,
            showErrorMessage: false,
            showSuccessMessage: false,
            allowedExtensions: [
                'pdf',
                'doc',
                'docx',
                'ppt',
                'pptx',
                'bmp',
                'gif',
                'jpg',
                'jpeg',
                'png',
                'svg',
                'rar',
                'zip',
                'txt',
                'rtf',
                'csv',
                'eml',
                'tiff'
            ],
            validationMessages,
            teamLeaderEmail: null,
            potentialTeamLeaders: [],
            isFetchingTeamLeaders: false,
            userHasTeamleaders: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapState('accountModule', ['account', 'accountSettings']),
        isMatchingTeamleaderEmail() {
            return this.potentialTeamLeaders?.some((x) => x.emailAddress === this.teamLeaderEmail)
        },
        showTeamleaderSelection() {
            return this.accountSettings?.abcApprovalRequired && this.userHasTeamleaders
        },
        searchTeamleaderValidationError() {
            if (
                this.teamLeaderEmail?.length >= 3 &&
                !this.isMatchingTeamleaderEmail &&
                !this.isFetchingTeamLeaders &&
                !this.potentialTeamLeaders.length
            ) {
                return 'Er zijn geen resultaten gevonden'
            }

            if (
                (!this.$v.teamLeaderEmail.required || this.teamLeaderEmail?.length > 0) &&
                this.$v.teamLeaderEmail.$error
            ) {
                return 'Leidinggevende is verplicht'
            }

            if (
                this.teamLeaderEmail?.length >= 3 &&
                !this.isFetchingTeamLeaders &&
                !this.isMatchingTeamleaderEmail
            ) {
                return 'Kies een leidinggevende uit de lijst'
            }

            return ''
        },
        isIncompany() {
            return !!this.course && this.$isAllowed('displayABCRequestIncompany')
        },
        previousRoute() {
            return this.course
                ? {
                      name: 'course',
                      query: {
                          course: this.course.id,
                          assortment: this.course.assortmentId,
                          'course-page-tab': 'planning'
                      }
                  }
                : { name: 'search' }
        }
    },
    watch: {
        async teamLeaderEmail(newVal) {
            if (this.isMatchingTeamleaderEmail) {
                const teamleader = this.potentialTeamLeaders?.find((u) => u.emailAddress === newVal)
                if (teamleader) {
                    // Set assigned approver id
                    this.formData.assignedApproverId = teamleader.userId
                }
            }
        }
    },
    async created() {
        if (this.accountSettings?.abcApprovalRequired) {
            if (this.accountSettings?.selectFirstApproverWithSearchInput) {
                // We check if at least there is one teamleader
                this.isFetchingTeamLeaders = true
                const filters = [
                    {
                        keys: 'user/accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.account.accountId
                    },
                    {
                        keys: 'companyId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.user.companyId
                    }
                ]

                const oDataFilters = filters.map((f) => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('user/firstName', 'asc')
                    .setPagination(0, 1)
                    .addFilters(oDataFilters)
                    .build()

                const response = await userClient.getCompanyTeamleaders(dataQuery)

                if (response?.totalCount > 0) {
                    this.userHasTeamleaders = true
                }
                this.isFetchingTeamLeaders = false
            } else {
                // Fetch teamleaders
                await this.getTeamLeaders()
            }
        }
    },
    methods: {
        async getTeamLeaders() {
            try {
                this.isFetchingTeamLeaders = true
                const filters = [
                    {
                        keys: 'user/accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.account.accountId
                    },
                    {
                        keys: 'companyId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.user.companyId
                    }
                ]

                const oDataFilters = filters.map((f) => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('user/firstName', 'asc')
                    .setPagination(0, 9999)
                    .addFilters(oDataFilters)
                    .build()

                const response = await userClient.getCompanyTeamleaders(dataQuery)

                if (response?.items?.length) {
                    this.userHasTeamleaders = true
                    // Set the options of available approvers
                    this.teamleaders = response.items.map((tl) => {
                        return {
                            value: tl.userId,
                            displayText: tl.fullName
                        }
                    })

                    // Set the default approver to the assigned teamleader of the user
                    this.formData.assignedApproverId = response.items.find(
                        (tl) => tl.emailAddress === this.user.teamLeaderEmail
                    )?.userId
                }
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingTeamLeaders = false
            }
        },

        // async searchTeamleader() {
        async queryTeamLeader(query) {
            if (!query) {
                return []
            }

            try {
                this.isFetchingTeamLeaders = true
                // Cancel previous pending request
                userClient.cancelFetchSearch()

                const filters = [
                    {
                        keys: [
                            'user/firstName',
                            'user/middleName',
                            'user/lastName',
                            'user/emailAddress'
                        ],
                        operator: ODataFilterOperations.CONTAINS,
                        value: query,
                        keyCombination: 'or'
                    },
                    {
                        keys: 'user/accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.account.accountId
                    },
                    {
                        keys: 'companyId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.user.companyId
                    }
                ]

                const oDataFilters = filters.map((f) => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('user/firstName', 'asc')
                    .setPagination(0, 5)
                    .addFilters(oDataFilters)
                    .build()

                const response = await userClient.getCompanyTeamleaders(dataQuery)
                this.potentialTeamLeaders = response?.items
                this.isFetchingTeamLeaders = false
                return this.potentialTeamLeaders
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    this.isFetchingTeamLeaders = false
                    console.error(error)
                }
                return []
            }
        },
        async sendRequest() {
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    scrollTo('.js-scroll-to-form')
                    return
                }

                this.isLoading = true
                await requestsOutsideAssortedProductsClient.postRequestOutsideAssortedProducts({
                    ...this.formData,
                    isIncompany: this.isIncompany
                })
                this.showSuccessMessage = true
                scrollTo('body')
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData[fieldName]
            const fieldValue = this.formData[fieldName]

            // Check Required
            if (fieldValidation && fieldValidation.$error && !fieldValidation.required) {
                if (fieldValidation && fieldValidation.$invalid && fieldValue) {
                    return this.validationMessages.invalid[fieldName]
                }
                return this.validationMessages.required[fieldName]
            }

            // Check Invalid
            if (fieldValidation && fieldValidation.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        }
    }
}
</script>

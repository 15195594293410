<template>
  <div class="w-100 h-100">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <div v-if="showErrorMessage">
      <recess-alert
        type="error"
        text="Er is een probleem opgetreden bij het inloggen. Probeer opnieuw in te loggen."
        class="my-auto"
      />
      <recess-button
        variant="secondary"
        title="Opnieuw proberen"
        class="mx-auto d-block mt-4"
        @click.native="returnHome()"
      />
    </div>
  </div>
</template>

<script>
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { getAppInsights } from '../../../modules/appinsights-client'
import DateUtils from '../../../utils/DateUtils'
import { getUserManager } from '../../../modules/oidc-client'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    name: 'OidcCallback',
    components: {
        LoaderComponent
    },
    data() {
        return {
            isLoading: true,
            showErrorMessage: false
        }
    },
    mounted() {
        getUserManager()
            .signinRedirectCallback()
            .then(() => {
                // If user has multiple accounts redirect to account selection page
                const redirectPath = sessionStorage.getItem('oidc_active_route')
                window.location.replace(redirectPath)
              
            })
            .catch(error => {
                // generate track trace with the id token
                if(getAppInsights()) {
                    getAppInsights().trackTrace({message: `Token authentication step failed at client utc time: ${ DateUtils.now.getTime() }. Message was: ${error.message}. Hash was: ${window.location.hash}`, severityLevel: SeverityLevel.Error})
                }
                this.isLoading = false
                this.showErrorMessage = true
                console.error(error)
            })
    },
    methods: {
        returnHome() {
            window.location.replace("/")
        }
    }
}
</script>
<template>
  <div v-if="mappedStartMoments && mappedStartMoments.length">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <start-moments-listing-table 
      :course="course"
      :show-capacity="showCapacity" 
      :start-moments="mappedStartMoments"
      :table-header="tableHeader" 
      :assortment-id="assortmentId"
      :facets="facets"
      :total-count="totalCount"
      :page-number="pageNumber"
      :page-size="pageSize"
      @facet-change="selectedFacets => setFacetsAndSearchStartMoments(selectedFacets)"
      @pagination-clicked="clickedPage => onPaginationClicked(clickedPage)"
    />

  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import useStore from '../../../composables/useStore'
import StartMomentsListingTable from '../StartMomentsListingTable/StartMomentsListingTable.vue'
import startMomentHelper from '../../../modules/startMomentHelper'

export default {
    components: {
        StartMomentsListingTable,
        LoaderComponent
    },
    perimeters: [featuresPerimeter],
    props: {
        courseId: {
            type: String,
            default: null
        },
        assortmentId: {
            type: String,
            default: null
        },
        providerId: {
            type: String,
            default: null
        },
        productType: {
            type: String,
            default: null
        },
        course: {
            type: Object,
            default: () => {}
        },
        limitIncompanyEnrollmentsAndIsIncompany: {
            type: Boolean,
            default: false
        },
        nrOfPeopleToEnroll: {
            type: [Number, null],
            default: null
        }
    },
    setup(props) {
        const store = useStore()

        const pageSize = computed(() => store.getters['startMomentsModule/top'])
        const pageNumber = computed(() => store.getters['startMomentsModule/pageNumber'])
        const totalCount = computed(() => store.getters['startMomentsModule/totalCount'])
        const facets = computed(() => store.getters['startMomentsModule/facets'])
        const startMoments = computed(() => store.getters['startMomentsModule/startMoments'])
        const mappedStartMoments = computed(() => {
            if (!startMoments.value?.length) {
                return []
            }

            return startMomentHelper.mapStartMoments(startMoments?.value, props.limitIncompanyEnrollmentsAndIsIncompany, props.productType, props.nrOfPeopleToEnroll)
        })
        const isLoading = computed(() => store.getters['startMomentsModule/isLoading'])
        const displayVatInPrices = computed(() => store.getters['accountModule/displayVatInPrices'])
        const showCapacity = computed(() =>   {    
            const showCapacityIconForAllCoursesSetting = store.getters['accountModule/showCapacityIconForAllCourses']

            return startMomentHelper.showCapacityDetail(
                showCapacityIconForAllCoursesSetting,
                props.productType
            )
        })
        const shouldFetchOrders = computed(() => {
            return showCapacity.value || props.limitIncompanyEnrollmentsAndIsIncompany
        })
        
        const setFacetsAndSearchStartMoments = async selectedFacets => {
            await store.dispatch('startMomentsModule/setFacetsAndSearchStartMoments', { selectedFacets, shouldFetchOrders: shouldFetchOrders.value })
        }

        const onPaginationClicked = async clickedPage => {
            await store.dispatch('startMomentsModule/goToPage', { pageNumber: clickedPage, shouldFetchOrders: shouldFetchOrders.value })
        }

        onMounted(async () => {
            await store.dispatch('startMomentsModule/initializeStartMomentsAndOrders',  { courseId: props.courseId, assortmentId: props.assortmentId , shouldFetchOrders: shouldFetchOrders.value })
        })

        return {
            isLoading,
            showCapacity,
            mappedStartMoments,
            displayVatInPrices,
            facets,
            totalCount,
            pageNumber,
            pageSize,
            setFacetsAndSearchStartMoments,
            onPaginationClicked
        }
        
    },
    computed: {
        tableHeader() {                    
            return startMomentHelper.getStartMomentPlanningHeader(this.showCapacity, this.displayVatInPrices, this.$isAllowed('displayPricingInformation'))
        }
    }
}
</script>
<template>
  <div v-if="enrollment">
    <recess-divider />

    <h4>Aanvullen persoonlijke gegevens</h4>

    <div class="o-grid">
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="enrollment.firstName"
          data-test="first-name-input"
          label-text="Voornaam *"
          :value="enrollment.firstName"
          :error-message="getErrorMessage('firstName')"
        />
      </div>

      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="enrollment.middleName"
          data-test="middle-name-input"
          label-text="Tussenvoegsel(s)"
          :value="enrollment.middleName"
        />
      </div>

      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="enrollment.lastName"
          data-test="last-name-input"
          label-text="Achternaam *"
          :value="enrollment.lastName"
          :error-message="getErrorMessage('lastName')"
        />
      </div>

      <div
        v-if="$isAllowed('displayPersonalInformation') && $isAllowed('displayBirthDateField')"
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
      >
        <recess-input
          v-model="enrollment.birthDay"
          data-test="birth-day-input"
          :label-text="isDiplomaOrAccreditationDegree ? 'Geboortedatum *' : 'Geboortedatum'"
          placeholder-text="Dag-maand-jaar, bijv.: 25-01-1970"
          :value="enrollment.birthDay"
          :error-message="getErrorMessage('birthDay')"
        />
      </div>

      <div
        v-if="$isAllowed('displayPersonalInformation') && $isAllowed('displayBirthPlaceField')"
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
      >
        <recess-input
          v-model="enrollment.birthPlace"
          data-test="birth-place-input"
          :label-text="isDiplomaOrAccreditationDegree ? 'Geboorteplaats *' : 'Geboorteplaats'"
          :value="enrollment.birthPlace"
          :error-message="getErrorMessage('birthPlace')"
        />
      </div>

      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="enrollment.email"
          data-test="email-address-input"
          label-text="E-mailadres *"
          :value="enrollment.email"
          :error-message="getErrorMessage('email')"
        />
      </div>

      <div
        v-if="$isAllowed('displayPersonalInformation')"
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
      >
        <recess-input
          v-model.trim="enrollment.phoneNumber"
          data-test="phone-number-input"
          label-text="Telefoonnummer *"
          :value="enrollment.phoneNumber"
          :error-message="getErrorMessage('phoneNumber')"
        />
      </div>
    </div>

    <recess-divider />

    <template v-if="$isAllowed('displayPersonalInformation')">
      <h4>Adresgegevens</h4>

      <div class="o-grid u-position-relative">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isFetchingAddress"
            variant="small"
            color="primary"
            overlay="white"
          />
        </transition>
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="enrollment.addressPostalCode"
            data-test="postal-code-input"
            label-text="Postcode *"
            :value="enrollment.addressPostalCode"
            :error-message="getErrorMessage('addressPostalCode')"
            @blur="getAddress()"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="enrollment.addressHouseNumber"
            data-test="house-number-input"
            label-text="Huisnummer *"
            :value="enrollment.addressHouseNumber"
            :error-message="getErrorMessage('addressHouseNumber')"
            @blur="getAddress()"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="enrollment.addressHouseNumberAdditive"
            label-text="Huisnummer toevoeging"
            data-test="house-number-additive-input"
            :value="enrollment.addressHouseNumberAdditive"
            :error-message="getErrorMessage('addressHouseNumberAdditive')"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="enrollment.addressStreet"
            data-test="street-input"
            label-text="Straat *"
            :value="enrollment.addressStreet"
            :error-message="getErrorMessage('addressStreet')"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="enrollment.addressCity"
            data-test="city-input"
            label-text="Plaats *"
            :value="enrollment.addressCity"
            :error-message="getErrorMessage('addressCity')"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-select
            v-model="enrollment.addressCountryCode"
            data-test="country-input"
            label-text="Land *"
            :default-value="enrollment.addressCountryCode || 'NL'"
            :options="countriesOptions"
            :error-message="getErrorMessage('addressCountryCode')"
          />
        </div>
      </div>
    </template>

    <recess-divider />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, numeric, requiredIf, maxLength } from 'vuelidate/lib/validators'
import {
    validationMessages,
    isValidDate,
    isValidPhoneNumber,
    isPastDate,
    isValidBirthdayDate,
    isValidEmail
} from '../../../../modules/validationsHelper'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import { myDevelopmentTabs } from '../../../../constants/tabNames'
import { getAddress, cancelFetchAddress } from "../../../../api/addressLookupClient"
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent
    },
    data() {
        return {
            validationMessages,
            myDevelopmentTabs,
            isFetchingAddress: false
        }
    },
    validations() {
        if (this.$isAllowed('displayPersonalInformation')) {
            const validationData = {
                enrollment: {
                    firstName: { required },
                    lastName: { required },
                    addressCity: { required },
                    addressCountryCode: { required },
                    addressPostalCode: { required },
                    addressHouseNumber: { required, numeric },
                    addressHouseNumberAdditive: { maxLength: maxLength(25) },
                    addressStreet: { required },
                    email: { required, isValidEmail },
                    phoneNumber: { required, isValidPhoneNumber },
                    eduMSFirstStartMomentId: {
                        required: requiredIf(() => {
                            return this.isELearning ? this.startMoments.length > 0 : true
                        })
                    },
                    
                    birthPlace: {
                        required: requiredIf(() => {
                            return this.isDiplomaOrAccreditationDegree && this.$isAllowed('displayBirthPlaceField')
                        })
                    }
                }
            }
            if (this.$isAllowed('displayBirthDateField')) {
                validationData.enrollment.birthDay = {
                    required: requiredIf(() => {
                        return this.isDiplomaOrAccreditationDegre
                    }),
                    isValidDate,
                    isPastDate,
                    isValidBirthdayDate
                }
            }
            return validationData
        }
        return {
            enrollment: {
                firstName: { required },
                lastName: { required },
                email: { required, isValidEmail },
                eduMSFirstStartMomentId: {
                    required: requiredIf(() => {
                        return this.isELearning ? this.startMoments.length > 0 : true
                    })
                }
            }
        }
    },
    computed: {
        ...mapState('verificationEnrollmentModule', [
            'enrollment',
            'countries',
            'isELearning',
            'startMoments'
        ]),
        countryDisplayValue() {
            const country = this.countries.find(m => m.value === this.enrollment.addressCountryCode)
            return country ? country.displayValue : ''
        },
        // Traslate the countries object for recess-select
        countriesOptions() {
            const countries = this.countries
                ? this.countries.map(country => {
                    return {
                        displayText: country.displayValue,
                        value: country.value
                    }
                })
                : []

            // Remove empty values, like the Selecteer since we don't need it
            return countries.filter(country => {
                return Boolean(country.value)
            })
        },
        isDiplomaOrAccreditationDegree() {
            return  this.diplomaType &&
                (this.diplomaType.toLowerCase() === 'diploma' ||
                    this.diplomaType.toLowerCase() === 'accrediteddegree')
        }
    },
    methods: {
        async getAddress() {
            try {
                // Cancel previous request
                cancelFetchAddress()
                this.isFetchingAddress = true
                if (this.enrollment.addressPostalCode 
                && this.enrollment.addressHouseNumber 
                && !this.$v.enrollment.addressPostalCode.$invalid 
                && !this.$v.enrollment.addressHouseNumber.$invalid) {
                    const response = await getAddress(this.enrollment.addressPostalCode, this.enrollment.addressHouseNumber)

                    if(response) {
                        this.enrollment.addressStreet = response.streetName
                        this.enrollment.addressCity = response.city
                        this.enrollment.addressCountryCode = "NL"
                    }
                }
            } catch {
                // No need to throw error to the console
            } finally {
                this.isFetchingAddress = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.enrollment[fieldName]
            const fieldValue = this.enrollment[fieldName]

            // Check Required
            if (fieldValidation && fieldValidation.$error && fieldValidation.required === false) {
                return this.validationMessages.required[fieldName]
            }

            // Check Invalid
            if (fieldValidation && fieldValidation.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        }
    }
}
</script>

import BasePerimeter from './BasePerimeter'
import featuresPerimeter from './featuresPerimeter'

export default new BasePerimeter({
    purpose: 'permanentEducationAuthorization',
    
    isCoordinatorAndFeatureIsEnabled() {
        return this.isCoordinator() && !featuresPerimeter.isPERemindersManageableByCoordinatorDisabled()
    },
    isTeamLeaderAndFeatureIsEnabled() {
        return this.isTeamLeader() && !featuresPerimeter.isPERemindersManageableByTeamLeaderDisabled()
    },
    isOwnPEReminder(userId) {
        return this.child?.user?.userId === userId
    },
    isLearningResultFeatureEnabled() {
        return !featuresPerimeter.isLearningResultDisabled()
    },
    isCreatedByProvider(subRecord) {
        return !subRecord.isImported && subRecord.createdByUserId === null
    },
    isSelfCreated(createdByUserId) {
        return createdByUserId && this.child?.user?.userId === createdByUserId
    },
    hasDiplomaConsent(subRecord) {
        return subRecord?.enrollment?.enrollmentMutualLearningResult?.diplomaConsent
    },
    hasOplzDiploma(subRecord) {
        return !!subRecord?.certificationPaths?.length || !!subRecord?.certifications?.length
    },

    can: {
        managePermanentEducation() {
            return this.isCoordinatorAndFeatureIsEnabled() || this.isTeamLeaderAndFeatureIsEnabled()
        },
        displayPermanentEducationDetails({ userId, createdByUserId, isImported } = {}) {
            const isCreatedByProvider = !isImported && createdByUserId === null

            return (!isCreatedByProvider || (isCreatedByProvider && this.isLearningResultFeatureEnabled())) 
            && (this.isCoordinatorAndFeatureIsEnabled() || this.isTeamLeaderAndFeatureIsEnabled() || this.isOwnPEReminder(userId))   
        },
        editPermanentEducation({userId, createdByUserId, isImported} = {}) {
            return isImported || this.isCoordinatorAndFeatureIsEnabled() || 
                (createdByUserId && this.isTeamLeaderAndFeatureIsEnabled() || (this.isOwnPEReminder(userId) && this.isSelfCreated(createdByUserId)))
        },
        removePermanentEducation({ userId, createdByUserId, isImported } = {}) {
            return createdByUserId  
            && (this.isCoordinatorAndFeatureIsEnabled() || this.isTeamLeaderAndFeatureIsEnabled() || (this.isOwnPEReminder(userId) && this.isSelfCreated(createdByUserId))) 
            || (isImported && this.isOwnPEReminder(userId))
        },

        displayPEReminderSubRecord(subRecord) {
            return subRecord.createdByUserId 
            || subRecord.isImported 
            || (!subRecord.createdByUserId && this.isLearningResultFeatureEnabled())
        },
        deletePEReminderSubRecord({subRecord, assignedUserId}) {
            return this.isSelfCreated(subRecord.createdByUserId) 
            || (subRecord.isImported && this.isOwnPEReminder(assignedUserId))
        },

        uploadPEReminderCertificate({subRecord, assignedUserId}) {
            const isOwnSubRecord = this.isOwnPEReminder(assignedUserId)
            
            return this.isSelfCreated(subRecord.createdByUserId)
            || (this.isCreatedByProvider(subRecord) && this.isCoordinatorAndFeatureIsEnabled() && !subRecord.providerDiplomaFile)
            || (subRecord.isImported && isOwnSubRecord)
        },
        displayPEReminderCertificate() {
            return !featuresPerimeter.isPEReminderCertificateDisabled()
        },

        updateExamStatus({ subRecord, assignedUserId }) {
            const isOwnSubRecord = this.isOwnPEReminder(assignedUserId)

            return this.isSelfCreated(subRecord.createdByUserId)
            || (this.isCreatedByProvider(subRecord) && this.isCoordinatorAndFeatureIsEnabled() && !subRecord.hasProviderUpdatedExamStatus)
            || (subRecord.isImported && isOwnSubRecord)
        },
        displayPassedExamValue({ subRecord, assignedUserId }) {
            const hasGivenConsent = subRecord?.enrollment?.enrollmentMutualLearningResult?.passedExamConsent
            const isOwnSubRecord = this.isOwnPEReminder(assignedUserId)

            return  (this.isCreatedByProvider(subRecord) && (hasGivenConsent || subRecord.coordinatorSetExamStatus != null))
            || (!this.isCreatedByProvider(subRecord) && (this.isCoordinatorAndFeatureIsEnabled() || this.isTeamLeaderAndFeatureIsEnabled() || isOwnSubRecord))
        },
        displayExamStatusNoConsentIndication(subRecord) {
            const passedExamConsent = subRecord?.enrollment?.enrollmentMutualLearningResult?.passedExamConsent
            const hasCoordinatorSetExamStatus = subRecord.coordinatorSetExamStatus != null

            return this.isCreatedByProvider(subRecord) && !passedExamConsent && !hasCoordinatorSetExamStatus
        },

        displayNoConsentWarningText(subRecord) {
            const enrollmentMutualLearningResult = subRecord?.enrollment?.enrollmentMutualLearningResult
            const hasCoordinatorSetExamStatus = subRecord.coordinatorSetExamStatus != null

            if (!enrollmentMutualLearningResult) return false

            let mutualConsentsKeys = Object.keys(enrollmentMutualLearningResult)
            if (!this.isCoordinator())
                mutualConsentsKeys = mutualConsentsKeys.filter(key => key !== 'diplomaConsent')

            return mutualConsentsKeys.some(key => key === 'passedExamConsent' && !enrollmentMutualLearningResult[key] && !hasCoordinatorSetExamStatus) ||
                    mutualConsentsKeys.some(key => key !== 'passedExamConsent' && !enrollmentMutualLearningResult[key])
        },

        displayUploadedCertificates(subRecord) {
            const hasProviderDiploma = !!subRecord?.providerDiplomaName && !!subRecord?.providerDiplomaName
            const displayCertificate = !featuresPerimeter.isPEReminderCertificateDisabled()

            return displayCertificate 
            && (!this.isCreatedByProvider(subRecord) 
                || ((!this.hasDiplomaConsent(subRecord) && this.hasOplzDiploma(subRecord)) 
                    || (this.hasDiplomaConsent(subRecord) 
                        && (hasProviderDiploma || this.hasOplzDiploma(subRecord))
                    )
                )
            )
        },
        displayDiplomaNoConsentIndication(subRecord) {
            return this.isCreatedByProvider(subRecord) 
            && !this.hasDiplomaConsent(subRecord) 
            && !this.hasOplzDiploma(subRecord)
        }
    }
})

<template>
  <recess-tooltip>
    <div class="c-participants">
      <participant :filled="hasParticipants" />
      <participant :filled="hasMinParticipants" />
      <participant :filled="hasMaxParticipants" />
    </div>
    <template #content>
      <div>
        <div>Minimum aantal deelnemers: {{ minParticipants || 0 }}</div>
        <div class="mb-3">Maximum aantal deelnemers: {{ maxParticipants || 0 }}</div>
        <div>Definitief ingeschreven: {{ processedOrders || 0 }}</div>
        <div class="mb-3">Nog in de goedkeuringsflow: {{ pendingOrders || 0 }}</div>
        <div class="font-weight-bold">Plekken resterend: {{ remainingSeats || 0 }}</div>
        <div>Deelnemers op de wachtlijst: {{ seatShortage || 0 }}</div>
      </div>
    </template>
  </recess-tooltip>
</template>

<script>
import Participant from './participant.vue'

export default {
    components: {
        Participant
    },
    props: {
        startMoment: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        minParticipants() {
            return this.startMoment?.minParticipants
        },
        maxParticipants() {
            return this.startMoment?.maxParticipants
        },
        pendingOrders() {
            return this.startMoment?.pendingOrders
        },
        processedOrders() {
            return this.startMoment?.processedOrders
        },
        totalOrders() {
            return this.pendingOrders + this.processedOrders
        },
        hasParticipants() {
            return this.pendingOrders + this.processedOrders > 0
        },
        hasMinParticipants() {
            return this.minParticipants || (this.minParticipants === 0 && this.maxParticipants)
                ? this.pendingOrders + this.processedOrders >= this.minParticipants
                : false
        },
        hasMaxParticipants() {
            return this.maxParticipants
                ? this.pendingOrders + this.processedOrders >= this.maxParticipants
                : false
        },
        remainingSeats() {
            if (this.maxParticipants - this.totalOrders < 0) return 0
            return this.maxParticipants - this.totalOrders
        },
        seatShortage() {
            if (this.remainingSeats > 0) return 0
            return this.totalOrders - this.startMoment.maxParticipants
        }
    }
}
</script>
<template>
  <recess-card
    has-transition
    class="c-home-banner-listing u-absolute-lg-left w-100 p-lg-5"
  >
    <div
      v-if="facetValues && facetValues.length"
      :class="`u-column u-column-md-${columnNumber}`"
    >
      <ul>
        <li
          v-for="(facetValue, index) in facetValues"
          :key="index"
          class="d-flex py-1"
        >
          <recess-icon
            icon="arrow-right"
            color="secondary"
            :height="13"
            :width="13"
            class="pr-1"
          /> 
          <span
            data-test="facet-item"
            class="a u-textcolor-black u-cursor-pointer"
            @click="onFacetClick(facetValue)"
          >{{ isCustomFilter ? facetValue.displayValue : facetValue }}</span>
        </li>
      </ul>
    </div>
    <p v-if="showNoItemsMessage">{{ noItemsMessage }}</p>
  </recess-card>
</template>

<script>
import facetFilterHelper from '../../../modules/facetFilterHelper'
import { PEERFILTERS } from '../../../constants/facetKeys'

export default {
    props: {
        facet: {
            type: Object,
            required: true,
            default: () => {}
        },
        noItemsMessage: {
            type: String,
            default: null
        }
    },
    computed: {
        isCustomFilter() {
            return this.facet?.key === PEERFILTERS
        },
        facetValues() {
            return this.facet?.values
        },
        columnNumber() {
            return this.facetValues?.length >= 10 ? 2 : 1
        },
        showNoItemsMessage() {
            return !this.facetValues?.length && this.noItemsMessage
        }
    },
    methods: {
        async onFacetClick(selectedFacetFilter) {
            const mappedSelectedFacetFilter = {
                key: this.facet?.key,
                value: this.isCustomFilter ? selectedFacetFilter.key : selectedFacetFilter,
                checked: true
            }

            await this.$router.push({
                name: "search",
                query: {
                    facetFilters: facetFilterHelper.getFacetFiltersAsBase64(mappedSelectedFacetFilter)
                }
            })
        }
    }
}
</script>


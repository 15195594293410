<template>
  <table-filters-component
    toggle-button-text="Filter projecten"
    :show-reset-filters="hasNonEmptyFilters "
    @reset-filters="handleFiltersReset()"
  >
    <recess-select
      slot="right"
      :value="pageSize"
      :options="pageSizes"
      @input="val => $emit('input:page-size', +val)"
    />
    <template #filters>
      <recess-input
        :label-text="filters.id.labelText"
        :value="filters.id.value"
        :error-message="fieldErrors('id')"
        type="number"
        min="1"
        class="mb-3"
        data-test="filter-by-id-input"
        @keydown.native.enter="submitFilters"
        @input="val => $emit('input:id', val)" 
      />
      <recess-input
        :label-text="filters.name.labelText"
        :value="filters.name.value"
        class="mb-3"
        data-test="filter-by-name-input"
        @keydown.native.enter="submitFilters"
        @input="val => $emit('input:name', val)" 
      />
      <recess-input
        v-if="filters.userFullName.key"
        :label-text="filters.userFullName.labelText"
        :value="filters.userFullName.value"
        class="mb-3"
        data-test="filter-by-user-full-name-input"
        @keydown.native.enter="submitFilters"
        @input="val => $emit('input:user-full-name', val)" 
      />
      <recess-input
        v-if="filters.userEmail.key"
        :label-text="filters.userEmail.labelText"
        :value="filters.userEmail.value"
        class="mb-3"
        data-test="filter-by-user-email-input"
        @keydown.native.enter="submitFilters"
        @input="val => $emit('input:user-email', val)" 
      />
      <recess-select 
        v-if="statusOptions?.length"
        :value="filters.status.value"
        :default-value="filters.status.value"
        data-test="filter-by-status-select"
        :prefill-with-first-option="false"
        :label-text="filters.status.labelText" 
        :options="statusOptions" 
        @input="val => $emit('input:status', val)" 
      />
    </template>
    <template #footer>
      <recess-button
        title="Filter"
        variant="primary"
        class="col-12 col-sm-auto"
        data-test="submit-filter-btn"
        @click="submitFilters"
      />
    </template>
  </table-filters-component>
</template>

<script>
import { computed } from 'vue'
import { minValue, integer } from 'vuelidate/lib/validators'
import TableFiltersComponent from '../../../Tables/TableFiltersComponent.vue'
import pageSizes from "../../../../../constants/pageSizes"
import useValidation from '../../../../../composables/useValidation'

const errorMessages = {
    id: 'Projectid is ongeldig'
}

export default {
    components: {
        TableFiltersComponent
    },
    props: {
        filters: {
            type: Object,
            default: () => {},
            required: true
        },
        pageSize: {
            type: Number,
            default: null,
            required: true
        },
        statusOptions: {
            type: Array,
            default: () => [],
            required: true
        },
        handleFiltersReset: {
            type: Function,
            default: () => null
        }
    },
    emits: ['on-filter-submit'],
    setup(props, { emit }) {
        const { validation } = useValidation()

        const hasNonEmptyFilters  = computed(() => {
            return Object.values(props.filters).some(filter => filter.value !== '' )
        })

        const fieldErrors = field => {
            const validationField = validation.value.filters?.[field]
            if (!validationField.$error) {
                return null
            }

            return errorMessages[field] ?? null
        }

        const submitFilters = () => {
            validation.value.$touch()
            if (validation.value.$invalid) {
                return
            }

            emit('on-filter-submit')
        }

        return {
            pageSizes,
            hasNonEmptyFilters,
            submitFilters,
            fieldErrors
        }
    },
    validations() {
        return {
            filters: {
                id: { 
                    value: {
                        integer,
                        minValue: minValue(1) 
                    }
                }
            }
        }
    }
}
</script>
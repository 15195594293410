<template>
  <div
    @touchstart.prevent="toggleWishList"
    @click="toggleWishList"
    @mouseover="toggleHeartOnOver()"
    @mouseleave="toggleHeartOnLeave()"
  >
    <div
      class="u-position-relative"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
          variant="xtra-small"
          class="d-flex justify-content-center"
        />
      </transition>
      <RecessIcon
        :has-hover="true"
        color="red"
        :icon="!showEmptyHeart ? 'heart-full' : 'heart-empty'"
        :class="[
          'c-wishlist-toggle__icon flex-column', 
          { 'flex-md-row' : !isColumnView && !isSmallView }
        ]"
      >
        <span :class="[{'pl-1' : !isColumnView},'u-text-small']">Verlanglijst</span>
      </RecessIcon>
    </div>
  </div>
</template>

<script>
import wishlistClient from '../../../api/wishlistClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    components: {
        LoaderComponent
    },
    props: {
        courseId: {
            type: String,
            default: null
        },
        isOnWishList: {
            type: Boolean,
            default: false
        },
        isColumnView: {
            type: Boolean,
            default: false
        },
        isSmallView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            mIsOnWishList: this.isOnWishList,
            showEmptyHeart: this.isOnWishList
        }
    },
    watch: {
        mIsOnWishList: {
            handler(val) {
                this.showEmptyHeart = !val
            },
            immediate: true
        }
    },
    methods: {
        toggleHeartOnOver() {
            if(this.mIsOnWishList) this.showEmptyHeart = true
            else this.showEmptyHeart = false
        },
        toggleHeartOnLeave() {
            if(this.mIsOnWishList) this.showEmptyHeart = false
            else this.showEmptyHeart = true
        },
        toggleWishList() {
            this.isLoading = true
            const request = {
                courseId: this.courseId,
                toggle: !this.mIsOnWishList
            }

            wishlistClient
                .toggle(request)
                .then(() => {
                    this.mIsOnWishList = !this.mIsOnWishList
                    this.showEmptyHeart = !this.showEmptyHeart
                    this.$emit('wish-list-toggled', this.mIsOnWishList)
                })
                .catch(err => {
                    console.error(err)
                })
                .then(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <recess-card
    :bordered="true"
    class="mt-5 mt-lg-0"
  >
    <div class="c-teaser">
      <h5 class="c-teaser__title">Meer informatie?</h5>
      <template v-if="$isAllowed('displayContactInformationForStudents')">
        <p class="c-teaser__text">Onze studieadviseurs staan voor u klaar.</p>
        <template v-if="accountContactInfo">
          <ul class="c-teaser__list">
            <li
              v-if="accountContactInfo.phoneNumber"
              class="c-teaser__list-item"
            >
              <a
                :href="`tel:${accountContactInfo.phoneNumber}`"
                target="_self"
                data-test="phone-number-link"
                class="c-teaser__link"
              >
                <contactPhoneSvg class="c-teaser__icon c-teaser__icon--phone" />
                <p class="c-teaser__link-text">{{ accountContactInfo.phoneNumber }}</p>
              </a>
            </li>
            <li
              v-if="accountContactInfo.email"
              class="c-teaser__list-item"
            >
              <a
                :href="'mailto:' + accountContactInfo.email"
                class="c-teaser__link"
                data-test="phone-number-email"
              >
                <contactEmailSvg class="c-teaser__icon c-teaser__icon--email" />
                <p class="c-teaser__link-text">e-mail</p>
              </a>
            </li>
          </ul>
        </template>
      </template>
      <div
        v-else-if="$isAllowed('displayContactAlternativeText')"
        class="qa-contact-alternative-text"
        v-html="contactAlternativeText"
      ></div>
    </div>
  </recess-card>
</template>

<script>
import { mapGetters } from 'vuex'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import contactEmailSvg from '../../organisms/IconComponents/ContactEmailSVG.vue'
import contactPhoneSvg from '../../organisms/IconComponents/ContactPhoneSVG.vue'

export default {
    perimeters: [featuresPerimeter],
    components: {
        contactPhoneSvg,
        contactEmailSvg
    },
    computed: {
        ...mapGetters('accountModule', ['accountContactInfo', 'contactAlternativeText'])
    }
}
</script>
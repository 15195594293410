<template>
  <div
    v-observe-visibility="{ callback: visibilityChanged, once: true }"
    class="c-top-notifications-accordion"
  >
    <recess-accordion
      variant="variant-2"
      @toggled="fetchNotifications"
    >
      <template #accordion-title>
        <strong>Notificaties</strong>
      </template>
      <template #accordion-content>
        <recess-alert
          v-if="errorOccurred"
          type="error"
          text="Er ging iets mis tijdens het ophalen van uw notificaties."
        />

        <div class="c-top-notifications-accordion__body">
          <transition
            name="fade"
            mode="out-in"
          >
            <loader-component
              v-if="showLoader"
              color="primary"
            />

            <top-notifications-list-view
              v-if="doneFetching"
              :no-data="noUnreadNotifications"
              :notifications="notifications"
            />
          </transition>
        </div>
      </template>
    </recess-accordion>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { get } from 'lodash'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import userClient from '../../../api/userClient'
import topNotificationsListView from './TopNotificationsListView.vue'

export default {
    components: {
        LoaderComponent,
        topNotificationsListView
    },
    data() {
        return {
            notifications: [],
            noUnreadNotifications: false,
            errorOccurred: false,
            isFetching: true,
            doneFetching: false,
            isVisible: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        userId() {
            return get(this.user, 'userId')
        },
        showLoader() {
            return !this.doneFetching || this.isFetching
        }
    },
    watch: {
        userId() {
            this.fetchNotifications()
        }
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible
        },
        fetchNotifications() {
            if (!this.doneFetching && this.isVisible && this.userId) {
                this.isFetching = true
                userClient
                    .getNotifications(this.userId, 4, false)
                    .then(response => {
                        const notifications = get(response, 'data', null)

                        if (!notifications || notifications.length === 0) {
                            this.noUnreadNotifications = true
                        } else {
                            this.notifications = notifications.slice(0, 4)
                        }
                    })
                    .catch(() => {
                        this.errorOccurred = true
                    })
                    .then(() => {
                        this.isFetching = false
                        this.doneFetching = true
                    })
            }
        }
    }
}
</script>
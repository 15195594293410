import Vue from 'vue'
import Router from 'vue-router'
import AccountSelection from './components/pages/AccountSelection/AccountSelection.vue'
import BudgetCreate from './components/pages/Budgets/BudgetCreate.vue'
import BudgetDetails from './components/pages/Budgets/BudgetDetails.vue'
import BudgetEdit from './components/pages/Budgets/BudgetEdit.vue'
import MyBudgets from './components/pages/Budgets/MyBudgets.vue'
import CompareCourses from "./components/pages/CompareCourses/CompareCourses.vue"
import Contact from './components/pages/Contact/Contact.vue'
import Course from './components/pages/Course/Course.vue'
import CourseRequest from './components/pages/CourseRequest/CourseRequest.vue'
import DisabledUser from './components/pages/DisabledUser/DisabledUser.vue'
import Enrollment from './components/pages/Enrollment/Enrollment.vue'
import EnrollmentApproval from './components/pages/EnrollmentApproval/EnrollmentApproval.vue'
import EnrollmentDetails from './components/pages/EnrollmentDetails/EnrollmentDetails.vue'
import EnrollmentVerification from './components/pages/EnrollmentVerification/EnrollmentVerification.vue'
import Faq from './components/pages/Faq/Faq.vue'
import Home from './components/pages/Home/Home.vue'
import EditLearningPath from './components/pages/LearningPath/EditLearningPath.vue'
import LearningPathDetails from './components/pages/LearningPath/LearningPathDetails.vue'
import LegalAgreements from './components/pages/LegalAgreements/LegalAgreements.vue'
import MyDelegatedActions from './components/pages/MyActions/MyActions.vue'
import MyDevelopment from './components/pages/MyDevelopment/MyDevelopment.vue'
import MyNotifications from './components/pages/MyNotifications/MyNotifications.vue'
import MyTeam from './components/pages/MyTeam/MyTeam.vue'
import NotFound from './components/pages/NotFound/NotFound.vue'
import OidcCallback from './components/pages/OIDC/OidcCallback.vue'
import OidcSignoutCallback from './components/pages/OIDC/OidcSignoutCallback.vue'
import OidcSilentRenew from './components/pages/OIDC/OidcSilentRenew.vue'
import PEReminderCreate from './components/pages/PEReminders/PEReminderCreate.vue'
import PEReminderEdit from './components/pages/PEReminders/PEReminderEdit.vue'
import CreateProjectRequest from './components/pages/ProjectRequests/CreateProjectRequest.vue'
import MyProjectRequests from './components/pages/ProjectRequests/MyProjectRequests.vue'
import ProjectRequestApproval from './components/pages/ProjectRequests/ProjectRequestApproval.vue'
import ProjectRequestDetails from './components/pages/ProjectRequests/ProjectRequestDetails.vue'
import ReportDashboard from './components/pages/ReportDashboard/ReportDashboard.vue'
import RequestOutsideAssortedProduct from './components/pages/RequestOutsideAssortedProduct/RequestOutsideAssortedProduct.vue'
import Search from './components/pages/Search/Search.vue'
import PostCreate from './components/pages/SocialLearning/PostCreate.vue'
import PostDetails from './components/pages/SocialLearning/PostDetails.vue'
import PostEdit from './components/pages/SocialLearning/PostEdit.vue'
import SearchSocialLearning from './components/pages/SocialLearning/Search.vue'
import StudyContractApproval from './components/pages/StudyContractApproval/StudyContractApproval.vue'
import User from './components/pages/User/User.vue'
import store from './store'

// With the new promise api, router.push return a promise, if the navigation failure
// (anything that cancels a navigation like a next(false) or next('/other') or navigating to the same path also counts)
// is not caught, you will see an error in the console.
// The following code replace Router's push/replace functions to silence the rejection and make the promise
// resolve with the error instead.
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

export default function createRouter() {
    return new Router({
        base: '/',
        mode: 'history',
        scrollBehavior(to, from, savedPosition) {
            // If disableScroll is set or is a tab navigation don't scroll
            if (to?.matched?.some(m => m.meta.disableScroll) || Object.keys(to?.query)?.some(key => key.includes('tab'))) return null

            // If a scrollToPosition is set in the from, scroll to that position after routing
            if (from?.params?.scrollToPosition) {
                return from.params.scrollToPosition
            }

            if (savedPosition) {
                return savedPosition
            }
            return { x: 0, y: 0 }
        },
        routes: [
            {
                path: '*',
                name: 'NotFound',
                component: NotFound,
                meta: {
                    requiresAuth: true,
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/signin-oidc-oplz', // Needs to match redirectUri (redirect_uri if you use snake case) in the oidcSettings
                component: OidcCallback,
                meta: {
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/signout-oidc-oplz', // Needs to match redirectUri (redirect_uri if you use snake case) in the oidcSettings
                component: OidcSignoutCallback,
                meta: {
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/silent-renew', // Needs to match redirectUri (redirect_uri if you use snake case) in the oidcSettings
                component: OidcSilentRenew,
                meta: {
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/legal-agreements',
                name: 'legal-agreements',
                component: LegalAgreements,
                meta: {
                    requiresAuth: true,
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/',
                alias: ['/home', '/dashboard', '/home-page'],
                name: 'home',
                component: Home,
                meta: {
                    requiresAuth: true,
                    title: 'Dashboard'
                }
            },
            {
                path: '/contact',
                name: 'contact',
                component: Contact,
                meta: {
                    requiresAuth: true,
                    title: 'Contact'
                }
            },
            {
                path: '/opleiding',
                alias: '/course',
                name: 'course',
                component: Course,
                meta: {
                    requiresAuth: true,
                    title: 'Opleiding',
                    disableScroll: true
                }
            },
            {
                path: '/inzage-inschrijving',
                alias: [
                    '/enrollments-approval',
                    '/enrollment-approval',
                    '/enrollment-approval.html',
                    '/TeamLeader/Enrollments-Approval'
                ],
                name: 'enrollment-approval',
                component: EnrollmentApproval,
                meta: {
                    requiresAuth: true,
                    title: 'Inzage inschrijving'
                }
            },
            {
                path: '/inschrijfgegevens',
                alias: ['/enrollment-details', '/enrollment-details.html'],
                name: 'enrollment-details',
                component: EnrollmentDetails,
                meta: {
                    requiresAuth: true,
                    title: 'Inschrijfgegevens'
                }
            },
            {
                path: '/inschrijven-controleren',
                alias: ['/enrollment-verification', '/enrollment-verification.html'],
                name: 'enrollment-verification',
                component: EnrollmentVerification,
                meta: {
                    requiresAuth: true,
                    title: 'Inschrijven controleren'
                }
            },
            {
                path: '/veelgestelde-vragen',
                alias: '/faq',
                name: 'faq',
                component: Faq,
                meta: {
                    requiresAuth: true,
                    title: 'Veelgestelde vragen'
                }
            },
            {
                path: '/mijn-ontwikkeling',
                alias: '/my-development',
                name: 'my-development',
                component: MyDevelopment,
                meta: {
                    requiresAuth: true,
                    title: 'Mijn ontwikkeling'
                }
            },
            {
                path: '/mijn-team',
                alias: '/my-team',
                name: 'my-team',
                component: MyTeam,
                meta: {
                    requiresAuth: true,
                    title: 'Mijn team'
                }
            },
            {
                path: '/mijn-notificaties',
                alias: '/my-notifications',
                name: 'my-notifications',
                component: MyNotifications,
                meta: {
                    requiresAuth: true,
                    title: 'Mijn notificaties'
                }
            },
            {
                path: '/mijn-acties',
                alias: '/my-actions',
                name: 'my-actions',
                component: MyDelegatedActions,
                meta: {
                    requiresAuth: true,
                    title: 'Mijn acties'
                }
            },
            {
                path: '/permanente-educatie/instellen/:role?',
                alias: '/permanent-educations/create/:role?',
                name: 'create-permanent-education',
                component: PEReminderCreate,
                meta: {
                    requiresAuth: true,
                    title: 'Instellen permanente educatie'
                },
                props: true
            },
            {
                path: '/permanente-educatie/:id/wijzigen/:role?',
                alias: '/permanent-educations/:id/edit/:role?',
                name: 'edit-permanent-education',
                component: PEReminderEdit,
                meta: {
                    requiresAuth: true,
                    title: 'Wijzigen permanente educatie'
                },
                props: true
            },
            {
                path: '/kennisplein/instellen/bijdrage',
                alias: '/knowledge-square/create/post',
                name: 'create-new-post',
                component: PostCreate,
                meta: {
                    requiresAuth: true,
                    title: 'Nieuwe bijdrage'
                }
            },
            {
                path: '/kennisplein/bijdrage/:id',
                alias: '/knowledge-square/post/:id',
                name: 'post-details',
                component: PostDetails,
                meta: {
                    requiresAuth: true,
                    title: 'Detail bijdrage'
                }
            },
            {
                path: '/kennisplein/bijdrage/:id/wijzigen',
                alias: '/knowledge-square/:id/edit',
                name: 'edit-post',
                component: PostEdit,
                meta: {
                    requiresAuth: true,
                    title: 'Bijdrage wijzigen'
                }
            },
            {
                path: '/rapporten-dashboard',
                alias: '/report-dashboard',
                name: 'report-dashboard',
                component: ReportDashboard,
                meta: {
                    requiresAuth: true,
                    title: 'Rapporten dashboard'
                }
            },
            {
                path: '/zoekresultaten',
                alias: '/search',
                name: 'search',
                component: Search,
                meta: {
                    requiresAuth: true,
                    title: 'Zoekresultaten'
                },
                props: true
            },
            {
                path: '/profiel',
                alias: '/profile',
                name: 'profile-page',
                component: User,
                meta: {
                    requiresAuth: true,
                    title: 'Profiel'
                }
            },
            {
                path: '/studieovereenkomst',
                alias: ['/studycontract', '/overeenkomstacceptatie'],
                name: 'studycontract',
                component: StudyContractApproval,
                meta: {
                    requiresAuth: true,
                    title: 'Studieovereenkomst'
                }
            },
            {
                path: '/aanvraag-buiten-catalogus/:id',
                alias: '/course-request/:id',
                name: 'course-request',
                component: CourseRequest,
                meta: {
                    requiresAuth: true,
                    title: 'Aanvraag buiten catalogus'
                }
            },
            {
                path: '/inschrijven-:type',
                alias: '/enrollment-:type',
                name: 'enrollment',
                component: Enrollment,
                meta: {
                    requiresAuth: true,
                    hideHeaderAndFooter: true,
                    title: 'Inschrijven',
                    useTypeSubtitle: true,
                    disableScroll: true
                }
            },
            {
                path: '/leerpaden/:id/wijzigen',
                alias: '/learning-path/:id/edit',
                name: 'edit-learning-path',
                component: EditLearningPath,
                meta: {
                    requiresAuth: true,
                    title: 'Leerpaden'
                },
                props: true
            },
            {
                path: '/leerpaden/:id',
                alias: '/learning-path/:id',
                name: 'learning-path-details',
                component: LearningPathDetails,
                meta: {
                    requiresAuth: true,
                    title: 'Leerpaden'
                },
                props: true
            },
            {
                path: '/budget/:id/wijzigen',
                alias: '/budget/:id/edit',
                name: 'edit-budget',
                component: BudgetEdit,
                meta: {
                    requiresAuth: true,
                    title: 'Budget wijzigen'
                }
            },
            {
                path: '/mijn-budgetten',
                alias: '/my-budgets',
                name: 'my-budgets',
                component: MyBudgets,
                meta: {
                    requiresAuth: true,
                    title: 'Mijn budgetten'
                }
            },
            {
                path: '/mijn-projectaanvragen',
                alias: '/my-project-requests',
                name: 'my-project-requests',
                component: MyProjectRequests,
                meta: {
                    requiresAuth: true,
                    title: 'Mijn projectaanvragen'
                }
            },
            {
                path: '/mijn-projectaanvragen/nieuw',
                alias: '/my-project-requests/create',
                name: 'create-project-request',
                component: CreateProjectRequest,
                meta: {
                    requiresAuth: true,
                    title: 'Nieuwe projectaanvraag'
                }
            },
            {
                path: '/projectaanvragen/:id',
                alias: '/project-requests/:id',
                name: 'project-request-detail',
                component: ProjectRequestDetails,
                meta: {
                    requiresAuth: true,
                    title: 'Projectaanvraag gegevens'
                }
            },
            {
                path: '/beoordelen-projectaanvraag/:id',
                alias: '/project-request-approval/:id',
                name: 'project-request-approval',
                component: ProjectRequestApproval,
                meta: {
                    requiresAuth: true,
                    title: 'Beoordelen projectaanvraag'
                }
            },
            {
                path: '/budget/nieuw',
                alias: '/budget/create',
                name: 'create-budget',
                component: BudgetCreate,
                meta: {
                    requiresAuth: true,
                    title: 'Toewijzen budget'
                }
            },
            {
                path: '/budget/:id',
                alias: '/budgets/:id',
                name: 'budget-details',
                component: BudgetDetails,
                meta: {
                    requiresAuth: true,
                    title: 'Budgetwijzigingen'
                }
            },
            {
                path: '/accountselectie',
                alias: '/select-account',
                name: 'select-account',
                component: AccountSelection,
                meta: {
                    requiresAuth: true,
                    title: 'Accountselectie',
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/gebruiker-inactief',
                alias: '/disabled-user',
                name: 'disabled-user',
                component: DisabledUser,
                meta: {
                    requiresAuth: false,
                    title: 'No user found',
                    hideHeaderAndFooter: true
                }
            },
            {
                path: '/aanvraag-buiten-catalogus',
                name: 'abc-request',
                component: RequestOutsideAssortedProduct,
                meta: {
                    requiresAuth: true,
                    title: 'Aanvraag buiten catalogus'
                },
                props: true
            },
            {
                path: '/vergelijk-ontwikkelactiviteiten',
                alias: '/compare-courses',
                name: 'compare-courses',
                component: CompareCourses,
                meta: {
                    requiresAuth: true,
                    title: 'Vergelijk ontwikkelactiviteiten'
                }
            },
            {
                path: '/kennisplein',
                alias: '/posts',
                name: 'search-posts',
                component: SearchSocialLearning,
                meta: {
                    requiresAuth: true,
                    title: 'Kennisplein'
                }
            },
            {
                name: 'forgot-password',
                path: '/wachtwoord-opvragen',
                alias: '/forgot-password',
                beforeEnter() {
                    // Forgot password was moved out from the app to IDS6
                    let url = store.getters['configurationModule/oidcConfig']?.authority?.trim()
                    if (!url?.endsWith('/')) url += '/'

                    window.location.href = `${url}account/forgotpassword`
                }
            },
            // Match a path that contains .html and remove it.
            // This apply to notification or email that contain old kentico urls.
            {
                path: '/([\\s\\S]+\\.html)',
                redirect: to => {
                    return to.path.replace(/.html/, '')
                }
            }
        ]
    })
}


<template>
  <div>
    <svg
      class="c-feedback-company__star-base"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <g id="c-feedback-company__star-source">
          <path
            d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
               l11.547-1.2L16.026,0.6L20.388,10.918z"
          />
        </g>

        <linearGradient id="c-feedback-company__half-star-source">
          <stop
            class="c-feedback-company__half-star-color"
            stop-opacity="1"
            offset="50%"
            stop-color="#000"
          />
          <stop
            stop-opacity="1"
            offset="50%"
            stop-color="#e5e5e5"
          />
        </linearGradient>
      </defs>
    </svg>

    <p>
      <svg
        v-for="(star, index) in totalFilledStars"
        :key="`star-${index}`"
        class="c-feedback-company__star"
        viewBox="0 0 32 32"
      >
        <use
          xlink:href="#c-feedback-company__star-source"
          x="0"
          y="0"
        />
      </svg>

      <svg
        v-if="showHalfFilledStar"
        class="c-feedback-company__star"
        viewBox="0 0 32 32"
      >
        <use
          xlink:href="#c-feedback-company__star-source"
          x="0"
          y="0"
          class="c-feedback-company__star--half"
        />
      </svg>

      <svg
        v-for="(star, index) in totalEmptyStars"
        :key="`empty-star-${index}`"
        class="c-feedback-company__star"
        viewBox="0 0 32 32"
      >
        <use
          xlink:href="#c-feedback-company__star-source"
          x="0"
          y="0"
          class="c-feedback-company__star--empty"
        />
      </svg>
    </p>
  </div>
</template>

<script>
export default {
    props: {
        score: {
            default: 0,
            type: Number
        }
    },
    data() {
        return {
            maxScore: 5
        }
    },
    computed: {
        roundedScore() {
            if (this.score > 100) return 100
            if (this.score < 0) return 0
            return Math.round(this.score)
        },
        currentScore() {
            // Round the score up or down to the nearest multiple of 20
            return Math.round(this.roundedScore / 10) * 10
        },
        totalFilledStars() {
            // Rounds the number to the largest int before returning the value
            return Math.floor(this.currentScore * 0.05)
        },
        totalEmptyStars() {
            // Halfs the score, then rounds the number to the nearest whole int.
            // We half it because it's a score out of 100 displayed with a 5 star rating.
            // Minus the score from the maximum possible score to display the leftover (empty) stars.
            return this.maxScore - Math.floor(this.currentScore * 0.05) - this.showHalfFilledStar
        },
        showHalfFilledStar() {
            return this.currentScore % 20 !== 0
        }
    }
}
</script>
import { computed, ref } from "vue"

const _totalCount = ref(0)
const _top = ref(12)
let initialTop = _top.value

const _sorting = ref("")
const _skip = ref(0)

export default function usePagination(paginationOptions) {
    if(paginationOptions?.top) {
        _top.value = paginationOptions.top
        initialTop = paginationOptions.top
    }
    if(paginationOptions?.totalCount) _totalCount.value = paginationOptions.totalCount
    if(paginationOptions?.sorting?.length) _sorting.value = paginationOptions.sorting
    
    const sorting = computed({
        get() {
            return _sorting.value
        },
        set(v) {
            _sorting.value = v
        }
    })

    const top = computed({
        get() {
            return _top.value
        },
        set(v) {
            _top.value = v
        }
    })

    const skip = computed({
        get() {
            return _skip.value
        },
        set(v) {
            _skip.value = v
        }
    })

    const totalCount = computed({
        get() {
            return _totalCount.value
        },
        set(v) {
            _totalCount.value = v
        }
    })

    const remainingItems = computed(() => {
        return totalCount.value > skip.value ? totalCount.value - top.value : totalCount.value
    })
    
    const increaseTop = () => {
        top.value += initialTop
    }

    const resetPagination = () => {
        totalCount.value = 0
        top.value = initialTop
        sorting.value = ""
        skip.value = 0
    }
    
    return {
        top,
        skip,
        sorting,
        totalCount,
        remainingItems,
        initialTop,
        increaseTop,
        resetPagination
    }
}
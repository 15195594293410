<template>
  <div>
    <form @submit.prevent="checkValidationAndSubmit">
      <recess-multi-select
        v-model="tags"
        :search-input="true"
        :options="expertiseAreaOptions"
        :selected-options="tags"
        class="mb-3"
        label-text="Vakgebieden *"
        data-test="multi-select-tag"
        :error-message="$v.mPost.tags.$error ? 'Selecteer minimaal 1 en maximaal 5 tags' : ''"
      />
      <transition-group
        name="slide-fade"
        tag="div"  
      > 
        <recess-tag
          v-for="(tag,index) in tags"
          :key="index"
          :text="tag"
          data-test="post-tag"
          @remove="removeTag(tag)"
        />
      </transition-group>
      
      <recess-divider variant="xsmall" />
          
      <recess-input
        v-model="mPost.title"
        label-text="Titel *"
        placeholder-text="Titel"
        data-test="post-title-input"
        :error-message="$v.mPost.title.$error ? 'Titel is verplicht' : ''"
      />
      <recess-divider variant="xsmall"/>
      
      <recess-rich-text-editor 
        v-model="mPost.content" 
        data-test="post-content-input" 
        label-text="Inhoud *"
        :error-message="$v.mPost.content.$error ? 'Inhoud is verplicht' : ''"
      />
      <recess-divider variant="xsmall"/>
      
      <recess-alert
        v-if="isMaxFilesLimitReached"
        type="error"
        :text="maxFilesUploadErrorText"
        class="mt-3 mb-3"
      />
      <recess-file-upload
        ref="uploadFileInput"
        max-size-file="26214400"
        data-test="upload-document-input"
        :max-file-to-upload="5"
        label-text="Bijlage"
        :disabled="isMaxFilesLimitReached"
        :allowed-extensions="allowedAttachmentExtensions"
        @upload="files => setDocumentsToUpload(files)"
      />
          
      <template v-if="documentsToUpload">
        <div 
          v-for="(document, index) in documentsToUpload"
          :key="'upload'+index"
        >
          <uploaded-file-component
            :document-url="document.fileData"
            :document-name="document.fileName"
            :has-duplicated-file-name="document.hasDuplicatedFileName"
            :disabled="true"
            @deleted="removeUpload(document.fileName, index)"
          />
        </div>
      </template>
      
      <template v-if="attachments">
        <div 
          v-for="(attachment, index) in attachments"
          :key="index"
        >
          <uploaded-file-component
            :document-url="attachment.url"
            :document-name="attachment.description"
            data-test="download-existing-attachment"
            @deleted="onClickDeleteAttachment(index)"
          />
        </div>
      </template>
      
      <recess-divider variant="xsmall"/>
      
      <recess-button 
        class="mr-2 mb-2 mb-md-0 col-12 col-md-auto"
        title="Annuleren"
        variant="tertiary"
        :url="backUrl" 
      />
      <recess-button 
        class="u-min-width-200 u-min-width-200 col-12 col-md-auto" 
        :title="submitBtnText" 
        variant="primary" 
        data-test="submit-post-btn" type="submit"
      />
    </form>

    <remove-item-confirmation-component
      v-if="isAttachmentRemovalConfirmationPopupVisible"
      :is-visible="isAttachmentRemovalConfirmationPopupVisible"
      confirmation-text="Weet u zeker dat u deze bijlage wilt verwijderen?"
      data-test="confirm-attachment-removal-modal"
      @close="setIsAttachmentRemovalConfirmationPopupVisible(false)"
      @remove="deleteAttachment"
    />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { required } from 'vuelidate/lib/validators'
import { ref, onBeforeUnmount, onMounted, computed } from 'vue'
import useSocialLearningTags from '../../../../composables/useSocialLearningTags'
import useStore from '../../../../composables/useStore'
import useSocialLearningAttachment from '../../../../composables/useSocialLearningAttachment'
import UploadedFileComponent from '../../../atoms/UploadedFileComponent/UploadedFileComponent.vue'
import RemoveItemConfirmationComponent from "../../../atoms/RemoveItemConfirmationComponent/RemoveItemConfirmationComponent.vue"

export default {
    name: 'PostCreateEditForm',
    components: {
        UploadedFileComponent,
        RemoveItemConfirmationComponent
    },
    props: {
        initialPost: {
            type: Object,
            default: () => null
        },
        backUrl: {
            type: Object,
            default: () => {
                return { name: 'search-posts'}
            }
        },
        submitBtnText: {
            type: String,
            default: "Post"
        }
    },
    emits: ['submit', 'is-uploading'],
    setup(props, context) {
        const defaultPost = {
            title: "",
            content: "",
            tags: [],
            attachments: []
        }

        const store = useStore()
        const { 
            attachments,
            isAttachmentRemovalConfirmationPopupVisible,
            uploadFileInput,
            removeUpload,
            deleteAttachment,
            documentsToUpload, 
            allowedAttachmentExtensions, 
            maxFilesUploadErrorText,
            getUploadedAttachments,
            setDocumentsToUpload,
            setIsAttachmentRemovalConfirmationPopupVisible,
            onClickDeleteAttachment,
            setAttachments,
            isMaxFilesLimitReached
        } = useSocialLearningAttachment(context)
        const {
            minTags,
            maxTags,
            removeTag,
            prefillTags,
            deleteTag,
            expertiseAreaKey,
            tags,
            expertiseAreaOptions
        } = useSocialLearningTags(store)

        const mPost = ref(props.initialPost ? {...props.initialPost} : {...defaultPost})

        const initialize = () => {
            if (props.initialPost?.tags) prefillTags(props.initialPost?.tags)
            if (props.initialPost?.attachments) setAttachments(props.initialPost?.attachments)
        }

        const isDirty = computed(() => {
            const hasTagsChanged = tags.value?.length !== mPost.value?.tags?.length || mPost.value?.tags?.some(tag => !tags.value?.includes(tag.displayValue))
            const hasAttachmentsChanged = attachments.value?.length !== mPost.value?.attachments?.length || !isEqual(attachments.value,  mPost.value?.attachments)

            // Check if title, content, tags or attachments have been modified
            return Object.entries(mPost.value)?.some(([key, value]) => { return mPost.value?.[key] !== value })
                          || documentsToUpload.value?.length || hasAttachmentsChanged || hasTagsChanged 
        }) 

        const updatePost = async () => {
            if(!isDirty.value) {
                context.emit('submit', mPost.value)
                return
            }

            // Update modified attachments
            mPost.value.attachments = [...attachments.value]

            // Add newly added attachments to patch request
            if(documentsToUpload.value?.length) {
                const uploadedDocuments = await getUploadedAttachments()
                mPost.value.attachments = [...mPost.value?.attachments, ...uploadedDocuments]
            }

            if(tags.value?.length) {
                mPost.value.tags = tags.value?.map(tag => {
                    return {
                        type: expertiseAreaKey.value,
                        displayValue: tag,
                        value: tag
                    }
                })
            }

            context.emit('submit', mPost.value)
        }

        const createPost = async () => {
            if(documentsToUpload.value?.length) {
                mPost.value.attachments = await getUploadedAttachments()
            }
            
            if(tags.value?.length) {
                mPost.value.tags = tags.value?.map(tag => {
                    return {
                        type: expertiseAreaKey.value,
                        displayValue: tag,
                        value: tag
                    }
                })
            }

            context.emit('submit', mPost.value)
        }

        const submit = async () => {
            if (props.initialPost) await updatePost()
            else await createPost()
           
        }

        onBeforeUnmount(() => {
            mPost.value = defaultPost
        })       

        onMounted(() => {
            if (props.initialPost) initialize()
        })

        return {
            attachments,
            mPost,
            allowedAttachmentExtensions,
            expertiseAreaOptions,
            submit,
            removeTag,
            deleteAttachment,
            maxTags,
            minTags,
            setIsAttachmentRemovalConfirmationPopupVisible,
            onClickDeleteAttachment,
            tags,
            deleteTag,
            removeUpload,
            setDocumentsToUpload,
            isMaxFilesLimitReached,
            maxFilesUploadErrorText,
            documentsToUpload,
            uploadFileInput,
            isAttachmentRemovalConfirmationPopupVisible,
            isDirty
        }
    },
    validations() {
        return {
            mPost: {
                title: { required },
                content: { required },
                tags: { 
                    isValid() {
                        return (this.minTags <= this.tags.length) && (this.maxTags >= this.tags.length)
                    } 
                }
            }
        }
    },
    methods: {
        async checkValidationAndSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid) { 
                return
            }
            await this.submit()
        }
    }
}
</script>
<template>
  <div>
    <recess-card
      v-if="selectedStartMoments && selectedStartMoments.length"
      variant="variant1"
    >
      <slot name="selected-start-moments-text">
        <h4>Gekozen startmoment(en)</h4>
      </slot>

      <start-moments-listing-table 
        :course="course"
        :start-moments="selectedStartMoments"
        :table-header="selectedStartMomentsTableHeader"
        :nr-of-people-to-enroll="nrOfPeopleToEnroll"
        :show-capacity="showCapacity"
        :assortment-id="assortmentId"
        :display-startmoment-action-button="startmomentUpdatesEnabled"
      >
        <template
          slot="startmoment-action-button"
          slot-scope="props">
          <recess-button
            v-if="startmomentUpdatesEnabled"
            data-test="remove-start-moment-button"
            class="col-12 col-md-auto"
            title="Wijzig"
            variant="tertiary"
            @click="removeStartMoment(props.startMoment.startMomentIndex)"
          />
        </template>
      </start-moments-listing-table>
    </recess-card>

    <transition name="slide">
      <div
        v-show="openStartMomentListing"
      >
        <recess-divider v-if="selectedStartMoments.length" />
      
        <slot name="start-moments-text">
          <h4>Nieuw(e) startmoment(en) kiezen</h4>
        </slot>

        <!-- Startmoments -->
        <start-moments-listing-table 
          v-if="startMomentTableData && startMomentTableData.length"
          :course="course"
          :start-moments="startMomentTableData"
          :table-header="startMomentsTableHeader"
          :nr-of-people-to-enroll="nrOfPeopleToEnroll"
          :show-capacity="showCapacity"
          :facets="facets"
          :assortment-id="assortmentId"
          :total-count="totalCount"
          :page-number="pageNumber"
          :page-size="pageSize"
          @pagination-clicked="clickedPage => $emit('pagination-clicked', clickedPage)"
          @facet-change="selectedFacets => $emit('facet-change', selectedFacets)"
        >
          <template
            slot="startmoment-action-button"
            slot-scope="props">
            <recess-button
              :disabled="props.startMoment.blockFullyBookedIncompanyStartMoment"
              class="col-12 col-md-auto"
              data-test="select-start-moment-button"
              :data-test-id="`select-start-moment-button-${props.startMoment.externalId}`"
              title="Opslaan"
              variant="primary"
              @click.native="$emit('select-start-moment', props.startMoment)"
            />
          </template>
        </start-moments-listing-table>

        <!-- Show message if there are not results in the filters or no startmoments at all -->
        <recess-alert
          v-if="noResultsMessage"
          class="mt-5"
          :text="noResultsMessage"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import startMomentHelper from '../../../modules/startMomentHelper'
import priceFormatter from '../../../modules/priceFormatter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import StartMomentsListingTable from '../StartMomentsListingTable/StartMomentsListingTable.vue'

export default {
    components: {
        StartMomentsListingTable
    },
    perimeters: [featuresPerimeter],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        startMoments: {
            type: Array,
            default: () => []
        },
        facets: {
            type: Array,
            default: () => []
        },
        startmomentUpdatesEnabled: {
            type: Boolean,
            default: false
        },
        selectedStartMoments: {
            type: Array,
            default: () => []
        },
        showCapacity: {
            type: Boolean,
            default: false
        },
        defaultOpen: {
            type: Boolean,
            default: false
        },
        assortmentId: {
            type: String,
            default: null
        },
        nrOfPeopleToEnroll: {
            type: [Number, null],
            default: null
        },
        tableHeader: {
            type: Array,
            default: () => []
        },
        totalCount: {
            type: Number,
            default: 0
        },
        pageNumber: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        course: {
            type: Object,
            default: () => {}
        },
        showNoStartMomentsMessage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mSelectedOptions: [],
            showStartMoments: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['displayStartMomentsType', 'limitIncompanyEnrollments', 'displayVatInPrices']),
        productType() {
            return this.course?.productType
        },
        courseId() {
            return this.course?.id
        },
        courseName() {
            return this.course?.name
        },
        openStartMomentListing() {
            return (this.showStartMoments || this.defaultOpen) && this.selectedStartMoments.length < 2
        },
        selectedStartMomentsTableHeader() {
            return startMomentHelper.getSelectedStartMomentsTableHeader(this.showCapacity, this.displayVatInPrices, this.$isAllowed('displayPricingInformation'), this.startmomentUpdatesEnabled)
        },
        startMomentsTableHeader() {
            return startMomentHelper.getEnrollmentStartMomentTableHeader(this.showCapacity, this.displayVatInPrices, this.$isAllowed('displayPricingInformation'))
        },
        cardVariant() {
            return this.showStartMomentFilters ? '' : 'variant1'
        },
        selectedStartMomentsIds() {
            return this.selectedStartMoments?.map(selectedStartMoment => selectedStartMoment.startMomentId)
        },
        startMomentTableData() {
            let startMomentsData = startMomentHelper.mapStartMoments(this.startMoments, this.limitIncompanyEnrollments, this.productType)

            // filter out the selected start moments
            this.selectedStartMoments?.forEach(csm => {
                startMomentsData = startMomentsData?.filter(
                    sm => sm.startMomentId !== csm?.externalId
                )
            })

            // also filter the data set on the selected filters
            startMomentsData = startMomentsData?.filter(startmoment => !this.selectedStartMomentsIds.includes(startmoment.startMomentId))

            return startMomentsData
        },
        noResultsMessage() {            
            if (!this.isLoading && !this.startMomentTableData?.length || (this.showNoStartMomentsMessage && this.selectedStartMoments.length < 2)) {
                // If the user selected a filter, and there is no match, show a text saying there is no match with the selected filter,
                // Otherwise if there are no results, and it is not because of the filters, show no startmoments message
                return this.mSelectedOptions.length
                    ? 'Er zijn geen resultaten beschikbaar die voldoen aan de gekozen filters.'
                    : 'Er zijn geen startmomenten beschikbaar.'
            }
            return null
        }
    },
    methods: {
        removeStartMoment(startMomentIndex) {
            // if the second start moment is selected to be cleared, only clear that one, otherwise, clear both..
            if (startMomentIndex > 0) {
                this.$emit('clear-start-moment', startMomentIndex)
            } else {
                this.$emit('clear-start-moments')
            }

            // show start moment table
            if (this.startMoments) this.showStartMoments = true
        },
        formatPrice: priceFormatter.formatPrice
    }
}
</script>

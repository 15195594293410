import { getOplzClient } from './apiClient'

const baseUrl = '/visited-courses'

export const getVisitedCourses = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}`).then(response => {
        return response?.data
    })
}

export const postVisitedCourse = async (courseId, assortmentId) => {
    const client = await getOplzClient()
    return client
        .post(`${baseUrl}`, null, {
            params: {
                courseId,
                assortmentId
                
            }
        })
        .then(response => {
            return response?.data
        })
}



import { computed } from 'vue'
import useStore from './useStore'

export default function useCourseSearchItem(course = {}) {
    const store = useStore()

    const isEnrollmentFlow = computed(() => !!store.getters["enrollmentModule/enrollmentType"])
    
    const isColumnView = computed(() => store.getters['searchModule/isColumnView'])
    const searchFromTeamAssortments = computed(() => store.getters['searchModule/searchFromTeamAssortments'])

    const courseIdsToCompare = computed(() => store.getters['compareCoursesModule/courseIdsToCompare'])
    const isCourseToCompareChecked = computed(() => courseIdsToCompare.value?.some(c => c.id === course.id))
    const coursesToCompareCount = computed(() => store.getters['compareCoursesModule/coursesToCompareCount'])
    const toggleCourseIdToCompare = () => store.commit('compareCoursesModule/toggleCourseIdToCompare', { id: course.id, assortmentId: course.assortmentId })
        
    const wishListItems = computed(() => store.getters['userModule/getUserWishListItems'])
    const isOnWishlist = computed(() => wishListItems.value?.some(wishListItem => wishListItem.courseId === course.id))
    const updateUserWishList = () => {
        const newWishListItem = {
            $id: null,
            courseId: course.id,
            userId: null
        }
        store.commit('userModule/toggleUserCourseWishListItem', newWishListItem)
    }

    const segments = computed(() => store.state?.segmentModule.segments)
    const segmentImageUrl = computed(() => {
        const defaultImageUrl = segments.value?.find(segment => {
            return segment.segmentName?.toLowerCase() === "default"
        })?.segmentImageUrl

        if(!course.segments || !course.segments?.length) return defaultImageUrl
        
        const existingSegmentImageUrl = segments.value?.find(segment => segment.segmentName?.toLowerCase() === course.segments[0]?.toLowerCase())?.segmentImageUrl

        return existingSegmentImageUrl || defaultImageUrl
    })

    
    const learningMethodFormatText = computed(() => course.learningMethodFormatDisplayValue || course.learningMethodFormat)
    const learningMethodTypeText = computed(() => {
        const { learningMethodTypeDisplayValue, learningMethodType, learningMethodFormatDisplayValue, learningMethodFormat } = course
        if(!learningMethodTypeDisplayValue && !learningMethodType) return null
        
        const format = learningMethodFormatDisplayValue || learningMethodFormat
        const type = learningMethodTypeDisplayValue || learningMethodType

        return format ? `(${type})` : type
    })

    return {
        isColumnView,
        courseIdsToCompare,
        coursesToCompareCount,
        isEnrollmentFlow,
        isCourseToCompareChecked,
        searchFromTeamAssortments,
        isOnWishlist,
        segmentImageUrl,
        learningMethodFormatText,
        learningMethodTypeText,
        updateUserWishList,
        toggleCourseIdToCompare
    }
}
<template>
  <div class="c-enrollment-step">
    <h4 class="u-textcolor-primary">Budget</h4>
    <span>Indien u budget heeft kunt u er voor kiezen dit budget te gebruiken voor de aankoop van een ontwikkelactiviteit. U kunt aangeven hoeveel van uw budget u wilt inzetten. Houd hierbij rekening met de regels die binnen uw bedrijf gelden voor de inzet van het budget. Bij een eventueel tekort aan budget kunt u er voor kiezen om het verschil zelf te betalen óf uw werkgever te verzoeken het verschil voor zijn rekening te nemen.</span>

    <recess-divider />

    <div class="row">
      <span class="col-12 col-md-3">Prijs {{ vatText }} voor:</span>
      <span class="col-12 col-md-6 font-weight-bold mt-3 mt-md-0">{{ courseName }}</span>
      <span
        data-test="course-price"
        class="col-md-2 font-weight-bold mt-3 mt-md-0"
      >{{ formatPrice(coursePrice) }}</span>
    </div>
    <recess-alert
      v-if="showHighestStartmomentPriceWarning"
      class="my-3"
      type="info"
      data-test="highest-start-moment-price-alert"
      :text="highestStartmomentPriceWarningMessage"
    />

    <recess-divider />

    <!-- Budget list -->
    <span class="u-textcolor-primary font-weight-bold">Kies budget en bedrag</span>
    <div
      v-for="(budget, index) in formData.budgets"
      :key="index" 
      class="row mt-3 d-flex align-items-center"
      :data-test="`budget-item-${budget.id}`"
    >
      <span
        class="col-6 col-md-5"
        data-test="budget-name"
      >
        {{ `${budget.name}${budget.approvalRequired ? '*' : ''}` }}
        {{ budget.endDate ? ` - Geldig t/m ${formatDate(budget.endDate)}` : '' }}
        {{ ` - Beschikbaar bedrag: ${formatPrice(budget.currentAmount)}` }}</span>
      <recess-input
        v-model="budget.selectedAmount"
        :has-error="+budget.selectedAmount > budget.currentAmount || $v.formData.budgets.$each[index].$invalid"
        placeholder-text="00.00"
        data-test="budget-input"
        step="any"
        type="number"
        class="col-6 col-md-2"
        @input="value => checkSelectedBudget(value,budget)"
        @blur="budget.selectedAmount = roundToTwo(budget.selectedAmount)"
      />
      <span class="col-4 d-none d-md-flex">Resterend: {{ formatPrice(budget.currentAmount - (+budget.selectedAmount || 0)) }} </span>
    </div>

    <!-- Error message -->
    <transition name="fade">
      <span
        v-if="isUsedBudgetHigherThanCoursePrice"
        class="mt-3 u-error-text pl-0"
        data-test="error-text"
      >Het bedrag dat u wilt gebruiken is hoger dan het benodigde bedrag. Pas het te gebruiken budget aan.</span>
    </transition>
    
    <!-- Total used budget and remaining amount -->
    <div class="row mt-3">
      <div class="col-md-5"></div>
      <div class="col-6 col-md-2">
        <span
          data-test="used-budget"
          class="d-flex justify-content-end"
        >{{ formatPrice(usedBudgetsAmount) }}</span>
        <recess-divider
          variant="xsmall"
          show-line
        />
        <span
          data-test="remaining-amount"
          class="d-flex justify-content-end font-weight-bold"
        >{{ formatPrice(remainingTotalAmount) }}</span>
      </div>
      <div class="col-6 col-md-5">
        <span>Aangewend budget</span>
        <recess-divider variant="xsmall" />
        <span class="font-weight-bold">Te betalen door werkgever **</span>
      </div>
    </div>

    <recess-divider />

    <!-- TeamLeader selection -->
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="isTeamLeaderRequired">
        <h4 class="u-textcolor-primary">Leidinggevende kiezen</h4>
        <p>Voor deze ontwikkelactiviteit is goedkeuring van een leidinggevende nodig. Kies hier een leidinggevende die wordt genotificeerd over uw inschrijving.</p>

        <div
          class="d-flex align-items-center row"
        >
          <div
            class="col-12 col-md-7"
            data-test="team-leader-selection"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <loader-component
                v-if="isFetchingTeamLeaders"
                variant="small"
                color="primary"
                :overlay="!selectFirstApproverWithSearchInput ? 'white' : null"
              />
            </transition>
            <div v-if="selectFirstApproverWithSearchInput">
              <recess-autocomplete
                :value="selectedTeamLeaderEmail"
                placeholder="Naam of e-mail"
                data-test="team-leader-input"
                label-text="Leidinggevende"
                item-text="email"
                item-sub-text="name"
                :error-message="teamleadValidationError"
                :on-query="queryFirstApprover"
                @input="val => setSelectedTeamLeaderEmail(val)"
              />
            </div>
            <recess-select
              v-else
              v-model="formData.assignedApproverId"
              v-observe-visibility="{ callback: fetchTeamLeaders, once: true }"
              :default-value="formData.assignedApproverId"
              data-test="team-leader-input"
              :options="teamLeaderOptions"
              :error-message="$v.formData.assignedApproverId.$invalid ? 'Leidinggevende is verplicht' : ''"
            />
          </div>
          <recess-divider />
        </div>
      </div>
    </transition>

    <span>* Gebruik van dit budget vereist altijd goedkeuring van uw leidinggevende.</span>
    <p class="mb-0">** Dit bedrag wordt volgens de geldende regels binnen {{ accountName }} ter goedkeuring aangeboden.</p>

    <recess-alert
      v-if="insufficientBudgetValidationError"
      :text="insufficientBudgetValidationError"
      type="error"
      class="mb-3"
    />

    <enrollment-step-buttons v-on="{ ...$listeners, 'next-step': confirmStep }" />

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { decimal, requiredIf } from 'vuelidate/lib/validators'
import priceFormatter from '../../../../modules/priceFormatter'
import DateUtils from '../../../../utils/DateUtils'
import numberFormatter from '../../../../modules/numberFormatter'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import coursePriceHelper from '../../../../modules/coursePriceHelper'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'
import { highestStartmomentPriceWarningMessage } from '../../../../constants/highestStartmomentPriceWarningMessage'

export default {
    components: { 
        LoaderComponent,
        EnrollmentStepButtons
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            isLoading: false,
            sufficientBudgetUse: true,
            highestStartmomentPriceWarningMessage
        }
    },
    validations() {
        return {
            formData: {
                budgets: {
                    $each: {
                        selectedAmount: { 
                            decimal,
                            isValid: (value, budget) =>  {
                                if (!value) return true
                                return value <= budget.currentAmount && value >= 0
                            }
                        }
                    }
                },
                assignedApproverId: {
                    required: requiredIf(() => {
                        return this.isTeamLeaderRequired
                    })
                }
            },
            selectedTeamLeaderEmail: {
                required: requiredIf(() => {
                    return this.isTeamLeaderRequired && this.selectFirstApproverWithSearchInput
                }),
                isMatchingTeamleaderEmail() { 
                    if(this.selectFirstApproverWithSearchInput && this.isTeamLeaderRequired) {
                        return this.isMatchingTeamleaderEmail
                    }
                    return true
                }
            },
            sufficientBudgetUse: {
                required: requiredIf(() => {
                    return this.blockEnrollmentInsufficientBudget
                }),
                usedEnoughBudgetAmount () { 
                    return this.usedEnoughBudgetAmount
                }
            }
        }
    },
    computed: {
        ...mapState('enrollmentModule', [
            'teamLeaders', 
            'prices', 
            'formData', 
            'approvalRequired'
        ]),
        ...mapGetters('enrollmentModule', [
            'courseName',
            'potentialTeamLeaders', 
            'isFetchingTeamLeaders',
            'coursePrice', 
            'selectedStartMoments',
            'showHighestStartmomentPriceWarning', 
            'selectedTeamLeaderEmail'
        ]),
        ...mapGetters('accountModule', [
            'displayVatInPrices',
            'accountName', 
            'minimalOrderPriceForApproval', 
            "selectFirstApproverWithSearchInput", 
            "blockEnrollmentInsufficientBudget"
        ]),
        vatText() {
            return coursePriceHelper.getVatText(this.displayVatInPrices)
        },
        teamleadValidationError() {
            if(this.selectedTeamLeaderEmail?.length >= 3 && !this.isMatchingTeamleaderEmail &&!this.isFetchingTeamLeaders  && !this.potentialTeamLeaders.length) {
                return 'Er zijn geen resultaten gevonden'
            }
            
            if(!this.$v.selectedTeamLeaderEmail.required && this.$v.selectedTeamLeaderEmail.$error) {
                return 'Leidinggevende is verplicht'
            }

            if(this.selectedTeamLeaderEmail?.length >= 3 && !this.isFetchingTeamLeaders && !this.isMatchingTeamleaderEmail) {
                return 'Kies een leidinggevende uit de lijst' 
            }
          
            return ''
        },
        insufficientBudgetValidationError() {
            if (this.$v.sufficientBudgetUse.$error) {
                return 'U heeft minder budget geselecteerd dan de kosten voor deze ontwikkelactiviteit. Gebruik een groter deel van uw budget en/of vraag meer budget aan wanneer u zich toch wilt kunnen inschrijven voor deze ontwikkelactiviteit.'
            }
          
            return ''
        },
        usedEnoughBudgetAmount() {
            if(!this.blockEnrollmentInsufficientBudget)
                return true
            
            return this.coursePrice === this.usedBudgetsAmount
        },
        isMatchingTeamleaderEmail() {
            return this.potentialTeamLeaders?.some(x => x.emailAddress?.toLowerCase() === this.selectedTeamLeaderEmail?.toLowerCase())
        },
        teamLeaderOptions() {
            // Set default team leader
            return this.teamLeaders.map(teamLeader => {
                let displayText = `${teamLeader.firstName} `
                if (teamLeader.middleName) displayText += `${teamLeader.middleName} `
                displayText += `${teamLeader.lastName} (${teamLeader.emailAddress})`
                return {
                    value: teamLeader.userId,
                    displayText
                }
            })
        },
        usedBudgetsAmount() {
            return this.roundToTwo(this.formData?.budgets?.reduce((acc, budget) => acc + (+budget.selectedAmount || 0), 0))
        },
        remainingTotalAmount() {
            return this.roundToTwo(this.coursePrice - this.usedBudgetsAmount) || 0
        },
        isUsedBudgetHigherThanCoursePrice() {
            return this.usedBudgetsAmount > this.coursePrice
        },
        budgetRequiresApproval() {
            return this.formData?.budgets?.some(budget => budget.approvalRequired && budget.selectedAmount > 0)
        }, 
        isBudgetCoveringMinimalPriceForApproval() {
            return !this.remainingTotalAmount || this.remainingTotalAmount < this.minimalOrderPriceForApproval
        },
        isTeamLeaderRequired() {
            // Show teamLeader dropdown if 
            // - budget that requires approval is used
            // - enrollment need appoval and the left over course prices is > than the minimalOrderPriceForApproval
            return this.budgetRequiresApproval || (this.approvalRequired && !this.isBudgetCoveringMinimalPriceForApproval)
        }
    },
    watch: {
        isBudgetCoveringMinimalPriceForApproval(newVal) {
            if(!this.budgetRequiresApproval) this.setApprovalRequired(!newVal)
        },
        budgetRequiresApproval() {
            this.setApprovalRequired(true)
        },
        async isTeamLeaderRequired(newVal) {
            if(!newVal) {
                this.setAssignedApproverId(null)
                this.setSelectedTeamLeaderEmail(null)
                this.setApprovalRequired(false)
                return
            }

            if(newVal) {
                this.setTeamLeaderInputInitialValue()
            }
        },
        selectedTeamLeaderEmail(newVal) {
            if(this.isMatchingTeamleaderEmail) {
                const teamleader = this.potentialTeamLeaders?.find(u => u.emailAddress?.toLowerCase() === newVal?.toLowerCase())
                if(teamleader){
                    this.setAssignedApproverId(teamleader.userId)
                    this.setSelectedTeamLeaderEmail(teamleader.emailAddress)
                }
                return
            } 

            if(newVal?.length >= 3 && !this.isMatchingTeamleaderEmail) {
                this.searchTeamleader(newVal)
            }
        }
    },
    async mounted() {
        this.$gtag.pageview({ page_path: '/inschrijven-student-budget' })

        this.setTeamLeaderInputInitialValue()
    },
    async activated() {
        if (this.selectedStartMoments?.length) await this.getCoursePrice()
    },
    methods: {
        ...mapActions('enrollmentModule', [
            'getTeamLeaders',
            'searchTeamleader',
            'getCoursePrice',
            'setSearchInputDefaultValue',
            'setDefaultTeamLeader'
        ]),
        ...mapMutations('enrollmentModule', [
            'setPotentialTeamLeaders', 
            'setIsFetchingTeamLeaders', 
            'setSelectedTeamLeaderEmail', 
            'setApprovalRequired', 
            'setAssignedApproverId', 
            'setSelectedTeamLeaderEmail'
        ]),
        fetchTeamLeaders() {
            if(this.isTeamLeaderRequired && (!this.teamLeaders || !this.teamLeaders.length)) this.getTeamLeaders()
        },
        setTeamLeaderInputInitialValue() {
            if(this.selectFirstApproverWithSearchInput) this.setSearchInputDefaultValue() 
            else this.setDefaultTeamLeader()
        },
        // Auto-set input model
        checkSelectedBudget(value, updatedBudget) {
            // If input value is > than the course price set it to the remaining amount 
            // or in case the remaining amount is > than the budget current amount set it 
            // to the current amount
            if (this.usedBudgetsAmount > this.coursePrice) {
                updatedBudget.selectedAmount = this.coursePrice - (this.usedBudgetsAmount - value) <= updatedBudget.currentAmount 
                    ? this.roundToTwo(this.coursePrice - (this.usedBudgetsAmount - value)) 
                    : updatedBudget.currentAmount 
                return
            }
            
            // If input value < 0 set it to 0
            if (value < 0) updatedBudget.selectedAmount = 0

            // If input value is > that the budget current amount se it to the current amount
            if (value > updatedBudget.currentAmount) updatedBudget.selectedAmount = updatedBudget.currentAmount
        },
        confirmStep() {
            this.$v.$touch()
            if (this.$v.$invalid || this.isUsedBudgetHigherThanCoursePrice) {
                return
            }
                
            // Reset assignedApprover if is not required
            if (!this.isTeamLeaderRequired) this.setAssignedApproverId(null)
            this.$emit('next-step')
            
        },
        /** 
         * Return list of teamleaders that match the search input
         * @param query {string} */
        async queryFirstApprover(query) {
            if (!query) return []

            await this.searchTeamleader(query)

            const list = this.potentialTeamLeaders.filter(item => {
                return item.emailAddress.toLowerCase().includes(query.toLowerCase())
                    || item.firstName.toLowerCase().includes(query.toLowerCase())
                    || item.middleName.toLowerCase().includes(query.toLowerCase())
                    || item.lastName.toLowerCase().includes(query.toLowerCase())
            })
            return list.map(e => ({
                id: e.userId,
                email: e.emailAddress,
                name: `${e.firstName} ${e.lastName}`
            }))
        },
        formatPrice: priceFormatter.formatPrice,
        formatDate: e => DateUtils.formatDate(e),
        roundToTwo: numberFormatter.roundToTwo
    }
}
</script>

<template>
  <recess-layout-static>
    <study-contract-approval-page-main
      v-if="!showThankYouPage"
      @approved="showThankYouPage = true"
    />
    <study-contract-approval-page-thank-you v-else />
  </recess-layout-static>
</template>

<script>
import StudyContractApprovalPageMain from '../../organisms/StudyContractApprovalPage/StudyContractApprovalPageMain.vue'
import StudyContractApprovalPageThankYou from '../../organisms/StudyContractApprovalPage/StudyContractApprovalPageThankYou.vue'

export default {
    components: {
        StudyContractApprovalPageMain,
        StudyContractApprovalPageThankYou
    },
    data() {
        return {
            showThankYouPage: false
        }
    }
}
</script>
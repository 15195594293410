<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <lightbox-component
    :is-visible="showTeamLeaderEnrollmentConfirmationText || showStudentEnrollmentConfirmationText"
    :show-close-button="false"
  >
    <h3>Let op!</h3>
    <p
      data-test="team-leader-enrollment-confirmation-text"
      v-if="showTeamLeaderEnrollmentConfirmationText"
      v-html="teamLeaderEnrollmentConfirmationText"
    ></p>
    <p
      data-test="student-enrollment-confirmation-text"
      v-if="showStudentEnrollmentConfirmationText"
      v-html="studentEnrollmentConfirmationText"
    ></p>
    <recess-button
      class="u-position-right"
      title="Gelezen"
      variant="secondary"
      data-test="close-button"
      @click.native="$emit('close')"
    />
  </lightbox-component>
</template>

<script>
import { mapGetters } from 'vuex'
import LightboxComponent from '../../molecules/LightboxComponent/LightboxComponent.vue'

export default {
    name: 'EnrollmentConfirmationModal',
    components: {
        LightboxComponent
    },
    props: {
        showTeamLeaderEnrollmentConfirmationText: {
            type: Boolean,
            default: false
        },
        showStudentEnrollmentConfirmationText: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['teamLeaderEnrollmentConfirmationText', 'studentEnrollmentConfirmationText'])
    }
}
</script>
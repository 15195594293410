<template>
  <div class="c-enrollment-step">
    <h4 class="u-textcolor-primary mb-0">Selecteer studenten</h4>
    <students-table />

    <enrollment-step-buttons 
      :show-back-button="showBackButton"
      :show-next-button="!!selectedStudents.length" 
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import StudentsTable from '../../Tables/StudentsTable.vue'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'

export default {
    name: "EnrollmentStepSelectStudents",
    components: { 
        StudentsTable,
        EnrollmentStepButtons
    },
    perimeters: [featuresPerimeter],
    computed: {
        ...mapState('enrollmentModule', ['enrollmentType', 'isELearning']),
        ...mapGetters('enrollmentModule', ['selectedStudents']),
        showBackButton() {
            return this.enrollmentType === enrollmentTypes.COORDINATOR && !this.isELearning
        }
    },
    mounted() {     
        if (this.enrollmentType === enrollmentTypes.TEAMLEADER)
            this.$gtag.pageview({ page_path: '/inschrijven-teamlead-studenten' })
    }
}
</script>

const configurationModule = {
    namespaced: true,

    state() {
        return {
            currentEnvironment: '',
            oidcConfig: ''
        }
    },

    getters: {
        currentEnvironment: state => {
            return state.currentEnvironment
        },
        oidcConfig: state => {
            return state.oidcConfig
        }
    },

    mutations: {
        setCurrentEnvironment(state, payload) {
            state.currentEnvironment = payload
        },
        setOidcConfig(state, payload) {
            state.oidcConfig = payload
        }
    }
}

export default configurationModule

<template>
  <recess-layout-static v-if="$isAllowed('displayProjectRequest') && $isAllowed('routeProjectRequestsPage')">
    <project-request-details page-title="Projectaanvraag gegevens"/>
    
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import ProjectRequestDetails from "../../organisms/ProjectRequests/ProjectRequestDetails/ProjectRequestDetails.vue"

export default {
    components: {
        ProjectRequestDetails
    },
    perimeters: [featuresPerimeter, routePerimeter]
}
</script>
  
  
export const STARTMOMENTS =  { code: "STARTMOMENTS", label: 'Datum en locatie kiezen' }
export const PERSONALDATA = { code: "PERSONALDATA", label: 'Persoonlijke gegevens' }
export const TEAMLEADER =  { code: "TEAMLEADER", label: 'Leidinggevende' }
export const SUMMARY = { code: "SUMMARY", label: 'Controle gegevens' }
export const SELECTSTUDENTS = { code: "SELECTSTUDENTS", label: 'Studenten selecteren' }
export const SELECTCOURSE = { code: "SELECTCOURSE", label: 'Cursus kiezen' }
export const OPTIONS = { code: "OPTIONS", label: 'Extra informatie' }
export const BILLINGADDRESS = { code: "BILLINGADDRESS", label: 'Betalingsgegevens' }
export const BUDGETS = { code: "BUDGETS", label: 'Budget' }
export const LEARNINGRESULTS = { code: "LEARNINGRESULTS", label: 'Resultaten' }

export const TEAMLEADERSTEPS = [
    SELECTSTUDENTS,
    SELECTCOURSE,
    STARTMOMENTS,
    OPTIONS, 
    LEARNINGRESULTS,
    SUMMARY
]

export const STUDENTSTEPS = [
    STARTMOMENTS,
    PERSONALDATA,
    BUDGETS,
    BILLINGADDRESS, 
    TEAMLEADER, 
    LEARNINGRESULTS,
    SUMMARY
]

export const COORDINATORSTEPS = [
    STARTMOMENTS,
    SELECTSTUDENTS,
    TEAMLEADER,
    SUMMARY
]

export default {
    STARTMOMENTS,
    PERSONALDATA,
    TEAMLEADER,
    SUMMARY,
    SELECTSTUDENTS,
    SELECTCOURSE,
    OPTIONS,
    BILLINGADDRESS,
    BUDGETS,
    TEAMLEADERSTEPS,
    STUDENTSTEPS
}

<template>
  <recess-layout-static v-if="$isAllowed('createProjectRequest')" :size="1200">
    <recess-divider />
    <h1>Projectaanvraag</h1>
    <p>
      Op deze pagina kunt u een nieuwe projectaanvraag aanmaken. De velden met een * zijn verplicht.
    </p>
    <create-project-request-form  />
    <recess-divider />
  </recess-layout-static>
</template>
    
<script>
import projectRequestPerimeter from '../../../perimeters/projectRequestPerimeter'
import CreateProjectRequestForm from '../../organisms/ProjectRequests/CreateProjectRequest/CreateProjectRequestForm.vue'

export default {
    components: { CreateProjectRequestForm },
    perimeters: [projectRequestPerimeter]
}
</script>
  
  
    
    
import axios from 'axios'
import { getOplzClient } from './apiClient'

const FetchSearchCancelToken = axios.CancelToken
let fetchSearchCancel

const baseUrl = '/providers'

const search = async () => {
    const client = await getOplzClient()
    
    const url = `${baseUrl}`

    return client.get(url, { 
        cancelToken: new FetchSearchCancelToken(c => {
            fetchSearchCancel = c
        })
    }).then(res => res.data)
}

const cancelFetchSearch = () => {
    if (fetchSearchCancel !== undefined) {
        fetchSearchCancel()
    }
}

export default {
    search,
    cancelFetchSearch
}
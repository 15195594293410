<template>
  <div class="c-account-quick-links">
    <div v-if="hasData">
      <recess-divider />
      <transition name="fade">
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>
      
      <h4 data-test="quick-links-label">{{customQuickLinksLabel}}</h4>

      <hooper
        ref="hooper"
        :settings="hooperSettings"
        :height="250"
      >
        <slide
          v-for="(ql, index) in quickLinks"
          :key="index"
        >
          <carousel-item>
            <template #label>
              {{ ql.title }}
            </template>
            <template #body>
              <carousel-image :image="ql.imageUrl" />
            </template>
            <template #description>
              <span>{{ ql.description }}</span>
            </template>
            <template #footer>
              <a
                :href="toExternalUrl(ql.linkUrl)"
                target="_blank"
                rel="noopener"
              >{{ ql.linkText || 'Ga naar functie' }}</a>
            </template>
          </carousel-item>
        </slide>
        <hooper-navigation
          v-if="showNavigations(quickLinks)"
          slot="hooper-addons"
          data-test="navigation"
        >
          <template #hooper-prev>
            <recess-icon
              icon="arrow-left"
              color="secondary"
              :height="25"
              :width="25"
            />
          </template>
          <template #hooper-next>
            <recess-icon
              icon="arrow-right"
              color="secondary"
              :height="25"
              :width="25"
            />
          </template>
        </hooper-navigation>
      </hooper>
    </div>
  </div>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

import { mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { loadQuickLinks } from '../../../api/quickLinkClient'
import CarouselItem from "../../atoms/CarouselItem/CarouselItem.vue"
import CarouselImage from '../../atoms/CarouselImage/CarouselImage.vue'
import { hooperSettings } from '../../../constants/carouselSettings'
import carouselHelper from '../../../modules/carouselHelper'

export default {
    components: {
        LoaderComponent,
        Hooper,
        Slide,
        HooperNavigation,
        CarouselImage,
        CarouselItem
    },
    data() {
        return {
            isLoading: false,
            quickLinks: [],
            hooperSettings
        }
    },
    computed: {
        ...mapGetters('accountModule', ['accountId', 'customQuickLinksLabel']),
        hasData() {
            return this.quickLinks.length > 0
        }
    },
    created() {
        this.fetchQuickLinks()
    },
    methods: {
        async fetchQuickLinks() {
            try {
                this.isLoading = true
                this.quickLinks = await loadQuickLinks(this.accountId)
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isLoading = false
            }
        },
        /** @param {string} url */
        toExternalUrl: url => {
            if (!url) return ''
            if (/^(http|https):\/\/[^ "]+$/.test(url)) return url

            return `https://${url}`
        },
        showNavigations: carouselHelper.showNavigations
    }
}
</script>
<template>
  <recess-layout-static>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <recess-divider />
    <h1>Budget wijzigen</h1>
    <p>Op deze pagina kunt u budgetten wijzigingen. Na het opslaan van de wijziging kan hij alleen ongedaan worden gemaakt door hem opnieuw te wijzigen. Alle wijzigingen worden opgeslagen en zijn inzichtelijk voor de medewerker, zijn teamleider en coordinators binnen uw organisatie.</p>

    <transition name="fade">
      <div
        v-if="budget"
        class="c-budget-edit row"
      >
        <recess-input
          v-model="userEmail"
          class="col-lg-6 col-md-6 col-12 mb-md-3"
          label-text="Medewerker e-mailadres"
          disabled
        />

        <recess-input
          v-model="budget.name"
          class="col-lg-6 col-md-6 col-12 mb-md-3"
          placeholder-text="Naam budget"
          label-text="Naam budget *"
          :error-message="getErrorMessage('name')"
          @input="resetAlerts"
        />

        <recess-input
          v-model="budget.startAmount"
          class="col-lg-6 col-md-6 col-12 mb-md-3"
          placeholder-text="Budget startbedrag"
          type="number"
          label-text="Budget startbedrag *"
          :error-message="getErrorMessage('startAmount')"
          @input="resetAlerts"
        />

        <div class="col-lg-6 col-md-6 col-12">
          <label class="d-block">
            <div class="c-recess-input__label qa-recess-input__label">Overig te besteden bedrag</div>
            <span class="c-budget-edit__amount">{{ availableAmount(budget) }}</span>
          </label>
        </div>

        <recess-date-picker
          v-model="budget.startDate"
          :default-value="budget.startDate"
          placeholder="dd-mm-jjjj"
          class="mb-3 col-lg-6 col-md-6 col-12"
          :error-message="$v.budget.startDate.$invalid ? 'Datum van ingang heeft geen geldige waarde' : ''"
          label-text="Geef een datum van ingang op (optioneel)"
        />

        <recess-date-picker
          v-model="budget.endDate"
          :default-value="budget.endDate"
          placeholder="dd-mm-jjjj"
          class="mb-3 col-lg-6 col-md-6 col-12"
          :error-message="$v.budget.endDate.$invalid ? 'Einddatum heeft geen geldige waarde' : ''"
          label-text="Geef een einddatum op (optioneel)"
        />

        <div class="col-12 mt-3">
          <recess-toggle-input
            v-model="budget.approvalRequired"
            label-text="Verplichte goedkeuring van leidinggevende bij gebruik van budget"
          />
        </div>

        <div class="col-sm-12 d-flex justify-content-between">
          <recess-button
            :url="{ name: 'my-team' , query: { 'my-team-tab': 'budgets', ...$route.query }}"
            title="Terug"
            variant="tertiary"
            class="mt-4 u-min-width-140"
          />

          <recess-button
            title="Opslaan"
            variant="primary"
            class="mt-4 u-min-width-140"
            @click.native="editBudget"
          />
        </div>
      </div>
    </transition>
    <recess-alert
      v-if="showNoBudgetText"
      text="Dit budget kan niet gevonden worden. Probeer het later opnieuw."
    />
    <recess-alert
      v-if="isSuccess"
      class="mt-5"
      type="success"
      text="Uw wijziging is succesvol doorgevoerd."
    />
    <recess-alert
      v-if="isError"
      class="mt-5"
      type="error"
      text="Oeps, er is iets mis gegaan met het opslaan. Probeer het later opnieuw."
    />
    <recess-alert
      v-if="currentAmountError"
      class="mt-5"
      type="error"
      text="Overig te besteden bedrag heeft geen geldige waarde."
    />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import { getBudgetById, editBudget } from '../../../api/budgetClient'
import DateUtils from '../../../utils/DateUtils'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { validationMessages, isValidDate } from '../../../modules/validationsHelper'

export default {
    name: 'EditBudget',
    components: {
        LoaderComponent
    },
    perimeters: [routePerimeter, featuresPerimeter],
    data() {
        return {
            validationMessages,
            originalBudgetState: null,
            budget: null,
            userEmail: null,
            isSuccess: false,
            isError: false,
            currentAmountError: false,
            showNoBudgetText: false,
            isFetchingBudget: false,
            isEditingBudget: false
        }
    },
    computed: {
        isLoading() {
            return this.isFetchingBudget || this.isEditingBudget
        }
    },
    validations: {
        budget: {
            name: { required },
            startAmount: { required, decimal, minValue: minValue(0) },
            startDate: { isValidDate },
            endDate: { isValidDate }
        }
    },
    created() {
        this.fetchBudget()
    },
    methods: {
        resetAlerts() {
            this.isSuccess = false
            this.isError = false
            this.currentAmountError = false
        },
        async fetchBudget() {
            try {
                this.isFetchingBudget = true
                const budgetId = this.$route?.params?.id || null
                await getBudgetById(budgetId).then(response => {
                    this.userEmail = response?.user?.emailAddress

                    // We put the response in a original state property so we can check what changed so we only patch what is really changed.
                    this.originalBudgetState = {
                        ...response,
                        startDate: DateUtils.formatDate(response?.startDate),
                        endDate: DateUtils.formatDate(response?.endDate)
                    }
                    this.budget = { ...this.originalBudgetState }
                })
            } catch (err) {
                this.showNoBudgetText = true
                throw new Error(err)
            } finally {
                this.isFetchingBudget = false
            }
        },
        async editBudget() {
            this.resetAlerts()
            this.$v.$touch()
            if (this.$v.$invalid) return

            // validate current amount
            if (this.availableAmount(this.budget) < 0) {
                this.currentAmountError = true
                return
            }

            try {
                this.isEditingBudget = true
                const patchArray = Object.keys(this.budget).reduce((acc, key) => {
                    if (this.budget[key] !== this.originalBudgetState[key]) {
                        acc.push({
                            op: 'replace',
                            path: `/${key}`,
                            value: this.formatValue(key, this.budget[key])
                        })
                    }
                    return acc
                }, [])

                await editBudget(patchArray, this.budget?.id).then(() => {
                    this.isSuccess = true
                    // fetch budget again to update original budget state
                    this.fetchBudget()
                })
            } catch (err) {
                this.isError = true
                throw new Error(err)
            } finally {
                this.isEditingBudget = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.budget[fieldName]
            const fieldValue = this.budget[fieldName]
            // Check Required
            if (fieldValidation?.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }
            // Check Invalid
            if (fieldValidation?.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        },
        formatValue(key, value) {
            if (key.includes('Date')) {
                return DateUtils.parseToISOString(value)
            }
            return value
        },
        availableAmount(budget) {
            if (budget.startAmount !== this.originalBudgetState.startAmount)
                return (
                    budget.currentAmount +
                    (budget.startAmount - this.originalBudgetState.startAmount)
                )
            return budget.currentAmount
        }
    }
}
</script>


/* eslint-disable eqeqeq */
export function setCookie(name, value, days) {
    let expires = ''
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value}${expires}; path=/; SameSite=None; Secure`
}
export function getCookie(name) {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(';')
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}
export function removeCookie(name) {
    setCookie(name, '', -999)
}

export default {
    setCookie,
    getCookie,
    removeCookie
}

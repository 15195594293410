import { CITY, YEARANDMONTH } from "../constants/facetKeys"
import { decodeBase64, encodeBase64 } from "./base64Helper"

/** @typedef {import('../models').FacetFilter} FacetFilter */

/**
 * Merge selected facet filter with already existing filters
 * 
 * @param {FacetFilter} newFacetFilters 
 * @param {FacetFilter} existingFacetFilters
 * @returns {FacetFilter | undefined} 
 */

/**
 * Get facet filters as base64
 * 
 * @param {import('../models').SelectedFacetFilter} selectedFacetFilter 
 * @param {string} existingEncodedFacetFilters
 * @returns {string} 
 */
const getFacetFiltersAsBase64 = (selectedFacetFilter, existingEncodedFacetFilters = null) => {
    let facetFilters = []
   
    if(!existingEncodedFacetFilters?.length) {
        facetFilters.push({
            key: selectedFacetFilter.key,
            values: new Array(selectedFacetFilter.value)
        })
    
        return encodeBase64(facetFilters)
    }

    facetFilters = [...decodeBase64(existingEncodedFacetFilters)]

    // Check selected facet filter is already in the existing query
    const existingFacetFilter = facetFilters?.find(facetFilter => facetFilter.key === selectedFacetFilter.key)
    
    if(!existingFacetFilter) {
        facetFilters.push({
            key: selectedFacetFilter.key,
            values: new Array(selectedFacetFilter.value)
        })
            
        return encodeBase64(facetFilters)
    }
            
    // Check selected value is already in the existing query
    const existingValue = existingFacetFilter.values?.find(value => value === selectedFacetFilter.value)

    if(!existingValue && selectedFacetFilter.checked) {
        existingFacetFilter.values.push(selectedFacetFilter.value)
        return encodeBase64(facetFilters)
    }

    if(existingValue && !selectedFacetFilter.checked) {
        existingFacetFilter.values = existingFacetFilter.values.filter(x => x !== selectedFacetFilter.value)

        if(!existingFacetFilter.values?.length) facetFilters = facetFilters.filter(ff => ff.key !== existingFacetFilter.key)
    }

    return encodeBase64(facetFilters) 
}

const addTooltipToFacets = facets => {
    if(!facets?.length) return []

    facets?.forEach(facet => {
        switch(facet.key) {
            case CITY: 
                facet.tooltip = 'Kies hier de gewenste locatie voor uw ontwikkelactiviteit.'
                break
            case YEARANDMONTH:
                facet.tooltip = 'De door u gekozen ontwikkelactiviteit heeft verschillende startmomenten. Kies hier de maand waarin u wilt starten.'
                break
            default: break
        }
    })

    return facets
}

export default {
    getFacetFiltersAsBase64,
    addTooltipToFacets
}
import learningResultSharingTypes from '../../constants/learningResultSharingTypes'
import LearningResultSharingUtils from '../../utils/LearningResultSharingUtils'
import enrollmentClient from '../../api/enrollmentClient'
import userClient from '../../api/userClient'
import courseClient from '../../api/courseClient'
import DateUtils from '../../utils/DateUtils'
import * as courseLearningMethodTypes from '../../constants/courseLearningMethodTypes'
import { getGuaranteeStringValue, getGuaranteeIntValue, getUserPersonalData } from '../../modules/enrollmentsHelper'
import { errorMessages } from '../../constants/errorMessages'
import * as productTypes from '../../constants/productTypes'
import startMomentHelper from '../../modules/startMomentHelper'
import learningResultSharingOptionsTypes from '../../constants/learningResultSharingOptionsTypes'

const verificationEnrollmentModule = {
    namespaced: true,
    state() {
        return {
            course: null,
            courseId: null,
            diplomaType: '',
            courseTitle: '',
            isELearning: false,
            isError: false,
            isDeclineError: false,
            declineErrorMessage: '',
            isFetchingCourse: false,
            isFetchingCountries: false,
            isFetchingEnrollment: false,
            isFetchingOrders: false,
            isVerifyingEnrollment: false,
            isFetchingEnrollmentDeclineReasons: false,
            isDecliningEnrollmentVerification: false,
            willUpdateUserData: false,
            declineReason: {
                code: null
            },
            enrollmentDeclineReasons: [],
            enrollment: {
                eduMSFirstStartMomentId: null,
                eduMSSecondStartMomentId: null,
                birthDay: null,
                isStartmomentUpdatesEnabled: true
            },
            countries: [],
            productType: null,
            selectedLearningResultOptions: null,
            learningResultSharingType: null,
            startMoments: [],
            selectedStartMoments: []
        }
    },
    getters: {
        willUpdateUserData(state) {
            return state.willUpdateUserData
        },
        course(state) {
            return state.course
        },
        courseName(state) {
            return state.courseName
        },
        firstSelectedStartMomentId(state) {
            return state.enrollment.eduMSFirstStartMomentId
        },
        secondSelectedStartMomentId(state) {
            return state.enrollment.eduMSSecondStartMomentId
        },
        selectedStartMoments(state) {
            return state.selectedStartMoments
        },
        startmomentUpdatesEnabled(state) {
            if (state.enrollment) {
                return state.enrollment.isStartmomentUpdatesEnabled
            }
            return true
        },
        isFirstStartMomentValid(state) {
            const firstStartMomentId = state.enrollment.eduMSFirstStartMomentId
            
            // If the course is elearning and the user didn't select a start moment, we don't even have to check it's valid
            if (state.isELearning && !firstStartMomentId) return true

            return !!firstStartMomentId && state.startMoments.some(starmoment => starmoment.externalId === firstStartMomentId)
        },
        isSecondStartMomentValid(state) {
            const secondStartMomentId = state.enrollment.eduMSSecondStartMomentId

            // If the user didn't select a second start moment, it's fine. We can still go through with the enrollment.
            if(!secondStartMomentId) return true

            return state.startMoments.some(starmoment => starmoment.externalId === secondStartMomentId)
        },
        isFirstStartMomentFullyBooked(_, getters) {
            return getters.selectedStartMoments[0]?.hasReachedMaxCapacity
        },
        showCapacity(state, _, rootgetters) {
            const showCapacityIconForAllCoursesSetting = rootgetters?.accountModule?.showCapacityIconForAllCourses

            return startMomentHelper.showCapacityDetail(
                showCapacityIconForAllCoursesSetting,
                state.productType
            )
        },
        // Limit incompany enrollments means if this is an incompany course, limit/block the start moments have reached their max capacity
        limitIncompanyEnrollmentsAndIsIncompany(state, _, rootgetters) {
            return rootgetters?.accountModule?.accountSettings?.limitIncompanyEnrollments && state.productType === productTypes.INCOMPANY
        },
        enrollmentLearningResult(state) {
            return state.enrollment?.enrollmentLearningResult
        },
        enrollmentTeamLeaderLearningResult(state) {
            return state.enrollment?.enrollmentTeamLeaderLearningResult
        }
    },
    mutations: {
        resetState(state) {
            state.course = null
            state.courseId = null
            state.diplomaType = ''
            state.courseTitle = ''
            state.isELearning = false
            state.isError = false
            state.isDeclineError = false
            state.declineErrorMessage = ''
            state.isFetchingEnrollment = false
            state.isFetchingCourse = false
            state.isFetchingCountries = false
            state.isFetchingOrders = false
            state.isVerifyingEnrollment = false
            state.isFetchingEnrollmentDeclineReasons = false
            state.isDecliningEnrollmentVerification = false
            state.willUpdateUserData = false
            state.declineReason = {
                code: null
            }
            state.enrollmentDeclineReasons = []
            state.startMoments = []
            state.enrollment = {}
            state.countries = []
            state.productType = null
            state.selectedLearningResultOptions = null      
            state.learningResultSharingType = null 
            state.startMoments = []
            state.selectedStartMoments = []    
        },
        toggleSelectedLearningResultOption(state, { optionKey, isOptionChecked }) {
            state.selectedLearningResultOptions[optionKey] = isOptionChecked
        },
        setSelectedLearningResultOptions(state, selectedLearningResultOptions) {
            state.selectedLearningResultOptions = selectedLearningResultOptions ? {...selectedLearningResultOptions} : null
        },
        removeAllConsentsOnSelectedLearningResultOptions(state) {
            const type = state.learningResultSharingType
            if (type === learningResultSharingTypes.LEGAL.key || type === learningResultSharingTypes.EMPLOYER.key) {
                state.selectedLearningResultOptions.agreesToShare = false
                state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].DIPLOMACONSENT] = false
                state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].PASSEDEXAMCONSENT] = false
                state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].COMPLETIONATRISKCONSENT] = false
                state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].PROGRESSPERCENTAGECONSENT] = false
            }
        },
        setDefaultApproverConsentsOnSelectedLearningResultOptions(state) {
            const type = state.learningResultSharingType
            const enrollmentTeamLeaderLearningResult = state.enrollment?.enrollmentTeamLeaderLearningResult
            state.selectedLearningResultOptions.agreesToShare = type === learningResultSharingTypes.LEGAL.key || type === learningResultSharingTypes.EMPLOYER.key
            state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].DIPLOMACONSENT] = enrollmentTeamLeaderLearningResult?.diplomaConsent
            state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].PASSEDEXAMCONSENT] = enrollmentTeamLeaderLearningResult?.passedExamConsent
            state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].COMPLETIONATRISKCONSENT] = enrollmentTeamLeaderLearningResult?.completionAtRiskConsent
            state.selectedLearningResultOptions[learningResultSharingOptionsTypes[type].PROGRESSPERCENTAGECONSENT] = enrollmentTeamLeaderLearningResult?.progressPercentageConsent
        },
        setSelectedStartMoment(state, selectedStartMoment) {
            const startMomentToAdd = startMomentHelper.mapSelectedStartMoment(selectedStartMoment, state.selectedStartMoments)

            state.selectedStartMoments.push(startMomentToAdd)
        },
        setLearningResultSharingType(state, learningResultSharingType) {
            state.learningResultSharingType = learningResultSharingType 
        },
        setEnrollment(state, enrollment) {
            enrollment.birthDay = DateUtils.formatDate(enrollment.birthDay)
            state.enrollment = { ...state.enrollment, ...enrollment }
        },
        setCourse(state, course) {
            state.course = course
        },
        setIsError(state, isError) {
            state.isError = isError
        },
        setIsDeclineError(state, isDeclineError) {
            state.isDeclineError = isDeclineError
        },
        setDeclineErrorMessage(state, declineErrorMessage) {
            state.declineErrorMessage = declineErrorMessage
        },
        setCourseId(state, courseId) {
            state.courseId = courseId
        },
        setDiplomaType(state, diplomaType) {
            state.diplomaType = diplomaType
        },
        setCourseTitle(state, courseTitle) {
            state.courseTitle = courseTitle
        },
        setIsELearning(state, isELearning) {
            state.isELearning = isELearning
        },
        setIsFetchingCourse(state, isFetchingCourse) {
            state.isFetchingCourse = isFetchingCourse
        },
        setIsFetchingCountries(state, isFetchingCountries) {
            state.isFetchingCountries = isFetchingCountries
        },
        setIsFetchingEnrollment(state, isFetchingEnrollment) {
            state.isFetchingEnrollment = isFetchingEnrollment
        },
        setIsVerifyingEnrollment(state, isVerifyingEnrollment) {
            state.isVerifyingEnrollment = isVerifyingEnrollment
        },
        setIsFetchingEnrollmentDeclineReasons(state, isFetchingEnrollmentDeclineReasons) {
            state.isFetchingEnrollmentDeclineReasons = isFetchingEnrollmentDeclineReasons
        },
        setIsDecliningEnrollmentVerification(state, isDecliningEnrollmentVerification) {
            state.isDecliningEnrollmentVerification = isDecliningEnrollmentVerification
        },
        setEnrollmentDeclineReasons(state, enrollmentDeclineReasons) {
            state.enrollmentDeclineReasons = enrollmentDeclineReasons.map(reason => {
                return {
                    value: reason.codeName,
                    displayText: reason.displayValue
                }
            })
        },
        setStartMoments(state, startMoments) {
            state.startMoments = startMoments
        },
        setCountries(state, countries) {
            state.countries = countries
        },
        clearStartMoment(state, startMomentIndex) {
            if (startMomentIndex === 1) {
                state.selectedStartMoments = state.selectedStartMoments.filter(x => x.startMomentId !== state.enrollment.eduMSSecondStartMomentId)
                state.enrollment.eduMSSecondStartMomentId = null
            }
        },
        clearStartMoments(state) {
            state.enrollment.eduMSFirstStartMomentId = null
            state.enrollment.eduMSSecondStartMomentId = null

            state.selectedStartMoments = []
        },
        selectNewStartMoment(state, startMoment) {
            const startMomentToAdd = startMomentHelper.mapSelectedStartMoment(startMoment, state.selectedStartMoments)

            if (!state.enrollment.eduMSFirstStartMomentId) {
                state.enrollment.eduMSFirstStartMomentId = startMomentToAdd.startMomentId
            } else {
                state.enrollment.eduMSSecondStartMomentId = startMomentToAdd.startMomentId
            }

            state.selectedStartMoments.push(startMomentToAdd)
        },
        setWillUpdateUserData(state, willUpdateUserData) {
            state.willUpdateUserData = willUpdateUserData
        },
        setProductType(state, productType) {
            state.productType = productType
        }
    },
    actions: {
        async getCourse({ commit, state }) {
            commit('setIsFetchingCourse', true)
            
            try {
                const course = await courseClient.getCourse(state.courseId)

                if (course) {
                    commit('setDiplomaType', course?.diplomaType)
                    commit('setCourseTitle', course?.name)
                    commit('setStartMoments', course?.startMoments ?? [])
                    commit('setProductType', course?.productType)
                    commit('setCourse', course)

                    if (course?.learningMethod?.type?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED) {
                        commit('setIsELearning', true)
                    }
                }
            } catch (err) {
                console.error(err)
                commit('setIsError', true)
            } finally {
                commit('setIsFetchingCourse', false)
            }
        },
        async getEnrollment({ commit, dispatch }, enrollmentId) {
            commit('setIsFetchingEnrollment', true)
            
            try {
                const enrollment = await enrollmentClient.getEnrollment(enrollmentId)

                if(enrollment) {
                    // Map user data on the enrollment
                    enrollment.firstName = getUserPersonalData(enrollment.user.firstName)
                    enrollment.middleName = getUserPersonalData(enrollment.user.middleName)
                    enrollment.lastName = getUserPersonalData(enrollment.user.lastName)
                    enrollment.birthPlace = getUserPersonalData(enrollment.user.birthplace)
                    enrollment.birthDay = getUserPersonalData(enrollment.user.birthday)
                    enrollment.addressCity = getUserPersonalData(enrollment.user.city)
                    enrollment.addressStreet = getUserPersonalData(enrollment.user.street)
                    enrollment.addressPostalCode = getUserPersonalData(enrollment.user.postalCode)
                    enrollment.addressCountryCode = getUserPersonalData(enrollment.user.country) || "NL"
                    enrollment.phoneNumber = getUserPersonalData(enrollment.user.telephone)
                    enrollment.addressHouseNumber = enrollment.user.number === -1 ? '' : enrollment.user.number
                    enrollment.addressHouseNumberAdditive = getUserPersonalData(enrollment.user.numberAdditive)

                    commit('setEnrollment', enrollment)
                    commit('setCourseId', enrollment.eduMSCourseId)

                    if(enrollment?.enrollmentTeamLeaderLearningResult) {
                        const learningResultSharingType = LearningResultSharingUtils.mapLearningResultSharingType(enrollment.enrollmentTeamLeaderLearningResult?.learningResultSharingType)
                        const mappedSelectedLearningResultOptions = LearningResultSharingUtils.mapApproverLearningResultSharingWithStudent(learningResultSharingType, enrollment.enrollmentTeamLeaderLearningResult)

                        commit('setLearningResultSharingType', learningResultSharingType)
                        commit('setSelectedLearningResultOptions', mappedSelectedLearningResultOptions)
                    }
                    await dispatch('getCourse')
                    dispatch('getCountries')
                }
            } catch(err) {
                console.error(err)
                commit('setIsError', true)
            } finally {
                commit('setIsFetchingEnrollment', false)
            }
        },
        getCountries({ commit }) {
            commit('setIsFetchingCountries', true)
            return new Promise((resolve, reject) => {
                userClient
                    .getCountries()
                    .then(response => {
                        commit('setCountries', response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
                    .then(() => {
                        commit('setIsFetchingCountries', false)
                    })
            })
        },
        verifyEnrollment({ commit, state, rootGetters }) {
            commit('setIsVerifyingEnrollment', true)
            

            const enrollment = {
                ...state.enrollment,
                phoneNumber: getGuaranteeStringValue(state.enrollment.phoneNumber),
                birthPlace: getGuaranteeStringValue(state.enrollment.birthPlace),
                addressStreet: getGuaranteeStringValue(state.enrollment.addressStreet),
                addressPostalCode: getGuaranteeStringValue(state.enrollment.addressPostalCode),
                addressHouseNumber: getGuaranteeIntValue(state.enrollment.addressHouseNumber),
                addressCountryCode: getGuaranteeStringValue(state.enrollment.addressCountryCode),
                addressCity: getGuaranteeStringValue(state.enrollment.addressCity),
                birthDay: DateUtils.parseToISOString(state.enrollment.birthDay)
            }

            if (rootGetters['accountModule/isLearningResultEnabled']) {
                const enrollmentLearningResultConsents = state.selectedLearningResultOptions
            
                // If student agrees to share learning results but no options are selected, consider it as no agreement to share
                if (state.learningResultSharingType === learningResultSharingTypes.EMPLOYER.key && enrollmentLearningResultConsents.agreesToShare && 
                    Object.values(LearningResultSharingUtils.getStudentSelectedLearningResultSharingByType(state.learningResultSharingType, enrollmentLearningResultConsents)).every(value => !value)) {
                    enrollmentLearningResultConsents.agreesToShare = false
                }
                enrollment.enrollmentLearningResult = enrollmentLearningResultConsents
            }

            return new Promise((resolve, reject) => {
                enrollmentClient
                    .verifyEnrollment(enrollment, state.enrollment.enrollmentId)
                    .then(response => {
                        if (response) {
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        commit('setIsError', true)
                        reject(error)
                    })
                    .then(() => {
                        commit('setIsVerifyingEnrollment', false)
                    })
            })
        },
        fetchDeclineReasons({ commit }) {
            commit('setIsFetchingEnrollmentDeclineReasons', true)
            return new Promise((resolve, reject) => {
                enrollmentClient
                    .getEnrollmentDeclineReasons()
                    .then(response => {
                        if (response) {
                            commit('setEnrollmentDeclineReasons', response)
                            resolve(response)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        commit('setIsError', true)
                        reject(err)
                    })
                    .then(() => {
                        commit('setIsFetchingEnrollmentDeclineReasons', false)
                    })
            })
        },
        declineEnrollmentVerification({ commit, state }) {
            commit('setIsDecliningEnrollmentVerification', true)

            return new Promise((resolve, reject) => {
                enrollmentClient
                    .declineEnrollmentVerification({ declineReason: state.declineReason.code }, state.enrollment?.enrollmentId)
                    .then(response => {
                        if (response) {
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        const errorKey = Object.keys(errorMessages).find(
                            key => key === error?.response?.data
                        )
                        
                        commit('setDeclineErrorMessage', errorMessages[errorKey] || 'Oeps, er is onverwacht iets mis gegaan.')
                        commit('setIsDeclineError', true)
                        reject(error)
                    })
                    .then(() => {
                        commit('setIsDecliningEnrollmentVerification', false)
                    })
            })
        }
    }
}

export default verificationEnrollmentModule

<template>
  <section>
    <h4>{{ title }}</h4>
    <div v-for="({ values, label, type, options }) in details" :key="label" class="row mb-3">
      <div class="col-6">{{ getLabelText(label, options) }}</div>
      <div
        class="col-6 u-text-break"
        :data-test="normalizeLabel(label)">

        <div v-for="(value, i) in values" :key="i">
          <recess-button
            v-if="type === 'Download'"
            target="_blank"
            variant="tertiary"
            title="Download"
            :url="value"
          />
          <recess-input
            v-else-if="type === 'Input'"
            class="col-md-6 w-100 p-0"
            :value="value"
            :data-test="`input-${normalizeLabel(label)}`"
            :error-message="options.handleErrorMessage"
            @input="val => options.onInput(val)"
          />
          <span v-else>{{ value }}</span>
        </div>
      </div>
    </div>
    <slot></slot>
  </section>
</template>
  
<script>

export default {
    props: {
        details: {
            type: Array,
            required: true,
            default: () => []
        },
        title: {
            type: String, 
            default: null
        }
    },
    setup() {
        const getLabelText = (label, options) => options?.required ? `${label} *` : label
      
        const normalizeLabel = label => label
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^a-zA-Z0-9-]/g, '')

        return {
            getLabelText,
            normalizeLabel
        }
    }
}
</script>
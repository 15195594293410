import 'intersection-observer'
import 'picturefill'
import 'web-animations-js'
import 'whatwg-fetch'

import 'formdata-polyfill'
import 'core-js/es/object'
import 'url-polyfill'

import appInsightsClient from '../modules/appinsights-client'

import themer from '../modules/themer'
import apiClient from '../api/apiClient'
import oidcClient from '../modules/oidc-client'
import createApp from '../app'
import initRouter from './initRouter'
import setStoreSettings from './setStoreSettings'
import '../styles/app.scss'
import buildAuthHandler from './auth.init'

/**
 * init store, wait for route hooks and mout app
 * 
 * @param {import('./types').FrontAppConfig} config 
 */
export default async function initApp(config) {
    try {
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        window.__ASPNETCORE_ENVIRONMENT__ = config.environment
        appInsightsClient.initializeAppInsights(config.appInsightsKey)

        // This means that we take ownership of any scroll changes
        // that may be required when a user traverses the app's history
        // This ownership action is handled in the router.js
        if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual'
        }

        const { app, router, store } = createApp()

        // commit settings from backend
        setStoreSettings(store, config)

        // init theme watcher
        themer.initializeThemer(store)

        // init api client url
        apiClient.initializeClient({
            oplzApiUrl: config.oplzApiUrl, 
            oplzSocialLearningApiUrl: config.oplzSocialLearningApiUrl, 
            oplzGeoLocationApiUrl: config.oplzGeoLocationApiUrl, 
            oplzAiApiUrl: config.oplzAiApiUrl
        })

        // init open id connect configuration
        oidcClient.initializeUserManager(
            store.getters['configurationModule/oidcConfig'])

        const authHandler = buildAuthHandler(oidcClient)

        // wait to load all hooks and fetch all data
        await initRouter(router, store, authHandler)

        // Check current user
        authHandler(router.currentRoute, () => app.$mount('#app'))
    } catch (err) {
        console.error('Error loadig app', err)
        document.location.href = '/error.html'
    }
}

<template functional>
  <svg
    width="11px"
    height="11px"
    viewBox="0 0 9 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
    <title>83FB2162-D133-4A81-B748-9DA84D2F7EA9</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Styleguide"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Styleguide---v2"
        transform="translate(-393.000000, -4835.000000)"
        :class="[`u-fill--${props.color}`]"
      >
        <path
          id="back"
          d="M401.845361,4835.96192 C401.948454,4836.0688 402,4836.19172 402,4836.33066 C402,4836.46961 401.948454,4836.59252 401.845361,4836.6994 L395.768041,4843 L401.845361,4849.3006 C401.948454,4849.40748 402,4849.53039 402,4849.66934 C402,4849.80828 401.948454,4849.9312 401.845361,4850.03808 L401.072165,4850.83968 C400.969072,4850.94656 400.850516,4851 400.716495,4851 C400.582474,4851 400.463918,4850.94656 400.360825,4850.83968 L393.154639,4843.36874 C393.051546,4843.26186 393,4843.13895 393,4843 C393,4842.86105 393.051546,4842.73814 393.154639,4842.63126 L400.360825,4835.16032 C400.463918,4835.05344 400.582474,4835 400.716495,4835 C400.850516,4835 400.969072,4835.05344 401.072165,4835.16032 L401.845361,4835.96192 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            validator(value) {
                return ['primary', 'secondary', 'black'].includes(value)
            },
            default: 'primary'
        }
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div class="d-flex flex-column">
    <div
      v-for="notification in notifications"
      :key="notification.notificationId"
      @click="onNotificationClick(notification)"
    >
      <recess-notification-item data-test="notification-item">
        <span
          class="font-weight-bold"
          v-html="notification.message"
        ></span>
      </recess-notification-item>
    </div>
    

    <p
      v-if="noData"
      class="font-weight-bold"
    >
      Goed bezig! Er zijn geen ongelezen notificaties.
    </p>

    <router-link
      :to="{ name: 'my-notifications' }"
      class="align-self-center"
      data-test="all-notifications-link"
    >
      <recess-button
        title="Toon alle notificaties"
        variant="tertiary"
      />
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userClient from '../../../api/userClient'

export default {
    props: {
        notifications: {
            type: Array,
            default() {
                return []
            }
        },
        noData: {
            type: Boolean
        }
    },
    data() {
        return {
            notificationClicked: null
        }
    },
    computed: {
        ...mapState('userModule', ['user'])
    },
    destroyed() {
        if (this.notificationClicked) {
            userClient.setNotificationAsRead(this.user?.userId, this.notificationClicked?.notificationId)
        }
    },
    methods:{
        onNotificationClick(notification) {
            this.notificationClicked = notification
            this.$router.push(notification.url)
        }
    }
}
</script>

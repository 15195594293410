<template>
  <div>
    <recess-divider />

    <p>Dankjewel voor accepteren van de studieovereenkomst! Hiermee is uw inschrijving automatisch doorgezet naar de opleider. Zij zullen contact met u opnemen over de definitieve bevestiging van de ontwikkelactiviteit en de vervolgstappen met betrekking tot uw deelname aan deze ontwikkelactiviteit. Opleiding.nl wenst u alvast heel veel plezier en nieuwe kennis toe!</p>

    <recess-divider />

    <recess-button
      :url="{ name: 'home' }"
      variant="secondary"
      class="mr-3"
      title="Terug naar home"
    />

    <recess-divider />
  </div>
</template>

<script>
export default {}
</script>
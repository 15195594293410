import { getOplzClient } from './apiClient'

const baseUrl = '/segment-image'

const getSegmentImages = async () => {
    const client = await getOplzClient()

    return client.get(`${baseUrl}`)
}

export default {
    getSegmentImages
}
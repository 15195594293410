import BasePerimeter from './BasePerimeter'

export default new BasePerimeter({
    purpose: 'learningPathAuthorization',

    can: {
        createLearningPathAsManagement() {
            return this.isCoordinator() || this.isTeamLeader()
        },
        editLearningPath(learningPath) {
            return (this.child?.user?.userId === learningPath?.createdById 
                && (this.isCoordinator() || this.isTeamLeader() || learningPath.isPersonal))
                || ((this.isCoordinator() || this.isTeamLeader()) && !learningPath.onlyManageableByTheCreator)
        },
        viewMyOwnLearningPath(learningPath) {
            return learningPath?.isCurrentUserAssigned || 
            (learningPath.isPersonal && learningPath.createdById === this.child?.user?.userId)
        },
        assignLearningPath() {
            return this.isCoordinator() || this.isTeamLeader()
        },
        canToggleIsManageableByOthers(learningPath) {
            return learningPath.createdById === this.child?.user?.userId && this.isCoordinator()
        }
    }
})

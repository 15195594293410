import { getCurrentInstance, computed } from "vue"

/**
 * Custom composable function to handle form validation.
 * @param {string} formName - The name of the form.
 * @param {Object} errorMessages - Object containing error messages for form inputs.
 * @returns {{
*  handleErrorMessage: (input: string) => string | null,
*  validateForm: () => boolean,
*  validation: any
* }}
*/
export default function useValidation(formName, errorMessages) {
    const instance = getCurrentInstance()
    if(!instance) throw new Error("Instance is out of scope")

    const validation = computed(() => instance.proxy?.$v)

    /**
     * Retrieves the error message for a specific input.
     * @param {string} input - The name of the input field.
     * @returns {string | null} The error message for the input, or null if no error.
     */
    const handleErrorMessage = input => {
        const validationField = validation.value?.[formName]?.[input]
        const fieldErrors = errorMessages[input]

        if(!validationField?.$error || !fieldErrors) {
            return null
        }

        if(typeof fieldErrors === 'string') {
            return fieldErrors
        }

        const errorKey = Object.keys(fieldErrors).find(key => validationField[key] === false)

        if(!errorKey) {
            return null
        }

        return fieldErrors[errorKey]
    }

    /**
     * Validates the form and returns whether it is valid.
     * @returns {boolean} True if the form is valid, otherwise false.
     */
    const validateForm = () => {
        validation.value?.$touch()
        return !validation.value?.$invalid
    }

    return {
        handleErrorMessage,
        validateForm,
        validation
    }
}
<template>
  <recess-modal
    modal-size="small"
    :show-modal="isVisible"
    data-test="remove-item-confirmation"
    @close="$emit('close')"
  >
    <template
      v-if="title"
      slot="modal-title"
    >
      <h3>{{ title }}</h3>
    </template>
    <div
      slot="modal-body"
      class="d-flex justify-content-center flex-column align-items-center"
    >
      <p>{{ confirmationText }}</p>
      <div>
        <recess-button
          variant="tertiary"
          :title=confirmationFalseButtonTitle
          @click="$emit('close')"
        />
        <recess-button
          class="ml-2"
          variant="primary"
          :title=confirmationTrueButtonTitle
          data-test="confirm-btn"
          @click="$emit('remove')"
        />
      </div>
    </div>
  </recess-modal>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        confirmationText: {
            type: String,
            default: 'Weet u zeker dat u dit item wilt verwijderen?'
        },
        confirmationTrueButtonTitle:{
            type: String,
            default: 'Ja'
        },
        confirmationFalseButtonTitle:{
            type: String,
            default: 'Nee'
        }
    }
}
</script>

import BasePerimeter from './BasePerimeter'

export default new BasePerimeter({
    purpose: 'legalAgreements',

    can: {
        acceptLegalAgreements() {
            return this.child && this.child.user && !this.child.user.legalAgreementAcceptedDate
        }
    }
})

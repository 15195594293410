<template>
  <section>
    <h4>Gegevens van leveranciers</h4>
  
    <div class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-autocomplete
          :value="providerName"
          data-test="provider-name-input"
          label-text="Leverancier *" 
          item-text="name"
          :error-message="handleErrorMessage('providerName')"
          :on-query="searchProviders"
          @input="val => $emit('input:provider-name', val)" 
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 w-100">
        <recess-file-upload
          ref="uploadFileInput"
          data-test="attachment-upload-input"
          label-text="Project aanvraag offerte bijlage"
          uploaded-file-style="variant2"
          :allowed-extensions="inputConfigs.allowedExtensions"
          :placeholder-text="inputConfigs.allowedExtensions.join(', ')"
          :max-file-to-upload="inputConfigs.maxFilesToUpload"
          :max-size-file="inputConfigs.maxFileSize"
          @upload="uploadDocument"
        />
      </div>
    </div>
    
  </section>
</template>
  
<script>
import { ref } from 'vue'
import { mapFileUploadsToRequests } from '../../../../../utils/MapUtils'

export default {
    props: {
        providerName: {
            type: String,
            default: null
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        },
        accountProviders: {
            type: Array,
            default: () => []
        }
    },
    emits: ['input:provider-name', 'input:file'],    
    setup(props, { emit }) {
        const inputConfigs = {
            // 10 MB
            maxFileSize: '10485760',
            maxFilesToUpload: 1,
            allowedExtensions: ['pdf']
        }

        const uploadFileInput = ref(null)
      
        const uploadDocument = docs => {
            const fileData = mapFileUploadsToRequests(docs)?.[0]
            emit('input:file', fileData ?? null)
        }

        const searchProviders = async query => {            
            if (!query) return []
            const providers = props.accountProviders.filter(x => x.name.toLowerCase().includes(query.toLowerCase())).slice(0,5)                
            return providers
        }

        return {
            inputConfigs,
            uploadFileInput,
            uploadDocument,
            searchProviders
        }
    }
}
</script>
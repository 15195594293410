<template>
  <section>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>

    <recess-button 
      variant="primary" 
      title="Aanvraag annuleren" 
      data-test="open-cancellation-modal-button"
      @click="setShowCancellationModal(true)" 
    />

    <project-request-cancellation-modal
      :is-visible="showCancellationModal"
      :show-options="true"
      :options="cancellationReasons"
      :message-label="projectCancellationModalMessageLabel"
      :option-label="projectCancellationModalOptionLabel"
      :title="projectCancellationModalTitle"
      :description="projectCancellationModalDescription"
      :handle-error-message="handleErrorMessage"
      :message="cancellationFormData.message"
      :selected-option="cancellationFormData.option"
      :show-error="isError"
      @input:message="cancellationFormData.message = $event"
      @input:option="cancellationFormData.option = $event"
      @confirm="cancel"
      @close="setShowCancellationModal(false)"
    />
  </section>
</template>

<script>
import { computed } from 'vue'
import { required, maxLength  } from 'vuelidate/lib/validators'
import useProjectRequestCancellation from '../../../../composables/useProjectRequestCancellation'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import ProjectRequestCancellationModal from '../../../molecules/SubmitWithRemarksModal/SubmitWithRemarksFormModal.vue'

export default {
    components: {
        LoaderComponent,
        ProjectRequestCancellationModal
    },
    props: {
        projectRequest: {
            type: Object,
            default: () => {}
        }
    },
    emits: ['submit'],
    setup(props, { emit }) {
        const {
            showCancellationModal,
            setShowCancellationModal,
            cancellationReasons,
            cancellationFormData,
            projectCancellationModalTitle, 
            projectCancellationModalDescription,
            projectCancellationModalMessageLabel,
            projectCancellationModalOptionLabel,
            isSubmittingCancellation, 
            isFetchingCancellationReasons,
            isError,
            submitCancellation,
            handleErrorMessage
        } = useProjectRequestCancellation()

        const cancel = async () => {
            const isSuccess = await submitCancellation(props.projectRequest?.id)
            if(isSuccess) emit('submit')
        }

        const isLoading = computed(() => isSubmittingCancellation.value || isFetchingCancellationReasons.value)

        return {
            showCancellationModal,
            setShowCancellationModal,
            cancellationReasons,
            projectCancellationModalMessageLabel,
            projectCancellationModalOptionLabel,
            projectCancellationModalTitle,
            projectCancellationModalDescription,
            handleErrorMessage,
            cancellationFormData,
            isError,
            cancel,
            isLoading
        }
    },
    validations() {
        return {
            cancellationFormData: {
                option: { required },
                message: { maxLength: maxLength(300) }
            }
        }
    }
}
</script>
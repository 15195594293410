<template>
  <div></div>
</template>

<script>
import { getUserManager } from '../../../modules/oidc-client'

export default {
    name: 'OidcCallback',
    mounted() {
        getUserManager()
            .signoutRedirectCallback()
            .catch(err => {
                console.error(err)
            })
            .then(() => {
                this.$router.replace({
                    name: 'home'
                })
            })
    }
}
</script>
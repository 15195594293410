<template>
  <lightbox-component
    :is-visible="isVisible"
    :show-close-button="!isSubmitting"
    @close="close"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <h3>Aanvraag ontwikkelactiviteit afkeuren</h3>
    <p>Geef een verplichte reden op. Daarnaast kunt u een optionele opmerking toevoegen.</p>

    <div class="row mb-4">
      <div class="col-12">
        <recess-select
          v-model="rejectionReasonCode"
          data-test="reject-reason-input"
          label-text="Reden voor afkeuring *"
          :default-value="rejectionReasonCode"
          :options="rejectionReasons"
          :prefill-with-first-option=false
          :error-message="$v.rejectionReasonCode.$error ? 'Reden voor afkeuring is verplicht' : ''"
        />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <recess-textarea-input
          v-model="rejectionMessage"
          data-test="reject-message-input"
          label-text="Aanvullende informatie/opmerking"
        />
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <recess-button
        data-test="confirm-reject-button"
        variant="primary"
        title="Bevestigen"
        @click="confirmRejection"
      />
    </div>
    <recess-alert
      v-if="showErrorMessage"
      class="mt-4"
      type="error"
      text="Er is iets misgegaan. Probeer het later nog een keer of neem contact op met een administrator."
    />
  </lightbox-component>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'

export default {
    components: {
        LoaderComponent,
        LightboxComponent
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        enrollmentId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isSubmitting: false,
            rejectionReasonCode: null,
            rejectionMessage: '',
            rejectionReasons: [],
            showErrorMessage: false
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['isRejectingEnrollment', 'isFetchingRejectionReasons']),
        isLoading() {
            return this.isSubmitting || this.isRejectingEnrollment || this.isFetchingRejectionReasons
        }
    },
    watch: {
        async isVisible(newVal) {
            if(newVal && !this.rejectionReasons?.length) await this.fetchEnrollmentRejectionReasons()
        }
    },
    beforeDestroy() {
        this.rejectionMessage = ""
        this.rejectionReasons = []
        this.rejectionReasonCode = null
    },
    validations: {
        rejectionReasonCode: { required }
    },
    methods: {
        ...mapActions('enrollmentModule', ['getEnrollmentRejectionReasons', 'rejectEnrollment']),
        async fetchEnrollmentRejectionReasons() {
            try {
                const response = await this.getEnrollmentRejectionReasons(this.enrollmentId)

                this.rejectionReasons = response.map(rejectionReason => {
                    return {
                        value: rejectionReason.codeName,
                        displayText: rejectionReason.displayValue
                    }
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        close() {
            if (!this.isSubmitting) {
                this.$emit('close')
            }
        },
        async confirmRejection() {
            try {
                this.isSubmitting = true

                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                await this.rejectEnrollment({
                    enrollmentId: this.enrollmentId,
                    rejectionReasonCode: this.rejectionReasonCode,
                    rejectionMessage: this.rejectionMessage
                })
                
                this.$emit('rejected')
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isSubmitting = false
            }
        }
    }
}
</script>
<template>
  <div>
    <recess-modal
      data-test="duplicate-modal"
      :show-modal="showDuplicateEnrollmentModal"
      @close="showDuplicateEnrollmentModal = false"
    >
      <template slot="modal-title">
        <h3>Let op!</h3>
      </template>
      <template slot="modal-body">
        <p class="mb-2">U heeft zich al eerder ingeschreven voor deze ontwikkelactiviteit. Weet u zeker dat u door wilt gaan?</p>
        <span v-if="warningText">{{ warningText }}</span>

        <recess-table v-if="filteredEnrollmentsForCourse.length">
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="enrollment in filteredEnrollmentsForCourse"
              :key="enrollment.enrollmentId"
            >
              <recess-table-cell :data-label="headers[0].title">
                {{ formatDate(enrollment.dateTimeCreated) }}
              </recess-table-cell>
              <recess-table-cell :data-label="headers[1].title">
                {{ enrollment.displayDate || 'n.v.t.' }}
              </recess-table-cell>
              <recess-table-cell :data-label="headers[2].title">
                {{ enrollment.location || 'n.v.t.' }}
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
      </template>
      <div
        slot="modal-footer"
        class="d-flex flex-column"
      >
        <div>
          <recess-button
            :url="{ name: 'home' }"
            class="u-position-left col-12 col-md-auto mb-3 mb-md-0"
            title="Terug naar home"
            variant="primary"
          />
          <recess-button
            class="u-position-right col-12 col-md-auto ml-3"
            title="Doorgaan"
            variant="secondary"
            data-test="close-duplicate"
            @click.native="showDuplicateEnrollmentModal = false"
          />
        </div>
      </div>
    </recess-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import DateUtils from '../../../../utils/DateUtils'
import startMomentHelper from '../../../../modules/startMomentHelper'

export default {
    data() {
        return {
            enrollmentTypes,
            showDuplicateEnrollmentModal: false,
            headers: [
                { key: 'enrollmentDate', title: 'Inschrijfdatum' }, 
                { key: 'startDate', title: 'Startmoment' }, 
                { key: 'location', title: 'Locatie' }
            ]
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollmentType', 'enrollmentsForCourse', 'course', 'isFetchingEnrollmentsForCourse', 'isFetchingCourse']),
        ...mapGetters('enrollmentModule', ['filteredEnrollmentsForCourse']),
        warningText() {
            if (this.filteredEnrollmentsForCourse.length === 1) return 'U heeft zich ingeschreven voor het onderstaande startmoment:'
            if (this.filteredEnrollmentsForCourse.length > 1) return 'U heeft zich ingeschreven voor de onderstaande startmomenten:'
            return ''
        }
    },
    watch: {
        filteredEnrollmentsForCourse(val) {
            if(!val?.length) return
            this.showDuplicateEnrollmentModal = true
        }
    },
    mounted() {
        if (this.filteredEnrollmentsForCourse?.length) this.showDuplicateEnrollmentModal = true
    },
    methods: {
        formatDate: e => DateUtils.formatDate(e),
        getStartDate: startMomentHelper.getStartDate
    }
}
</script>
<template>
  <section>
    <h4>
      Deelnemers
      <recess-tooltip class="d-inline-block pl-2">
        <template #content>
          <div class="u-max-width-500">
            Het aantal deelnemers en startmomenten is belangrijk voor het bepalen van de
            maximumprijs waarvoor de purchase order wordt aangevraagd. Het aantal
            deelnemers en/of startmomenten kan dit maximum niet overschrijden zonder
            instemming van de opleider/uitvoerder. Het aantal deelnemers is per
            startmoment. Het minimum aantal deelnemers kan optioneel als
            annuleringsdrempel worden afgesproken.
          </div>
        </template>
      </recess-tooltip>
    </h4>
    <div class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-input
          type="number"
          :value="minParticipants"
          min="1"
          step="1"
          data-test="minParticipants-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Minimum aantal deelnemers *"
          :error-message="handleErrorMessage('minParticipants')"
          @input="(val) => $emit('input:min-participants', val)"
        />
      </div>

      <div class="col-md-6 w-100">
        <recess-input
          type="number"
          :value="maxParticipants"
          min="1"
          step="1"
          data-test="maxParticipants-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Maximum aantal deelnemers *"
          :error-message="handleErrorMessage('maxParticipants')"
          @input="(val) => $emit('input:max-participants', val)"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        minParticipants: {
            type: Number,
            default: null
        },
        maxParticipants: {
            type: Number,
            default: null
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        }
    },
    emits: ['input:min-participants', 'input:max-participants']
}
</script>

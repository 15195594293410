<template>
  <div>
    <div
      :class="['u-gutter-header', {'u-gutter-header--navigation' : $isAllowed('displayNavigationBar')}]"
    ></div>
    <div
      id="c-header"
      :class="['c-header', {'c-header__no-navigation': !$isAllowed('displayNavigationBar')}, {'c-header__has-impersonation': isUserImpersonating}]"
    >
      <HeaderComponentLarge />
      <HeaderComponentSmall />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderComponentLarge from './HeaderComponentLarge.vue'
import HeaderComponentSmall from './HeaderComponentSmall.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'

export default {
    perimeters: [routePerimeter, featuresPerimeter],
    components: { HeaderComponentLarge, HeaderComponentSmall },
    data() {
        return {
            active: false
        }
    },
    computed: {
        ...mapGetters('userModule', ['isUserImpersonating'])
    },
    watch: {
        active(newValue) {
            setTimeout(() => {
                if (newValue) {
                    // It can be that the css has a transition, if you need to calculate heights put a settimeout in the event listener
                    this.$bus.emit('header-became-sticky')
                } else {
                    // It can be that the css has a transition, if you need to calculate heights put a settimeout in the event listener
                    this.$bus.emit('header-became-normal')
                }
            }, 300) // This setTimeOut needs to be equal to the CSS transition time of the sticky header
        }
    },
    beforeDestroy() {
        if (window) {
            window.removeEventListener('scroll', this.onScroll)
        }
    },
    mounted() {
        if (window) {
            window.addEventListener('scroll', this.onScroll)
        }
    },
    methods: {
        onScroll() {
            const distanceFromTop = window.pageYOffset

            if (distanceFromTop === 0) {
                this.active = false
            } else {
                this.active = true
            }
        }
    }
}
</script>
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider />
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />
    <transition name="fade">
      <div>
        <p>Op deze pagina ziet u de acties die aan u zijn toegewezen/gedelegeerd. U wordt verzocht deze acties af te handelen.</p>

        <table-filters-component
          :show-reset-filters="showResetButton"
          toggle-button-text="Filter medewerkers"
          @reset-filters="resetFilters()"
        >
          <recess-select
            slot="right"
            :options="enrollmentsPageSizes"
            :default-value="mDataQuery.take"
            @input="(value) => setPageSize(value)"
          />
          <template #filters>
            <recess-input
              v-model="userFilters.fullNameFilter.value"
              label-text="Zoek op naam van de medewerker"
              class="mb-3"
              @keyup.enter.native="updateFilters()"
            />
            <recess-input
              v-model="userFilters.courseNameFilter.value"
              label-text="Zoek op ontwikkelactiviteit"
              class="mb-3"
              @keyup.enter.native="updateFilters()"
            />
            <recess-input
              v-model="userFilters.createdByFilter.value"
              label-text="Gecreëerd door"
              class="mb-3"
              @keyup.enter.native="updateFilters()"
            />

            <recess-select
              v-model="userFilters.statusFilter.value"
              :disabled="statusSelectOptions.length <= 1"
              :default-value="userFilters.statusFilter.value"
              :options="statusSelectOptions || []"
              label-text="Zoek op status"
              class="mb-3"
              @input="updateFilters()"
            />

            <recess-select
              v-model="userFilters.delegatorFilter.value"
              :disabled="delegatorSelectOptions.length <= 1"
              :default-value="userFilters.delegatorFilter.value"
              :options="delegatorSelectOptions || []"
              label-text="Zoek op delegator"
              class="mb-3"
              @input="updateFilters()"
            />
            <div
              v-if="userRangeFilters.creationDateFilter"
              class="row mb-3"
            >
              <recess-input
                v-model="userRangeFilters.creationDateFilter.fromValue"
                :error-message="$v.userRangeFilters.creationDateFilter.fromValue.$invalid ? 'Datum heeft geen geldige waarde' : null"
                label-text="Vanaf datum"
                placeholder-text="Dag-maand-jaar, bijv.: 25-01-1970"
                class="col-12 col-md-6"
                @keyup.enter.native="updateFilters()"
              />
              <recess-input
                v-model="userRangeFilters.creationDateFilter.toValue"
                :error-message="$v.userRangeFilters.creationDateFilter.toValue.$invalid ? 'Datum heeft geen geldige waarde.' : null"
                label-text="Tot datum"
                placeholder-text="Dag-maand-jaar, bijv.: 25-01-1970"
                class="col-12 col-md-6"
                @keyup.enter.native="updateFilters()"
              />
            </div>
            <div
              v-if="$isAllowed('displayPricingInformation') && userRangeFilters.priceFilter"
              class="row"
            >
              <recess-input
                v-model="userRangeFilters.priceFilter.fromValue"
                :error-message="$v.userRangeFilters.priceFilter.fromValue.$invalid ? 'Prijs heeft geen geldige waarde' : null"
                label-text="Minimale prijs"
                placeholder-text="10.00"
                class="col-12 col-md-6"
                @keyup.enter.native="updateFilters()"
              />
              <recess-input
                v-model="userRangeFilters.priceFilter.toValue"
                label-text="Maximale prijs"
                placeholder-text="100.50"
                :error-message="$v.userRangeFilters.priceFilter.toValue.$invalid ? 'Prijs heeft geen geldige waarde.' : null"
                class="col-12 col-md-6"
                @keyup.enter.native="updateFilters()"
              />
            </div>
          </template>
          <template #footer>
            <recess-button
              :disabled="$v.$invalid"
              title="Filter"
              variant="primary"
              class="col-12 col-sm-auto"
              @click="updateFilters()"
            />
          </template>
        </table-filters-component>

        <recess-table v-if="enrollments && enrollments.length">
          <recess-table-head>
            <recess-table-row>
              <template v-for="header in headers">
                <recess-table-head-item
                  v-if="displayHeaderCell(header.key, useNetPrice)"
                  :key="header.key"
                  :sortable="header.isSortable"
                  :sort-direction="getSortDirection(header)"
                  @click.native="setSorting(header)"
                >
                  {{ header.title }}
                </recess-table-head-item>
              </template>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="enrollment in enrollments"
              :key="enrollment.id"
            >
              <recess-table-cell :data-label="headers[0].title">
                <span>{{ enrollment.firstName }} {{ enrollment.middleName }} {{ enrollment.lastName }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[1].title">
                <router-link
                  :to="{ name: 'course', query: { course: enrollment.eduMSCourseId, assortment: enrollment.assortmentId }}"
                >
                  <span data-test="course-name" v-html="enrollment.courseName"></span>
                </router-link>
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[2].title"
              >
                {{ format(enrollment.dateTimeCreated) }}
              </recess-table-cell>
              <recess-table-cell
                v-if="$isAllowed('displayPricingInformation')"
                :data-label="headers[3].title"
              >
                <span data-test="price-with-or-without-vat">{{ getPriceWithOrWithoutVat(displayVatInPrices, enrollment.displayPrice) }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[4].title">{{ delegatorsFullName(enrollment) }}</recess-table-cell>
              <recess-table-cell
                :data-label="headers[5].title"
              >
                {{ enrollment.currentStatus ? enrollment.currentStatus.displayValue : '' }}
              </recess-table-cell>
              <recess-table-cell>
                <recess-button
                  v-if="$isAllowed('approveEnrollmentAsFirstApprover', enrollment) || $isAllowed('approveEnrollmentAsSecondApprover', enrollment)"
                  :url="{ name: 'enrollment-approval', query: { enrollment: enrollment.enrollmentId, delegatedAction: true, ...$route.query }}"
                  title="Beoordelen"
                  variant="secondary"
                  class="col-12 col-md-auto"
                />

                <recess-button
                  v-else
                  :url="{ name: 'enrollment-details', query: { enrollment: enrollment.enrollmentId, delegatedAction: true, ...$route.query }}"
                  title="Bekijk"
                  variant="tertiary"
                  class="col-12 col-md-auto"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <recess-divider />
        <pagination-component
          :current-page-number="currentPageNumber"
          :total-pages="totalPages"
          @goToPage="paginationClicked"
        />
      </div>
    </transition>
    <recess-alert
      v-if="showNoEnrollmentsMessage"
      text="Er zijn op dit moment geen acties."
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { decimal } from 'vuelidate/lib/validators'

import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import TableFiltersComponent from './TableFiltersComponent.vue'

import enrollmentClient from '../../../api/enrollmentClient'
import DateUtils from '../../../utils/DateUtils'

import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import enrollmentPerimeter from '../../../perimeters/enrollmentPerimeter'
import DataQueryBuilder from '../../../api/DataQuery/dataQueryBuilder'
import DataQueryFilter from '../../../api/DataQuery/dataQueryFilter'
import DataQueryDynamicFilter from '../../../api/DataQuery/dataQueryDynamicFilter'
import { isValidDate, isPastDate } from '../../../modules/validationsHelper'
import tableHelper from '../../../modules/tableHelper'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import TableFilterBuilder from '../../../utils/builders/TableFilterBuilder'
import TableHeaderBuilder from '../../../utils/builders/TableHeaderBuilder'

export default {
    components: {
        LoaderComponent,
        PaginationComponent,
        TableFiltersComponent
    },
    perimeters: [featuresPerimeter, enrollmentPerimeter],
    data() {
        return {
            enrollments: null,
            enrollmentsTotalCount: 0,
            enrollmentsPageIndex: 1,
            enrollmentsPageSize: 10,
            enrollmentsPageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            isLoading: true,
            showNoEnrollmentsMessage: false,
            mDataQuery: {
                sortField: 'DateTimeCreated',
                sortDirection: 'Desc',
                skip: 0,
                take: 10,
                filters: [],
                userFilters: []
            },
            statusSelectOptions: [{ displayText: '', value: '' }],
            userFilters: new TableFilterBuilder()
                .fullName()
                .courseName()
                .enrollmentStatus()
                .delegator()
                .createdBy()
                .build(),
            userRangeFilters: {}
        }
    },
    validations() {
        return {
            userRangeFilters: {
                creationDateFilter: {
                    fromValue: { isValidDate, isPastDate },
                    toValue: { isValidDate, isPastDate }
                },
                priceFilter: {
                    fromValue: { decimal },
                    toValue: { decimal }
                }
            }
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapState('accountModule', ['account']),
        ...mapGetters('accountModule', ['useNetPrice', 'displayVatInPrices']),
        totalAmountWithOrWithoutVatKey() {
            return tableHelper.totalAmountWithOrWithoutVatKey(this.useNetPrice)
        },
        headers() {
            return new TableHeaderBuilder()
                .firstName()
                .courseName()
                .enrollmentCreationDate()
                .price(this.useNetPrice, this.displayVatInPrices)
                .delegator()
                .enrollmentStatus()
                .emptyCell()
                .build()
        },
        dataQuery() {
            if (this.mDataQuery) {
                const filters = this.mDataQuery.filters.map(
                    x => new DataQueryFilter(x.key, x.operator, x.value)
                )
                const userFilters = this.mDataQuery.userFilters.map(x => {
                    if (x.operator === 'Dynamic') return new DataQueryDynamicFilter(x.value)
                    return new DataQueryFilter(x.key, x.operator, x.value)
                })

                return new DataQueryBuilder()
                    .setSort(this.mDataQuery.sortField, this.mDataQuery.sortDirection)
                    .setPagination(this.mDataQuery.skip, this.mDataQuery.take)
                    .addFilters(filters)
                    .addFilters(userFilters)
                    .build()
            }
            return null
        },
        currentPageNumber() {
            if (this.enrollments) {
                return this.enrollmentsPageIndex
            }
            return 1
        },
        totalPages() {
            if (this.enrollments) {
                return Math.ceil(this.enrollmentsTotalCount / this.enrollmentsPageSize)
            }
            return 1
        },
        showPagination() {
            return this.enrollments && this.totalPages > 1
        },
        userId() {
            return this.user.userId
        },
        showResetButton() {
            return (
                Object.values(this.userFilters).some(uf => {
                    return uf.value
                }) ||
                Object.values(this.userRangeFilters).some(urf => {
                    return urf.fromValue.length > 0 || urf.toValue.length > 0
                })
            )
        },
        delegatorSelectOptions() {
            let delegators = []
            let userDelegators = []
            // fill list based on user active delegations delegators
            if(this.user && this.user.activeDelegations && this.user.activeDelegations.length) {
                userDelegators = this.user.activeDelegations.map(ad => {
                    return { displayText: ad.delegatorFullName, value: ad.delegatorId }
                })
            }
            delegators = [
                { displayText: '', value: '' },
                ...userDelegators
            ]
            return delegators
        }
    },
    watch: {
        dataQuery: {
            // eslint-disable-next-line
            handler: function(value) {
                // Its a function because we need the this context
                if (value) {
                    // The dataQuery object changed, lets do a new request
                    this.doRequest()

                    // also update the url
                    const deserializeDataQuery = this.$route.query.tableDataQueries
                        ? JSON.parse(Buffer.from(this.$route.query.tableDataQueries, 'base64').toString('utf8'))
                        : {}
                    deserializeDataQuery.myDelegatedEnrollments = this.mDataQuery
                    const serializedDataQuery = Buffer.from(JSON.stringify(deserializeDataQuery), 'utf8').toString('base64')
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            tableDataQueries: serializedDataQuery
                        }
                    })
                }
            },
            deep: true
        },
        userId() {
            // sometimes the user is not set on time in the store yet, so then the userId is null,
            // which gives us no results when fetching the enrollments. So we need to make sure the
            // addQueryFilters method is called again if the user id is available
            if (
                this.user.userId &&
                this.mDataQuery.filters.filter(f => f.key === 'DelegationId').length === 0
            ) {
                this.buildDefaultQuery()
            }
        }
    },
    mounted() {
        this.setInitialRangeFilters()
                
        // Fetch enrollment status list
        this.fetchEnrollmentStatusList()

        // Refresh user delegation actions
        this.fetchUserDelegationActions()

        // Create dataQueryFilters for fetching enrollments
        this.buildDefaultQuery()

        // Deserialize the dataquery object and set it
        if (this.$route.query.tableDataQueries) {
            const deserializeDataQuery = JSON.parse(atob(this.$route.query.tableDataQueries))

            // Set default input values
            if (deserializeDataQuery?.myDelegatedEnrollments?.userFilters){
                deserializeDataQuery.myDelegatedEnrollments.userFilters.forEach(queryFilter => {
                    Object.values(this.userFilters).forEach(usrFilter => {
                        if(queryFilter.key === usrFilter.key) {
                            usrFilter.value = queryFilter.value
                        }
                       
                      
                    })

                    Object.values(this.userRangeFilters).forEach(rangeFilter => {
                        if(queryFilter.key === rangeFilter.key) {
                            rangeFilter.fromValue = queryFilter.fromValue
                            rangeFilter.toValue = queryFilter.toValue
                        }
                    })
                })
            }
            
            this.mDataQuery = deserializeDataQuery.myDelegatedEnrollments || this.mDataQuery
        }
        this.mDataQuery.take = this.getTakeFromSessionStorage()
    },
    methods: {
        ...mapActions('userModule', ['fetchUserDelegationActions']),
        setInitialRangeFilters() {
            const filters =  new TableFilterBuilder()
                .creationDateRange()

            if(this.$isAllowed('displayPricingInformation')) filters.priceRange(this.useNetPrice)

            this.userRangeFilters = filters.build()
        },
        delegatorsFullName(enrollment) {
            // make a union of all delegation action delegator names for the enrollment
            if(enrollment.delegationActions){
                return enrollment.delegationActions.map(da => da.delegatorFullName).join()
            }
            return ''
        },
        parseFilterDate(date) {
            if (date) {
                const year = date.slice(6)
                const month = date.slice(3, 5)
                const day = date.slice(0, 2)
                return `${year}, ${month}, ${day}`
            }
            return ''
        },
        resetFilters() {
            Object.values(this.userFilters).forEach(uf => {
                uf.value = ''
            })
            Object.values(this.userRangeFilters).forEach(urf => {
                urf.fromValue = ''
                urf.toValue = ''
            })
            this.updateFilters()
        },
        updateFilters() {
            if (this.$v.$invalid) {
                return
            }
            // Return to the first page
            this.mDataQuery.skip = 0

            const userFilters = Object.values(this.userFilters).map(uf => {
                return { ...uf }
            })

            // Add range filters
            const userRangeFilters = Object.values(this.userRangeFilters).map(urf => {
                if (urf.key.includes('DateTimeCreated')) {
                    const fromValue = urf.fromValue
                        ? `${urf.key}>=DateTime(${this.parseFilterDate(urf.fromValue)})`
                        : null
                    const toValue = urf.toValue
                        ? `${urf.key}<=DateTime(${this.parseFilterDate(urf.toValue)})`
                        : null

                    return {
                        key: urf.key,
                        fromValue: urf.fromValue,
                        toValue: urf.toValue,
                        operator: urf.operator,
                        value:
                            fromValue && toValue ? `${fromValue}&&${toValue}` : fromValue || toValue
                    }
                }

                if (urf.key.includes(this.totalAmountWithOrWithoutVatKey)) {
                    const fromValue = urf.fromValue ? `${urf.key}>=${urf.fromValue}` : null
                    const toValue = urf.toValue ? `${urf.key}<=${urf.toValue}` : null

                    return {
                        key: urf.key,
                        fromValue: urf.fromValue,
                        toValue: urf.toValue,
                        operator: urf.operator,
                        value:
                            fromValue && toValue ? `${fromValue}&&${toValue}` : fromValue || toValue
                    }
                }
                return null
            })

            this.mDataQuery.userFilters = [...userFilters, ...userRangeFilters]
        },
        // Add default delegation filter to data query
        buildDefaultQuery() {
            if (!this.user.userId || !this.user.activeDelegations || !(this.user.activeDelegations.length > 0)) {
                return
            }

            // fetch delegation id from active delegation
            const delegationFilter = {
                key: 'DelegationId',
                operator: 'Contains',
                value: this.user.activeDelegations.map(d => d.id).join(' ')
            }

            this.mDataQuery.filters.push(delegationFilter)
        },
        async doRequest() {
            // Start loading
            this.isLoading = true

            // Do the request
            enrollmentClient
                .getEnrollments(this.dataQuery)
                .then(response => {
                    this.showNoEnrollmentsMessage = false
                    this.enrollments = response?.items
                    this.enrollmentsTotalCount = response.totalCount ?? 0
                    this.enrollmentsPageSize = response.pageSize ?? this.mDataQuery.size
                    this.enrollmentsPageIndex = response.pageIndex ?? 0

                    if (!this.enrollments || !this.enrollments.length)
                        this.showNoEnrollmentsMessage = true
                })
                .catch(error => {
                    this.showNoEnrollmentsMessage = true
                    throw new Error(error)
                })
                .then(() => {
                    this.isLoading = false
                })
        },
        fetchEnrollmentStatusList() {
            enrollmentClient.getEnrollmentStatuses().then(response => {
                const statusFilters = response.map(i => {
                    return {
                        displayText: i.displayValue,
                        value: i.enrollmentStatusId
                    }
                })
                this.statusSelectOptions.push(...statusFilters)
            })
        },
        paginationClicked(pageNumber) {
            this.mDataQuery.skip = this.mDataQuery.take * (pageNumber - 1)
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.mDataQuery.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.mDataQuery.skip = 0

            // set the new page size value in the data query and in the sessopm storage
            this.mDataQuery.take = value
            sessionStorage.setItem('DelegatedEnrollmentsTableComponent/take', JSON.stringify(value))
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.mDataQuery.sortField === header.key) {
                this.mDataQuery.sortDirection =
                    this.mDataQuery.sortDirection === 'Asc' ? 'Desc' : 'Asc'
            } else {
                this.mDataQuery.sortDirection = 'Desc'
            }
            this.mDataQuery.sortField = header.key
            this.mDataQuery.skip = 0
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.mDataQuery.sortField !== header.key) {
                return null
            }

            return this.mDataQuery.sortDirection
                ? this.mDataQuery.sortDirection.toLowerCase()
                : null
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('DelegatedEnrollmentsTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(
                            sessionStorage.getItem('DelegatedEnrollmentsTableComponent/take')
                        ),
                        10
                    )
                } catch {
                    return this.enrollmentsPageSize
                }
            }
            return this.enrollmentsPageSize
        },
        format: e => DateUtils.formatDate(e),
        displayHeaderCell: tableHelper.displayHeaderCell,
        getPriceWithOrWithoutVat: coursePriceHelper.getPriceWithOrWithoutVat
    }
}
</script>
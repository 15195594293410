import { ref,  computed} from 'vue'

export default function useSocialLearningTags(store) {
    const tags = ref([])
    const maxTags = 5
    const minTags = 1

    const expertiseAreaItems = computed(() => {
        return  store.getters['facetsModule/expertiseAreaItems']
    })
    const expertiseAreaOptions = computed(() => {
        return expertiseAreaItems.value?.values?.map(item => {
            return {
                displayText: item,
                value: item
            }
        })
    })
    const expertiseAreaKey = computed(() => {
        return expertiseAreaItems.value?.key
    }) 

    const removeTag = tag => {
        tags.value = tags.value.filter(x => x !== tag)
    }
    const prefillTags = existingTags => {
        tags.value = existingTags?.map(tag => {
            return tag.displayValue
        })
    }

    return {
        maxTags,
        minTags,
        removeTag,
        prefillTags,
        expertiseAreaKey,
        expertiseAreaOptions,
        tags
    }
}
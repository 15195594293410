export default {
    0: {
        value: 'ToStart',
        displayValue: 'Te starten'
    },
    1: {
        value: 'Active',
        displayValue: 'Actief'
    },
    2: {
        value: 'TheoreticallyFinished',
        displayValue: '(Theoretisch) Voltooid'
    },
    3: {
        value: 'Finished',
        displayValue: 'Voltooid'
    }
}
<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="pageIsRendering || isFetchingPdf"
        color="primary"
        overlay="white"
      />
    </transition>
    <template v-if="pdfUrl">
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="pdfDoc">
          <span class="d-flex justify-content-center">{{ currentPage + 1 }} / {{ totalPages + 1 }}</span>
          <div class="d-flex justify-content-center pt-4">
            <canvas ref="pdf"></canvas>
          </div>
          <div class="d-flex justify-content-center my-4">
            <pagination-component
              v-if="totalPages >= 1"
              :current-page-number="currentPage + 1"
              :total-pages="totalPages + 1"
              @goToPage="goToPage"
            />
          </div>
        </div>
      </transition>
    </template>
    <template v-if="showErrorMessage">
      <recess-alert
        type="error"
        class="mb-4"
        text="Er is een fout opgetreden. Probeer het later opnieuw."
      />
    </template>
  </div>
</template>

<script>
import { scrollTo } from 'vue-scrollto'
import pdfjsLib from 'pdfjs-dist'
import { jsMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    name: 'PdfViewer',
    components: { LoaderComponent, PaginationComponent },
    mixins: [jsMediaQueriesMixin],
    props: {
        pdfUrl: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            pdfDoc: null,
            currentPage: 0,
            totalPages: null,
            pageIsRendering: false,
            isFetchingPdf: false,
            showErrorMessage: false
        }
    },
    watch: {
        pdfDoc() {
            this.renderPage()
        },
        currentPage() {
            if (this.currentPage === this.totalPages) this.$emit('last-page')
        },
        totalPages() {
            if (this.totalPages === 0) this.$emit('last-page')
        }
    },
    mounted() {
        this.getPdfDocument(this.pdfUrl)
    },
    methods: {
        goToPage(currentPage) {
            this.currentPage = currentPage - 1
            this.renderPage()
        },
        async getPdfDocument(pdfUrl) {
            try {
                this.isFetchingPdf = true
                const loadingTask = pdfjsLib.getDocument({url: pdfUrl, disableRange: true})

                const pdfDoc = await loadingTask.promise

                if(pdfDoc) {
                    this.pdfDoc = pdfDoc
                    this.totalPages = pdfDoc.numPages - 1
                }
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isFetchingPdf = false
            }
        },
        getRenderContext(page) {
            // Get the canvas
            const canvas = this.$refs.pdf
            // Get the screen width
            const windowWidth = window ? window.innerWidth : 0
            // Set the scale of the canvas base on the screen width
            const scale = windowWidth <= this.jsMediaQueries.large ? 0.5 : 1.2
            // Get viewPort and set canvas height and width
            const viewport = page.getViewport({ scale })
            canvas.height = viewport.height
            canvas.width = viewport.width
            const canvasContext = canvas.getContext('2d')
            return { canvasContext, viewport }
        },
        renderPage() {
            this.pageIsRendering = true

            const pagePromises = Array.from({ length: this.pdfDoc.numPages }, (_, idx) =>
                this.pdfDoc.getPage(idx + 1)
            )

            return Promise.all(pagePromises).then(pages => {
                pages[this.currentPage].render(this.getRenderContext(pages[this.currentPage]))
                this.pageIsRendering = false
                scrollTo('body')
            })
        }
    }
}
</script>

<template>
  <lightbox-component
    :is-visible="isVisible"
    @close="resetAndClose"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isSubmitting"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>
    <form @submit.prevent="checkValidationAndSubmit">
      <header class="d-flex">
        <img src="../../../assets/images/icons/ai.png" alt="ai icon" class="u-max-height-70"/>
        <p class="px-3">
          Hoi! Ik ben Eden*, de eerste kunstmatige intelligentie opleidingsadviseur van Opleiding.nl. 
          Ik help door je nuttige ontwikkeladviezen te geven die je
          helpen jouw werkdoel z.s.m. te bereiken. Selecteer jouw doel hieronder.
        </p>
      </header>
    
      <recess-select 
        v-model="formData.goalType" 
        label-text="Mijn doel is *"
        data-test="goal-type"
        :default-value="formData.goalType"
        :options="goalOptions" 
        :prefill-with-first-option="false"
        :error-message="$v.formData.goalType.$error ? 'Doel is verplicht' : ''"
      />

      <recess-divider variant="xsmall" />

      <fieldset class="row pb-md-3">
        <legend class="d-none">Old job</legend>
        <recess-input 
          v-model="formData.currentJob"
          data-test="current-job"
          class="col-12 col-md-4 pb-2 pb-md-0" 
          label-text="Huidige functie *" 
          :disabled="disabledFields.currentJob"
          :error-message="$v.formData.currentJob.$error ? 'Huidige functie is verplicht' : ''"
        />
        <recess-select 
          v-model="formData.currentJobLevel"
          data-test="current-job-level"
          :default-value="formData.currentJobLevel"
          class="col-12 col-md-4 pb-2 pb-md-0"
          label-text="Huidig niveau *" 
          :options="jobLevelOptions" 
          :prefill-with-first-option="false"
          :error-message="$v.formData.currentJobLevel.$error ? 'Huidig niveau is verplicht' : ''"
        />
        <recess-select 
          v-model="formData.currentJobSector"
          data-test="current-job-sector"
          class="col-12 col-md-4 pb-2 pb-md-0" 
          label-text="Huidige branche *" 
          :default-value="formData.currentJobSector"
          :options="jobSectorOptions"
          :prefill-with-first-option="false"
          :error-message="$v.formData.currentJobSector.$error ? 'Huidige branche is verplicht' : ''"
        />
      </fieldset>

      <fieldset class="row">
        <legend class="d-none">New job</legend>
        <recess-input 
          v-model="formData.newJob"
          data-test="new-job"
          class="col-12 col-md-4 pb-2 pb-md-0" 
          :label-text="`Nieuwe functie ${appendStar('newJob')}`"
          :disabled="disabledFields.newJob"
          :error-message="$v.formData.newJob.$error ? 'Nieuwe functie is verplicht' : ''"
        />
        <recess-select 
          v-model="formData.newJobLevel"
          data-test="new-job-level"
          class="col-12 col-md-4 pb-2 pb-md-0" 
          :label-text="`Nieuw niveau ${appendStar('newJobLevel')}`" 
          :options="jobLevelOptions" 
          :default-value="formData.newJobLevel"
          :prefill-with-first-option="false"
          :disabled="disabledFields.newJobLevel"
          :error-message="$v.formData.newJobLevel.$error ? 'Nieuw niveau is verplicht' : ''"
        />
        <recess-select 
          v-model="formData.newJobSector"
          data-test="new-job-sector"
          class="col-12 col-md-4 pb-2 pb-md-0" 
          :label-text="`Nieuwe branche ${appendStar('newJobSector')}`"
          :options="jobSectorOptions"
          :default-value="formData.newJobSector"
          :prefill-with-first-option="false"
          :disabled="disabledFields.newJobSector"
          :error-message="$v.formData.newJobSector.$error ? 'Nieuwe branche is verplicht' : ''"
        />
      </fieldset>

      <recess-divider variant="xsmall" />

      <footer>
        <small class="u-text-small">
          * Ik ben net gestart bij Opleiding.nl, dus vergeef me als ik heel soms een iets minder relevant advies geef. 
          Ik ben heel goed getraind en net als jij blijf ik me continu ontwikkelen en verbeteren!
        </small>

        <recess-divider variant="xsmall" />

        <recess-button class="u-position-right w-100 w-md-auto" type="submit" variant="primary" title="Doorgaan" :disabled="!hasWizardInputsChanged" data-test="submit" />
      </footer>
    </form>

    <recess-alert
      v-if="showServerErrorMessage"
      type="error"
      text="Er is een fout opgetreden. Probeer het later opnieuw."
      class="w-100 mt-5"
    />

  </lightbox-component>
</template>
  
<script>
import { ref, reactive, watch, computed } from 'vue'
import { required, requiredIf } from 'vuelidate/lib/validators'
import aiClient from '../../../api/aiClient'
import userFeatureSettingsClient from '../../../api/userFeatureSettingsClient'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import goalTypes from '../../../constants/goalTypes'
import jobLevels from '../../../constants/jobLevels'
import jobSectors from '../../../constants/jobSectors'
import useStore from '../../../composables/useStore'

export default {
    components: {
        LightboxComponent,
        LoaderComponent
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const isSubmitting = ref(false)
        const showServerErrorMessage = ref(false)
        const store = useStore()

        const disabledFields = ref({
            currentJobLevel: false,
            currentJob: !!store?.getters['userModule/jobName'],
            currentJobSector: false,
            newJob: !store?.getters['userModule/newJob'],
            newJobLevel: !store?.getters['userModule/newJobLevel'],
            newJobSector:   !store?.getters['userModule/newJobSector']
        })

        const formData = reactive({
            goalType: store?.getters['userModule/goalType'] ?? null,
            currentJob: store?.getters['userModule/currentJob'] ?? store?.getters['userModule/jobName'] ?? null,
            currentJobLevel: store?.getters['userModule/currentJobLevel'] ?? null,
            currentJobSector: store?.getters['userModule/currentJobSector'] ?? null,
            newJob: store?.getters['userModule/newJob'] ?? null,
            newJobLevel: store?.getters['userModule/newJobLevel'] ?? null,
            newJobSector: store?.getters['userModule/newJobSector'] ?? null
        })

        const jobLevelOptions = ref([
            {
                displayText: "Junior",
                value: jobLevels.JUNIOR
            },
            {
                displayText: "Medior",
                value: jobLevels.MEDIOR
            },
            {
                displayText: "Senior",
                value: jobLevels.SENIOR
            }
        ])
        const goalOptions = ref([
            {
                displayText: 'Om zo snel mogelijk zelfstandig te kunnen werken in mijn nieuwe functie',
                value: goalTypes.NEW_JOB_INDEPENDENT_WORKING
            },
            {
                displayText: 'Me te ontwikkelen binnen mijn huidige functie (bijv. van junior naar medior)',
                value: goalTypes.HIGHER_LEVEL_DEV
            },
            {
                displayText: 'Dezelfde functie uit te voeren binnen een andere branche',
                value: goalTypes.SECTOR_CHANGE_JOB
            },
            {
                displayText: 'Door te groeien naar een nieuwe/zwaardere functie',
                value: goalTypes.NEW_POSITION_GROWTH
            }
        ])
        const jobSectorOptions = ref([
            { displayText: 'Bouw', value: jobSectors.CONSTRUCTION },
            { displayText: 'Communicatie en marketing', value: jobSectors.COMMUNICATION_AND_MARKETING },
            { displayText: 'Cultuur', value: jobSectors.CULTURE },
            { displayText: 'Detailhandel', value: jobSectors.RETAIL },
            { displayText: 'Facilitaire dienstverlening', value: jobSectors.FACILITY_SERVICES },
            { displayText: 'Financiële dienstverlening', value: jobSectors.FINANCIAL_SERVICES },
            { displayText: 'Gezondheidszorg', value: jobSectors.HEALTHCARE },
            { displayText: 'Horeca', value: jobSectors.HOSPITALITY },
            { displayText: 'Installatie, reparatie en onderhoud', value: jobSectors.INSTALLATION_REPAIR_AND_MAINTENANCE },
            { displayText: 'Justitie', value: jobSectors.JUSTICE },
            { displayText: 'Landbouw', value: jobSectors.AGRICULTURE },
            { displayText: 'Magazijn en opslag', value: jobSectors.WAREHOUSE_AND_STORAGE },
            { displayText: 'Media en journalistiek', value: jobSectors.MEDIA_AND_JOURNALISM },
            { displayText: 'Natuur', value: jobSectors.NATURE },
            { displayText: 'Onderwijs', value: jobSectors.EDUCATION },
            { displayText: 'Ontwerp en ontwikkeling', value: jobSectors.DESIGN_AND_DEVELOPMENT },
            { displayText: 'Openbaar bestuur', value: jobSectors.PUBLIC_ADMINISTRATION },
            { displayText: 'Personeel en arbeid', value: jobSectors.HUMAN_RESOURCES },
            { displayText: 'Persoonlijke verzorging', value: jobSectors.PERSONAL_CARE },
            { displayText: 'Post en pakket', value: jobSectors.POST_AND_PARCEL },
            { displayText: 'Productie', value: jobSectors.PRODUCTION },
            { displayText: 'Recreatie', value: jobSectors.RECREATION },
            { displayText: 'Spoorwegen', value: jobSectors.RAILWAYS },
            { displayText: 'Techniek', value: jobSectors.TECHNOLOGY },
            { displayText: 'Toerisme', value: jobSectors.TOURISM },
            { displayText: 'Veiligheid en defensie', value: jobSectors.SECURITY_AND_DEFENSE },
            { displayText: 'Visserij', value: jobSectors.FISHERIES },
            { displayText: 'Wegtransport', value: jobSectors.ROAD_TRANSPORT },
            { displayText: 'Welzijn', value: jobSectors.WELFARE },
            { displayText: 'Wetenschap', value: jobSectors.SCIENCE },
            { displayText: 'Zakelijke dienstverlening', value: jobSectors.BUSINESS_SERVICES },
            { displayText: 'Zee-, binnen- en luchtvaart', value: jobSectors.SEA_INLAND_AND_AIR_TRANSPORTATION }
        ])

        const appendStar = field => !disabledFields.value[field] ? "*" : ""

        const resetAllInputFields = () => {
            formData.goalType = null
            formData.currentJob = store?.getters['userModule/jobName'] ?? null
            formData.currentJobLevel = null
            formData.currentJobSector = null
            formData.newJob = null
            formData.newJobLevel = null
            formData.newJobSector = null
        }

        const resetAllDisabledFields = () => {
            disabledFields.value = {
                currentJob: !!store?.getters['userModule/jobName'],
                newJob: !store?.getters['userModule/newJob'],
                newJobLevel: !store?.getters['userModule/newJobLevel'],
                newJobSector:   !store?.getters['userModule/newJobSector']
            }
        }
        
        const resetInputIfDisabled = () => {
            Object.entries(disabledFields.value).forEach(([key, value]) => {
                if (value) {
                    formData[key] = key === 'currentJob' ? store?.getters['userModule/jobName'] : null
                }
            })
        }
        
        const setDisabledFieldsByGoalType = goalType => {
            switch(goalType) {
                case goalTypes.NEW_JOB_INDEPENDENT_WORKING: 
                    disabledFields.value.newJobLevel = true
                    disabledFields.value.newJobSector = true
                    disabledFields.value.newJob = true
                    break
                case goalTypes.HIGHER_LEVEL_DEV:  
                    disabledFields.value.newJobLevel = false
                    disabledFields.value.newJobSector = true
                    disabledFields.value.newJob = true
                    break
                case goalTypes.SECTOR_CHANGE_JOB:
                    disabledFields.value.newJobLevel = true
                    disabledFields.value.newJobSector = false
                    disabledFields.value.newJob = true
                    break
                case goalTypes.NEW_POSITION_GROWTH:
                    disabledFields.value.newJobLevel = false
                    disabledFields.value.newJobSector = false
                    disabledFields.value.newJob = false
                    break
                default: 
                    break
            }
        }
        watch(() => formData.goalType, newVal => {
            setDisabledFieldsByGoalType(newVal)
        })

        watch(disabledFields.value, newVal => {
            resetInputIfDisabled(newVal)
        })

        const hasUserFeatureSettings = computed(() => !!store.getters['userModule/aiAdvisor'] || !!store.getters['userModule/aiWizardInputs'])

        const getRecommendationTopics = async () => {
            try {
                const recommendationTopics = await aiClient.getRecommendationTopics(formData)

                return recommendationTopics
            } catch (error) {
                throw new Error("Failed to retrieve course recommendation topics", { cause: error })
            }
        }

        const postUserFeatureSettings = async userFeatureSettingsRequest => {
            try {
                await userFeatureSettingsClient.post(userFeatureSettingsRequest)
            } catch (error) {
                throw new Error("Failed to post UserFeatureSettings", { cause: error })
            }
        } 

        const userId = computed(() => store.getters['userModule/userId'])

        const patchAiAdvisor = async recommendationTopics => {
            try {
                const patchRequest = [{
                    path: '/aiAdvisor',
                    op: "replace",
                    value: recommendationTopics
                }]
                await userFeatureSettingsClient.patch(userId.value, patchRequest)
            } catch (error) {
                throw new Error("Failed to patch aiAdvisor", { cause: error })
            }
        }

        const patchAiWizardInputs = async aiWizardInputs => {
            try {
                const patchRequest = [{
                    path: '/aiWizardInputs',
                    op: "replace",
                    value: aiWizardInputs
                }]
                await userFeatureSettingsClient.patch(userId.value, patchRequest)
            } catch (error) {
                throw new Error("Failed to patch aiWizardInputs", { cause: error })
            }
        }

        const saveAiWizardInputs = async aiWizardInputs => {
            await store.commit('userModule/setAiWizardInputs', aiWizardInputs)
        }

        const saveAiAdvisor = async recommendationTopics => {
            await store.commit('userModule/setAiAdvisor', recommendationTopics)
        }
        
        const hasWizardInputsChanged = computed(() => {
            if (!hasUserFeatureSettings.value) return true

            const userAiWizardInputs = store.getters['userModule/aiWizardInputs']
            return Object.entries(formData).some(([key, value]) => userAiWizardInputs?.[key] !== value)
        })

        return {
            goalOptions,
            jobLevelOptions,
            formData,
            disabledFields,
            jobSectorOptions,
            isSubmitting,
            showServerErrorMessage,
            resetAllInputFields,
            resetInputIfDisabled,
            setDisabledFieldsByGoalType,
            getRecommendationTopics,
            saveAiAdvisor,
            resetAllDisabledFields,
            appendStar,
            saveAiWizardInputs,
            hasWizardInputsChanged,
            patchAiWizardInputs,
            patchAiAdvisor,
            hasUserFeatureSettings,
            postUserFeatureSettings
        }
    },
    validations() {
        return {
            formData: {
                goalType: { required },
                currentJob: { required },
                currentJobSector: { required },
                currentJobLevel: { required },
                newJobSector: {
                    required: requiredIf(() => !this.disabledFields.newJobSector)
                },
                newJob: {
                    required: requiredIf(() => !this.disabledFields.newJob)
                },
                newJobLevel: {
                    required: requiredIf(() => !this.disabledFields.newJobLevel)
                }
            }
        }
    },
    methods: {
        resetAndClose() {
            this.showServerErrorMessage = false
            this.$v.$reset()
            this.$emit('close')
        },
        async updateAiWizardInputs() {
            const aiWizardInputs = {
                goalType: this.formData.goalType,
                currentJob: this.formData.currentJob,
                currentJobLevel: this.formData.currentJobLevel,
                currentJobSector: this.formData.currentJobSector,
                newJob: this.formData.newJob,
                newJobLevel: this.formData.newJobLevel,
                newJobSector: this.formData.newJobSector
            }
            try {
                if (this.hasUserFeatureSettings) {
                    await this.patchAiWizardInputs(aiWizardInputs)
                } else {
                    const userFeatureSettingsRequest = { aiWizardInputs }
                    await this.postUserFeatureSettings(userFeatureSettingsRequest)
                }

                await this.saveAiWizardInputs(aiWizardInputs)
            } catch (error) {
                throw new Error(error)
            }
        },
        async updateRecommendationTopics(recommendationTopics) {
            try {
                if (!this.hasUserFeatureSettings) 
                    throw new Error("UserFeatureSettings not found")

                await this.patchAiAdvisor(recommendationTopics)
                await this.saveAiAdvisor(recommendationTopics)
                
            } catch (error) {
                throw new Error(error)
            }
        },
        async checkValidationAndSubmit() {
            try {
                this.showServerErrorMessage = false
               
                this.$v.$touch()
                if(this.$v.$invalid) {
                    return
                }

                this.isSubmitting = true
                if (!this.hasWizardInputsChanged) return 
                
                const promises = [this.updateAiWizardInputs(),  this.getRecommendationTopics()]
                const [, recommendationTopics] = await Promise.all(promises)
                
                await this.updateRecommendationTopics(recommendationTopics)
                this.resetAndClose()
            } catch (error) {
                this.showServerErrorMessage = true
                console.error(error)
            } finally {
                this.$gtag.event('ai_wizard')
                this.isSubmitting = false
            }
          
        }
    }
}
</script>

<template>
  <table   
    v-if="showMatrix"
    aria-label="Selected learning result"
    class="w-100"
  >
    <tr>
      <th scope="col" ></th>
      <th
        v-for="learningResultCategory in learningResultCategoriesToShow"
        :key="learningResultCategory.key" 
        scope="col"
        class="d-table-cell align-middle pb-2">
        <strong>{{ learningResultCategory.displayValue }}</strong>
      </th>
    </tr>
    <tr v-for="learningResultOption in learningResultOptionsToShow" :key="learningResultOption.key" >
      <th scope="row" class="text-left pb-2">
        <component :is="isBoldLabel ? 'strong' : 'span'">{{ learningResultOption.displayName }}</component>
      </th>
      <td 
        v-for="(optionType, index) in getLearningResultOptionsTypes(learningResultOption.key)" 
        :key="index" 
        class="d-table-cell align-middle pb-2"
      >
        <recess-icon v-if="isLearningResultOptionSelected(optionType)" class="d-flex justify-content-center" icon="check"/>
      </td>
    </tr>
  </table>
</template>

<script>
import { computed } from 'vue'
import learningResultSharingTypes from '../../../constants/learningResultSharingTypes'
import learningResultSharingOptions from '../../../constants/learningResultSharingOptions'
import learningResultSharingOptionsTypes from '../../../constants/learningResultSharingOptionsTypes'

export default {
    props: {
        learningResultCategoriesToShow: {
            type: Array,
            default: () => []
        },
        isTeamLeaderEnrollment: {
            type: Boolean,
            default: false
        },
        selectedLearningResultOptions: {
            type: Object,
            default: () => {}
        },
        isBoldLabel: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const learningResultOptionsToShow = computed(() => {
            return Object.keys(learningResultSharingOptions)?.map(key => learningResultSharingOptions[key])
        })

        const getLearningResultOptionsTypes = type => {
            if(props.isTeamLeaderEnrollment) {
                return [ learningResultSharingOptionsTypes[learningResultSharingTypes.SELECTEDBYAPPROVER.key][type] ]
            }
         
            const learningResultOptionsTypes = []
            props.learningResultCategoriesToShow.forEach(category => {
                const optionType = learningResultSharingOptionsTypes[category.key][type]
                learningResultOptionsTypes.push(optionType)
            })
           
            return learningResultOptionsTypes
        }

        const isLearningResultOptionSelected = type => {
            return props.selectedLearningResultOptions?.[type]
        }

        const showMatrix = computed(() => {
            return !!Object.keys(props.learningResultCategoriesToShow)?.length
        })

        return {
            learningResultOptionsToShow,
            showMatrix,
            getLearningResultOptionsTypes,
            isLearningResultOptionSelected
        }
    }
}
</script>
<template>
  <router-link
    v-if="logoUrl"
    :to="$isAllowed('displayHomeLink') ? '/' : { path: $route.path, query: $route.query }"
    class="c-logo"
  >
    <img
      v-show="logoUrl"
      :class="{'c-logo__image' : true, 'c-logo__image-svg' : isSvg}"
      alt="Logo"
      :src="logoUrl"
    />
  </router-link>
</template>

<script>
import featuresPerimeter from '../../../perimeters/featuresPerimeter'

export default {
    perimeters: [featuresPerimeter],
    props: {
        logoUrl: {
            default: '',
            type: String
        }
    },
    computed: {
        isSvg() {
            return this.logoUrl && this.logoUrl.includes('.svg')
        }
    }
}
</script>

import qs  from 'qs'
import { getOplzClient } from './apiClient'

const baseRoute = '/learning-paths'

export const createLearningPath = async learningPath => {
    const client = await getOplzClient()
    return client.post(`${baseRoute}`, learningPath).then(response => {
        return response
    })
}

export const getLearningPathById = async id => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/${id}`).then(response => response.data)
}

export const getLearningPaths = async (data, queryOption) => {
    const client = await getOplzClient()
    return client
        .post(`${baseRoute}/search`, data, { 
            params: {
                queryOption
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
           
        })
        .then(response => response.data)
}

export const patchLearningPath = async (id, data) => {
    const client = await getOplzClient()
    return client.patch(`${baseRoute}/${id}`, data).then(response => response.data)
}

export const getLearningPathAssignedUsers = async id => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/users/${id}`).then(response => response.data)
}

export const assignLearningPathUsers = async data => {
    const client = await getOplzClient()
    return client.post(`${baseRoute}/users`, data).then(response => response.data)
}

export const setSorting = async (id, sorting) => {
    const client = await getOplzClient()
    return client.post(`${baseRoute}/${id}/sort`, sorting).then(response => response.data)
}

export const deleteLearningPathCourses = async (learningPathId, learningPathCourseIds) => {
    const client = await getOplzClient()
    return client
        .post(`${baseRoute}/${learningPathId}/courses/delete`, learningPathCourseIds)
        .then(response => response.data)
}

export const completeLearningPathCourse = async learningPathCourseId => {
    const client = await getOplzClient()
    return client
        .post(`${baseRoute}/set-course-complete/${learningPathCourseId}`)
        .then(response => response.data)
}

export const assignLearningPathCourse = async (learningPathId, data) => {
    const client = await getOplzClient()
    return client
        .post(`${baseRoute}/${learningPathId}/courses`, data)
        .then(response => response.data)
}

export const deleteLearningPath = async learningPathId => {
    const client = await getOplzClient()
    return client.delete(`${baseRoute}/${learningPathId}`).then(response => response.data)
}

export const autoAssignLearningPath = async (learningPathId, data) => {
    const client = await getOplzClient()
    return client
        .post(`${baseRoute}/${learningPathId}/auto-assign`, data)
        .then(response => response.data)
}

export const deleteAutoAssignedLearningPath = async learningPathId => {
    const client = await getOplzClient()
    return client.delete(`${baseRoute}/${learningPathId}/auto-assign`).then(response => response.data)
}

export const getAutoAssignedLearningPath = async learningPathId => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/${learningPathId}/auto-assign`).then(response => response.data)
}

export const searchLearningPathsBasicInfo = async data => {
    const client = await getOplzClient()
    return client.post(`${baseRoute}/search-basic-info`, data).then(response => response.data)
}

export const getDashboardLearningPaths = async () => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/dashboard`).then(response => response.data)
}

export const checkIfUserIsAssignedToLearningPathCourse = async eduMSCourseId => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/check-if-assigned-to-course/${eduMSCourseId}`).then(response => response.data)
}
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div 
    v-if="$isAllowed('displayLearningPath')" 
    class="c-learning-path"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <recess-loader
        v-if="isLoading"
        full-screen
        overlay="white"
        color="primary"
      />
    </transition>
    <!-- Go back button -->
    <recess-layout-static :size="1700">
      <ArrowBackSVG />
      <recess-button
        class="u-textcolor-primary"
        title="Ga terug"
        variant="variant4"
        :url="goBackButtonUrl"
      />
    </recess-layout-static>
    <recess-divider />
    <template v-if="learningPath && $isAllowed('editLearningPath', learningPath) ">
      <recess-layout-static>
        <!-- Learning path title and description -->
        <div class="row">
          <div class="col-12 col-md-8">
            <!-- Icon mobile -->
            <div
              v-if="iconPath"
              class="d-md-none my-2 d-flex justify-content-center align-items-center"
            >
              <img
                alt="Learnig path icon"
                class="c-learning-path__icon--medium"
                :src="iconPath"
              />
            </div>
            <h2 class="mb-5">Bewerk leerpad</h2>
            <recess-input
              v-model="mLearningPath.title"
              data-test="edit-learning-path-title"
              :error-message="$v.mLearningPath.title.$error ? 'De naam van het leerpad is verplicht.' : ''"
              class="mb-3 qa-edit-lp__title"
              label-text="Geef uw leerpad een naam *"
            />

            <recess-date-picker
              v-model="mLearningPath.endDate"
              :default-value="mLearningPath.endDate"
              placeholder="dd-mm-jjjj"
              :error-message="$v.mLearningPath.endDate.$invalid ? 'Einddatum heeft geen geldige waarde' : ''"
              class="mb-3 qa-edit-lp__end-date"
              label-text="Geef een einddatum op (optioneel)"
            />

            <recess-textarea-input
              v-model="mLearningPath.description"
              data-test="edit-learning-path-description"
              class="mb-3 qa-edit-lp__description"
              label-text="Geef een korte omschrijving van het leerpad (doel, thema) in maximaal 500 tekens"
            />

            <recess-select 
              v-if="icons"
              v-model="selectedIcon"
              :default-value="selectedIcon"
              label-text="Selecteer een afbeelding"
              class="mb-4 qa-edit-lp__icon"
              :options="icons"
              @input="updateIcon"
            />

            <div
              v-if="!learningPath.isPersonal"
              class="d-flex flex-column"
            >
              <recess-toggle-input
                v-if="$isAllowed('canToggleIsManageableByOthers', learningPath)"
                v-model="mLearningPath.onlyManageableByTheCreator"
                data-test="only-manageable-by-creator-input"
                class="mb-3"
              >
                <template #label>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">Alleen aan te passen door mijzelf</span>
                    <recess-tooltip>
                      <template #content>
                        <span class="d-block u-max-width-500">
                          Het leerpad is standaard alleen aanpasbaar door u zelf. Door deze setting te de-activeren kunnen collega's, met dezelfde rol als u, 
                          uw leerpad ook bewerken (bijv. indien u coordinator bent, kan een andere coordinator het leerpad aanpassen).
                        </span>
                      </template>
                    </recess-tooltip>
                  </div>
                </template>
              </recess-toggle-input>
              <recess-toggle-input
                v-model="mLearningPath.hasObligatedOrder"
                class="mb-3 qa-edit-lp__has-obligated-order"
                label-text="Verplichte volgorde"
              />
            </div>
          </div>
          <!-- Icon desktop -->
          <div
            v-if="iconPath"
            class="col-4 d-none d-md-flex justify-content-center align-items-center "
          >
            <img
              alt="Learnig path icon"
              class="c-learning-path__icon--medium"
              :src="iconPath"
            />
          </div>

          <div class="col-12 col-md-8 d-flex justify-content-end">
            <recess-button
              class="u-position-right mr-3"
              title="Terug"
              variant="tertiary"
              :url="goBackButtonUrl"
            />
            <recess-button
              class="u-position-right qa-edit-lp__save-button"
              title="Opslaan"
              variant="primary"
              @click.native="updateLearningPath"
            />
          </div>
        </div>
       
        <recess-divider />
      </recess-layout-static>
      <div class="u-bg--variant-gray1">
        <recess-divider />
        <recess-layout-static>
          <div v-if="learningPathCourses.length || unavailableLearningPathCourses.length">
            <draggable
              v-model="learningPathCourses"
              handle=".c-learning-path__icon--drag"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <!-- Learning path course -->
                <div
                  v-for="(item, i) in learningPathCourses"
                  :key="item.id"
                  class="list-group-item"
                >
                  <recess-card
                    v-if="item.course"
                    class="c-learning-path__item"
                  >
                    <!-- Course Title and reviews -->
                    <div class="col-12 col-lg-1 c-learning-path__counter-wrapper pl-lg-0">
                      <div class="c-learning-path__counter-number">{{ i + 1 }}</div>
                    </div>

                    <div class="col-12 col-lg-11 px-0 px-md-auto pt-2 pt-lg-0">
                      <div class="c-learning-path__header row">
                        <h4 class="col-lg-9"><span v-html="item.course.title"></span></h4>
                        <div class="c-learning-path__rating col-lg-3">
                          <feedback-stars-component :score="item.course.rating || 0" />
                          <span class="ml-2">{{ item.course.reviewCount || 0 }} reviews</span>
                        </div>
                      </div>

                      <recess-divider
                        class="pt-lg-0"
                        show-line
                      />

                      <!-- Course general info -->
                      <ul class="c-learning-path__list">
                        <li
                          v-if="(item.course.learningMethodTypeDisplayValue || item.course.learningMethodType) ||
                            (item.course.learningMethodFormatDisplayValue || item.course.learningMethodFormat)"
                          class="c-learning-path__list-text"
                        >
                          {{ learningMethodFormatText(item.course) }} {{ learningMethodTypeText(item.course) }}
                        </li>
                        <li
                          v-if="item.course.courseDuration && item.course.courseDurationUnit"
                          class="c-learning-path__list-text"
                        >
                          {{ item.course.courseDuration }} {{ item.course.courseDurationUnit }}
                        </li>

                        <li
                          v-if="item.course.level_nl"
                          class="c-learning-path__list-text"
                        >
                          {{ item.course.level_nl }}
                        </li>
                      </ul>

                      <!-- Course description -->
                      <div class="row c-learning-path__body">
                        <p
                          class="col-lg-8 d-none d-lg-block"
                          v-html="cutOff(item.course.content, 150)"
                        ></p>

                        <!-- Course price -->
                        <div class="col-12 col-lg-4 c-learning-path__info">
                          <div class="c-learning-path__actions">
                            <div
                              v-if="item.course.providerLogoUrl || item.course.providerName"
                              class="d-lg-none"
                            >
                              <img
                                v-if="item.course.providerLogoUrl"
                                alt="Provider logo"
                                class="c-learning-path__provider-logo mb-3"
                                :src="item.course.providerLogoUrl"
                              />
                              <span
                                v-else
                                class="c-learning-path__provider-name"
                              >{{ item.course.providerName }}</span>
                            </div>
                            <div 
                              v-if="$isAllowed('displayPricingInformation')" 
                              class="c-learning-path__price"
                            >
                              <h4 
                                data-test="course-price"
                                class="c-learning-path__price-value"
                              >
                                {{ formatPrice(item.course.price) }}
                              </h4>
                              <span 
                                data-test="vat_incl_or_excl"
                                class="c-learning-path__price-vat ml-3 font-italic"
                              >{{ getVatText(item.course.vatIncluded) }}</span>
                            </div>
                          </div>
                          <div class="d-flex mt-2 mt-lg-0 justify-content-between">
                            <recess-button
                              :url="{name: 'course', query:{ course: item.course.id, assortment: item.course.assortmentId}}"
                              title="Bekijk details"
                              variant="tertiary"
                            />
                            <div class="d-lg-none c-learning-path__icons">
                              <recess-icon
                                icon="drag"
                                color="secondary"
                                cursor="move"
                                class="c-learning-path__icon--drag qa-edit-lp__drag-icon"
                              />
                              <recess-divider
                                vertical
                                show-line
                                class="d-inline-flex"
                              />
                              <recess-icon
                                icon="bin"
                                color="secondary"
                                class="qa-edit-lp__delete-course-icon"
                                @click="removeCourse(item.id)"    
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Logo and icons Desktop-->
                      <div class="d-none d-lg-flex c-learning-path__actions">
                        <div v-if="item.course.providerLogoUrl || item.course.providerName">
                          <img
                            v-if="item.course.providerLogoUrl"
                            alt="Provider logo"
                            class="c-learning-path__provider-logo"
                            :src="item.course.providerLogoUrl"
                          />
                          <span
                            v-else
                            class="c-learning-path__provider-name"
                          >{{ item.course.providerName }}</span>
                        </div>
                        <div class="c-learning-path__icons">
                          <recess-icon
                            icon="drag"
                            color="secondary"
                            cursor="move"
                            class="c-learning-path__icon--drag qa-edit-lp__drag-icon"
                          />
                          <recess-divider
                            vertical
                            show-line
                            class="d-inline-flex"
                          />
                          <recess-icon
                            icon="bin"
                            color="secondary"
                            @click="removeCourse(item.id)"
                          />
                        </div>
                      </div>
                    </div>
                  </recess-card>
                  <recess-divider />
                </div>
              </transition-group>
            </draggable>

            <span class="js-scroll-bottom"></span>
            
            <!-- Deleted courses -->
            <div v-if="unavailableLearningPathCourses.length">
              <template v-for="(item, i) in unavailableLearningPathCourses">
                <recess-card
                  v-if="item.course"
                  :key="i"
                  class="d-flex flex-column flex-lg-row"
                >
                  <!-- Course Title -->
                  <div class="col-12 col-lg-1 c-learning-path__counter-wrapper px-lg-0">
                    <div
                      class="c-learning-path__counter-number"
                    ></div>
                  </div>
                  <div class="col-12 col-lg-11 px-0 pt-2 pt-lg-0">
                    <div class="c-learning-path__header row">
                      <h4 class="col-lg-9">{{ item.course.title }}</h4>
                    </div>

                    <recess-divider
                      class="pt-lg-0"
                      show-line
                    />
                  
                    <!-- Course learning method -->
                    <ul class="c-learning-path__list">
                      <li
                        v-if="item.course.LearningMethodDisplayValue"
                        class="c-learning-path__list-text"
                      >
                        {{ item.course.LearningMethodDisplayValue }}
                      </li>
                    </ul>

                    <!-- Course description -->
                    <div class="row c-learning-path__body">
                      <p
                        class="col-lg-6 col-xl-7 d-none d-lg-block"
                        v-html="cutOff(item.course.content, 200)"
                      ></p>

                      <!-- Provider name (mobile) and not available anymore text -->
                      <div class="col-12 col-lg-6 col-xl-5 c-learning-path__info">
                        <div class="c-learning-path__actions">
                          <div
                            v-if="item.course.providerName"
                            class="d-lg-none"
                          >
                            <span
                              class="c-learning-path__provider-name"
                            >{{ item.course.providerName }}</span>
                          </div>
                          <div class="d-flex flex-column">
                            <span class="u-textcolor-blue-grey font-weight-bold">Niet meer beschikbaar</span>
                            <div class="d-flex justify-content-end mt-2">
                              <recess-icon 
                                icon="bin"
                                color="secondary"
                                @click="removeCourse(item.id)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Provider name Desktop-->
                    <div class="d-none d-lg-flex c-learning-path__actions">
                      <div v-if="item.course.providerName">
                        <span
                          class="c-learning-path__provider-name"
                        >{{ item.course.providerName }}</span>
                      </div>
                    </div>
                  </div>
                </recess-card>
                <recess-divider :key="`divider ${i}`" />
              </template>
            </div>
            
            <recess-button
              class="u-position-right qa-edit-lp__save-button"
              title="Opslaan"
              variant="primary"
              @click.native="updateLearningPath"
            />
            <div v-if="!isLoading && showErrorMessage">
              <recess-divider variant="large" />
              <recess-alert
                class="u-bg--variant-gray1"
                type="error"
                text="Er is iets misgegaan. Probeer het later nog een keer."
              />
            </div>
          </div>
          <template v-if="!unavailableLearningPathCourses.length && !learningPathCourses.length">
            <recess-alert text="Er zijn momenteel geen ontwikkelactiviteiten in dit leerpad." />
          </template>
        </recess-layout-static>
        <recess-divider />
      </div>
    </template>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { scrollTo } from 'vue-scrollto'
import { required } from 'vuelidate/lib/validators'
import { isFuture } from '../../../modules/validationsHelper'
import { cutOff } from '../../../modules/textFormatter'
import ArrowBackSVG from '../../organisms/IconComponents/ArrowBackSVG.vue'
import FeedbackStarsComponent from '../../molecules/FeedbackStarsComponent/FeedbackStarsComponent.vue'
import {
    getLearningPathById,
    setSorting,
    deleteLearningPathCourses,
    patchLearningPath
} from '../../../api/learningPathClient'
import priceFormatter from '../../../modules/priceFormatter'
import DateUtils from '../../../utils/DateUtils'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import { getFilePathByName } from '../../../modules/assetsHelper'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import previousRouteMixin from '../../../mixins/previous-route'

export default {
    perimeters: [learningPathPerimeter, featuresPerimeter],
    name: 'EditLearningPath',
    components: {
        draggable,
        FeedbackStarsComponent,
        ArrowBackSVG
    },
    mixins: [ previousRouteMixin ],
    validations() {
        return {
            mLearningPath: {
                title: { required },
                endDate: { isFuture }
            }
        }
    },

    props: {
        scrollToBottom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isFetchingLearningPath: false,
            isUpdatingLearningPath: false,
            showErrorMessage: false,
            learningPath: null,
            mLearningPath: null,
            icons: null,
            selectedIcon: null,
            drag: false,
            learningPathCourses: [],
            unavailableLearningPathCourses: []
        }
    },
    computed: {
        iconPath() {
            return getFilePathByName('images/learningPath', this.mLearningPath?.iconName)
        },
        dragOptions() {
            return {
                animation: 200,
                ghostClass: 'c-learning-path__drag-ghost'
            }
        },
        isLoading() {
            return this.isFetchingLearningPath || this.isUpdatingLearningPath
        },
        goBackButtonUrl() {
            if (this.prevRoute) {
                return {
                    ...this.prevRoute, 
                    query: {
                        ...this.prevRoute.query, 
                        'assign-learning-path-tab': undefined 
                    }
                }
            }
          
            return this.learningPath?.isPersonal ?
                { name: 'learning-path-details', params:{ id: this.learningPath.id }} :
                { name:'my-team' , query: { 'my-team-tab': 'learning-paths'}}
        },
        sorting() {
            const sorting = this.learningPathCourses?.map((lpc, index) => {
                return {
                    courseId: lpc.course?.id,
                    learningPathCourseId: lpc.id,
                    order: index
                }
            }, [])
                
            // Push also unavailable courses in the learning paths
            this.unavailableLearningPathCourses.forEach(lpc => {
                sorting.push({
                    courseId: lpc.course?.id, 
                    learningPathCourseId: lpc.id,
                    order: sorting.length
                })
            })
            return sorting
        },
        didOrderChange() {
            const initialSortedCourses = this.learningPath?.courses
            if (this.sorting.length){
                return initialSortedCourses?.some(courseFromInitialSorting => {
                    const courseFromCurrentSorting = this.sorting.find(x => x.courseId === courseFromInitialSorting.course?.id)
                    return courseFromCurrentSorting?.order !== courseFromInitialSorting?.order
                })}
            return false
        }
    },
    watch: {
        icons(value) {
            // Get the icon path if is selected
            if (value && this.mLearningPath?.iconName) {
                this.selectedIcon = this.icons?.find(icon => icon.displayText === this.mLearningPath.iconName).value ?? null
            }
        }
    },
    async created() {
        await this.fetchLearningPath()
        this.getIcons()
        this.$nextTick(() => {
            if (this.scrollToBottom) {
                scrollTo('.js-scroll-bottom', {
                    offset: -500,
                    duration: -1
                })
            }
        })
    },
    methods: {
        getVatText(vatIncluded) {
            return coursePriceHelper.getVatText(vatIncluded)
        },
        removeCourse(learningPathCourseId) {
            this.learningPathCourses = this.learningPathCourses.filter(x => x.id !== learningPathCourseId)
            this.unavailableLearningPathCourses = this.unavailableLearningPathCourses.filter(x => x.id !== learningPathCourseId) 
        },
        async fetchLearningPath() {
            try {
                this.isFetchingLearningPath = true
                const id = this.$route?.params?.id
                this.learningPath = await getLearningPathById(id)

                // We will also assing the same values to the mLearning property. This is to check what property changed when we do the call to the backend.
                this.mLearningPath = {
                    title: this.learningPath?.title,
                    description: this.learningPath?.description,
                    iconName: this.learningPath?.iconName,
                    endDate: this.learningPath?.endDate ? DateUtils.formatDate(this.learningPath?.endDate) : null,
                    hasObligatedOrder: this.learningPath?.hasObligatedOrder,
                    onlyManageableByTheCreator: this.learningPath?.onlyManageableByTheCreator
                }

                // We're using a method to set the courses in a data property instead of just using a computed property.
                // This is because IE cannot work with draggable and computeds together.
                this.setLearningPathCourses(this.learningPath)
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.isFetchingLearningPath = false
            }
        },
        setLearningPathCourses(learningPath) {
            const sortedCourses = learningPath?.courses?.sort((a, b) => a.order - b.order)
            this.learningPathCourses = sortedCourses?.reduce((acc, item) => {
                if (item.course && !item.course.deleted) acc.push({ course: item.course, id: item.id })
                return acc
            },[])
            this.unavailableLearningPathCourses = sortedCourses?.reduce((acc, item) => {
                if (item.course?.deleted) acc.push({ course: item.course, id: item.id })
                return acc
            },[])

        },
        async updateLearningPath() {
            // We'll create an empty array to put the patch and sort requests in, so we can do promise.all on these two requests.
            const requests = []
           
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                this.isUpdatingLearningPath = true
                // ------------------------------- DELETE COURSES -------------------------------
                // Check if course have been removed from this learning path
                if(this.sorting?.length !== this.learningPath?.courses?.length) {
                
                    // Get the id's of the courses that were deleted from the list
                    const learningPathCoursesIds = this.learningPath?.courses?.map(x => x.id)
                    const mLearningPathCoursesIds = this.sorting.map(x => x.learningPathCourseId)
                    const deletedCourseIds = learningPathCoursesIds.filter(x => !mLearningPathCoursesIds.includes(x))

                    // Do the request to remove the courses from the learningpath permanently
                    await deleteLearningPathCourses(this.learningPath?.id, deletedCourseIds)
                }

                // ------------------------------- UPDATE LEARNING PATH PROPERTIES -------------------------------
                // Check if any of the 'simple' fields have changed (title, description, date etc.)
                const patchArray = Object.keys(this.mLearningPath).reduce((acc, key) => {

                    // first check if a date value has changed. If so, parse that date so the backend can do something with it.
                    if(key.toLowerCase().indexOf('date') >= 0 && DateUtils.formatDate(this.learningPath[key]) !== this.mLearningPath[key]) {
                        acc.push({
                            op: 'replace',
                            path: `/${key}`,
                            value: DateUtils.parseToISOString(this.mLearningPath[key])
                        })
                    }
                    
                    // For all the other keys, just check if they have changed.
                    if (key.toLowerCase().indexOf('date') < 0 && this.mLearningPath[key] !== this.learningPath[key]) {
                        acc.push({
                            op: 'replace',
                            path: `/${key}`,
                            value: this.mLearningPath[key]
                        })
                    }
                    return acc
                }, [])

                // Do the request to update the 'simple' learningpath fields 
                if (patchArray.length) {
                    requests.push(patchLearningPath(this.learningPath?.id, patchArray))
                }

                // ------------------------------- POST NEW ORDER -------------------------------
                if (this.didOrderChange) {
                    requests.push(setSorting(this.learningPath.id, this.sorting))
                }

                // This array includes the patch (if made any changes) and the sort
                if (requests.length) {
                    await Promise.all(requests)
                    await this.fetchLearningPath()
                    
                }
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isUpdatingLearningPath = false
            }
        },
        learningMethodFormatText(course) {
            return course?.learningMethodFormatDisplayValue || course?.learningMethodFormat
        },
        learningMethodTypeText(course) {
            if(!course?.learningMethodTypeDisplayValue && !course?.learningMethodType) return null
            
            // if there is a format, display the type in brackets, otherwise, lose the brackets
            return course?.learningMethodFormatDisplayValue || course?.learningMethodFormat ? 
                `(${course?.learningMethodTypeDisplayValue || course?.learningMethodType})` : 
                course?.learningMethodTypeDisplayValue || course?.learningMethodType
        },
        getIcons() {
            // Get learning path icons context
            const staticIcons = require.context('../../../assets/images/learningPath')

            // Set the icons options and push empty option
            this.icons = [{displayText: "Geen afbeelding", value: null}, ...staticIcons.keys().map(key =>{
                return {
                    // Extract the name of the icon as the key value contain the absolute path like /name.png
                    displayText: key.substring(key.lastIndexOf("/") + 1, key.lastIndexOf(".")),
                    value: staticIcons(key)
                }
            })]
        },
        updateIcon() {
            if(this.selectedIcon) {
                this.mLearningPath.iconName = this.icons?.find(icon => icon.value === this.selectedIcon)?.displayText ?? null
            } else { 
                this.mLearningPath.iconName = null
            }
        },
        formatPrice: priceFormatter.formatPrice,
        cutOff
    }
}
</script>
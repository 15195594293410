/* eslint-disable no-underscore-dangle */
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights = null

export default {
    /**
     * init store, wait for route hooks and mout app
     * 
     * @param { string } instrumentationKey 
     */
    initializeAppInsights(instrumentationKey) {
        if (instrumentationKey) {
            appInsights = new ApplicationInsights({ config: {
                instrumentationKey
            } })
            appInsights.loadAppInsights()
        }
    }
}

export const getAppInsights = () => appInsights

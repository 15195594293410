<template>
  <recess-card
    class="u-bg-gray-medium-light"
  >
    <recess-file-upload
      ref="uploadFileInput"
      data-test="upload-input"
      :max-size-file="maxFileSize"
      :label-text="inputLabel"
      :max-file-to-upload="maxFilesToUpload"
      :allowed-extensions="allowedExtensions"
      :placeholder-text="placeholderText"
      @upload="userInputFile => fileToUpload = userInputFile[0]"
    />
    <div class="d-flex justify-content-end mt-3">
      <recess-button
        class="col-12 col-md-auto"
        title="Uploaden"
        variant="primary"
        :disabled="!fileToUpload"
        data-test="upload-btn"
        @click="$emit('upload', fileToUpload)"
      />
    </div>
    <recess-alert
      v-if="showUploadError"
      data-test="upload-error-msg"
      class="mt-3"
      type="error"
      text=" Er is iets fout gegaan tijdens het uploaden van uw bestand, probeer het later nog eens."
    />
    <!-- Success message modal -->
    <recess-modal
      modal-size="small"
      :show-modal="showSuccessUploadMessage"
      @close="$emit('closeSuccessModal')"
    >
      <div
        slot="modal-body"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <p data-test="success-upload-msg">Uw bestand is succesvol geupload</p>
        <div>
          <recess-button
            variant="primary"
            title="Ok"
            data-test="close-success-btn"
            @click="$emit('closeSuccessModal')"
          />
        </div>
      </div>
    </recess-modal>
  </recess-card>
</template>

<script>

export default {
    props: {
        maxFileSize: {
            type: String,
            default: '5000000'
        },
        inputLabel: {
            type: String,
            default: 'Kies bestand'
        },
        maxFilesToUpload: {
            type: Number,
            default: 1
        },
        placeholderText: {
            type: String,
            default: '.pdf .tiff .zip .jpg .png'
        },
        showUploadError: {
            type: Boolean,
            default: false
        },
        showSuccessUploadMessage: {
            type: Boolean,
            default: false
        },
        allowedExtensions: {
            type: Array,
            default: () => {
                return ['pdf', 'tif', 'tiff', 'zip', 'jpg', 'png']
            }
        }
    },
    data() {
        return {
            fileToUpload: null
        }
    },
    methods: {
        // Warning: This method is not used in this component, but referenced from its parent. Do not remove this method unless you are sure it's not used anymore.
        reset() {
            this.fileToUpload = null
            this.$refs.uploadFileInput.selectedFiles = []
        }
    }
}
</script>
<template>
  <div>
    <div v-if="isMobileOrTablet">
      <slot name="course-page-accordion">
        <course-page-accordion />
      </slot>
    </div>
    <div v-else>
      <slot name="course-page-tabs">
        <course-page-tabs />
      </slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scrollTo } from 'vue-scrollto'
import CoursePageAccordion from './CoursePageAccordion.vue'
import CoursePageTabs from './CoursePageTabs.vue'
import useMedia from '../../../composables/useMediaQueries'
import mediaQueries from '../../../constants/mediaQueries'

export default {
    components: {
        CoursePageAccordion,
        CoursePageTabs
    },
    setup() {
        return {
            isMobileOrTablet: useMedia(mediaQueries.MEDIUM)
        }
    },
    computed: {
        ...mapGetters('coursePageModule', ['defaultTab'])

    },
    mounted() {
        if (!this.$route?.query?.['course-page-tab']) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    'course-page-tab': this.defaultTab
                }
            })
            scrollTo('body')
        }
    }
}
</script>

import learningResultSharingOptionsTypes from "./learningResultSharingOptionsTypes"
import learningResultSharingTypes from "./learningResultSharingTypes"
import learningResultSharingOptions from "./learningResultSharingOptions"

export default {
    [learningResultSharingTypes.LEGAL.key]: {
        displayName: learningResultSharingTypes.LEGAL.displayValue,
        key: learningResultSharingTypes.LEGAL.key,
        options: [
            {
                key: learningResultSharingOptionsTypes.LEGAL.PASSEDEXAMCONSENT,
                displayName: learningResultSharingOptions.PASSEDEXAMCONSENT.displayName
            },
            {
                key: learningResultSharingOptionsTypes.LEGAL.DIPLOMACONSENT,
                displayName: learningResultSharingOptions.DIPLOMACONSENT.displayName
            },
            {
                key: learningResultSharingOptionsTypes.LEGAL.COMPLETIONATRISKCONSENT,
                displayName: learningResultSharingOptions.COMPLETIONATRISKCONSENT.displayName
            },
            {
                key: learningResultSharingOptionsTypes.LEGAL.PROGRESSPERCENTAGECONSENT,
                displayName: learningResultSharingOptions.PROGRESSPERCENTAGECONSENT.displayName
            }
        ]
    },
    [learningResultSharingTypes.EMPLOYER.key]: {
        displayName: learningResultSharingTypes.EMPLOYER.displayValue,
        key: learningResultSharingTypes.EMPLOYER.key,
        options: [
            {
                key: learningResultSharingOptionsTypes.EMPLOYER.PASSEDEXAMCONSENT,
                displayName: learningResultSharingOptions.PASSEDEXAMCONSENT.displayName
            },
            {
                key: learningResultSharingOptionsTypes.EMPLOYER.DIPLOMACONSENT,
                displayName: learningResultSharingOptions.DIPLOMACONSENT.displayName
            },
            {
                key: learningResultSharingOptionsTypes.EMPLOYER.COMPLETIONATRISKCONSENT,
                displayName: learningResultSharingOptions.COMPLETIONATRISKCONSENT.displayName
            },
            {
                key: learningResultSharingOptionsTypes.EMPLOYER.PROGRESSPERCENTAGECONSENT,
                displayName: learningResultSharingOptions.PROGRESSPERCENTAGECONSENT.displayName
            }
        ]
    }
}


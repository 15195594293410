<template>
  <div :class="[{'c-mobile-navigation__item': !cssClass}, cssClass]">
    <slot></slot>
  </div>
</template>

<script>
export default {
    props: {
        cssClass: {
            type: String,
            default: null
        }
    }
}
</script>
const mixin = {
    directives: {
        focus: {
            // directive definition
            componentUpdated(el, binding) {
                if (binding.value) {
                    el.focus()
                }
            }
        }
    }
}

export default mixin

import priceFormatterHelper from './priceFormatter'

const getCalculatedPriceDisplayValue = (totalPrice, vatAmount, discount, displayVatInPrices) => {
    const priceValue = displayVatInPrices
        ? totalPrice +
          vatAmount -
          discount
        : totalPrice - discount
        
    return priceFormatterHelper.formatPrice(priceValue)
}

const getInvoiceItemCalculatedPrice = (invoiceItem, isVatExempt, displayVatInPrices) => {
    if(!invoiceItem) return null

    if(isVatExempt)
        return getCalculatedPriceDisplayValue(invoiceItem.vatExemptAmount, invoiceItem.vatExemptVatAmount, invoiceItem.vatExemptDiscountAmount, displayVatInPrices) 

    return getCalculatedPriceDisplayValue(invoiceItem.priceBeforeTax, invoiceItem.vatAmount, invoiceItem.discountAmountWithoutVat, displayVatInPrices)
}

/**
 * Get vat text
 * 
 * @param {boolean} displayVat  
 * @returns { 'incl. btw' | 'excl. btw' } 
 */
const getVatText = displayVat => {
    return displayVat ? 'incl. btw' : 'excl. btw'
}

/**
 * Get formatted price with or without vat
 * 
 * @param {boolean} displayVat  
 * @param {object} price
 * @returns {string} Example: € 10.000,-
 */
const getPriceWithOrWithoutVat = (displayVat, price) => {
    if(!price) return '0,-'

    return priceFormatterHelper.formatPrice(displayVat ? price.totalPrice : price.totalPriceWithoutVAT)
}

export default {
    getInvoiceItemCalculatedPrice,
    getVatText,
    getPriceWithOrWithoutVat
}
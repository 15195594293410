<template>
  <div class="c-course-content">
    <!-- Tab navigation -->
    <recess-tab-navigation
      name="course-page"
      class="js-scroll-to-tabs"
    >
      <recess-tab-navigation-item
        v-if="showGeneralContent"
        data-test="general-tab"
        :tab-key="tabKeys.GENERAL"
        @click.native="scrollToTab()"
      >
        Algemene informatie
      </recess-tab-navigation-item>

      <recess-tab-navigation-item
        v-if="showProgramContent"
        :tab-key="tabKeys.PROGRAM"
        data-test="program-tab"
        @click.native="scrollToTab()"
      >
        Programma
      </recess-tab-navigation-item>

      <recess-tab-navigation-item
        v-if="showCertificationContent"
        :tab-key="tabKeys.CERTIFICATION"
        data-test="certification-tab"
        @click.native="scrollToTab()"
      >
        Certificering
      </recess-tab-navigation-item>

      <recess-tab-navigation-item
        v-if="showPlanningContent"
        data-test="tab-nav-planning"
        :tab-key="tabKeys.PLANNING"
        @click.native="scrollToTab()"
      >
        <transition name="fade">
          <loader-component
            v-if="checkingIfUserHasAccess"
            overlay="white"
            color="primary"
          />
        </transition>
        Planning
      </recess-tab-navigation-item>

      <recess-tab-navigation-item
        v-if="(isFetchingCoursePrice || showInvestmentContent) && $isAllowed('displayPricingInformation')"
        :tab-key="tabKeys.INVESTMENT"
        data-test="investment-tab"
        
        @click.native="scrollToTab()"
      >
        <transition name="fade">
          <loader-component
            v-if="isFetchingCoursePrice"
            overlay="white"
            color="primary"
          />
        </transition>
        Investering
      </recess-tab-navigation-item>
    </recess-tab-navigation>
    <recess-divider />

    <!-- General -->
    <div
      :class="['c-course-content__item d-none', {'d-block' : $route.query['course-page-tab'] === tabKeys.GENERAL }]"
    >
      <course-page-content-general />
    </div>

    <!-- Program -->
    <div
      :class="['c-course-content__item d-none', {'d-block' : $route.query['course-page-tab'] === tabKeys.PROGRAM }]"
    >
      <course-page-content-program />
    </div>

    <!-- Certification -->
    <div
      :class="['c-course-content__item d-none', {'d-block' : $route.query['course-page-tab'] === tabKeys.CERTIFICATION }]"
    >
      <course-page-content-certification />
    </div>

    <!-- Planning -->
    <div
      :class="['c-course-content__item d-none',
               { 'd-block' : $route.query['course-page-tab'] === tabKeys.PLANNING },
               { 'u-min-height-300' : checkingIfUserHasAccess }]"

      data-test="planning-tab"
    >
      <transition name="fade">
        <loader-component
          v-if="checkingIfUserHasAccess"
          overlay="white"
          color="primary"
        />
      </transition>
      <slot name="planning-content">
        <course-page-content-planning />
      </slot>
    </div>

    <!-- Investment -->
    <div
      :class="['c-course-content__item u-position-relative d-none', 
               { 'd-block' : $route.query['course-page-tab'] === tabKeys.INVESTMENT }, 
               { 'u-min-height-300' : isFetchingCoursePrice }]"
    >
      <transition name="fade">
        <loader-component
          v-if="isFetchingCoursePrice"
          overlay="white"
          color="primary"
        />
      </transition>
      <course-page-content-investment />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { scrollTo } from 'vue-scrollto'
import tabKeys from './tabKeys'

import CoursePageContentGeneral from './CoursePageContentGeneral.vue'
import CoursePageContentProgram from './CoursePageContentProgram.vue'
import CoursePageContentCertification from './CoursePageContentCertification.vue'
import CoursePageContentPlanning from './CoursePageContentPlanning.vue'
import CoursePageContentInvestment from './CoursePageContentInvestment.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'


export default {
    components: {
        LoaderComponent,
        CoursePageContentGeneral,
        CoursePageContentProgram,
        CoursePageContentCertification,
        CoursePageContentPlanning,
        CoursePageContentInvestment
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            tabKeys
        }
    },
    computed: {
        ...mapGetters('coursePageModule', [
            'showGeneralContent',
            'showProgramContent',
            'showCertificationContent',
            'showInvestmentContent',
            'showPlanningContent'
        ]),
        ...mapState('coursePageModule', ['isFetchingCoursePrice', 'checkingIfUserHasAccess'])
    },
    methods: {
        scrollToTab() {
            scrollTo(`.js-scroll-to-tabs`, {
                offset: -150
            })
        }
    }
}
</script>
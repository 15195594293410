<template>
  <lightbox-component
    :is-visible="isVisible"
    :show-close-button="showCloseButton"
    @close="close()"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <h3>Account selecteren</h3>
    <p>U heeft toegang tot meerdere omgevingen. Selecteer de organisatie waarvoor u wilt inloggen.</p>
     
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="accounts">
        <div class="row">
          <recess-select
            v-model="selectedUserId"
            :default-value="selectedUserId"
            class="col-12 col-md-6 "
            select-option-text="Maak een keuze"
            data-test="select-account-input"
            :options="accounts"
          />
        </div>
          
        <recess-button
          class="mt-3"
          variant="primary"
          data-test="submit-btn"
          title="Opslaan"
          :disabled="!selectedUserId"
          @click="submit"
        />
      </div>
    </transition>

    <recess-alert
      v-if="showErrorMessage"
      type="error"
      text="Er is een fout opgetreden. Probeer het later opnieuw."
      class="mt-4"
    />
  </lightbox-component>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'
import userClient from '../../../api/userClient'
import { userSwitch } from '../../../api/AuthenticationClient'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'

export default {
    components: {
        LoaderComponent,
        LightboxComponent
    },
    props: {
        isVisible: {
            type: Boolean,
            default: true
        },
        showCloseButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedUserId: null,
            isFetchingUsers: false,
            isSwitchingAccount: false,
            accounts: null,
            showErrorMessage: false
        }
    },
    computed: {
        ...mapGetters('userModule', ['sharedIds', 'idServerUserId', 'legalAgreementAcceptedDate']),
        ...mapGetters('configurationModule', ['oidcConfig']),
        isLoading() {
            return this.isFetchingUsers || this.isSwitchingAccount
        },
        selectedOption() {
            return this.accounts.find(e => e.value === this.selectedUserId)
        }
    },
    async mounted() {
        if(this.sharedIds?.length > 1)
            await this.searchUsers()    
    },
    methods: {
        async submit() {
            try {
                this.isSwitchingAccount = true
                const redirectPath = sessionStorage.getItem('oidc_active_route')
                if(this.selectedUserId === this.idServerUserId) {
                    this.$router.replace(!this.legalAgreementAcceptedDate ? { name: 'legal-agreements' } : redirectPath)
                    this.isSwitchingAccount = false
                    this.close()
                } else {
                    // Reset redirect url if switching account after login to avoid being redirect to a previous page that you might not have access
                    // or not existing for the other account you are switching to
                    const isAccountAlreadySelected = sessionStorage.getItem('account_selected')
                    if(isAccountAlreadySelected) {
                        sessionStorage.removeItem('oidc_active_route')
                    }
                    this.$router.replace({query: { ref: undefined } })
                    await userSwitch(this.oidcConfig.authority, this.idServerToken, this.selectedUserId)
                }

                const accountCode = this.selectedOption?.accountCode
                sessionStorage.setItem('account_selected', accountCode)
            } catch (error) {
                this.showErrorMessage = true
                this.isSwitchingAccount = false
                throw new Error(error)
            }
        },
        async searchUsers() {
            try {
                this.isFetchingUsers = true
                const dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .addFilter(new ODataQueryFilter("identityServerUserID", ODataFilterOperations.EQUALS, this.sharedIds, null, 'or'))
                    .addFilter(new ODataQueryFilter("isEnabled", ODataFilterOperations.EQUALS, true, null, null))
                    .setFields('account.name, identityServerUserID, account.accountCode')
                    .build()

                // TODO - what if there is a problem with the search???? in case of error nothing happens
                const response = await userClient.searchUsers(dataQuery)

                this.accounts = response.items?.map(user => {
                    return {
                        displayText: user?.account?.name,
                        value: user?.identityServerUserID,
                        accountCode: user?.account?.accountCode
                    }
                })

                // no users found, probably are disabled, send to user disabled page
                if(!this.accounts?.length)
                {
                    this.$router.replace({ name: 'disabled-user'})
                }

            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingUsers = false
            }
        },
        close() {
            if(!this.isLoading) this.$emit('close')
        }
    }
}
</script>

import axios from 'axios'
import { getOplzClient } from './apiClient'

const baseUrl = '/address'

const FetchAddressCancelToken = axios.CancelToken
let fetchAddressCancel

export const getAddress = async (postalCode, houseNumber) => {
    const client = await getOplzClient()
    return client
        .get(`${baseUrl}?postCode=${postalCode?.replace(/ +/g, '')}&houseNumber=${houseNumber}`, {
            cancelToken: new FetchAddressCancelToken(c => {
                // An executor function receives a cancel function as a parameter
                fetchAddressCancel = c
            })
        })
        .then(response => {
            return response?.data
        })
}

export const cancelFetchAddress = () => {
    if (fetchAddressCancel !== undefined) {
        fetchAddressCancel()
    }
}

export default {
    getAddress,
    cancelFetchAddress
}

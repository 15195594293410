<template>
  <recess-layout-static :size="1200">
    <transition
      name="fade"
      mode="out-in"
    >
      <recess-loader
        v-if="isLoading"
        :full-screen="true"
        overlay="white"
        color="primary"
      />
    </transition>
    <recess-divider />
    <div class="row">
      <div class="col-12 col-lg-8">
        <h1>Contactformulier</h1>
        <p>Heeft u een vraag, kijk dan bij onze veelgestelde vragen. Vindt u daar geen passend antwoord, dan kunt u gebruik maken van onderstaand contactformulier.</p>
        <div class="row">
          <div class="col-12 col-lg-10">
            <recess-input
              v-model="formData.subject"
              :has-error="$v.formData.subject.$error"
              :error-message="getErrorMessage('subject')"
              label-text="Onderwerp *"
            />
            <recess-textarea-input
              v-model="formData.message"
              :has-error="$v.formData.message.$error"
              :error-message="getErrorMessage('message')"
              label-text="Bericht *"
              class="mt-3"
            />
            <recess-button
              class="mt-3 u-min-width-200"
              variant="secondary"
              title="Versturen"
              @click="sendContactForm"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <recess-alert
              v-if="showErrorMessage"
              type="error"
              class="col-12 mt-3"
              text="Er is iets misgegaan. Probeer het later nog een keer."
            />
            <recess-alert
              v-if="showSuccessMessage"
              type="success"
              class="col-12 mt-3"
              text="Uw bericht is successvol verzonden. Wij nemen zo spoedig mogelijk contact met u op."
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <contact-teaser-component />
      </div>
    </div>
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import contactClient from '../../../api/contactClient'
import ContactTeaserComponent from '../../molecules/ContactTeaserComponent/ContactTeaserComponent.vue'

export default {
    components: {
        ContactTeaserComponent
    },
    validations() {
        return {
            formData: {
                subject: { required },
                message: { required }
            }
        }
    },
    data() {
        return {
            isLoading: false,
            showSuccessMessage: false,
            showErrorMessage: false,
            formData: {
                subject: null,
                message: null
            },
            validationErrorMessages: {
                required: {
                    subject: 'Onderwerp is verplicht',
                    message: 'Bericht is verplicht'
                }
            }
        }
    },
    methods: {
        sendContactForm() {
            this.showErrorMessage = false
            this.showSuccessMessage = false

            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            this.isLoading = true

            contactClient
                .postContactForm(this.formData)
                .then(() => {
                    this.showSuccessMessage = true

                    this.formData.subject = null
                    this.formData.message = null
                    this.$v.$reset()
                })
                .catch(() => {
                    this.showErrorMessage = true
                })
                .then(() => {
                    this.isLoading = false
                })
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData[fieldName]

            if (fieldValidation && fieldValidation.$error && !fieldValidation.required) {
                return this.validationErrorMessages.required[fieldName]
            }

            return null
        }
    }
}
</script>

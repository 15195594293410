import { isNotEmptyValue } from '../../modules/validationsHelper'

export default class BaseDataQueryBuilder {
    constructor(dataQuery) {
        this.DataQuery = dataQuery
        this.Filters = []
        this.DynamicFilters = []
    }

    generateFilters() {
        let filterParam = ''
        const concat = 'and'

        this.Filters.forEach(filter => {
            // create filter string only for filter with values
            if ((!Array.isArray(filter.values) && isNotEmptyValue(filter.values)) || 
            (Array.isArray(filter.values) && filter.values.length) ||
            (!isNotEmptyValue(filter.values) && filter.options?.acceptEmptyValues)) {
                filterParam += `${filter.generateFilterForOData()} ${concat} `
            }
        })

        // Add dynamic filters
        if (this.DynamicFilters.length) {
            this.DynamicFilters.forEach(filter => {
                filterParam += `${filter} ${concat}`
            })
        }
        // remove trailing 'and'
        filterParam = filterParam.substring(0, filterParam.lastIndexOf(concat)).trim()
        
        return filterParam
    }

    addFilter(odataFilter) {
        this.Filters.push(odataFilter)
        
        return this
    }

    addDynamicFilter(dynamicFilter) {
        if (!dynamicFilter) return this
        this.DynamicFilters.push(dynamicFilter)
        return this
    }

    addFilters(odataFilter) {
        odataFilter.forEach(filter => {
            this.Filters.push(filter)
        })
        return this
    }

    or() {
        const filterParam = this.generateFilters()
        if (this.DataQuery.filter) this.DataQuery.filter = `(${this.DataQuery.filter}(${filterParam})) or `
        else this.DataQuery.filter = `(${filterParam}) or `

        // reset filters
        this.Filters = []
        this.DynamicFilters = []
        
        return this
    }

    and() {
        const filterParam = this.generateFilters()
        if (this.DataQuery.filter) this.DataQuery.filter = `(${this.DataQuery.filter}(${filterParam})) and `
        else this.DataQuery.filter = `(${filterParam}) and `

        // reset filters
        this.Filters = []
        this.DynamicFilters = []

        return this
    }

    build() {
        const filterParam = this.generateFilters()

        if (this.DataQuery.filter) this.DataQuery.filter += `(${filterParam})`
        else this.DataQuery.filter = filterParam

        return this.DataQuery
    }
}

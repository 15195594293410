<template>
  <div>
    <recess-table>
      <recess-table-head>
        <recess-table-row>
          <template v-for="header in tableHeaders">
            <recess-table-head-item
              v-if="tableHeaders"
              :key="header.key"
              :sortable="header.sortable"
              :sort-direction="getSortDirection(header)"
              @click.native="setSorting(header)"
            >
              {{ header.title }}
            </recess-table-head-item>
          </template>
        </recess-table-row>
      </recess-table-head>
      <recess-table-body>
        <recess-table-row
          v-for="projectRequest in projectRequests"
          :key="projectRequest.id"
        >
          <recess-table-cell :data-label="tableHeaders[0].title">{{
            projectRequest.id
          }}</recess-table-cell>
          <recess-table-cell :data-label="tableHeaders[1].title">{{
            projectRequest.name
          }}</recess-table-cell>
          <recess-table-cell :data-label="tableHeaders[2].title">
            {{ formatDate(projectRequest.dateCreated) }}
          </recess-table-cell>
          <recess-table-cell :data-label="tableHeaders[3].title">{{
            projectRequest.providerName
          }}</recess-table-cell>
          <recess-table-cell :data-label="tableHeaders[4].title">
            <div class="d-flex flex-column">
              <span>{{ projectRequest.userFullName }}</span>
              <span class="u-textcolor-grey-dark">{{
                projectRequest.userEmailAddress
              }}</span>
            </div>
          </recess-table-cell>
          <recess-table-cell :data-label="tableHeaders[5].title">{{
            projectRequest.status.description
          }}</recess-table-cell>
          <recess-table-cell :data-label="tableHeaders[6].title">
            <div>
              <recess-button
                v-if="showApprovalButton(projectRequest)"
                :url="{
                  name: 'project-request-approval',
                  params: { id: projectRequest.id },
                  query: { listingView: listingView }
                }"
                title="Beoordelen"
                variant="primary"
                class="col-12 col-md-auto"
              />
              <recess-button
                v-else
                :url="{
                  name: 'project-request-detail',
                  params: { id: projectRequest.id },
                  query: { listingView: listingView }
                }"
                title="Bekijk"
                variant="tertiary"
                class="col-12 col-md-auto"
              />
            </div>
          </recess-table-cell>
        </recess-table-row>
      </recess-table-body>
    </recess-table>
    <pagination-component
      class="mt-4"
      :current-page-number="currentPage"
      :total-pages="totalPages"
      @goToPage="(pageIndex) => $emit('on-pagination-click', pageIndex)"
    />
  </div>
</template>

<script>
import PaginationComponent from '../../../../molecules/PaginationComponent/PaginationComponent.vue'
import projectRequestPerimeter from '../../../../../perimeters/projectRequestPerimeter'
import projectRequestListingViews from '../../../../../constants/projectRequestListingViews'
import DateUtils from '../../../../../utils/DateUtils'

export default {
    perimeters: [projectRequestPerimeter],
    components: {
        PaginationComponent
    },
    props: {
        projectRequests: {
            type: Array,
            default: () => [],
            required: true
        },
        tableHeaders: {
            type: Array,
            default: () => [],
            required: true
        },
        currentPage: {
            type: Number,
            default: null
        },
        totalPages: {
            type: Number,
            default: null
        },
        listingView: {
            type: String,
            default: null
        },
        sortField: {
            type: Object,
            default: null
        }
    },
    computed: {
        projectRequestListingViews: () => projectRequestListingViews
    },
    methods: {
        showApprovalButton({ assignedApproverId, status }) {
            const payload = {
                assignedApprover: {
                    id: assignedApproverId
                },
                status
            }
            return this.$isAllowed('approveProjectRequest', payload)
        },
        formatDate(date) {
            return DateUtils.formatDate(date)
        },
        getSortDirection(header) {
            if (!header.sortable || this.sortField?.key !== header.key) return null

            return this.sortField.direction ?? null
        },
        setSorting(header) {
            if (!header.sortable) return

            const direction =
                this.sortField?.key === header.key && this.sortField?.direction === 'desc'
                    ? 'asc'
                    : 'desc'
                    
            this.$emit('on-table-sort', { key: header.key, direction })
        }
    }
}
</script>

<template>
  <ul class="c-step-summary__steps d-flex justify-content-between">
    <li
      v-for="(item, index) in steps"
      :key="item.code"
      :class="['c-step-summary__step d-flex align-items-center', {'is-active' : item.code === currentStep.code }, {'is-clickable' : index < currentStepIndex}]"
      @click="goToStep(index)"
    >
      <span class="c-step-summary__stepnumber">{{ index + 1 }}</span>
      <span class="c-step-summary__title">
        <div :class="{'d-none d-md-inline-block' : item.code !== currentStep.code}">{{ item.label }}</div>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array,
            default: () => {
                return []
            }
        },
        currentStep: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        currentStepIndex() {
            const stepsList = Object.values(this.steps)
            return stepsList.indexOf(this.currentStep)
        }
    },
    methods: {
        goToStep(indexToGoTo) {
            if (indexToGoTo < this.currentStepIndex) this.$emit('goToStep', this.steps[indexToGoTo])
        }
    }
}
</script>


<template>
  <recess-button :title="buttonText" variant="tertiary" @click="increaseTop" />
</template>

<script>
import { computed } from 'vue'
import usePagination from '../../../../composables/usePagination'

export default {
    setup() {
        const { totalCount, increaseTop, remainingItems, initialTop } = usePagination()
        
        const buttonText = computed(() => {
            const remainingItemsText =  remainingItems.value >= initialTop ? initialTop : remainingItems.value 
            return `Load ${remainingItemsText} meer van ${totalCount.value}`
        })

        return {
            increaseTop,
            buttonText
        }
    }
}
</script>
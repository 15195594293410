<template>
  <div class="js-scroll-to-accordions">
    <!-- Algemene informatie -->
    <recess-accordion
      v-if="showGeneralContent"
      :active="$route.query['course-page-tab'] === tabKeys.GENERAL"
      variant="variant-3"
      data-test="general-tab"
      @toggled="toggleMobileTab(tabKeys.GENERAL)"
    >
      <template #accordion-title>Algemene informatie</template>
      <template #accordion-content>
        <course-page-content-general />
      </template>
    </recess-accordion>

    <!-- Programma -->
    <recess-accordion
      v-if="showProgramContent"
      :active="$route.query['course-page-tab'] === tabKeys.PROGRAM"
      variant="variant-3"
      data-test="program-tab"
      @toggled="toggleMobileTab(tabKeys.PROGRAM)"
    >
      <template #accordion-title>Programma</template>
      <template #accordion-content>
        <course-page-content-program />
      </template>
    </recess-accordion>

    <!-- Certification -->
    <recess-accordion
      v-if="showCertificationContent"
      :active="$route.query['course-page-tab'] === tabKeys.CERTIFICATION"
      variant="variant-3"
      data-test="certification-tab"
      @toggled="toggleMobileTab(tabKeys.CERTIFICATION)"
    >
      <template #accordion-title>Certificering</template>
      <template #accordion-content>
        <course-page-content-certification />
      </template>
    </recess-accordion>

    <!-- Planning -->
    <recess-accordion
      :active="$route.query['course-page-tab'] === tabKeys.PLANNING"
      variant="variant-3"
      data-test="planning-tab"
      @toggled="toggleMobileTab(tabKeys.PLANNING)"
    >
      <template #accordion-title>
        <transition name="fade">
          <loader-component
            v-if="checkingIfUserHasAccess"
            overlay="white"
            color="primary"
          />
        </transition>
        Planning
      </template>
      <template #accordion-content>
        <div
          :class="['u-position-relative', { 'u-min-height-300' : isFetchingCoursePrice }]"
        >
          <slot name="planning-content">
            <transition name="fade">
              <loader-component
                v-if="checkingIfUserHasAccess"
                overlay="white"
                color="primary"
              />
            </transition>
            <course-page-content-planning />
          </slot>
        </div>
      </template>
    </recess-accordion>

    <!-- Investment -->
    <recess-accordion
      v-if="(isFetchingCoursePrice || showInvestmentContent) && $isAllowed('displayPricingInformation')"
      :active="$route.query['course-page-tab'] === tabKeys.INVESTMENT"
      variant="variant-3"
      data-test="investment-tab"
      @toggled="toggleMobileTab(tabKeys.INVESTMENT)"
    >
      <template #accordion-title>
        <transition name="fade">
          <loader-component
            v-if="isFetchingCoursePrice"
            overlay="white"
            color="primary"
          />
        </transition>
        Investering
      </template>
      <template #accordion-content>
        <div
          :class="['u-position-relative', { 'u-min-height-300' : isFetchingCoursePrice }]"
        >
          <transition name="fade">
            <loader-component
              v-if="isFetchingCoursePrice"
              overlay="white"
              color="primary"
            />
          </transition>
          <course-page-content-investment />
        </div>
      </template>
    </recess-accordion>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { scrollTo } from 'vue-scrollto'
import tabKeys from './tabKeys'

import CoursePageContentGeneral from './CoursePageContentGeneral.vue'
import CoursePageContentProgram from './CoursePageContentProgram.vue'
import CoursePageContentCertification from './CoursePageContentCertification.vue'
import CoursePageContentPlanning from './CoursePageContentPlanning.vue'
import CoursePageContentInvestment from './CoursePageContentInvestment.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    components: {
        LoaderComponent,
        CoursePageContentGeneral,
        CoursePageContentProgram,
        CoursePageContentCertification,
        CoursePageContentPlanning,
        CoursePageContentInvestment
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            tabKeys
        }
    },
    computed: {
        ...mapState('coursePageModule', ['isFetchingCoursePrice', 'checkingIfUserHasAccess']),
        ...mapGetters('coursePageModule', [
            'showGeneralContent',
            'showProgramContent',
            'showCertificationContent',
            'showInvestmentContent'
        ])
    },
    methods: {
        toggleMobileTab(tabName) {
            if (this.$route && this.$route.query) {
                this.$router.replace({
                    path: this.$route.path,
                    query: { ...this.$route.query, 'course-page-tab': tabName }
                })
                scrollTo(`.js-scroll-to-accordions`, {
                    offset:  -50
                })
            }
        }
    }
}
</script>
<template>
  <div class="c-my-activities">
    <h4 class="c-my-activities__title">Mijn activiteiten</h4>
    <div class="c-my-activities__content">
      <transition name="fade">
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>

      <router-link
        v-slot="{ navigate }" 
        custom
        class="c-my-activities__item"
        :to="{name: 'my-development', query: { 'my-development-tab': myDevelopmentTabs.ENROLLMENTS.key }}"
      >
        <div @click="navigate">
          <h1 class="c-my-activities__number">{{ (activityCount && activityCount.enrollments) || 0 }}</h1>
          <h5 class="c-my-activities__label">Inschrijvingen</h5>
        </div>
      </router-link>

      <router-link
        v-slot="{ navigate }" 
        custom
        class="c-my-activities__item"
        :to="{name: 'my-development', query: { 'my-development-tab': myDevelopmentTabs.ACTIVECOURSES.key }}"
      >
        <div @click="navigate">
          <h1
            class="c-my-activities__number"
          >
            {{ (activityCount && activityCount.activeCourses) || 0 }}
          </h1>
          <h5 class="c-my-activities__label">Gestarte ontwikkelactiviteiten</h5>
        </div>
      </router-link>
      <router-link
        v-if="$isAllowed('displayStudyContract')"
        v-slot="{ navigate }" 
        custom
        class="c-my-activities__item"
        :to="{name: 'my-development', query: { 'my-development-tab': myDevelopmentTabs.STUDYCONTRACTS.key }}"
      >
        <div @click="navigate">
          <h1
            class="c-my-activities__number"
          >
            {{ (activityCount && activityCount.studyContracts) || 0 }}
          </h1>
          <h5 class="c-my-activities__label">Overeenkomsten</h5>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { myDevelopmentTabs } from '../../../constants/tabNames'
import userClient from '../../../api/userClient'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'

export default {
    components: {
        LoaderComponent
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            isLoading: false,
            activityCount: null,
            myDevelopmentTabs
        }
    },
    created() {
        this.fetchActivityCount()
    },
    methods: {
        fetchActivityCount() {
            this.isLoading = true

            userClient
                .getActivityCount()
                .then(response => {
                    if (!response) return
                    this.activityCount = response
                })
                .catch(err => {
                    console.error(err)
                })
                .then(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>
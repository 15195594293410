const mixin = {
    data() {
        return {
            prevRoute: null
        }
    },
    beforeRouteEnter(_, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    }
}

export default mixin
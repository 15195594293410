import get from 'lodash/get'
import { getOplzClient } from './apiClient'

const baseUrl = '/wishlist'

const toggle = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/toggle`, data).then(response => {
        return get(response, 'data', null)
    })
}

const getWishlist = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/userwishlist`).then(response => {
        return get(response, 'data', null)
    })
}

export default {
    toggle,
    getWishlist
}

const mixin = {
    methods: {
        getVisibleClasses(smallVisible, mediumVisible, largeVisible) {
            if (!smallVisible && !mediumVisible && !largeVisible)
                // Hidden on all
                return 'd-none'
            if (smallVisible && !mediumVisible && !largeVisible)
                // Visible only on mobile
                return 'd-md-none'
            if (!smallVisible && mediumVisible && !largeVisible)
                // Visible only on tablet
                return 'd-none d-md-block d-lg-none'
            if (!smallVisible && !mediumVisible && largeVisible)
                // Visible only on desktop
                return 'd-none d-lg-block'
            if (smallVisible && mediumVisible && !largeVisible)
                // Visible on mobile and tablet
                return 'd-lg-none'
            if (smallVisible && !mediumVisible && largeVisible)
                // Visible on mobile and desktop
                return 'd-md-none d-lg-block'
            if (!smallVisible && mediumVisible && largeVisible)
                // Visible on tablet and desktop
                return 'd-none d-md-block'
            return 'd-block'
        }
    }
}

export default mixin

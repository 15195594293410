<template>
  <lightbox-component
    :is-visible="isVisible"
    :show-close-button="true"
    data-test="close-button"
    @close="$emit('close')"
  >
    <h3 data-test="title">{{ title }}</h3>        
    <p data-test="description">{{ description }}</p>
    <div v-if="showOptions" class="row mb-4">
      <div class="col-12">
        <recess-select
          :value="selectedOption"
          :default-value="selectedOption"
          data-test="option-input"
          :label-text="optionLabel"
          :prefill-with-first-option=false
          :options="options"
          :error-message="handleErrorMessage('option')"
          @input="val => $emit('input:option', val)" 
        />
      </div>
    </div>

    <div class="row" >
      <div class="col-12">
        <recess-textarea-input
          :value="message"
          :label-text="messageLabel"
          data-test="message-input"
          :error-message="handleErrorMessage('message')"
          @input="val => $emit('input:message', val)" 
        />
      </div>
    </div>
    <recess-divider />

    <div class="d-flex justify-content-end">
      <recess-button
        variant="primary"
        title="Bevestigen"
        data-test="submit-btn"
        @click="$emit('confirm')"
      />
    </div>
   
    <template v-if="showError">
      <recess-divider />
      <recess-alert
        type="error"
        :text="errorText"
      />
    </template>
  </lightbox-component>
</template>

<script>
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'

export default {
    components: {
        LightboxComponent
    },
    props: {
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        messageLabel: {
            type: String,
            default: null
        },
        optionLabel: {
            type: String,
            default: null
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        showOptions: {
            type: Boolean, 
            default: false
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        },
        message: {
            type: String,
            default: null
        },
        selectedOption: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        showError: {
            type: Boolean,
            default: false
        },
        errorText: {
            type: String,
            default: 'Er is een fout opgetreden. Probeer het later opnieuw.'
        }
    },
    emits: ['close', 'confirm', 'input:message', 'input:option']
}
</script>
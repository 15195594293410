<template>
  <div v-if="$isAllowed('routeAccountSelectionPage')">
    <background-component :x-small-image="background" />
    <account-selection-component />
  </div>
</template>

<script>
import BackgroundComponent from '../../atoms/BackgroundComponent/BackgroundComponent.vue'
import AccountSelectionComponent from '../../molecules/AccountSelectionComponent/AccountSelectionComponent.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import background from '../../../assets/images/login-background.jpg'

export default {
    perimeters: [routePerimeter],
    components: {
        BackgroundComponent,
        AccountSelectionComponent
    },
    data() {
        return {
            background
        }
    }
}
</script>
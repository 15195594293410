import { mapGetters } from 'vuex'
import * as enrollmentTypes from '../constants/enrollmentTypes'

const mixin = {
    computed: {
        ...mapGetters('userModule', ['userId'])
    },
    methods: {
        copyEnrollment(enrollment) {
            this.$router.push({
                name: 'enrollment',
                params: {
                    type: this.getEnrollmentType(enrollment.roleId)
                },
                query: {
                    course: enrollment.eduMSCourseId,
                    assortment: enrollment.assortmentId,
                    enrollment: enrollment.enrollmentId
                }
            })
        },
        getEnrollmentType(roleId) {
            switch (roleId) {
                case 1:
                    return enrollmentTypes.TEAMLEADER
                case 7:
                    return enrollmentTypes.COORDINATOR
                default:
                    return enrollmentTypes.STUDENT
            }
        },
        showDuplicateIcon(createdById) {
            return createdById === this.userId
        }
    }
}

export default mixin

<template>
  <div class="c-pb-refresh">
    <p class="c-pb-refresh-text">{{ message }}</p>
    <recess-button
      class="c-pb-refresh-button"
      title="Opnieuw Proberen"
      variant="primary"
      data-test="refresh-button"
      @click.native="refresh()"
    />
    <NotFoundSVG class="c-pb-refresh-img" />
  </div>
</template>

<script>
import NotFoundSVG from '../../atoms/NotFoundSVG/NotFoundSVG.vue'

export default {
    components: {
        NotFoundSVG
    },
    data() {
        return {
            message: 'De service verwerkt de nieuwe gegevens, probeer het later opnieuw.'
        }
    },
    methods: {
        refresh() {
            this.$emit('refresh')
        }
    }
}
</script>
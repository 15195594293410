<template>
  <div class="u-position-relative">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="items && items.length > 0"
        class="d-flex flex-wrap"
        data-test="search-result-items"
      >
        <template  v-for="(course, index) in items">
          <slot
            name="search-items-content"
            :item="course"
          >
            <course-search-item
              :key="`${course.id}-${index}`"
              :item="course"
              :is-card-clickable="isCardClickable"
              data-test="search-result-item"
            />
          </slot>
        </template>
      </div>
    </transition>

    <!-- Go to compare courses block -->
    <transition
      name="fade"
      mode="out-in"
    >
      <recess-card
        v-if="coursesToCompareCount > 1 && !isEnrollmentFlow"
        data-test="compare-courses-block"
        color-variant="secondary"
        class="c-search-results__compare container d-flex justify-content-between align-items-center"
      >
        <h4
          data-test="compare-courses-title"
          class="mb-0 u-textcolor-white"
        >
          {{ coursesToCompareCount }} ontwikkelactiviteiten vergelijken
        </h4>

        <div>
          <recess-button
            class="mr-3"
            variant="primary"
            title="Annuleren"
            data-test="cancel-compare-courses-button"
            @click="closeCompare()"
          />

          <recess-button
            class="mr-3"
            variant="primary"
            title="Vergelijk nu"
            data-test="compare-courses-button"
            :url="{ name: 'compare-courses', query: {...queryParams} }"
          />
        </div>
      </recess-card>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CourseSearchItem from './CourseSearchItem.vue'

export default {
    components: {
        CourseSearchItem
    },
    props: {
        isCardClickable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters('compareCoursesModule', ['coursesToCompareCount', 'courseIdsToCompare']),
        ...mapState('searchModule', ['items', 'isColumnView']),
        ...mapGetters('searchModule', ['searchFromTeamAssortments']),
        ...mapGetters('enrollmentModule', ['enrollmentType']),
        isEnrollmentFlow() {
            return !!this.enrollmentType
        },
        queryParams() {
            const queries = {}
            if (this.courseIdsToCompare) {
                this.courseIdsToCompare.forEach((course, index) => {
                    queries[`course${index + 1}Id`] = course.id
                    queries[`course${index + 1}AssortmentId`] = course.assortmentId 
                })
                if(this.searchFromTeamAssortments) queries.searchFromTeamAssortments = true 
            }

            return queries
        }
    },
    methods: {
        ...mapMutations('compareCoursesModule', ['resetState']),
        closeCompare() {
            this.resetState(this)
        }
    }
}
</script>


<template>
  <div>
    <lightbox-component
      is-visible
      :show-close-button="false"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>

      <h3>Gebruikersvoorwaarden</h3>
      <p>Alvorens u gebruik maakt van het Opleiding.nl Zakelijk platform dient u de gebruikersvoorwaarden door te lezen.</p>
     
      <div class="u-position-relative">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isLoadingLegalAgreements"
            overlay="white"
            color="primary"
            variant="small"
          />
        </transition>
        <transition
          name="fade"
          mode="out-in"
        >
          <div v-if="legalAgreements.length">
            <div
              v-for="agreement in legalAgreements"
              :key="agreement.id"
            >
              <a
                :href="agreement.url"
                target="_blank"
                class="underline"
              >
                <u>{{ agreement.name }}</u>
              </a>
            </div>
          </div>
        </transition>
      </div>
      <recess-divider />
      <form @submit.prevent="postLegalAgreementConsent">
        <recess-checkbox-input
          v-model="legalAgreementCheckbox"
          :default-checked="legalAgreementCheckbox"
          :class="{'text-danger' : showError}"
          data-test="legal-agreement"
          label-text="Ik heb de gebruikersvoorwaarden gelezen *"
        />
        <recess-checkbox-input
          v-model="dataProcessingCheckbox"
          :default-checked="dataProcessingCheckbox"
          label-text="Ik ontvang graag suggesties voor ontwikkelactiviteiten op basis van mijn gegevens"
        />
        <recess-divider />

        <div class="d-flex justify-content-end">
          <recess-button
            :disabled="!legalAgreementCheckbox"
            variant="primary"
            title="Doorgaan"
            type="submit"
            data-test="submit-legal-agreement"
          />
        </div>
      </form>

      <span
        v-if="errors"
        class="o-error-text mt-3"
      >{{ errors }}</span>
    </lightbox-component>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'
import legalAgreementsClient from '../../../api/legalAgreementsClient'
import userClient from '../../../api/userClient'

export default {
    components: {
        LoaderComponent,
        LightboxComponent
    },
    data() {
        return {
            isLoading: false,
            isLoadingLegalAgreements: false,
            showError: false,
            errors: null,
            legalAgreementCheckbox: false,
            dataProcessingCheckbox: false,
            legalAgreements: []
        }
    },
    computed: {
        ...mapGetters('userModule', ['getAllowDataProcessingDate']),
        ...mapState('userModule', ['user'])
    },
    watch: {
        legalAgreementCheckbox() {
            this.showError = !this.legalAgreementCheckbox
        }
    },
    mounted() {
        this.fetchLegalAgreements()
    },
    methods: {
        fetchLegalAgreements() {
            this.isLoadingLegalAgreements = true

            // fetch the legal agreements and the DPA value
            legalAgreementsClient
                .getLegalAgreements(true)
                .then(response => {
                    this.legalAgreements = response.filter(x => x.isEnabled)
                    this.dataProcessingCheckbox = !!this.getAllowDataProcessingDate
                })
                .then(() => {
                    this.isLoadingLegalAgreements = false
                })
        },
        async postLegalAgreementConsent() {
            try {
              
                // show error if the user didn't accept the legal agreement
                if (!this.legalAgreementCheckbox) {
                    this.showError = !this.legalAgreementCheckbox
                    return
                }
  
                this.isLoading = true
  
                const response = await userClient.postLegalAgreementConsent(this.user.userId, this.dataProcessingCheckbox)
                if (response?.status === 200) {
                    const redirectPath = sessionStorage.getItem('oidc_active_route')
                    this.$router.replace(redirectPath || { name: 'home'})
                }
            } catch (error) {
                const response = error?.response?.data ?? null
                if (response && response.message) {
                    this.errors = response.message
                } else {
                    this.errors = 'Er is een fout opgetreden. Probeer het later opnieuw.'
                }
            } finally {
                this.isLoading = false
            }

        }
    }
}
</script>

export const CITY = 'city'
export const YEARANDMONTH = 'yearAndMonth'

export const LEVELS = 'level_nl'
export const VARIANTS = 'variants_nl'
export const SEGMENTS = 'segmentsDisplayValue'
export const DURATIONS = 'courseDurationInDays'
export const PEERFILTERS = 'peerFilters'
export const LOCATIONS = `startMomentsArray/${CITY}`
export const STARTMOMENTS = `startMomentsArray/${YEARANDMONTH}`
export const STARTMOMENTYEARANDMONTHANDCITY = 'startMomentsArray/yearAndMonthAndCity'
export const PRICE = 'price'
export const ASSORTMENTNAME = 'assortmentName'

export default {
    LEVELS,
    VARIANTS,
    SEGMENTS,
    LOCATIONS,
    DURATIONS,
    PEERFILTERS,
    STARTMOMENTS,
    STARTMOMENTYEARANDMONTHANDCITY,
    PRICE,
    ASSORTMENTNAME
}

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { TEAMLEADER } from '../constants/enrollmentTypes'

const mixin = {
    computed: {
        ...mapGetters('startMomentsModule', ['startMoments', 'facets', 'top', 'totalCount', 'pageNumber', 'showNoStartMomentsMessage']),
        ...mapGetters({
            isLoadingStartMomentsAndOrders: 'startMomentsModule/isLoading'
        }),
        ...mapGetters('enrollmentModule', ['showStartmoments' , 'showCapacity', 'limitIncompanyEnrollmentsAndIsIncompany', 'courseAssortment', 'courseId', 'enrollmentType' ]),
        shouldFetchOrdersPerStartMoment() {
            return this.showCapacity || this.limitIncompanyEnrollmentsAndIsIncompany
        },
        isTeamLeaderEnrollment() {
            return this.enrollmentType === TEAMLEADER
        }
    },
    methods: {
        ...mapActions('startMomentsModule', [
            'goToPage',
            'setFacetsAndSearchStartMoments',
            'initializeStartMomentsAndOrders'
        ]),
        ...mapMutations('startMomentsModule', [
            'SET_IS_SEARCHING_START_MOMENTS'
        ]),
        async initializeStartMoments() {
            await this.initializeStartMomentsAndOrders({ courseId: this.courseId, assortmentId: this.courseAssortment, shouldFetchOrders: this.shouldFetchOrdersPerStartMoment })
        },
        async onPaginationClicked(clickedPage) {
            await this.goToPage({ pageNumber: clickedPage, shouldFetchOrders: this.shouldFetchOrdersPerStartMoment })
        },
        async setFacets(selectedFacets) {
            await this.setFacetsAndSearchStartMoments({ selectedFacets, shouldFetchOrders: this.shouldFetchOrdersPerStartMoment })
        }
    }
}

export default mixin
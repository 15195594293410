import { watchEffect, ref } from "vue"

export default function useMediaQueries(viewPort) {
    const matches = ref(true)

    watchEffect(onInvalidate => {
        const media = window.matchMedia(viewPort)
  
        if(media.matches !== matches.value) {
            matches.value = media.matches
        }
  
        const onChange = () => {
            matches.value = media.matches
        }
  
        media.addEventListener("change", onChange)
  
        onInvalidate(() => {
            media.removeEventListener("change", onChange)
        })
    })
  
    return matches
}
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />
    <p
      data-test="explanation-text"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      {{ explanationText }}
    </p>

    <transition name="fade">
      <div
        v-if="wishlistItems && wishlistItems.length"
      >
        <recess-table class="pl-0 col-xl-8">
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="wishlistItem in wishlistItems"
              :key="wishlistItem.wishListItemId"
            >
              <recess-table-cell :data-label="headers[0].title">
                <router-link
                  :to="{ name: 'course', query: { course: wishlistItem.courseId }}"
                  data-test="wish-list-course-name"
                >
                  <span v-html="wishlistItem.courseName"></span>
                </router-link>
              </recess-table-cell>

              <recess-table-cell class="d-flex align-items-center justify-content-end">
                <recess-button
                  title="Toevoegen aan leerpad"
                  variant="primary"
                  class="mr-3"
                  @click="openAssignLearningPathModal(wishlistItem.courseId)"
                />
                <recess-icon
                  icon="bin"
                  @click="toggleRemoveWishlistItemLightbox(wishlistItem.courseId)"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
      </div>
    </transition>

    <remove-wishlist-item-popup-component
      :is-visible="showRemoveWishlistItemLightbox"
      :course-id="selectedCourseId"
      @close="showRemoveWishlistItemLightbox=false"
      @wishlistItemRemoved="fetchWishlist()"
    />

    <recess-alert
      v-if="showNoWishlistMessage"
      text="Er zijn geen resultaten."
    />

    <assign-learning-path-course-component
      :show-assign-learning-path="showAssignLearningPath"
      :course-id="selectedCourseId"
      @close="closeAssignLearningPathModal()"
    />
    <create-learning-path-component />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import RemoveWishlistItemPopupComponent from '../../molecules/RemoveWishlistItemPopupComponent/RemoveWishlistItemPopupComponent.vue'
import AssignLearningPathCourseComponent from "../LearningPath/AssignLearningPathCourseComponent.vue"
import CreateLearningPathComponent from "../LearningPath/CreateLearningPathComponent.vue"
import wishlistClient from '../../../api/wishlistClient'

export default {
    components: {
        LoaderComponent,
        RemoveWishlistItemPopupComponent,
        AssignLearningPathCourseComponent,
        CreateLearningPathComponent
    },
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            headers: [{ key: 'CourseName', title: 'Ontwikkelactiviteit' }, { key: '', title: '' }],
            wishlistItems: null,
            showNoWishlistMessage: false,
            isLoading: false,
            showRemoveWishlistItemLightbox: false,
            selectedCourseId: null,
            showAssignLearningPath: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        userId() {
            return this.user.userId
        }
    },
    watch: {
        userId() {
            // sometimes the user is not set on time in the store yet, so then the userId is null,
            // which gives us no results when fetching the enrollments. So we need to make sure the
            // addQueryFilters method is called again if the user id is available
            if (this.user.userId) {
                this.fetchWishlist()
            }
        }
    },
    mounted() {
        this.fetchWishlist()
    },
    methods: {
        fetchWishlist() {
            if (!this.userId) return

            this.isLoading = true
            wishlistClient
                .getWishlist()
                .then(response => {
                    if (!response) return

                    this.showNoWishlistMessage = false
                    this.wishlistItems = response

                    if(!this.wishlistItems?.length) {
                        this.showNoWishlistMessage = true
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .then(() => {
                    this.isLoading = false
                })
        },
        openAssignLearningPathModal(selectedCourseId) {
            this.selectedCourseId = selectedCourseId
            this.showAssignLearningPath = true
        },
        closeAssignLearningPathModal() {
            this.selectedCourseId = null
            this.showAssignLearningPath = false
        },
        toggleRemoveWishlistItemLightbox(selectedCourseId) {
            this.selectedCourseId = selectedCourseId
            this.showRemoveWishlistItemLightbox = true
        }
    }
}
</script>
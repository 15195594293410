<template>
  <recess-card
    :class="['c-search-result__item', {'is-block' : isColumnView}]"
    :to="isCardClickable ? { name: 'course', query: { course: item.id, assortment: item.assortmentId }} : null"
    bordered
    has-hover
    data-test="search-result-item"
  >
    <article
      v-if="item"
      :class="['c-search-result__item-wrap d-flex', {'has-background-image': isColumnView && segmentImageUrl && $isAllowed('displaySegmentImages')}]"
      data-test="search-result-item-wrap">

      <course-search-item-header 
        :course="item"
        :segment-image-url="segmentImageUrl"
      />
      <course-search-item-body 
        :course="item"
      />      
      <course-search-item-footer 
        :course="item"
        @toggle-course-recommendation="showRecommendCourseModal = true"
        @toggle-assign-lp="showAssignLearningPath = true"
      >
        <template slot="search-item-action-buttons">
          <slot name="search-item-action-buttons"></slot>
        </template>
      </course-search-item-footer>
      <div @click.prevent.stop>
        <assign-learning-path-course-component
          class="u-cursor-default"
          :show-assign-learning-path="showAssignLearningPath"
          :course-id="item.id"
          @close="showAssignLearningPath = false"
        />
        <recommend-course-component
          v-if="$isAllowed('displayShareCourse')"
          class="u-cursor-default"
          :course-name="item.title"
          :course-id="item.id"
          :assortment-id="item.assortmentId"
          :show-recommend-course-modal="showRecommendCourseModal"
          @close="showRecommendCourseModal = false"
        />
      </div>
    </article>
  </recess-card>
</template>

<script>
import { ref } from 'vue'
import useCourseSearchItem from '../../../../composables/useCourseSearchItem'
import AssignLearningPathCourseComponent from '../../LearningPath/AssignLearningPathCourseComponent.vue'
import RecommendCourseComponent from '../../../molecules/RecommendCouseComponent/RecommendCouseComponent.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import CourseSearchItemHeader from './CourseSearchItemHeader.vue'
import CourseSearchItemBody from './CourseSearchItemBody.vue'
import CourseSearchItemFooter from './CourseSearchItemFooter.vue'

export default {
    components: {
        CourseSearchItemHeader,
        CourseSearchItemBody,
        CourseSearchItemFooter,
        AssignLearningPathCourseComponent,
        RecommendCourseComponent
    },
    perimeters: [featuresPerimeter],
    props: {
        item: {
            type: Object,
            required: true
        },
        isCardClickable: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const { isColumnView, segmentImageUrl } = useCourseSearchItem(props.item)
        const showAssignLearningPath = ref(false)
        const showRecommendCourseModal = ref(false)

        return {
            isColumnView,
            segmentImageUrl,
            showAssignLearningPath,
            showRecommendCourseModal
        }
    }
}
</script>

<template>
  <recess-layout-static
    :size="1700"
  >
    <loader-component
      v-if="isFetchingCustomTexts"
      overlay="white"
      color="primary"
    />
    <recess-divider />
    <h1>Mijn ontwikkeling</h1>
    <recess-divider />

    <template v-if="!isFetchingCustomTexts">
      <template v-if="isMobile">
        <template v-for="(tab, index) in Object.keys(myDevelopmentTabs)">
          <recess-accordion
            v-if="isTabVisible(myDevelopmentTabs[tab].key)"
            :key="index"
            :active="currentComponent && currentComponent.key === myDevelopmentTabs[tab].key"
            variant="variant-3"
            @toggled="isActive => toggledTab(isActive, myDevelopmentTabs[tab].key)"
          >
            <template #accordion-title>
              {{ myDevelopmentTabs[tab].displayValue }}
            </template> 
            <template #accordion-content>
              <component
                :is="currentComponent.componentName"
                v-if="currentComponent && currentComponent.key === myDevelopmentTabs[tab].key"
                :explanation-text="currentComponent.explanationText"
              />
            </template>
          </recess-accordion>
        </template>
      </template>

      <template v-else>
        <recess-tab-navigation name="my-development">
          <template v-for="(tab, index) in Object.keys(myDevelopmentTabs)">
            <recess-tab-navigation-item
              v-if="isTabVisible(myDevelopmentTabs[tab].key)"
              :key="index"
              :tab-key="myDevelopmentTabs[tab].key"
              :data-test="`my-development-${myDevelopmentTabs[tab].key}`"
            >
              {{ myDevelopmentTabs[tab].displayValue }}
            </recess-tab-navigation-item>
          </template>
        </recess-tab-navigation>
        <keep-alive>
          <component
            :is="currentComponent.componentName"
            v-if="currentComponent && isTabVisible(currentComponent.key)"
            :explanation-text="currentComponent.explanationText"
          />
        </keep-alive>
      </template>
    </template>
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import { mapGetters } from 'vuex'
import { jsMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'
import ActiveCoursesTable from '../../organisms/Tables/ActiveCoursesTable.vue'
import MyEnrollmentsTable from '../../organisms/Tables/MyEnrollmentsTable.vue'
import MyStudyContractsTable from '../../organisms/Tables/MyStudyContractsTable.vue'
import MyWishlistTable from '../../organisms/Tables/MyWishlistTable.vue'
import MyPERemindersTable from '../../organisms/Tables/MyPERemindersTable.vue'
import MyLearningPathListing from '../../organisms/MyLearningPathListing/MyLearningPathListing.vue'
import { myDevelopmentTabs } from '../../../constants/tabNames'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import customTextClient from '../../../api/customTextClient'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import MyRecentlyVisitedCouresTable from '../../organisms/Tables/MyRecentlyVisitedCouresTable.vue'

export default {
    name: "MyDevelopment",
    components: {
        LoaderComponent
    },
    mixins: [jsMediaQueriesMixin],
    perimeters: [featuresPerimeter],
    data() {
        return {
            myDevelopmentTabs,
            customTexts: [],
            isFetchingCustomTexts: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['accountId']),
        currentComponent() {
            return this.getComponentToRender(this.$route?.query['my-development-tab'])
        }
    },
    async created() {
        if (
            !this.$route?.query['my-development-tab'] ||
            !Object.values(this.myDevelopmentTabs).some(tab => tab.key === this.$route?.query['my-development-tab'])
        ) {
            this.$router.replace({
                path: this.$route.path,
                query: { ...this.$route.query, 'my-development-tab': this.myDevelopmentTabs.ACTIVECOURSES.key }
            })
        }
        await this.fetchCustomTexts()
    },
    methods:  {
        getComponentToRender(tab) {
            switch(tab) {
                case myDevelopmentTabs.ACTIVECOURSES.key:
                    return {
                        key: myDevelopmentTabs.ACTIVECOURSES.key,
                        componentName: ActiveCoursesTable,
                        explanationText: this.getCustomText(myDevelopmentTabs.ACTIVECOURSES)
                    }
                case myDevelopmentTabs.ENROLLMENTS.key:
                    return {
                        key: myDevelopmentTabs.ENROLLMENTS.key,
                        componentName: MyEnrollmentsTable,
                        explanationText: this.getCustomText(myDevelopmentTabs.ENROLLMENTS)
                    }
                case myDevelopmentTabs.STUDYCONTRACTS.key:
                    return {
                        key: myDevelopmentTabs.STUDYCONTRACTS.key,
                        componentName: MyStudyContractsTable,
                        explanationText: this.getCustomText(myDevelopmentTabs.STUDYCONTRACTS),
                        isTabVisible: this.$isAllowed('displayStudyContract')
                    }
                case myDevelopmentTabs.PERMANENTEDUCATIONS.key:
                    return {
                        key: myDevelopmentTabs.PERMANENTEDUCATIONS.key,
                        componentName: MyPERemindersTable,
                        explanationText: this.getCustomText(myDevelopmentTabs.PERMANENTEDUCATIONS),
                        isTabVisible: this.$isAllowed('displayPERemindersFeature')
                    }
                case myDevelopmentTabs.WISHLIST.key:
                    return {
                        key: myDevelopmentTabs.WISHLIST.key,
                        componentName: MyWishlistTable,
                        explanationText: this.getCustomText(myDevelopmentTabs.WISHLIST)
                    }
                case myDevelopmentTabs.LEARNINGPATHS.key:
                    return {
                        key: myDevelopmentTabs.LEARNINGPATHS.key,
                        componentName: MyLearningPathListing,
                        explanationText: this.getCustomText(myDevelopmentTabs.LEARNINGPATHS),
                        isTabVisible: this.$isAllowed('displayLearningPath')
                    }
                case myDevelopmentTabs.RECENTLYVISITEDCOURSES.key:
                    return {
                        key: myDevelopmentTabs.RECENTLYVISITEDCOURSES.key,
                        componentName: MyRecentlyVisitedCouresTable,
                        explanationText: this.getCustomText(myDevelopmentTabs.RECENTLYVISITEDCOURSES),
                        isTabVisible: this.$isAllowed('displayRecentlyVisitedCourses')
                    }
                default:
                    return null
            }
        },
        isTabVisible(tab) {
            const componentToRender = this.getComponentToRender(tab)
            return componentToRender && "isTabVisible" in componentToRender ? componentToRender.isTabVisible : true
        },
        async fetchCustomTexts() {
            try {
                this.isFetchingCustomTexts = true
                const dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .addFilter(new ODataQueryFilter("accountId", ODataFilterOperations.EQUALS, this.accountId, null, null))
                    .build() 

                const customTexts = await customTextClient.getCustomTexts(dataQuery)
                this.customTexts  = customTexts.items
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingCustomTexts = false
            }
        },
        getCustomText(tab) {
            const customText = this.customTexts?.find(x => x.textType === tab.textType)

            if(customText) return customText.text

            return tab.defaultExplanationText
        },
        toggledTab(isActive, tab) {
            if(isActive) {
                this.$router.replace({
                    query: { ...this.$route.query, 'my-development-tab': tab }
                })
            } else {
                this.$router.replace({
                    query: { ...this.$route.query, 'my-development-tab': undefined }
                })
            }
        }
    }
}
</script>
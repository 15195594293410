export default {
    DASHBOARD: 'Dashboard',
    MY_TEAM: 'Mijn team',
    MY_DEVELOPMENT: 'Mijn ontwikkeling',
    SOCIAL_LEARNING: 'Kennisplein',
    DELEGATION_ACTIONS: 'Toegewezen acties',
    REPORTS: 'Rapporten',
    PROJECT_REQUESTS: 'Projecten',
    FAQ: 'Vragen',
    CONTACT: 'Contact',
    GENERAL_CONTACT: 'Algemene vraag',
    ABC_REQUEST: 'Aanvraag buiten catalogus'
}
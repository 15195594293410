import { getOplzClient } from './apiClient'

const baseUrl = '/delegations'

const postDelegation = async delegation => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}`, delegation).then(response => {
        return response.data
    })
}

const getDelegationActionsByDelegationId = async delegationId => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${delegationId}/delegation-actions`).then(response => {
        return response.data
    })
}

const getDelegationActionsForCurrentUser = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/delegation-actions`).then(response => {
        return response.data
    })
}

const disableDelegationByDelegationId = async delegationId => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${delegationId}/disable`).then(response => response)
}

export default {
    postDelegation,
    getDelegationActionsByDelegationId,
    getDelegationActionsForCurrentUser,
    disableDelegationByDelegationId
}

<template>
  <footer class="c-search-result__item-footer ">
    <div
      v-if="$isAllowed('displayPricingInformation')"
      class="c-search-result__price-wrap d-flex flex-wrap align-items-end"
    >
      <h4
        v-if="!showNoaPriceValue"
        data-test="original-price"
        class="c-search-result__price--original"
      >
        <del>{{ formatPrice(course.price) }}</del>
      </h4>
      <h4 class="c-search-result__price" data-test="search-result-price">{{ formatPrice(showNoaPriceValue ? course.price : 0) }}</h4>
      <span
        v-if="showNoaPriceValue"
        class="c-search-result__price-vat ml-2"
        data-test="search-result-price-vat"
      >
        <em>{{ vatText }}</em>
      </span>
    </div>
    <div
      class="c-search-result__buttons d-flex mt-3 w-100"
      @click.prevent.stop
    >
      <slot name="search-item-action-buttons">
        <recess-button
          title="Bekijk details"
          variant="tertiary"
          class="c-search-result__button"
          :url="{ name: 'course', query: {course: course.id, assortment: course.assortmentId }}"
        />
        <enrollment-button-component
          v-if="!searchFromTeamAssortments"
          :course="enrollmentButtonData"
          :is-e-learning="isELearning"
          class="c-search-result__button"
        />
      </slot>
    </div>

    <course-search-item-actions 
      v-if="!isSmallView" 
      :course="course"
      class="pt-3"
      v-on="{ ...$listeners }"
    />
  </footer>
</template>

<script>
import { computed } from 'vue'
import useCourseSearchItem from '../../../../composables/useCourseSearchItem'
import useStore from '../../../../composables/useStore'
import * as courseLearningMethodTypes from '../../../../constants/courseLearningMethodTypes'
import coursePriceHelper from '../../../../modules/coursePriceHelper'
import priceFormatter from '../../../../modules/priceFormatter'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import EnrollmentButtonComponent from '../../EnrollmentButtonComponent/EnrollmentButtonComponent.vue'
import CourseSearchItemActions from './CourseSearchItemActions.vue'

export default {
    perimeters: [featuresPerimeter],
    components: {
        EnrollmentButtonComponent,
        CourseSearchItemActions
    },
    props: {
        course: {
            type: Object,
            default: () => {}
        },
        isSmallView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore()
        const {
            searchFromTeamAssortments
        } = useCourseSearchItem(props.course)

        const vatText = computed(() => coursePriceHelper.getVatText(props.course.vatIncluded))
        const isELearning = computed(() =>   props.course.learningMethodType?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED)
        const enrollmentButtonData = computed(() => {
            return {
                id: props.course.id,
                startMoments: props.course.startMoments,
                externalId: props.course.externalId,
                providerName: props.course.providerName,
                providerId: props.course.providerId,
                courseName: props.course.title,
                oleUrl: props.course.oleUrl,
                showZeroPrice: props.course.showZeroPrice,
                assortmentId: props.course.assortmentId,
                isInUserAssortments: props.course.isInUserAssortments
            }
        })

        const showNoaPriceValue = () => store.getters('oleProvidersModule/showProviderPrice', { showZeroPrice: props.course.showZeroPrice, providerId: props.course.providerId })
        return {
            vatText,
            searchFromTeamAssortments,
            enrollmentButtonData,
            isELearning,
            showNoaPriceValue,
            formatPrice: priceFormatter.formatPrice
        }
    }
}
</script>
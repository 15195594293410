<template>
  <div
    :class="['c-loader', overlayColorClass, { 'loader-small': variant == 'small'}, { 'loader-xtra-small': variant == 'xtra-small'}, {'is-fixed': isFixed}]"
    data-test="loader"
  >
    <div :class="['c-loader__item', customClass]">
      <svg
        id="L9"
        :class="loaderColorClass"
        class="c-loader__svg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          transform="rotate(150 50 50)"
          class="c-loader__path"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
    name: 'LoaderComponent',
    props: {
        color: {
            type: String,
            default: 'primary',
            validator(value) {
                return ['primary', 'white', 'custom'].includes(value)
            }
        },
        overlay: {
            type: String,
            default: null,
            validator(value) {
                return ['white', 'black', 'custom'].includes(value)
            }
        },
        isFixed: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: null
        },
        customClass: {
            type: String,
            default: null
        }
    },
    computed: {
        overlayColorClass() {
            return this.overlay ? `with-overlay-${this.overlay}` : null
        },
        loaderColorClass() {
            return `with-loader-${this.color}`
        }
    }
}
</script>
<template>
  <div></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    computed: {
        ...mapGetters('userModule', ['sharedIds', 'isUserEnabled', 'tokenAccountCode']),
        ...mapState('userModule', ['isUserFetched'])
    },
    watch: {
        isUserFetched: {
            handler(newValue) {
                if (newValue ) {
                    if(!this.isUserEnabled) {
                        this.$router.replace({ name: 'disabled-user'})
                        return
                    }

                    const accountCode = sessionStorage.getItem('account_selected') 
                    if(this.sharedIds?.length > 1 && (accountCode !== this.tokenAccountCode || !this.isUserEnabled)) {
                        this.$router.replace({ name : "select-account" })
                    }
                }
            },
            immediate: true
        }
    }
}
</script>
import { ref, readonly, reactive, watch } from 'vue'
import useValidation from './useValidation'
import projectRequestStatusClient from '../api/projectRequestStatusClient'
import projectRequestClient from '../api/projectRequestClient'

export default function useProjectRequestCancellations() {
    const projectCancellationModalTitle = 'Projectaanvraag annuleren'
    const projectCancellationModalDescription = 'Geef een reden op voor de annulering van de projectaanvraag, zodat de aanvrager op de hoogte is en indien nodig actie kan ondernemen.'
    const projectCancellationModalMessageLabel = 'Aanvullende informatie/opmerkingen'  
    const projectCancellationModalOptionLabel = 'Reden voor annulering *'
    const errorMessages = {
        message: {
            maxLength: 'Uw opmerking mag maximaal 300 tekens bevatten'
        },
        option: {
            required: 'Reden voor annulering is verplicht'
        }
    }

    const { handleErrorMessage, validateForm } = useValidation("cancellationFormData", errorMessages)

    const cancellationFormData = reactive({
        message: null,
        option: null
    })

    const isFetchingCancellationReasons = ref(false)
    const isError = ref(false)
    const setIsError = newValue => { isError.value = newValue }
    const setIsFetchingCancellationReasons = newValue => { isFetchingCancellationReasons.value = newValue }
    const isSubmittingCancellation = ref(false)
    const setIsSubmittingCancellation = newValue => { isSubmittingCancellation.value = newValue }
    const showCancellationModal = ref(false)
    const setShowCancellationModal = newValue => { showCancellationModal.value = newValue }
    const cancellationReasons = ref([])
    const setCancellationReasons = newValue => { cancellationReasons.value = newValue }
    
    const submitCancellation = async projectRequestId => {
        try {
            if(!validateForm() || !projectRequestId) {
                return false
            }
            setIsSubmittingCancellation(true)

            const payload = {
                reason: cancellationFormData.option,
                remark: cancellationFormData.message
            } 
            await projectRequestClient.cancel(projectRequestId, payload)
            setShowCancellationModal(false)
            setIsError(false)
            return true
        } catch (error) {
            setIsError(true)
            throw new Error("Failed to cancel project request", { cause: error }) 
        } finally {
            setIsSubmittingCancellation(false)
        }
    }

    const fetchCancellationReasons = async () => {
        if (cancellationReasons.value?.length) return 

        try {
            setIsFetchingCancellationReasons(true)

            const reasons = await projectRequestStatusClient.getCancellationReasons()
            
            setCancellationReasons(reasons)
            setIsError(false)
        } catch(error) {
            setIsError(true)
            throw new Error("Failed to retrieve cancellation reasons", { cause: error })
        } finally {
            setIsFetchingCancellationReasons(false)
        }
    }

    watch(
        showCancellationModal,
        async newVal => {
            if(newVal) {
                await fetchCancellationReasons()
            } 
        }
    )

    return {
        showCancellationModal: readonly(showCancellationModal),
        isSubmittingCancellation: readonly(isSubmittingCancellation),
        isFetchingCancellationReasons: readonly(isFetchingCancellationReasons),
        isError: readonly(isError),
        cancellationReasons,
        cancellationFormData,
        projectCancellationModalTitle, 
        projectCancellationModalDescription,
        projectCancellationModalMessageLabel,
        projectCancellationModalOptionLabel,
        submitCancellation,
        setShowCancellationModal,
        handleErrorMessage
    }
}
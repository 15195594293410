<template>
  <div class="c-page c-page--enrollment">
    <recess-layout-static>
      <recess-divider />
      <div v-if="isAccountEnabled">
        <enrollment-component
          v-if="showEnrollmentComponent"
          :prev-route="prevRoute"
        />
      </div>
    </recess-layout-static>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import EnrollmentComponent from '../../organisms/Enrollments/EnrollmentComponent/EnrollmentComponent.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import * as enrollmentTypes from '../../../constants/enrollmentTypes'

export default {
    name: 'Enrollment',
    perimeters: [routePerimeter],
    components: {
        EnrollmentComponent
    },
    beforeRouteEnter(_, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    data() {
        return {
            prevRoute: null
        }
    },
    computed: {
        ...mapGetters('accountModule', ['isAccountEnabled']),
        ...mapState('accountModule', ['isAccountFetched']),
        ...mapState('enrollmentModule', ['enrollmentType']),
        showEnrollmentComponent() {
            let canAccess = false
            switch (this.enrollmentType) {
                case enrollmentTypes.TEAMLEADER:
                    canAccess = this.$isAllowed('routeAsTeamleader')
                    break
                case enrollmentTypes.COORDINATOR:
                    canAccess = this.$isAllowed('routeAsCoordinator')
                    break
                case enrollmentTypes.STUDENT:
                    canAccess = true
                    break
                default:
                    break
            }

            return canAccess
        }
    },
    watch: {
        isAccountFetched() {
            this.redirectDisabledAccount()
        }
    },
    mounted() {
        this.redirectDisabledAccount()
        this.setEnrollmentType(this.$route.params.type)
    },
    methods: {
        ...mapMutations('enrollmentModule', ['setEnrollmentType']),
        redirectDisabledAccount() {
            if (this.isAccountFetched && !this.isAccountEnabled) {
                this.$router.push({ path: '/' })
            }
        }
    }
}
</script>
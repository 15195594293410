import axios from 'axios'
import { removeCookie } from '../modules/cookie-helper'
import { getUserManager } from '../modules/oidc-client'

export function impersonateSignout(authorityUrl, authorization) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            baseURL: authorityUrl,
            headers: {
                Authorization: authorization
            },
            withCredentials: true,
            url: 'account/impersonateSignout'
        })
            .then(response => {
                resolve(response)
            })
            .catch(response => {
                console.error('impersonateSignout error response: ', response)
                reject(response)
            })
    })
}

export async function impersonateSignoutAndRedirect(authorityUrl, authorization, redirectUrl) {
    await impersonateSignout(authorityUrl, authorization)
    // emulate logout behavior on client side
    removeCookie('Authorization')
    getUserManager().removeUser()
    
    window.location.href = redirectUrl
}

export function userSwitch(authorityUrl, authorization, userid) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            baseURL: authorityUrl,
            headers: {
                Authorization: authorization
            },
            withCredentials: true,
            url: 'account/userswitch',
            params: {
                userid
            }
        })
            .then(response => {
                resolve(response)
            })
            .catch(response => {
                console.error(response)
                reject(response)
            })
    })
}

export async function userSwitchAndRedirect(authorityUrl, authorization, userid, redirectUrl) {
    await userSwitch(authorityUrl, authorization, userid)
    // emulate logout behavior on client side
    removeCookie('Authorization')
    getUserManager().removeUser()
    // redirect
    window.location.href = redirectUrl
}

export default {
    impersonateSignout,
    userSwitch,
    userSwitchAndRedirect
}
<template>
  <div class="d-flex flex-column flex-md-row">
    <recess-radio-input
      :default-checked="allowSharing"
      name="learningResult"
      class="mr-4 mr-md-5 font-weight-normal" 
      :value="true"
      data-test="allow-sharing-result"
      label-text="Ik wil mijn resultaten ontvangen en delen"
      @change="value => $emit('change-sharing-consent-choice', value)"
    />
    <recess-radio-input
      :default-checked="allowSharing !== undefined  && !allowSharing"
      name="learningResult"
      :value="false"
      class="font-weight-normal"
      data-test="disable-sharing-result"
      label-text="Ik wil geen resultaten ontvangen en delen"
      @change="value => $emit('change-sharing-consent-choice', value)"
    />
  </div>
</template>

<script>

import { onMounted } from 'vue'

export default {
    props: {
        allowSharing: {
            type: Boolean,
            default: undefined
        }
    },
    setup(props, { emit }) {
        onMounted(() => {
            emit('change-sharing-consent-choice', props.allowSharing)
        })
    }
}
</script>
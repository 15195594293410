import { powerBiRenderStatus, powerBiServiceStatus } from '../../constants/powerBIStates'
import reportClient from '../../api/reportClient'
import { jsMediaQueries } from '../../mixins/mediaqueries-mixin'

export default {
    namespaced: true,
    state: {
        pbi: undefined,
        status: powerBiRenderStatus.unloaded,
        reportType: -1,
        report: undefined,
        reportConfig: undefined,
        elemRef: undefined
    },
    getters: {
        isError(state) {
            return state.status === powerBiRenderStatus.error
        },
        isRefreshing(state) {
            return state.status === powerBiRenderStatus.refreshing
        },
        isLoading(state) {
            return state.status === powerBiRenderStatus.loading
                || state.status === powerBiRenderStatus.unloaded
                || state.status === powerBiRenderStatus.refreshing
                || typeof state.pbi === 'undefined'
        },
        isReady(state) {
            return state.status === powerBiRenderStatus.ready
        },
        service(state) {
            if (!state.pbi) { throw new Error('Power BI service is not loaded') }

            return new state.pbi.service.Service(
                state.pbi.factories.hpmFactory,
                state.pbi.factories.wpmpFactory,
                state.pbi.factories.routerFactory
            )
        },
        isMobile() {
            const windowWidth = window ? window.innerWidth : 0
            return windowWidth <= jsMediaQueries.large
        }
    },
    mutations: {
        SET_STATUS(state, newStatus) {
            state.status = newStatus
        },
        SET_REPORT_TYPE(state, newReportType) {
            state.reportType = newReportType
        },
        SET_REPORT(state, newReport) {
            state.report = newReport

            if (!newReport) {
                state.reportConfig = undefined
                return
            }

            // Embed configuration used to describe the what and how to embed.
            // This object is used when calling powerbi.embed.
            // This also includes settings and options such as filters.
            // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
            const models = state.pbi.models
            state.reportConfig = {
                type: 'report',
                tokenType: models.TokenType.Embed,
                accessToken: newReport.embedToken,
                embedUrl: newReport.embedUrl,
                id: newReport.reportId,
                permissions: models.Permissions.Read,
                settings: {
                    localeSettings: {
                        language: 'nl',
                        formatLocale: 'nl'
                    },
                    navContentPaneEnabled: true,
                    filterPaneEnabled: false
                }
            }
        },
        SET_PBI(state, newPbi) {
            state.pbi = newPbi
        },
        SET_ELEM_REF(state, newElemRef) {
            state.elemRef = newElemRef
        }
    },
    actions: {
        /** Load powerbi client */
        initPBI({ commit, state }) {
            if (typeof state.pbi !== 'undefined') return
            // eslint-disable-next-line global-require
            const pbi = require('powerbi-client')
            commit('SET_PBI', pbi)
        },
        /** Check powerbi refresh status */
        async checkLoadingStatus({ commit }) {
            commit('SET_STATUS', powerBiRenderStatus.loading)

            const refreshStatusResponse = await reportClient.refreshStatus()
            if (refreshStatusResponse.result?.status === powerBiServiceStatus.succeeded || refreshStatusResponse.result?.status === powerBiServiceStatus.failed || refreshStatusResponse.statusCode >= 400) {
                commit('SET_STATUS', powerBiRenderStatus.ready)
            } else {
                commit('SET_STATUS', powerBiRenderStatus.refreshing)
            }
        },
        /** fetch report type */
        async fetchReport({ commit, getters, dispatch, state }) {
            await dispatch('checkLoadingStatus')
            if (getters.isLoading) return

            commit('SET_STATUS', powerBiRenderStatus.loading)
            const reportType = state.reportType
            try {
                const report = await reportClient.generateReport(reportType)
                commit('SET_REPORT', report)
                commit('SET_STATUS', powerBiRenderStatus.ready)
            } catch (error) {
                console.error(error)
                commit('SET_STATUS', powerBiRenderStatus.error)
            }
        },
        /** Render current report */
        async renderReport({ getters, state }) {
            if (!getters.isReady) return

            const reportConfig = state.reportConfig
            if (!reportConfig) return

            const target = state.elemRef
            if (!target) return
            
            // Embed the report and display it within the div container.
            const report = getters.service.embed(target, reportConfig)

            // Change to mobile view if required
            if (getters.isMobile) {
                report.updateSettings({
                    layoutType: state.pbi.models.LayoutType.MobilePortrait
                })
            }
        },
        async fetchAndRenderReport(context, { reportType, elemRef }) {
            await context.dispatch('initPBI')
            context.commit('SET_REPORT_TYPE', reportType)
            context.commit('SET_ELEM_REF', elemRef)
            await context.dispatch('fetchReport')
            await context.dispatch('renderReport')
        },
        reset({ commit }) {
            commit('SET_REPORT_TYPE', -1)
            commit('SET_REPORT', undefined)
            commit('SET_ELEM_REF', undefined)
            commit('SET_STATUS', powerBiRenderStatus.unloaded)
        }
    }
}
import axios from 'axios'
import courseClient from '../../api/courseClient'
import * as facets from '../../constants/facetKeys'
import * as expertiseAreas from '../../constants/expertiseArea'

const defaultSteps =  [
    {
        key: facets.LEVELS,
        stepTitle: "Op welke niveau('s) zoekt u een ontwikkelactiviteit?",
        label: 'Niveau'
    },
    {
        key: facets.DURATIONS,
        stepTitle: 'Hoe lang mag de ontwikkelactiviteit duren?',
        label: 'Duur'
    },
    {
        key: facets.LOCATIONS,
        stepTitle: 'Naar welke locatie(s) gaat uw voorkeur uit?',
        label: 'Locatie'
    }
]

const courseWizardModule = {
    namespaced: true,

    state() {
        return {
            facets: [],
            steps: defaultSteps,
            count: 0,
            selectedFilters: [],
            activeStep: null,
            isLoading: false
        }
    },
    getters: {
        getStep: state => index => {
            return state.steps[index - 1] ? state.steps[index - 1] : null
        },
        // Check if the filter is selectet
        isSelectedFilter: state => (facetName, filterValue) => {
            return state.selectedFilters.some(f => {
                if (f.name === facetName) {
                    return f.values.some(v => v === filterValue)
                }
                return false
            })
        },
        // Get the index of the active step
        activeStepNumber: state => {
            if (state.steps && state.steps.length && state.activeStep) {
                const stepIndex = state.steps.findIndex(step => step.key === state.activeStep.key)
                return stepIndex + 1
            }
            return null
        },
        totalStepsNumber: state => {
            return state.steps.length
        },
        // Get the active step facet
        activeStepFacet: state => {
            if (state.facets && state.activeStep?.key) {
                const facetKey = state.activeStep.key
                const activeStep = state.facets.find(facet => facet.key === facetKey)

                // filter out facet values without courses
                if (activeStep) activeStep.values = activeStep?.values?.filter(x => x.count)

                return activeStep
            }
            return null
        },
        lastStepFacet: state => {
            if (state.steps && state.steps.length) {
                return [...state.steps].pop()
            }
            return null
        },
        firstStepFacet: state => {
            if (state.steps && state.steps.length) {
                return state.steps[0]
            }
            return null
        },
        activeStepIsLastStep: (state, getters) => {
            return state.activeStep && getters.lastStepFacet
                ? state.activeStep.key === getters.lastStepFacet.key
                : null
        },
        activeStepIsFirstStep: (state, getters) => {
            return state.activeStep && getters.firstStepFacet
                ? state.activeStep.key === getters.firstStepFacet.key
                : null
        }
    },
    mutations: {
        resetState(state) {
            state.facets = []
            state.count = 0
            state.activeStep = null
            state.selectedFilters = []
            state.isLoading = false
            state.steps = defaultSteps
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setCount(state, newCount) {
            state.count = newCount
        },
        setFacets(state, newFacets) {
            state.facets = newFacets
        },
        setActiveStep(state, activeStep) {
            state.activeStep = activeStep
        },
        addFilter(state, { facetName, filterValue }) {
            const facetToUpdate = state.selectedFilters.find(f => f.name === facetName)
            if (!facetToUpdate) {
                state.selectedFilters.push({ name: facetName, values: [filterValue] })
            } else {
                facetToUpdate.values.push(filterValue)
            }
        },
        removeFilter(state, { facetName, filterValue }) {
            const facetToUpdate = state.selectedFilters.find(f => f.name === facetName)
            if (facetToUpdate) {
                facetToUpdate.values = facetToUpdate.values.filter(v => v !== filterValue)
            }

            // Delete facet from selectedfilters if no selected filters are left
            if (facetToUpdate.values.length === 0) {
                state.selectedFilters = state.selectedFilters.filter(f => f.name !== facetName)
            }
        },
        // Delete all selected filters for a step
        removeStepFilters(state, step) {
            const facetToUpdate = state.selectedFilters.find(f => f.name === step.key)
            if (facetToUpdate) {
                state.selectedFilters = state.selectedFilters.filter(f => f.name !== step.key)
            }
        },
        addStep(state, step) {
            state.steps.push(step)
        }
    },
    actions: {
        initWizard({ commit, rootGetters, state, getters, dispatch }) {
            commit('resetState')

            if (!state.activeStep) {
                const firstStep = getters.firstStepFacet
                if (firstStep) {
                    commit('setActiveStep', firstStep)
                    dispatch('fetchFacets')
                }
            }

            // Add Vakgebieden (expertiseArea) step
            const expertiseArea = rootGetters['accountModule/expertiseArea']
            const step = {
                stepTitle: 'Naar welke vakgebieden gaat uw voorkeur uit?',
                label: 'Vakgebied'
            }

            switch(expertiseArea) {
                case expertiseAreas.SEGMENTS:
                    step.key = facets.SEGMENTS
                    commit('addStep', step)
                    break
                default: 
                    break
            }
        },
        fetchFacets({ commit, state, getters, rootGetters }) {
            commit('setIsLoading', true)
            const selectedFilters = state.selectedFilters
            const activeStepFacet = getters.activeStepFacet
            const previousStepFilters = selectedFilters.filter(f => f.name !== activeStepFacet.key)

            // Add if final startmoment filter is to be included in search filter
            const includeFinalStartMomentDateFilter = !!rootGetters['accountModule/finalStartMomentDate']

            courseClient
                .fetchSearch({ filters: previousStepFilters, includeFinalStartMomentDateFilter })
                .then(response => {
                    if (response) {
                        commit('setFacets', response.data.facets)
                        commit('setCount', response.data.count)
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        // Do nothing
                    } else {
                        console.error(
                            'Something went wrong while retrieving the search items',
                            error
                        )
                    }
                })
                .then(() => commit('setIsLoading', false))
        },
        updateCount({ commit, state }) {
            const filters = state.selectedFilters
            courseClient
                .fetchSearch({ filters })
                .then(response => {
                    if (response) {
                        commit('setCount', response.data.count)
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        // Do nothing
                    } else {
                        console.error(
                            'Something went wrong while retrieving the search items',
                            error
                        )
                    }
                })
        },
        setFilter({ commit, getters }, { facetName, filterValue, checked }) {
            if (checked && !getters.isSelectedFilter(facetName, filterValue)) {
                commit('addFilter', { facetName, filterValue })
            } else if (!checked && getters.isSelectedFilter(facetName, filterValue)) {
                commit('removeFilter', { facetName, filterValue })
            }
        },
        nextStep({ commit, getters, dispatch }) {
            const activeStepfacet = getters.activeStepFacet
            const lastStepFacet = getters.lastStepFacet
            if (activeStepfacet !== lastStepFacet) {
                const nextStepNumber = getters.activeStepNumber
                    ? getters.activeStepNumber + 1
                    : null
                const nextStep = getters.getStep(nextStepNumber)
                if (nextStep) {
                    commit('setActiveStep', nextStep)
                }
                dispatch('fetchFacets')
            }
        },
        previousStep({ commit, getters, dispatch }) {
            const activeStepfacet = getters.activeStepFacet
            const firstStepFacet = getters.firstStepFacet
            if (activeStepfacet !== firstStepFacet) {
                const previousStepNumber = getters.activeStepNumber
                    ? getters.activeStepNumber - 1
                    : null
                const previousStep = getters.getStep(previousStepNumber)
                if (previousStep) {
                    commit('removeStepFilters', activeStepfacet)
                    commit('setActiveStep', previousStep)
                }
                dispatch('fetchFacets')
            }
        },
        skipStep({ commit, getters, dispatch }) {
            const lastStepFacet = getters.lastStepFacet
            const activeStepfacet = getters.activeStepFacet
            if (activeStepfacet !== lastStepFacet) {
                const nextStepNumber = getters.activeStepNumber
                    ? getters.activeStepNumber + 1
                    : null
                const nextStep = getters.getStep(nextStepNumber)
                if (nextStep) {
                    commit('removeStepFilters', activeStepfacet)
                    commit('setActiveStep', nextStep)
                }
                dispatch('fetchFacets')
            }
        },
        goToSpecificStep({ commit, dispatch, getters }, stepNumber) {
            const activeStepNumber = getters.activeStepNumber
            const newStep = getters.getStep(stepNumber)
            for (let i = stepNumber + 1; i <= activeStepNumber; i += 1) {
                const stepToDelete = getters.getStep(i)
                commit('removeStepFilters', stepToDelete)
            }
            commit('setActiveStep', newStep)
            dispatch('fetchFacets')
        }
    }
}

export default courseWizardModule

<template functional>
  <svg
    :class="props.cssClass"
    viewBox="0 0 25 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill-rule="evenodd"
    >
      <g
        id="Searchresult-(Grid-View)"
        transform="translate(-1516.000000, -542.000000)"
      >
        <g
          id="Listing"
          transform="translate(375.000000, 339.000000)"
        >
          <g
            id="filtering"
            transform="translate(0.000000, 79.000000)"
          >
            <path
              id="list"
              d="M1165.003,139 L1165.003,141.571429 C1165.003,141.928573 1164.878,142.232142 1164.628,142.482143 C1164.378,142.732144 1164.07443,142.857143 1163.71729,142.857143 L1142.28571,142.857143 C1141.92857,142.857143 1141.625,142.732144 1141.375,142.482143 C1141.125,142.232142 1141,141.928573 1141,141.571429 L1141,139 C1141,138.642855 1141.125,138.339287 1141.375,138.089286 C1141.625,137.839284 1141.92857,137.714286 1142.28571,137.714286 L1163.71729,137.714286 C1164.07443,137.714286 1164.378,137.839284 1164.628,138.089286 C1164.878,138.339287 1165.003,138.642855 1165.003,139 Z M1165,132.142857 L1165,134.714286 C1165,135.07143 1164.875,135.374999 1164.625,135.625 C1164.375,135.875001 1164.07143,136 1163.71429,136 L1142.28571,136 C1141.92857,136 1141.625,135.875001 1141.375,135.625 C1141.125,135.374999 1141,135.07143 1141,134.714286 L1141,132.142857 C1141,131.785712 1141.125,131.482144 1141.375,131.232143 C1141.625,130.982142 1141.92857,130.857143 1142.28571,130.857143 L1163.71429,130.857143 C1164.07143,130.857143 1164.375,130.982142 1164.625,131.232143 C1164.875,131.482144 1165,131.785713 1165,132.142857 Z M1165.02985,125.285714 L1165.02985,127.857143 C1165.02985,128.214287 1164.90486,128.517856 1164.65485,128.767857 C1164.40485,129.017858 1164.10129,129.142857 1163.74414,129.142857 L1142.28571,129.142857 C1141.92857,129.142857 1141.625,129.017858 1141.375,128.767857 C1141.125,128.517856 1141,128.214288 1141,127.857143 L1141,125.285714 C1141,124.92857 1141.125,124.625001 1141.375,124.375 C1141.625,124.124999 1141.92857,124 1142.28571,124 L1163.74414,124 C1164.10129,124 1164.40485,124.124999 1164.65485,124.375 C1164.90486,124.625001 1165.02985,124.92857 1165.02985,125.285714 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    props: {
        cssClass: {
            type: [String, Array],
            default: ''
        }
    }
}
</script>
import { getUserManager } from '../modules/oidc-client'

/**
 * Validate current user login state
 * 
 * @param {*} oidcClient 
 * @param {string} signInPath 
 * @returns 
 */
export async function validateLogin(oidcClient, signInPath) {
    try {
        const user = await getUserManager().getUser()
        if (user && !user.expired) return true

        console.warn('User expired or not found, attempting to login')
        oidcClient.signIn(signInPath)

        return false
    } catch (err) {
        console.error('Error during login:', err)
        throw err
    }
}

/**
 * build method to handle user authentication
 * 
 * @param {*} oidcClient 
 * @returns {import('./types').AuthHandler}
 */
export default function buildAuthHandler(oidcClient) {
    // eslint-disable-next-line
    return (to, next) => {
        // check if any matcher route requires authentication
        const requiresAuth = to.matched.some(
            record => !!record?.meta?.requiresAuth)
        
        const callNext = () => { if (next) next() }

        if (!requiresAuth)
            return callNext()

        validateLogin(oidcClient, to.fullPath)
            .then(valid => {
                if (valid)
                    callNext()
            })
            .catch(err => console.error(err))
    }
}
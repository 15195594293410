import TableHeaderBuilder from '../utils/builders/TableHeaderBuilder'
import tableHelper from "./tableHelper"

const getEnrollmentTableHeaders = (useNetPrice, displayVatInPrices, isMyTeam = false) => {
    const headers = new TableHeaderBuilder()
        .courseName()
        .enrollmentCreationDate()
        .price(useNetPrice, displayVatInPrices)
        .courseVariant()
        .enrollmentStatus()
        .emptyCell()
        .build()

    if (isMyTeam)
        headers.unshift(...new TableHeaderBuilder()
            .firstName()
            .build())    

    return headers   
}

const displayHeaderCell = (headerKey, useNetPrice) => tableHelper.displayHeaderCell(headerKey, useNetPrice)

const showApproveButton = enrollment => tableHelper.showApproveButton(enrollment)

export default {
    getEnrollmentTableHeaders,
    displayHeaderCell,
    showApproveButton
}

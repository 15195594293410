<template>
  <section>
    <transition name="fade" mode="out-in">
      <loader-component v-if="isLoading" overlay="white" color="primary" is-fixed />
    </transition>

    <div class="d-flex justify-content-end flex-grow-1 flex-column flex-md-row">
      <recess-button
        data-test="reject-button"
        class="mb-3 mb-md-0 mr-md-3"
        title="Afkeuren"
        variant="tertiary"
        @click="setShowRejectionModal(true)"
      />
      <recess-button
        data-test="approve-button"
        title="Goedkeuren"
        variant="primary"
        @click="approve"
      />
    </div>

    <project-request-rejection-modal
      :title="projectRejectionModalTitle"
      :description="projectRejectionModalDescription"
      :message-label="projectRejectionMessageLabel"
      :is-visible="showRejectionModal"
      :handle-error-message="handleErrorMessage"
      :message="rejectionFormData.message"
      :show-error="isRejectError"
      @input:message="rejectionFormData.message = $event"
      @confirm="reject"
      @close="setShowRejectionModal(false)"
    />
  </section>
</template>

<script>
import { watch } from 'vue'
import { required, maxLength } from 'vuelidate/lib/validators'
import useProjectRequestApproval from '../../../../composables/useProjectRequestApproval'
import ProjectRequestRejectionModal from '../../../molecules/SubmitWithRemarksModal/SubmitWithRemarksFormModal.vue'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    components: {
        ProjectRequestRejectionModal,
        LoaderComponent
    },
    props: {
        projectRequest: {
            type: Object,
            default: () => {}
        },
        approvalFormData: {
            type: Object,
            default: () => {}
        },
        validateApproval: {
            type: Function,
            default: () => true
        }
    },
    emits: ['submit', 'error'],
    setup(props, { emit }) {
        const {
            projectRejectionModalTitle,
            projectRejectionModalDescription,
            projectRejectionMessageLabel,
            isApproveError,
            isLoading,
            approveProjectRequest,
            rejectProjectRequest,
            rejectionFormData,
            handleErrorMessage,
            isRejectError,
            showRejectionModal,
            setShowRejectionModal
        } = useProjectRequestApproval()

        watch(isApproveError, newVal => {
            emit('error', newVal)
        })

        const approve = async () => {
            if (!props.validateApproval()) {
                return
            }

            await approveProjectRequest(props.projectRequest.id, props.approvalFormData)
            emit('submit')
        }

        const reject = async () => {
            const rejectRequest = {
                id: props.projectRequest.id,
                remark: rejectionFormData.message
            }

            const isSuccess = await rejectProjectRequest(rejectRequest)
            if (isSuccess) emit('submit')
        }

        return {
            projectRejectionModalTitle,
            projectRejectionModalDescription,
            projectRejectionMessageLabel,
            isApproveError,
            isRejectError,
            isLoading,
            handleErrorMessage,
            showRejectionModal,
            rejectionFormData,
            approve,
            reject,
            setShowRejectionModal
        }
    },
    validations() {
        return {
            rejectionFormData: {
                message: { required, maxLength: maxLength(300) }
            }
        }
    }
}
</script>

<template>
  <footer class="c-footer">
    <div
      v-if="$isAllowed('displayFooterMenu')"
      class="c-footer__listing"
    >
      <recess-layout-static v-if="!isFetchingFacets">
        <div class="row">
          <div class="col-12 col-md-6">
            <footer-facet-listing
              data-test="footer-expertise-area"
              title="Vakgebieden"
              :facet="expertiseAreaItems"
              :column-numbers="2"
              no-item-message="Er vallen geen vakgebieden onder dit account."
            />
          </div>
          <div :class="[{'col-lg-3' : ($isAllowed('displayContactInformationForStudents') || $isAllowed('displayContactAlternativeText'))} ,'col-12 col-md-6']">
            <footer-facet-listing
              data-test="footer-level"
              title="Niveau"
              :facet="levels"
              no-item-message="Er vallen geen levels onder dit account."
            />
          </div>
          <div
            v-if="$isAllowed('displayContactInformationForStudents') || $isAllowed('displayContactAlternativeText')"
            class="col-12 col-lg-3 col-md-12"
          >
            <account-contact-info-component 
              data-test="footer-contact"
            />
          </div>
        </div>
      </recess-layout-static>
    </div>

    <div class="c-footer__bar d-flex align-items-center">
      <recess-layout-static>
        <div class="row">
          <div class="col-12 col-md-6">
            <div
              class="d-flex align-items-center h-100 justify-content-center justify-content-md-start"
            >
              <span>Powered by</span>
              <span class="c-footer__brand-logo"></span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="d-flex align-items-center h-100 justify-content-center justify-content-md-end"
            >
              <span>&copy; {{ getCurrentYear() }} zakelijk.opleiding.nl</span>
            </div>
          </div>
        </div>
      </recess-layout-static>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AccountContactInfoComponent from '../../molecules/AccountContactInfoComponent/AccountContactInfoComponent.vue'
import FooterFacetListing from './FooterFacetListing.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import DateUtils from '../../../utils/DateUtils'

export default {
    perimeters: [featuresPerimeter],
    components: {
        AccountContactInfoComponent,
        FooterFacetListing
    },
    computed: {
        ...mapState('facetsModule', ['isFetchingFacets']),
        ...mapGetters('facetsModule', ['levels', 'expertiseAreaItems'])
    },
    methods: {
        getCurrentYear() {
            return DateUtils.now.getFullYear()
        }
    }
}
</script>
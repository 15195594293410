<template>
  <div>
    <!-- Desktop -->
    <div class="d-none d-lg-block">
      <recess-card
        v-click-outside="closeFacetListing"
        class="c-home-banner-tab u-position-relative"
      >
        <recess-tab-navigation
          name="facet"
        >
          <recess-tab-navigation-item
            class="mw-100"
            close-on-click
            data-test="area-of-expertise-tab"
            :tab-key="homeBannerFacetTabs.AREAOFEXPERTISE.key"
          >
            {{ homeBannerFacetTabs.AREAOFEXPERTISE.displayValue }}
          </recess-tab-navigation-item>   
          <recess-tab-navigation-item
            class="mw-100"
            data-test="level-tab"
            close-on-click
            :tab-key="homeBannerFacetTabs.LEVEL.key"
          >
            {{ homeBannerFacetTabs.LEVEL.displayValue }}
          </recess-tab-navigation-item>      
          <recess-tab-navigation-item
            v-if="showPeerFilters"
            data-test="peer-tab"
            close-on-click
            :tab-key="homeBannerFacetTabs.PEERS.key"
          >
            {{ homeBannerFacetTabs.PEERS.displayValue }}
          </recess-tab-navigation-item>
        </recess-tab-navigation>
    
        <template v-if="!isFetchingFacets">
          <home-banner-facet-listing
            v-if="$route.query['facet-tab'] === homeBannerFacetTabs.AREAOFEXPERTISE.key"
            no-items-message="Er vallen geen vakgebieden onder dit account."
            data-test="expertise-area-listing"
            :facet="expertiseAreaItems"
          />
          <home-banner-facet-listing
            v-if="$route.query['facet-tab'] === homeBannerFacetTabs.LEVEL.key"
            no-items-message="Er vallen geen levels onder dit account."
            data-test="level-listing"
            :facet="levels"
          />
          <home-banner-facet-listing
            v-if="$route.query['facet-tab'] === homeBannerFacetTabs.PEERS.key && showPeerFilters"
            data-test="peers-listing"
            :facet="peerFilters"
          />
        </template>
      </recess-card>
    </div>

    <!-- Mobile -->
    <div class="d-lg-none">
      <recess-divider
        variant="small"
        :display-small-viewport="true"
        :display-medium-viewport="false"
        :display-large-viewport="false"
      />
      <recess-accordion :variant="accordionVariant">
        <template #accordion-title>{{ homeBannerFacetTabs.AREAOFEXPERTISE.displayValue }}</template>
        <template #accordion-content>
          <home-banner-facet-listing
            :facet="expertiseAreaItems"
            no-items-message="Er vallen geen vakgebieden onder dit account."
          />
        </template>
      </recess-accordion>
      <recess-accordion :variant="accordionVariant">
        <template #accordion-title>{{ homeBannerFacetTabs.LEVEL.displayValue }}</template>
        <template #accordion-content>
          <home-banner-facet-listing
            :facet="levels"
            no-items-message="Er vallen geen levels onder dit account."
          />
        </template>
      </recess-accordion>
      <recess-accordion
        v-if="showPeerFilters"
        :variant="accordionVariant"
      >
        <template #accordion-title>{{ homeBannerFacetTabs.PEERS.displayValue }}</template>
        <template #accordion-content>
          <home-banner-facet-listing :facet="peerFilters" />
        </template>
      </recess-accordion>
    </div>
    <recess-divider />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import HomeBannerFacetListing from '../HomeBannerFacetListing/HomeBannerFacetListing.vue'
import mixinClickOutside from '../../../mixins/click-outside-directive'
import { homeBannerFacetTabs } from '../../../constants/tabNames'
import { homeBannerPeers } from '../../../constants/customFacets'
import routePerimeter from '../../../perimeters/routePerimeter'

export default {
    perimeters: [ routePerimeter ],
    components: {
        HomeBannerFacetListing
    },
    mixins: [mixinClickOutside],
    data() {
        return {
            accordionVariant: 'variant-2',
            homeBannerFacetTabs,
            peerFilters: homeBannerPeers
        }
    },
    computed: {
        ...mapState('facetsModule', ['isFetchingFacets']),
        ...mapGetters('facetsModule', ['expertiseAreaItems', 'levels']),
        ...mapGetters("accountModule", ["teamLeaderSelfSearchDisabled"]),
        showPeerFilters() {
            return !this.$isAllowed('routeAsTeamleader') || (!this.teamLeaderSelfSearchDisabled && this.$isAllowed('routeAsTeamleader'))
        }
    },
    methods: {
        closeFacetListing() {
            if(this.$route.query?.['facet-tab']) this.$router.replace({ query: { 'facet-tab' : undefined} })
        }
    }
}
</script>
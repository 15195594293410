// Example: € 10.000,-
const formatPrice = value => {
    if (typeof Intl === 'object') {
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: 'EUR'
        })

        return `${formatter.format(value).replace(/,00$/, ',-')}`
    }
    return `${value}`
}

// Example: €10.000
const formatPriceShort = value => {
    if (typeof Intl === 'object') {
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currency: 'EUR'
        })

        return `${formatter.format(value).replace(/,00$/, '')}`.replace(/\s/g, '')
    }
    return `${value}`.replace(/\s/g, '')
}

// Example: € 10.000,00
const formatPriceWithZeros = value => {
    if (typeof Intl === 'object') {
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: 'EUR'
        })

        return `${formatter.format(value)}`
    }
    return `${value}`
}

export default { 
    formatPrice,
    formatPriceShort,
    formatPriceWithZeros 
}

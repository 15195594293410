import { PEERFILTERS } from "./facetKeys"

export const searchPagePeers =  [ 
    {
        displayValue: 'Met dezelfde functie',
        key: 'Jobtitle'
    },
    {
        displayValue: 'Op mijn afdeling',
        key: 'Company'
    },
    {
        displayValue: 'In mijn bedrijfsonderdeel',
        key: 'Department'
    },
    {
        displayValue: 'Binnen mijn organisatie',
        key: 'Account'
    }
]

export const homeBannerPeers = {
    key: PEERFILTERS,
    values: [
        {
            displayValue: 'Obv mensen met dezelfde functie',
            key: 'Jobtitle'
        },
        {
            displayValue: 'Obv wat mensen doen op mijn afdeling',
            key: 'Company'
        },
        {
            displayValue: 'Obv wat mensen doen binnen mijn bedrijfsonderdeel',
            key: 'Department'
        },
        {
            displayValue: 'Obv wat mensen doen binnen mijn organisatie',
            key: 'Account'
        }
    ]
}
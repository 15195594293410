import examStatus from './examStatus'

export const examStatusOptions =  [ 
    {
        displayText: 'n.v.t',
        value: examStatus.NOTAPPLICABLE
    },
    {
        displayText: 'Geslaagd',
        value: examStatus.PASSED
    },
    {
        displayText: 'Niet geslaagd',
        value:  examStatus.FAILED
    }
]

export default examStatusOptions
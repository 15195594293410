<template>
  <div>
    <recess-modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template slot="modal-title">
        <transition
          name="fade"
          mode="out-in"
        >
          <recess-loader
            v-if="isFetchingCourse || isFetchingCoursePrice"
            overlay="white"
            color="primary"
            full-screen
          />
        </transition>
        <h3>Oeps..</h3>
      </template>
      <template slot="modal-body">
        <p class="mb-2">
          U heeft helaas minder <router-link
            :to="{ name: 'my-budgets'}"
            class="font-weight-bold"
          >
            budget
          </router-link> beschikbaar dan de kosten voor deze ontwikkelactiviteit. 
          Vraag meer budget aan wanneer u zich toch wilt kunnen inschrijven voor deze ontwikkelactiviteit.
        </p>
      </template>
      <div
        slot="modal-footer"
        class="d-flex flex-column"
      >
        <div>
          <recess-button
            :url="{ name: 'home' }"
            title="Terug naar home"
            variant="primary"
          />
          <recess-button
            class="u-position-right col-12 col-md-auto ml-3"
            title="Toch doorgaan"
            variant="secondary"
            data-test="close-duplicate"
            @click.native="showModal = false"
          />
        </div>
      </div>
    </recess-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'

export default {
    data() {
        return {
            enrollmentTypes,
            showModal: this.blockBecauseOfInsufficientBudget
        }
    },
    computed: {
        ...mapGetters('enrollmentModule', ['blockBecauseOfInsufficientBudget']),
        ...mapState('enrollmentModule', ['isFetchingCourse', 'isFetchingCoursePrice'])
    },
    watch: {
        blockBecauseOfInsufficientBudget(value) {
            if(value) this.showModal = true
        }
    }
}
</script>
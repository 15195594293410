import axios from 'axios'
import courseClient from '../api/courseClient'
import { checkIfUserIsAssignedToLearningPathCourse } from '../api/learningPathClient'
import courseAccessStatuses from '../constants/courseAccessStatuses'

class CourseAccessHelper {
    #pendingRequests

    constructor() {
        this.#pendingRequests = new Map() // Track pending requests for each courseId
    }

    static getInstance() {
        if (!CourseAccessHelper.instance) {
            CourseAccessHelper.instance = new CourseAccessHelper()
        }
        return CourseAccessHelper.instance
    }

    async doesUserHaveAccess(courseId, assortmentId, includeFinalStartMomentDateFilter) {
        if (!courseId) return false
        
        const courseKey = `${courseId}${assortmentId || ''}`.toLowerCase()
        try {
            if (this.#pendingRequests.has(courseKey)) {
                // A request for the same courseId is already pending, wait for its completion
                const existingHasAccessRequest = await this.#pendingRequests.get(courseKey)
                return existingHasAccessRequest
            }
                    
            const hasAccessRequest = CourseAccessHelper.#fetchAccess(courseId, assortmentId, includeFinalStartMomentDateFilter)
            this.#pendingRequests.set(courseKey, hasAccessRequest)

            const hasAccess = await hasAccessRequest
            return hasAccess
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error("User has no access to this course")
                return false
            }

            // Retry the access check by recursively calling the method
            return this.doesUserHaveAccess(courseId, assortmentId, includeFinalStartMomentDateFilter)
        } finally {
            this.#pendingRequests.delete(courseKey)
        }
    }

    static async #fetchAccess(courseId, assortmentId, includeFinalStartMomentDateFilter) {
        const searchRequestBody = {
            top: 1,
            filters: [
                { name: 'productReference', values: [`course:${courseId}`] }
            ],
            excludeFacets: true,
            skipOleExtras: true
        }

        // Some redirects don't include the course assortment id
        if(assortmentId) {
            searchRequestBody.filters.push( { name: 'assortmentId', values: [assortmentId] })
        }

        const { data: { count : searchResponseWithFinalStartMomentCount } } = await courseClient.fetchSearch({...searchRequestBody, includeFinalStartMomentDateFilter})
        if (searchResponseWithFinalStartMomentCount > 0) return courseAccessStatuses.HAS_ACCESS

        const { data: { count: isInUserAssortment } } = await courseClient.fetchSearch(searchRequestBody)
        if (isInUserAssortment && searchResponseWithFinalStartMomentCount === 0) return courseAccessStatuses.NO_VALID_START_MOMENTS

        const isInUserLearningPath = await CourseAccessHelper.#isInUserLearningPaths(courseId)

        return isInUserLearningPath ? courseAccessStatuses.HAS_ACCESS : courseAccessStatuses.OUTSIDE_ASSORTED_PRODUCTS
    }

    static async #isInUserLearningPaths(courseId) {
        try {
            await checkIfUserIsAssignedToLearningPathCourse(courseId)
            return true
        } catch {
            console.error("User has no access to this course")
            return false
        }
    }
}

const accessChecker = CourseAccessHelper.getInstance()

export default accessChecker
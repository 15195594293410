const routerHistory = []

export const writeHistory = (to, from) => {
    if (from) {
        routerHistory.push(from)
    }
}

export const getHistory = () => {
    return routerHistory.reverse()
}

<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <recess-divider />
    <h1>Acceptatie studieovereenkomst</h1>
    <p>Om de ontwikkelactiviteitsaanvraag af te ronden is het voor uw organisatie noodzakelijk dat u de studieovereenkomst accepteert. Deze overeenkomst is bindend.</p>
    <template v-if="pdfUrl">
      <recess-card
        class="u-bg-gray-medium-light"
      >
        <pdf-viewer
          :pdf-url="pdfUrl"
          @last-page="showActionButtons = true"
        />

        <div class="d-md-flex justify-content-md-between">
          <div>
            <recess-button
              :url="{ name: isOwnStudyContract ? 'my-development' : 'my-team', query: $route.query }"
              variant="tertiary"
              title="Terug"
              class="mr-3 mb-3 mb-md-0 col-12 col-md-auto"
            />
            <recess-button
              v-show="$isAllowed('downloadStudyContract', studyContract)"
              data-test="download-study-contract"
              target="_blank"
              variant="tertiary"
              class="col-12 col-md-auto ml-3"
              title="Download"
              :url="pdfUrl"
            />
          </div>
          <div v-if="showActionButtons && $isAllowed('reviewStudyContract', studyContract)">
            <recess-button
              title="Afkeuren"
              variant="tertiary"
              data-test="reject-study-contract-button"
              class="mr-md-2 mb-3 mb-md-0 col-12 col-md-auto"
              @click="showRejectEnrollmentLightbox = true"
            />
            <recess-button
              :disabled="!enrollmentHasValidStartMoments"
              title="Goedkeuren"
              data-test="approve-study-contract-button"
              variant="primary"
              class="col-12 col-md-auto ml-3"
              @click="approve"
            />
          </div>
        </div>
      </recess-card>
      <template v-if="showActionButtons && $isAllowed('reviewStudyContract', studyContract) && !enrollmentHasValidStartMoments">
        <recess-divider />
        <recess-alert
          type="error"
          text="Alle gekozen startmomenten bestaat niet (meer) binnen deze ontwikkelactiviteit. U kunt de studieovereenkomst afkeuren en een nieuwe inschrijving doen met geldige startmomenten."
        />
      </template>
      <reject-study-contract-popup-component
        :study-contract="studyContract"
        :is-visible="showRejectEnrollmentLightbox"
        @close="showRejectEnrollmentLightbox = false"
      />
      <template v-if="showManualUploadStudyContract">
        <recess-divider />
        <h3>{{ isOwnStudyContract ? "Studieovereenkomst handmatig ondertekenen" : "Handmatig ondertekende studieovereenkomst" }}</h3>
        <span v-if="isOwnStudyContract">
          Uw organisatie verlangt, naast een digitale goedkeuring, een met pen ondertekende studieovereenkomst.
          Wanneer u de goedgekeurde studieovereenkomst hebt gedownload, geprint en ondetekend kunt u hem hier uploaden.
          U kunt een PDF, TIFF of ZIP bestand uploaden tot een maximale grootte van 5MB.
        </span>
        <span v-else>
          Download (indien aanwezig) hier de met pen ondertekende studieovereenkomst.
        </span>
        <recess-divider />

        <uploaded-file-component
          v-if="uploadedStudyContractUrl"
          :document-url="uploadedStudyContractUrl"
          :document-name="uploadedStudyContractFileName"
          :show-delete-button="false"
        />

        <recess-divider />
     
        <upload-file-component
          v-if="$isAllowed('uploadStudyContract', studyContract)"
          ref="studyContractFileUpload"
          :show-upload-error="showUploadError"
          :show-success-upload-message="showSuccessUploadMessage"
          @upload="fileToUpload => uploadUserStudyContract(fileToUpload)"
          @closeSuccessModal="showSuccessUploadMessage = false"
        />
      </template>
    </template>
    <recess-alert
      v-show="showErrorMessage"
      text="Uw studieovereenkomst is niet bekend. Probeer een andere studieovereenkomst."
    />
    <recess-divider />
  </div>
</template>

<script>
import { scrollTo } from 'vue-scrollto'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import PdfViewer from '../PdfViewer/PdfViewer.vue'
import studyContractClient from '../../../api/studyContractClient'
import studyContractPerimeter from '../../../perimeters/studyContractPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import UploadFileComponent from '../../atoms/UploadFileComponent/UploadFileComponent.vue'
import UploadedFileComponent from '../../atoms/UploadedFileComponent/UploadedFileComponent.vue'
import RejectStudyContractPopupComponent from '../../molecules/RejectStudyContractPopupComponent/RejectStudyContractPopupComponent.vue'

export default {
    name: 'StudyContractApprovalPage',
    perimeters: [studyContractPerimeter, featuresPerimeter],
    components: {
        LoaderComponent,
        PdfViewer,
        RejectStudyContractPopupComponent,
        UploadFileComponent,
        UploadedFileComponent
    },
    data() {
        return {
            showErrorMessage: false,
            showUploadError: false,
            showSuccessUploadMessage: false,
            pdfUrl: null,
            showActionButtons: false,
            showRejectEnrollmentLightbox: false,
            studyContract: null,
            uploadedStudyContractUrl: null,
            enrollment: null,
            course: null,
            isFetchingStudyContract: false,
            isApprovingStudyContract: false,
            isUploadingDocument: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapGetters('enrollmentModule', ['enrollmentHasValidStartMoments']),
        isOwnStudyContract() {
            return this.user?.userId === this.studyContract?.enrollment?.userId
        },
        isLoading() {
            return this.isFetchingStudyContract || this.isApprovingStudyContract || this.isUploadingDocument
        },
        uploadedStudyContractFileName() {
            // Get the filename by extracting it from the path
            return this.studyContract?.userFilePath?.slice(this.studyContract?.userFilePath?.lastIndexOf('/') + 1) 
        },
        showManualUploadStudyContract() {
            // Check feature toggle perimeter and studyContract perimeter
            return this.$isAllowed('displayManualUploadStudyContract') 
          && (this.$isAllowed('uploadStudyContract', this.studyContract) 
          || this.$isAllowed('downloadUploadedStudyContract', this.studyContract, this.uploadedStudyContractUrl ))
        },
        studyContractId() {
            return this.$route.query?.studyContract
        }
    },
    created() {
        this.fetchStudyContract()
    },
    methods: {
        ...mapActions('enrollmentModule', ['getCourse']),
        ...mapMutations('enrollmentModule', ['setEnrollment']),
        async fetchStudyContract() {
            this.isFetchingStudyContract = true

            try {
                const studyContractDocument = await studyContractClient.getStudyContractDocument(this.studyContractId)
                if (!studyContractDocument) throw new Error('No study contract document')

                this.studyContract = studyContractDocument?.studyContract 
                this.pdfUrl = studyContractDocument?.url 
                this.uploadedStudyContractUrl = studyContractDocument?.userFileUrl

                // only when the student needs to approve the study contract we need to fetch the course to check if the start moments are still valid
                if(this.$isAllowed('reviewStudyContract', this.studyContract)) {
                    this.setEnrollment(studyContractDocument?.studyContract?.enrollment)
                    await this.getCourse(studyContractDocument.studyContract?.enrollment?.eduMSCourseId)
                }
            } catch(err) {
                this.showErrorMessage = true
                console.error(err)
            } finally {
                this.isFetchingStudyContract = false
            }
        },
        async uploadUserStudyContract(fileToUpload) {
            try {
                this.showUploadError = false
                this.isUploadingDocument = true

                if (
                    !fileToUpload?.base64FileString ||
                    !this.studyContractId ||
                    !fileToUpload?.structure?.name
                ) {
                    return
                }

                const request = {
                    fileData: fileToUpload?.base64FileString,
                    fileName: fileToUpload?.structure?.name
                }

                // Upload studyContract
                await studyContractClient.uploadStudyContract(this.studyContractId, request)
                await this.fetchStudyContract()

                // Show success message
                this.showSuccessUploadMessage = true
            } catch (error) {
                this.showUploadError = true
                throw new Error(error)
            } finally {
                this.isUploadingDocument = false
                // Reset file upload input field
                this.$refs.studyContractFileUpload.reset()
            }
        },
        approve() {
            this.isApprovingStudyContract = true
            studyContractClient
                .approve(this.studyContract.studyContractId)
                .then(response => {
                    if (response) {
                        scrollTo('body')
                        this.$emit('approved')
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .then(() => {
                    this.isApprovingStudyContract = false
                })
        }
    }
}
</script>
<template>
  <div class="d-flex align-items-center">
    <recess-dropdown is-bordered class="w-100">
      <template v-if="facetName" #dropdown-title>
        <label class="u-textcolor-black">{{ facetName  }}</label>
      </template>
      <template #dropdown-content>
        <div
          v-for="(facetValue, index) in facetValues"
          :key="index"
        >
          <recess-checkbox-input
            class="font-weight-normal"
            :default-checked="isChecked(facetValue.key)"
            :label-text="capitalize(facetValue.display)"
            @input="(checked) => $emit('checked', facetValue, checked, facetKey)"
          />
        </div>
      </template>
    </recess-dropdown>
    <recess-tooltip v-if="tooltip" class="pl-2">
      <template #content>
        <div class="u-max-width-300">{{ tooltip }}</div>
      </template>
    </recess-tooltip>
  </div>
</template>

<script>
import { computed } from 'vue'
import capitalize from 'lodash/capitalize'

export default {
    props: {
        facet: {
            type: Object,
            default: () => {}
        },
        selectedFacets: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
      
        const facetKey = computed(() => {
            return props.facet?.key
        })
      
        const facetName = computed(() => {
            return props.facet?.display
        })

        const facetValues = computed(() => {
            return props.facet?.values
        })

        const tooltip = computed(() => {
            return props.facet?.tooltip
        })
      
        const isChecked = facet => {
            return props.selectedFacets?.[facetKey.value]?.some(facetValue => facetValue.key === facet)
        }

        return {
            isChecked,
            facetKey,
            facetName,
            facetValues,
            tooltip,
            capitalize
        }
    }
}
</script>
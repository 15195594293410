<template>
  <lightbox-component
    :is-visible="isVisible"
    :show-close-button="!isSubmitting"
    @close="close"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isSubmitting"
        overlay="white"
        color="primary"
      />
    </transition>

    <h3>Aanvraag ontwikkelactiviteit afkeuren</h3>
    <p>Wat is de reden voor het afkeuren van de studieovereenkomst?</p>

    <div class="row">
      <div class="col-12">
        <recess-textarea-input
          v-model="rejectionMessage"
          label-text="Reden voor afkeuren"
          data-test="reject-message-input"
          :error-message="$v.rejectionMessage.$error ? 'Reden voor afkeuring is verplicht' : ''"
        />
      </div>
    </div>
    <recess-divider />
    <div class="d-flex justify-content-end">
      <recess-button
        variant="primary"
        title="Bevestigen"
        data-test="submit-rejection-btn"
        @click="confirmRejection"
      />
    </div>
    <recess-alert
      v-if="showErrorMessage"
      class="mt-4"
      type="error"
      text="Er is iets misgegaan. Probeer het later nog een keer of neem contact op met een administrator."
    />
  </lightbox-component>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import studyContractClient from '../../../api/studyContractClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'

export default {
    components: {
        LoaderComponent,
        LightboxComponent
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        enrollmentId: {
            type: Number,
            default: 0
        },
        studyContract: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            isSubmitting: false,
            rejectionMessage: '',
            showErrorMessage: false
        }
    },
    validations: {
        rejectionMessage: { required }
    },
    watch: {
        rejectionMessage() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.showErrorMessage = false
            }
        }
    },
    methods: {
        close() {
            if (!this.isSubmitting) {
                this.$emit('close')
                this.showErrorMessage = false
            }
        },
        async confirmRejection() {
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                this.isSubmitting = true
                const request = {
                    userMessage: this.rejectionMessage
                }

                await studyContractClient.reject(this.studyContract.studyContractId, request)
            
                this.$emit('close')
                this.$router.push({ name: 'my-development', query: { 'my-development-tab': 'enrollments' } })
            } catch (error) {
                console.error(error)
                this.showErrorMessage = true
            } finally {
                this.isSubmitting = false
            }
        }
    }
}
</script>
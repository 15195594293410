<template>
  <recess-modal
    modal-size="small"
    :show-modal="isVisible"
    :show-close-icon="false"
    data-test="message-modal"
    @close="$emit('close')"
  >
    <div
      slot="modal-body"
      class="d-flex justify-content-center flex-column align-items-center"
    >
      <p class="w-100">{{ message }}</p>
      <div>
        <slot name="buttons"></slot>
      </div>
    </div>
  </recess-modal>
</template>
  
<script>
export default {
    props: {  
        isVisible: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: null,
            required: true
        }
    }
}
</script>
  
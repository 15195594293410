export default class ParamUtils {
    /**
     * Build Get params from object
     * 
     * @param {object} params 
     * @returns 
     */
    static buildGETParams(params) {
        let paramsString = ""
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(params)) {
            paramsString += `${key}=${value}&`
        }
        return paramsString.slice(0, -1)
    }
}
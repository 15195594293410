import get from 'lodash/get'
import { getOplzClient } from './apiClient'

const baseUrl = '/legal-agreements'

const getLegalAgreements = consentRequired => {
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            return c
                .get(`${baseUrl}?consent-required=${!!consentRequired}`)
                .then(response => {
                    const legalAgreements = get(response, 'data', null)
                    resolve(legalAgreements)
                })
                .catch(err => reject(err))
        })
    })
}

export default {
    getLegalAgreements
}

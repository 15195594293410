<template>
  <recess-main-navigation v-if="$isAllowed('displayNavigationBar')">
    <recess-layout-static :size="1700">
      <div class="d-flex justify-content-between">
        <div class="c-recess-nav__list d-flex">
          <recess-navigation-item v-if="$isAllowed('displayHomeLink')">
            <router-link
              exact
              :to="{ name: 'home' }"
              class="c-recess-nav__link"
            >
              {{  navigationItemTexts.DASHBOARD }}
            </router-link>
          </recess-navigation-item>

          <recess-navigation-item v-if="$isAllowed('routeMyTeamPage')">
            <router-link
              :to="{ name: 'my-team', query: { 'my-team-tab': $route.query['my-team-tab'], tableDataQueries: $route.query.tableDataQueries} }"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.MY_TEAM }}
            </router-link>
          </recess-navigation-item>

          <recess-navigation-item v-if="$isAllowed('displayMyDevelopmentLink')">
            <router-link
              :to="{ name: 'my-development', query: { 'my-development-tab': $route.query['my-development-tab'], tableDataQueries: $route.query.tableDataQueries} }"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.MY_DEVELOPMENT }}
            </router-link>
          </recess-navigation-item>

          <recess-navigation-item v-if="$isAllowed('displaySocialLearning')">
            <router-link
              :to="{ name: 'search-posts' }"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.SOCIAL_LEARNING }}
            </router-link>
          </recess-navigation-item>

          <recess-navigation-item v-if="$isAllowed('checkDelegatedActions')">
            <router-link
              :to="{ name: 'my-actions', query: { 'my-actions-tab': $route.query['my-actions-tab'], tableDataQueries: $route.query.tableDataQueries} }"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.DELEGATION_ACTIONS }}
            </router-link>
          </recess-navigation-item>

          <recess-navigation-item v-if="$isAllowed('displayReportLink')">
            <router-link
              :to="{name: 'report-dashboard'}"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.REPORTS }}
            </router-link>
          </recess-navigation-item>
          <recess-navigation-item v-if="$isAllowed('displayProjectRequest') && $isAllowed('routeProjectRequestsPage')">
            <router-link
              :to="{name: 'my-project-requests'}"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.PROJECT_REQUESTS }}
            </router-link>
          </recess-navigation-item>
        </div>

        <div class="c-recess-nav__list d-flex">
          <recess-navigation-item v-if="$isAllowed('displaySearchLink')">
            <router-link
              :to="{ name: 'search'}"
            >
              <recess-icon
                icon="search"
                color="white"
                :height="15"
                :width="15"
              />
            </router-link>
          </recess-navigation-item>
          <recess-navigation-item 
            v-if="$isAllowed('displayContactInformation') && $isAllowed('displayContactInformationForStudents')"
            data-test="nav-contact"
          >
            <recess-dropdown 
              v-if="$isAllowed('displayContactSubmenu')"
              variant="secondary"
              :has-white-arrows="true"
            >
              <template
                #dropdown-title
              >
                <span class="u-textcolor-white">
                  {{ navigationItemTexts.CONTACT }}
                </span>
              </template>
              <template #dropdown-content> 
                <router-link
                  :to="{name: 'contact'}"
                  class="c-side-navigation__link u-textcolor-secondary"
                >
                  {{ navigationItemTexts.GENERAL_CONTACT }}
                </router-link>
                <recess-divider 
                  v-if="$isAllowed('displayABCRequestStandard')"
                  show-line
                />
                <router-link
                  v-if="$isAllowed('displayABCRequestStandard')"
                  :to="{name: 'abc-request'}"
                  class="c-side-navigation__link u-textcolor-secondary"
                >
                  {{ navigationItemTexts.ABC_REQUEST }}
                </router-link>
              </template>
            </recess-dropdown>
            
            <router-link
              v-else
              :to="{name: 'contact'}"
              data-test="nav-link-contact"
              class="c-recess-nav__link"
            >
              {{ navigationItemTexts.CONTACT }}
            </router-link>
          </recess-navigation-item>

          <recess-navigation-item v-if="$isAllowed('displayFAQLink')">
            <router-link
              :to="{ name: 'faq' }"
              class="c-recess-nav__link"
              data-test="faq-link"
            >
              {{ navigationItemTexts.FAQ }}
            </router-link>
          </recess-navigation-item>
        </div>
      </div>
    </recess-layout-static>
  </recess-main-navigation>
</template>

<script>
import routePerimeter from '../../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import delegatePerimeter from '../../../../perimeters/DelegatePerimeter'
import navigationItemTexts from '../../../../constants/navigationItemTexts'

export default {
    perimeters: [routePerimeter, featuresPerimeter, delegatePerimeter],
    setup() {
        return {
            navigationItemTexts
        }
    }
}
</script>

<template>
  <div v-if="$isAllowed('displayBudgets')">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetchingBudgets"
        overlay="white"
        color="primary"
      />
    </transition>
    
    <h1>Mijn budgetten</h1>
    <p>Op deze pagina ziet u uw budgetten die op dit moment actief zijn. Door op een budget te klikken kunt u alle wijzigingen en transacties binnen het budget inzien.</p>
    <transition name="fade">
      <div v-if="budgets && budgets.length">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="(header,index) in headers"
                :key="index"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="budget in budgets"
              :key="budget.id"
            >
              <recess-table-cell :data-label="headers[0].title">
                <span>{{ budget.name }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[1].title">
                <span>{{ formatPrice(budget.currentAmount) }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[2].title">
                <span>{{ budget.startDate ? formatDate(budget.startDate) : "-" }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[3].title">
                <span>{{ budget.endDate ? formatDate(budget.endDate) : "-" }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[4].title">
                <span>{{ getModifiedBy(budget) }}</span>
              </recess-table-cell>
              <recess-table-cell>
                <recess-button
                  title="Wijzigingen"
                  variant="tertiary"
                  :url="{ name: 'budget-details', params: { id: budget.id }, query: { name: encodeURIComponent(budget.name)}}"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <recess-divider />
      </div>
    </transition>
    <recess-alert
      v-if="(!budgets || !budgets.length) && !isFetchingBudgets"
      text="Er zijn op dit moment geen budgetten."
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { searchBudgets } from '../../../api/budgetClient'
import DateUtils from '../../../utils/DateUtils'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import priceFormatter from '../../../modules/priceFormatter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'

export default {
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent
    },
    data() {
        return {
            isFetchingBudgets: false,
            budgets: [],
            headers: [
                { key: 'name', title: 'Soort Budget' },
                { key: 'currentAmount', title: 'Resterend budget' },
                { key: 'startDate', title: 'Ingangsdatum budget' },
                { key: 'endDate', title: 'Einddatum budget' },
                { key: 'modifiedByUser', title: 'Laatste wijziging door' },
                { key: '', title: '' }
            ]
        }
    },
    computed: {
        ...mapState('userModule', ['user', 'isUserFetched']),
        ...mapGetters('accountModule', ['accountName'])
    },
    watch: {
        isUserFetched(newValue) {
            if (newValue && !this.budgets.length) {
                this.fetchBudgets()
            }
        }
    },
    created() {
        this.fetchBudgets()
    },
    methods: {
        getModifiedBy(budget) {
            if (budget?.isImported && (!budget?.budgetHistory?.length || budget?.budgetHistory?.length <= 1)) return 'Geïmporteerd'
            if (budget?.budgetTemplateId && (!budget?.budgetHistory?.length || budget?.budgetHistory?.length <= 1)) return this.accountName
            if (!budget?.modifiedByFullName) return 'Extern'
            return budget.modifiedByFullName
        },
        async fetchBudgets() {
            try {
                this.isFetchingBudgets = true
                if (!this.user?.userId) {
                    return
                }

                const oDataQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .setSort('endDate', 'asc')
                    .setIncludes('BudgetHistory')
                    .addFilter(
                        new ODataQueryFilter(
                            'userId',
                            ODataFilterOperations.EQUALS,
                            this.user.userId
                        )
                    )
                    .addDynamicFilter(`(endDate gt ${new Date().toISOString()} or endDate eq null)`)
                    .build()

                const response = await searchBudgets(oDataQuery)

                this.budgets = response?.items
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isFetchingBudgets = false
            }
        },
        formatDate: d => DateUtils.formatDate(d),
        formatPrice: priceFormatter.formatPrice
    }
}
</script>


<template>
  <div>
    <h4>Toestemming voor het delen van uw ontwikkelresultaten met uw werk- of opdrachtgever (indien aanwezig)*</h4>
    <p class="mb-0">Uw leidinggevende heeft aangegeven dat het delen van resultaten met uw werk- of opdrachtgever verplicht is. Geef hieronder aan met welke gegevens u instemt:</p>

    <recess-divider variant="xsmall" />

    <learning-result-sharing-choice
      :allow-sharing="hasSelectedLearningResults"
      @change-sharing-consent-choice="val => shareLearningResult = val"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="shareLearningResult">
        <recess-divider variant="xsmall" />
        <learning-result-sharing-item 
          :key="learningResultSharingType"
          :default-options="selectedLearningResultOptions"
          :learning-result="learningResultOptionsToShow" 
          @input="(optionKey, isOptionChecked) => toggleSelectedLearningResultOption(optionKey, isOptionChecked)" 
        />
      </div>
    </transition>

    <recess-divider variant="xsmall" />

    <div class="d-flex flex-column">
      <span class="u-text-small pb-2 pb-md-0"><strong>*</strong> {{ learningResultSharingConsentInfoText }}</span>
      <span class="u-text-small"><strong>**</strong> {{ learningResultSharingConsentsWarningText }}</span>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import LearningResultSharingUtils from '../../../../utils/LearningResultSharingUtils'
import LearningResultSharingItem from '../../LearningResultSharing/LearningResultSharingItem.vue'
import enrollmentLearningResultSharingOptions from '../../../../constants/enrollmentLearningResultSharingOptions'
import LearningResultSharingChoice from '../../../molecules/LearningResultSharing/LearningResultSharingChoice.vue'
import useStore from '../../../../composables/useStore'
import { learningResultSharingConsentInfo, learningResultSharingConsentWarning }  from '../../../../constants/learningResultSharingConsentsWarningMessage'

export default {
    components: {
        LearningResultSharingItem,
        LearningResultSharingChoice
    },
    setup() {
        const store = useStore()
        const learningResultSharingConsentInfoText = ref(learningResultSharingConsentInfo)
        const learningResultSharingConsentsWarningText = ref(learningResultSharingConsentWarning)
        const shareLearningResult = ref(true)

        const selectedLearningResultOptions = computed(() => {
            return store.state.verificationEnrollmentModule.selectedLearningResultOptions
        })

        const learningResultSharingType = computed(() => {
            return store.state.verificationEnrollmentModule.learningResultSharingType
        })
     
        watch(
            shareLearningResult,
            newVal => {
                if(newVal) {
                    store.commit('verificationEnrollmentModule/setDefaultApproverConsentsOnSelectedLearningResultOptions')
                } else {
                    store.commit('verificationEnrollmentModule/removeAllConsentsOnSelectedLearningResultOptions')
                }
            }
        )

        watch(
            selectedLearningResultOptions,
            newSelectedOptions => {
                if (Object.values(LearningResultSharingUtils.getStudentSelectedLearningResultSharingByType(learningResultSharingType.value, newSelectedOptions)).every(value => !value)) {
                    shareLearningResult.value = false
                }
            }
        )

        const hasSelectedLearningResults = computed(() => {
            return !!(selectedLearningResultOptions.value && Object.keys(selectedLearningResultOptions.value)?.length)
        })

        const learningResultOptionsToShow = computed(() => {
            return enrollmentLearningResultSharingOptions?.[learningResultSharingType.value]
        })
  
        return {
            learningResultSharingConsentInfoText,
            learningResultSharingConsentsWarningText,
            selectedLearningResultOptions,
            learningResultSharingType,
            shareLearningResult,
            hasSelectedLearningResults,
            learningResultOptionsToShow,
            toggleSelectedLearningResultOption: (optionKey, isOptionChecked) => store.commit('verificationEnrollmentModule/toggleSelectedLearningResultOption', optionKey, isOptionChecked)
        }
    }
}
</script>
<template>
  <div
    id="app"
    class="c-app"
  >
    <div v-if="shouldRender">
      <legal-agreements-check />
      <account-check />
      <impersonation-header />
      <header-component v-if="!$route.meta.hideHeaderAndFooter" />
      <!-- The router view renders the component attached to the current route, configured in router.js -->
      <transition
        name="fade"
        mode="out-in"
        :duration="150"
      >
        <router-view />
      </transition>
      <footer-component 
        v-if="!$route.meta.hideHeaderAndFooter"
        data-test="footer"
      />
    </div>
  </div>
</template>

<script>
import 'current-script-polyfill'
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import { getUserManager } from './modules/oidc-client'
import HeaderComponent from './components/organisms/HeaderComponent/HeaderComponent.vue'
import FooterComponent from './components/organisms/FooterComponent/FooterComponent.vue'
import LegalAgreementsCheck from './components/atoms/LegalAgreementsCheck/LegalAgreementsCheck.vue'
import ImpersonationHeader from './components/atoms/ImpersonationHeader/ImpersonationHeader.vue'
import AccountCheck from './components/atoms/AccountCheck/AccountCheck.vue'
import * as facets from './constants/facetKeys'
import * as expertiseAreas from './constants/expertiseArea'
import FacetUtils from './utils/FacetUtils'
import featuresPerimeter from './perimeters/featuresPerimeter'

export default {
    perimeters: [featuresPerimeter],
    // This component is the most generic root component within the application, used for all routes.
    // Don't add any specific code in here..
    name: 'App',
    components: {
        HeaderComponent,
        FooterComponent,
        LegalAgreementsCheck,
        ImpersonationHeader,
        AccountCheck
    },
    data() {
        return {
            shouldRender: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['expertiseArea', 'finalStartMomentDate'])
    },
    watch: {
        $route(to) {
            this.setCurrentRoutePageTitle(to)
        }
    },
    created() {
        const currentRoute = this.$router.currentRoute
        this.setCurrentRoutePageTitle(currentRoute)
    },
    mounted() {
        this.getUserManager()
    },
    methods: {
        ...mapActions('userModule', ['fetchUser', 'fetchUserFeatureSettings']),
        ...mapActions('accountModule', ['fetchAccount']),
        ...mapActions('facetsModule', ['fetchFacets']),
        ...mapActions('oleProvidersModule', ['fetchProviders']),
        ...mapActions('segmentModule', ['fetchSegmentImages']),
        setCurrentRoutePageTitle(route) {
            let metaTitle = get(route, 'meta.title', null)
            const useTypeSubtitle = get(route, 'meta.useTypeSubtitle', null)
            if (useTypeSubtitle) {
                const typeSubtitle = get(route, 'params.type', null)
                metaTitle = `${metaTitle} ${typeSubtitle}`
            }
            const title = metaTitle
                ? `${metaTitle} | Opleiding.nl Zakelijk`
                : 'Opleiding.nl Zakelijk'

            // Start setting the actual title
            if (this.$ssrContext) {
                this.$ssrContext.title = title
            } else if (document) {
                document.title = title
            }
        },
        async initializeState() {
            try {
                const includes = ['WishListItems', 'UserDepartment']
                const promises = [ this.fetchUser(includes), this.fetchAccount()]
                if (this.$isAllowed('displaySegmentImages')) promises.push(this.fetchSegmentImages())
              
                const [user, account] = await Promise.all(promises)
                this.setGtagCustomDimensions(user, account) 

                if (this.$isAllowed('displayAI')) await this.fetchUserFeatureSettings()
                this.fetchFooterAndHomePageFacets()
                this.fetchProviders()
            } catch (err) {
                console.error(err)
            } finally {
                this.shouldRender = true
            }
        },
        async fetchFooterAndHomePageFacets() {
            try {
                const facetsToFetch = [ facets.LEVELS, facets.VARIANTS ]
    
                switch (this.expertiseArea) {
                    case expertiseAreas.SEGMENTS: 
                        facetsToFetch.push(facets.SEGMENTS)
                        break
                    default:
                        facetsToFetch.push(facets.SEGMENTS)
                        break
                }
    
                await this.fetchFacets({ facetsToFetch: FacetUtils.setCount(100, ...facetsToFetch), includeFinalStartMomentDateFilter: !!this.finalStartMomentDate })
            } catch (error) {
                throw new Error("Failed to fetch facets", { cause: error })
            }
        },
        getUserManager() {
            const requiresAuth = this.$router.currentRoute.matched.some(record => {
                if (record != null && record.meta != null && record.meta.requiresAuth != null)
                    return record.meta.requiresAuth
                return false
            })

            if (requiresAuth) {
                getUserManager()
                    .getUser()
                    .then(user => {
                        if (user) {
                            this.initializeState()
                        }
                    })
            } else {
                this.shouldRender = true
            }
        },
        setGtagCustomDimensions(user, account) {
            this.$gtag.customMap(
                {
                    'dimension1': 'roles',
                    'dimension2': 'account',
                    'dimension3': 'company',
                    'dimension4': 'job_title',
                    'dimension5': 'course_id',
                    'dimension6': 'course_name',
                    'dimension7': 'external_id'
                }
            )

            if(user && account) {
                // Trigges login event
                this.$gtag.event('login', {
                    roles: user.roles,
                    account: account.name,
                    company: user.company?.name,
                    job_title: user.jobName
                })

                // Set custom user_properties
                this.$gtag.set('user_properties',  {
                    roles: user.roles,
                    account: account.name,
                    company: user.company?.name,
                    job_title: user.jobName
                })
            }
        }
    }
}
</script>
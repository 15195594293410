export const MY_DEVELOPMENT_ENROLLMENTS = 0
export const MY_DEVELOPMENT_ACTIVE_COURSES = 1
export const MY_DEVELOPMENT_STUDYCONTRACTS = 2
export const MY_DEVELOPMENT_WISHLIST = 3
export const MY_DEVELOPMENT_LEARNING_PATHS = 4
export const MY_DEVELOPMENT_PERMANENT_EDUCATIONS = 5
export const MY_DEVELOPMENT_RECENTLY_VISITED_COURSES = 6

export default {
    MY_DEVELOPMENT_ENROLLMENTS,
    MY_DEVELOPMENT_ACTIVE_COURSES,
    MY_DEVELOPMENT_STUDYCONTRACTS,
    MY_DEVELOPMENT_WISHLIST,
    MY_DEVELOPMENT_LEARNING_PATHS,
    MY_DEVELOPMENT_PERMANENT_EDUCATIONS,
    MY_DEVELOPMENT_RECENTLY_VISITED_COURSES
}

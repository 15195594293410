import { getOplzClient } from './apiClient'

const baseUrl = '/ole-providers'

/**
 * Return list of ole providers
 * 
 * @returns {Promise<{ providerId: string }[]>}
 */
export async function getAll() {
    const client = await getOplzClient()
    return client.get(baseUrl).then(res => res?.data ?? [])
}

export default {
    getAll
}
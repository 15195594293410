<template>
  <h3
    class="c-search-result__title"
    data-test="search-result-title"
  >
    <span
      class="h2"
      data-test="search-course-count"
    >{{ count }}</span>
    Zoekresultaten
    <span
      v-if="searchTitle"
      class="h3"
    >"{{ searchTitle }}"</span>
  </h3>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('searchModule', ['count']),
        ...mapGetters('searchModule', ['searchTitle'])
    }
}
</script>

<template>
  <div>
    <span class="font-weight-bold">Toestemming voor het delen van uw ontwikkelresultaten met uw werk- of opdrachtgever *</span>
  
    <recess-divider variant="xsmall" />
  
    <learning-result-sharing-choice
      @change-sharing-consent-choice="val => agreesToShare = val"
    />
    
    <span
      v-if="isError"
      class="u-error-text p-0 m-0"
      data-test="error-text"
    >Kies een optie voor het delen van ontwikkelresultaten</span>
  
    <transition name="fade">
      <div v-if="agreesToShare">
        <recess-divider variant="xsmall" />
  
        <learning-result-sharing-item 
          v-for="(learningResult, index) in learningResults" 
          :key="index" 
          :default-options="options"
          :learning-result="learningResult"
          class="c-enrollment-step__learning-result-item"
          @input="(optionKey, isChecked) => setOptionValue(optionKey, isChecked)"
        />
      </div>
    </transition>
  
    <recess-divider variant="xsmall" />
  
    <div class="d-flex flex-column">
      <span class="u-text-small pb-2 pb-md-0"><strong>*</strong> {{ learningResultSharingConsentInfoText }}</span>
      <span class="u-text-small"><strong>**</strong> {{ learningResultSharingConsentsWarningText }}</span>
    </div>
  
    <enrollment-step-buttons  
      v-on="{ ...$listeners, 'next-step': confirmStep }" 
    />
  </div>
</template>
  
<script>
import {  ref, watch } from 'vue'
import LearningResultSharingItem from '../../../LearningResultSharing/LearningResultSharingItem.vue'
import enrollmentLearningResultSharingOptions from '../../../../../constants/enrollmentLearningResultSharingOptions'
import EnrollmentStepButtons from '../EnrollmentStepButtons.vue'
import useStore from '../../../../../composables/useStore'
import learningResultSharingOptionsTypes from '../../../../../constants/learningResultSharingOptionsTypes'
import LearningResultSharingChoice from '../../../../molecules/LearningResultSharing/LearningResultSharingChoice.vue'
import { learningResultSharingConsentInfo, learningResultSharingConsentWarning }  from '../../../../../constants/learningResultSharingConsentsWarningMessage'

export default {
    components: {
        LearningResultSharingItem,
        EnrollmentStepButtons,
        LearningResultSharingChoice
    },
    emits: [ 'next-step' ],
    setup(_, { emit }) {
        const learningResultSharingConsentInfoText = ref(learningResultSharingConsentInfo)
        const learningResultSharingConsentsWarningText = ref(learningResultSharingConsentWarning)
        const agreesToShare = ref(undefined)
        const isError = ref(false)
        const store = useStore()
        const options = ref({
            [learningResultSharingOptionsTypes.LEGAL.DIPLOMACONSENT]: true,
            [learningResultSharingOptionsTypes.LEGAL.COMPLETIONATRISKCONSENT]: true,
            [learningResultSharingOptionsTypes.LEGAL.PASSEDEXAMCONSENT]: true,
            [learningResultSharingOptionsTypes.LEGAL.PROGRESSPERCENTAGECONSENT]: true,
            [learningResultSharingOptionsTypes.EMPLOYER.DIPLOMACONSENT]: true,
            [learningResultSharingOptionsTypes.EMPLOYER.COMPLETIONATRISKCONSENT]: true,
            [learningResultSharingOptionsTypes.EMPLOYER.PASSEDEXAMCONSENT]: true,
            [learningResultSharingOptionsTypes.EMPLOYER.PROGRESSPERCENTAGECONSENT]: true
        })
  
        const learningResults = ref([
            enrollmentLearningResultSharingOptions.LEGAL,
            enrollmentLearningResultSharingOptions.EMPLOYER
        ])

  
        watch(
            agreesToShare,
            async newVal => {
                isError.value = typeof newVal === 'undefined'
                Object.keys(options.value)?.forEach(key => {
                    options.value[key] = newVal
                })

                store.commit('enrollmentModule/setAgreesToShare', newVal)
                store.commit('enrollmentModule/setSelectedLearningResultOptions', options.value)
            }
        )
  
        const confirmStep = () => {
            if(typeof agreesToShare.value === 'undefined') {
                isError.value = true
            } else {
                isError.value = false
                emit('next-step')
            }
        }

        const setOptionValue = (optionKey, isOptionChecked) => {
            store.commit('enrollmentModule/toggleSelectedLearningResultOption', optionKey, isOptionChecked)
        }
  
        return {
            learningResultSharingConsentInfoText,
            learningResultSharingConsentsWarningText,
            learningResults,
            agreesToShare,
            isError,
            confirmStep,
            setOptionValue,
            options
        }
    }
}
</script>
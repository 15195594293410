var render = function render(){var _vm=this,_c=_vm._self._c;return _c('recess-layout-static',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading)?_c('loader-component',{attrs:{"overlay":"white","color":"primary","is-fixed":""}}):_vm._e()],1),_c('recess-divider'),_c('h1',[_vm._v("Wijzigen "+_vm._s(_vm.learningResultText))]),(_vm.isOwnPEReminder)?_c('p',[_vm._v(" Pas op deze pagina uw eerder ingestelde herinnering aan. Als u uw vervaldatum wijzigt naar een datum in de toekomst die verder ligt dan de datum van vandaag plus de herinneringstermijn, dan ontvangt u pas weer herinneringen zodra de gekozen termijn weer bereikt is. ")]):_c('p',[_vm._v(" Pas op deze pagina de bestaande herinneringen van uw collega's aan. Als u een vervaldatum wijzigt naar een datum in de toekomst die verder ligt dan de datum van vandaag plus de herinneringstermijn, dan ontvangt uw collega weer herinneringen zodra de gekozen termijn weer bereikt is. ")]),_c('transition',{attrs:{"name":"fade"}},[(
                _vm.$isAllowed('displayPermanentEducationDetails', _vm.peUsers) && !_vm.isFetchingPeReminder
            )?_c('div',[_c('section',{staticClass:"row"},[_c('recess-input',{ref:"courseNewNameInput",staticClass:"col-lg-4 col-md-6 col-12 mb-3 mb-md-0",attrs:{"disabled":!_vm.$isAllowed('editPermanentEducation', _vm.peUsers),"label-text":"Ontwikkelactiviteit *","placeholder-text":"Ontwikkelactiviteit","data-test":"course-name-input","error-message":_vm.getErrorMessage('courseNewName')},on:{"input":_vm.resetAlerts},model:{value:(_vm.courseNewName),callback:function ($$v) {_vm.courseNewName=$$v},expression:"courseNewName"}}),_c('recess-input',{staticClass:"col-lg-4 col-md-6 col-12 mb-3 mb-md-0",attrs:{"value":_vm.created,"label-text":"Aangemaakt op","data-test":"pe-creation-date","disabled":""}}),_c('recess-input',{staticClass:"col-lg-4 col-md-6 col-12",attrs:{"value":_vm.peUsers.createdByUserFullName,"label-text":"Toegevoegd door","data-test":"pe-created-by-user","disabled":""}}),(!_vm.isOwnPEReminder)?_c('recess-input',{staticClass:"col-lg-4 col-md-6 col-12 mt-3",attrs:{"label-text":"Medewerker e-mailadres","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e()],1),_c('recess-divider',{attrs:{"show-line":"","variant":"large"}}),(
                    _vm.viewItems.length || _vm.$isAllowed('displayPermanentEducationDetails', _vm.peUsers)
                )?_c('section',{staticClass:"mb-3"},[_c('h3',[_vm._v("Behaalde resultaten")]),_c('recess-card',{attrs:{"variant":"variant1"}},[_c('recess-button',{attrs:{"data-test":"create-sub-record-btn","title":"Nieuw resultaat","variant":"primary"},on:{"click":function($event){_vm.showUploadCertificate = true}}})],1),_c('recess-divider',{attrs:{"variant":"xsmall"}}),_c('recess-modal',{attrs:{"show-modal":_vm.showUploadCertificate},on:{"close":function($event){_vm.showUploadCertificate = false}},scopedSlots:_vm._u([{key:"modal-body",fn:function(){return [_c('upload-sub-record-component',{attrs:{"show-upload":""},on:{"addSubRecord":_vm.addSubRecord}})]},proxy:true}],null,false,3003683356)}),(_vm.viewItems.length)?_vm._l((_vm.items),function(subRecord,index){return _c('div',{key:index},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
                                    _vm.showItems &&
                                    _vm.$isAllowed('displayPEReminderSubRecord', subRecord)
                                )?_c('sub-record-component',{attrs:{"assigned-user-id":_vm.peUsers.userId,"sub-record":subRecord,"show-popup":true,"show-save-button":_vm.$isAllowed('editPermanentEducation', {
                                        userId: _vm.peUsers.userId,
                                        createdByUserId: subRecord.createdByUserId,
                                        isImported: subRecord.isImported
                                    }),"show-upload":_vm.$isAllowed('uploadPEReminderCertificate', {
                                        subRecord,
                                        assignedUserId: _vm.peUsers.userId
                                    }),"sub-record-index":index,"data-test":"pe-sub-record"},on:{"delete-sub-record":_vm.removeSubRecord,"save":_vm.updateSubRecord,"remove-certificate":_vm.removeCertificate}}):_vm._e()],1)],1)}):_vm._e()],2):_vm._e(),_c('section',{staticClass:"d-flex flex-column flex-md-row justify-content-md-between"},[_c('recess-button',{staticClass:"w-100 w-md-auto",attrs:{"url":_vm.backUrl,"title":"Terug","variant":"tertiary"}}),(_vm.$isAllowed('editPermanentEducation', _vm.peUsers))?_c('recess-button',{staticClass:"w-100 w-md-auto mt-3 mt-md-0",attrs:{"title":"Opslaan","variant":"primary","data-test":"submit-btn"},nativeOn:{"click":function($event){return _vm.updatePe.apply(null, arguments)}}}):_vm._e()],1),(_vm.isSuccess || _vm.errorMessage)?_c('section',{staticClass:"mt-4"},[(_vm.isSuccess)?_c('recess-alert',{attrs:{"type":"success","text":"Uw herinnering is succesvol opgeslagen."}}):_vm._e(),(_vm.errorMessage)?_c('recess-alert',{class:{ 'mt-4': _vm.isSuccess },attrs:{"type":_vm.errorMessage.type,"text":_vm.errorMessage.message}}):_vm._e()],1):_vm._e()],1):_vm._e()]),_c('recess-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <recess-divider />
    <recess-card bordered>
      <h4>Online ontwikkelactiviteiten van GoodHabitz</h4>

      <!-- Randstad & Tempo Team text -->
      <span v-if="isRandstad || isTempoTeam">
        Ontdek de grenzeloze mogelijkheden van leren met de online trainingen van
        GoodHabitz! Of je nu wilt werken aan je veerkracht, Excel-skills wilt verbeteren of
        je Nederlands naar een hoger niveau wilt tillen, bij GoodHabitz vind je alles wat je
        nodig hebt. En het beste van alles? Je kunt alle trainingen van GoodHabitz helemaal
        gratis volgen. Klik
        <a
          :href="
            isRandstad
              ? 'https://my.goodhabitz.com/sso/randstad'
              : 'https://my.goodhabitz.com/sso/tempoteam'
          "
          target="_blank"
          rel="noopener"
        >hier</a>
        om direct naar GoodHabitz te gaan en start vandaag nog met jouw persoonlijke
        ontwikkeling.
      </span>

      <!-- Yacht text -->
      <span v-if="isYacht">
        Ontdek de grenzeloze mogelijkheden van leren met de online trainingen van
        GoodHabitz! Of je nu wilt werken aan je persoonlijk leiderschap, gedragsverandering
        of je lean of Excel-skills wilt verbeteren, bij GoodHabitz vind je alles wat je
        nodig hebt. En het beste van alles? Je kunt alle trainingen van GoodHabitz helemaal
        gratis volgen. Klik
        <a 
          href="https://my.goodhabitz.com/sso/yacht" 
          target="_blank" 
          rel="noopener"
        >hier</a>
        om direct naar GoodHabitz te gaan en start vandaag nog met jouw persoonlijke
        ontwikkeling.
      </span>
    </recess-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('accountModule', ['accountCodeName']),
        isRandstad() {
            return this.accountCodeName.toUpperCase() === 'RNL'
        },
        isTempoTeam() {
            return this.accountCodeName.toUpperCase() === 'TTG'
        },
        isYacht() {
            return this.accountCodeName.toUpperCase() === 'YGN'
        }
    }
}
</script>

import segmentClient from '../../api/segmentClient'

const segmentModule = {
    namespaced: true,
    state() {
        return { 
            segments: []
        }
    },
    mutations: {
        setSegments(state, newSegments) {
            state.segments = newSegments
        }
    },
    actions: {
        async fetchSegmentImages( { commit, state }) {
            if(state.segments?.length) return 
            
            const response = await segmentClient.getSegmentImages()
            commit('setSegments', response?.data)
        }
    }
}

export default segmentModule
<template>
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
    <title>E68F078C-A84B-4A9C-A68E-8F0B4B6CD7AA</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Styleguide"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Styleguide---v2"
        transform="translate(-1420.000000, -3336.000000)"
        fill="#2E4557"
      >
        <g
          id="Buttons"
          transform="translate(160.000000, 3246.000000)"
        >
          <path
            id="cross"
            :class="[`u-fill--${color}`]"
            d="M1269.0625,96.9375 C1269.20833,97.0833341 1269.28125,97.2604156 1269.28125,97.46875 C1269.28125,97.6770844 1269.20833,97.8541659 1269.0625,98 L1268,99.0625 C1267.85417,99.2083341 1267.67708,99.28125 1267.46875,99.28125 C1267.26042,99.28125 1267.08333,99.2083341 1266.9375,99.0625 L1264.64062,96.765625 L1262.34375,99.0625 C1262.19792,99.2083341 1262.02083,99.28125 1261.8125,99.28125 C1261.60417,99.28125 1261.42708,99.2083341 1261.28125,99.0625 L1260.21875,98 C1260.07292,97.8541659 1260,97.6770844 1260,97.46875 C1260,97.2604156 1260.07292,97.0833341 1260.21875,96.9375 L1262.51562,94.640625 L1260.21875,92.34375 C1260.07292,92.1979159 1260,92.0208344 1260,91.8125 C1260,91.6041656 1260.07292,91.4270841 1260.21875,91.28125 L1261.28125,90.21875 C1261.42708,90.0729159 1261.60417,90 1261.8125,90 C1262.02083,90 1262.19792,90.0729159 1262.34375,90.21875 L1264.64062,92.515625 L1266.9375,90.21875 C1267.08333,90.0729159 1267.26042,90 1267.46875,90 C1267.67708,90 1267.85417,90.0729159 1268,90.21875 L1269.0625,91.28125 C1269.20833,91.4270841 1269.28125,91.6041656 1269.28125,91.8125 C1269.28125,92.0208344 1269.20833,92.1979159 1269.0625,92.34375 L1266.76562,94.640625 L1269.0625,96.9375 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>


<script>
export default {
    props: {
        color: {
            type: String,
            validator(value) {
                return ['primary', 'secondary', 'black'].includes(value)
            },
            default: 'black'
        }
    }
}
</script>


<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>

    <div v-if="enrollment">
      <h4 class="u-textcolor-primary">Authorisatie</h4>
      <table
        aria-label="Authorization detail"
        class="w-100"
      >
        <tbody>
          <tr v-if="assignedApproverName">
            <th
              scope="row"
              class="text-left pt-3"
            >
              Toegewezen beoordelaar
            </th>
            <td class="w-50">
              {{ assignedApproverName }}
              <span v-if="assignedApproverEmail">({{ assignedApproverEmail }})</span>
            </td>
          </tr>
          <tr v-if="$isAllowed('displayCostCenter')">
            <th
              scope="row"
              class="text-left pt-3"
            >
              Kostenplaats *
            </th>
            <td>
              <recess-input
                v-model="formData.costCenter"
                data-test="cost-center-input"
                :disabled="isCostCenterDisabled"
                :error-message="getErrorMessage('costCenter')"
              />
            </td>
          </tr>
          <tr v-if="$isAllowed('displayPONumber')">
            <th
              scope="row"
              class="text-left pt-3"
            >
              PO nummer {{ isPurchaseOrderMandatoryForFirstApproval ? '*' : null }}
            </th>
            <td>
              <recess-input
                v-model="formData.purchaseOrderNumber"
                :disabled="isPoNumberInputDisabled"
                data-test="po-number-input"
                :error-message="getErrorMessage('purchaseOrderNumber')"
              />
            </td>
          </tr>
          <tr v-if="secondApprovalNeeded">
            <th
              scope="row"
              class="text-left pt-3"
            >
              Kies tweede goedkeurder *
            </th>
            <td>
              <recess-select
                v-model="formData.assignedSecondApproverId"
                data-test="second-approver-input"
                :error-message="getErrorMessage('assignedSecondApproverId')"
                :options="secondApproverOptions"
              />
            </td>
          </tr>
          <tr v-if="showStudyContarctInput">
            <th
              scope="row"
              class="text-left pt-3"
            >
              Studieovereenkomst *
            </th>
            <td>
              <!-- Select study contract -->
              <recess-select
                v-model="formData.studyContract"
                :error-message="getErrorMessage('studyContract')"
                :prefill-with-first-option=false
                :options="studyContractOptions"
                data-test="study-contract-input"
                @input="getStudyContractTemplateDocument"
              />
            </td>
          </tr>
          <tr
            v-if="alternativeStudyContractFlow"
          >
            <th
              scope="row"
              class="text-left pt-3"
            ></th>
            <td>
              <!-- Download study contract template if applicable -->
              <div
                class="w-100 w-md-auto u-position-relative d-inline-block pt-3"
              >
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <recess-loader
                    v-if="isFetchingStudyContractTemplateDocument"
                    overlay="white"
                    color="primary"
                    spinner-size="small"
                  />
                </transition>
                <recess-button
                  :disabled="isDownloadButtonDisabled"
                  variant="tertiary"
                  title="Download bestand"
                  class="col-12 col-md-auto align-self-end"
                  target="_blank"
                  :url="studyContractTemplateDocument"
                />
              </div>
            </td>
          </tr>
          <tr v-if="showKeyAccounts">
            <th
              scope="row"
              class="text-left pt-3"
            >
              Key-Accounts {{ keyAccountRequired ? '*' : null }}
            </th>
            <td>
              <!-- Select Key acccount -->
              <recess-select
                v-model="formData.keyAccountId"
                :options="keyAccountsOptions"
                :select-option-text="selectKeyAccountOptionText"
                data-test="key-account-input"
                :error-message="getErrorMessage('keyAccountId')"
              />
            </td>
          </tr>
          <tr
            v-if="isSubsidyEnabled"
          >
            <th
              scope="row"
              class="d-flex"
            >
              <span class="mr-2">Subsidie</span>
              <recess-tooltip class="d-flex justify-content-center">
                <template #content>
                  <span class="d-block u-max-width-500">
                    Indien voor deze inschrijving subsidie is- of wordt aangevraagd, activeer dan deze toggle. Hiermee wordt het duidelijk op de rapportage dat aan deze inschrijving subsidie verbonden is.
                  </span>
                </template>
              </recess-tooltip>
            </th>
            <td class="pt-3">
              <recess-toggle-input
                v-model="formData.isSubsidized"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <recess-divider />

      <template v-if="isLearningResultEnabled && !isCoordinatorEnrollment">
        <h4 class="u-textcolor-primary">Delen van ontwikkelresultaten met werk- of opdrachtgever (indien aanwezig)</h4>
  
        <learning-result-sharing-team-leader-form 
          data-test="learning-result-form"
          @selected-learning-result-options="value => teamLeaderLearningResultConsents = value" 
          @sharing-type-selected="value => learningResultSharingType = value"
          @toggle-learning-result-option="({optionKey, isOptionChecked}) => toggleLearningResultSharingOption(optionKey, isOptionChecked)" 
        />

        <recess-divider />
      </template>

      <div
        v-if="!isApproving"
        class="d-flex flex-column flex-md-row"
      >
        <recess-button
          title="Annuleren"
          variant="tertiary"
          class="mb-3 mb-md-0"
          @click="$emit('redirect')"
        />
        <div class="d-flex justify-content-end flex-grow-1 flex-column flex-md-row">
          <recess-button
            v-if="explanationDocumentUrl"
            data-test="download-explanation-button"
            variant="tertiary"
            class="mb-3 mb-md-0 mr-md-3"
            title="Download uitleg"
            target="_blank"
            :url="explanationDocumentUrl"
          />
          <recess-button
            data-test="reject-button"
            class="mb-3 mb-md-0 mr-md-3"
            title="Afkeuren"
            variant="tertiary"
            @click="$emit('reject')"
          />
          <recess-button
            :disabled="!enrollmentHasValidStartMoments"
            data-test="approve-button"
            title="Goedkeuren"
            variant="primary"
            @click="approve"
          />
        </div>
      </div>
      <recess-alert
        v-if="isError && errorMessage && errorMessage.length"
        data-test="first-approval-error"
        type="error"
        :text="errorMessage"
        class="mt-3 mb-3"
      />
      <enrollment-confirmation-modal
        :show-team-leader-enrollment-confirmation-text="showTeamLeaderEnrollmentConfirmationText"
        @close="$emit('redirect')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import learningResultSharingTypeEnum from '../../../../constants/learningResultSharingTypeEnum'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import EnrollmentClient from '../../../../api/enrollmentClient'
import keyAccountClient from '../../../../api/keyAccountClient'
import userClient from '../../../../api/userClient'
import studyContractTemplateClient from '../../../../api/studyContractTemplateClient'
import ODataQueryBuilder from '../../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../../api/OData/odataQueryFilter'
import ODataFilterOperations from '../../../../api/OData/odataFilterOperations'
import DataQueryBuilder from '../../../../api/DataQuery/dataQueryBuilder'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import * as approvalNeededTypes from '../../../../constants/approvalNeededTypes'
import odataSortingDirections from '../../../../api/OData/odataSortingDirections'
import { errorMessages } from '../../../../constants/errorMessages'
import EnrollmentConfirmationModal from '../EnrollmentConfirmationModal.vue'
import LearningResultSharingTeamLeaderForm from '../../LearningResultSharing/LearningResultSharingTeamLeaderForm.vue'
import { COORDINATOR } from '../../../../constants/roleIds'

export default {
    components: {
        LoaderComponent,
        EnrollmentConfirmationModal,
        LearningResultSharingTeamLeaderForm
    },
    perimeters: [featuresPerimeter],
    validations() {
        return {
            formData: {
                purchaseOrderNumber: {
                    required: requiredIf(() => 
                        this.isPurchaseOrderMandatoryForFirstApproval && this.$isAllowed('displayPONumber')
                    )
                },
                costCenter: {
                    required: requiredIf(() =>  this.$isAllowed('displayCostCenter'))
                },
                studyContract: { 
                    required: requiredIf(() => this.showStudyContarctInput) 
                },
                assignedSecondApproverId: {
                    required: requiredIf(() => 
                        this.secondApprovalNeeded && this.secondApprovers.length
                    )
                },
                keyAccountId: {
                    required: requiredIf(() => {
                        return this.showKeyAccounts && this.keyAccountRequired
                    })
                }
            }
        }
    },
    data() {
        return {
            learningResultSharingType: null,
            teamLeaderLearningResultConsents: null,
            isFetchingStudyContracts: false,
            isGettingApprovalRequired: false,
            isFetchingSecondApprovers: false,
            showTeamLeaderEnrollmentConfirmationText: false,
            isApproving: false,
            studyContractOptions: [{ value: 'notApplicable', displayText: 'Niet van toepassing' }],
            secondApprovalNeeded: false,
            secondApprovers: [],
            formData: {
                purchaseOrderNumber: null,
                costCenter: null,
                studyContract: null,
                assignedSecondApproverId: null,
                keyAccountId: null,
                isSubsidized: false
            },
            validationErrorMessages: {
                required: {
                    purchaseOrderNumber: 'PO nummer is verplicht',
                    studyContract: 'Studieovereenkomst is verplicht',
                    assignedSecondApproverId: 'Tweede Goedkeurder is verplicht',
                    costCenter: 'Kostenplaats is verplicht',
                    keyAccountId: 'Key-Account is verplicht'
                }
            },
            keyAccounts: []
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollment', 'isFetchingStudyContractTemplateDocument', 'studyContractTemplateDocument', 'isError']),
        ...mapGetters('userModule', ['costCenter']),
        ...mapGetters('enrollmentModule', ['enrollmentHasValidStartMoments', 'errorMessage']),
        ...mapGetters('accountModule', [
            'isPurchaseOrderMandatoryForFirstApproval', 
            'alternativeStudyContractFlow', 
            'accountId', 
            'isSubsidyEnabled',
            'explanationDocumentUrl', 
            'prefillTLCostCenter',
            'isCostCenterEditable', 
            'keyAccountRequired',
            'teamLeaderEnrollmentConfirmationText',
            'isLearningResultEnabled'
        ]),
        isCoordinatorEnrollment() {
            return this.enrollment?.roleId === COORDINATOR
        },
        keyAccountsOptions() {
            return this.keyAccounts?.length ? [{ value: 'notApplicable', displayText: "Geen key-account"}, ...(this.keyAccounts ?? [])
                .filter(keyAccount => keyAccount.isEnabled)
                .map(keyAccount => {
                    return {
                        value: keyAccount.id,
                        displayText: keyAccount.name
                    }
                })] : []
        },
        isCostCenterDisabled() {
            return !this.$isAllowed('displayCostCenter') 
            || (!this.isCostCenterEditable 
              && (!!this.enrollment.costCenter?.length 
              || !!(this.prefillTLCostCenter ? this.costCenter : this.enrollment?.user?.costCenter)?.length))
        },
        secondApproverOptions() {
            return this.secondApprovers?.map(secondApprover => {
                const displayText = `${secondApprover.firstName || ''} 
                ${secondApprover.middleName || ''} 
                ${secondApprover.lastName || ''} 
                (${secondApprover.emailAddress || '-'})`
                return {
                    value: secondApprover.userId,
                    displayText
                }
            }) ?? []
        },
        isLoading() {
            return (
                this.isFetchingStudyContracts ||
                this.isFetchingSecondApprovers ||
                this.isGettingApprovalRequired ||
                this.isApproving
            )
        },
        isDownloadButtonDisabled() {
            return !this.formData.studyContract || this.formData.studyContract === 'notApplicable' || this.isFetchingStudyContractTemplateDocument
        },
        assignedApproverName() {
            return this.enrollment?.assignedApprover?.fullName
        },
        assignedApproverEmail() {
            return this.enrollment?.assignedApprover?.emailAddress
        },
        showKeyAccounts() {
            return this.keyAccounts?.length && this.$isAllowed('displayKeyAccounts')
        },
        selectKeyAccountOptionText() {
            return this.keyAccountRequired && !this.formData.keyAccountId ? " " : null 
        },
        showStudyContarctInput(){
            return this.studyContractOptions?.length > 1
        },
        isRequiredByEmployerLearningResultSharingType() {
            return this.learningResultSharingType === learningResultSharingTypeEnum.Employer
        },
        selectedStudyContractTemplateId() {
            return !this.alternativeStudyContractFlow && this.formData.studyContract !== 'notApplicable' ? this.formData.studyContract : null
        },
        isPoNumberInputDisabled() {
            return !!this.enrollment?.purchaseOrderNumber
        }
    },
    watch: {
        // fetch second approver when second approval is needed
        async secondApprovalNeeded(newValue) {
            if (newValue) {
                await this.fetchSecondApprovers()
            }
            else {
                this.formData.assignedSecondApproverId = null
            }
        },
        // If the cost center is not already prefilled and the setting is on,
        // prefill it with the costCenter of the logged in teamleader.
        enrollment: {
            handler() {
                // If the cost center is already stored in the enrollment, set that one
                if(this.enrollment?.costCenter?.length) {
                    this.formData.costCenter = this.enrollment.costCenter
                } else {
                    // If the cost center is NOT already stored in the enrollment, check the prefillTLCostCenter setting.
                    this.formData.costCenter = this.prefillTLCostCenter ? this.costCenter : this.enrollment?.user?.costCenter
                }

                // If the purchase order number is already stored in the enrollment, set that one
                if(this.enrollment?.purchaseOrderNumber?.length) {
                    this.formData.purchaseOrderNumber = this.enrollment.purchaseOrderNumber
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        this.resetState()
    },
    async mounted() {
        const promises = [this.checkApprovalRequired(),  this.fetchStudyContracts(), this.fetchKeyAccounts()]
        await Promise.all(promises)
    },
    methods: {
        ...mapActions('enrollmentModule', ['fetchStudyContractTemplateDocument']),
        ...mapMutations('enrollmentModule', ['resetState', 'setErrorMessage', 'setIsError']),
        toggleLearningResultSharingOption(optionKey, isOptionChecked) {
            this.teamLeaderLearningResultConsents[optionKey] = isOptionChecked
        },
        async checkApprovalRequired() {
            try {
                this.isGettingApprovalRequired = true
                const response = await EnrollmentClient.checkApprovalRequired(this.enrollment?.eduMSCourseId, this.enrollment?.assortmentId, null, this.enrollment?.enrollmentId)
                if(response === approvalNeededTypes.SECONDAPPROVAL) this.secondApprovalNeeded = true
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isGettingApprovalRequired = false
            }
        },
        async fetchSecondApprovers() {
            try {
                this.isFetchingSecondApprovers = true
                this.secondApprovers = await userClient.getSecondApprovers()
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingSecondApprovers = false
            }
        },
        async fetchKeyAccounts() {
            try {
                const dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 999)
                    .setSort("name", odataSortingDirections.ASC)
                    .addFilter(new ODataQueryFilter('isEnabled', ODataFilterOperations.EQUALS, true))
                    .and()
                    .addFilter(new ODataQueryFilter('accountId', ODataFilterOperations.EQUALS, this.accountId))
                    .build()
                
                const keyAccountSearch = await keyAccountClient.searchKeyAccounts(dataQuery) 
                this.keyAccounts = keyAccountSearch?.items
            } catch (error) {
                throw new Error(error)
            }
        },
        async fetchStudyContracts() {
            try {
                this.isFetchingStudyContracts = true
                const dataQuery = new DataQueryBuilder().setPagination(0, 999).build()
                const response = await studyContractTemplateClient.getStudyContractTemplates(dataQuery)
              
                if (response?.items?.length) {
                    const mappedStudyContracts = response.items.map(studyContract => {
                        return {
                            displayText: studyContract.name,
                            value: studyContract.studyContractTemplateId
                        }
                    })
                    this.studyContractOptions = [
                        ...this.studyContractOptions,
                        ...mappedStudyContracts
                    ]
                }
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingStudyContracts = false
            }
        },
        async approve() {
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                   
                    return
                }

                this.isApproving = true
                
                // Show validation alert message if there aren't second approvers
                if (this.secondApprovalNeeded && !this.formData.assignedSecondApproverId) {
                    this.setErrorMessage(errorMessages.MissingSecondApprover)
                    this.setIsError(true)
                    return
                }

                const postData = {
                    enrollmentId: this.enrollment?.enrollmentId,
                    purchaseOrderNumber: this.formData.purchaseOrderNumber,
                    costCenter: this.formData.costCenter,
                    studyContractTemplateId: this.selectedStudyContractTemplateId,
                    keyAccountId: this.formData.keyAccountId !== 'notApplicable' ? this.formData.keyAccountId : null,
                    isSubsidized: this.formData.isSubsidized
                }

                if (this.isLearningResultEnabled && !this.isCoordinatorEnrollment) postData.enrollmentTeamLeaderLearningResult = this.getSelectedTeamLeaderLearningResult()
                if (this.secondApprovalNeeded) postData.assignedSecondApproverId = this.formData.assignedSecondApproverId

                const response = await EnrollmentClient.approveEnrollment(postData)
                if (!response) throw new Error()
                  
                if (this.teamLeaderEnrollmentConfirmationText) this.showTeamLeaderEnrollmentConfirmationText = true 
                else this.$emit('redirect')
            } catch (error) {
                const errorKey = Object.keys(errorMessages).find(
                    key => key === error?.response?.data
                )
                if (errorKey) this.setErrorMessage(errorMessages[errorKey])
                else this.setErrorMessage('Er is iets misgegaan. Probeer het later nog een keer of neem contact op met een administrator.')

                this.setIsError(true)
                throw new Error(error)
            } finally {
                this.isApproving = false
            }
        },
        getSelectedTeamLeaderLearningResult() {
            const didUserUncheckedAllConsents = this.teamLeaderLearningResultConsents && Object.values(this.teamLeaderLearningResultConsents)?.every(value => !value)

            // If employer category is chosen for learning result sharing basis with all options false, then consider it as "No consents required"
            if (this.isRequiredByEmployerLearningResultSharingType && didUserUncheckedAllConsents) {
                this.learningResultSharingType = learningResultSharingTypeEnum.None
            }
            
            return { 
                ...this.teamLeaderLearningResultConsents,
                learningResultSharingType: this.learningResultSharingType
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData[fieldName]

            if (fieldValidation && fieldValidation.$error && !fieldValidation.required) {
                return this.validationErrorMessages.required[fieldName]
            }

            return null
        },
        getStudyContractTemplateDocument() {
            if(this.isDownloadButtonDisabled || !this.alternativeStudyContractFlow) return

            this.fetchStudyContractTemplateDocument(this.formData.studyContract)
        }
    }
}
</script>

<template>
  <div
    :class="['c-carousel__image', 
             { 
               'c-carousel__image--empty': !isValidImage || isInvalidThumbnailImage, 
               'c-carousel__image--rounded' : isRounded,
               'c-carousel__image--small' : isSmall
             }
    ]"
  >
    <span
      v-if="isThumbnail"
      class="c-thumb__icon-play"></span>
    <img
      v-if="isValidImage && !isInvalidThumbnailImage"
      :src="image"
      alt="Carousel image"
      data-test="carousel-img"
      :class="['c-carousel__image-item', { 'is-active' : showContent && isValidImage }]"
    />
    <template v-if="showContent" >
      <span v-if="fileName" class="c-carousel__file-name">{{ fileName }}</span>
      <div v-if="showDeleteIcon" @click.prevent.stop>
        <recess-icon
          class="c-carousel__delete-icon"
          icon="bin"
          color="black"
          :height="20"
          :width="20"
          @click="$emit('delete')"
        />
      </div>
    </template>
  </div>
</template>

<script>

export default {
    props: {
        image: {
            type: String,
            default: null
        },
        isRounded: {
            type: Boolean,
            default: true
        },
        fileName: {
            type: String,
            default: null
        },
        showDeleteIcon: {
            type: Boolean,
            default: false
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        isThumbnail:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        isValidImage() {
            return this.isThumbnail || /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(this.image)
        },
        showContent() {
            return this.fileName && !this.isValidImage 
        },
        isInvalidThumbnailImage(){
            return this.isThumbnail && !this.image
        }

    }
}
</script>

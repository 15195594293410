import { ref, readonly, computed, reactive } from 'vue'
import useValidation from './useValidation'
import projectRequestClient from '../api/projectRequestClient'

export default function useProjectRequestApproval() {

    const projectRejectionModalTitle = 'Projectaanvraag afkeuren'
    const projectRejectionModalDescription = 'Geef een reden op waarom de projectaanvraag is afgekeurd, zodat de aanvrager hier actie op kan ondernemen als dit nodig is.'
    const projectRejectionMessageLabel = 'Reden voor afkeuren *'
    const errorMessages = {
        message: {
            required: 'Reden voor afkeuring is verplicht',
            maxLength: 'Uw beschrijving mag maximaal 300 tekens bevatten'
        }
    }

    const { handleErrorMessage, validateForm } = useValidation("rejectionFormData", errorMessages)
    const isApprovingProjectRequest = ref(false)
    const setIsApprovingProjectRequest = newValue => { isApprovingProjectRequest.value = newValue }

    const isApproveError = ref(false)
    const setIsApproveError = newValue => { isApproveError.value = newValue}
    const isRejectError = ref(false)
    const setIsRejectError = newValue => { isRejectError.value = newValue}

    const showRejectionModal = ref(false)
    const setShowRejectionModal = newValue => { showRejectionModal.value = newValue }

    const isLoading = computed(() => {
        return isApprovingProjectRequest.value
    })

    const rejectionFormData = reactive({
        message: null
    })

    const approveProjectRequest = async (projectRequestId, approvalData) => {
        try {
            setIsApprovingProjectRequest(true)
            await projectRequestClient.approve(projectRequestId, { ...approvalData })
            setIsApproveError(false)
        } catch (error) {
            setIsApproveError(true)
            throw new Error("Failed to approve project request", { cause: error })
        } finally {
            setIsApprovingProjectRequest(false)
        }
    }

    const rejectProjectRequest = async rejectRequest => {
        try {
            if(!validateForm()) {
                return false
            }

            setIsApprovingProjectRequest(true)

            await projectRequestClient.reject(rejectRequest)
            setIsRejectError(false)
            setShowRejectionModal(false)  
            return true
        } catch (error) {
            setIsRejectError(true)
            throw new Error("Failed to reject project request", { cause: error })
        } finally {
            setIsApprovingProjectRequest(false)
        }
    }
    
    return {
        projectRejectionModalTitle,
        projectRejectionModalDescription,
        projectRejectionMessageLabel,
        isLoading: readonly(isLoading),
        isRejectError: readonly(isRejectError),
        isApproveError,
        approveProjectRequest,
        rejectProjectRequest,
        rejectionFormData,
        handleErrorMessage,
        setShowRejectionModal,
        validateForm,
        showRejectionModal: readonly(showRejectionModal)
    }
}
export const AbcRequestSettings = {
    Enabled: 0,
    Disabled: 1,
    DisabledForStudent: 2
}

export const AbcRequestType = {
    Standard: 0,
    Incompany: 1,
    All: 2
}

export const AbcIncompanyAllowedLearningMethodFormats = [
    'Planned_Classroom',
    'Planned_VirtualClassroom',
    'Planned_Lecture',
    'Planned_Practicum',
    'Planned_Training',
    'Planned_Workshop'
]

export default {
    AbcRequestSettings,
    AbcRequestType,
    AbcIncompanyAllowedLearningMethodFormats
}

<template>
  <div>
    <recess-alert
      v-if="isMaxFilesLimitReached"
      type="error"
      :text="maxFilesUploadErrorText"
      class="mt-3 mb-3"
    />
    <recess-file-upload
      ref="uploadFileInput"
      class='p-0 col-12 col-md-8'
      max-size-file="26214400"
      data-test="upload-document-input"
      label-text="Bijlages"
      :max-file-to-upload="5"
      :disabled="isMaxFilesLimitReached"
      :allowed-extensions="allowedAttachmentExtensions"
      @upload="files => setDocumentsToUpload(files)"
    />

    <div  v-if="documentsToUpload.length || attachments.length" class="col-12 col-md-8 p-0">
      <template v-if="documentsToUpload">
        <div 
          v-for="(document, index) in documentsToUpload"
          :key="'upload'+index"
        >
          <uploaded-file-component
            :key="index"
            :document-url="document.fileData"
            :document-name="document.fileName"
            :disabled="true"
            :has-duplicated-file-name="document.hasDuplicatedFileName"
            @deleted="removeUpload(document.fileName, index)"
          />
        </div>
      </template>
          
      <template v-if="attachments && attachments.length">
        <div 
          v-for="(attachment, index) in attachments"
          :key="index"
        >
          <uploaded-file-component
            :document-url="attachment.url"
            :document-name="attachment.description"
            @deleted="onClickDeleteAttachment(index)"
          />
        </div>
      </template>
    </div>

    <remove-item-confirmation-component
      :is-visible="isAttachmentRemovalConfirmationPopupVisible"
      confirmation-text="Weet u zeker dat u deze bijlage wilt verwijderen?"
      @close="setIsAttachmentRemovalConfirmationPopupVisible(false)"
      @remove="deleteAttachment"
    />
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import useSocialLearningAttachment from '../../../../composables/useSocialLearningAttachment'
import RemoveItemConfirmationComponent from '../../../atoms/RemoveItemConfirmationComponent/RemoveItemConfirmationComponent.vue'
import UploadedFileComponent from '../../../atoms/UploadedFileComponent/UploadedFileComponent.vue'

export default {
    name: 'EditAttachments',
    components: {
        RemoveItemConfirmationComponent,
        UploadedFileComponent
    },
    props: {
        initialAttachments: {
            type: Array,
            required: false,
            default: () => []
        },
        resetFileUpload: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'updated', 'removed' ],
    setup(props, context) {
        const {
            uploadFileInput,
            maxFilesUploadErrorText,
            isMaxFilesLimitReached,
            allowedAttachmentExtensions,
            documentsToUpload,
            isAttachmentRemovalConfirmationPopupVisible,
            attachments,
            setDocumentsToUpload,
            setIsAttachmentRemovalConfirmationPopupVisible,
            removeUpload,
            deleteAttachment,
            onClickDeleteAttachment,
            setAttachments
        } = useSocialLearningAttachment(context)

        const dirty = ref(false)
        
        watch(() => props.initialAttachments, newVal => setAttachments(newVal))
        watch(() => props.resetFileUpload, newVal => {
            if(newVal) {
                setDocumentsToUpload([])
            }
        })

        watch(
            attachments,
            (newVal, oldVal) => {
                context.emit('removed', newVal)

                if(oldVal !== [] && newVal !== props.initialAttachments)
                    dirty.value = true
            },
            {
                deep: true
            }
        )

        watch(
            documentsToUpload,
            (newVal, oldVal) => {
                context.emit('uploaded', newVal)

                if(oldVal !== [])
                    dirty.value = true
            },
            {
                deep: true
            }
        )

        onMounted(() => {
            setAttachments(props.initialAttachments)
        })

        return {
            allowedAttachmentExtensions,
            isMaxFilesLimitReached,
            maxFilesUploadErrorText,
            documentsToUpload,
            isAttachmentRemovalConfirmationPopupVisible,
            uploadFileInput,
            attachments,
            dirty,
            setDocumentsToUpload,
            removeUpload,
            deleteAttachment,
            onClickDeleteAttachment,
            setIsAttachmentRemovalConfirmationPopupVisible
        }
    }
}
</script>

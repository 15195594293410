import coursePriceHelper from "../../modules/coursePriceHelper"
import tableHelper from "../../modules/tableHelper"

export default class TableHeaderBuilder {
    _headers = []

    firstName(isSortable = true) {
        this._headers.push({ key: 'FirstName', title: 'Medewerker', isSortable })

        return this
    }

    enrollmentCreationDate(isSortable = true) {
        this._headers.push({ key: 'DateTimeCreated', title: 'Datum inschrijving', isSortable })

        return this
    }

    price(useNetPrice = false, displayVat = false, isSortable = true) {
        this._headers.push({ key: tableHelper.totalAmountWithOrWithoutVatKey(useNetPrice), title: `Prijs (${coursePriceHelper.getVatText(displayVat)})`, isSortable })

        return this
    }

    courseName(isSortable = true) {
        this._headers.push({ key: 'CourseName', title: 'Ontwikkelactiviteit', isSortable })

        return this
    }

    courseVariant(isSortable = true) {
        this._headers.push({ key: 'CourseVariant', title: 'Variant', isSortable })

        return this
    }

    enrollmentStatus(isSortable = true) {
        this._headers.push({ key: 'StatusId', title: 'Status', isSortable })

        return this
    }

    delegator(isSortable = false) {
        this._headers.push({ key: 'Delegator', title: 'Uit naam van', isSortable })

        return this
    }

    emptyCell() {
        this._headers.push({})

        return this
    }

    build() {
        return this._headers
    }
}
<template>
    <recess-layout-static>
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading" overlay="white" color="primary" />
        </transition>
        <recess-divider />
        <h1>Leerresultaten</h1>
        <p v-if="isUserAbleToManagePEReminders">
            Op deze pagina kunt u een herinnering aanmaken voor uw collega's zodat zij tijdig worden
            geattendeerd op het feit dat ze actie moeten ondernemen om gecertificeerd te blijven. Uw
            collega ontvangt vanaf de geselecteerde periode voorafgaand aan de vervaldatum periodiek
            een e-mail ter herinnering (tot aan de vervaldatum).
        </p>
        <p v-else>
            Op deze pagina kunt u een herinnering aanmaken, zodat u tijdig wordt geattendeerd dat u
            actie moet ondernemen om gecertificeerd te blijven. U ontvangt vanaf de geselecteerde
            periode voorafgaand aan de vervaldatum periodiek een e-mail ter herinnering (tot aan de
            vervaldatum).
        </p>
        <div class="row">
            <recess-autocomplete
                v-if="isUserAbleToManagePEReminders"
                v-model="formData.emailAddress"
                label-text="Medewerker e-mailadres*"
                placeholder="Naam of e-mail"
                class="col-lg-4 col-md-6 col-12 mb-md-3"
                item-key="userId"
                item-text="emailAddress"
                item-sub-text="fullName"
                data-test="search-user-input"
                :error-message="getErrorMessage('emailAddress')"
                :on-query="queryUser"
            />

            <recess-input
                ref="courseNameInput"
                v-model="formData.courseName"
                class="col-lg-4 col-md-6 col-12"
                :value="formData.courseName"
                placeholder-text="Vereiste certificering"
                label-text="Vereiste certificering *"
                data-test="course-name-input"
                :error-message="getErrorMessage('courseName')"
                @input="resetAlerts"
            />
        </div>
        <recess-alert
            v-if="isSuccess"
            class="mt-5"
            type="success"
            text="Uw herinnering is succesvol opgeslagen."
        />
        <div v-if="showErrors">
            <recess-divider />
            <recess-alert
                v-for="(errorMessage, index) in errorMessages"
                :key="index"
                class="mb-2"
                type="error"
                :text="errorMessage"
            />
        </div>

        <div class="mb-3">
            <recess-divider show-line />

            <h3 class="mb-4">Behaald diploma/certificaat</h3>

            <recess-card class="u-bg-gray-medium-light">
                <upload-sub-record-component
                    :show-upload="
                        !isUserAbleToManagePEReminders && $isAllowed('displayPEReminderCertificate')
                    "
                    @addSubRecord="addSubRecord"
                />
            </recess-card>

            <recess-divider />

            <template v-if="viewItems">
                <div v-for="(subRecord, index) in items" :key="index">
                    <transition name="fade" mode="out-in">
                        <sub-record-component
                            v-if="showItems"
                            :sub-record="subRecord"
                            :show-popup="false"
                            :show-save-button="false"
                            :sub-record-index="index"
                            :show-upload="
                                !isUserAbleToManagePEReminders &&
                                $isAllowed('displayPEReminderCertificate')
                            "
                            :assigned-user-id="userId"
                            @delete-sub-record="removeSubRecord(index)"
                            @update="updateSubRecord(index, $event)"
                        />
                    </transition>
                </div>
            </template>

            <div class="col-sm-12 d-flex justify-content-between">
                <recess-button :url="backUrl" title="Terug" variant="tertiary" class="mt-4" data-test="pre-create-back-button"/>

                <recess-button
                    title="Opslaan"
                    variant="primary"
                    data-test="submit-btn"
                    class="mt-4"
                    @click.native="submit"
                />
            </div>
        </div>
    </recess-layout-static>
</template>

<script>
import axios from 'axios'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { scrollTo } from 'vue-scrollto'
import userClient from '../../../api/userClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { validationMessages, isValidEmail } from '../../../modules/validationsHelper'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import permanentEducationPerimeter from '../../../perimeters/permanentEducationPerimeter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import * as roles from '../../../constants/roles'
import UploadSubRecordComponent from '../../atoms/UploadSubRecordComponent/UploadSubRecordComponent.vue'
import SubRecordComponent from '../../atoms/SubRecordComponent/SubRecordComponent.vue'
import { errorMessages } from '../../../constants/errorMessages'
import StringUtils from '../../../utils/StringUtils'
import { allowedTextTags } from '../../../constants/htmlSanitizerConsts'

export default {
    name: 'CreatePermanentEducation',
    perimeters: [routePerimeter, featuresPerimeter, permanentEducationPerimeter],
    components: {
        LoaderComponent,
        UploadSubRecordComponent,
        SubRecordComponent
    },
    props: {
        defaultCourseName: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            validationMessages,
            isSuccess: false,
            showErrors: false,
            formData: {
                courseName: null,
                startDate: null,
                expiryDate: null,
                emailEnabled: false,
                emailReminderPeriod: null
            },
            errorMessages: [],
            isSendingRequest: false,
            potentialUsers: [],
            showItems: true
        }
    },
    computed: {
        ...mapGetters('userModule', ['emailAddress', 'roles', 'companyTeamleadersIds', 'userId']),
        ...mapGetters('accountModule', ['accountId']),
        ...mapGetters('peModule', ['loading', 'items', 'isPEDuplicate']),
        viewItems() {
            return this.showItems ? this.items : []
        },
        isUserAbleToManagePEReminders() {
            return (
                (this.$isAllowed('managePERemindersByCoordinator') ||
                    this.$isAllowed('managePERemindersByTeamLeader')) &&
                (this.$route?.params?.role?.toLowerCase() === roles.COORDINATOR.toLowerCase() ||
                    this.$route?.params?.role?.toLowerCase() === roles.TEAMLEADER.toLowerCase())
            )
        },
        isTeamLeader() {
            return (
                this.roles?.includes(roles.TEAMLEADER) && !this.roles?.includes(roles.COORDINATOR)
            )
        },
        isCoordinator() {
            return this.roles?.includes(roles.COORDINATOR)
        },
        isMatchingUserEmail() {
            return this.potentialUsers?.some((x) => x.emailAddress === this.formData.emailAddress)
        },
        selectedUserId() {
            const matchedUser = this.potentialUsers?.find(
                (x) => x.emailAddress === this.formData.emailAddress
            )
            return matchedUser?.userId
        },
        backUrl() {
            if (this.defaultCourseName) {
                return {
                    name: 'my-development',
                    query: { 'my-development-tab': 'active-courses' }
                }
            }

            if (this.isUserAbleToManagePEReminders) {
                return {
                    name: 'my-team',
                    query: { 'my-team-tab': 'permanent-educations' }
                }
            }

            return {
                name: 'my-development',
                query: { 'my-development-tab': 'permanent-educations' }
            }
        }
    },
    validations() {
        return {
            formData: {
                emailAddress: {
                    required: requiredIf(() => {
                        return this.isUserAbleToManagePEReminders
                    }),
                    isValidEmail
                },
                courseName: { required }
            }
        }
    },
    created() {
        if (this.defaultCourseName) {
            this.formData.courseName = this.defaultCourseName
        }

        this.startNewPE(this.userId)
    },
    beforeDestroy() {
        this.resetPEModuleState()
    },
    methods: {
        ...mapMutations('peModule', [
            'startNewPE',
            'setUserId',
            'addPEItem',
            'removePEItem',
            'updatePEItem',
            'setCourseName'
        ]),
        ...mapMutations({ resetPEModuleState: 'peModule/resetState' }),
        ...mapActions('peModule', ['checkDuplicatePEName', 'createNewPermanentEducation']),
        resetAlerts() {
            this.isSuccess = false
            this.showErrors = false
            this.errorMessages = []
        },
        addSubRecord(item) {
            item.isNewSubRecord = true
            this.showItems = false
            this.addPEItem(item)
            setTimeout(() => {
                this.showItems = true
            }, 0)
        },
        removeSubRecord(index) {
            this.removePEItem(this.items[index])
        },
        updateSubRecord(index, item) {
            const src = this.items[index]
            this.updatePEItem({ src, item })
        },
        sanitizeFields() {
            if (this.formData.courseName) {
                this.formData.courseName = StringUtils.sanitizeHtmlText(
                    this.formData.courseName,
                    allowedTextTags
                )
            }
        },
        async queryUser(query) {
            if (!query) return []

            // Cancel previous pending request
            userClient.cancelFetchSearch()

            const filters = [
                {
                    keys: ['firstName', 'middleName', 'lastName', 'emailAddress'],
                    operator: ODataFilterOperations.CONTAINS,
                    value: query,
                    keyCombination: 'or'
                },
                {
                    keys: 'emailAddress',
                    operator: ODataFilterOperations.NOTEQUALS,
                    value: this.emailAddress
                },
                {
                    keys: 'isEnabled',
                    operator: ODataFilterOperations.EQUALS,
                    value: true
                }
            ]

            if (this.isCoordinator) {
                filters.push({
                    keys: 'accountId',
                    operator: ODataFilterOperations.EQUALS,
                    value: this.accountId
                })
            } else if (this.isTeamLeader) {
                filters.push({
                    keys: 'companyId',
                    operator: ODataFilterOperations.IN,
                    value: this.companyTeamleadersIds.join(', ')
                })
            }

            const oDataFilters = filters.map((f) => {
                return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
            })

            const dataQuery = new ODataQueryBuilder()
                .setSort('firstName', 'asc')
                .setPagination(0, 5)
                .addFilters(oDataFilters)
                .build()

            try {
                const response = await userClient.searchUsersBasicInfo(dataQuery)
                this.potentialUsers = response?.items ?? []
                return this.potentialUsers
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    console.error(error)
                }
                return []
            }
        },
        async submit() {
            this.resetAlerts()
            this.sanitizeFields()
            this.$v.$touch()
            if (this.$v.$invalid) {
                scrollTo(this.$refs.courseNameInput)
                return
            }

            try {
                const userId = this.isUserAbleToManagePEReminders
                    ? this.selectedUserId
                    : this.userId
                this.setUserId(userId)
                this.setCourseName(this.formData.courseName)

                await this.checkDuplicatePEName()
                if (this.isPEDuplicate) {
                    this.errorMessages.push(errorMessages.DuplicatePermanentEducation)
                    this.showErrors = true
                    return
                }

                await this.createNewPermanentEducation()
                this.$router.push(this.backUrl)
            } catch (err) {
                // Get the errors from the validation or show default error message
                this.errorMessages = err.response?.data?.errors?.map(
                    (error) => error.errorMessage
                ) ?? ['Oeps, er is iets mis gegaan met het opslaan. Probeer het later opnieuw.']
                this.showErrors = true
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData[fieldName]
            const fieldValue = this.formData[fieldName]

            // Check Required
            if (fieldValidation?.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }

            // Check Invalid
            if (fieldValidation?.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        }
    }
}
</script>

<template>
  <div>
    <div v-if="hasData">
      <recess-divider />
      <transition name="fade">
        <loader-component
          v-if="isLoading"
          overlay="white"
          color="primary"
        />
      </transition>
       
      <h4>Toegewezen leerpaden</h4>
        
      <hooper
        data-test="learning-path-carousel"
        :settings="hooperSettings"
        :height="250"
      >
        <slide
          v-for="(lp, index) in learningPaths"
          :key="index"
          :index="index"
        >
          <carousel-item>
            <template #label>
              {{ lp.title }}
            </template>
            <template #body>
              <carousel-image :image="getIconPath(lp.iconName)" />
            </template>
            <template #description>
              <span>Einddatum: {{ formatDate(lp.endDate) || 'n.v.t' }}</span>
            </template>
            <template #status>
              <span>{{ lp.completedCoursesCount }} voltooid /<span class="font-weight-bold">{{ lp.remainingCoursesCount }} resterend</span></span>
            </template>
            <template #footer>
              <router-link :to="{ name: 'learning-path-details', params: { id: lp.id }}">Bekijk details</router-link>
            </template>
          </carousel-item>
        </slide>
        <hooper-navigation
          v-if="showNavigations(learningPaths)"
          slot="hooper-addons"
          data-test="navigation"
        >
          <template #hooper-prev>
            <recess-icon
              icon="arrow-left"
              color="secondary"
              :height="25"
              :width="25"
            />
          </template>
          <template #hooper-next>
            <recess-icon
              icon="arrow-right"
              color="secondary"
              :height="25"
              :width="25"
            />
          </template>
        </hooper-navigation>
      </hooper>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { getDashboardLearningPaths } from '../../../api/learningPathClient'
import { getFilePathByName } from '../../../modules/assetsHelper'
import CarouselItem from "../../atoms/CarouselItem/CarouselItem.vue"
import CarouselImage from '../../atoms/CarouselImage/CarouselImage.vue'
import { hooperSettings } from '../../../constants/carouselSettings'
import carouselHelper from '../../../modules/carouselHelper'
import DateUtils from '../../../utils/DateUtils'

export default {
    components: {
        LoaderComponent,
        Hooper,
        Slide,
        CarouselItem,
        CarouselImage,
        HooperNavigation
    },
    data() {
        return {
            isLoading: false,
            learningPaths: [],
            hooperSettings
            
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId']),
        hasData() {
            return this.learningPaths?.length > 0
        } 
    },
    created() {
        this.fetchLearningPaths()
    },
    methods: {
        async fetchLearningPaths() {
            try {
                this.isLoading = true
                this.learningPaths = await getDashboardLearningPaths()
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isLoading = false
            }
        },
        getIconPath(iconName) {
            return getFilePathByName('images/learningPath', iconName)
        },
        showNavigations: carouselHelper.showNavigations,
        formatDate: e => DateUtils.formatDate(e)
    }
}
</script>
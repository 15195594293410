<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
    <div>
        <recess-divider :display-medium-viewport="false" :display-small-viewport="false" />

        <transition name="fade">
            <loader-component v-if="isLoading" overlay="white" color="primary" />
        </transition>
        <p class="p-0 pl-md-4 col-12 col-md-8">
            Op deze pagina staan de inschrijvingen van iedereen waarvoor u goedkeurder bent en/of
            die u hebt ingeschreven. De status geeft de actuele status weer. Aan de actieknop
            (rechts van de inschrijving) ziet u of u nog een actie dient uit te voeren. Met het
            filter kunt u eenvoudig specifieke inschrijvingen vinden.
        </p>

        <table-filters-component
            :show-reset-filters="showResetButton"
            toggle-button-text="Filter inschrijvingen"
            @reset-filters="resetFilters()"
        >
            <recess-select
                slot="right"
                :options="enrollmentsPageSizes"
                :default-value="mDataQuery.take"
                @input="(value) => setPageSize(value)"
            />
            <template #filters>
                <recess-input
                    v-model="userFilters.fullNameFilter.value"
                    data-test="filter-by-name-input"
                    label-text="Zoek op naam van de medewerker"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
                <recess-input
                    v-model="userFilters.employeeNumberFilter.value"
                    data-test="filter-by-employee-number-input"
                    label-text="Zoek op personeelsnummer"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
                <recess-input
                    v-model="userFilters.emailFilter.value"
                    data-test="filter-by-email-input"
                    label-text="Zoek op e-mail"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
                <recess-input
                    v-model="userFilters.department.value"
                    data-test="filter-by-department-input"
                    label-text="Zoek op organisatieonderdeel"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
                <recess-input
                    v-model="userFilters.courseNameFilter.value"
                    data-test="filter-by-course-input"
                    label-text="Zoek op ontwikkelactiviteit"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
                <recess-input
                    v-model="userFilters.createdByFilter.value"
                    data-test="filter-by-createdby-input"
                    label-text="Gecreëerd door"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
                <recess-select
                    v-model="userFilters.variantsFilter.value"
                    :default-value="userFilters.variantsFilter.value"
                    :options="variantsSelectOptions || []"
                    data-test="filter-by-variant-input"
                    label-text="Zoek op variant"
                    class="mb-3"
                    @input="updateFilters()"
                />
                <recess-select
                    v-model="userFilters.statusFilter.value"
                    :disabled="statusSelectOptions.length <= 1"
                    :default-value="userFilters.statusFilter.value"
                    :options="statusSelectOptions || []"
                    data-test="filter-by-status-input"
                    label-text="Zoek op status"
                    class="mb-3"
                    @input="updateFilters()"
                />
                <div v-if="userRangeFilters.creationDateFilter" class="row mb-3">
                    <recess-input
                        v-model="userRangeFilters.creationDateFilter.fromValue"
                        placeholder-text="Dag-maand-jaar, bijv.: 25-01-1970"
                        data-test="filter-by-date-from-input"
                        label-text="Vanaf datum"
                        class="col-12 col-md-6"
                        :error-message="
                            $v.userRangeFilters.creationDateFilter.fromValue.$invalid
                                ? 'Datum heeft geen geldige waarde'
                                : null
                        "
                        @keyup.enter.native="updateFilters()"
                    />
                    <recess-input
                        v-model="userRangeFilters.creationDateFilter.toValue"
                        label-text="Tot datum"
                        data-test="filter-by-date-to-input"
                        placeholder-text="Dag-maand-jaar, bijv.: 25-01-1970"
                        :error-message="
                            $v.userRangeFilters.creationDateFilter.toValue.$invalid
                                ? 'Datum heeft geen geldige waarde.'
                                : null
                        "
                        class="col-12 col-md-6"
                        @keyup.enter.native="updateFilters()"
                    />
                </div>
                <div
                    v-if="$isAllowed('displayPricingInformation') && userRangeFilters.priceFilter"
                    class="row mb-3"
                >
                    <recess-input
                        v-model="userRangeFilters.priceFilter.fromValue"
                        label-text="Minimale prijs"
                        data-test="filter-by-price-from-input"
                        placeholder-text="10.00"
                        class="col-12 col-md-6"
                        :error-message="
                            $v.userRangeFilters.priceFilter.fromValue.$invalid
                                ? 'Prijs heeft geen geldige waarde'
                                : null
                        "
                        @keyup.enter.native="updateFilters()"
                    />
                    <recess-input
                        v-model="userRangeFilters.priceFilter.toValue"
                        label-text="Maximale prijs"
                        data-test="filter-by-price-to-input"
                        placeholder-text="100.50"
                        :error-message="
                            $v.userRangeFilters.priceFilter.toValue.$invalid
                                ? 'Prijs heeft geen geldige waarde.'
                                : null
                        "
                        class="col-12 col-md-6"
                        @keyup.enter.native="updateFilters()"
                    />
                </div>
                <div class="c-recess-input__label qa-recess-input__label">Goedkeuringen</div>
                <div class="col">
                    <div class="row">
                        <recess-radio-input
                            name="sameCompanyApprovers"
                            class="mr-4 mr-md-5"
                            value=""
                            label-text="Voor mijn team"
                            :default-checked="!sameCompanyToggled"
                            @change="(val) => updateFilters('sameCompanyFilter', val)"
                        />
                        <recess-radio-input
                            name="sameCompanyApprovers"
                            :value="user.companyId"
                            label-text="Leidinggevende is van mijn afdeling"
                            :default-checked="sameCompanyToggled"
                            @change="(val) => updateFilters('sameCompanyFilter', val)"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <recess-button
                    :disabled="$v.$invalid"
                    title="Filter"
                    variant="primary"
                    class="col-12 col-sm-auto"
                    @click="updateFilters()"
                />
            </template>
        </table-filters-component>

        <recess-divider />

        <transition name="fade">
            <div v-if="enrollments && enrollments.length">
                <recess-table>
                    <recess-table-head>
                        <recess-table-row>
                            <template v-for="header in headers">
                                <recess-table-head-item
                                    v-if="displayHeaderCell(header.key, useNetPrice)"
                                    :key="header.key"
                                    :sortable="header.isSortable"
                                    :sort-direction="getSortDirection(header)"
                                    @click.native="setSorting(header)"
                                >
                                    {{ header.title }}
                                </recess-table-head-item>
                            </template>
                        </recess-table-row>
                    </recess-table-head>
                    <recess-table-body>
                        <recess-table-row v-for="enrollment in enrollments" :key="enrollment.id">
                            <recess-table-cell :data-label="headers[0].title">
                                <span
                                    >{{ enrollment.firstName }} {{ enrollment.middleName }}
                                    {{ enrollment.lastName }}</span
                                >
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[1].title">
                                <router-link
                                    :to="{
                                        name: 'course',
                                        query: {
                                            course: enrollment.eduMSCourseId,
                                            assortment: enrollment.assortmentId
                                        }
                                    }"
                                >
                                    <span
                                        data-test="course-name"
                                        v-html="enrollment.courseName"
                                    ></span>
                                </router-link>
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[2].title">
                                {{ formatDate(enrollment.dateTimeCreated) }}
                            </recess-table-cell>
                            <recess-table-cell
                                v-if="$isAllowed('displayPricingInformation')"
                                :data-label="headers[3].title"
                            >
                                <span data-test="price-with-or-without-vat">{{
                                    getPriceWithOrWithoutVat(
                                        displayVatInPrices,
                                        enrollment.displayPrice
                                    )
                                }}</span>
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[4].title">{{
                                enrollment.courseVariant
                            }}</recess-table-cell>
                            <recess-table-cell :data-label="headers[5].title">
                                {{
                                    enrollment.currentStatus
                                        ? enrollment.currentStatus.displayValue
                                        : ''
                                }}
                            </recess-table-cell>
                            <recess-table-cell
                                class="d-flex align-items-center justify-content-between"
                            >
                                <div>
                                    <recess-button
                                        v-if="
                                            isPendingEnrollment(enrollment.statusId) &&
                                            showApproveButton(enrollment)
                                        "
                                        :url="{
                                            name: 'enrollment-approval',
                                            query: {
                                                enrollment: enrollment.enrollmentId,
                                                ...$route.query
                                            }
                                        }"
                                        title="Beoordelen"
                                        variant="primary"
                                        class="col-12 col-md-auto"
                                    />

                                    <recess-button
                                        v-else
                                        :url="{
                                            name: 'enrollment-details',
                                            query: {
                                                enrollment: enrollment.enrollmentId,
                                                ...$route.query
                                            }
                                        }"
                                        title="Bekijk"
                                        variant="tertiary"
                                        class="col-12 col-md-auto"
                                    />
                                </div>
                                <RecessIcon
                                    v-if="showDuplicateIcon(enrollment.createdById)"
                                    icon="duplicate"
                                    class="ml-3"
                                    :data-test="`copy-enrollment-${enrollment.enrollmentId}`"
                                    @click="copyEnrollment(enrollment)"
                                />
                            </recess-table-cell>
                        </recess-table-row>
                    </recess-table-body>
                </recess-table>

                <recess-divider />

                <pagination-component
                    :current-page-number="currentPageNumber"
                    :total-pages="totalPages"
                    @goToPage="paginationClicked"
                />
            </div>
        </transition>

        <recess-alert
            v-if="showNoEnrollmentsMessage"
            text="Er zijn op dit moment geen inschrijvingen."
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { decimal } from 'vuelidate/lib/validators'

import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import TableFiltersComponent from './TableFiltersComponent.vue'

import enrollmentClient from '../../../api/enrollmentClient'
import DateUtils from '../../../utils/DateUtils'
import priceFormatter from '../../../modules/priceFormatter'

import enrollmentPerimeter from '../../../perimeters/enrollmentPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import * as enrollmentStatus from '../../../constants/enrollmentStatus'
import * as roles from '../../../constants/roles'
import DataQueryBuilder from '../../../api/DataQuery/dataQueryBuilder'
import DataQueryFilter from '../../../api/DataQuery/dataQueryFilter'
import DataQueryDynamicFilter from '../../../api/DataQuery/dataQueryDynamicFilter'
import { isValidDate, isPastDate } from '../../../modules/validationsHelper'
import copyEnrollmentMixin from '../../../mixins/copy-enrollment-mixin'
import tableHelper from '../../../modules/tableHelper'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import TableFilterBuilder from '../../../utils/builders/TableFilterBuilder'
import enrollmentsTableHelper from '../../../modules/enrollmentsTableHelper'

export default {
    components: {
        LoaderComponent,
        PaginationComponent,
        TableFiltersComponent
    },
    mixins: [copyEnrollmentMixin],
    perimeters: [enrollmentPerimeter, featuresPerimeter],
    data() {
        return {
            enrollments: null,
            enrollmentsTotalCount: 0,
            enrollmentsPageIndex: 1,
            enrollmentsPageSize: 10,
            enrollmentsPageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            isLoading: false,
            showNoEnrollmentsMessage: false,
            mDataQuery: {
                sortField: 'DateTimeCreated',
                sortDirection: 'Desc',
                skip: 0,
                take: 10,
                filters: [],
                userFilters: []
            },
            statusSelectOptions: [{ displayText: '', value: '' }],
            variantsSelectOptions: [
                { displayText: '', value: '' },
                { displayText: 'Zaterdag', value: 'zaterdag' },
                { displayText: 'Dag', value: 'dag' },
                { displayText: 'Avond', value: 'avond' }
            ],
            userFilters: new TableFilterBuilder()
                .fullName()
                .employeeNumber()
                .email()
                .department()
                .courseVariant()
                .courseName()
                .enrollmentStatus()
                .createdBy()
                .isSameCompany()
                .build(),
            userRangeFilters: {}
        }
    },
    validations() {
        return {
            userRangeFilters: {
                creationDateFilter: {
                    fromValue: { isValidDate, isPastDate },
                    toValue: { isValidDate, isPastDate }
                },
                priceFilter: {
                    fromValue: { decimal },
                    toValue: { decimal }
                }
            }
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapState('accountModule', ['account', 'accountSettings']),
        ...mapGetters('accountModule', ['displayVatInPrices', 'useNetPrice']),
        totalAmountWithOrWithoutVatKey() {
            return tableHelper.totalAmountWithOrWithoutVatKey(this.useNetPrice)
        },
        headers() {
            return enrollmentsTableHelper.getEnrollmentTableHeaders(
                this.useNetPrice,
                this.displayVatInPrices,
                true
            )
        },
        dataQuery() {
            if (this.mDataQuery) {
                const filters = this.mDataQuery.filters.map(
                    (x) => new DataQueryFilter(x.key, x.operator, x.value)
                )
                const userFilters = this.mDataQuery.userFilters.map((x) => {
                    if (x.operator === 'Dynamic') return new DataQueryDynamicFilter(x.value)
                    return new DataQueryFilter(x.key, x.operator, x.value)
                })

                return new DataQueryBuilder()
                    .setSort(this.mDataQuery.sortField, this.mDataQuery.sortDirection)
                    .setPagination(this.mDataQuery.skip, this.mDataQuery.take)
                    .addFilters(filters)
                    .addFilters(userFilters)
                    .build()
            }
            return null
        },
        currentPageNumber() {
            if (this.enrollments) {
                return this.enrollmentsPageIndex
            }
            return 1
        },
        totalPages() {
            if (this.enrollments) {
                return Math.ceil(this.enrollmentsTotalCount / this.enrollmentsPageSize)
            }
            return 1
        },
        showPagination() {
            return this.enrollments && this.totalPages > 1
        },
        userId() {
            return this.user.userId
        },
        showResetButton() {
            return (
                Object.values(this.userFilters).some((uf) => {
                    return uf.value
                }) ||
                Object.values(this.userRangeFilters).some((urf) => {
                    return urf.fromValue.length > 0 || urf.toValue.length > 0
                })
            )
        },
        sameCompanyToggled() {
            return !!this.userFilters.sameCompanyFilter.value
        }
    },
    watch: {
        dataQuery: {
            // eslint-disable-next-line
            handler: function (value) {
                // Its a function because we need the this context
                if (value) {
                    // The dataQuery object changed, lets do a new request
                    this.doRequest()

                    // also update the url
                    const deserializeDataQuery = this.$route.query.tableDataQueries
                        ? JSON.parse(
                              Buffer.from(this.$route.query.tableDataQueries, 'base64').toString(
                                  'utf8'
                              )
                          )
                        : {}
                    deserializeDataQuery.myTeamEnrollments = this.mDataQuery
                    const serializedDataQuery = Buffer.from(
                        JSON.stringify(deserializeDataQuery),
                        'utf8'
                    ).toString('base64')
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            'my-team-tab': this.$route?.query['my-team-tab'],
                            tableDataQueries: serializedDataQuery
                        }
                    })
                }
            },
            deep: true
        },
        userId() {
            // sometimes the user is not set on time in the store yet, so then the userId is null,
            // which gives us no results when fetching the enrollments. So we need to make sure the
            // addQueryFilters method is called again if the user id is available
            if (this.user.userId) {
                this.buildQueryByUserRoles(this.user.roles)
            }
        }
    },
    created() {
        this.setInitialRangeFilters()

        // Fetch enrollment status list
        this.fetchEnrollmentStatusList()

        // Create dataQueryFilters for fetching enrollments
        this.buildQueryByUserRoles(this.user.roles)

        // Deserialize the dataquery object and set it
        if (this.$route.query.tableDataQueries) {
            const deserializeDataQuery = JSON.parse(
                Buffer.from(this.$route.query.tableDataQueries, 'base64')
            )

            // Set default input values
            if (deserializeDataQuery?.myTeamEnrollments?.userFilters) {
                deserializeDataQuery.myTeamEnrollments.userFilters.forEach((queryFilter) => {
                    Object.values(this.userFilters).forEach((usrFilter) => {
                        if (queryFilter.key === usrFilter.key) {
                            usrFilter.value = queryFilter.value
                        }
                    })

                    Object.values(this.userRangeFilters).forEach((rangeFilter) => {
                        if (queryFilter.key === rangeFilter.key) {
                            rangeFilter.fromValue = queryFilter.fromValue
                            rangeFilter.toValue = queryFilter.toValue
                        }
                    })
                })
            }

            // Set dataQuery
            this.mDataQuery = deserializeDataQuery?.myTeamEnrollments || this.mDataQuery
        }
        this.mDataQuery.take = this.getTakeFromSessionStorage()
    },
    methods: {
        setInitialRangeFilters() {
            const filters = new TableFilterBuilder().creationDateRange()

            if (this.$isAllowed('displayPricingInformation')) filters.priceRange(this.useNetPrice)

            this.userRangeFilters = filters.build()
        },
        parseFilterDate(date) {
            if (date) {
                const year = date.slice(6)
                const month = date.slice(3, 5)
                const day = date.slice(0, 2)
                return `${year}, ${month}, ${day}`
            }
            return ''
        },
        resetFilters() {
            Object.values(this.userFilters).forEach((uf) => {
                uf.value = ''
            })
            Object.values(this.userRangeFilters).forEach((urf) => {
                urf.fromValue = ''
                urf.toValue = ''
            })
            this.updateFilters()
        },
        updateFilters(filter, val) {
            if (this.$v.$invalid) {
                return
            }
            // Return to the first page
            this.mDataQuery.skip = 0

            // Handle radio button seperatly since it does not support v-model :|
            if (filter && this.userFilters[filter]) this.userFilters[filter].value = val

            const userFilters = Object.values(this.userFilters).map((uf) => {
                return { ...uf }
            })

            // Add range filters
            const userRangeFilters = this.getUserRangeFilters()

            this.mDataQuery.userFilters = [...userFilters, ...userRangeFilters]
        },
        getUserRangeFilters() {
            return Object.values(this.userRangeFilters).map((urf) => {
                if (urf.key.includes('DateTimeCreated')) {
                    const fromValue = urf.fromValue
                        ? `${urf.key}>=DateTime(${this.parseFilterDate(urf.fromValue)})`
                        : null
                    const toValue = urf.toValue
                        ? `${urf.key}<=DateTime(${this.parseFilterDate(urf.toValue)})`
                        : null

                    return {
                        key: urf.key,
                        fromValue: urf.fromValue,
                        toValue: urf.toValue,
                        operator: urf.operator,
                        value:
                            fromValue && toValue ? `${fromValue}&&${toValue}` : fromValue || toValue
                    }
                }

                if (urf.key.includes(this.totalAmountWithOrWithoutVatKey)) {
                    const fromValue = urf.fromValue ? `${urf.key}>=${urf.fromValue}` : null
                    const toValue = urf.toValue ? `${urf.key}<=${urf.toValue}` : null

                    return {
                        key: urf.key,
                        operator: urf.operator,
                        fromValue: urf.fromValue,
                        toValue: urf.toValue,
                        value:
                            fromValue && toValue ? `${fromValue}&&${toValue}` : fromValue || toValue
                    }
                }
                return null
            })
        },
        // Add filters to data query base on roles
        buildQueryByUserRoles(currentUserRoles) {
            if (!this.user.userId || !currentUserRoles || !currentUserRoles.length) {
                return
            }

            const filtersByRoles = []

            // All the filters that are applied in this switch statement are "or" filters. At the end these are combined into one filter,
            // separating them with an "||" statement. So if you have multiple roles, you get the data for every role.
            currentUserRoles.forEach((role) => {
                switch (role) {
                    case roles.TEAMLEADER:
                        if (!this.user.companyTeamLeaders.length) break
                        this.user.companyTeamLeaders.forEach((company) => {
                            filtersByRoles.push(`User.CompanyId == ${company.companyId}`)
                        })
                        break
                    case roles.COORDINATOR:
                        if (!this.account.accountId) break
                        filtersByRoles.push(`User.AccountId == ${this.account.accountId}`)
                        break
                    case roles.SECONDAPPROVER:
                        if (!this.account) break
                        if (this.accountSettings?.secondApprovalAccountWide) {
                            filtersByRoles.push(
                                `StatusId == ${enrollmentStatus.PENDINGSECONDAPPROVAL}`
                            )
                        } else {
                            filtersByRoles.push(`AssignedSecondApproverId == ${this.user.userId}`)
                        }
                        filtersByRoles.push(`SecondApproverId == ${this.user.userId}`)
                        filtersByRoles.push(`RejectorId == ${this.user.userId}`)
                        break
                    default:
                        break
                }
            })
            // Remove duplicate filters and join them with || as separator
            const uniqueFilters = filtersByRoles.length
                ? [...new Set(filtersByRoles)].join('||')
                : null

            if (uniqueFilters) {
                this.mDataQuery.filters.push({
                    operator: 'Dynamic',
                    value: `(${uniqueFilters}) && User.UserId != ${this.user.userId}`
                })
            }
            this.mDataQuery.filters.push({
                operator: 'Dynamic',
                value: `User.AccountId == ${this.account.accountId}`
            })
        },
        async doRequest() {
            try {
                // Start loading
                this.isLoading = true

                const response = await enrollmentClient.getEnrollments(this.dataQuery)
                this.showNoEnrollmentsMessage = false
                this.enrollments = response?.items
                this.enrollmentsTotalCount = response?.totalCount ?? 0
                this.enrollmentsPageSize = response?.pageSize ?? this.mDataQuery.size
                this.enrollmentsPageIndex = response?.pageIndex ?? 0

                if (!this.enrollments?.length) this.showNoEnrollmentsMessage = true
            } catch (error) {
                this.showNoEnrollmentsMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        fetchEnrollmentStatusList() {
            enrollmentClient.getEnrollmentStatuses().then((response) => {
                const statusFilters = response.map((i) => {
                    return {
                        displayText: i.displayValue,
                        value: i.enrollmentStatusId
                    }
                })
                this.statusSelectOptions.push(...statusFilters)
            })
        },
        isPendingEnrollment(status) {
            return (
                status === enrollmentStatus.PENDINGAPPROVAL ||
                status === enrollmentStatus.PENDINGSECONDAPPROVAL
            )
        },
        paginationClicked(pageNumber) {
            this.mDataQuery.skip = this.mDataQuery.take * (pageNumber - 1)
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.mDataQuery.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.mDataQuery.skip = 0

            // set the new page size value in the data query and in the sessopm storage
            this.mDataQuery.take = value
            sessionStorage.setItem(
                'TeamLeaderEnrollmentsTableComponent/take',
                JSON.stringify(value)
            )
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.mDataQuery.sortField === header.key) {
                this.mDataQuery.sortDirection =
                    this.mDataQuery.sortDirection === 'Asc' ? 'Desc' : 'Asc'
            } else {
                this.mDataQuery.sortDirection = 'Desc'
            }
            this.mDataQuery.sortField = header.key
            this.mDataQuery.skip = 0
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.mDataQuery.sortField !== header.key) {
                return null
            }

            return this.mDataQuery.sortDirection
                ? this.mDataQuery.sortDirection.toLowerCase()
                : null
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('TeamLeaderEnrollmentsTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(
                            sessionStorage.getItem('TeamLeaderEnrollmentsTableComponent/take')
                        ),
                        10
                    )
                } catch {
                    return this.enrollmentsPageSize
                }
            }
            return this.enrollmentsPageSize
        },
        formatPrice: priceFormatter.formatPrice,
        formatDate: (e) => DateUtils.formatDate(e),
        displayHeaderCell: (headerKey, useNetPrice) =>
            enrollmentsTableHelper.displayHeaderCell(headerKey, useNetPrice),
        showApproveButton: (enrollment) => enrollmentsTableHelper.showApproveButton(enrollment),
        getPriceWithOrWithoutVat: coursePriceHelper.getPriceWithOrWithoutVat
    }
}
</script>

import delegationClient from '../../api/delegationClient'
import userClient from '../../api/userClient'
import userFeatureSettingsClient from '../../api/userFeatureSettingsClient'
import{ PROJECTAPPROVER, PROJECTINITIATOR, COORDINATOR } from '../../constants/roles'
import {
    getGuaranteeCountryValue,
    getGuaranteeIntValue,
    getGuaranteeStringValue
} from '../../modules/enrollmentsHelper'
import { getUserManager } from '../../modules/oidc-client'
import DateUtils from '../../utils/DateUtils'
import { getSessionToken } from '../../utils/SessionTokenUtils'

const userModule = {
    namespaced: true,
    state() {
        return {
            isFetchingUser: false,
            isUpdatingUser: false,
            isUserFetched: false,
            isFetchingUserFeatureSettings: false,
            user: {
                userId: null,
                firstName: null,
                middleName: null,
                lastName: null,
                emailAddress: null,
                initials: null,
                gender: null,
                birthday: null,
                birthplace: null,
                street: null,
                number: null,
                numberAdditive: null,
                postalCode: null,
                city: null,
                country: null,
                telephone: null,
                employeeNumber: null,
                jobName: null,
                userDepartment: null,
                teamLeaderEmail: null,
                teamLeaderFullName: null,
                honorific: null,
                honorificAsPrefix: null,
                identityServerUserID: null,
                lastLoginDate: null,
                costCenter: null,
                fullName: null,
                roles: [],
                companyTeamLeaders: [],
                companyId: null,
                company: {},
                accountId: null,
                allowDataProcessingDate: null,
                legalAgreementAcceptedDate: null,
                wishListItems: [],
                activeDelegator: { delegateeEmail: null },
                activeDelegations: [],
                isEnabled: true,
                peStatus: null
            },
            userFeatureSettings: {
                aiAdvisor: null,
                aiWizardInputs: null
            },
            userDelegationActions: [],
            idServerUser: null,
            assortments: []
        }
    },
    getters: {
        userDepartment(state) {
            return state.user.userDepartment
        },
        fullName(state) {
            return state.user.fullName
        },
        telephone(state) {
            return state.user.telephone
        },
        defaultTeamLeaderEmail(state) {
            return state.user.teamLeaderEmail
        },
        costCenter(state) {
            return state.user.costCenter
        },
        companyTeamleadersIds(state) {
            return state.user.companyTeamLeaders.map(company => company.companyId)
        },
        legalAgreementAcceptedDate(state) {
            return state.user.legalAgreementAcceptedDate
        },
        getUserWishListItems(state) {
            return state.user.wishListItems
        },
        getAllowDataProcessingDate(state) {
            return state.user.allowDataProcessingDate
        },
        isUserEnabled(state) {
            return state.user.isEnabled
        },
        isUserImpersonating(state) {
            return !!state.idServerUser && !!state.idServerUser.profile.act
        },
        doesUserHasIdServerAdminRole(state){
            const idServerUserRoles = state.idServerUser.profile.role

            return (idServerUserRoles?.includes('DelegatedAccountAdmin') || idServerUserRoles?.includes('AccountAdmin') || idServerUserRoles?.includes('SupportAdmin'))
        },
        idServerToken(state) {
            if (state.idServerUser) {
                return `${state.idServerUser.token_type} ${state.idServerUser.access_token}`
            }
            return null
        },
        userId(state) {
            return state.user?.userId
        },
        emailAddress(state) {
            return state.user?.emailAddress
        },
        idServerUserId(state) {
            return state.user?.identityServerUserID
        },
        isUserADelegatee(state) {
            return !!(state.user?.activeDelegations && state.user?.activeDelegations.length > 0)
        },
        userActiveDelegationActions(state) {
            // userDelegationActions normally has always the latest correct values
            if (state.userDelegationActions && state.userDelegationActions.length > 0) {
                return state.userDelegationActions
            }
            // otherwise compile the list from all users active delegations
            let delegationActions = []
            if (state.user?.activeDelegations) {
                state.user.activeDelegations.forEach(delegation => {
                    delegationActions = [
                        ...delegationActions,
                        ...delegation.map(d => d.delegationActions)
                    ]
                })
            }
            return delegationActions
        },
        isSSOUser(state) {
            if (state.idServerUser) {
                // if sso_user claim is present, then user has SSO credentials associated
                return state.idServerUser.profile.sso_user
            }
            return false
        },
        sharedIds(state) {
            return state.idServerUser?.profile?.shared_id
        },
        roles(state) {
            return state.user.roles
        },
        userAssortments(state) {
            return state.assortments
        },
        userAssortmentIds(state) {
            return state.assortments?.map(x => x.assortmentId) ?? []
        },
        hasActiveDelegator(state) {
            return !!(state.user.activeDelegator && state.user.activeDelegator.delegateeEmail)
        },
        tokenAccountCode() {
            return getSessionToken()?.profile?.account_code
        },
        isCoordinator(state) {
            return state.user.roles.includes(COORDINATOR)
        },
        city(state) {
            return state.user.city
        },
        jobName(state) {
            return state.user.jobName
        },
        aiAdvisor(state) {
            return state.userFeatureSettings?.aiAdvisor?.split(";")?.filter(element => element)
        },
        aiWizardInputs(state) {
            return state.userFeatureSettings?.aiWizardInputs
        },
        userFeatureSettings(state) {
            return state.userFeatureSettings
        },
        goalType(state) {
            return state.userFeatureSettings?.aiWizardInputs?.goalType
        },
        currentJob(state) {
            return state.userFeatureSettings?.aiWizardInputs?.currentJob
        },
        currentJobLevel(state) {
            return state.userFeatureSettings?.aiWizardInputs?.currentJobLevel
        },
        currentJobSector(state) {
            return state.userFeatureSettings?.aiWizardInputs?.currentJobSector
        },
        newJob(state) {
            return state.userFeatureSettings?.aiWizardInputs?.newJob
        },
        newJobLevel(state) {
            return state.userFeatureSettings?.aiWizardInputs?.newJobLevel
        },
        newJobSector(state) {
            return state.userFeatureSettings?.aiWizardInputs?.newJobSector
        },
        isProjectInitiator(state) {
            return state.user.roles?.some(role => role === PROJECTINITIATOR)
        },
        isProjectApprover(state) {
            return state.user.roles?.some(role => role === PROJECTAPPROVER)
        }
    },
    mutations: {
        resetState(state) {
            state.isFetchingUser = false
            state.isUpdatingUser = false
            state.isUserFetched = false
            state.isFetchingUserFeatureSettings = false
            state.userFeatureSettings = {
                aiAdvisor: null,
                aiWizardInputs: null
            }
            state.user = {
                userId: null,
                firstName: null,
                middleName: null,
                lastName: null,
                emailAddress: null,
                initials: null,
                gender: null,
                birthday: null,
                birthplace: null,
                street: null,
                number: null,
                numberAdditive: null,
                postalCode: null,
                city: null,
                country: null,
                telephone: null,
                employeeNumber: null,
                jobName: null,
                userDepartment: null,
                teamLeaderEmail: null,
                teamLeaderFullName: null,
                honorific: null,
                honorificAsPrefix: null,
                identityServerUserID: null,
                lastLoginDate: null,
                costCenter: null,
                fullName: null,
                roles: [],
                budgets: [],
                companyTeamLeaders: [],
                companyId: null,
                company: {},
                accountId: null,
                allowDataProcessingDate: null,
                legalAgreementAcceptedDate: null,
                wishListItems: [],
                activeDelegator: { delegateeEmail: null },
                activeDelegations: [],
                isEnabled: true,
                peStatus: null
            }
            state.userDelegationActions = []
            state.idServerUser = null
            state.assortments = []
        },
        setIsFetchingUserFeatureSettings(state, isFetchingUserFeatureSettings) {
            state.isFetchingUserFeatureSettings = isFetchingUserFeatureSettings
        },
        setUserFeatureSettings(state, userFeatureSettings) {
            state.userFeatureSettings = userFeatureSettings
        },
        setIsFetchingUser(state, isFetchingUser) {
            state.isFetchingUser = isFetchingUser
        },
        setIsUpdatingUser(state, isUpdatingUser) {
            state.isUpdatingUser = isUpdatingUser
        },
        setIsUserFetched(state, isUserFetched) {
            state.isUserFetched = isUserFetched
        },
        setUserDelegationActions(state, userDelegationActions) {
            state.userDelegationActions = userDelegationActions
        },
        setUser(state, user) {
            state.user.userId = user.userId
            state.user.firstName = user.firstName
            state.user.middleName = user.middleName
            state.user.lastName = user.lastName
            state.user.emailAddress = user.emailAddress
            state.user.initials = user.initials
            state.user.gender = user.gender
            state.user.birthday = DateUtils.formatDate(user.birthday)
            state.user.birthplace = user.birthplace
            state.user.street = user.street
            state.user.number = user.number
            state.user.numberAdditive = user.numberAdditive
            state.user.postalCode = user.postalCode
            state.user.city = user.city
            state.user.country = user.country
            state.user.telephone = user.telephone
            state.user.employeeNumber = user.employeeNumber
            state.user.jobName = user.jobName
            state.user.userDepartment = user.userDepartment
            state.user.teamLeaderEmail = user.teamLeaderEmail
            state.user.teamLeaderFullName = user.teamLeaderFullName
            state.user.honorific = user.honorific
            state.user.honorificAsPrefix = user.honorificAsPrefix
            state.user.identityServerUserID = user.identityServerUserID
            state.user.lastLoginDate = user.lastLoginDate
            state.user.costCenter = user.costCenter
            state.user.fullName = user.fullName
            state.user.roles = user.roles
            state.user.companyTeamLeaders = user.companyTeamLeaders
            state.user.companyId = user.companyId
            state.user.accountId = user.accountId
            state.user.wishListItems = user.wishListItems
            state.user.allowDataProcessingDate = user.allowDataProcessingDate
            state.user.legalAgreementAcceptedDate = user.legalAgreementAcceptedDate
            state.user.company.name = user.company?.name
            state.user.activeDelegator = user.activeDelegator
            state.user.activeDelegations = user.activeDelegations
            state.user.isEnabled = user.isEnabled
            state.user.peStatus = user.peStatus
        },
        updateUserFields(state, userFields) {
            state.user = { ...state.user, ...userFields }
        },
        toggleUserCourseWishListItem(state, wishlistObject) {
            if (wishlistObject && state.user.wishListItems) {
                const index = state.user.wishListItems.findIndex(
                    x => x.courseId === wishlistObject.courseId
                )
                if (index > -1) {
                    state.user.wishListItems.splice(index, 1)
                } else {
                    state.user.wishListItems.push(wishlistObject)
                }
            }
        },
        setIdServerUser(state, user) {
            state.idServerUser = user
        },
        setAssortments(state, assortments) {
            state.assortments = assortments
        },
        setAiAdvisor(state, aiAdvisor) {
            state.userFeatureSettings.aiAdvisor = aiAdvisor
        },
        setAiWizardInputs(state, aiWizardInputs) {
            state.userFeatureSettings.aiWizardInputs = aiWizardInputs
        }
    },
    actions: {
        async fetchUser({ commit }, includes) {
            try {
                commit('setIsFetchingUser', true)
                const currentUser = await userClient.getCurrentUser({includeDelegation: true, includes})
                commit('setUser', currentUser)
                commit('setIsUserFetched', true)
                
                const idServerUser = await getUserManager().getUser()
                commit('setIdServerUser', idServerUser)

                return currentUser
            } catch (error) {
                if (error?.response?.status !== 401) {
                    throw new Error(error)
                }
                return null
            } finally {
                commit('setIsFetchingUser', false)
            }
        },
        async fetchUserFeatureSettings({ commit, getters }) {
            try {
                commit('setIsFetchingUserFeatureSettings', true)

                const userFeatureSettings = await userFeatureSettingsClient.get(getters.userId)

                if (userFeatureSettings) commit('setUserFeatureSettings', userFeatureSettings)
            } catch (error) {
                throw new Error("Failed to retrieve user feature settings", { cause: error })
            } finally {
                commit('setIsFetchingUserFeatureSettings', false)
            }
        },
        async updateUser({ commit, state }, isBirthDateInputDisplayed = true) {
            try {
                commit('setIsUpdatingUser', true)

                const userUpdateRequest = {
                    userId: state.user.userId,
                    firstName: state.user.firstName,
                    middleName: state.user.middleName,
                    lastName: state.user.lastName,
                    initials: state.user.initials,
                    birthday: isBirthDateInputDisplayed ? DateUtils.parseToISOString(state.user.birthday) : null ,
                    birthplace: state.user.birthplace,
                    gender: state.user.gender,
                    honorific: state.user.honorific,
                    honorificAsPrefix: state.user.honorificAsPrefix === 'prefix',
                    street: getGuaranteeStringValue(state.user.street),
                    city: getGuaranteeStringValue(state.user.city),
                    number: getGuaranteeIntValue(state.user.number),
                    numberAdditive: state.user.numberAdditive,
                    postalCode: getGuaranteeStringValue(state.user.postalCode),
                    country: getGuaranteeCountryValue(state.user.country),
                    telephone: state.user.telephone,
                    allowDataProcessingDate: state.user.allowDataProcessingDate
                }

                return await userClient.updateUser(userUpdateRequest)
            } catch (error) {
                return Promise.reject(error)
            } finally {
                commit('setIsUpdatingUser', false)
            }
        },
        async fetchUserDelegationActions({ commit }) {
            try {
                const delegationActions = await delegationClient.getDelegationActionsForCurrentUser()
                commit('setUserDelegationActions', delegationActions)
            } catch (error) {
                throw new Error(error)
            }
        },
        async fetchAssortments({ commit }) {
            try {
                const userAssortment = await userClient.getAssortments()
                commit('setAssortments', userAssortment)
            }
            catch(error) {
                throw new Error(error)
            }
        },
        resetState({ commit }) {
            commit('resetState')
        }
    }
}

export default userModule

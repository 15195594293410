import learningResultSharingTypes from '../constants/learningResultSharingTypes'
import learningResultSharingOptionsTypes from '../constants/learningResultSharingOptionsTypes'
import learningResultSharingTypeEnum from '../constants/learningResultSharingTypeEnum'

export default class LearningResultSharingUtils {
    static unauthorizedKeys = [ 
        learningResultSharingTypes.LEGAL.key
    ]

    constructor() {
        throw new Error('This is a static class')
    }

    static isUnauthorizedKey(key) {
        return this.unauthorizedKeys.includes(key)
    }

    static mapLearningResultSharingType(learningResultSharingTypeEnumValue) {
        switch (learningResultSharingTypeEnumValue) {
            case learningResultSharingTypeEnum.None:
                return learningResultSharingTypes.NONE.key
            case learningResultSharingTypeEnum.Legal:
                return learningResultSharingTypes.LEGAL.key
            case learningResultSharingTypeEnum.Employer:
                return learningResultSharingTypes.EMPLOYER.key
            default:
                return null
        }
    }

    static mapApproverLearningResultSharingWithStudent(type, selectedByApproverSharingResults) {
        if (type === learningResultSharingTypes.NONE.key) return null
        return {
            agreesToShare: true,
            [learningResultSharingOptionsTypes[type].DIPLOMACONSENT]:  selectedByApproverSharingResults?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.DIPLOMACONSENT],
            [learningResultSharingOptionsTypes[type].COMPLETIONATRISKCONSENT]: selectedByApproverSharingResults?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.COMPLETIONATRISKCONSENT],
            [learningResultSharingOptionsTypes[type].PASSEDEXAMCONSENT]: selectedByApproverSharingResults?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PASSEDEXAMCONSENT],
            [learningResultSharingOptionsTypes[type].PROGRESSPERCENTAGECONSENT]: selectedByApproverSharingResults?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PROGRESSPERCENTAGECONSENT]
        }     
    }

    static getMatchedLearningResultSharing(type, selectedLearningResultSharing) {
        return {
            [learningResultSharingOptionsTypes[type]?.DIPLOMACONSENT]: selectedLearningResultSharing?.diplomaConsent,
            [learningResultSharingOptionsTypes[type]?.COMPLETIONATRISKCONSENT]: selectedLearningResultSharing?.completionAtRiskConsent,
            [learningResultSharingOptionsTypes[type]?.PASSEDEXAMCONSENT]: selectedLearningResultSharing?.passedExamConsent,
            [learningResultSharingOptionsTypes[type]?.PROGRESSPERCENTAGECONSENT]: selectedLearningResultSharing?.progressPercentageConsent
        }
    }
    
    static getStudentSelectedLearningResultSharingByType(type, selectedLearningResultSharing) { 
        if (type === learningResultSharingTypes.NONE.key) return null
        return {
            [learningResultSharingOptionsTypes[type]?.DIPLOMACONSENT]: selectedLearningResultSharing?.[learningResultSharingOptionsTypes[type]?.DIPLOMACONSENT],
            [learningResultSharingOptionsTypes[type]?.COMPLETIONATRISKCONSENT]: selectedLearningResultSharing?.[learningResultSharingOptionsTypes[type]?.COMPLETIONATRISKCONSENT],
            [learningResultSharingOptionsTypes[type]?.PASSEDEXAMCONSENT]: this.isUnauthorizedKey(type) ? true : selectedLearningResultSharing?.[learningResultSharingOptionsTypes[type]?.PASSEDEXAMCONSENT],
            [learningResultSharingOptionsTypes[type]?.PROGRESSPERCENTAGECONSENT]: this.isUnauthorizedKey(type) ? true : selectedLearningResultSharing?.[learningResultSharingOptionsTypes[type].PROGRESSPERCENTAGECONSENT]
        }
    }

    static getStudentSelectedLearningResultSharingConsents(selectedLearningResultSharing) {
       
        let selectedLearningResultSharingConsents = {}
        let isInitialValue = false
        if (!selectedLearningResultSharing || !this.hasStudentConsents(selectedLearningResultSharing)) isInitialValue = true
        Object.keys(learningResultSharingTypes)?.filter(key => key !== learningResultSharingTypes.SELECTEDBYAPPROVER.key && key !== learningResultSharingTypes.NONE.key).forEach(key => {
            const options = {
                [learningResultSharingOptionsTypes[key].DIPLOMACONSENT]: isInitialValue || selectedLearningResultSharing?.[learningResultSharingOptionsTypes[key]?.DIPLOMACONSENT],
                [learningResultSharingOptionsTypes[key].COMPLETIONATRISKCONSENT]: isInitialValue || selectedLearningResultSharing?.[learningResultSharingOptionsTypes[key]?.COMPLETIONATRISKCONSENT],
                [learningResultSharingOptionsTypes[key].PASSEDEXAMCONSENT]: isInitialValue || selectedLearningResultSharing?.[learningResultSharingOptionsTypes[key]?.PASSEDEXAMCONSENT],
                [learningResultSharingOptionsTypes[key].PROGRESSPERCENTAGECONSENT]: isInitialValue || selectedLearningResultSharing?.[learningResultSharingOptionsTypes[key].PROGRESSPERCENTAGECONSENT]
            }
            selectedLearningResultSharingConsents = {...selectedLearningResultSharingConsents, ...options}
        })
        return selectedLearningResultSharingConsents
    }
    
    static getApproverSelectedLearningResultSharing(type, selectedLearningResultSharing) {
        return {
            [learningResultSharingOptionsTypes[type]?.DIPLOMACONSENT]: this.isUnauthorizedKey(type) ? true : selectedLearningResultSharing?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.DIPLOMACONSENT],
            [learningResultSharingOptionsTypes[type]?.COMPLETIONATRISKCONSENT]: selectedLearningResultSharing?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.COMPLETIONATRISKCONSENT],
            [learningResultSharingOptionsTypes[type]?.PASSEDEXAMCONSENT]: selectedLearningResultSharing?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PASSEDEXAMCONSENT],
            [learningResultSharingOptionsTypes[type]?.PROGRESSPERCENTAGECONSENT]: this.isUnauthorizedKey(type) ? true : selectedLearningResultSharing?.[learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PROGRESSPERCENTAGECONSENT]
        }
    }

    static hasStudentOrApproverConsents(enrollmentLearningResult) {
        if (!enrollmentLearningResult || !this.hasStudentConsents(enrollmentLearningResult)) return false

        return !!enrollmentLearningResult.learningResultSharingType || 
            this.hasStudentAgreedForLearningResultSharing(enrollmentLearningResult)
    }

    static hasStudentAgreedForLearningResultSharing(enrollmentLearningResult) {
        return !!enrollmentLearningResult?.agreesToShare 
    }

    static hasStudentConsents(enrollmentLearningResult) {
        return enrollmentLearningResult.legalDiplomaConsent !== null &&
               enrollmentLearningResult.legalPassedExamConsent !== null &&
               enrollmentLearningResult.legalProgressPercentageConsent !== null &&
               enrollmentLearningResult.legalCompletionAtRiskConsent !== null &&
               enrollmentLearningResult.employerDiplomaConsent !== null &&
               enrollmentLearningResult.employerPassedExamConsent !== null &&
               enrollmentLearningResult.employerProgressPercentageConsent !== null &&
               enrollmentLearningResult.employerCompletionAtRiskConsent !== null
    }
}
import courseAccessStatuses from '../constants/courseAccessStatuses'
import { errorMessages } from '../constants/errorMessages'

/** @typedef {import('../models').PermanentEducation} PermanentEducation */
/** @typedef {import('../models').PELegacy} PELegacy */

/**
 * Convert a PermanentEducation object to a PELegacy object
 * 
 * @param {PermanentEducation} pe 
 * @returns {PELegacy}
 */
export function mapPermanentEducationToLegacy(pe) {
    const lastItem = [...pe?.items]?.filter(item => item.expiryDate)?.sort((a, b) => a.expiryDate?.localeCompare(b.expiryDate)).pop()

    const hasCertificate = lastItem?.createdByUserId ? !!lastItem?.certificationPaths?.pop() : !!lastItem?.hasProviderUploadedCertificate || !!lastItem?.certificationPaths?.pop()

    return {
        accountAllowsStudentEdit: pe.accountAllowStudentEdit,
        accountId: pe.accountId,
        hasCertificate,
        companyId: pe.companyId,
        courseName: pe.courseName,
        isImported: pe.isImported,
        // eslint-disable-next-line no-nested-ternary
        createdByUserFullName: pe.createdByUserId ? pe.createdByUserFullName : (pe.isImported ? 'Geïmporteerd' : 'Opleider'),
        createdByUserId: pe.createdByUserId,
        creationDate: pe.creationDate,
        emailEnabled: pe.emailEnabled,
        emailReminderPeriod: lastItem?.emailReminderPeriod,
        permanentEducationId: pe.permanentEducationId,
        startDate: lastItem?.startDate ?? '1970-01-01',
        expiryDate: lastItem?.expiryDate ?? '1970-01-01',
        userEmailAddress: pe.userEmailAddress,
        userId: pe.userId,
        latestEnrollmentId: pe.latestEnrollmentId,
        latestEnrollmentCourseId: pe.latestEnrollmentCourseId,
        latestEnrollmentAssortmentId: pe.latestEnrollmentAssortmentId
    }
}

/**
 * Convert a list of files from a RecessUpload object to a list of OPLZ upload file request
 * 
 * @param {*} uploadList 
 * @returns {[]}
 */
export function mapFileUploadsToRequests(uploadList) {
    return (uploadList ?? []).map(f => {
        if (!f?.base64FileString || !f?.structure?.name) 
            return undefined
        return {
            fileData: f?.base64FileString,
            fileName: f?.structure?.name
        }
    }).filter(f => !!f)
}

/**
 * Map properties that are used by authorization perimeters
 * 
 * @param {*} storeObject 
 * @returns {{}}
 */
export function mapPerimeterChilds(storeObject) {
    if(storeObject?.state?.userModule &&
        storeObject?.state?.accountModule &&
        storeObject?.state?.configurationModule) {

        return {
            user: storeObject.state.userModule.user,
            claims: storeObject.state.userModule.idServerUser?.profile,
            userDelegationActions: storeObject.state.userModule.userDelegationActions,
            account: storeObject.state.accountModule.account,
            accountSettings: storeObject.state.accountModule.accountSettings,
            environment: storeObject.state.configurationModule.currentEnvironment
        }
    }

    return null
}

export function mapCourseAccessErrorMessage(errorType) {
    switch(errorType) {
        case courseAccessStatuses.NO_VALID_START_MOMENTS: 
            return errorMessages.CourseNotValidStartMoments
        case courseAccessStatuses.OUTSIDE_ASSORTED_PRODUCTS:
            return errorMessages.CourseOutsideAssortment
        default:
            return null
    }
}

export default {
    mapPermanentEducationToLegacy,
    mapFileUploadsToRequests,
    mapCourseAccessErrorMessage
}
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isFetchingLearningPaths"
      data-test="fetching-learning-paths-loader"
      overlay="white"
      color="primary"
    />

    <p
      data-test="explanation-text"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      {{ explanationText }}
    </p>
 
    <table-filters-component
      :show-reset-filters="showResetButton"
      toggle-button-text="Filter leerpaden"
      data-test="filter-learning-path-recess-button"
      @reset-filters="resetFilters()"
    >
      <template #filters>
        <recess-input
          v-model="learningPathNameFilter"
          data-test="filter-learning-path-title"
          label-text="Zoek op leerpadnaam"
          class="mb-3"
          @keyup.enter.native="fetchLearningPaths()"
        />
        <recess-select
          v-model="typeFilter"
          :default-value="typeFilter"
          :options="typeFilterOptions"
          class="mb-3"
          label-text="Filter het leerpad type"
        />      
        <recess-select
          v-model="statusFilter"
          :default-value="statusFilter"
          :options="statusFilterOptions"
          label-text="Filter op status"
        />      
      </template>
      <template #footer>
        <recess-button
          title="Filter"
          variant="primary"
          class="col-12 col-sm-auto"
          data-test="filter-recess-button"
          @click="fetchLearningPaths()"
        />
      </template>
    </table-filters-component>

    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
        
    <recess-divider
      variant="xsmall"
      :display-large-viewport="false"
    />

    <transition name="fade">
      <div v-if="learningPaths && learningPaths.length">
        <h4 class="pl-md-4">
          Er zijn
          <span class="h4">{{ learningPaths.length }}</span> leerpaden
        </h4>

        <recess-divider
          :display-medium-viewport="false"
          :display-small-viewport="false"
        />
        
        <recess-divider
          variant="xsmall"
          :display-large-viewport="false"
        />

        <div
          class="row d-flex flex-wrap"
          data-test="my-learning-path-listing"
        >
          <div
            v-for="learningPath in learningPaths"
            :key="learningPath.id"
            class="col-12 col-md-6 col-lg-4 c-learning-path__tile"
          >
            <recess-card
              bordered
              has-hover
              class="h-100 d-flex flex-column justify-content-between"
              data-test="learning-path-recess-card"
              @click.native="$router.push({ name: 'learning-path-details', params:{ id: learningPath.id }})"
            >
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <h4>{{ learningPath.title }}</h4>
                    <span>{{ learningPath.completedCoursesCount }} voltooid /</span>
                    <span class="font-weight-bold">{{ learningPath.remainingCoursesCount }} over</span>
                  </div>
                  <div
                    v-if="displayCompletedLeaningPathStyle(learningPath)"
                    class="c-learning-path__icon--success"
                  >
                    <recess-icon
                      class="d-none d-md-flex"
                      icon="check"
                      color="white"
                      :height="35"
                      :width="35"
                    />
                    <recess-icon
                      class="d-md-none"
                      icon="check"
                      color="white"
                      cursor="default"
                    />
                  </div>
                </div>
                <recess-divider show-line />
                <span v-if="learningPath.description">{{ cutOff(learningPath.description, getIconPath(learningPath.iconName) ? 100 : 300) }}</span>
                <div
                  v-if="getIconPath(learningPath.iconName)"
                  class="d-flex justify-content-center my-3"
                >
                  <img
                    alt="Learning path icon"
                    class="c-learning-path__icon--small"
                    :src="getIconPath(learningPath.iconName)"
                  />
                </div>
              </div>

              <div>
                <recess-divider show-line />
                <recess-button
                  variant="primary"
                  title="Bekijk details"
                  class="w-100"
                  :url="{ name: 'learning-path-details', params:{ id: learningPath.id }}"
                />
              </div>
            </recess-card>
          </div>
        </div>
      </div>
    </transition>

    <recess-alert
      v-if="(!learningPaths || !learningPaths.length) && !isFetchingLearningPaths"
      text="Er zijn op dit moment geen leerpaden."
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cutOff } from '../../../modules/textFormatter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import { getLearningPaths } from '../../../api/learningPathClient'
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import { getFilePathByName } from '../../../modules/assetsHelper'
import TableFiltersComponent from '../Tables/TableFiltersComponent.vue'
import * as learningPathQueryOptions from '../../../constants/learningPathQueryOptions'
import * as learningPathTypes from '../../../constants/learningPathTypes'

export default {
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent,
        TableFiltersComponent
    },
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            learningPaths: null,
            sorting: {
                key: 'endDate',
                direction: ODataSortingDirections.DESC
            },
            typeFilter: null,
            statusFilter: learningPathQueryOptions.ALL,
            learningPathNameFilter: '',
            isFetchingLearningPaths: false,
            typeFilterOptions: [
                { value: null, displayText: "" },
                { value: learningPathTypes.PERSONAL, displayText: "Persoonlijke leerpaden" },
                { value: learningPathTypes.ASSIGNED, displayText: "Toegewezen leerpaden" }
            ],
            statusFilterOptions: [
                { value: learningPathQueryOptions.ALL, displayText: "" },
                { value: learningPathQueryOptions.COMPLETEDLEARNINGPATH, displayText: "Afgerond" },
                { value: learningPathQueryOptions.UNCOMPLETEDLEARNINGPATH, displayText: "Openstaand " }
            ]
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId']),
        showResetButton() {
            return !!this.learningPathNameFilter.length || this.statusFilter !== learningPathQueryOptions.ALL || !!this.typeFilter
        }
    },
    watch: {
        userId(newVal) {
            if (newVal) {
                this.fetchLearningPaths()
            }
        }
    },
    created() {
        this.fetchLearningPaths()
    },
    methods: {
        displayCompletedLeaningPathStyle(learningPath) {
            
            return this.$isAllowed('displayCompletedLearningPathStyle') 
            && learningPath.remainingCoursesCount === 0 && learningPath.completedCoursesCount > 0
        },
        async fetchLearningPaths() {
            try {
                this.isFetchingLearningPaths = true
                if (!this.userId) {
                    return
                }

                const filters = [ 
                    {
                        keys: 'createdById',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.userId
                    },
                    {
                        keys: 'isPersonal',
                        operator: ODataFilterOperations.EQUALS,
                        value: true
                    }
                ]

                // Set base filters
                const dataQuery = new ODataQueryBuilder().setSort(this.sorting.key, this.sorting.direction)
                   
                // Set learning path type filters
                switch(this.typeFilter) {
                    case(learningPathTypes.ASSIGNED) :
                        dataQuery.addDynamicFilter(`assignedUsers/any(u: u/userId eq ${this.userId})`)
                        break
                    case(learningPathTypes.PERSONAL):
                        dataQuery
                            .addFilters(filters.map(f => {
                                return new ODataQueryFilter(f.keys, f.operator, f.value)
                            }))  
                        break
                    default:
                        dataQuery
                            .addFilters(filters.map(f => {
                                return new ODataQueryFilter(f.keys, f.operator, f.value)
                            })) 
                            .or()
                            .addDynamicFilter(`assignedUsers/any(u: u/userId eq ${this.userId})`)
                }

                // Set learning path title filters
                if(this.learningPathNameFilter) {
                    dataQuery
                        .and()
                        .addFilter(new ODataQueryFilter('title', ODataFilterOperations.CONTAINS, this.learningPathNameFilter))
                }

                this.learningPaths = await getLearningPaths(dataQuery.build(), this.statusFilter)
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isFetchingLearningPaths = false
            }
        },
        getIconPath(iconName) {
            return getFilePathByName('images/learningPath', iconName)
        },
        resetFilters() {
            this.learningPathNameFilter = ''
            this.typeFilter = null
            this.statusFilter = learningPathQueryOptions.ALL

            this.fetchLearningPaths()
        },
        cutOff
    }
}
</script>


export const errorMessages = {
    Exception: 'Oeps, er is een onverwachte fout opgetreden (exception).',
    NullRequest: 'Oeps, de inschrijving is niet opgeslagen omdat hij niet volledig is (null request).',
    CreationFailed: 'Oeps, het was niet mogelijk om de inschrijving op te slaan (creation failed).',
    CreationFailedDuplicateValue: 'Oeps, de inschrijving is niet opgeslagen omdat het een dubbele waarde bevat (duplicate value).',
    UpdateFailed: 'Oeps, het was niet mogelijk om de inschrijving bij te werken (update failed).',
    ValidationFailed: 'Oeps, de validatie van het verzoek is mislukt (validation failed).',
    NoAccount: 'Deze gebruiker is niet gekoppeld aan een opdrachtgever (no account).',
    AccountDisabled: 'De opdrachtgever waar deze gebruiker aan gekoppeld is, is niet actief (account disabled).',
    NonExistingCourse:
           'Oeps, de gezochte ontwikkelactiviteit kon niet worden gevonden in de zoekindex (non existing course).',
    NonExistingAssortedProduct:
           'De gezochte ontwikkelactiviteit kan niet worden gevonden in het aan u toegewezen assortiment (non existing assorted product).',
    InsufficientBudget: 'Oeps, er is onvoldoende budget beschikbaar (insufficient budget).',
    InvalidBudget: 'Oeps, de validatie van het budget is mislukt (invalid budget).',
    DelegationActionFailed: 'Oeps, de gedelegeerde taak is mislukt (delegation action faild).',
    CourseDeleted: 'Oeps, deze ontwikkelactiviteit is niet meer beschikbaar binnen het assortiment (course deleted).',
    TeamleaderCannotEnrollSelf: 'Oeps, u kunt uzelf niet inschrijven binnen een groepsinschrijving. Mocht u toch graag deze ontwikkelactiviteit willen volgen kunt u zich inschrijven via een persoonlijke inschrijving (teamleader cannot enroll self).',
    NullEntity: 'Oeps, het was niet mogelijk om de handeling uit te voeren omdat benodigde informatie niet gevonden kon worden (null entity).',
    MissingSecondApprover: 'Let op: Er zijn géén 2e goedkeurders ingericht om te selecteren, terwijl er wel een 2e goedkeuring is vereist. Hierdoor kan de inschrijving niet worden goedgekeurd (missing second approver).',
    InvalidStateForCurrentAction: 'Oeps, de actie heeft een ongeldige status (invalid state for current action)',
    UserDisabled: 'De gebruiker is niet actief (user disabled)',
    MustUseBudget: 'Het gebruik van budget is verplicht voor het inschrijven op deze ontwikkelactiviteit (must use budget)',
    MustCoverPriceWithBudget: 'De kosten van de ontwikkelactiviteit moeten volledig binnen het beschikbare budget vallen (must cover price with budget)',
    OverBudgetUse: 'Het budgetbedrag is overschreden (over budget use)',
    BudgetUseHigherThanCost: 'Het gebruikte budget is hoger dan de kosten (budget use higher than cost)',
    NotificationEmailFailed: 'De notificatie e-mail is niet verzonden (notification email failed)',
    UploadFailed: 'De upload is mislukt (upload failed)',
    RevertBudgetFailed: 'Het terugboeken naar het budget is niet goed gegaan (revert budget failed)',
    WebhookNotificationFailed: 'De webhook notificatie is niet goedgegaan (webhook notification failed)',
    DeleteFailed: 'Het verwijderen is niet goed gegaan (delete failed).',
    DuplicatePermanentEducation: 'Er bestaat al een herinnering met deze naam',
    CourseOutsideAssortment: 'U kunt zich niet inschrijven omdat deze ontwikkelactiviteit niet in het aan u toegewezen assortiment zit. Ga naar home om opnieuw naar een ontwikkelactiviteit te zoeken.',
    CourseNotValidStartMoments: 'U kunt zich op dit moment niet inschrijven omdat er geen (actieve) startmomenten beschikbaar zijn. Probeer het later nog eens.'
}

export default errorMessages
<template>
  <recess-layout-static v-if="$isAllowed('routeApprovalAbcPage')">
    <recess-divider />
    <h1>Aanvraag buiten catalogus</h1>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetching"
        overlay="white"
        color="primary"
      />
    </transition>
    <recess-divider />
    <recess-alert
      v-if="showErrorMessage"
      class="mb-5"
      type="error"
      text="Er is iets misgegaan. Probeer het later nog een keer."
    />
    <template v-if="requestOutsideAssortedProducts">
      <h4 class="u-textcolor-primary">Medewerker</h4>
      <table
        aria-label="ABC request general detail"
        class="w-100"
      >
        <tbody>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Aanhef
            </th>
            <td class="w-50">{{ requestOutsideAssortedProducts.gender === 'M' ? 'Dhr.' : 'Mevr.' }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Naam
            </th>
            <td>{{ requestOutsideAssortedProducts.requestedByFullName }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              E-mailadres
            </th>
            <td>{{ requestOutsideAssortedProducts.requestedByEmailAddress }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Telefoonnummer
            </th>
            <td>{{ requestOutsideAssortedProducts.phoneNumber || '-' }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Functie
            </th>
            <td>{{ requestOutsideAssortedProducts.requestedByJobName || '-' }}</td>
          </tr>
          <recess-divider variant="xsmall" />
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Voor wie is de ontwikkelactiviteit?
            </th>
            <td>{{ requestOutsideAssortedProducts.isOwnRequest ? 'Voor mijzelf' : 'Voor mijn medewerkers/groepsaanvraag' }}</td>
          </tr>
          <tr v-if="requestOutsideAssortedProducts.hasQuote != null">
            <th
              scope="row"
              class="text-left"
            >
              Heb je al een offerte?
            </th>
            <td>{{ requestOutsideAssortedProducts.hasQuote ? 'Ja' : 'Nee' }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Ontwikkelactiviteit type
            </th>
            <td>{{ requestOutsideAssortedProducts.isExistingCourse ? 'Bestaande ontwikkelactiviteit' : 'Maatwerk ontwikkelactiviteit' }}</td>
          </tr>
          <tr v-if="requestOutsideAssortedProducts.requestOutsideAssortedProductsReasonDisplayValue">
            <th
              scope="row"
              class="text-left"
            >
              Reden aanvraag buiten catalogus
            </th>
            <td>{{ requestOutsideAssortedProducts.requestOutsideAssortedProductsReasonDisplayValue }}</td>
          </tr>
        </tbody>
      </table>

      <recess-divider />

      <h4 class="u-textcolor-primary">Gegevens ontwikkelactiviteit leverancier</h4>
      <table
        aria-label="ABC request provider detail"
        class="w-100"
      >
        <tbody>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Naam opleider
            </th>
            <td class="w-50">{{ requestOutsideAssortedProducts.providerName || "-" }}</td>
          </tr>
          <template v-if="!requestOutsideAssortedProducts.isIncompany">
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                Telefoonnummer opleider
              </th>
              <td>{{ requestOutsideAssortedProducts.providerPhoneNumber || "-" }}</td>
            </tr>
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                E-mailadres opleider
              </th>
              <td>{{ requestOutsideAssortedProducts.providerEmailAddress || "-" }}</td>
            </tr>
          </template>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Link naar gewenste ontwikkelactiviteit
            </th>
            <td>{{ requestOutsideAssortedProducts.courseUrl || "-" }}</td>
          </tr>
        </tbody>
      </table>

      <recess-divider />

      <h4 class="u-textcolor-primary">Gegevens gewenste ontwikkelactiviteit</h4>
      <table
        aria-label="ABC request course detail"
        class="w-100"
      >
        <tbody>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Naam gewenste ontwikkelactiviteit
            </th>
            <td class="w-50">{{ requestOutsideAssortedProducts.courseName }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Bijeenkomsten
            </th>
            <td>{{ requestOutsideAssortedProducts.meetings || "-" }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Verwachte startdatum van aanvang
            </th>
            <td>{{ requestOutsideAssortedProducts.startDate || "-" }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Verwachte einddatum van aanvang
            </th>
            <td>{{ requestOutsideAssortedProducts.endDate || "-" }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Locatie
            </th>
            <td>{{ requestOutsideAssortedProducts.courseLocationDisplayValue || "-" }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Fysieke locatie
            </th>
            <td>{{ requestOutsideAssortedProducts.physicalLocation || "-" }}</td>
          </tr>
        </tbody>
      </table>

      <recess-divider />

      <template  v-if="!requestOutsideAssortedProducts.isIncompany">
        <h4 class="u-textcolor-primary">Kosten (alle bedragen exclusief BTW)</h4>
        <table
          aria-label="ABC request cost detail"
          class="w-100"
        >
          <tbody>
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                ontwikkelactiviteitskosten per persoon
              </th>
              <td class="w-50">{{ requestOutsideAssortedProducts.coursePrice ? formatPrice(requestOutsideAssortedProducts.coursePrice) : "-" }}</td>
            </tr>
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                Overige kosten
              </th>
              <td>{{ requestOutsideAssortedProducts.otherCost ? formatPrice(requestOutsideAssortedProducts.otherCost) : "-" }}</td>
            </tr>
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                Totale kosten
              </th>
              <td>{{ requestOutsideAssortedProducts.totalCost ? formatPrice(requestOutsideAssortedProducts.totalCost) : "-" }}</td>
            </tr>
          </tbody>
        </table>

        <recess-divider />
      </template>

      <h4 class="u-textcolor-primary">Autorisatie</h4>
      <table
        aria-label="Approval detail"
        class="w-100"
      >
        <tbody>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Datum van aanvraag
            </th>
            <td class="w-50">{{ formatDate(requestOutsideAssortedProducts.utcDateCreated) }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Status
            </th>
            <td>{{ requestOutsideAssortedProducts.displayStatus }}</td>
          </tr>
          <tr>
            <th
              scope="row"
              class="text-left"
            >
              Toegewezen beoordelaar
            </th>
            <td>{{ requestOutsideAssortedProducts.assignedApproverFullName }}</td>
          </tr>
          <template
            v-if="requestOutsideAssortedProducts.status !== requestOutsideAssortedProductStatus.PENDINGAPPROVAL"
          >
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                Beoordeeld door
              </th>
              <td>{{ requestOutsideAssortedProducts.reviewedByFullName }}</td>
            </tr>
            <tr>
              <th
                scope="row"
                class="text-left"
              >
                Laatste wijziging
              </th>
              <td>{{ formatDate(requestOutsideAssortedProducts.utcDateModified) }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <template v-if="requestOutsideAssortedProducts.participantListUrl || requestOutsideAssortedProducts.otherAttachmentUrl">
        <recess-divider />
        <h4 class="u-textcolor-primary"> Bijlage</h4>
        <div>
          <a
            v-if="requestOutsideAssortedProducts.participantListUrl"
            :href="requestOutsideAssortedProducts.participantListUrl"
            target="_blank"
          >Deelnemerslijst</a>
        </div>
        <div>
          <a
            v-if="requestOutsideAssortedProducts.otherAttachmentUrl"
            :href="requestOutsideAssortedProducts.otherAttachmentUrl"
            target="_blank"
          >Andere bijlage</a>
        </div>
      </template>

      <recess-divider />
      
      <template
        v-if="requestOutsideAssortedProducts.status === requestOutsideAssortedProductStatus.PENDINGAPPROVAL"
      >
        <recess-button
          class="u-position-right ml-4 mb-3 mb-md-0 col-12 col-md-auto"
          data-test="approve-button"
          title="Goedkeuren"
          variant="primary"
          @click="approve()"
        />
        <recess-button
          data-test="reject-button"
          class="u-position-right mb-3 mb-md-0 col-12 col-md-auto"
          title="Afkeuren"
          variant="tertiary"
          @click="reject()"
        />
      </template>
    </template>
    <recess-button
      :url="{ name: 'my-team', query: { 'my-team-tab': myTeamTabs.COURSEREQUESTS.key } }"
      class="col-12 col-md-auto mb-3"
      title="Terug"
      variant="tertiary"
    />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import routePerimeter from '../../../perimeters/routePerimeter'
import requestsOutsideAssortedProductsClient from '../../../api/requestOutsideAssortedProductsClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import priceHelper from '../../../modules/priceFormatter'
import DateUtils from '../../../utils/DateUtils'
import { myTeamTabs } from '../../../constants/tabNames'
import * as requestOutsideAssortedProductStatus from '../../../constants/requestOutsideAssortedProductStatus'

export default {
    name: 'CourseRequest',
    perimeters: [routePerimeter],
    components: {
        LoaderComponent
    },
    data() {
        return {
            isFetching: false,
            requestOutsideAssortedProducts: null,
            showErrorMessage: false,
            myTeamTabs,
            requestOutsideAssortedProductStatus
        }
    },
    created() {
        this.getRequestOutsideAssortedProductsById()
    },
    methods: {
        async getRequestOutsideAssortedProductsById() {
            try {
                this.isFetching = true
                const id = this.$route?.params?.id
                await requestsOutsideAssortedProductsClient
                    .getRequestOutsideAssortedProductsById(id)
                    .then(response => {
                        this.requestOutsideAssortedProducts = response
                    })
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.isFetching = false
            }
        },
        async approve() {
            try {
                this.isFetching = true
                const id = this.$route?.params?.id
                await requestsOutsideAssortedProductsClient
                    .approveRequestOutsideAssortedProductsById(id)
                    .then(() => {
                        this.$router.replace({
                            name: 'my-team',
                            query: { 'my-team-tab': myTeamTabs.COURSEREQUESTS.key }
                        })
                    })
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.isFetching = false
            }
        },
        async reject() {
            try {
                this.isFetching = true
                const id = this.$route?.params?.id
                await requestsOutsideAssortedProductsClient
                    .rejectRequestOutsideAssortedProductsById(id)
                    .then(() => {
                        this.$router.replace({
                            name: 'my-team',
                            query: { 'my-team-tab': myTeamTabs.COURSEREQUESTS.key }
                        })
                    })
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.isFetching = false
            }
        },
        formatPrice: priceHelper.formatPrice,
        formatDate: e => DateUtils.formatDate(e)
    }
}
</script>

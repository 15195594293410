<template>
  <recess-layout-static v-if="$isAllowed('routeApprovalPage')">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>
    <template v-if="enrollment">
      <reject-enrollment-popup-component
        :is-visible="showRejectEnrollmentLightbox"
        :enrollment-id="enrollment.enrollmentId"
        @close="showRejectEnrollmentLightbox = false"
        @rejected="handleRedirect"
      />

      <recess-divider />
      <h1>Inzage inschrijving</h1>

      <recess-divider />

      <enrollment-approval-details />

      <recess-divider />

      <enrollment-first-approval
        v-if="isFirstPendingApproval && $isAllowed('approveEnrollmentAsFirstApprover', enrollment)"
        :enrollment="enrollment"
        :has-valid-start-moments="enrollmentHasValidStartMoments"
        @reject="showRejectEnrollmentLightbox = true"
        @redirect="handleRedirect"
      />
      <enrollment-second-approval
        v-if="isSecondPendingApproval && $isAllowed('approveEnrollmentAsSecondApprover', enrollment)"
        :enrollment="enrollment"
        :has-valid-start-moments="enrollmentHasValidStartMoments"
        @reject="showRejectEnrollmentLightbox = true"
        @redirect="handleRedirect"
      />
      <template v-if="!enrollmentHasValidStartMoments">
        <recess-divider />
        <recess-alert
          type="error"
          text="Alle gekozen startmomenten bestaat niet (meer) binnen deze ontwikkelactiviteit. U kunt de inschrijving afkeuren/annuleren en de student een nieuwe inschrijving laten doen met geldige startmomenten."
        />
      </template>
      <recess-divider v-if="!showErrorMessage" />
    </template>
    <recess-alert
      v-show="showErrorMessage"
      class="my-5"
      text="Deze inschrijving is niet bekend. Probeer een andere inschrijving."
      type="error"
    />
  </recess-layout-static>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import EnrollmentFirstApproval from '../../organisms/Enrollments/EnrollmentApprovalComponents/EnrollmentFirstApproval.vue'
import EnrollmentApprovalDetails from '../../organisms/Enrollments/EnrollmentApprovalComponents/EnrollmentApprovalDetails.vue'
import EnrollmentSecondApproval from '../../organisms/Enrollments/EnrollmentApprovalComponents/EnrollmentSecondApproval.vue'
import RejectEnrollmentPopupComponent from '../../molecules/RejectEnrollmentPopupComponent/RejectEnrollmentPopupComponent.vue'
import enrollmentPerimeter from '../../../perimeters/enrollmentPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import * as enrollmentStatus from '../../../constants/enrollmentStatus'

export default {
    name: 'EnrollmentApprovalPage',
    perimeters: [enrollmentPerimeter, routePerimeter],
    components: {
        RejectEnrollmentPopupComponent,
        EnrollmentFirstApproval,
        EnrollmentApprovalDetails,
        EnrollmentSecondApproval,
        LoaderComponent
    },
    beforeRouteEnter(_, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    data() {
        return {
            isFetchingDelegationActions: false,
            showRejectEnrollmentLightbox: false,
            showErrorMessage: false,
            prevRoute: null
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollment', 'isFetchingEnrollment', 'isFetchingCourse']),
        ...mapGetters('enrollmentModule', ['enrollmentHasValidStartMoments']),
        isFirstPendingApproval() {
            return this.enrollment?.statusId === enrollmentStatus.PENDINGAPPROVAL
        },
        isSecondPendingApproval() {
            return this.enrollment?.statusId === enrollmentStatus.PENDINGSECONDAPPROVAL
        },
        isLoading() {
            return this.isFetchingEnrollment || this.isFetchingCourse || this.isFetchingDelegationActions
        }
    },
    async mounted() {
        await this.fetchEnrollmentAndCourse()
        // Refresh user delegation actions
        await this.fetchUserDelegationActions()
    },
    methods: {
        ...mapActions('userModule', ['fetchUserDelegationActions']),
        ...mapActions('enrollmentModule', ['getEnrollment', 'getCourse']),
        async fetchEnrollmentAndCourse() {
            try {
                const enrollmentId = this.$route.query.enrollment

                await this.getEnrollment(enrollmentId)
                await this.getCourse(this.enrollment?.eduMSCourseId)
            } catch (error) {            
                this.showErrorMessage = true
                throw new Error(error)
            }
        },
        handleRedirect() {
            if (this.prevRoute?.name) {
                this.$router.replace(this.prevRoute)
                return
            }
            
            if (this.$route.query?.delegatedAction) {
                this.$router.replace({ name: 'my-actions', query: {
                    ...this.$route.query,
                    delegatedAction: undefined 
                }})

                return
            }

            this.$router.replace({ name: 'my-team', query: this.$route.query })
        }
    }
}
</script>





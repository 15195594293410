<template>
    <div>
        <recess-divider :display-medium-viewport="false" :display-small-viewport="false" />
        <transition name="fade" mode="out-in">
            <loader-component v-if="isLoading" overlay="white" color="primary" />
        </transition>

        <div class="p-0 pl-md-4 col-12 col-md-6">
            <p>
                {{ intro }}
            </p>

            <div v-if="$isAllowed('routeAsCoordinator')">
                <recess-button
                    title="Voeg een nieuw budget toe"
                    variant="primary"
                    data-test="create-budget-btn"
                    :url="{ name: 'create-budget' }"
                />
                <recess-divider />
            </div>
        </div>

        <table-filters-component
            :show-reset-filters="filterApplied"
            toggle-button-text="Filter medewerkers"
            @reset-filters="removeFilters"
        >
            <recess-select
                slot="right"
                :options="pageSizeOptions"
                :default-value="pagination.take"
                @input="(value) => setPageSize(value)"
            />
            <template #filters>
                <recess-input
                    :label-text="filters.fullName.labelText"
                    :value="filters.fullName.value"
                    data-test="filter-by-name-input"
                    class="mb-3"
                    @keyup.enter.native="fetchBudgets"
                    @input="(value) => setFilter({ value: value, id: filters.fullName.id })"
                />
                <recess-input
                    :label-text="filters.employeeNumber.labelText"
                    :value="filters.employeeNumber.value"
                    data-test="filter-by-employee-number-input"
                    class="mb-3"
                    @keyup.enter.native="fetchBudgets"
                    @input="(value) => setFilter({ value: value, id: filters.employeeNumber.id })"
                />
                <recess-input
                    :label-text="filters.emailAddress.labelText"
                    :value="filters.emailAddress.value"
                    data-test="filter-by-email-input"
                    class="mb-3"
                    @keyup.enter.native="fetchBudgets"
                    @input="(value) => setFilter({ value: value, id: filters.emailAddress.id })"
                />
                <recess-input
                    :label-text="filters.department.labelText"
                    :value="filters.department.value"
                    data-test="filter-by-department-input"
                    @keyup.enter.native="fetchBudgets"
                    @input="(value) => setFilter({ value: value, id: filters.department.id })"
                />
            </template>
            <template #footer>
                <recess-button
                    title="Filter"
                    variant="primary"
                    class="col-12 col-sm-auto"
                    @click="fetchBudgets"
                />
            </template>
        </table-filters-component>

        <recess-divider />

        <transition name="fade">
            <div v-if="budgets && budgets.length">
                <recess-table>
                    <recess-table-head>
                        <recess-table-row>
                            <recess-table-head-item
                                v-for="(header, index) in headers"
                                :key="index"
                                :sortable="header.isSortable"
                                :sort-direction="getSortDirection(header)"
                                @click.native="setSorting(header)"
                            >
                                {{ header.title }}
                            </recess-table-head-item>
                        </recess-table-row>
                    </recess-table-head>
                    <recess-table-body>
                        <recess-table-row v-for="budget in budgets" :key="budget.id">
                            <recess-table-cell :data-label="headers[0].title">
                                <span>{{ budget.user && budget.user.emailAddress }}</span>
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[1].title">
                                <span>{{ budget.name }}</span>
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[2].title">
                                <span>{{
                                    budget.startDate ? formatDate(budget.startDate) : '-'
                                }}</span>
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[3].title">
                                <span>{{ budget.endDate ? formatDate(budget.endDate) : '-' }}</span>
                            </recess-table-cell>
                            <recess-table-cell :data-label="headers[4].title">
                                <span>{{ formatPrice(budget.currentAmount) }}</span>
                            </recess-table-cell>
                            <recess-table-cell v-if="isTeamLead" :data-label="headers[5].title">
                                <span>{{ budget.approvalRequired ? 'Ja' : 'Nee' }}</span>
                            </recess-table-cell>
                            <recess-table-cell
                                v-if="isCoordinator"
                                :data-label="headers[headers.length - 2].title"
                            >
                                <span>{{ getModifiedBy(budget) }}</span>
                            </recess-table-cell>
                            <recess-table-cell
                                v-if="isCoordinator"
                                class="d-flex align-items-center"
                            >
                                <router-link
                                    :to="{
                                        name: 'edit-budget',
                                        params: { id: budget.id },
                                        query: $route.query
                                    }"
                                    class="u-line-height-0"
                                >
                                    <recess-icon icon="edit" class="qa-edit-icon" />
                                </router-link>
                                <recess-divider variant="medium" vertical show-line />
                                <recess-icon
                                    class="qa-bin-icon"
                                    icon="bin"
                                    @click="showConfirmationBeforeDelete(budget.id)"
                                />
                            </recess-table-cell>
                        </recess-table-row>
                    </recess-table-body>
                </recess-table>
                <recess-divider />
                <pagination-component
                    :current-page-number="currentPageNumber"
                    :total-pages="totalPages"
                    @goToPage="paginationClicked"
                />
            </div>
        </transition>

        <recess-alert v-if="showNoBudgetsMessage" text="Er zijn op dit moment geen budgetten." />

        <recess-modal
            modal-size="small"
            :show-modal="showConfirmationModal"
            @close="showConfirmationModal = false"
        >
            <template slot="modal-title">
                <h3>Weet u zeker dat u dit budget wilt verwijderen?</h3>
            </template>
            <div slot="modal-footer" class="d-flex flex-column">
                <div>
                    <recess-button
                        class="u-position-left u-min-width-200 col-12 col-md-auto"
                        title="Annuleren"
                        variant="primary"
                        @click="showConfirmationModal = false"
                    />
                    <recess-button
                        class="u-position-right u-min-width-200 col-12 col-md-auto ml-3"
                        title="Bevestigen"
                        variant="secondary"
                        @click.native="deleteBudget"
                    />
                </div>
            </div>
        </recess-modal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import { searchBudgets, deleteBudget } from '../../../api/budgetClient'
import DateUtils from '../../../utils/DateUtils'
import priceFormatter from '../../../modules/priceFormatter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'
import TableFiltersComponent from './TableFiltersComponent.vue'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'

export default {
    components: {
        LoaderComponent,
        PaginationComponent,
        TableFiltersComponent
    },
    perimeters: [routePerimeter],
    data() {
        return {
            isFetchingBudgets: false,
            isDeletingBudget: false,
            showConfirmationModal: false,
            budgets: [],
            showNoBudgetsMessage: false,
            budgetToDelete: null,
            pagination: {
                skip: 0,
                take: 10,
                pageIndex: 1,
                totalCount: 0
            },
            commonHeaders: [
                {
                    id: 'email',
                    keys: ['user/emailAddress'],
                    title: 'Medewerker e-mailadres',
                    isSortable: true
                },
                { id: 'name', keys: ['name'], title: 'Soort Budget', isSortable: true },
                {
                    id: 'startDate',
                    keys: ['startDate'],
                    title: 'Ingangsdatum budget',
                    isSortable: true
                },
                { id: 'endDate', keys: ['endDate'], title: 'Einddatum budget', isSortable: true },
                {
                    id: 'amount',
                    keys: ['currentAmount'],
                    title: 'Resterend budget',
                    isSortable: true
                }
            ],
            teamLeadHeaders: [
                {
                    id: 'approval',
                    keys: ['approvalRequired'],
                    title: 'Goedkeuring vereist',
                    isSortable: true
                }
            ],
            coordinatorHeaders: [
                {
                    id: 'modifiedBy',
                    keys: [
                        'modifiedBy/firstName',
                        'modifiedBy/middleName',
                        'modifiedBy/lastName',
                        'isImported'
                    ],
                    title: 'Laatste wijziging door',
                    isSortable: true
                },
                { id: '', keys: [''], title: '' }
            ],
            sorting: {
                id: 'id',
                keys: ['id'],
                direction: ODataSortingDirections.DESC
            },
            pageSizeOptions: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            filters: {
                fullName: {
                    labelText: 'Zoek op naam medewerker',
                    id: 'fullName',
                    keys: ['user/firstName', 'user/middleName', 'user/lastName'],
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    keyCombination: 'or'
                },
                employeeNumber: {
                    labelText: 'Zoek op personeelsnummer',
                    id: 'employeeNumber',
                    keys: 'user/employeeNumber',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    keyCombination: 'or'
                },
                emailAddress: {
                    labelText: 'Zoek op e-mail',
                    id: 'emailAddress',
                    keys: 'user/emailAddress',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    keyCombination: 'or'
                },
                department: {
                    labelText: 'Zoek op organisatieonderdeel',
                    id: 'department',
                    keys: 'user/userDepartment/name',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    keyCombination: 'or'
                }
            }
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapGetters('userModule', ['companyTeamleadersIds']),
        ...mapGetters('accountModule', ['accountName']),
        isLoading() {
            return this.isFetchingBudgets || this.isDeletingBudget
        },
        currentPageNumber() {
            if (this.budgets) {
                return this.pagination.pageIndex
            }
            return 1
        },
        totalPages() {
            if (this.budgets) {
                return Math.ceil(this.pagination.totalCount / this.pagination.take)
            }
            return 1
        },
        headers() {
            let result = [...this.commonHeaders]
            if (this.$isAllowed('routeAsTeamleader')) {
                result = [...result, ...this.teamLeadHeaders]
            }
            if (this.$isAllowed('routeAsCoordinator')) {
                result = [...result, ...this.coordinatorHeaders]
            }
            return result
        },
        isCoordinator() {
            return this.$isAllowed('routeAsCoordinator')
        },
        isTeamLead() {
            return this.$isAllowed('routeAsTeamleader')
        },
        intro() {
            if (this.isCoordinator) {
                return "Op deze pagina ziet u al de budgetten van collega's en kunt deze beheren. U kunt via de 'Bewerk' knop een budget toevoegen of aanpassen."
            }
            return 'Op deze pagina ziet u de (resterende) Ontwikkelbudgetten van uw medewerkers.'
        },
        filterApplied() {
            return (
                Object.values(this.filters).filter((ssf) => {
                    return !!ssf.value
                }).length > 0
            )
        }
    },
    created() {
        this.pagination.take = this.getTakeFromSessionStorage()

        // Deserialize the dataquery object and set it
        if (this.$route?.query?.tableDataQueries) {
            const deserializeDataQuery = JSON.parse(atob(this.$route.query.tableDataQueries))

            // Set default input value
            if (deserializeDataQuery?.myTeamBudget) {
                this.filters.fullName.value = deserializeDataQuery.myTeamBudget || ''
            }
        }

        this.fetchBudgets()
    },
    methods: {
        getModifiedBy(budget) {
            if (
                budget?.isImported &&
                (!budget?.budgetHistory?.length || budget?.budgetHistory?.length <= 1)
            )
                return 'Geïmporteerd'
            if (
                budget?.budgetTemplateId &&
                (!budget?.budgetHistory?.length || budget?.budgetHistory?.length <= 1)
            )
                return this.accountName
            if (!budget?.modifiedByFullName) return 'Extern'
            return budget.modifiedByFullName
        },
        getODataQueryFilter() {
            return Object.values(this.filters).map((ssf) => {
                return new ODataQueryFilter(
                    ssf.keys,
                    ssf.operator,
                    ssf.value,
                    ssf.keyCombination,
                    ssf.valueCombination
                )
            })
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.pagination.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.pagination.skip = 0

            // set the new page size value in the data query and in the sessopm storage
            this.pagination.take = value
            sessionStorage.setItem('MyTeamBudgetsTable/take', JSON.stringify(value))
            this.fetchBudgets()
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('MyTeamBudgetsTable/take')) {
                try {
                    return parseInt(
                        JSON.parse(sessionStorage.getItem('MyTeamBudgetsTable/take')),
                        10
                    )
                } catch {
                    return this.pagination.take
                }
            }
            return this.pagination.take
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.sorting.id === header.id) {
                this.sorting.direction =
                    this.sorting.direction === ODataSortingDirections.ASC
                        ? ODataSortingDirections.DESC
                        : ODataSortingDirections.ASC
            } else {
                this.sorting.direction = ODataSortingDirections.DESC
            }
            this.sorting.id = header.id
            this.sorting.keys = header.keys
            this.fetchBudgets()
        },
        getSortDirection(header) {
            if (!header.id || !header.isSortable) return null

            if (this.sorting.id !== header.id) {
                return null
            }

            return this.sorting.direction ? this.sorting.direction.toLowerCase() : null
        },
        setFilter({ value, id }) {
            Object.values(this.filters).forEach((ssf) => {
                if (ssf.id === id) ssf.value = value
            })
            this.pagination.pageIndex = 1
        },
        removeFilters() {
            Object.values(this.filters).forEach((ssf) => {
                ssf.value = ''
            })
            this.fetchBudgets()
        },
        async paginationClicked(pageNumber) {
            this.pagination.skip = this.pagination.take * (pageNumber - 1)
            await this.fetchBudgets()
        },
        async fetchBudgets() {
            try {
                if (!this.user?.accountId) {
                    return
                }

                this.isFetchingBudgets = true
                this.showNoBudgetsMessage = false

                const oDataBaseQuery = new ODataQueryBuilder()
                    .setPagination(this.pagination.skip, this.pagination.take)
                    .setSort(this.sorting.keys, this.sorting.direction)
                    .setIncludes('BudgetHistory')
                    .addFilter(
                        new ODataQueryFilter(
                            'user/accountId',
                            ODataFilterOperations.EQUALS,
                            this.user.accountId
                        )
                    )
                    .addFilter(
                        new ODataQueryFilter('user/isDeleted', ODataFilterOperations.EQUALS, false)
                    )

                // if not coordinator but is team lead then add filter by user company id
                if (!this.isCoordinator && this.isTeamLead) {
                    oDataBaseQuery.addFilter(
                        new ODataQueryFilter(
                            'user/companyId',
                            ODataFilterOperations.IN,
                            this.companyTeamleadersIds.join(', ')
                        )
                    )
                }
                // add user filters
                const filters = this.getODataQueryFilter()
                oDataBaseQuery.addFilters(filters)

                const oDataQuery = oDataBaseQuery.build()
                const response = await searchBudgets(oDataQuery)

                this.budgets = response?.items
                this.pagination.totalCount = response?.totalCount
                this.pagination.pageIndex = response?.pageIndex

                // also update the url
                const deserializeDataQuery = this.$route.query.tableDataQueries
                    ? JSON.parse(atob(this.$route.query.tableDataQueries))
                    : {}
                deserializeDataQuery.myTeamBudget = this.filters.fullName.value
                const serializedDataQuery = btoa(JSON.stringify(deserializeDataQuery))
                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        tableDataQueries: serializedDataQuery
                    }
                })

                if (!this.budgets?.length) {
                    this.showNoBudgetsMessage = true
                }
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isFetchingBudgets = false
            }
        },
        async deleteBudget() {
            try {
                this.showConfirmationModal = false
                this.isDeletingBudget = true

                await deleteBudget(this.budgetToDelete).then(() => {
                    this.fetchBudgets()
                })
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isDeletingBudget = false
                this.budgetToDelete = null
            }
        },
        showConfirmationBeforeDelete(budgetId) {
            this.showConfirmationModal = true
            this.budgetToDelete = budgetId
        },
        formatDate: (e) => DateUtils.formatDate(e),
        formatPrice: priceFormatter.formatPrice
    }
}
</script>

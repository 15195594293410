export const decodeBase64 = base64 => {
    try {
        if(typeof base64 !== 'string') throw new Error("Value should be a valid base64 string")

        return JSON.parse(Buffer.from(base64, 'base64').toString())
    } catch {
        throw new Error("Failed to decode base64")
    }
}

export const encodeBase64 = value => {
    try {
        if(!value?.length) return undefined

        return Buffer.from(JSON.stringify(value)).toString( 'base64')
    } catch {
        throw new Error("Failed to encode base64")
    }
}

<template>
    <lightbox-component
        :is-visible="showAssignLearningPath && $isAllowed('assignLearningPath')"
        @close="closeModal"
    >
        <h3>Leerpad toewijzen</h3>
        <transition name="fade" mode="out-in">
            <loader-component v-if="isLoading" overlay="white" color="primary" />
        </transition>
        <table-filters-component
            :show-reset-filters="isStudentsFiltersFilled"
            toggle-button-text="Filter medewerkers"
            @reset-filters="removeStudentsFilters"
        >
            <recess-select
                slot="right"
                :options="studentsPagination.pageSizeFields"
                :default-value="studentsPagination.pageSize"
                class="c-learning-path__toolbar-select--page-size"
                @input="(value) => setStudentsPageSize(value)"
            />
            <template #filters>
                <recess-input
                    :label-text="studentsFilters.fullName.labelText"
                    :value="studentsFilters.fullName.value"
                    class="mb-3"
                    data-test="filter-by-name-input"
                    @keyup.enter.native="getUsers"
                    @input="
                        (value) =>
                            setStudentsFilter({ value: value, id: studentsFilters.fullName.id })
                    "
                />
                <recess-input
                    :label-text="studentsFilters.employeeNumber.labelText"
                    :value="studentsFilters.employeeNumber.value"
                    data-test="filter-by-employee-number-input"
                    class="mb-3"
                    @keyup.enter.native="getUsers"
                    @input="
                        (value) =>
                            setStudentsFilter({
                                value: value,
                                id: studentsFilters.employeeNumber.id
                            })
                    "
                />
                <recess-multi-select
                    v-model="studentsFilters.jobName.value"
                    v-observe-visibility="{ callback: fetchJobNames, once: true }"
                    :label-text="studentsFilters.jobName.labelText"
                    :options="jobNames"
                    data-test="filter-by-job-name-input"
                    class="mb-3"
                    @input="
                        (value) =>
                            setStudentsFilter({ value: value, id: studentsFilters.jobName.id })
                    "
                />
                <recess-input
                    :label-text="studentsFilters.companyName.labelText"
                    :value="studentsFilters.companyName.value"
                    data-test="filter-by-company-name-input"
                    class="mb-3"
                    @keyup.enter.native="getUsers"
                    @input="
                        (value) =>
                            setStudentsFilter({ value: value, id: studentsFilters.companyName.id })
                    "
                />
                <recess-input
                    :label-text="studentsFilters.department.labelText"
                    :value="studentsFilters.department.value"
                    data-test="filter-by-department-input"
                    class="mb-3"
                    @keyup.enter.native="getUsers"
                    @input="
                        (value) =>
                            setStudentsFilter({ value: value, id: studentsFilters.department.id })
                    "
                />
                <recess-input
                    v-if="$isAllowed('displayCostCenter')"
                    :label-text="studentsFilters.costCenter.labelText"
                    :value="studentsFilters.costCenter.value"
                    data-test="filter-by-cost-center-input"
                    class="mb-3"
                    @keyup.enter.native="getUsers"
                    @input="
                        (value) =>
                            setStudentsFilter({ value: value, id: studentsFilters.costCenter.id })
                    "
                />
                <recess-select
                    v-model="studentRoleFilterValue"
                    :default-value="studentRoleFilterValue"
                    :options="studentRoleOptions"
                    data-test="filter-by-role-input"
                    label-text="Zoek op rol"
                    class="mb-3"
                />
                <recess-select
                    v-model="isAssignedFilterValue"
                    :default-value="isAssignedFilterValue"
                    :options="isAssignedOptions"
                    data-test="filter-is-assigned-input"
                    label-text="Zoek op toewijzen"
                />
                <transition name="fade" mode="out-in">
                    <loader-component v-if="isFetchingJobNames" overlay="white" color="primary" />
                </transition>
            </template>
            <template #footer>
                <recess-button
                    title="Filter"
                    variant="primary"
                    class="col-12 col-sm-auto"
                    data-test="submit-filter-btn"
                    @click="getUsers"
                />
            </template>
        </table-filters-component>
        <h5 class="mt-4 mb-3 font-weight-normal">
            <span class="h5">{{ selectedStudents.length }}</span> Geselecteerd
            <span class="h7">({{ totalChanges }} wijzigingen)</span>
        </h5>
        <transition name="fade" mode="out-in">
            <recess-table v-if="users && users.length">
                <recess-table-head>
                    <recess-table-row>
                        <recess-table-head-item
                            v-for="headerItem in tableHeader"
                            :key="headerItem.id"
                            :sortable="headerItem.isSortable"
                            :sort-direction="getSortDirection(headerItem)"
                            @click.native="setSorting(headerItem)"
                        >
                            {{ headerItem.displayTitle }}
                        </recess-table-head-item>
                        <!-- An extra table header (not in the for each because it dirives in content) -->
                        <recess-table-head-item key="selectUsers">
                            <recess-checkbox-input
                                label-text="Selecteer iedereen"
                                :default-checked="allUsersSelected"
                                @input="(checked) => toggleAllSelection(checked)"
                            />
                        </recess-table-head-item>
                    </recess-table-row>
                </recess-table-head>
                <recess-table-body>
                    <recess-table-row v-for="user in users" :key="user.userId">
                        <recess-table-cell :data-label="tableHeader[0].displayTitle">{{
                            user.fullName || '-'
                        }}</recess-table-cell>
                        <recess-table-cell :data-label="tableHeader[1].displayTitle">{{
                            user.jobName || '-'
                        }}</recess-table-cell>
                        <recess-table-cell :data-label="tableHeader[2].displayTitle">
                            {{ getCompanyName(user) || '-' }}
                        </recess-table-cell>
                        <recess-table-cell
                            v-if="$isAllowed('displayCostCenter')"
                            :data-label="tableHeader[3].displayTitle"
                        >
                            {{ user.costCenter || '-' }}
                        </recess-table-cell>
                        <recess-table-cell>
                            <recess-checkbox-input
                                :default-checked="isInSelectedStudents(user.userId)"
                                label-text="Toewijzen"
                                @input="(checked) => checkboxHandler(checked, user)"
                            />
                        </recess-table-cell>
                    </recess-table-row>
                </recess-table-body>
            </recess-table>
        </transition>
        <recess-alert
            v-if="studentsPagination.totalCount == 0 && !isLoading"
            class="mb-3"
            text="Er zijn geen studenten beschikbaar."
        />
        <recess-alert
            v-if="
                totalChanges > selectedUsersThreshold &&
                !learningPathAssignmentIsBlocked &&
                !isLoading
            "
            class="mb-3"
            :text="assignUsersThresholdAlertMessage"
        />
        <recess-alert
            v-if="learningPathAssignmentIsBlocked"
            class="mb-3"
            text="Op dit moment wordt het leerpad gekoppeld aan de geselecteerde medewerkers. Om het proces correct te laten voltooien kunnen er in de tussentijd geen additionele medewerkers aan dit leerpad worden gekoppeld."
        />
        <div class="d-flex justify-content-end">
            <recess-button
                :disabled="learningPathAssignmentIsBlocked"
                variant="primary"
                title="Bevestigen"
                class="col-sm-12 mt-5 mt-md-0 col-md-auto"
                @click.native="submit"
            />
        </div>
        <template v-if="showStudentsPagination">
            <pagination-component
                class="mt-4"
                :current-page-number="studentsPagination.currentPage"
                :total-pages="studentsPagination.totalPages"
                @goToPage="(pageIndex) => setStudentsCurrentPage(pageIndex)"
            />
        </template>
        <recess-alert
            v-if="showErrorMessage"
            type="error"
            text="Er is iets misgegaan. Probeer het later nog een keer."
            class="mt-3"
        />
        <recess-modal :show-modal="showThresholdAssignmentConfirmation" @close="closeConfirm">
            <template slot="modal-title">
                <h3>
                    Het leerpad wordt toegekend aan meer dan
                    {{ selectedUsersThreshold }} medewerkers
                </h3>
            </template>
            <template slot="modal-body">
                <p>
                    U hebt meer dan {{ selectedUsersThreshold }} medewerkers geselecteerd, waardoor
                    de toekenning van het leerpad aan elke gebruiker langer duurt dan normaal. Pas
                    als alle geselecteerde medewerkers het leerpad toegewezen hebben gekregen kunt u
                    geen additionele personen aan dit leerpad toevoegen. U kunt de applicatie verder
                    gewoon blijven gebruiken. Wilt u doorgaan?
                </p>
            </template>
            <div slot="modal-footer" class="d-flex flex-column">
                <div>
                    <recess-button
                        class="u-position-left col-12 col-md-auto"
                        title="Annuleren"
                        variant="primary"
                        @click="closeConfirm"
                    />
                    <recess-button
                        class="u-position-right col-12 col-md-auto ml-3"
                        title="Bevestigen"
                        variant="secondary"
                        @click.native="confirmSelection"
                    />
                </div>
            </div>
        </recess-modal>
    </lightbox-component>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import TableFiltersComponent from '../Tables/TableFiltersComponent.vue'
import {
    getLearningPathAssignedUsers,
    getLearningPathById,
    assignLearningPathUsers
} from '../../../api/learningPathClient'
import userClient from '../../../api/userClient'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LightboxComponent from '../../molecules/LightboxComponent/LightboxComponent.vue'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import * as roles from '../../../constants/roles'

export default {
    perimeters: [learningPathPerimeter, routePerimeter, featuresPerimeter],
    components: {
        LoaderComponent,
        PaginationComponent,
        TableFiltersComponent,
        LightboxComponent
    },
    props: {
        showModal: {
            type: [Boolean],
            default: false
        },
        selectedPath: {
            type: [Number],
            default: null
        },
        assignmentBlocked: {
            type: [Boolean],
            default: false
        }
    },
    data() {
        return {
            selectedUsersThreshold: 500,
            learningPathAssignmentIsBlocked: this.assignmentBlocked,
            showThresholdAssignmentConfirmation: false,
            isFetchingUsers: false,
            isFetchingAssignedUsers: false,
            isFetchingLearningPath: false,
            isFetchingJobNames: false,
            showErrorMessage: false,
            showAssignLearningPath: this.showModal,
            currentPath: null,
            jobNames: [],
            studentsFilters: {
                fullName: {
                    labelText: 'Zoek op naam medewerker',
                    id: 'FullName',
                    keys: ['firstName', 'middleName', 'lastName'],
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    keyCombination: 'or'
                },
                employeeNumber: {
                    labelText: 'Zoek op personeelsnummer',
                    id: 'EmployeeNumber',
                    keys: 'employeeNumber',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS,
                    valueCombination: 'or'
                },
                jobName: {
                    labelText: 'Zoek op functietitel',
                    id: 'JobTitle',
                    keys: 'jobName',
                    value: '',
                    operator: ODataFilterOperations.EQUALS,
                    valueCombination: 'or'
                },
                companyName: {
                    labelText: 'Zoek op afdeling',
                    id: 'CompanyName',
                    keys: 'company/name',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                },
                department: {
                    labelText: 'Zoek op organisatieonderdeel',
                    id: 'Department',
                    keys: 'userDepartment/name',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                },
                costCenter: {
                    labelText: 'Zoek op kostenplaats',
                    id: 'CostCenter',
                    keys: 'costCenter',
                    value: '',
                    operator: ODataFilterOperations.CONTAINS
                }
            },
            studentsPagination: {
                sortField: 'firstName',
                sortDirection: ODataSortingDirections.ASC,
                pageSize: 5,
                currentPage: 1,
                totalCount: -1,
                totalPages: 0,
                pageSizeFields: [
                    { value: 5, displayText: '5' },
                    { value: 10, displayText: '10' },
                    { value: 15, displayText: '15' }
                ]
            },
            users: [],
            userIds: [],
            originalStateUserIds: [],
            selectedStudents: [],
            studentRoleOptions: [
                {
                    displayText: '',
                    value: ''
                },
                {
                    displayText: 'Coördinator',
                    value: roles.COORDINATOR
                },
                {
                    displayText: 'Leidingevende',
                    value: roles.TEAMLEADER
                },
                {
                    displayText: 'Coördinator en Leidingevende',
                    value: `${roles.TEAMLEADER}, ${roles.COORDINATOR}`
                },
                {
                    displayText: 'Iedereen',
                    value: roles.STUDENT
                }
            ],
            studentRoleFilterValue: null,
            isAssignedOptions: [
                {
                    displayText: '',
                    value: null
                },
                {
                    displayText: 'Toegewezen',
                    value: '1'
                },
                {
                    displayText: 'Niet Toegewezen',
                    value: '0'
                }
            ],
            isAssignedFilterValue: null
        }
    },
    computed: {
        ...mapGetters('userModule', ['companyTeamleadersIds']),
        ...mapState('accountModule', ['account']),
        tableHeader() {
            const headers = [
                {
                    id: 'FullName',
                    key: 'firstName',
                    displayTitle: 'Naam',
                    isSortable: true
                },
                {
                    id: 'JobTitle',
                    key: 'jobName',
                    displayTitle: 'Functietitel',
                    isSortable: true
                },
                {
                    id: 'CompanyName',
                    key: 'company/name',
                    displayTitle: 'Afdeling',
                    isSortable: true
                },
                {
                    id: 'CostCenter',
                    key: 'costCenter',
                    displayTitle: 'Kostenplaats',
                    isSortable: true
                }
            ]

            return this.$isAllowed('displayCostCenter')
                ? headers
                : headers.filter((f) => f.id !== 'CostCenter')
        },
        isLoading() {
            return (
                this.isFetchingUsers || this.isFetchingAssignedUsers || this.isFetchingLearningPath
            )
        },
        showStudentsPagination() {
            return this.users.length > 0
        },
        isStudentsFiltersFilled() {
            return (
                Object.values(this.studentsFilters).filter((ssf) => {
                    return !!ssf.value
                }).length > 0 || !!this.studentRoleFilterValue
            )
        },
        allUsersSelected() {
            return !this.userIds.some((u) => !this.selectedStudents.includes(u))
        },
        assignUsersThresholdAlertMessage() {
            return `Indien u het leerpad aan meer dan ${this.selectedUsersThreshold} personen toekent dan wordt dit proces op de achtergrond uitgevoerd en kan wat tijd in beslag nemen`
        },
        totalChanges() {
            let total = 0
            // get users removed
            total += this.originalStateUserIds.filter(
                (id) => !this.selectedStudents.includes(id)
            ).length
            // get users added
            total += this.selectedStudents.filter(
                (id) => !this.originalStateUserIds.includes(id)
            ).length
            return total
        }
    },
    watch: {
        showModal(newValue) {
            this.showAssignLearningPath = newValue
        },
        assignmentBlocked(newValue) {
            this.learningPathAssignmentIsBlocked = newValue
        },
        async showAssignLearningPath(newValue, oldValue) {
            if (newValue && !oldValue) {
                if (!this.currentPath || this.selectedPath !== this.currentPath) {
                    this.currentPath = this.selectedPath
                    await this.cleanComponent()
                    await this.getAssignedUsers()
                    await this.getUsers()
                }

                // always fetch learning path if showing the modal to check for updated status of running process if there is a background process running
                if (this.learningPathAssignmentIsBlocked) {
                    await this.getLearningPath()
                }
            }

            // bubble up change
            this.$emit('update:showModal', newValue)
        }
    },
    beforeDestroy() {
        this.cleanComponent()
    },
    methods: {
        async fetchJobNames(isVisible) {
            if (!isVisible || this.jobNames?.length) return

            try {
                this.isFetchingJobNames = true
                const jobNames = await userClient.fetchUserJobNames()

                this.jobNames = jobNames.map((jobName) => {
                    return {
                        displayText: jobName,
                        value: jobName
                    }
                })
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingJobNames = false
            }
        },
        closeModal() {
            this.showAssignLearningPath = false
        },
        closeConfirm() {
            this.showThresholdAssignmentConfirmation = false
        },
        cleanComponent() {
            this.selectedStudents = []
            this.originalStateUserIds = []
            this.showErrorMessage = false
            // reset sorting options and filters to default
            this.studentsPagination.sortField = 'firstName'
            this.studentsPagination.sortDirection = ODataSortingDirections.ASC
            this.studentsPagination.pageSize = 5
            this.studentsPagination.currentPage = 1
            this.studentsPagination.totalCount = -1
            this.studentsPagination.totalPages = 0
            this.cleanStudentsFilters()
        },
        removeStudentsFilters() {
            this.cleanStudentsFilters()
            this.getUsers()
        },
        cleanStudentsFilters() {
            Object.values(this.studentsFilters).forEach((ssf) => {
                ssf.value = ''
            })
            this.studentRoleFilterValue = null
            this.isAssignedFilterValue = null
        },
        setStudentsPageSize(size) {
            this.studentsPagination.currentPage = 1
            this.studentsPagination.pageSize = size
            this.getUsers()
        },
        setStudentsCurrentPage(index) {
            this.studentsPagination.currentPage = index
            this.getUsers()
        },
        getCompanyName(user) {
            return user?.company?.name
        },
        setStudentsFilter({ value, id }) {
            Object.values(this.studentsFilters).forEach((ssf) => {
                if (ssf.id === id) ssf.value = value
            })
            this.studentsPagination.currentPage = 1
        },
        isInSelectedStudents(userId) {
            return this.selectedStudents.some((studentId) => studentId === userId)
        },
        checkboxHandler(checked, user) {
            if (checked) {
                this.selectedStudents.push(user.userId)
            } else {
                this.selectedStudents = this.selectedStudents.filter(
                    (studentId) => studentId !== user.userId
                )
            }
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.studentsPagination.sortField === header.key) {
                this.studentsPagination.sortDirection =
                    this.studentsPagination.sortDirection === ODataSortingDirections.ASC
                        ? ODataSortingDirections.DESC
                        : ODataSortingDirections.ASC
            } else {
                this.studentsPagination.sortDirection = ODataSortingDirections.DESC
            }
            this.studentsPagination.sortField = header.key
            // sends user back to page one and triggers search
            this.getUsersFromFilter()
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.studentsPagination.sortField !== header.key) {
                return null
            }

            return this.studentsPagination.sortDirection
                ? this.studentsPagination.sortDirection.toLowerCase()
                : null
        },
        getAssignedUsers() {
            this.isFetchingAssignedUsers = true
            getLearningPathAssignedUsers(this.currentPath)
                .then((response) => {
                    const pathUsers = response?.userIds ?? []
                    this.selectedStudents = [...pathUsers]
                    this.originalStateUserIds = [...pathUsers]
                    this.showErrorMessage = false
                })
                .catch((error) => {
                    console.error(error)
                    this.showErrorMessage = true
                })
                .then(() => {
                    this.isFetchingAssignedUsers = false
                })
        },
        getUsersFromFilter() {
            this.setStudentsCurrentPage(1)
        },
        // should probably eventually refactor this logic from the enrollment store module, would be way easier using composition api in Vue 3
        async getUsers() {
            try {                
                this.isFetchingUsers = true
                const filters = this.getODataQueryFilter()
                const roleFilter = this.getStudentRoleFilter()
                const isAssignedFilter = this.getIsAssignFilter()

                let dataQuery = new ODataQueryBuilder()
                    .setSort(
                        this.studentsPagination.sortField,
                        this.studentsPagination.sortDirection
                    )
                    .setPagination(
                        this.studentsPagination.pageSize *
                            (this.studentsPagination.currentPage - 1),
                        this.studentsPagination.pageSize
                    )
                    .addDynamicFilter(roleFilter)
                    .addDynamicFilter(isAssignedFilter)
                    .addFilters(filters)
                    .setFields('firstName, middleName, lastName, jobName, company.name, costCenter')

                dataQuery = this.addRoleFilters(dataQuery)
                // filter only by enabled users
                dataQuery.addFilter(
                    new ODataQueryFilter('isEnabled', ODataFilterOperations.EQUALS, true)
                )

                const userResponse = await userClient.searchUsers(dataQuery.build())

                const totalCount = userResponse?.totalCount ?? 0
                this.users = userResponse?.items ?? []
                this.studentsPagination.totalCount = totalCount
                this.studentsPagination.totalPages = Math.ceil(
                    totalCount / this.studentsPagination.pageSize
                )

                const userIdsResponse = await this.getUserIds()
                this.userIds = userIdsResponse.items
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isFetchingUsers = false
            }
        },
        async getLearningPath() {
            this.isFetchingLearningPath = true
            getLearningPathById(this.currentPath)
                .then((response) => {
                    this.learningPathAssignmentIsBlocked = response?.hasRunningBackgroundProcess
                })
                .catch((error) => {
                    console.error(error)
                    this.showErrorMessage = true
                })
                .then(() => {
                    this.isFetchingLearningPath = false
                })
        },
        submit() {
            if (this.totalChanges > this.selectedUsersThreshold) {
                // show confirmation window
                this.showThresholdAssignmentConfirmation = true
            } else {
                this.confirmSelection()
            }
        },
        confirmSelection() {
            // close modal confirmation first
            this.showThresholdAssignmentConfirmation = false

            this.isFetchingAssignedUsers = true
            assignLearningPathUsers({
                learningPathId: this.currentPath,
                userIds: this.selectedStudents
            })
                .then(() => {
                    // close all modals
                    this.showAssignLearningPath = false
                    this.showErrorMessage = false
                    // set the original state to equal the changes made
                    this.originalStateUserIds = [...this.selectedStudents]
                    this.$emit('assigned')
                })
                .catch((error) => {
                    console.error(error)
                    this.showErrorMessage = true
                })
                .then(() => {
                    this.isFetchingAssignedUsers = false
                })
        },
        async toggleAllSelection(checked) {
            const userIdResult = await this.getUserIds()
            const userIds = userIdResult.items

            if (checked) {
                this.selectedStudents = [...new Set(this.selectedStudents.concat(userIds))]
            } else {
                this.selectedStudents = this.selectedStudents.filter(
                    (userid) => !userIds.includes(userid)
                )
            }
        },
        async getUserIds() {
            try {
                this.isFetchingUsers = true

                const filters = this.getODataQueryFilter()
                const roleFilter = this.getStudentRoleFilter()

                // We want all the users based on the filters (no pagination basically)
                let dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .addDynamicFilter(roleFilter)
                    .addFilters(filters)

                dataQuery = this.addRoleFilters(dataQuery)
                // filter only by enabled users
                dataQuery.addFilter(
                    new ODataQueryFilter('isEnabled', ODataFilterOperations.EQUALS, true)
                )
                const oDataQuery = dataQuery.build()

                return await userClient.searchUserIds(oDataQuery)
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingUsers = false
            }
        },
        getODataQueryFilter() {
            return Object.values(this.studentsFilters).map((ssf) => {
                return new ODataQueryFilter(
                    ssf.keys,
                    ssf.operator,
                    ssf.value,
                    ssf.keyCombination,
                    ssf.valueCombination
                )
            })
        },
        getStudentRoleFilter() {
            if (this.studentRoleFilterValue) {
                switch (this.studentRoleFilterValue) {
                    case roles.COORDINATOR:
                        return `userRoles/any(r: r/role/name eq '${roles.COORDINATOR}')`
                    case roles.TEAMLEADER:
                        return `userRoles/any(r: r/role/name eq '${roles.TEAMLEADER}')`
                    case `${roles.TEAMLEADER}, ${roles.COORDINATOR}`:
                        return `userRoles/any(r: r/role/name eq '${roles.TEAMLEADER}' or r/role/name eq '${roles.COORDINATOR}')`
                    case roles.STUDENT:
                        return null
                    default:
                        return null
                }
            }
            return null
        },
        getIsAssignFilter(){  
            const findAssignedUsersQuery = ` userId in (${this.selectedStudents.join(',')})`
            switch (this.isAssignedFilterValue){
                case '1': // Assigned
                    if (!this.selectedStudents.length){                    
                        return ` 1 eq 0` 
                    }                    
                    return  findAssignedUsersQuery

                case '0': // Not Assigned
                    if (!this.selectedStudents.length) {      
                        return null
                    }                    
                    return ` not(${findAssignedUsersQuery})`             

                default:
                    return null
            }            
        },
        addRoleFilters(dataQuery) {
            // check user role, if coordinator filter by account, otherwise should filter by company (assumes user is team leader)
            if (this.$isAllowed('routeAsCoordinator')) {
                dataQuery.addFilter(
                    new ODataQueryFilter(
                        'accountId',
                        ODataFilterOperations.EQUALS,
                        this.account.accountId
                    )
                )
            } else {
                dataQuery.addFilter(
                    new ODataQueryFilter(
                        'companyId',
                        ODataFilterOperations.IN,
                        this.companyTeamleadersIds.join(', ')
                    )
                )
            }
            return dataQuery
        }
    }
}
</script>

<template>
  <section>
    <facet-filters :facets="facets" @facet-change="selectedStartMoments => $emit('facet-change', selectedStartMoments)" />

    <recess-table>
      <recess-table-head>
        <recess-table-row>
          <recess-table-head-item
            v-for="(headerItem, index) in tableHeader"
            :key="index"
          >
            {{ headerItem.displayTitle }}
          </recess-table-head-item>
        </recess-table-row>
      </recess-table-head>
      <recess-table-body v-if="startMoments && startMoments.length" data-test="start-moment-table">
        <recess-table-row
          v-for="(startMoment, index) in startMoments"
          :key="index"
          :is-disabled="startMoment.blockFullyBookedIncompanyStartMoment"
          :data-test-id="`start-moment-row-${startMoment.externalId}`"
          data-test="start-moment-row"
          
        >
          <recess-table-cell 
            v-if="startMoment.choiceText"
            data-label="Keuze"
            class="d-flex justify-content-between align-items-center"
          >
            {{ startMoment.choiceText }}
          </recess-table-cell>
          <recess-table-cell 
            data-label="Locatie"
            class="d-flex justify-content-between align-items-center"
          >
            {{ startMoment.city }}
            <recess-tooltip
              v-if="!!startMoment.capacityWarningText"
              class="d-flex flex-column"
            >
              <recess-icon
                class="p-0 ml-2"
                icon="danger"
                color="warning"
              />
              <template #content>
                <span class="d-block u-max-width-500">{{ startMoment.capacityWarningText }}</span>
              </template>
            </recess-tooltip>
          </recess-table-cell>
          <recess-table-cell
            class="d-flex justify-content-between align-items-center"
            data-label="Datum"
            data-test="start-moment-calendar"
          >
            <start-moment-date-component 
              :course="course"
              :start-moment="startMoment" 
            />
          </recess-table-cell>
          <recess-table-cell
            v-if="showCapacity"
            data-label="Bezetting"
            class="d-flex justify-content-between align-items-center"
          >
            <participants
              :start-moment="startMoment"
              class="mr-2 mb-2 mb-md-0"
            />
          </recess-table-cell>
          <recess-table-cell
            v-if="$isAllowed('displayPricingInformation') && startMoment.price"
            :data-label="priceLabel"
            class="d-flex justify-content-between align-items-center"
          >
            <div data-test="startmoment-price">{{ startMoment.price }}</div>
          </recess-table-cell>
          <recess-table-cell v-if="displayStartmomentActionButton">
            <slot
              :start-moment="startMoment"
              name="startmoment-action-button"
            >
              <recess-button
                v-if="displayEnrollButton"
                class="col-12 col-md-auto"
                :disabled="startMoment.blockFullyBookedIncompanyStartMoment"
                title="Inschrijven"
                variant="primary"
                data-test="enroll-startmoment-btn"
                @click="resetStateAndStartEnrollment(startMoment.startMomentId)"
              />
            </slot>
          </recess-table-cell>
        </recess-table-row>
      </recess-table-body>
    </recess-table>

    <pagination-component
      class="mt-4"
      :current-page-number="pageNumber"
      :total-pages="totalPages"
      @goToPage="val => $emit('pagination-clicked', val)"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'

import courseAccessStatuses from '../../../constants/courseAccessStatuses'
import courseAccessHelper from '../../../modules/courseAccessHelper'
import coursePriceHelper from "../../../modules/coursePriceHelper"
import priceFormatter from '../../../modules/priceFormatter'

import Participants from '../../atoms/Participants/Participants.vue'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import StartMomentDateComponent from '../../molecules/StartMomentDateComponent/StartMomentDateComponent.vue'
import FacetFilters from '../FacetFilter/FacetFilters.vue'

export default {
    perimeters: [routePerimeter, featuresPerimeter],
    components: {
        StartMomentDateComponent,
        Participants,
        FacetFilters,
        PaginationComponent
    },
    props: {
        facets: {
            type: Array,
            default: () => []
        },
        startMoments: {
            type: Array,
            default: () => []
        },
        showCapacity: {
            type: Boolean,
            default: false
        },
        assortmentId: {
            type: String,
            default: null
        },
        tableHeader: {
            type: Array,
            default: () => []
        },
        totalCount: {
            type: Number,
            default: 0
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pageNumber: {
            type: Number,
            default: 0
        },
        course: {
            type: Object,
            default: () => {}
        },
        displayStartmomentActionButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            userHasAccess: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['isAccountEnabled', 'displayVatInPrices', 'finalStartMomentDate']),
        courseId() {
            return this.course?.id
        },
        displayEnrollButton() {
            return this.isAccountEnabled && this.userHasAccess
        },
        priceLabel() {
            return `Prijs (${coursePriceHelper.getVatText(this.displayVatInPrices)})`
        },
        totalPages() {
            if (this.startMoments?.length) {
                return Math.ceil(this.totalCount / this.pageSize)
            }
            return 1
        }
    },
    async created() {
        const courseAccessStatus = await courseAccessHelper.doesUserHaveAccess(this.courseId, this.assortmentId, !!this.finalStartMomentDate)
        this.userHasAccess = courseAccessStatus === courseAccessStatuses.HAS_ACCESS
    },
    methods: {
        ...mapMutations('startMomentsModule', ['RESET_STATE']),
        getEnrollmentUrl(startMoment) {
            const isCoordinator =  this.$isAllowed('routeAsCoordinator')

            return  {
                name: 'enrollment',
                params: { 
                    type: isCoordinator ? 'coordinator' : 'student' 
                },
                query: { 
                    course: this.courseId, 
                    startMoment, 
                    assortment: this.assortmentId 
                }
            }
        },
        resetStateAndStartEnrollment(startMoment) {
            this.RESET_STATE()
            const enrollmentUrl = this.getEnrollmentUrl(startMoment)

            this.$router.replace(enrollmentUrl)
        },
        formatPrice: priceFormatter.formatPrice
    }
}
</script>
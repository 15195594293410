<template>
  <div class="c-enrollment-step">
    <h4 class="u-textcolor-primary">Aanvullen persoonlijke gegevens</h4>
    <p>Onderstaande informatie hebben wij nodig om uw inschrijving te kunnen verwerken. Velden met een * zijn verplicht.</p>

    <!-- Persoonsgegevens -->
    <div class="o-grid">
      <!-- FirstName text -->
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="formData.firstName"
          label-text="Voornaam *"
          data-test="first-name-input"
          class="qa-enrollment-step__firstname"
          :error-message="getErrorMessage('firstName')"
        />
      </div>
      <!-- MiddleName text -->
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="formData.middleName"
          data-test="middle-name-input"
          label-text="Tussenvoegsel(s)"
          class="qa-enrollment-step__middlename"
        />
      </div>
      <!-- LastName text -->
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
        <recess-input
          v-model="formData.lastName"
          label-text="Achternaam *"
          data-test="last-name-input"
          class="qa-enrollment-step__lastname"
          :error-message="getErrorMessage('lastName')"
        />
      </div>

      <!-- Geboortegegevens -->
      <!-- Birthday text -->
      <div
        v-if="showBirthdayInput"
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-6"
      >
        <recess-input
          v-model="formData.birthday"
          data-test="birth-day-input"
          placeholder-text="Dag-maand-jaar, bijv.: 25-01-1970"
          :label-text="isDiplomaOrAccreditationDegree ? 'Geboortedatum *' : 'Geboortedatum'"
          class="qa-enrollment-step__birthday"
          :error-message="getErrorMessage('birthday')"
        />
      </div>
      <!-- Birthplace text -->
      <div
        v-if="$isAllowed('displayPersonalInformation') && $isAllowed('displayBirthPlaceField')"
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-6"
      >
        <recess-input
          v-model="formData.birthplace"
          data-test="birth-place-input"
          :label-text="isDiplomaOrAccreditationDegree ? 'Geboorteplaats *' : 'Geboorteplaats'"
          class="qa-enrollment-step__birthplace"
          :error-message="getErrorMessage('birthplace')"
        />
      </div>

      <!-- Contactgegevens -->
      <!-- Email text -->
      <div
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6"
        :class="$isAllowed('displayPersonalInformation') ? 'grid-col-lg-6' : 'grid-col-lg-4'"
      >
        <recess-input
          v-model="formData.emailAddress"
          data-test="email-address-input"
          label-text="E-mailadres *"
          class="qa-enrollment-step__email"
          :error-message="getErrorMessage('emailAddress')"
        />
      </div>
      <!-- PhoneNumber text -->
      <div
        v-if="$isAllowed('displayPersonalInformation')"
        class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-6"
      >
        <recess-input
          v-model.trim="formData.telephone"
          label-text="Telefoonnummer *"
          data-test="phone-number-input"
          class="qa-enrollment-step__telephone"
          :error-message="getErrorMessage('telephone')"
        />
      </div>
    </div>

    <!-- Adresgegevens -->
    <template v-if="$isAllowed('displayPersonalInformation')">
      <h4 class="u-textcolor-primary">Adresgegevens</h4>
      <div class="o-grid u-position-relative">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isFetchingAddress"
            variant="small"
            color="primary"
            overlay="white"
          />
        </transition>
        <!-- Address PostalCode text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.postalCode"
            label-text="Postcode *"
            data-test="postal-code-input"
            class="qa-enrollment-step__postalcode"
            :error-message="getErrorMessage('postalCode')"
            @blur="getAddress()"
          />
        </div>
        <!-- Address HouseNumber text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.number"
            label-text="Huisnummer *"
            data-test="house-number-input"
            class="qa-enrollment-step__number"
            :error-message="getErrorMessage('number')"
            @blur="getAddress()"
          />
        </div>
        <!-- Address HouseNumberAddition text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.numberAdditive"
            data-test="house-number-additive-input"
            label-text="Huisnummer toevoeging"
            class="qa-enrollment-step__numberadditive"
            :error-message="getErrorMessage('numberAdditive')"
          />
        </div>
        <!-- Address Street text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.street"
            label-text="Straat *"
            data-test="street-input"
            class="qa-enrollment-step__street"
            :error-message="getErrorMessage('street')"
          />
        </div>
        <!-- Address City	text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="formData.city"
            label-text="Plaats *"
            data-test="city-input"
            class="qa-enrollment-step__city"
            :error-message="getErrorMessage('city')"
          />
        </div>
        <!-- Address CountryCode text -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-select
            v-model="formData.country"
            label-text="Land *"
            data-test="country-input"
            :default-value="formData.country"
            :options="countries"
            class="qa-enrollment-step__country"
            :error-message="getErrorMessage('country')"
            @input="(value) => $emit('input', value)"
          />
        </div>
      </div>
    </template>

    <!-- Enrollment enroll reason -->
    <template v-if="$isAllowed('displayEnrollReason')">
      <h4 class="u-textcolor-primary">Reden van inschrijving kiezen</h4>
      <p>Wat is de reden van uw inschrijving voor deze ontwikkelactiviteit?</p>

      <div class="row mb-4">
        <div class="col-12 col-md-7">
          <recess-select
            v-model="formData.enrollReasonCode"
            data-test="enroll-reason-input"
            label-text="Reden van inschrijving *"
            :default-value="formData.enrollReasonCode"
            :options="enrollReasons"
            :prefill-with-first-option=false
            :error-message="$v.formData.enrollReasonCode.$error ? 'Reden van inschrijving is verplicht' : ''"
          />
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12 col-md-7">
          <recess-textarea-input
            v-model="formData.enrollReasonMessage"
            data-test="enroll-message-input"
            label-text="Aanvullende informatie/opmerking"
          />
        </div>
      </div>
    </template>

    <enrollment-step-buttons 
      :show-back-button="!!(startMoments && startMoments.length)" 
      v-on="{ ...$listeners, 'next-step': confirmStep }" 
    />

    <enrollment-save-personal-data-modal
      :show-save-personal-data-modal="showSavePersonalDataModal"
      @close="showSavePersonalDataModal = false"
      @submit="setUpdateUserDataAndConfirmStep"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { required, numeric, requiredIf, maxLength } from 'vuelidate/lib/validators'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import {
    validationMessages,
    isValidDate,
    isValidPhoneNumber,
    isPastDate,
    isValidBirthdayDate,
    isValidEmail
} from '../../../../modules/validationsHelper'
import { getAddress, cancelFetchAddress } from "../../../../api/addressLookupClient"
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import EnrollmentSavePersonalDataModal from './EnrollmentSavePersonalDataModal.vue'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'


export default {
    perimeters: [featuresPerimeter],
    components: {
        LoaderComponent,
        EnrollmentSavePersonalDataModal,
        EnrollmentStepButtons
    },
    validations() {
        if (this.$isAllowed('displayPersonalInformation')) {
            const validationData = {
                formData: {
                    firstName: { required },
                    lastName: { required },
                    city: { required },
                    country: { required },
                    postalCode: { required },
                    number: { required, numeric },
                    numberAdditive: { maxLength: maxLength(25) },
                    street: { required },
                    emailAddress: { required, isValidEmail },
                    telephone: { required, isValidPhoneNumber },
                    birthplace: {
                        required: requiredIf(() => {
                            return this.isDiplomaOrAccreditationDegree && this.$isAllowed('displayBirthPlaceField')
                        })
                    },
                    enrollReasonCode: { 
                        required: requiredIf(() => {
                            return this.$isAllowed('displayEnrollReason')
                        })
                    }
                }
            }
            if (this.$isAllowed('displayBirthDateField')) {
                validationData.formData.birthday = {
                    required: requiredIf(() => {
                        return this.isDiplomaOrAccreditationDegree
                    }),
                    isValidDate,
                    isPastDate,
                    isValidBirthdayDate
                }
            }
            return validationData
        }
        return {
            formData: {
                firstName: { required },
                lastName: { required },
                emailAddress: { required, isValidEmail }
            }
        }
    },
    data() {
        return {
            validationMessages,
            isFetchingAddress: false,
            showSavePersonalDataModal: false
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['formData', 'countries', 'diplomaType', 'enrollReasons']),
        ...mapGetters('startMomentsModule', ['startMoments']),
        ...mapState('userModule', ['user']),
        isDiplomaOrAccreditationDegree() {
            return this.diplomaType &&
                (this.diplomaType.toLowerCase() === 'diploma' ||
                    this.diplomaType.toLowerCase() === 'accrediteddegree')
        },
        enrollmentUserData() {
            return {
                firstName: this.formData.firstName,
                middleName: this.formData.middleName,
                lastName: this.formData.lastName,
                birthday: this.$isAllowed('displayBirthDateField') ? this.formData.birthday : null,
                birthplace: this.formData.birthplace,
                telephone: this.formData.telephone,
                postalCode: this.formData.postalCode,
                number: +this.formData.number,
                numberAdditive: this.formData.numberAdditive,
                street: this.formData.street,
                city: this.formData.city,
                country: this.formData.country
            }
        },
        showBirthdayInput() {
            return this.$isAllowed('displayPersonalInformation') && this.$isAllowed('displayBirthDateField')
        }
    },
    async created() {
        if(!this.enrollReasons?.length && this.$isAllowed('displayEnrollReason')) await this.getEnrollReasons()
    },
    mounted() {
        this.$gtag.pageview({ page_path: '/inschrijven-student-gegevens' })
    },
    methods: {
        ...mapMutations('enrollmentModule', ['setWillUpdateUserData']),
        ...mapMutations('userModule', ['updateUserFields']),
        ...mapActions('enrollmentModule', ['getEnrollReasons']),

        async getAddress() {
            try {
                // Cancel previous request
                cancelFetchAddress()
                this.isFetchingAddress = true
                if (this.formData.postalCode
                 && this.formData.number 
                 && !this.$v.formData.postalCode.$invalid 
                 && !this.$v.formData.number.$invalid) {
                    const response = await getAddress(this.formData.postalCode, this.formData.number)

                    if(response) {
                        this.formData.street = response.streetName
                        this.formData.city = response.city
                        this.formData.country = "NL"
                    }
                }
            } catch {
                // No need to throw error to the console
            } finally {
                this.isFetchingAddress = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData[fieldName]
            const fieldValue = this.formData[fieldName]

            // Check Required
            if (fieldValidation && fieldValidation.$error && fieldValidation.required === false) {
                return this.validationMessages.required[fieldName]
            }

            // Check Invalid
            if (fieldValidation && fieldValidation.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        },
        confirmStep() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            if (this.changedUserData()) {
                this.showSavePersonalDataModal = true
                return 
            }
            
            this.$emit('next-step')
        },
        changedUserData() {
            // Check if any of the values from the enrollment data is different from what is in the user module
            if (!this.showBirthdayInput)
            {
                return Object.entries(this.enrollmentUserData).some(([key, value]) => key !== 'birthday' && this.user[key] !== value)
            }
            return Object.entries(this.enrollmentUserData).some(([key, value]) => this.user[key] !== value)
        },
        setUpdateUserDataAndConfirmStep(emittedData) {
            // Set the willUpdateUserData flag in the enrollment module and update the user properties in the user module
            if (emittedData.willUpdateUserData) {
                this.setWillUpdateUserData(emittedData.willUpdateUserData)
                this.updateUserFields(this.enrollmentUserData)
            }

            this.showSavePersonalDataModal = false
            this.$emit('next-step')
        }
    }
}
</script>

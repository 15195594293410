<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div v-if="course">
    <!-- coursename -->
    <h2
      v-if="course.name"
      v-html="course.name"
    ></h2>

    <!-- Intro Content -->
    <template v-if="introContent">
      <div
        v-for="(content, index) in introContent"
        :key="`introContent${index}`"
        class="c-course-content__wrapper"
      >
        <div
          v-if="content.introHtml || content.intro"
          class="c-course-content__block"
          v-html="content.introHtml || content.intro"
        ></div>
      </div>
    </template>

    <!-- ExpertiseArea -->
    <template v-if="expertiseAreaValues && expertiseAreaValues.length">
      <span class="font-weight-bold">{{ expertiseAreaTitleText }}</span>
      <ul class="c-course-content__block">
        <li
          v-for="expertiseAreaValue in expertiseAreaValues"
          :key="expertiseAreaValue.value"
          :data-test="`expertise-area-${expertiseArea}-text`"
        >
          {{ expertiseAreaValue.displayValue }}
        </li>
      </ul>
    </template>

    <!-- General content -->
    <template v-if="generalContent">
      <div
        v-for="(content, index) in generalContent"
        :key="`generalContent${index}`"
        class="c-course-content__wrapper"
      >
        <h3 v-if="content.heading">{{ content.heading }}</h3>
        <div
          v-if="content.bodyHtml || content.body"
          class="c-course-content__block"
          v-html="content.bodyHtml || content.body"
        ></div>
      </div>
    </template>

    <!-- Rest content -->
    <template v-if="restContent">
      <div
        v-for="(content, index) in restContent"
        :key="`restContent${index}`"
        class="c-course-content__wrapper"
      >
        <h3 v-if="content.heading">{{ content.heading }}</h3>
        <div
          v-if="content.bodyHtml || content.body"
          class="c-course-content__block"
          v-html="content.bodyHtml || content.body"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as expertiseAreas from '../../../constants/expertiseArea'

export default {
    data() {
        return {
            expertiseAreas
        }
    },
    computed: {
        ...mapState('coursePageModule', ['course']),
        ...mapGetters('coursePageModule', ['generalContent', 'restContent', 'introContent']),
        ...mapGetters('accountModule', ['expertiseArea']),
        expertiseAreaValues() {
            return (!this.course || !this.course.segments) 
                ? null
                : this.course.segments
        },
        expertiseAreaTitleText() {
            if(this.expertiseAreaValues?.length > 1)
                return 'Vakgebieden'

            return 'Vakgebied'
        }
    }
}
</script>
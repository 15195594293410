<template>
  <lightbox-component
    :is-visible="showAutomateLearningPath && $isAllowed('assignLearningPath')"
    @close="closeModal"
  >
    <h3>Leerpad toewijzing automatisering</h3>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <p>
      Maak hieronder een selectie om het leerpad automatisch aan elke gebruiker toe te kennen, die vanaf het moment van instellen van deze automatisering nieuw aan het systeem wordt toegevoegd én voldoet aan de gekozen selectie. Let op: Een reeds bekende gebruiker die, bijvoorbeeld door een functiewijziging, opeens aan het ingestelde filter gaat voldoen zal niet automatisch het leerpad toegewezen krijgen. Bestaande gebruikers kunt u het leerpad via handmatige selectie toekennen.
    </p>
    <div class="row">
      <recess-checkbox-input
        v-model="everyoneSelected"
        class="mb-3 col-9 col-md-5"
        label-text="Automatisch toewijzen aan iedereen"
        :default-checked="everyoneSelected"
      />
    </div>
    <div
      v-if="!everyoneSelected"
      class="o-grid"
    >
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-6">
        <recess-multi-select
          v-model="filters.jobNames"
          v-observe-visibility="{ callback: fetchJobNames, once: true }"
          :selected-options="filters.jobNames"
          label-text="Functietitel"
          :options="jobNames"
        />
      </div>
      <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-6">
        <recess-multi-select
          v-model="filters.roles"
          :selected-options="filters.roles"
          label-text="Role"
          :options="roles"
        />
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isFetchingJobNames"
        overlay="white"
        color="primary"
      />
    </transition>

    <div
      v-if="showClearFiltersButton"
      class="mb-3 mb-md-0"
    >
      <CrossSVG color="secondary" />
      <a
        href
        @click.prevent="clearFilters"
      >Verwijder filters</a>
    </div>
   
    <recess-button
      title="Opslaan"
      variant="primary"
      class="d-block ml-auto mr-0 mb-3 mb-md-0 col-12 col-md-auto"
      @click="submit"
    />
    <recess-alert
      v-if="showErrorMessage"
      type="error"
      text="Er is iets misgegaan. Probeer het later nog een keer."
      class="mt-5"
    />
  </lightbox-component>
</template>

<script>
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import userClient from '../../../api/userClient'
import { getAutoAssignedLearningPath, autoAssignLearningPath, deleteAutoAssignedLearningPath } from '../../../api/learningPathClient'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import LightboxComponent from '../../molecules/LightboxComponent/LightboxComponent.vue'
import CrossSVG from '../IconComponents/CrossSVG.vue'
import * as roles from '../../../constants/roles'

export default {
    perimeters: [learningPathPerimeter, routePerimeter],
    components: {
        LoaderComponent,
        LightboxComponent,
        CrossSVG
    },
    props: {
        showModal: {
            type: [Boolean],
            default: false
        },
        selectedPath: {
            type: [Number],
            default: null
        }
    },
    data() {
        return {
            hasCurrentConfig: false,
            everyoneSelected: false,
            isFetchingCurrentConfig: false,
            isPostingCurrentConfig: false,
            isFetchingJobNames: false,
            showErrorMessage: false,
            showAutomateLearningPath: this.showModal,
            currentPath: null,
            jobNames: [],
            filters: {
                jobNames: [],
                roles: []
            },
            roles: [
                {
                    displayText: 'Coördinator',
                    value: roles.COORDINATOR
                },
                {
                    displayText: 'Leidingevende',
                    value: roles.TEAMLEADER
                },
                {
                    displayText: 'Tweede goedkeurder',
                    value: roles.SECONDAPPROVER
                }
            ]
        }
    },
    computed: {
        isLoading() {
            return this.isFetchingCurrentConfig || this.isPostingCurrentConfig
        },
        showClearFiltersButton() {
            return Object.values(this.filters).some(filter => filter.length) && !this.everyoneSelected
        }
    },
    watch: {
        showModal(newValue) {
            this.showAutomateLearningPath = newValue
        },
        showAutomateLearningPath(newValue, oldValue) {
            if (
                newValue &&
                !oldValue &&
                (!this.currentPath || this.selectedPath !== this.currentPath)
            ) {
                this.currentPath = this.selectedPath
                // clean component already triggers a get users request
                this.cleanComponent()
                this.getCurrentConfig()
            }
            // bubble up change
            this.$emit('update:showModal', newValue)
        }
    },
    beforeDestroy() {
        this.cleanComponent()
    },
    methods: {
        clearFilters() {
            this.filters =  {
                jobNames: [],
                roles: []
            }
        },
        async fetchJobNames(isVisible) {
            if (!isVisible || this.jobNames?.length) return

            try {
                this.isFetchingJobNames = true
                const jobNames = await userClient.fetchUserJobNames()

                this.jobNames = jobNames.map(jobName => {
                    return {
                        displayText: jobName,
                        value: jobName
                    }
                })
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingJobNames = false
            }
        },
        getCurrentConfig() {
            this.isFetchingCurrentConfig = true
            getAutoAssignedLearningPath(this.currentPath)
                .then(result => {
                    if(result) {
                        if(!result.jobTitleFilter && !result.roleFilter){
                            this.everyoneSelected = true
                        }
                        else{
                            if(result.jobTitleFilter) {
                                this.filters.jobNames = [...result.jobTitleFilter]
                            }
                            if(result.roleFilter) {
                                this.filters.roles = [...result.roleFilter]
                            }
                        }
                        this.hasCurrentConfig = true
                    }
                    this.showErrorMessage = false
                })
                .catch(error => {
                    if(error.response.status !== 404){
                        this.showErrorMessage = true
                        throw new Error(error)
                    }
                })
                .then(() => {
                    this.isFetchingCurrentConfig = false
                })
        },
        closeModal() {
            this.showAutomateLearningPath = false
        },
        cleanComponent() {
            this.filters.jobNames = []
            this.filters.roles = []
            this.showErrorMessage = false
            this.hasCurrentConfig = false
            this.everyoneSelected = false
        },
        removeFilters() {
            this.filters.jobNames = []
            this.filters.roles = []
            // trigger a save
            this.submit()
        },
        setJobFilter(value) {
            this.filters.jobNames.push(value)
        },
        setRoleFilter(value) {
            this.filters.roles.push(value)
        },
        submit() {
            const postObject = { roleFilter: null, jobTitleFilter: null }
            if(!this.everyoneSelected) {
                // if filter values are both null, trigger removal instead
                if(this.filters.roles.length === 0 && this.filters.jobNames.length === 0){
                    this.removeConfig()
                    return
                }
                postObject.roleFilter = this.filters.roles
                postObject.jobTitleFilter = this.filters.jobNames
            }
            this.isPostingCurrentConfig = true
            autoAssignLearningPath(this.currentPath, postObject)
                .then(() => {
                    this.showErrorMessage = false
                    this.hasCurrentConfig = true
                    // close modal on successful upload
                    this.closeModal()
                })
                .catch(error => {
                    this.showErrorMessage = true
                    throw new Error(error)
                })
                .then(() => {
                    this.isPostingCurrentConfig = false
                })
        },
        removeConfig() {
            this.isFetchingCurrentConfig = true
            deleteAutoAssignedLearningPath(this.currentPath)
                .then(() => {
                    this.cleanComponent()
                    this.closeModal()
                })
                .catch(error => {
                    this.showErrorMessage = true
                    throw new Error(error)
                })
                .then(() => {
                    this.isFetchingCurrentConfig = false
                })
        }
    }
}
</script>
<template>
  <form>
    <fieldset>
      <legend class="d-none">Sharing result type</legend>

      <recess-divider variant="xsmall" />

      <recess-select
        v-model="mLearningResultSharingType"
        data-cy="learning-result-type-input"
        class="col-12 col-sm-4 p-0"
        :default-value="learningResultSharingType"
        :options="learningResultTypesOptions"
      />
    </fieldset>

    <recess-divider v-if="mLearningResultSharingType" variant="xsmall" />
  
    <learning-result-sharing-item 
      v-if="learningResultOptionsToShow && Object.keys(learningResultOptionsToShow).length"
      :key="mLearningResultSharingType"
      :show-label="false"
      :default-options="options"
      :learning-result="learningResultOptionsToShow" 
      @input="selectedOption => setOptionValue(selectedOption)"
    />
  </form>
</template>
  
<script>
import { computed, watch, ref } from 'vue'
import LearningResultSharingUtils from '../../../utils/LearningResultSharingUtils'
import learningResultSharingTypes from '../../../constants/learningResultSharingTypes'
import learningResultSharingOptions from '../../../constants/learningResultSharingOptions'
import learningResultSharingOptionsTypes from '../../../constants/learningResultSharingOptionsTypes'
import LearningResultSharingItem from './LearningResultSharingItem.vue'
import learningResultSharingTypeEnum from '../../../constants/learningResultSharingTypeEnum'
  
export default {
    components: {
        LearningResultSharingItem
    },
    props: {
        learningResultSharingType: {
            type: Number,
            default: learningResultSharingTypeEnum.None
        }
    },
    emits: [
        'sharing-type-selected',
        'selected-learning-result-options',
        'selected-learning-result-option'
    ],
    setup(props, { emit }) {
        const mLearningResultSharingType = ref(props.learningResultSharingType)
  
        const learningResultOptionsToShow = computed(() => {
            if(!mLearningResultSharingType.value) return null

            return {
                key: LearningResultSharingUtils.mapLearningResultSharingType(mLearningResultSharingType.value),
                options: [
                    {
                        key: learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PASSEDEXAMCONSENT,
                        displayName: learningResultSharingOptions.PASSEDEXAMCONSENT.displayName
                    },
                    {
                        key: learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.DIPLOMACONSENT,
                        displayName: learningResultSharingOptions.DIPLOMACONSENT.displayName
                    },
                    {
                        key: learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.COMPLETIONATRISKCONSENT,
                        displayName: learningResultSharingOptions.COMPLETIONATRISKCONSENT.displayName
                    },
                    {
                        key: learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PROGRESSPERCENTAGECONSENT,
                        displayName: learningResultSharingOptions.PROGRESSPERCENTAGECONSENT.displayName
                    }
                ]
            }
        })
  
        const learningResultTypesOptions = computed(() => {
            return [
                {
                    displayText: "Niet verplicht",
                    value: learningResultSharingTypeEnum.None
                },
                {
                    displayText: learningResultSharingTypes.LEGAL.displayValue,
                    value: learningResultSharingTypeEnum.Legal
                },
                {
                    displayText: learningResultSharingTypes.EMPLOYER.displayValue,
                    value: learningResultSharingTypeEnum.Employer
                }
            ]
        })
  
        const getDefaultOptionsForSelectedLearningResultType = type => {
            switch (type) {
                case learningResultSharingTypes.LEGAL.key:
                    return {
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.DIPLOMACONSENT]: false,
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.COMPLETIONATRISKCONSENT]: false,
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PASSEDEXAMCONSENT]: true,
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PROGRESSPERCENTAGECONSENT]: true
                    }
                case learningResultSharingTypes.EMPLOYER.key:
                    return {
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.DIPLOMACONSENT]: false,
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.COMPLETIONATRISKCONSENT]: false,
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PASSEDEXAMCONSENT]: false,
                        [learningResultSharingOptionsTypes.SELECTEDBYAPPROVER.PROGRESSPERCENTAGECONSENT]: false
                    }
                default:
                    return null
            }
        }
  
        const options = ref({})
        watch(
            mLearningResultSharingType,
            async newVal => {
                emit('sharing-type-selected', newVal)

                const type = LearningResultSharingUtils.mapLearningResultSharingType(newVal)
                if(type === learningResultSharingTypes.LEGAL.key || type === learningResultSharingTypes.EMPLOYER.key) {
                    const defaultLearningResultOptions = getDefaultOptionsForSelectedLearningResultType(type)
  
                    options.value = {...defaultLearningResultOptions}
                    emit('selected-learning-result-options', defaultLearningResultOptions)
                } else {
                    emit('selected-learning-result-options', null)
                }
            },
            {
                immediate: true
            }
        )
  
        const setOptionValue = selectedOption => {
            emit('toggle-learning-result-option', selectedOption)
        }
  
        return {
            learningResultTypesOptions,
            learningResultOptionsToShow,
            mLearningResultSharingType,
            setOptionValue,
            options,
            getDefaultOptionsForSelectedLearningResultType
        }
    }
}
</script>
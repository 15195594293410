<template>
  <div>
    <recess-divider />
    <p data-test="confirmation-text">{{ title }}</p>
    <p
      v-if="approvalRequired && enrollmentType === enrollmentTypes.STUDENT"
    >
      Uw leidinggevende moet uw aanvraag nog goedkeuren. U kunt in uw inschrijvingenoverzicht te allen tijde zien wat de status is van uw aanvraag. Indien de status van uw aanvraag wijzigt ontvangt u van ons uiteraard ook een e-mailnotificatie.
    </p>

    <recess-divider />

    <recess-button
      v-if="$isAllowed('displayHomeLink')"
      :url="{ name : 'home' }"
      variant="secondary"
      data-test="home-button"
      class="mr-0 mr-sm-3 d-block d-sm-inline-block"
      title="Terug naar home"
    />

    <recess-button
      v-if="$isAllowed('displayMyDevelopmentLink') && enrollmentType === enrollmentTypes.STUDENT"
      :url="{ name: 'my-development', query: { 'my-development-tab': 'enrollments' } }"
      variant="primary"
      data-test="subscription-button"
      title="Naar mijn inschrijvingen"
      class="d-block d-sm-inline-block mt-3 mt-sm-0"
    />

    <recess-divider />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'

export default {
    perimeters: [featuresPerimeter],
    data() {
        return {
            enrollmentTypes
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollmentType', 'approvalRequired']),
        ...mapGetters('accountModule', ['allowSkipEnrollmentVerification']),

        title() {
            let nonStudentEnrollmentSuccessMessage = 'De inschrijving is succesvol ingediend. De in de inschrijving geselecteerde persoon/personen ontvangen een verzoek voor verificatie van hun persoonlijke gegevens'
            if (this.allowSkipEnrollmentVerification) {
                nonStudentEnrollmentSuccessMessage += ' als het nodig is'
            }
            return this.enrollmentType === enrollmentTypes.STUDENT
                ? 'Gefeliciteerd met uw inschrijving! Uw inschrijving is succesvol ontvangen.'
                : `${nonStudentEnrollmentSuccessMessage}.`
        }
    },
    mounted() {
        if (this.enrollmentType === enrollmentTypes.STUDENT)
            this.$gtag.pageview({ page_path: '/inschrijven-succesvol' })

        if (this.enrollmentType === enrollmentTypes.TEAMLEADER)
            this.$gtag.pageview({ page_path: '/inschrijven-succesvol' })
    }
}
</script>
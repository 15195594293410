<template>
  <div>
    <h4 class="u-textcolor-primary">Inschrijvingsopties</h4>
    <div class="row">
      <transition name="fade">
        <recess-input
          v-if="showCostCenterInput"
          v-model="formData.costCenter"
          class="col-md-6 col-sm-12 mt-2 mb-md-3"
          :disabled="isCostCenterDisabled"
          data-test="cost-center-input"
          :error-message="getErrorMessage('costCenter')"
        >
          <template #label>
            <div class="d-flex align-items-center">
              <span class="mr-2">Kostenplaats *</span>
              <recess-tooltip>
                <template #content>
                  <span class="d-block u-max-width-500">
                    Indien uw organisatie uitgaven budgettair splitst naar afdeling of investeringsdoel, wordt er vaak gebruik gemaakt van kostenplaatsen (ook wel: cost centers). Indien dit zo is vult u in dit veld de kostenplaats in die op de factuur moet komen te staan. Indien u niet weet welke kostenplaats er gehanteerd dient te worden kan uw financiële afdeling u hier meestal mee helpen.
                  </span>
                </template>
              </recess-tooltip>
            </div>
          </template>
        </recess-input>
      </transition>
 
      <recess-input
        v-if="$isAllowed('displayPONumber')"
        v-model="formData.purchaseOrderNumber"
        :disabled="!!course.purchaseAgreementId"
        class="col-md-6 col-sm-12 mt-2 mb-md-3"
        data-test="po-number-input"
        :error-message="getErrorMessage('purchaseOrderNumber')"
      >
        <template #label>
          <div class="d-flex align-items-center">
            <span class="mr-2">{{ purchaseOrderNumberLabelText }}</span>
            <recess-tooltip>
              <template #content>
                <span class="d-block u-max-width-500">
                  Indien uw organisatie gebruik maakt van een inkoop systeem (bijv. SAP, Arriba, etc.), dan gebruikt uw organisatie bijna zeker ook inkooporder nummer (purchase order numbers/PO nummers). Vul in dit veld het voor deze inschrijving geldende PO nummer in. Uw inkoop - of financiële afdeling kan u meestal helpen indien u het nummer niet kent. Dit veld is alleen verplicht als uw organisatie vereist dat dit veld ingevuld moet zijn.
                </span>
              </template>
            </recess-tooltip>
          </div>
        </template>
      </recess-input>
   
      <recess-select
        v-if="approvalRequired === 2"
        v-model="formData.secondApproverId"
        class="col-md-6 col-sm-12 mt-2 mb-md-3"
        :prefill-with-first-option=false
        data-test="second-approver-input"
        :error-message="getErrorMessage('secondApproverId')"
        :default-value="formData.secondApproverId || null"
        :options="secondApproversOptions"
      >
        <template #label>
          <span class="mr-2">Kies tweede goedkeurder *</span>
        </template>
      </recess-select>

      <recess-select
        v-if="showKeyAccounts"
        v-model="formData.keyAccountId"
        class="col-md-6 col-sm-12 mt-2 mb-md-3"
        :select-option-text="selectKeyAccountOptionText"
        :default-value="formData.keyAccountId"
        data-test="key-account-input"
        :error-message="getErrorMessage('keyAccountId')"
        :options="keyAccountsOptions"
      >
        <template #label>
          <span class="mr-2">Key-Account {{ keyAccountRequired ? '*' : null }}</span>
        </template>
      </recess-select>
        

      <recess-select
        v-if="showStudyContarctInput"
        v-model="formData.studyContractTemplateId"
        class="col-md-6 col-sm-12 mt-2 mb-md-3"
        :prefill-with-first-option = false
        :error-message="getErrorMessage('studyContractTemplateId')"
        :default-value="formData.studyContractTemplateId || null"
        :options="studyContractOptions"
        data-test="study-contract-input"
        @input="getStudyContractTemplateDocument"
      >
        <template #label>
          <div class="d-flex align-items-center">
            <span class="mr-2">Studieovereenkomst *</span>
            <recess-tooltip>
              <template #content>
                <span class="d-block u-max-width-500">
                  Wanneer uw organisatie vereist dat medewerkers een studieovereenkomst tekenen voor het volgen van een ontwikkelactiviteit (meestal boven een bepaald bedrag), dan selecteert u hier de betreffende studieovereenkomst. Als u hier vragen over heeft dan kan de HR afdeling van uw organisatie u hier meestal over adviseren.
                </span>
              </template>
            </recess-tooltip>
          </div>
        </template>
      </recess-select>
    </div>
    <div>
      <div
        v-if="alternativeStudyContractFlow"
        class="col-md-6 col-sm-12 mt-2 mb-md-3 d-flex"
      >
        <div class="w-100 w-md-auto u-position-relative align-self-end">
          <transition
            name="fade"
            mode="out-in"
          >
            <recess-loader
              v-if="isFetchingStudyContractTemplateDocument"
              overlay="white"
              color="primary"
              spinner-size="small"
            />
          </transition>
          <recess-button
            :disabled="isDownloadButtonDisabled"
            variant="tertiary"
            title="Download bestand"
            class="col-12 col-md-auto align-self-end"
            target="_blank"
            :url="studyContractTemplateDocument"
          />
        </div>
      </div>

      <div
        class="row"
      >
        <div
          v-if="isSubsidyEnabled"
          class="col-12 mt-2 mb-md-3"
        >
          <recess-toggle-input
            v-model="formData.isSubsidized"
          >
            <template #label>
              <div class="d-flex align-items-center">
                <span class="mr-2">Subsidie</span>
                <recess-tooltip>
                  <template #content>
                    <span class="d-block u-max-width-500">
                      Indien voor deze inschrijving subsidie is- of wordt aangevraagd, activeer dan deze toggle. Hiermee wordt het duidelijk op de rapportage dat aan deze inschrijving subsidie verbonden is.
                    </span>
                  </template>
                </recess-tooltip>
              </div>
            </template>
          </recess-toggle-input>
        </div>
          
        <div
          v-if="showApplyAlternativeCostCenterToggle"
          class="col-12 mt-2 mb-md-3"
        >
          <recess-toggle-input
            v-model="applyUseAlternativeCostCenter"
            data-test="alternative-cost-center-toggle"
          >
            <template #label>
              <div class="d-flex align-items-center">
                <span class="mr-2">Gebruik een afwijkende/groeps kostenplaats</span>
                <recess-tooltip>
                  <recess-icon
                    :icon="showApplyAlternativeCostCenterToggle ? 'danger' : 'info'"
                    :color="showApplyAlternativeCostCenterToggle ? 'warning' : 'primary'"
                  />
                  <template #content>
                    <span class="d-block u-max-width-500">
                      <span v-if="showApplyAlternativeCostCenterToggle"> 
                        Pas op: als u een afwijkende kostenplaats opgeeft, wordt deze kostenplaats gebruikt op de inschrijving in plaats van die van uw medewerker(s).
                      </span>

                    </span>
                  </template>
                </recess-tooltip>
              </div>
            </template>
          </recess-toggle-input>
        </div>
        
        <div
          v-if="showStartmoments"
          class="col-12 mt-2 mb-md-3"
        >
          <recess-toggle-input
            v-model="formData.isStartmomentSelectionEnabled"
          >
            <template #label>
              <div class="d-flex align-items-center">
                <span class="mr-2">Sta wijziging van startmoment(en) toe</span>
                <recess-tooltip>
                  <template #content>
                    <span class="d-block u-max-width-500">
                      Als u aangeeft dat een medewerker de startmomenten kan aanpassen dan kan hij/zij ervoor kiezen de ontwikkelactiviteit op een ander moment te volgen dan u heeft aangegeven. Dit is vooral gebruikelijk voor ontwikkelactiviteiten die niet onder werktijd kunnen worden gevolgd.
                    </span>
                  </template>
                </recess-tooltip>
              </div>
            </template>
          </recess-toggle-input>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row">
        <div class="d-flex flex-column flex-md-row">
          <enrollment-step-buttons  :show-next-button="false" v-on="$listeners" />
        </div>
          
        <div class="d-flex justify-content-end flex-grow-1 flex-column flex-md-row align-items-md-center">
          <recess-button
            v-if="explanationDocumentUrl"
            variant="tertiary"
            class="mb-3 mb-md-0 mr-md-3"
            title="Download uitleg"
            target="_blank"
            :url="explanationDocumentUrl"
          />
          <recess-button
            variant="primary"
            title="Verder"
            data-test="next-step-button"
            @click.native="submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'
import projectRequestClient from '../../../../api/projectRequestClient'

export default {
    name: "EnrollmentStepOptions",
    components: {
        EnrollmentStepButtons
    },
    perimeters: [featuresPerimeter],
    validations() {
        return {
            formData: {
                studyContractTemplateId: { 
                    required: requiredIf(() => this.showStudyContarctInput) 
                },
                secondApproverId: {
                    required: requiredIf(() => {
                        return this.approvalRequired === 2
                    })
                },
                costCenter: {
                    required: requiredIf(() => this.showCostCenterInput)
                },
                purchaseOrderNumber: {
                    required: requiredIf(() => {
                        return this.isPurchaseOrderMandatoryForFirstApproval && this.$isAllowed('displayPONumber')
                    })
                },
                keyAccountId: {
                    required: requiredIf(() => {
                        return this.showKeyAccounts && this.keyAccountRequired
                    })
                }
            }
        }
    },
    data() {
        return {
            validationErrorMessages: {
                required: {
                    studyContractTemplateId: 'Studieovereenkomst is verplicht',
                    secondApproverId: 'Tweede Goedkeurder is verplicht',
                    purchaseOrderNumber: 'PO nummer is verplicht',
                    costCenter: 'Kostenplaats is verplicht',
                    keyAccountId: 'Key-Account is verplicht'
                }
            }
        }
    },
    computed: {
        ...mapState('enrollmentModule', [
            'enrollmentType',
            'studyContractOptions',
            'secondApproversOptions',
            'formData',
            'approvalRequired',
            'isFetchingStudyContractTemplateDocument',
            'studyContractTemplateDocument',
            'useAlternativeCostCenter',
            'course'
        ]),
        ...mapState('userModule', ['user']),
        ...mapGetters('enrollmentModule', ['keyAccounts', 'showStartmoments']),
        ...mapGetters('accountModule', ['isPurchaseOrderMandatoryForFirstApproval', 'alternativeStudyContractFlow', 'isSubsidyEnabled', 'explanationDocumentUrl', 'isCostCenterEditable', 'prefillTLCostCenter', 'keyAccountRequired']),
        keyAccountsOptions() {
            return this.keyAccounts?.length ? [{ value: 'notApplicable', displayText: "Geen key-account"}, ...this.keyAccounts?.map(keyAccount => {
                return {
                    value: keyAccount.id,
                    displayText: keyAccount.name
                }
            })] : []
        },    
        showCostCenterInput() {
            return this.$isAllowed('displayCostCenter') && 
            ((this.showApplyAlternativeCostCenterToggle && this.useAlternativeCostCenter) || 
            !this.showApplyAlternativeCostCenterToggle)
        },   
        showStudyContarctInput(){
            return this.studyContractOptions?.length > 1
        },
        isCostCenterDisabled() {
            return !this.$isAllowed('displayCostCenter') ||
            (!this.isCostCenterEditable && this.prefillTLCostCenter && !!this.user?.costCenter?.length)
        },
        showApplyAlternativeCostCenterToggle() {
            return this.$isAllowed('displayCostCenter') && !this.prefillTLCostCenter
        },
        purchaseOrderNumberLabelText() {
            return this.isPurchaseOrderMandatoryForFirstApproval ? 'PO nummer *' : 'PO nummer'
        },
        isDownloadButtonDisabled() {
            return !this.formData.studyContractTemplateId || this.formData.studyContractTemplateId === 'notApplicable' || this.isFetchingStudyContractTemplateDocument
        },
        selectKeyAccountOptionText() {
            return this.keyAccountRequired && !this.formData.keyAccountId ? " " : null 
        },
        showKeyAccounts() {
            return this.keyAccounts?.length && this.$isAllowed('displayKeyAccounts')
        },
        applyUseAlternativeCostCenter: {
            get()  {
                return this.useAlternativeCostCenter
            },
            set(newValue){
                this.setUseAlternativeCostCenter(newValue)
            }
        }
    },
    watch: {
        approvalRequired() {
            this.handleGetSecondApprovers()
        }
    },
    async mounted() {
        this.getStudyContractTemplates()
        this.handleGetSecondApprovers()
        // If cost center is displayed and not set, prefill it with the teamleader cost center
        if(this.$isAllowed('displayCostCenter') && !this.formData.costCenter && this.prefillTLCostCenter) {
            this.formData.costCenter = this.user?.costCenter
        }
        
        if (this.enrollmentType === enrollmentTypes.TEAMLEADER)
            this.$gtag.pageview({ page_path: '/inschrijven-teamlead-informatie' })

        if (this.$isAllowed('displayPONumber') && this.course.purchaseAgreementId) {
            await this.setProjectPurchaseOrderNumber()
        }
    },
    methods: {
        ...mapActions('enrollmentModule', ['getStudyContractTemplates', 'getSecondApprovers', 'fetchStudyContractTemplateDocument']),
        ...mapMutations('enrollmentModule', ['setSecondApproverId', 'setUseAlternativeCostCenter']),
        handleGetSecondApprovers() {
            // Fetch second approvers only if second approval is required and if they haven't been fetched before
            if (this.approvalRequired === 2 && !this.secondApproversOptions?.length) this.getSecondApprovers()
            // Reset second approver when re-choosing course and is not required anymore
            if(this.approvalRequired !== 2 && this.formData.secondApproverId) this.setSecondApproverId(null)
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.formData[fieldName]

            if (fieldValidation && fieldValidation.$error && !fieldValidation.required) {
                return this.validationErrorMessages.required[fieldName]
            }

            return null
        },
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return null
            }
            return this.$emit('next-step')
        },
        getStudyContractTemplateDocument() {
            if(this.isDownloadButtonDisabled || !this.alternativeStudyContractFlow) return

            this.fetchStudyContractTemplateDocument(this.formData.studyContractTemplateId)
        },
        async setProjectPurchaseOrderNumber() {
            try {
                const poNumberResponse = await projectRequestClient.getPONumberByPurchaseAgreementId(this.course.purchaseAgreementId)
                this.formData.purchaseOrderNumber = poNumberResponse.purchaseOrderNumber
            } catch (error)  {
                console.error(error)
            }            
        }
    }
}
</script>
// In case we have a zone component which we don't want to render as a wrapper, it will replace itself for its child components
const zoneTypesToBeReplaced = ['VisibilityWrapperComponent']
const removeWrappingZones = components => {
    if (!components || !components.length) {
        return []
    }
    return components.reduce((acc, item) => {
        if (zoneTypesToBeReplaced.includes(item.webPartType)) {
            const childComponents = item.components && item.components.length ? item.components : []
            // The child components of the 'zone' component
            return [...acc, ...childComponents]
        }
        return [...acc, item]
    }, [])
}

const mixin = {
    props: {
        components: {
            type: Array,
            default: null
        }
    },
    computed: {
        childComponents() {
            return removeWrappingZones(this.components)
        }
    },
    methods: {
        mergeComponentData: component => {
            if (component.properties) {
                // Change null value to empty object before were going to merge them
                component.data = component.data ? component.data : {}
                // Check if data keys conflict with property keys before merging them
                Object.keys(component.properties).forEach(key => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (component.data.hasOwnProperty(key)) {
                        console.warn(
                            `mergeComponentData: 'Data' and 'Properties' are both having an item with key: '${key}'`
                        )
                    }
                })
                // Merge properties with data
                return {
                    ...component.properties,
                    ...component.data,
                    instanceGUID: component.instanceGUID
                }
            }
            return {
                ...component.data,
                instanceGUID: component.instanceGUID
            }
        }
    }
}

export default mixin

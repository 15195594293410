/* eslint-disable import/no-named-as-default-member */
/** @typedef {import('../../models').PEState} PEState */
/** @typedef {import('../../models').PEMutations} PEMutations */
/** @typedef {import('../../models').PEGetters} PEGetters */
import peApiClient from '../../api/permanentEducationClient'
import DateUtils from '../../utils/DateUtils'
import ODataQueryBuilder from '../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../api/OData/odataQueryFilter'
import ODataFilterOperations from '../../api/OData/odataFilterOperations'

export default {
    namespaced: true,
    /** @type {PEState} */
    state: {
        pe: {
            courseName: '',
            emailEnabled: false,
            items: [],
            userId: 0
        },
        loading: false,
        isPEDuplicate: false
    },
    /** @type {PEGetters} */
    getters: {
        items: state => state.pe.items.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()),
        courseName: state => state.pe.courseName,
        emailEnabled: state => state.pe.emailEnabled,
        userId: state => state.pe.userId,
        createdByUserId: state => state.pe.createdByUserId ?? 0,
        peUsers: state => ({ 
            userId: state.pe.userId, 
            createdByUserId: state.pe.createdByUserId, 
            isImported: state.pe.isImported,
            // eslint-disable-next-line no-nested-ternary
            createdByUserFullName: state.pe.createdByUserId && state.pe.createdByUserFullName ? state.pe.createdByUserFullName : (state.pe.isImported ? 'Geïmporteerd' :  'Opleider')
        }),
        loading: state => state.loading,
        email: state => state.pe.userEmailAddress ?? '',
        created: state => DateUtils.formatDate(state.pe.creationDate) ?? '',
        isPEDuplicate: state => state.isPEDuplicate
    },
    /** @type {PEMutations} */
    mutations: {
        resetState(state) {
            state.pe = {
                courseName: '',
                emailEnabled: false,
                items: [],
                userId: 0
            }
            state.loading = false
            state.isPEDuplicate = false
        },
        startNewPE(state, userId) {
            state.pe = {
                courseName: '',
                emailEnabled: false,
                items: [],
                userId: userId ?? 0
            }
            state.loading = false
        },
        setPE(state, pe) {
            state.pe = {...pe}
        },
        addPEItem(state, item) {
            state.pe.items.push(item)
        },
        removePEItem(state, item) {
            const toDelete = state.pe.items.findIndex(i => i === item)
            if (toDelete !== -1) { state.pe.items.splice(toDelete, 1) }
        },
        removePEItemById(state, id) {
            const toDelete = state.pe.items.findIndex(i => i.permanentEducationItemId === id)
            if (toDelete !== -1) { state.pe.items.splice(toDelete, 1) }
        },
        updatePEItem(state, {src, item}) {
            const target = state.pe.items.find(i => i === src)
            if (!target) { return }
            Object.assign(target, item)
        },
        updatePEItemById(state, item) {
            const toUpdate = state.pe.items.findIndex(i => i.permanentEducationItemId === item.permanentEducationItemId)
            if (toUpdate !== -1) { Object.assign(state.pe.items[toUpdate], item) }
            else { state.pe.items.push(item) }
        },
        setCourseName(state, courseName) {
            state.pe.courseName = courseName
        },
        setEmailEnabled(state, emailEnabled) {
            state.pe.emailEnabled = emailEnabled
        },
        setUserId(state, userId) {
            state.pe.userId = userId
        },
        setItems(state, items) {
            state.pe.items = items
        },
        removeCertificate(state, {path, itemId}) {
            const itemIndex = state.pe.items.findIndex(i => i.permanentEducationItemId === itemId)
            if (itemIndex === -1) { return }
            const toDelete = state.pe.items[itemIndex].certificationPaths?.findIndex(c => c === path) ?? -1
            if (toDelete !== -1) { state.pe.items[itemIndex].certificationPaths.splice(toDelete, 1) }
        },
        addCertificates(state, {newCerts, itemId}) {
            const itemIndex = state.pe.items.findIndex(i => i.permanentEducationItemId === itemId)
            if (itemIndex === -1) { return }
            state.pe.items[itemIndex].certifications = [...(newCerts ?? [])]
        },
        removeCertificateByIndex(state, {itemId, index}) {
            const itemIndex = state.pe.items.findIndex(i => i.permanentEducationItemId === itemId)
            if (itemIndex === -1) { return }
            state.pe.items[itemIndex].certificationPaths.splice(index, 1)
        },
        setLoading(state, loading) {
            state.loading = !!loading
        },
        setError(state, error) {
            state.error = error
        },
        setIsPEDuplicate(state, isPEDuplicate) {
            state.isPEDuplicate = isPEDuplicate
        }
    },
    actions: {
        async loadById({commit}, id) {
            try {
                commit('setLoading', true)
                const pe = await peApiClient.getPermanentEducationById(id)
                commit('setPE', pe)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to fetch pe with error: ${err}`)
            }
        },
        async createNewPermanentEducation({commit, state}) {
            try {
                commit('setLoading', true)
                await peApiClient.createPermanentEducation(state.pe)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to create pe with error: ${err}`)
            }
        },
        async updatePermanentEducation({commit, state}) {
            try {
                commit('setLoading', true)
                const pe = await peApiClient.updatePermanentEducation(state.pe)
                commit('setPE', pe)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to update pe with error: ${err}`)
            }
        },
        async updateCourseName({commit, state}, courseName) {
            try {
                commit('setLoading', true)
                commit('setCourseName', courseName)
                const pe = {...state.pe, courseName, items: [] }
                await peApiClient.updatePermanentEducation(pe)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to update pe course name with error: ${err}`)
            }
        },
        async createPEItem({commit, state}, item) {
            try {
                commit('setLoading', true)
                const peId = state.pe.permanentEducationId
                const newItem = await peApiClient.createPermanentEducationItem(peId, item)
                commit('addPEItem', newItem)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to create pe item with error: ${err}`)
            }
        },
        async updatePEItem({commit}, item) {
            try {
                commit('setLoading', true)
                const updatedItem = await peApiClient.updatePEItem(item)
                commit('updatePEItemById', updatedItem)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to update pe item with error: ${err}`)
            }
        },
        async deletePEItem({commit}, itemId) {
            try {
                commit('setLoading', true)
                const success = await peApiClient.deletePEItem(itemId)
                if (success) commit('removePEItemById', itemId)
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to delete pe item with error: ${err}`)
            }
        },
        async deleteCertificate({commit}, {path, itemId}) {
            try {
                commit('setLoading', true)
                const success = await peApiClient.deletePECertificate(itemId, path)
                if (success) commit('removeCertificate', {path, itemId})
                commit('setLoading', false)
            } catch (err) {
                console.error(err)
                commit('setLoading', false)
                commit('setError', err)
                throw new Error(`Failed to delete certificate with error: ${err}`)
            }
        },        
        async checkDuplicatePEName({commit, state}, courseName = null) {
            try {
                commit('setLoading', true)
                // Check if PE name already exists
                const dataQuery = new ODataQueryBuilder()
                    .setPagination(0, 1)
                    .addFilter(new ODataQueryFilter('userId', ODataFilterOperations.EQUALS, state.pe.userId))
                    .and()
                    .addFilter(new ODataQueryFilter('courseName', ODataFilterOperations.EQUALS, courseName ?? state.pe.courseName))
                    .build()

                const permanentEducationReminders =  await peApiClient.searchPermanentEducation(dataQuery)
                const isPEDuplicate = !!permanentEducationReminders?.items?.length
                commit('setIsPEDuplicate', isPEDuplicate)
            } catch (err) {
                console.error(err)
            } finally {
                commit('setLoading', false)
            }
        }
    }
}

<template>
  <recess-layout-static>
    <NotFoundSVG />
    <div class="d-flex justify-content-center">
      <div>
        <h1>Oeps we kunnen u helaas niet inloggen...</h1>
        <div class="text-center">
          Waarschijnlijk heeft u geen actief gebruikersaccount. Neem contact op met de supportdesk via 
          <a href="mailto:zakelijk@opleiding.nl">zakelijk@opleiding.nl</a>
        </div>
      </div>
    </div>
  </recess-layout-static>
</template>

<script>
import NotFoundSVG from '../../atoms/NotFoundSVG/NotFoundSVG.vue'

export default {
    components: {
        NotFoundSVG
    }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import configurationModule from './modules/configuration'
import coursePageModule from './modules/coursePage'
import verificationEnrollmentModule from './modules/enrollmentVerification'
import searchModule from './modules/search'
import accountModule from './modules/account'
import oplzConfigurationModule from './modules/oplzConfiguration'
import userModule from './modules/user'
import courseWizardModule from './modules/courseWizard'
import enrollmentModule from './modules/enrollment'
import compareCoursesModule from "./modules/compareCourses"
import facetsModule from "./modules/facets"
import segmentModule from "./modules/segment"
import oleProvidersModule from "./modules/oleProviders"
import powerBIModule from "./modules/powerBI"
import peModule from "./modules/permanentEducation"
import socialLearningPostModule from "./modules/socialLearningPost"
import startMomentsModule from './modules/startMoments'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        configurationModule,
        accountModule,
        searchModule,
        coursePageModule,
        userModule,
        courseWizardModule,
        oplzConfigurationModule,
        verificationEnrollmentModule,
        enrollmentModule,
        compareCoursesModule,
        facetsModule,
        segmentModule,
        oleProvidersModule,
        powerBIModule,
        peModule,
        socialLearningPostModule,
        startMomentsModule
    }
})

export default store

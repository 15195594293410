import { getOplzClient } from './apiClient'

const getCurrentAccount = async () => {
    const client = await getOplzClient()
    return client.get(`/accounts/getCurrentAccount`).then(response => {
        return response?.data
    })
}

export default {
    getCurrentAccount
}

<template>
  <recess-tab-navigation
    name="project-requests"
  >
    <recess-tab-navigation-item
      data-test="project-initiator-tab"
      :tab-key="projectRequestListingViews.PROJECTINITIATOR"
    >
      Mijn projectaanvragen
    </recess-tab-navigation-item>   
    <recess-tab-navigation-item
      data-test="project-approver-tab"
      :tab-key="projectRequestListingViews.PROJECTAPPROVER"
    >
      Mijn project beoordelingen
    </recess-tab-navigation-item>      
  </recess-tab-navigation>
</template>

<script>
import projectRequestListingViews from '../../../../../constants/projectRequestListingViews'

export default {
    setup() {
        return { projectRequestListingViews }
    }
}
</script>
import { getOplzClient } from './apiClient'

const baseUrl = '/studycontracttemplate'

const getStudyContractTemplates = async dataQuery => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/getStudyContractTemplates`, dataQuery).then(response => {
        return response?.data
    })
}

const getStudyContractTemplateDocument = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}/document`).then(response => {
        return response?.data
    })
}

export default { 
    getStudyContractTemplates,
    getStudyContractTemplateDocument
}

<template>
  <div>
    <recess-divider />
    
    <recess-button
      v-if="showBackButton"
      variant="tertiary"
      title="Terug"
      class="mr-3 mb-3 mb-md-0 col-12 col-md-auto"
      @click.native="$emit('previous-step')"
    />

    <recess-button
      variant="tertiary"
      title="Annuleren"
      class="mr-3 mb-3 mb-md-0 col-12 col-md-auto"
      @click.native="$emit('cancel')"
    />

    <recess-button
      v-if="showNextButton"
      variant="primary"
      :title="nextButtonText"
      data-test="next-step-button"
      class="u-position-right col-sm-12 col-md-auto mb-3 mb-md-0"
      @click.native="$emit('next-step')"
    />

    <recess-divider />
  </div>
</template>

<script>
export default {
    props: {
        showBackButton: {
            type: Boolean,
            default: true
        },
        showNextButton: {
            type: Boolean,
            default: true
        },
        nextButtonText: {
            type: String,
            default: 'Verder'
        }
    }
}
</script>
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />

    <p
      data-test="explanation-text"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      {{ explanationText }}
    </p>

    <transition name="fade">
      <div v-if="studyContracts && studyContracts.length">
        <div class="c-select-input--page-size">
          <label>Aantal per pagina</label>
          <recess-select
            :options="studyContractsPageSizes"
            :default-value="mDataQuery.take"
            class="ml-2"
            data-test="search-size"
            @input="(value) => setPageSize(value)"
          />
        </div>
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
                :sortable="header.isSortable"
                :sort-direction="getSortDirection(header)"
                @click.native="setSorting(header)"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="studyContract in studyContracts"
              :key="studyContract.Id"
            >
              <recess-table-cell :data-label="headers[0].title">
                <router-link
                  :to="{ name: 'course', query: { course: studyContract.enrollment.eduMSCourseId, assortment: studyContract.enrollment.assortmentId }}"
                >
                  <span v-html="studyContract.enrollment.courseName"></span>
                </router-link>
              </recess-table-cell>

              <recess-table-cell :data-label="headers[1].title">
                <span>{{ format(studyContract.creationDate) }}</span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers[2].title">
                <span>{{ studyContract.studyContractTemplate.name }}</span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers[3].title">
                <span>{{ studyContract.stateDisplayValue }}</span>
              </recess-table-cell>

              <recess-table-cell>
                <recess-button
                  data-test="study-contract-button"
                  :data-test-state="studyContract.state"
                  :url="{ name: 'studycontract', query: { ...$route.query, studyContract: studyContract.studyContractId }}"
                  :title="studyContractButtonText(studyContract.enrollment)"
                  :variant="studyContractButtonVariant(studyContract.enrollment)"
                  class="col-12 col-md-auto"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <template v-if="showPagination">
          <recess-divider />
          <pagination-component
            :current-page-number="currentPageNumber"
            :total-pages="totalPages"
            @goToPage="paginationClicked"
          />
        </template>
      </div>
    </transition>
    
    <recess-alert
      v-if="showNoStudyContractMessage"
      text="Er zijn op dit moment geen studieovereenkomsten."
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import studyContractPerimeter from '../../../perimeters/studyContractPerimeter'
import studyContractClient from '../../../api/studyContractClient'
import DateUtils from '../../../utils/DateUtils'
import DataQueryBuilder from '../../../api/DataQuery/dataQueryBuilder'
import DataQueryFilter from '../../../api/DataQuery/dataQueryFilter'
import * as enrollmentStatus from '../../../constants/enrollmentStatus'

export default {
    components: {
        LoaderComponent,
        PaginationComponent
    },
    perimeters: [studyContractPerimeter],
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            headers: [
                { key: 'Enrollment.CourseName', title: 'Ontwikkelactiviteit', isSortable: true },
                { key: 'CreationDate', title: 'Creatiedatum', isSortable: true },
                {
                    key: 'StudyContractTemplate.Name',
                    title: 'Studieovereenkomst',
                    isSortable: true
                },
                { key: 'State', title: 'Status', isSortable: true },
                { key: '', title: '', isSortable: false }
            ],
            studyContracts: null,
            showNoStudyContractMessage: false,
            studyContractsTotalCount: 0,
            studyContractsPageIndex: 1,
            studyContractsPageSize: 10,
            studyContractsPageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            isLoading: false,
            mDataQuery: {
                sortField: 'CreationDate',
                sortDirection: 'Desc',
                skip: 0,
                take: 10,
                filters: []
            }
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        dataQuery() {
            if (this.user?.userId) {
                return new DataQueryBuilder()
                    .setSort(this.mDataQuery.sortField, this.mDataQuery.sortDirection)
                    .setPagination(this.mDataQuery.skip, this.mDataQuery.take)
                    .addFilter(new DataQueryFilter('UserId', 'Equals', this.user.userId))
                    .build()
            }
            return null
        },
        currentPageNumber() {
            if (this.studyContracts) {
                return this.studyContractsPageIndex
            }
            return 1
        },
        totalPages() {
            if (this.studyContracts) {
                return Math.ceil(this.studyContractsTotalCount / this.studyContractsPageSize)
            }
            return 1
        },
        showPagination() {
            return this.studyContracts && this.totalPages > 1
        }
    },
    watch: {
        dataQuery: {
            handler(value) {
                // Its a function because we need the this context
                if (value) {
                    // The dataQuery object changed, lets do a new request
                    this.doRequest()

                    // also update the url
                    const deserializeDataQuery = this.$route?.query?.tableDataQueries
                        ? JSON.parse(Buffer.from(this.$route.query.tableDataQueries, 'base64').toString('utf8'))
                        : {}
                    deserializeDataQuery.myStudyContracts = this.mDataQuery
                    const serializedDataQuery = Buffer.from(JSON.stringify(deserializeDataQuery), 'utf8').toString('base64')
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            'my-development-tab': this.$route?.query['my-development-tab'],
                            tableDataQueries: serializedDataQuery
                        }
                    })
                }
            },
            deep: true
        }
    },
    mounted() {
        // Deserialize the dataquery object and set it
        if (this.$route.query.tableDataQueries) {
            const deserializeDataQuery = JSON.parse(atob(this.$route.query.tableDataQueries))
            this.mDataQuery = deserializeDataQuery.myStudyContracts || this.mDataQuery
        }
        this.mDataQuery.take = this.getTakeFromSessionStorage()

        this.doRequest()
    },
    methods: {
        doRequest() {
            this.isLoading = true
            if (this.dataQuery) {
                // Do the request
                studyContractClient
                    .getStudyContracts(this.dataQuery)
                    .then(response => {
                        this.showNoStudyContractMessage = false

                        this.studyContracts = response?.items ?? []
                        this.studyContractsTotalCount = response?.totalCount ?? 0
                        this.studyContractsPageSize = response?.pageSize ?? this.mDataQuery.size
                        this.studyContractsPageIndex = response?.pageIndex ?? 0

                        if(!this.studyContracts?.length) {
                            this.showNoStudyContractMessage = true
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .then(() => {
                        this.isLoading = false
                    })
            }
        },
        paginationClicked(pageNumber) {
            this.mDataQuery.skip = this.mDataQuery.take * (pageNumber - 1)
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.mDataQuery.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.mDataQuery.skip = 0

            // set the new page size value in the data query and in the sessopm storage
            this.mDataQuery.take = value
            sessionStorage.setItem('MyStudyContractsTableComponent/take', JSON.stringify(value))
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.mDataQuery.sortField === header.key) {
                this.mDataQuery.sortDirection =
                    this.mDataQuery.sortDirection === 'Asc' ? 'Desc' : 'Asc'
            } else {
                this.mDataQuery.sortDirection = 'Desc'
            }
            this.mDataQuery.sortField = header.key
            this.mDataQuery.skip = 0
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.mDataQuery.sortField !== header.key) {
                return null
            }

            return this.mDataQuery.sortDirection
                ? this.mDataQuery.sortDirection.toLowerCase()
                : null
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('MyStudyContractsTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(sessionStorage.getItem('MyStudyContractsTableComponent/take')),
                        10
                    )
                } catch {
                    return this.studyContractsPageSize
                }
            }
            return this.studyContractsPageSize
        },
        studyContractButtonText(enrollment) {
            return enrollment?.statusId ===
                enrollmentStatus.PENDINGSTUDYCONTRACTAGREEMENT
                ? 'Beoordelen'
                : 'Bekijk'
        },
        studyContractButtonVariant(enrollment) {
            return enrollment?.statusId ===
                enrollmentStatus.PENDINGSTUDYCONTRACTAGREEMENT
                ? 'primary'
                : 'tertiary'
        },
        format: e => DateUtils.formatDate(e)
    }
}
</script>
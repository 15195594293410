<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />
   
    <div
      data-test="explanation-text"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      <p>
        {{ explanationText }}
      </p>

      <recess-button
        :url="{ name: 'create-permanent-education' }"
        title="Voeg een nieuw item toe"
        variant="primary"
        class="u-min-width-140"
      />
    </div>

    <recess-divider />

    <recess-alert
      v-if="isError"
      class="mt-5"
      type="error"
      text="Oeps, er is iets mis gegaan met het opslaan. Probeer het later opnieuw."
    />

    <transition name="fade">
      <div v-if="permanentEducationReminders && permanentEducationReminders.length">
        <div class="c-select-input--page-size">
          <label>Aantal per pagina</label>
          <recess-select
            :options="permanentEducationRemindersPageSizes"
            :default-value="take"
            class="ml-2"
            @input="(value) => setPageSize(value)"
          />
        </div>
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
                :class="header.class"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="permanentEducationReminder in permanentEducationReminders"
              :key="permanentEducationReminder.permanentEducationId"
              :data-test="`pe-${permanentEducationReminder.permanentEducationId}`"
            >
              <recess-table-cell
                :data-label="headers[0].title"
                class="d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <recess-tooltip v-if="$isAllowed('displayPEReminderCertificate')">
                    <recess-icon
                      data-test="certification-icon"
                      :icon="permanentEducationReminder.hasCertificate ? 'document-uploaded' : 'document-upload'"
                      :disabled="!permanentEducationReminder.hasCertificate"
                      :color="permanentEducationReminder.hasCertificate ? 'green' : 'black'"
                      :height="25"
                      :width="25"
                      class="mr-2"
                    />
                    <template #content>
                      <span class="d-block u-max-width-500">
                        {{ permanentEducationReminder.hasCertificate ? permanentEducationTooltipText.certificateUploadedToPermanentEducationItemTooltip : permanentEducationTooltipText.certificateNotUploadedToPermanentEducationItemTooltip }}
                      </span>
                    </template>
                  </recess-tooltip>
                  <template v-if="showEnrollmentIcon(permanentEducationReminder.latestEnrollmentId)">                    
                    <router-link
                      data-test="pe-course-details-redirect"
                      :to="{ name: 'course', query: { course: permanentEducationReminder.latestEnrollmentCourseId, assortment: permanentEducationReminder.latestEnrollmentAssortmentId }}">
                      <span v-html="permanentEducationReminder.courseName"></span>
                    </router-link>
                  </template>
                  <router-link
                    v-else
                    data-test="pe-course-search-redirect"
                    :to="{ name: 'search', query: {q: permanentEducationReminder.courseName}}"
                  >
                    <span v-html="permanentEducationReminder.courseName"></span>
                  </router-link>
                </div>
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[1].title"
                class="d-flex align-items-center justify-content-between"
              >
                {{ format(permanentEducationReminder.startDate) }}
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[2].title"
                class="d-flex align-items-center justify-content-between"
              >
                {{ format(permanentEducationReminder.expiryDate) }}
              </recess-table-cell>
              <recess-table-cell 
                :data-label="headers[3].title"
                class="d-flex align-items-center justify-content-between"
              >
                <span v-if="permanentEducationReminder.emailReminderPeriod">{{ permanentEducationReminder.emailReminderPeriod  }} maanden voor vervaldatum</span>
                <span v-else>-</span>
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[4].title"
                class="d-flex align-items-center justify-content-between u-max-width-lg-200"
              >
                {{ permanentEducationReminder.createdByUserFullName || '-' }}
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[5].title"
                class="d-flex align-items-center justify-content-between u-max-width-lg-150"
              >
                <recess-toggle-input
                  v-model="permanentEducationReminder.emailEnabled"
                  :disabled="isLoading || !permanentEducationReminder.emailReminderPeriod"
                  @input="toggleEmailReminder(permanentEducationReminder)"
                />
              </recess-table-cell>
              <recess-table-cell
                class="d-flex align-items-center justify-content-end u-max-width-lg-200"
              >
                <template v-if="showEnrollmentIcon(permanentEducationReminder.latestEnrollmentId)">
                  <recess-tooltip data-test="pe-enrollment-details-tooltip" >
                    <recess-icon
                      data-test="enrollment-icon"
                      :icon="'document-details'"
                      :height="25"
                      :width="25"
                      @click.native="$router.push({ name: 'enrollment-details', query: { enrollment: permanentEducationReminder.latestEnrollmentId }})"
                    />
                    <template #content>
                      <span class="d-block u-max-width-500"> {{ permanentEducationTooltipText.enrollmentLinkedToPermanentEducationTooltip }}</span>
                    </template>
                  </recess-tooltip>
                  <recess-divider
                    v-if="studentCanEditPEReminders"
                    variant="medium"
                    vertical
                    show-line
                  />
                </template>
                <template v-if="studentCanEditPEReminders">
                  <router-link
                    :to="{ name: 'edit-permanent-education', params: { id: permanentEducationReminder.permanentEducationId }}"
                    data-test="edit-permanent-education"
                    class="u-line-height-0"
                  >
                    <recess-icon
                      icon="edit"
                    />
                  </router-link>
                  <recess-divider
                    variant="medium"
                    vertical
                    show-line
                  />
                  <recess-icon
                    :disabled="!$isAllowed('removePermanentEducation', { userId: permanentEducationReminder.userId, createdByUserId: permanentEducationReminder.createdByUserId, isImported: permanentEducationReminder.isImported })"
                    icon="bin"
                    class="d-flex"
                    data-test="delete-btn"
                    @click="setRemoveItemModalVisibility(permanentEducationReminder.permanentEducationId)"
                  />
                </template>
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <template v-if="showPagination">
          <recess-divider />
          <pagination-component
            :current-page-number="currentPageNumber"
            :total-pages="totalPages"
            @goToPage="paginationClicked"
          />
        </template>
      </div>
    </transition>
    <recess-alert
      v-if="showNoPERemindersMessage"
      text="Momenteel zijn er geen leerresultaten."
    />
    <remove-item-confirmation-component
      :is-visible="removePEReminderConfirmationVisibility"
      confirmation-text="Weet u zeker dat u deze leerresultaten wilt verwijderen?"
      @close="removePEReminderConfirmationVisibility = false"
      @remove="deletePermanentEducationReminder(peReminderToDelete)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import DateUtils from '../../../utils/DateUtils'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import odataFilterOperations from '../../../api/OData/odataFilterOperations'
import { deletePermanentEducation, togglePermanentEducationEmail, searchPermanentEducation } from '../../../api/permanentEducationClient'
import permanentEducationPerimeter from '../../../perimeters/permanentEducationPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import RemoveItemConfirmationComponent from '../../atoms/RemoveItemConfirmationComponent/RemoveItemConfirmationComponent.vue'
import { mapPermanentEducationToLegacy } from '../../../utils/MapUtils'
import permanentEducationTooltipText from '../../../constants/permanentEducationTooltipText'

export default {
    perimeters: [permanentEducationPerimeter, featuresPerimeter],
    components: {
        LoaderComponent,
        PaginationComponent,
        RemoveItemConfirmationComponent
    },
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            permanentEducationReminders: null,
            showNoPERemindersMessage: false,
            permanentEducationRemindersTotalCount: 0,
            permanentEducationRemindersPageIndex: 1,
            permanentEducationRemindersPageSize: 10,
            permanentEducationRemindersPageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 }
            ],
            isLoading: false,
            isError: false,
            skip: 0,
            take: 10,
            removePEReminderConfirmationVisibility: false,
            peReminderToDelete: null,
            permanentEducationTooltipText
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId']),
        ...mapGetters('accountModule', ['studentCanEditPEReminders']),
        headers() {
            const headers = [
                { key: 'CourseName', title: 'Vereiste certificering' },
                { key: 'StartDate', title: 'Ingangsdatum'},
                { key: 'ExpiryDate', title: 'Vervaldatum' },
                { key: 'Herinnering', title: 'Herinnering' },
                { key: 'CreatedByUserFullName', title: 'Gemaakt door', class: 'u-max-width-lg-200' },
                { key: 'e-mail', title: 'Notificatie actief', class: "u-max-width-lg-150" }
            ]

            if (this.studentCanEditPEReminders || (this.$isAllowed('displayLearningResult') && this.permanentEducationReminders.some(p => !!p.latestEnrollmentId))) 
                headers.push({ key: 'actions', title: '', class: 'u-max-width-lg-200' })

            return headers
        },
        currentPageNumber() {
            if (this.permanentEducationReminders) {
                return this.permanentEducationRemindersPageIndex
            }
            return 1
        },
        totalPages() {
            if (this.permanentEducationReminders) {
                return Math.ceil(
                    this.permanentEducationRemindersTotalCount /
                        this.permanentEducationRemindersPageSize
                )
            }
            return 1
        },
        showPagination() {
            return this.permanentEducationReminders && this.totalPages > 1
        }
    },
    async mounted() {
        // Add the sortField, sortDirection, skip and take to the dataQuery and do a request
        this.addDataQueryParams()
        await this.fetchPermanentEducationsReminders()
    },
    methods: {
        async fetchPermanentEducationsReminders() {
            try {
                this.isLoading = true
                const includes = ['CreatedByUser']

                const dataQuery = new ODataQueryBuilder()
                    .setPagination(this.skip, this.take)
                    .setSort('creationDate', 'desc')
                    .addFilter(new ODataQueryFilter('userId', odataFilterOperations.EQUALS, this.userId))
                  
                if(!this.$isAllowed('displayLearningResult')) {
                    dataQuery
                        .addDynamicFilter('(createdByUserId ne null or isImported eq true)')
                }
                else {
                    includes.push('LatestEnrollment')
                }
                dataQuery.setIncludes(includes.join(','))

                const response =  await searchPermanentEducation(dataQuery.build())

                this.permanentEducationReminders = (response?.items ?? []).map(mapPermanentEducationToLegacy)
                this.permanentEducationRemindersTotalCount = response?.totalCount ?? 0
                this.permanentEducationRemindersPageSize = response?.pageSize ?? 10
                this.permanentEducationRemindersPageIndex = response?.pageIndex ?? 0

                if(!response?.items?.length) this.showNoPERemindersMessage = true
                else this.showNoPERemindersMessage = false
            } catch (error) {
                throw new Error(error)
                
            } finally {
                this.isLoading = false
            }
        },
        paginationClicked(pageNumber) {
            this.skip = this.take * (pageNumber - 1)
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    skip: this.skip
                }
            })

            this.fetchPermanentEducationsReminders()
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.skip = 0
            if (this.$route.query?.skip) {
                this.$router.replace({
                    path: this.$route.path,
                    query: { ...this.$route.query, skip: 0 }
                })
            }

            // set the new page size value in the data query and in the sessopm storage
            this.take = value
            sessionStorage.setItem('MyPERemindersTableComponent/take', JSON.stringify(value))

            this.fetchPermanentEducationsReminders()
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('MyPERemindersTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(sessionStorage.getItem('MyPERemindersTableComponent/take')),
                        10
                    )
                } catch {
                    return this.permanentEducationRemindersPageSize
                }
            }
            return this.permanentEducationRemindersPageSize
        },
        addDataQueryParams() {
            // Get table state properties from the url
            if (this.$route.query?.skip) this.skip = parseInt(this.$route.query.skip, 10)

            this.take = this.getTakeFromSessionStorage()
        },
        async deletePermanentEducationReminder(id) {
            this.isLoading = true
            this.removePEReminderConfirmationVisibility = false
            
            try {
                await deletePermanentEducation(id)
                this.fetchPermanentEducationsReminders()
            } catch (err) {
                throw new Error(err)
            } finally {
                this.peReminderToDelete = null
            }
        },
        async toggleEmailReminder(peReminder) {
            try {
                this.isLoading = true
                await togglePermanentEducationEmail(peReminder.permanentEducationId)
            } catch (err) {
                // revert change
                peReminder.emailEnabled = !peReminder.emailEnabled
                this.isError = true
                throw new Error(err)
            } finally {
                this.isLoading = false
            }
        },
        setRemoveItemModalVisibility(peReminderId) {
            this.peReminderToDelete = peReminderId
            this.removePEReminderConfirmationVisibility = true
        },
        showEnrollmentIcon(peLatestEnrollmentId) {
            return this.$isAllowed('displayLearningResult') && !!peLatestEnrollmentId
        },
        format: e => DateUtils.formatDate(e)
    }
}
</script>
export default class SearchQuery {
    constructor(top, skip, orderBy, facets, filter, select) {
        this.skip = skip
        this.filter = filter
        this.orderBy = orderBy
        this.top = top
        this.select = select
        this.facets = facets
    }

    setSkip(skip) {
        this.skip = skip
    }

    setTop(top) {
        this.top = top
    }

    setSelects(fields) {
        this.select = [...fields]
    }

    setFacets(facets) {
        this.facets = [...facets]
    }

    setOrderBy(orderBy) {
        this.orderBy = [...orderBy]
    }
}
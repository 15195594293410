<template>
    <recess-layout-static v-if="$isAllowed('routeMyTeamPage')" :size="1700">
        <recess-divider />
        <h1>Mijn team</h1>
        <recess-divider />

        <template v-if="isMobile">
            <template v-for="(tab, key) in tabComponents">
                <recess-accordion
                    v-if="tab.isTabVisible !== false"
                    :key="key"
                    :active="key === currentComponentKey"
                    variant="variant-3"
                    @toggled="(isActive) => toggledTab(isActive, key)"
                >
                    <template #accordion-title>
                        {{ tab.displayValue }}
                    </template>
                    <template #accordion-content>
                        <component
                            :is="currentComponent.componentName"
                            v-if="currentComponent?.key === key"
                        />
                    </template>
                </recess-accordion>
            </template>
        </template>

        <template v-else>
            <recess-tab-navigation name="my-team">
                <template v-for="(tab, key) in tabComponents">
                    <recess-tab-navigation-item
                        v-if="tab.isTabVisible !== false"
                        :key="key"
                        :tab-key="key"
                        :data-test="`my-team-${key}`"
                    >
                        {{ tab.displayValue }}
                    </recess-tab-navigation-item>
                </template>
            </recess-tab-navigation>
            <keep-alive>
                <component
                    :is="currentComponent.componentName"
                    v-if="currentComponent && currentComponent.isTabVisible !== false"
                />
            </keep-alive>
        </template>

        <recess-divider />
    </recess-layout-static>
</template>

<script>
import { jsMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'
import MyTeamActiveCoursesTable from '../../organisms/Tables/MyTeamActiveCoursesTable.vue'
import MyTeamEnrollmentsTable from '../../organisms/Tables/MyTeamEnrollmentsTable.vue'
import MyTeamPERemindersTable from '../../organisms/Tables/MyTeamPERemindersTable.vue'
import MyTeamStudyContractsTable from '../../organisms/Tables/MyTeamStudyContractsTable.vue'
import MyTeamCourseRequestTable from '../../organisms/Tables/MyTeamCourseRequestTable.vue'
import MyTeamLearningPathsTable from '../../organisms/Tables/MyTeamLearningPathsTable.vue'
import MyTeamBudgetsTable from '../../organisms/Tables/MyTeamBudgetsTable.vue'
import StudentsTable from '../../organisms/Tables/StudentsTable.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import { myTeamTabs } from '../../../constants/tabNames'

export default {
    name: 'MyTeam',
    mixins: [jsMediaQueriesMixin],
    perimeters: [routePerimeter, featuresPerimeter],
    computed: {
        currentComponentKey() {
            return this.$route?.query['my-team-tab']
        },
        currentComponent() {
            return this.tabComponents[this.currentComponentKey]
        },
        tabComponents() {
            return {
                [myTeamTabs.ACTIVECOURSES.key]: {
                    ...myTeamTabs.ACTIVECOURSES,
                    componentName: MyTeamActiveCoursesTable,
                    isTabVisible: this.$isAllowed('routeAsCoordinator') || this.$isAllowed('routeAsTeamleader')
                },
                [myTeamTabs.ENROLLMENTS.key]: {
                    ...myTeamTabs.ENROLLMENTS,
                    componentName: MyTeamEnrollmentsTable
                },
                [myTeamTabs.STUDYCONTRACTS.key]: {
                    ...myTeamTabs.STUDYCONTRACTS,
                    componentName: MyTeamStudyContractsTable,
                    isTabVisible: this.$isAllowed('displayStudyContract')
                },
                [myTeamTabs.COURSEREQUESTS.key]: {
                    ...myTeamTabs.COURSEREQUESTS,
                    componentName: MyTeamCourseRequestTable,
                    isTabVisible: this.$isAllowed('routeApprovalAbcPage')
                },
                [myTeamTabs.PERMANENTEDUCATIONS.key]: {
                    ...myTeamTabs.PERMANENTEDUCATIONS,
                    componentName: MyTeamPERemindersTable,
                    isTabVisible:
                        (this.$isAllowed('routeAsCoordinator') ||
                            this.$isAllowed('routeAsTeamleader')) &&
                        this.$isAllowed('displayPERemindersFeature')
                },
                [myTeamTabs.LEARNINGPATHS.key]: {
                    ...myTeamTabs.LEARNINGPATHS,
                    componentName: MyTeamLearningPathsTable,
                    isTabVisible:
                        this.$isAllowed('displayLearningPath') &&
                        (this.$isAllowed('routeAsCoordinator') ||
                            this.$isAllowed('routeAsTeamleader'))
                },
                [myTeamTabs.BUDGETS.key]: {
                    ...myTeamTabs.BUDGETS,
                    componentName: MyTeamBudgetsTable,
                    isTabVisible:
                        this.$isAllowed('displayBudgets') &&
                        (this.$isAllowed('routeAsCoordinator') ||
                            this.$isAllowed('routeAsTeamleader'))
                },
                [myTeamTabs.USERS.key]: {
                    ...myTeamTabs.USERS,
                    componentName: StudentsTable
                }
            }
        }
    },
    created() {
        if (
            !this.$route?.query['my-team-tab'] ||
            !Object.values(myTeamTabs).some((tab) => tab.key === this.$route?.query['my-team-tab'])
        ) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    'my-team-tab': myTeamTabs.ACTIVECOURSES.key
                }
            })
        }
    },
    methods: {
        toggledTab(isActive, tab) {
            if (isActive) {
                this.$router.replace({
                    query: { ...this.$route.query, 'my-team-tab': tab }
                })
            } else {
                this.$router.replace({
                    query: { ...this.$route.query, 'my-team-tab': undefined }
                })
            }
        }
    }
}
</script>

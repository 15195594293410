import { getOplzClient } from './apiClient'

const baseUrl = '/faq-categories'

const getFaqCategories = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, data).then(response => {
        return response?.data
    })
}

export default {
    getFaqCategories
}

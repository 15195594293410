<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
    <div>
        <!-- Loader -->
        <transition name="fade" mode="out-in">
            <loader-component v-if="isLoading" overlay="white" color="primary" />
        </transition>

        <!-- Duration -->
        <template v-if="durationText">
            <span class="font-weight-bold">Duur:</span>
            <p>{{ durationText }}</p>
        </template>

        <!-- Studyload -->
        <template v-if="studyLoadText">
            <span class="font-weight-bold" data-test="study-load-title"> Studielast:</span>
            <p data-test="study-load-text">{{ studyLoadText }}</p>
        </template>

        <template v-if="showIncompanyRequestBtn">
            <p class="font-weight-bold" data-test="course-abc-request-text">
                Ik heb een groep van minimaal 8 collega's die samen deze ontwikkelactiviteit willen
                volgen als incompany.
            </p>
            <recess-button
                title="Start Incompany aanvraag"
                variant="primary"
                data-test="course-abc-request-btn"
                @click="startCourseAbcRequest"
            />

            <recess-divider />
        </template>

        <!-- Planning tab content -->
        <template v-if="planningContent">
            <div
                v-for="(content, index) in planningContent"
                :key="`planningContent${index}`"
                class="c-course-content__wrapper"
            >
                <h3 v-if="content.heading">{{ content.heading }}</h3>
                <div
                    v-if="content.bodyHtml || content.body"
                    class="c-course-content__block"
                    v-html="content.bodyHtml || content.body"
                ></div>
            </div>
            <recess-divider />
        </template>

        <!-- Startmoments table -->
        <slot v-if="showStartmoments" name="startmoments-content">
            <course-page-start-moments-listing
                :course="course"
                :course-id="course.id"
                :assortment-id="courseAssortmentId"
                :product-type="productType"
                :provider-id="providerId"
                :limit-incompany-enrollments-and-is-incompany="
                    limitIncompanyEnrollmentsAndIsIncompany
                "
            />
        </slot>

        <!-- Volgen functionality -->
        <template v-else-if="isClassRoomLearningMethod && $isAllowed('followCourse')">
            <p class="font-weight-bold">
                Er zijn op dit moment (nog) geen startmomenten bekend. Om geïnformeerd te worden
                wanneer er nieuwe startmomenten zijn, klik op de volgen knop hieronder.
            </p>
            <recess-button
                title="Volgen"
                variant="primary"
                data-test="follow-course-btn"
                @click="followCourse"
            />
        </template>

        <!-- Show message if course is self planned -->
        <recess-alert
            v-else
            text="Indien u de knop 'inschrijven' ziet, kunt u zich inschrijven voor de ontwikkelactiviteit. 
      Als deze knop niet zichtbaar is dan zijn er geen startmomenten beschikbaar om op in te schrijven. 
      Indien u een groep van 5 collega's of meer heeft, die de ontwikkelactiviteit willen volgen, 
      bestaat de mogelijkheid tot het organiseren van een incompany ontwikkelactiviteit. Neem daarvoor contact met ons op."
        />

        <!-- Show error message if something goes wrong when sending follow course email -->
        <recess-alert
            v-if="showErrorMessage"
            class="mt-5"
            data-test="error-txt"
            type="error"
            text="Er is een fout opgetreden. Probeer het later opnieuw."
        />

        <!-- Modal for the 'volgen' confirmation message -->
        <recess-modal
            modal-size="small"
            data-test="follow-success-modal"
            :show-modal="showFollowCourseMessage"
            @close="showFollowCourseMessage = false"
        >
            <template #modal-body>
                U bent succesvol toegevoegd aan de notificatielijst. Wij informeren u wanneer er
                nieuwe startmomenten bekend zijn voor deze ontwikkelactiviteit.
            </template>
        </recess-modal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import userClient from '../../../api/userClient'
import CoursePageStartMomentsListing from './CoursePageStartMomentsListing.vue'
import { REGULAR } from '../../../constants/productTypes'
import { AbcIncompanyAllowedLearningMethodFormats } from '../../../constants/abcRequestSettings'

export default {
    components: {
        CoursePageStartMomentsListing,
        LoaderComponent
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            showFollowCourseMessage: false,
            showErrorMessage: false,
            isFetchingOrders: false,
            isSendingFollowCourse: false
        }
    },
    computed: {
        ...mapState('coursePageModule', ['course', 'courseAssortmentId']),
        ...mapGetters('coursePageModule', [
            'showStartmoments',
            'durationText',
            'studyLoadText',
            'planningContent',
            'isClassRoomLearningMethod',
            'limitIncompanyEnrollmentsAndIsIncompany',
            'productType',
            'providerId'
        ]),
        isLoading() {
            return this.isFetchingOrders || this.isSendingFollowCourse
        },
        showIncompanyRequestBtn() {
            return (
                this.$isAllowed('displayABCRequestIncompany') &&
                this.productType === REGULAR &&
                AbcIncompanyAllowedLearningMethodFormats.includes(
                    this.course?.learningMethod.format
                )
            )
        }
    },
    methods: {
        async followCourse() {
            try {
                this.isSendingFollowCourse = true
                this.showErrorMessage = false
                const followCourseRequest = {
                    courseName: this.course?.name,
                    externalId: this.course?.externalId
                }

                const response = await userClient.postFollowCourse(followCourseRequest)

                if (response) {
                    this.showFollowCourseMessage = true
                } else this.showErrorMessage = true
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isSendingFollowCourse = false
            }
        },
        startCourseAbcRequest() {
            const courseUrl = new URL(window.location.href)
            courseUrl.searchParams.delete('course-page-tab')

            const courseData = {
                id: this.course.id,
                name: this.course.name,
                url: courseUrl.href,
                assortmentId: this.courseAssortmentId,
                providerName: this.course.embedded?.provider?.name ?? null
            }

            this.$router.push({ name: 'abc-request', params: { course: courseData } })
        }
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isLoading"
      overlay="white"
      color="primary"
    />
    
    <p
      data-test="explanation-text"
      class="p-0 pl-md-4 col-12 col-md-8"
    >
      {{ explanationText }}
    </p>

    <transition name="fade">
      <div v-if="enrollments && enrollments.length">
        <div
          v-show="showPaginationOptions"
          class="c-select-input--page-size"
        >
          <label>Aantal per pagina</label>
          <recess-select
            :options="enrollmentsPageSizes"
            :default-value="mDataQuery.take"
            class="ml-2"
            @input="(value) => setPageSize(value)"
          />
        </div>
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <template v-for="header in headers">
                <recess-table-head-item
                  v-if="displayHeaderCell(header.key, useNetPrice)"
                  :key="header.key"
                  :sortable="header.isSortable"
                  :sort-direction="getSortDirection(header)"
                  @click.native="setSorting(header)"
                >
                  {{ header.title }}
                </recess-table-head-item>
              </template>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="enrollment in enrollments"
              :key="enrollment.id"
            >
              <recess-table-cell
                :data-label="headers[0].title"
              >
                <router-link
                  :to="{ name: 'course', query: { course: enrollment.eduMSCourseId, assortment: enrollment.assortmentId }}"
                >
                  <span data-test="course-name" v-html="enrollment.courseName"></span>
                </router-link>
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[1].title"
              >
                {{ formatDate(enrollment.dateTimeCreated) }}
              </recess-table-cell>
              <recess-table-cell
                v-if="$isAllowed('displayPricingInformation')"
                :data-label="headers[2].title"
              >
                <span data-test="price-with-or-without-vat">{{ getPriceWithOrWithoutVat(displayVatInPrices, enrollment.displayPrice) }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[3].title">{{ enrollment.courseVariant }}</recess-table-cell>
              <recess-table-cell
                :data-label="headers[4].title"
              >
                {{ enrollment.currentStatus ? enrollment.currentStatus.displayValue : '' }}
              </recess-table-cell>
              <recess-table-cell class="d-flex align-items-center justify-content-between">
                <div>
                  <recess-button
                    v-if="$isAllowed('verifyEnrollment', enrollment)"
                    :url="{ name: 'enrollment-verification', query: {...$route.query, enrollment: enrollment.enrollmentId }}"
                    title="Controleren"
                    variant="primary"
                    class="col-12 col-md-auto"
                  />
                  <recess-button
                    v-else-if="showApproveButton(enrollment)"
                    :url="{ name: 'enrollment-approval', query: {...$route.query, enrollment: enrollment.enrollmentId }}"
                    title="Beoordelen"
                    variant="secondary"
                    class="col-12 col-md-auto"
                  />
                  <recess-button
                    v-else
                    :url="{ name: 'enrollment-details', query: {...$route.query, enrollment: enrollment.enrollmentId }}"
                    title="Bekijk"
                    variant="tertiary"
                    class="col-12 col-md-auto ml-3"
                  />
                </div>
                <RecessIcon
                  v-if="showDuplicateIcon(enrollment.createdById)"
                  icon="duplicate" 
                  class="ml-3"
                  :data-test="`copy-enrollment-${enrollment.enrollmentId}`"
                  @click="copyEnrollment(enrollment)"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <template v-if="showPagination">
          <recess-divider />
          <pagination-component
            :current-page-number="currentPageNumber"
            :total-pages="totalPages"
            @goToPage="paginationClicked"
          />
        </template>
      </div>
    </transition>
    
    <recess-alert
      v-if="showNoEnrollmentsMessage"
      text="Er zijn op dit moment geen inschrijvingen."
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

import enrollmentPerimeter from '../../../perimeters/enrollmentPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import enrollmentClient from '../../../api/enrollmentClient'
import DateUtils from '../../../utils/DateUtils'
import priceFormatter from '../../../modules/priceFormatter'

import DataQueryBuilder from '../../../api/DataQuery/dataQueryBuilder'
import DataQueryDynamicFilter from '../../../api/DataQuery/dataQueryDynamicFilter'
import copyEnrollmentMixin from '../../../mixins/copy-enrollment-mixin'
import coursePriceHelper from '../../../modules/coursePriceHelper'
import enrollmentsTableHelper from '../../../modules/enrollmentsTableHelper'

export default {
    components: {
        LoaderComponent,
        PaginationComponent
    },
    mixins: [copyEnrollmentMixin],
    perimeters: [enrollmentPerimeter, featuresPerimeter],
    props: {
        explanationText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            enrollments: null,
            showNoEnrollmentsMessage: false,
            enrollmentsTotalCount: 0,
            enrollmentsPageIndex: 1,
            enrollmentsPageSize: 10,
            enrollmentsPageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            isLoading: false,
            showPaginationOptions: true,
            mDataQuery: {
                sortField: 'DateTimeCreated',
                sortDirection: 'Desc',
                skip: 0,
                take: 10,
                filters: []
            }
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId']),
        ...mapGetters('accountModule', ['displayVatInPrices', 'useNetPrice']),
        headers() {
            return enrollmentsTableHelper.getEnrollmentTableHeaders(this.useNetPrice, this.displayVatInPrices)
        },
        dataQuery() {
            if (this.userId) {
                return new DataQueryBuilder()
                    .setSort(this.mDataQuery.sortField, this.mDataQuery.sortDirection)
                    .setPagination(this.mDataQuery.skip, this.mDataQuery.take)
                    .addFilter(new DataQueryDynamicFilter(`User.UserId == ${this.userId}`))
                    .build()
            }
            return null
        },
        currentPageNumber() {
            if (this.enrollments) {
                return this.enrollmentsPageIndex
            }
            return 1
        },
        totalPages() {
            if (this.enrollments) {
                return Math.ceil(this.enrollmentsTotalCount / this.enrollmentsPageSize)
            }
            return 1
        },
        showPagination() {
            return this.enrollments && this.totalPages > 1
        }
    },
    watch: {
        dataQuery: {
            handler(value) {
                // Its a function because we need the this context
                if (value) {
                    // The dataQuery object changed, lets do a new request
                    this.doRequest()

                    // also update the url
                    const deserializeDataQuery = this.$route.query.tableDataQueries
                        ? JSON.parse(Buffer.from(this.$route.query.tableDataQueries, 'base64').toString('utf8'))
                        : {}
                        
                    deserializeDataQuery.myEnrollments = this.mDataQuery
                    const serializedDataQuery = Buffer.from(JSON.stringify(deserializeDataQuery), 'utf8').toString('base64')
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            'my-development-tab': this.$route?.query['my-development-tab'],
                            tableDataQueries: serializedDataQuery
                        }
                    })
                }
            },
            deep: true
        }
    },
    mounted() {
        // Deserialize the dataquery object and set it
        if (this.$route.query.tableDataQueries) {
            const deserializeDataQuery = JSON.parse(Buffer.from(this.$route.query.tableDataQueries, 'base64'))
            this.mDataQuery = deserializeDataQuery.myEnrollments || this.mDataQuery
        }
        this.mDataQuery.take = this.getTakeFromSessionStorage()

        this.doRequest()
    },
    methods: {
        doRequest() {
            if (this.dataQuery) {
                this.isLoading = true

                // Do the request
                enrollmentClient
                    .getEnrollments(this.dataQuery)
                    .then(response => {
                        this.showNoEnrollmentsMessage = false
                        
                        this.enrollments = response?.items
                        this.enrollmentsTotalCount = response?.totalCount ?? 0
                        this.enrollmentsPageSize = response?.pageSize ?? this.mDataQuery.size
                        this.enrollmentsPageIndex = response?.pageIndex ?? 0

                        if(!this.enrollments?.length) {
                            this.showNoEnrollmentsMessage = true
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .then(() => {
                        this.isLoading = false
                    })
            }
        },
        paginationClicked(pageNumber) {
            this.mDataQuery.skip = this.mDataQuery.take * (pageNumber - 1)
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.mDataQuery.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.mDataQuery.skip = 0

            // set the new page size value in the data query and in the sessopm storage
            this.mDataQuery.take = value
            sessionStorage.setItem(
                'TeamLeaderEnrollmentsTableComponent/take',
                JSON.stringify(value)
            )
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.mDataQuery.sortField === header.key) {
                this.mDataQuery.sortDirection =
                    this.mDataQuery.sortDirection === 'Asc' ? 'Desc' : 'Asc'
            } else {
                this.mDataQuery.sortDirection = 'Desc'
            }
            this.mDataQuery.sortField = header.key
            this.mDataQuery.skip = 0
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.mDataQuery.sortField !== header.key) {
                return null
            }

            return this.mDataQuery.sortDirection
                ? this.mDataQuery.sortDirection.toLowerCase()
                : null
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('TeamLeaderEnrollmentsTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(
                            sessionStorage.getItem('TeamLeaderEnrollmentsTableComponent/take')
                        ),
                        10
                    )
                } catch {
                    return this.enrollmentsPageSize
                }
            }
            return this.enrollmentsPageSize
        },
        formatPrice: priceFormatter.formatPrice,
        formatDate: e => DateUtils.formatDate(e),
        displayHeaderCell: (headerKey, useNetPrice) => enrollmentsTableHelper.displayHeaderCell(headerKey, useNetPrice),
        showApproveButton: enrollment => enrollmentsTableHelper.showApproveButton(enrollment),
        getPriceWithOrWithoutVat: coursePriceHelper.getPriceWithOrWithoutVat
    }
}
</script>
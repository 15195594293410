import tabKeys from '../components/organisms/CoursePage/tabKeys'

export const courseHeadings = [
    {
        name: 'contact',
        courseTab: 'general'
    },
    {
        name: 'targetGroup',
        courseTab: 'program'
    },
    {
        name: 'relatedPrograms',
        courseTab: 'program'
    },
    {
        name: 'curriculum',
        courseTab: 'program'
    },
    {
        name: 'application',
        courseTab: 'general'
    },
    {
        name: 'costs',
        courseTab: 'investment'
    },
    {
        name: 'objectives',
        courseTab: 'program'
    },
    {
        name: 'instructionMode',
        courseTab: 'program'
    },
    {
        name: 'location',
        courseTab: 'planning'
    },
    {
        name: 'programGuide',
        courseTab: 'program'
    },
    {
        name: 'studyInformation',
        courseTab: 'program'
    },
    {
        name: 'language',
        courseTab: 'program'
    },
    {
        name: 'admissionCriteria',
        courseTab: 'general'
    },
    {
        name: 'faq',
        courseTab: 'general'
    },
    {
        name: 'testmonial',
        courseTab: 'general'
    },
    {
        name: 'programYear',
        courseTab: 'program'
    },
    {
        name: 'level',
        courseTab: 'general'
    },
    {
        name: 'organisationOfEducation',
        courseTab: 'planning'
    },
    {
        name: 'accessibility',
        courseTab: 'general'
    }
]

export const getTabHeadings = tabName => {
    return courseHeadings.filter(heading => heading.courseTab.includes(tabName)).map(x => x.name)
}

export const getContentForHeading = (courseContent, tabName) => {
    const tabHeadings = getTabHeadings(tabName)

    if (Array.isArray(courseContent)) {
        const tabContent = courseContent.reduce((acc, item) => {
            item.blocks.forEach(content => {
                if (!content.type) return

                tabHeadings.forEach(th => {
                    if (
                        content.type.toLowerCase() === th.toLowerCase() &&
            (content?.body?.trim() || content?.bodyHtml?.trim())
                    )
                        acc.push(content)
                })
            })
            return acc
        }, [])
        return tabContent.length ? tabContent : null
    }
    return null
}

export const totalTabContent = courseContent => {
    return Object.values(tabKeys).reduce((acc, key) => {
        if (Array.isArray(getContentForHeading(courseContent, key))) {
            getContentForHeading(courseContent, key).forEach(item => {
                acc.push(item.heading)
            })
        }
        return acc
    }, [])
}

export const introContent = courseContent => {
    if (Array.isArray(courseContent)) {
        return courseContent.map(content => {
            return {
                intro: content.intro,
                introHtml: content.introHtml
            }
        })
    }
    return null
}

export const restContent = courseContent => {
    if (Array.isArray(courseContent)) {
        let rest
        const totalTab = totalTabContent(courseContent)
        courseContent.forEach(content => {
            rest = content.blocks.filter(
                item => !totalTab.includes(item.heading) && (item?.body?.trim() || item?.bodyHtml?.trim())
            )
        })
        return rest
    }
    return null
}

export const descriptionContent = courseContent => {
    if (Array.isArray(courseContent)) {
        const description = courseContent
            .filter(content => !!content.description || !!content.descriptionHtml)
            .map(content => {
                return {
                    description: content.description,
                    descriptionHtml: content.descriptionHtml
                }
            })
        return description?.length ? description : null
    }
    return null
}

export default { courseHeadings, getTabHeadings, getContentForHeading, totalTabContent, introContent, restContent, descriptionContent }

import PEStatus from '../constants/peStatus'

/**
 * return the ref color class for the given status
 * 
 * @param {number} status 
 */
export function getColorClass(status) {
    switch (status) {
        case PEStatus.NA: return ''
        case PEStatus.OK: return 'u-bg--green'
        case PEStatus.UNCLEAR: return 'u-bg--variant6'
        case PEStatus.ALMOST_EXPIRED: return 'u-bg--orange'
        case PEStatus.EXPIRED: return 'u-bg--red'
        default: return ''
    }
}

/**
 * 
 * @param {number} status 
 * @returns {string}
 */
export function buildODataQueryFilterForSingle(status) {
    return `userPEStatus/peStatus eq ${status}`
}

/**
 * Create odata filter for the given status
 * 
 * @param {number[]} status 
 * @returns {string | undefined}
 */
export function buildODataQueryFilterForAll(status) {
    if (!status || !status.length) return undefined

    if (status.length === 1)
        return buildODataQueryFilterForSingle(status[0])

    return `(${status.map(s => buildODataQueryFilterForSingle(s)).join(' or ')})`
}

export function getStatusOptions(includeNa = false) {
    const status = [
        {
            displayText: 'Ok',
            value: PEStatus.OK
        },
        {
            displayText: 'Onduidelijk',
            value: PEStatus.UNCLEAR
        },
        {
            displayText: 'Bijna verlopen',
            value: PEStatus.ALMOST_EXPIRED
        },
        {
            displayText: 'Verlopen!',
            value: PEStatus.EXPIRED
        }]

    if (includeNa)
        status.unshift({
            displayText: '-',
            value: 0
        })

    return status
}

/**
 * 
 * @param {number} status 
 * @returns {string}
 */
export function getStatusName(status) {
    return getStatusOptions(true)
        .find(e => e.value === status)
        .displayText ?? ''
}

<template>
  <div class="py-5">
    <recess-layout-static>
      <enrollment-verification-component />
    </recess-layout-static>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import EnrollmentVerificationComponent from '../../organisms/Enrollments/EnrollmentVerificationComponent/EnrollmentVerificationComponent.vue'

export default {
    name: 'EnrollmentVerificationPage',
    components: {
        EnrollmentVerificationComponent
    },
    beforeDestroy() {
        this.resetState()
    },
    methods: {
        ...mapMutations('verificationEnrollmentModule', ['resetState'])
    }
}
</script>

<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isFetching"
      overlay="white"
      color="primary"
    />
    <p class="p-0 pl-md-4 col-12 col-md-8">
      Op deze pagina ziet u al de studieovereenkomsten van collega's. 
      De status geeft de actuele status weer. Aan de actieknop (rechts van de studieovereenkomst) ziet u of u nog een actie 
      dient uit te voeren. Met het filter kunt u eenvoudig specifieke studieovereenkomsten vinden.
    </p>
    
    <table-filters-component
      :show-reset-filters="showResetButton"
      toggle-button-text="Filter studieovereenkomsten"
      @reset-filters="resetFilters()"
    >
      <recess-select
        slot="right"
        :options="studyContractsPageSizes"
        :default-value="mDataQuery.take"
        @input="(value) => setPageSize(value)"
      />
      <template #filters>
        <recess-input
          v-model="userFilters.fullNameFilter.value"
          data-test="filter-by-name-input"
          label-text="Zoek op naam van de medewerker"
          class="mb-3"
          @keyup.enter.native="updateFilters()"
        />
        
        <recess-input
          v-model="userFilters.employeeNumberFilter.value"
          data-test="filter-by-employee-number-input"
          label-text="Zoek op personeelsnummer"
          class="mb-3"
          @keyup.enter.native="updateFilters()"
        />
        <recess-input
          v-model="userFilters.emailFilter.value"
          data-test="filter-by-email-input"
          label-text="Zoek op e-mail"
          class="mb-3"
          @keyup.enter.native="updateFilters()"
        />
        <recess-input
          v-model="userFilters.departmentFilter.value"
          data-test="filter-by-department-input"
          label-text="Zoek op organisatieonderdeel"
          @keyup.enter.native="updateFilters()"
        />
      </template>
      <template #footer>
        <recess-button
          title="Filter"
          variant="primary"
          class="col-12 col-sm-auto"
          data-test="filter-btn"
          @click="updateFilters()"
        />
      </template>
    </table-filters-component>

    <recess-divider />

    <transition name="fade">
      <div v-if="studyContracts && studyContracts.length">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
                :sortable="header.isSortable"
                :sort-direction="getSortDirection(header)"
                @click.native="setSorting(header)"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="studyContract in studyContracts"
              :key="studyContract.Id"
            >
              <recess-table-cell
                :data-label="headers[0].title"
              >
                {{ studyContract.enrollment.firstName }} {{ studyContract.enrollment.middleName }} {{ studyContract.enrollment.lastName }}
              </recess-table-cell>

              <recess-table-cell :data-label="headers[0].title">
                <router-link
                  :to="{ name: 'course', query: { course: studyContract.enrollment.eduMSCourseId, assortment: studyContract.enrollment.assortmentId }}" 
                >
                  <span v-html="studyContract.enrollment.courseName"></span>
                </router-link>
              </recess-table-cell>

              <recess-table-cell :data-label="headers[1].title">
                <span>{{ format(studyContract.creationDate) }}</span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers[2].title">
                <span>{{ studyContract.studyContractTemplate.name }}</span>
              </recess-table-cell>

              <recess-table-cell :data-label="headers[3].title">
                <span>{{ studyContract.stateDisplayValue }}</span>
              </recess-table-cell>

              <recess-table-cell>
                <recess-button
                  :url="{ name: 'studycontract', query: { studyContract: studyContract.studyContractId, ...$route.query }}"
                  title="Bekijk"
                  variant="tertiary"
                  class="col-12 col-md-auto"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>

        <template v-if="showPagination">
          <recess-divider />
          <pagination-component
            :current-page-number="currentPageNumber"
            :total-pages="totalPages"
            @goToPage="paginationClicked"
          />
        </template>
      </div>
    </transition>

    <recess-alert
      v-if="showNoStudyContractMessage"
      text="Er zijn op dit moment geen studieovereenkomsten."
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import TableFiltersComponent from './TableFiltersComponent.vue'
import studyContractClient from '../../../api/studyContractClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import DateUtils from '../../../utils/DateUtils'
import DataQueryBuilder from '../../../api/DataQuery/dataQueryBuilder'
import DataQueryFilter from '../../../api/DataQuery/dataQueryFilter'
import * as roles from '../../../constants/roles'

export default {
    components: {
        LoaderComponent,
        PaginationComponent,
        TableFiltersComponent
    },
    data() {
        return {
            headers: [
                { key: 'UserFullName', title: 'Medewerker' },
                { key: 'Enrollment.CourseName', title: 'Ontwikkelactiviteit', isSortable: true },
                { key: 'CreationDate', title: 'Creatiedatum', isSortable: true },
                {
                    key: 'StudyContractTemplate.Name',
                    title: 'Studieovereenkomst',
                    isSortable: true
                },
                { key: 'State', title: 'Status', isSortable: true },
                { key: '', title: '', isSortable: false }
            ],
            studyContracts: null,
            showNoStudyContractMessage: false,
            studyContractsTotalCount: 0,
            studyContractsPageIndex: 1,
            studyContractsPageSize: 10,
            studyContractsPageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            isFetching: false,
            mDataQuery: {
                sortField: 'CreationDate',
                sortDirection: 'Desc',
                skip: 0,
                take: 10,
                filters: [],
                userFilters: []
            },
            userFilters: {
                fullNameFilter: {
                    key: 'FullName',
                    value: '',
                    operator: 'Contains'
                },
                employeeNumberFilter: {
                    key: 'EmployeeNumber',
                    value: '',
                    operator: 'Contains'
                },
                emailFilter: {
                    key: 'EmailAddress',
                    value: '',
                    operator: 'Contains'
                },
                departmentFilter: {
                    key: 'UserDepartment',
                    value: '',
                    operator: 'Contains'
                }
            }
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapState('accountModule', ['account']),
        dataQuery() {
            if (this.user) {
                const filters = this.mDataQuery.filters.map(
                    x => new DataQueryFilter(x.key, x.operator, x.value)
                )
                const userFilters = this.mDataQuery.userFilters.map(
                    x => new DataQueryFilter(x.key, x.operator, x.value)
                )

                return new DataQueryBuilder()
                    .setSort(this.mDataQuery.sortField, this.mDataQuery.sortDirection)
                    .setPagination(this.mDataQuery.skip, this.mDataQuery.take)
                    .addFilters(filters)
                    .addFilters(userFilters)
                    .build()
            }
            return null
        },
        currentPageNumber() {
            if (this.studyContracts) {
                return this.studyContractsPageIndex
            }
            return 1
        },
        totalPages() {
            if (this.studyContracts) {
                return Math.ceil(this.studyContractsTotalCount / this.studyContractsPageSize)
            }
            return 1
        },
        showPagination() {
            return this.studyContracts && this.totalPages > 1
        },
        showResetButton() {
            return Object.values(this.userFilters).some(uf => {
                return uf.value.length
            })
        }
    },
    watch: {
        dataQuery: {
            handler(value) {
                // Its a function because we need the this context
                if (value) {
                    // The dataQuery object changed, lets do a new request
                    this.doRequest()
                    // also update the url
                    const deserializeDataQuery = this.$route.query.tableDataQueries
                        ? JSON.parse(Buffer.from(this.$route.query.tableDataQueries, 'base64').toString('utf8'))
                        : {}
                    deserializeDataQuery.myTeamStudyContracts = this.mDataQuery
                    const serializedDataQuery = Buffer.from(JSON.stringify(deserializeDataQuery), 'utf8').toString('base64')
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            'my-team-tab': this.$route?.query['my-team-tab'],
                            tableDataQueries: serializedDataQuery
                        }
                    })
                }
            },
            deep: true
        },
        userId() {
            // sometimes the user is not set on time in the store yet, so then the userId is null,
            // which gives us no results when fetching the enrollments. So we need to make sure the
            // addQueryFilters method is called again if the user id is available
            if (this.user.userId) {
                this.buildQueryByUserRoles(this.user.roles)
            }
        }
    },
    mounted() {
        // Create dataQueryFilters for fetching enrollments
        this.buildQueryByUserRoles(this.user.roles)
        // Deserialize the dataquery object and set it
        if (this.$route.query.tableDataQueries) {
            const deserializeDataQuery = JSON.parse(atob(this.$route.query.tableDataQueries))
            this.mDataQuery = deserializeDataQuery.myTeamStudyContracts || this.mDataQuery
        }
        this.mDataQuery.take = this.getTakeFromSessionStorage()
    },
    methods: {
        doRequest() {
            this.isFetching = true

            // Do the request
            studyContractClient
                .getStudyContracts(this.dataQuery)
                .then(response => {
                    this.showNoStudyContractMessage = false
                    this.studyContracts = response?.items
                    this.studyContractsTotalCount = response?.totalCount ?? 0
                    this.studyContractsPageSize = response?.pageSize ?? this.mDataQuery.size
                    this.studyContractsPageIndex = response?.pageIndex ?? 0

                    if(!this.studyContracts?.length) {
                        this.showNoStudyContractMessage = true
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .then(() => {
                    this.isFetching = false
                })
        },
        buildQueryByUserRoles(currentUserRoles) {
            if (!this.user.userId || !currentUserRoles || !currentUserRoles.length) {
                return
            }

            const filtersByRoles = []

            currentUserRoles.forEach(role => {
                switch (role) {
                    case roles.TEAMLEADER:
                        if (!this.user.companyTeamLeaders.length) break
                        this.user.companyTeamLeaders.forEach(company => {
                            filtersByRoles.push(`Enrollment.User.CompanyId == ${company.companyId}`)
                        })
                        break
                    case roles.COORDINATOR:
                        if (!this.account.accountId) break
                        filtersByRoles.push(
                            `Enrollment.User.AccountId == ${this.account.accountId}`
                        )
                        break
                    case roles.SECONDAPPROVER:
                        filtersByRoles.push(`Enrollment.AssignedSecondApproverId == ${this.user.userId}`)
                        filtersByRoles.push(`Enrollment.SecondApproverId == ${this.user.userId}`)
                        filtersByRoles.push(`Enrollment.RejectorId == ${this.user.userId}`)
                        break
                    default:
                        break
                }
            })
            // Remove duplicate filters and join them with || as separator
            const uniqueFilters = filtersByRoles.length
                ? [...new Set(filtersByRoles)].join('||')
                : null

            if (uniqueFilters) {
                this.mDataQuery.filters.push({
                    operator: 'Dynamic',
                    value: `(${uniqueFilters})&& Enrollment.User.UserId != ${this.user.userId} `
                })
            }
        },
        resetFilters() {
            Object.values(this.userFilters).forEach(uf => {
                uf.value = ''
            })
            this.updateFilters()
        },
        updateFilters() {
            // Return to the first page
            this.mDataQuery.skip = 0
            const userFilters = Object.values(this.userFilters).map(uf => {
                return { ...uf }
            })
            this.mDataQuery.userFilters = userFilters
        },
        paginationClicked(pageNumber) {
            this.mDataQuery.skip = this.mDataQuery.take * (pageNumber - 1)
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.mDataQuery.take) return

            // if new page size selected, set the skip to 0 and remove from the quertstring
            this.mDataQuery.skip = 0

            // set the new page size value in the data query and in the sessopm storage
            this.mDataQuery.take = value
            sessionStorage.setItem('MyTeamStudyContractsTableComponent/take', JSON.stringify(value))
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.mDataQuery.sortField === header.key) {
                this.mDataQuery.sortDirection =
                    this.mDataQuery.sortDirection === 'Asc' ? 'Desc' : 'Asc'
            } else {
                this.mDataQuery.sortDirection = 'Desc'
            }
            this.mDataQuery.sortField = header.key
            this.mDataQuery.skip = 0
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.mDataQuery.sortField !== header.key) {
                return null
            }

            return this.mDataQuery.sortDirection
                ? this.mDataQuery.sortDirection.toLowerCase()
                : null
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('MyTeamStudyContractsTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(
                            sessionStorage.getItem('MyTeamStudyContractsTableComponent/take')
                        ),
                        10
                    )
                } catch {
                    return this.studyContractsPageSize
                }
            }
            return this.studyContractsPageSize
        },
        format: e => DateUtils.formatDate(e)
    }
}
</script>
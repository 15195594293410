<template>
  <recess-card
    v-if="buttonVisible"
    variant="variant1"
    class="text-center"
  >
    <recess-button
      variant="tertiary"
      :title="buttonText"
      data-test="next-page-btn"
      class="u-min-width-200 col-sm-12 col-md-5"
      @click="goToNextPage"
    />
  </recess-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState('searchModule', ['count', 'items', 'pageSize']),
        itemsLength() {
            return !this.items ? 0 : this.items.length
        },
        itemsTotalCount() {
            return !this.count ? 0 : this.count
        },
        itemsPageSize() {
            return !this.pageSize ? 20 : this.pageSize
        },
        itemsLeft() {
            return this.itemsTotalCount - this.itemsLength
        },
        itemsNextPageSize() {
            return this.itemsLeft < this.itemsPageSize ? this.itemsLeft : this.itemsPageSize
        },
        buttonVisible() {
            return this.itemsLength < this.itemsTotalCount
        },
        buttonText() {
            return this.itemsLeft > 1
                ? `Laad ${this.itemsNextPageSize} meer van ${this.count}`
                : `Toon het volgende resultaat`
        }
    },
    methods: {
        ...mapActions('searchModule', ['goToNextPage'])
    }
}
</script>

import { getSocialLearningClient } from './apiClient'

const baseRoute = '/posts'

const searchPosts = async oDataQuery => {
    const client = await getSocialLearningClient()
    return client.post(`${baseRoute}/search`, oDataQuery).then(response => {
        return response?.data
    })
}

const uploadAttachments = async attachments => {
    const client = await getSocialLearningClient()
    return client.post(`attachments`, attachments).then(response => {
        return response?.data
    })
}

const createPost = async post => {
    const client = await getSocialLearningClient()
    return client.post(`${baseRoute}`, post).then(response => {
        return response?.data
    })
}

const deletePost = async id => {
    const client = await getSocialLearningClient()
    return client.delete(`posts/${id}`).then(response => {
        return response?.data
    })
}

const getPostById = async id => {
    const client = await getSocialLearningClient()
    return client.get(`${baseRoute}/${id}`).then(response => {
        return response?.data
    })
}

const createReply = async reply => {
    const client = await getSocialLearningClient()
    return client.post(`${baseRoute}/reply`, reply).then(response => {
        return response?.data
    })
}

const updatePost = async (jsonPatchDocument, postId) => {
    const client = await getSocialLearningClient()
    return client.patch(`${baseRoute}/${postId}`, jsonPatchDocument).then(response => {
        return response?.data
    })
}

const deleteReply = async (postId, replyId) => {
    const client = await getSocialLearningClient()
    return client.delete(`${baseRoute}/${postId}/reply/${replyId}`).then(response => response?.data)
}

const updateReply = async (jsonPatchDocument, postId, replyId) => {
    const client = await getSocialLearningClient()
    return client.patch(`${baseRoute}/${postId}/reply/${replyId}`, jsonPatchDocument).then(response => {
        return response?.data
    })
}

const postVote = async (postId, replyId, vote) => {
    const client = await getSocialLearningClient()
    return client.post(`${baseRoute}/${postId}/reply/${replyId}/vote`, vote).then(response => {
        return response?.data
    })
}

export default {
    searchPosts,
    uploadAttachments,
    createPost,
    getPostById,
    createReply,
    updatePost,
    deleteReply,
    deletePost,
    updateReply,
    postVote
}

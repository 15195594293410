<template>
  <div :class="lightboxClass">
    <transition name="lightbox">
      <div
        v-if="mIsVisible"
        class="c-lightbox__mask"
        @mousedown="closeLightbox"
      >
        <div class="c-lightbox__wrapper">
          <div class="c-lightbox__inner-wrapper d-flex align-items-center justify-content-center">
            <loader-component
              v-if="isLoading"
              color="white"
            />
            <div
              :class="{ 'is-visible': !isLoading }"
              class="c-lightbox__container"
              @mousedown.stop
            >
              <div class="c-lightbox__top">
                <slot name="top">
                  <div
                    v-if="showCloseButton"
                    class="c-lightbox__button"
                  >
                    <a
                      :href="currentPagePath"
                      class="c-lightbox__close"
                      @click.prevent="closeLightbox"
                    >{{ closeText }}</a>
                  </div>
                </slot>
              </div>
              <div
                v-if="childComponents"
                class="c-lightbox__body"
              >
                <slot name="body">
                  <slot>
                    <component
                      :is="component.webPartType"
                      v-for="component in childComponents"
                      :key="component.instanceGUID"
                      :components="component.components"
                      v-bind="mergeComponentData(component)"
                      @stopLoading="stopLoading"
                      @startLoading="startLoading"
                    />
                  </slot>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import mixinRemoveFromQuery from '../../../mixins/remove-from-querystring-mixin'
import mergeComponentDataMixin from '../../../mixins/merge-component-data-mixin'

import { getHistory } from '../../../modules/history-helper'

import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'

const queryName = 'open'

export default {
    components: { LoaderComponent },
    mixins: [mixinRemoveFromQuery, mergeComponentDataMixin],
    props: {
        // The identifier when opening a lightbox by url query
        toggleName: {
            type: String,
            default: null
        },
        // The variant
        // TODO: Add validation rule that you can only select one of the 3 options
        variant: {
            type: String,
            default: ''
        },
        // TODO: Clean up closeText feature, we don't use it.
        closeText: {
            type: String,
            default: ''
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        stopEvent: {
            type: String,
            default: null
        },
        startEvent: {
            type: String,
            default: null
        },
        // Relay42 event triggered when lightbox is opened
        relay42: {
            type: Object,
            default: () => {}
        },
        // Able to handle the visibility of the lightbox
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            previousUrl: null,
            isLoading: false,
            mIsVisible: this.isVisible
        }
    },
    computed: {
        currentPagePath() {
            if (this.$router && this.$router.currentRoute) {
                return this.$router.currentRoute.path
            }
            return null
        },
        lightboxClass() {
            switch (this.variant) {
                case 'lightbox':
                    return 'c-lightbox--full'
                case 'modal-wide':
                    return 'c-lightbox--wide'
                // Modal is the default style, all the others should have their own case, e.g. Lightbox variant
                default:
                    return 'c-lightbox'
            }
        }
    },
    watch: {
        $route() {
            this.updateVisibleState()
        },
        mIsVisible(visible) {
            this.$emit(visible ? 'open' : 'close')

            if (visible && this.stopEvent) {
                this.startLoading()

                if (this.stopEvent) {
                    this.$bus.on(this.stopEvent, this.stopLoading)
                }
                if (this.startEvent) {
                    this.$bus.on(this.startEvent, this.startLoading)
                }
            }
        },
        isVisible(visible) {
            // If no toggleName was set, update the visible state
            if (!this.toggleName) {
                this.mIsVisible = visible
            }
        }
    },
    created() {
        this.updateVisibleState()
    },
    beforeDestroy() {
        // Trigger hide events when the lightbox is destroyed
        if (this.mIsVisible) {
            this.hide()
        }
    },
    methods: {
        closeLightbox() {
            if (!this.mIsVisible) {
                return
            }

            // If a toggleName was set, do some router logic on close
            if (this.toggleName) {
                const findIndex = getHistory().findIndex(x => {
                    return !x.query.open
                })
                if (findIndex > -1) {
                    const goBackIndex = (findIndex + 1) * -1
                    this.$router.go(goBackIndex)
                } else {
                    this.$router.replace({
                        path: this.$route.path,
                        query: this.getLeftoverQueryParams([queryName])
                    })
                }
            } else {
                // Emit the close event to the parent component, so that component can change the visible state
                this.$emit('close')
            }
        },
        show() {
            if (!this.mIsVisible) {
                this.$bus.emit('disable-app-scroll', 'noScroll')
                this.mIsVisible = true
                // Trigger the lightboxOpened event with the relayData object when the lightbox is opened
                const relayData = this.relay42
                if (relayData) {
                    this.$bus.emit('lightboxOpened', { relayData })
                }
            }
        },
        hide() {
            if (this.mIsVisible) {
                // Set visibility to false
                this.mIsVisible = false
                this.$bus.emit('enable-app-scroll', 'noScroll')
            }
        },
        updateVisibleState() {
            // If a toggleName was set, change the visible state based on the url
            if (this.toggleName) {
                const lightboxQuery = this.$router.currentRoute.query[queryName]
                const shouldShow =
                    !!lightboxQuery && lightboxQuery.toLowerCase() === this.toggleName.toLowerCase()

                if (shouldShow) {
                    this.show()
                } else {
                    this.hide()
                }
            }
        },
        startLoading() {
            this.isLoading = true
        },
        stopLoading() {
            this.isLoading = false
        }
    }
}
</script>

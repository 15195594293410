<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <refreshing-component 
        v-if="isRefreshing"
        @refresh="refresh()"
      />
      <loader-component
        v-else-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <div
      ref="report"
      data-test="report"
      :class="{'c-powerbi-report' : isReady}"
    ></div>
    <recess-alert
      v-if="isError"
      type="error"
      class="mb-4"
      text="Er is een fout opgetreden. Probeer het later opnieuw."
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { jsMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import RefreshingComponent from '../../molecules/PowerBIRefreshing/PowerBIRefreshing.vue'

export default {
    components: { 
        LoaderComponent,
        RefreshingComponent
    },
    mixins: [jsMediaQueriesMixin],
    props: {
        reportType: {
            type: Number,
            default: -1
        }
    },
    computed: {
        ...mapGetters('powerBIModule', ['isLoading', 'isRefreshing', 'isError', 'isReady'])
    },
    watch: {
        reportType() { this.refresh() }
    },
    beforeDestroy() {
        this.reset()
    },
    mounted() {
        if(this.reportType) this.refresh()
    },
    methods: {
        ...mapActions('powerBIModule', ['fetchAndRenderReport', 'reset']),
        refresh() {
            this.fetchAndRenderReport({
                reportType: this.reportType,
                elemRef: this.$refs.report
            })
        }
    }
}
</script>

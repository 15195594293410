<template>
  <div class="d-none d-lg-flex c-header__large-wrapper">
    <recess-layout-static :size="1700">
      <div class="c-header__large w-100">
        <div class="c-header__content">
          <div class="c-header__large-left">
            <account-logo-component :logo-url="account.logoUrl" />
          </div>

          <div class="c-header__large-right">
            <div class="c-header__user-menu">
              <large-settings-navigation />
            </div>
          </div>
        </div>
      </div>
    </recess-layout-static>

    <LargeMainNavigation v-show="showNavBar" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LargeMainNavigation from '../NavigationComponent/Large/LargeMainNavigation.vue'
import LargeSettingsNavigation from '../NavigationComponent/Large/LargeSettingsNavigation.vue'
import AccountLogoComponent from '../../atoms/AccountLogoComponent/AccountLogoComponent.vue'

export default {
    components: {
        LargeMainNavigation,
        LargeSettingsNavigation,
        AccountLogoComponent
    },
    data() {
        return {
            showNavBar: false
        }
    },
    computed: {
        ...mapState('userModule', ['user', 'isUserFetched']),
        ...mapState('accountModule', ['account', 'isFetchingAccount'])
    },
    watch: {
        isUserFetched() {
            this.updateNavBarVisibility()
        },

        isFetchingAccount() {
            this.updateNavBarVisibility()
        }
    },
    mounted() {
        this.updateNavBarVisibility()
    },
    methods: {
        updateNavBarVisibility() {
            if (this.isUserFetched && !this.isFetchingAccount) {
                this.showNavBar = true
            }
        }
    }
}
</script>
<template>
  <div>
    <recess-layout-static :size="1700">
      <ArrowBackSVG />
      <recess-button
        class="u-textcolor-primary"
        title="Ga terug naar mijn budget(ten)"
        variant="variant4"
        :url="{ name: 'my-budgets' }"
      />
 
      <recess-divider />
      <budget-events-table />
      <recess-divider />
    </recess-layout-static>
  </div>
</template>

<script>
import BudgetEventsTable from '../../organisms/Tables/BudgetEventsTable.vue'
import ArrowBackSVG from '../../organisms/IconComponents/ArrowBackSVG.vue'

export default {
    name: 'BudgetDetails',
    components: {
        BudgetEventsTable,
        ArrowBackSVG
    }
}
</script>


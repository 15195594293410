<template>
  <recess-layout-static>
    <edit-user-form />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import EditUserForm from '../../organisms/ProfilePage/EditUserForm.vue'

export default {
    components: { EditUserForm },
    data() {
        return {
            showLightbox: false
        }
    }
}
</script>
<template>
  <lightbox-component
    :is-visible="isVisible"
    :show-close-button="!isLoading"
    @close="close"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <p class="u-textalignment-centered">Weet je zeker dat je dit item wilt verwijderen?</p>

    <div class="u-textalignment-centered">
      <recess-button
        variant="primary"
        title="OK"
        class="mr-3"
        data-test="confirm-delete-wishlist-item"
        @click="confirm"
      />
      <recess-button
        variant="primary"
        title="Annuleer"
        @click="close"
      />
    </div>
    <span
      v-if="showErrorMessage"
      class="o-error-text mt-2"
      data-test="error-txt"
    >Er is iets misgegaan. Probeer het later nog een keer of neem contact op met een administrator.</span>
  </lightbox-component>
</template>

<script>
import { mapMutations } from 'vuex'
import wishlistClient from '../../../api/wishlistClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'

export default {
    components: {
        LoaderComponent,
        LightboxComponent
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        courseId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isLoading: false,
            showErrorMessage: false
        }
    },
    methods: {
        ...mapMutations('userModule', ['toggleUserCourseWishListItem']),
        close() {
            this.$emit('close')
            this.showErrorMessage = false
            
        },
        async confirm() {
            try {
                if (!this.courseId) return
                this.isLoading = true

                const request = {
                    courseId: this.courseId,
                    toggle: false
                }

                const response = await wishlistClient.toggle(request)
                   
                if (response) {
                    this.close()
                    this.$emit('wishlistItemRemoved')
                } else {
                    this.showErrorMessage = true
                }
                        
                const newWishListItem = {
                    courseId: this.courseId
                }
                this.toggleUserCourseWishListItem(newWishListItem)
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>
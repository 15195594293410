<template>
  <div>
    <recess-layout-static class="c-post-details">
      <transition name="fade">
        <loader-component
          v-if="isLoading || isFetchingPost"
          overlay="white"
          color="primary"
          is-fixed
        />
      </transition>

      <recess-divider />

      <recess-button
        title="Terug"
        icon="back"
        variant="variant4"
        :url="{ name: 'search-posts'}"
      />

      <recess-divider />

      <recess-alert
        v-if="showNoPostFound"
        data-test="no-post-found-alert"
        text="Dit bericht kan niet gevonden worden. Probeer het later opnieuw."
      />

      <template v-if="post">
        
        <!-- Post title tags and description -->
        <h1 data-test="post-title">{{ post.title }}</h1>
        <recess-divider variant="xsmall"/>
        <div class="d-flex flex-md-row flex-column justify-content-md-between row align-items-md-center">
          <div class="col-md-6 col-12" data-test="post-creation-date-author">{{ formatDate(post.creationDate) }}
            <strong >{{ ` ${post.createdBy.name}`}}</strong>
            <div v-if="post.lastModifiedDate" data-test="post-modified-date">{{ ` (gewijzigd op ${formatDate(post.lastModifiedDate)} om ${formatTime(post.lastModifiedDate)})`}}</div>
          </div>
          <div v-if="canEditOrRemovePost(post)" class="d-flex justify-content-between align-items-center col-md-6 col-12 py-3 py-md-0">
            <div class="d-flex align-items-center">
              <recess-icon
                class="qa-edit-icon"
                icon="edit"
                data-test="edit-post-icon"
                @click="$router.replace({ name: 'edit-post', params: { id: post.id }})"
              />
              <recess-divider
                variant="medium"
                vertical
                show-line
              />
              <component :is="isPostRemovable ? 'div' : 'recess-tooltip'">
                <recess-icon
                  :disabled="!isPostRemovable"
                  icon="bin"
                  data-test="delete-btn"
                  @click.native="onDeletePost(post.id)"
                />
                <template #content>
                  <span>
                    Alleen berichten zonder reactie(s) kunnen verwijderd worden.
                  </span>
                </template>
              </component>
            </div>
            <div data-test="post-creation-total-reations"> <strong > {{ (post.replies ? post.replies.length : 0) }}</strong> reacties </div>
          </div>
        </div>

        <recess-tag
          v-for="(tag,index) in tags"
          :key="index"
          :text="tag.displayValue"
          :show-close-icon="false"
          data-test="post-tag"
        />

        <recess-divider />

        <recess-card bordered>  
          <!-- eslint-disable-next-line vue/no-v-html, Content is sanitized  -->
          <p class="s-post-content" data-test="post-content" v-html="post.content"></p>
          
          <template v-if="(post.attachments && post.attachments.length) || (post.videoThumbnails && post.videoThumbnails.length)" >
            <attachment-thumbnail-preview-carousel
              data-test="post-attachments-carousel"
              :attachments="post.attachments"
              :video-thumbnails="post.videoThumbnails"
            />
          </template>
        </recess-card>

        <recess-divider />
  
        <!-- Post replies -->
        <template v-if="post.replies && post.replies.length">
          <h4 class="mb-0">{{ `Reacties (${post.replies.length})` }}</h4>
    
          <div v-for="(reply,index) in sortedReplies"  :key="'reply' + index" :class="['ml-0', { 'ml-md-3' : replyToEdit !== reply.id }]">
            <recess-divider variant="xsmall" />
            <div v-if="replyToEdit !== reply.id" class="d-flex">
              <div class="d-flex align-items-center flex-column">
                <div
                  v-if="isTopVotedReply(reply.id)" 
                  class="c-post-details__reply-thums-up-medal" 
                  data-test="thums-up-medal">
                </div>
                <recess-icon
                  icon='up-vote'
                  data-test="up-vote-icon"
                  :color="isReplyUpVoted(reply.votes) ? 'secondary' : 'black'"
                  :height="30"
                  :width="25"
                  @click="onVoteClick(reply)"
                />
                <span class="font-weight-bold" data-test="reply-vote-count">{{ reply.totalVoteCount }}</span>
                <recess-icon
                  icon="down-vote"
                  data-test="down-vote-icon"
                  :color="isReplyDownVoted(reply.votes) ? 'secondary' : 'black'"
                  :height="30"
                  :width="25"
                  @click="onVoteClick(reply, false)"
                />
              </div>
                
              <recess-card bordered class="c-post-details__reply">
                <!-- eslint-disable-next-line vue/no-v-html, Content is sanitized  -->
                <p data-test="post-reply-content" class="s-post-content" v-html="reply.content" ></p>
                <template v-if="(reply.attachments && reply.attachments.length) || (reply.videoThumbnails && reply.videoThumbnails.length)" >
         
                  <attachment-thumbnail-preview-carousel
                    data-test="reply-attachments-carousel"
                    :attachments="reply.attachments"
                    :video-thumbnails="reply.videoThumbnails"
                    is-small
                  />
                </template>
                <recess-divider show-line />
                <div class="d-flex justify-content-between">
                  <p data-test="post-reply-date-time-author" class="mb-0">{{ reply.lastModifiedDate ? `Bewerkt op ${formatDateTime(reply.lastModifiedDate)}` : formatDateTime(reply.creationDate) }} 
                    <strong> {{ ` door ${reply.createdBy.name}` }}</strong>
                  </p>    
                  <div v-if="reply.createdBy.principalId === idServerUserId" class="d-flex">
                    <recess-icon
                      class="qa-edit-icon"
                      data-test="edit-reply-icon"
                      icon="edit"
                      @click="onEditReply(reply.id)"
                    />
                    <recess-icon
                      class="qa-bin-icon"
                      icon="bin"
                      @click="onDeleteReply(reply.id)"
                    />
                  </div>  
                </div>
              </recess-card>   
            </div>
            
            <!-- Edit reply form -->
            <reply-form
              v-if="replyToEdit === reply.id" 
              ref="editReplyForm" 
              :reply = reply 
              @reload = "fetchPost(postId)" 
              @resetEditing = "resetEditReply()"
              @is-uploading="val => isLoading = val" />
          </div>        
        </template>

        <recess-divider v-if="!replyToEdit" variant="large" show-line />

        <!-- Post new reply form -->
        <reply-form
          v-if="!replyToEdit" 
          @reload = "fetchPost(postId)"
          @is-uploading="val => isLoading = val"/>
      </template>

      <recess-divider />

      <recess-alert v-if="showErrorMessage" class="mb-5" type="error" text="Er is een fout opgetreden. Probeer het later opnieuw." />
    </recess-layout-static>

    <remove-item-confirmation-component
      :is-visible="showDeletePostConfirmationMessage"
      confirmation-text="Weet je zeker dat je dit bericht wilt verwijderen?"
      data-test="remove-post-confirmation-modal"
      @close="showDeletePostConfirmationMessage = false"
      @remove="deletePost(postToDelete)"
    />
      
    <remove-item-confirmation-component
      :is-visible="showDeleteReplyConfirmationMessage"
      confirmation-text="Weet u zeker dat u dit antwoord wilt verwijderen?"
      @close="showDeleteReplyConfirmationMessage = false"
      @remove="deleteReply(replyToDelete)"      
    />

    <message-modal 
      message="U heeft uw bijdrage succesvol bijgewerkt, over enkele momenten zal uw post verwijderd zijn." 
      :is-visible="showDeletedPostSuccessMessage"
    >
      <template #buttons>
        <recess-button
          variant="primary"
          title="Ok"
          :url="{ name: 'search-posts'}"
        />
      </template>
    </message-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import DateUtils from '../../../utils/DateUtils'
import socialLearningClient from '../../../api/socialLearningClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import AttachmentThumbnailPreviewCarousel from '../../organisms/Carousels/AttachmentThumbnailPreviewCarousel.vue'
import { replyHints } from '../../../constants/replyHints'
import RemoveItemConfirmationComponent from '../../atoms/RemoveItemConfirmationComponent/RemoveItemConfirmationComponent.vue'
import ReplyForm from '../../organisms/SocialLearning/Reply/CreateEditForm.vue'
import MessageModal from '../../atoms/MessageModal/MessageModal.vue'

export default {
    name: 'PostDetails',
    components: {
        LoaderComponent,
        AttachmentThumbnailPreviewCarousel,
        RemoveItemConfirmationComponent,
        ReplyForm,
        MessageModal
    },
    validations() {
        return {
            myReply: {
                content: { required }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.$refs?.editReplyForm != null && this.$refs?.editReplyForm[0]?.isDirty) {
            const answer = window.confirm(
                'Weet u zeker dat u deze pagina wilt verlaten? U hebt mogelijk niet-opgeslagen wijzigingen.'
            )

            return answer ? next() : next(false)
        }
        return next()
    },
    data() {
        return {
            isLoading: false,
            showErrorMessage: false,
            showNoPostFound: false,
            myReply: {
                postId: "",
                content: "",
                attachments: []
            },
            documentsToUpload: [],
            replyHints,
            replyHintsTitle: "Hints",
            editorToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }],
                ['link'],
                ['image']
            ],
            showDeleteReplyConfirmationMessage: false,
            replyToDelete: null,
            deleteConfirmationMsg : "",
            showDeletePostConfirmationMessage: false,
            showDeletedPostSuccessMessage: false,
            postToDelete: null,
            isEditingReply: false,
            replyToEdit: null,
            myVote: {
                isUpVote: false,
                isRemove: false
            }
        }
    },
    computed: {
        ...mapGetters('userModule', ['idServerUserId']),
        ...mapGetters('socialLearningPostModule', ['post', 'isFetchingPost']),
        postId() {
            return this.$route.params?.id
        },
        isPostRemovable() {
            return !this.post?.replies?.some( r => r.createdBy.principalId !== this.idServerUserId)
        },
        tags() {
            return this.post?.tags?.filter(t => t.displayValue)
        },
        sortedReplies(){
            if(!this.post.topVotedReplyId) return this.post.replies

            const sortedReplies = Object.assign([], this.post.replies)
            const topVotedReply = this.post.replies?.filter(r => r.id === this.post.topVotedReplyId)

            const replyIndex = sortedReplies?.findIndex(r => r.id === this.post.topVotedReplyId)
            sortedReplies.splice(replyIndex, 1)
            if(topVotedReply) sortedReplies.unshift(...topVotedReply)

            return sortedReplies
        }
    },  
    beforeDestroy() {
        this.resetComponentData()
        this.resetState()
    },
    created() {
        this.initialize()
    },
    methods: {
        ...mapActions('socialLearningPostModule', ['fetchPost']),
        ...mapMutations('socialLearningPostModule', ['resetState']),
        async initialize() {
            try {
                if (!this.postId) return
                await this.fetchPost(this.postId)
                if (!this.post) this.showNoPostFound = true
            } catch (error) {
                this.showNoPostFound = true
                throw new Error(error)
            } 
        },
        setDocumentsToUpload(files) {
            this.documentsToUpload = [...files]
        },
        async getUploadedAttachments() {
            try {
                const attachments = this.documentsToUpload.map(({base64FileString, structure}) => {
                    return {
                        fileName: structure?.name,
                        fileData: base64FileString
                    }
                })

                const uploadedAttachments = await socialLearningClient.uploadAttachments(attachments)

                return uploadedAttachments?.map(({fileName, url}) => {
                    return {
                        description: fileName,
                        url
                    }
                } )
            } catch (error) {
                throw new Error("Failed to upload attachments", error)
            } 
        },
        async createReply() {
            this.myReply.postId = this.postId

            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            try {
                this.isLoading = true

                if(this.documentsToUpload?.length) {
                    this.myReply.attachments = await this.getUploadedAttachments()
                }
           
                await socialLearningClient.createReply(this.myReply)
                await this.fetchPost(this.postId)
                this.resetComponentData()
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        resetComponentData() {
            this.$v?.$reset()
            this.showErrorMessage = false
            this.documentsToUpload = []
            this.myReply.content = ""
            this.myReply.attachments = []
        },
        async deletePost(id) {
            this.isLoading = true
            this.showDeletePostConfirmationMessage = false
            try {
                if(!this.canEditOrRemovePost(this.post) || !this.isPostRemovable) return
                await socialLearningClient.deletePost(id)
                this.showDeletedPostSuccessMessage = true
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.postToDelete = null
                this.isLoading = false  
            }
        },
        onDeletePost(postId) {
            if(this.isUserEditingReply())
                return
            this.postToDelete = postId
            this.showDeletePostConfirmationMessage = true
        },
        formatDate(date) {
            return DateUtils.formatDate(date)
        },
        formatDateTime(date)  {
            return DateUtils.formatDateTime(date)
        },
        canEditOrRemovePost(post) {
            return this.idServerUserId === post?.createdBy.principalId
        },
        formatTime(date) {
            return DateUtils.formatTime(date)
        },        
        onDeleteReply(replyId) {
            if(this.isUserEditingReply())
                return
            this.showDeleteReplyConfirmationMessage = true
            this.replyToDelete = replyId
        },
        async deleteReply() {
            try {
                this.showDeleteReplyConfirmationMessage = false 
                this.isLoading = true

                await socialLearningClient.deleteReply(this.postId, this.replyToDelete)
                await this.fetchPost(this.postId)
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isLoading = false
                this.replyToDelete = null
            }
        },
        onEditReply(replyId) {
            if(this.isUserEditingReply())
                return
            this.isEditingReply = true
            this.replyToEdit = replyId
        },
        resetEditReply() {
            this.isEditingReply = false
            this.replyToEdit = null
        },
        isUserEditingReply(){
            return this.isEditingReply && !this.$refs?.editReplyForm[0].confirmAndCancelEdit()
        },
        createNewVote(voteValue){
            const newVote = {
                principalId : this.idServerUserId,
                value: voteValue
            }
            return newVote
        },
        async onVoteClick(reply, isUpVote = true){
            const upVoteValue = 1
            const downVoteValue = -1
            const voteValue = isUpVote ? upVoteValue : downVoteValue
            this.myVote.isUpVote = isUpVote
            try{
                const voteIndex = reply.votes?.findIndex(v => v.principalId === this.idServerUserId)
                
                if(voteIndex !== -1){
                    // if user already voted and trying to remove the existing vote
                    if(reply.votes[voteIndex].value === (isUpVote ? upVoteValue : downVoteValue)) {
                        reply.totalVoteCount += isUpVote ? -1 : 1
                        reply.votes = reply.votes.filter(v => v.principalId !== this.idServerUserId)
                        this.myVote.isRemove = true
                    } 
                    // if user already voted and trying to change the vote
                    else {
                        reply.totalVoteCount += isUpVote ? 2 : -2
                        reply.votes[voteIndex].value = voteValue
                        this.myVote.isRemove = false
                    }
                }
                // user trying to vote for the first time
                else {
                    reply.totalVoteCount += voteValue
                    this.myVote.isRemove = false
                    if(!reply.votes) reply.votes = []
                    reply.votes?.push(this.createNewVote(voteValue))
                }
                await socialLearningClient.postVote(this.postId, reply.id, this.myVote)
            } catch (error) {
                this.showErrorMessage = true
                throw new Error("Failed to update reply", error)             
            } 
        },
        isReplyUpVoted(votes){
            return votes?.find(v => v.principalId === this.idServerUserId && v.value === 1)
        },
        isReplyDownVoted(votes){
            return votes?.find(v => v.principalId === this.idServerUserId && v.value === -1)
        },
        isTopVotedReply(replyId){
            return this.post.topVotedReplyId === replyId
        }      
    }
}
</script>
/**
 * Commit backend settings to the store
 * 
 * @param {import('vuex').Store} store
 * @param {import('./types').FrontAppConfig} config 
 */
export default function setStoreSettings(store, config) {
    store.commit('oplzConfigurationModule/setOplzApiUrl', config.oplzApiUrl)
    store.commit('oplzConfigurationModule/setOplzAdminUrl', config.oplzAdminUrl)
    store.commit('oplzConfigurationModule/setOplzSocialLearningApiUrl', config.oplzSocialLearningApiUrl)
    store.commit('oplzConfigurationModule/setOplzAiApiUrl', config.oplzAiApiUrl)
    store.commit('oplzConfigurationModule/setOplzGeoLocationApiUrl', config.oplzGeoLocationApiUrl)
    store.commit('configurationModule/setCurrentEnvironment', config.environment)

    const oidcConfig = config.oidcConfig.reduce((acc, item) => {
        return {
            ...acc,
            [item.key]: item.value
        }
    }, {})
    store.commit('configurationModule/setOidcConfig', oidcConfig)
}
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <div class="p-0 pl-md-4 col-12 col-md-8">
      <p>
        {{ intro }}
      </p>
      <div v-if="$isAllowed('managePermanentEducation')">
        <recess-button
          :url="createPEReminderUrl"
          title="Voeg een nieuw item toe"
          variant="primary"
          class="u-min-width-140"
          data-test="create-btn"
        />
        <recess-divider />
      </div>
    </div>


    <table-filters-component
      :show-reset-filters="showResetButton"
      toggle-button-text="Filter leerresultaten"
      @reset-filters="resetFilters()"
    >
      <recess-select
        slot="right"
        :options="pageSizes"
        :default-value="dataQuery.take"
        @input="(value) => setPageSize(value)"
      />
      <template #filters>
        <recess-input
          v-model="userFilters.fullNameFilter.value"
          label-text="Zoek op naam van de medewerker"
          data-test="filter-by-name-input"
          class="mb-3"
          @keyup.enter.native="updateFilters()"
        />
        <recess-input
          v-model="userFilters.employeeNumberFilter.value"
          label-text="Zoek op personeelsnummer"
          data-test="filter-by-employee-number-input"
          class="mb-3"
          @keyup.enter.native="updateFilters()"
        />
        <recess-input
          v-model="userFilters.emailFilter.value"
          label-text="Zoek op e-mail"
          data-test="filter-by-email-input"
          class="mb-3"
          @keyup.enter.native="updateFilters()"
        />
        <recess-input
          v-model="userFilters.departmentFilter.value"
          data-test="filter-by-department-input"
          label-text="Zoek op organisatieonderdeel"
          @keyup.enter.native="updateFilters()"
        />
      </template>
      <template #footer>
        <recess-button
          title="Filter"
          variant="primary"
          class="col-12 col-sm-auto"
          @click="updateFilters()"
        />
      </template>
    </table-filters-component>

    <recess-divider />

    <transition name="fade">
      <div v-if="permanentEducationReminders && permanentEducationReminders.length">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="header in headers"
                :key="header.key"
                :class="header.class"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="permanentEducationReminder in permanentEducationReminders" 
              :key="permanentEducationReminder.permanentEducationId"
              :data-test="`pe-${permanentEducationReminder.permanentEducationId}`"
            >
              <recess-table-cell
                :data-label="headers[0].title"
                class="d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <recess-tooltip v-if="$isAllowed('displayPEReminderCertificate')">
                    <recess-icon
                      data-test="certification-icon"
                      :icon="permanentEducationReminder.hasCertificate ? 'document-uploaded' : 'document-upload'"
                      :disabled="!permanentEducationReminder.hasCertificate"
                      :color="permanentEducationReminder.hasCertificate ? 'green' : 'black'"
                      :height="25"
                      :width="25"
                      class="mr-2"
                    />
                    <template #content>
                      <span class="d-block u-max-width-500">
                        {{ permanentEducationReminder.hasCertificate ? permanentEducationTooltipText.certificateUploadedToPermanentEducationItemTooltip : permanentEducationTooltipText.certificateNotUploadedToPermanentEducationItemTooltip }}
                      </span>
                    </template>
                  </recess-tooltip>
                  <span>
                    {{ permanentEducationReminder.userEmailAddress }}
                  </span>
                </div>
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[1].title"
                class="d-flex align-items-center justify-content-between"
              >
                <router-link
                  v-if="showEnrollmentIcon(permanentEducationReminder.latestEnrollmentId)"
                  data-test="pe-course-details-redirect"
                  :to="{ name: 'course', query: { course: permanentEducationReminder.latestEnrollmentCourseId, assortment: permanentEducationReminder.latestEnrollmentAssortmentId }}">
                  <span v-html="permanentEducationReminder.courseName"></span>
                </router-link>
                <router-link 
                  v-else
                  data-test="pe-course-search-redirect"
                  :to="{ name: 'search', query: { q: permanentEducationReminder.courseName } }"
                >
                  <span v-html="permanentEducationReminder.courseName"></span>
                </router-link>
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[2].title"
                class="d-flex align-items-center justify-content-between"
              >
                {{ format(permanentEducationReminder.startDate) }}
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[3].title"
                class="d-flex align-items-center justify-content-between"
              >
                {{ format(permanentEducationReminder.expiryDate) }}
              </recess-table-cell>
              <recess-table-cell
                :data-label="headers[4].title"
                class="d-flex align-items-center justify-content-between u-max-width-lg-200"
              >
                {{ getCreatedByUserName(permanentEducationReminder) }}
              </recess-table-cell>
              <recess-table-cell
                class="d-flex align-items-center justify-content-end u-max-width-lg-200"
                v-if="showTableActions"
              >
                <template v-if="showEnrollmentIcon(permanentEducationReminder.latestEnrollmentId)">
                  <recess-tooltip data-test="pe-enrollment-details-tooltip" >
                    <recess-icon
                      data-test="enrollment-icon"
                      :icon="'document-details'"
                      :height="25"
                      :width="25"
                      @click.native="$router.push({ name: 'enrollment-details', query: { enrollment: permanentEducationReminder.latestEnrollmentId }})"
                    />
                    <template #content>
                      <span class="d-block u-max-width-500"> {{ permanentEducationTooltipText.enrollmentLinkedToPermanentEducationTooltip }}</span>
                    </template>
                  </recess-tooltip>
                  <recess-divider
                    v-if="$isAllowed('managePermanentEducation')"
                    variant="medium"
                    vertical
                    show-line
                  />
                </template>
                <template v-if="$isAllowed('managePermanentEducation')">
                  <recess-icon
                    :disabled="!$isAllowed('displayPermanentEducationDetails', permanentEducationReminder)"
                    icon="edit"
                    data-test="edit-btn"
                    @click="goToEditPEReminderPage(permanentEducationReminder)"
                  />
                  <recess-divider
                    variant="medium"
                    vertical
                    show-line
                  />
                  <recess-icon
                    :disabled="!$isAllowed('removePermanentEducation', { userId: permanentEducationReminder.userId, createdByUserId: permanentEducationReminder.createdByUserId, isImported: permanentEducationReminder.isImported })"
                    icon="bin"
                    data-test="delete-btn"
                    @click="setRemoveItemModalVisibility(permanentEducationReminder.permanentEducationId)"
                  />
                </template>
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
        <recess-divider />
        <pagination-component
          :current-page-number="currentPageNumber"
          :total-pages="totalPages"
          @goToPage="paginationClicked"
        />
      </div>
    </transition>

    <recess-alert
      v-if="showNoPERemindersMessage"
      text="Er zijn op dit moment geen herinneringen ingesteld."
    />
    <recess-alert
      v-if="showErrorMessage"
      type="error"
      text="Oeps, er is iets mis gegaan. Probeer het later opnieuw."
    />
    <remove-item-confirmation-component
      :is-visible="removePEReminderConfirmationVisibility"
      confirmation-text="Weet u zeker dat u deze leerresultaten wilt verwijderen?"
      @close="removePEReminderConfirmationVisibility = false"
      @remove="deletePermanentEducationReminder(peReminderToDelete)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import TableFiltersComponent from './TableFiltersComponent.vue'
import { searchPermanentEducation, deletePermanentEducation } from '../../../api/permanentEducationClient'
import DateUtils from '../../../utils/DateUtils'
import permanentEducationPerimeter from '../../../perimeters/permanentEducationPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import odataFilterOperations from '../../../api/OData/odataFilterOperations'
import * as roles from '../../../constants/roles'
import RemoveItemConfirmationComponent from '../../atoms/RemoveItemConfirmationComponent/RemoveItemConfirmationComponent.vue'
import { mapPermanentEducationToLegacy } from '../../../utils/MapUtils'
import permanentEducationTooltipText from '../../../constants/permanentEducationTooltipText'

export default {
    name: "MyTeamPERemindersTable",
    perimeters: [permanentEducationPerimeter, featuresPerimeter],
    components: {
        LoaderComponent,
        TableFiltersComponent,
        PaginationComponent,
        RemoveItemConfirmationComponent
    },
    data() {
        return {
            roles,
            showErrorMessage: false,
            showNoPERemindersMessage: false,
            permanentEducationReminders: null,
            pageSizes: [
                { value: 10, displayText: 10 },
                { value: 25, displayText: 25 },
                { value: 50, displayText: 50 },
                { value: 100, displayText: 100 }
            ],
            dataQuery: {
                skip: 0,
                take: 10,
                userFilters: []
            },
            userFilters: {
                fullNameFilter: {
                    keys: ['user/firstName', 'user/middleName', 'user/lastName'],
                    operator: odataFilterOperations.CONTAINS,
                    value: '',
                    keyCombination: 'or'
                },
                employeeNumberFilter: {
                    keys: 'user/employeeNumber',
                    value: '',
                    operator: odataFilterOperations.CONTAINS
                },
                emailFilter: {
                    keys: 'user/emailAddress',
                    value: '',
                    operator: odataFilterOperations.CONTAINS
                },
                departmentFilter: {
                    keys: 'user/userDepartment/name',
                    value: '',
                    operator: odataFilterOperations.CONTAINS
                }
            },
            isLoading: false,
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            removePEReminderConfirmationVisibility: false,
            peReminderToDelete: null,
            permanentEducationTooltipText
        }
    },
    computed: {
        ...mapGetters('userModule', {
            userRoles: "roles",
            companyTeamleadersIds: "companyTeamleadersIds",
            userId: "userId"
        }),
        ...mapGetters('accountModule', ['accountId']),
        showTableActions() {
            return (
                this.$isAllowed('managePermanentEducation') ||
                (this.$isAllowed('displayLearningResult') &&
                    this.permanentEducationReminders.some((p) => !!p.latestEnrollmentId))
            )
        },
        headers() {
            const headers = [
                { key: 'UserFullName', title: 'Medewerker e-mailadres' },
                { key: 'CourseName', title: 'Vereiste certificering' },
                { key: 'StartDate', title: 'Ingangsdatum' },
                { key: 'ExpiryDate', title: 'Vervaldatum' },
                { key: 'CreatedByUserFullName', title: 'Gemaakt door', class: 'u-max-width-lg-200' }
            ]

            if (this.showTableActions) 
                headers.push({ key: 'actions', title: '', class: 'u-max-width-lg-200' })

            return headers
        },
        isTeamLeader() {
            return this.userRoles?.includes(roles.TEAMLEADER) && !this.userRoles?.includes(roles.COORDINATOR)
        },
        isCoordinator() {
            return this.userRoles?.includes(roles.COORDINATOR)
        },
        createPEReminderUrl() {
            const url = { name: 'create-permanent-education', params: { ...this.$route.params }}
            
            if(this.isCoordinator) url.params.role = roles.COORDINATOR.toLowerCase()
            else if (this.isTeamLeader) url.params.role = roles.TEAMLEADER.toLowerCase()

            return url
        },
        currentPageNumber() {
            if (this.permanentEducationReminders) {
                return this.pageIndex
            }
            return 1
        },
        totalPages() {
            if (this.permanentEducationReminders) {
                return Math.ceil(this.totalCount / this.pageSize)
            }
            return 1
        },
        showPagination() {
            return this.permanentEducationReminders && this.totalPages > 1
        },
        showResetButton() {
            return Object.values(this.userFilters).some(uf => {
                return uf.value.length
            })
        },
        intro() {
            if(this.$isAllowed('managePermanentEducation')) {
                return " Op deze pagina ziet u al de herinneringen van collega's. U kunt via de 'Voeg een nieuw item toe' knop een nieuwe herinnering toevoegen."
            } 
            return " Op deze pagina ziet u al de herinneringen van collega's."
        }
    },

    async created() {
        await this.addQueryParams()
        await this.fetchPermanentEducationReminders()
    },
    methods: {
        goToEditPEReminderPage(permanentEducationReminder) {
            if(!this.$isAllowed('managePermanentEducation')) return null
            const url = { name: 'edit-permanent-education', params: { id: permanentEducationReminder.permanentEducationId }}
            
            if(this.isCoordinator) url.params.role = roles.COORDINATOR.toLowerCase()
            else if (this.isTeamLeader) url.params.role = roles.TEAMLEADER.toLowerCase()

            return this.$router.replace(url)
        },
        async fetchPermanentEducationReminders() {
            try {
                this.isLoading = true
                const includes = ['CreatedByUser']

                const dataQuery = new ODataQueryBuilder()
                    .setSort('creationDate', 'desc')
                    .setPagination(this.dataQuery.skip, this.dataQuery.take)
                    .addFilter(new ODataQueryFilter('user/userId', odataFilterOperations.NOTEQUALS, this.userId))

                if(this.isCoordinator) {
                    dataQuery.addFilter(new ODataQueryFilter('user/accountId', odataFilterOperations.EQUALS, this.accountId))
                }
                
                if (this.isTeamLeader) {
                    dataQuery.addFilter(new ODataQueryFilter('user/companyId', odataFilterOperations.IN, this.companyTeamleadersIds.join(', ')))
                }

                if(!this.$isAllowed('displayLearningResult')) {
                    dataQuery
                        .addDynamicFilter('(createdByUserId ne null or isImported eq true)')
                }
                else {
                    includes.push('LatestEnrollment')
                }
                dataQuery.setIncludes(includes.join(','))

                const userFilters = this.dataQuery.userFilters.map(
                    f =>  new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
                )
                dataQuery.addFilters(userFilters)
                    
                const response = await searchPermanentEducation(dataQuery.build())

                this.permanentEducationReminders = (response?.items ?? []).map(mapPermanentEducationToLegacy)
                this.totalCount = response?.totalCount ?? 0
                this.pageSize = response?.pageSize ?? 10
                this.pageIndex = response?.pageIndex ?? 0

                if(!response?.items?.length) this.showNoPERemindersMessage = true
                else this.showNoPERemindersMessage = false
            } catch(error) {
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        setPageSize(value) {
            // if the current page size value is selected again (eighter by hand or programatically), do nothing
            if (value === this.dataQuery.take) return

            // if new page size selected, set the skip to 0 and remove from the query string
            this.dataQuery.skip = 0
            if (this.$route.query.skip) {
                this.$router.replace({
                    path: this.$route.path,
                    query: { ...this.$route.query, skip: 0 }
                })
            }

            // set the new page size value in the data query and in the session storage
            this.dataQuery.take = value
            sessionStorage.setItem(
                'MyTeamPERemindersTableComponent/take',
                JSON.stringify(value)
            )

            this.fetchPermanentEducationReminders()
        },
        resetFilters() {
            Object.values(this.userFilters).forEach(uf => {
                uf.value = ''
            })
            this.updateFilters()
        },
        updateFilters() {
            // Return to the first page
            this.dataQuery.skip = 0
            const userFilters = Object.values(this.userFilters).map(uf => {
                return { ...uf }
            })
            this.dataQuery.userFilters = userFilters

            this.fetchPermanentEducationReminders()
        },
        paginationClicked(pageNumber) {
            this.dataQuery.skip = this.dataQuery.take * (pageNumber - 1)
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    skip: this.dataQuery.skip
                }
            })

            this.fetchPermanentEducationReminders()
        },
        getTakeFromSessionStorage() {
            if (sessionStorage.getItem('MyTeamPERemindersTableComponent/take')) {
                try {
                    return parseInt(
                        JSON.parse(
                            sessionStorage.getItem('MyTeamPERemindersTableComponent/take')
                        ),
                        10
                    )
                } catch {
                    return this.pageSize
                }
            }
            return this.pageSize
        },
        addQueryParams() {
            const take = this.getTakeFromSessionStorage()
            this.dataQuery = { ...this.dataQuery, take }
        },
        async deletePermanentEducationReminder(id) {
            this.isLoading = true
            this.removePEReminderConfirmationVisibility = false

            try {
                if(!this.$isAllowed('managePermanentEducation')) return

                await deletePermanentEducation(id)
                this.fetchPermanentEducationReminders()
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.peReminderToDelete = null
                this.isLoading = false  
            }
        },
        setRemoveItemModalVisibility(peReminderId) {
            this.peReminderToDelete = peReminderId
            this.removePEReminderConfirmationVisibility = true
        },
        showEnrollmentIcon(peLatestEnrollmentId) {
            return this.$isAllowed('displayLearningResult') && !!peLatestEnrollmentId
        },
        format: e => DateUtils.formatDate(e),
        getCreatedByUserName(permanentEducationReminder) {
            return permanentEducationReminder.createdByUserFullName ?? (permanentEducationReminder.isImported ? 'Geïmporteerd' : 'Opleider')
        }
    }
}
</script>
import ODataOps from './odataFilterOperations'
import {isNotEmptyValue} from '../../modules/validationsHelper'

export default class DataQueryFilter {
    // TODO: refactor this to pass an object and destructure it 
    constructor(keys, operator, values, keyCombination, valueCombination, options = {}) {
        this.keys = keys
        this.operator = operator
        this.values = values
        this.keyCombination = keyCombination || ''
        this.valueCombination = valueCombination || ''
        this.options = options
    }

    // eslint-disable-next-line class-methods-use-this
    generateFilter(key, operator, value) {
        let filterVal = null
        if (value instanceof Date) {
            filterVal = value.toISOString()
        } else {
            filterVal = typeof value === 'string' ? `'${value}'` : value
        }
        // check operator
        switch (operator) {
            case ODataOps.CONTAINS:
                return `${operator}(${key}, ${filterVal})`
            case ODataOps.IN:
            // "in" operator uses default value because it cannot be quoted after
                return `${key} ${operator} (${value})`
            default:
                return `${key} ${operator} ${filterVal}`
        }
    }

    generateFilterForOData() {
        const me = this
        let filter = ''
        if (!this.keys) {
            console.error('Tried to generate filter using empty keys')
            return filter
        }

        // apply no filters for empty values
        if ((!isNotEmptyValue(this.values) && !this.options.acceptEmptyValues) || (Array.isArray(this.values) && !this.values.length)) {
            return filter
        }

        // keys should be an array
        if (Array.isArray(this.keys)) {
            filter = '('
            if (!this.keyCombination && this.keys.length > 1) {
                console.warn(
                    'Tried to generate complex filter without key combination, will use OR'
                )
                this.keyCombination = 'or'
            }

            // separate values by ' ' for complex keys, not perfect but EF core does not support concat (only as in memory op which is way too slow)
            if (this.keys.length > 1) {
                const values = this.values.match(/[^ ]+/g)
                const valComb = 'and'
                values.forEach(val => {
                    filter += '('
                    me.keys.forEach(key => {
                        filter += `${me.generateFilter(key, this.operator, val)} ${
                            this.keyCombination
                        } `
                    })
                    // remove trailing keycombination
                    filter = filter.substring(0, filter.lastIndexOf(this.keyCombination))
                    filter += `) ${valComb} `
                })
                // remove trailing valComb
                filter = filter.substring(0, filter.lastIndexOf(valComb))
            } else {
                filter += `${me.generateFilter(me.keys[0], this.operator, me.values)}`
            }
            filter += ')'
        } else if (Array.isArray(this.values) && this.values.length) {
            filter = '('
            this.values.forEach(val => {
                filter += `${me.generateFilter(this.keys, this.operator, val)} ${this.valueCombination} `
            })
            // remove trailing keycombination
            filter = filter.substring(0, filter.lastIndexOf(this.valueCombination))
            filter += ')'
        } else {
            // treat as single field
            filter = me.generateFilter(this.keys, this.operator, this.values)
        }
        return filter
    }
}

<template>
  <recess-layout-static
    v-if="$isAllowed('displayReports')"
    :size="1700"
  >
    <recess-divider />
    <h1>Rapporten</h1>
    <recess-divider />
    <template v-if="showTabs">
      <recess-tab-navigation name="report">
        <recess-tab-navigation-item tab-key="coordinator">Coordinator rapportage</recess-tab-navigation-item>
        <recess-tab-navigation-item tab-key="teamleader">Leidinggevende rapportage</recess-tab-navigation-item>
      </recess-tab-navigation>
      <recess-divider />
    </template>
    <powerbi-report :report-type="reportType" />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import { mapState } from 'vuex'
import PowerbiReport from '../../organisms/PowerbiReport/PowerbiReport.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import * as reportType from '../../../constants/reportType'

export default {
    components: { PowerbiReport },
    perimeters: [routePerimeter, featuresPerimeter],
    data() { 
        return {
            tabs: ['coordinator', 'teamleader']
        }
    },
    computed: {
        ...mapState('userModule', ['isUserFetched']),
        showTabs() {
            return (this.$isAllowed('routeAsCoordinator') || this.$isAllowed('routeAsAccountReporter')) 
                && this.$isAllowed('routeAsTeamleader')
        },
        reportType() {
            if (!this.showTabs) {
                if (this.$isAllowed('routeAsCoordinator') || this.$isAllowed('routeAsAccountReporter')) return reportType.COORDINATORREPORT
                return reportType.TEAMLEADERREPORT
            }

            switch (this.$route?.query['report-tab']) {
                case 'coordinator':
                    return reportType.COORDINATORREPORT
                case 'teamleader':
                    return reportType.TEAMLEADERREPORT
                default:
                    return null
            }
        }
    },
    watch: {
        isUserFetched(newValue) {
            if (newValue) {
                if (!this.showTabs) {
                    this.$router.replace({ path: this.$route.path })
                } else {
                    this.$router.replace({ path: this.$route.path, 'report-tab': 'coordinator' })
                }
            }
        }
    },
    mounted() {
        this.initializeReport()
    },
    methods: {
        initializeReport() {
            if (this.$isAllowed('routeAsCoordinator') || this.$isAllowed('routeAsAccountReporter')) {
                this.$router.replace({ query: { ...this.$route.query, 'report-tab': 'coordinator' } })
                return
            }

            if (this.$isAllowed('routeAsTeamleader')) {
                this.$router.replace({ query: { ...this.$route.query, 'report-tab': 'teamleader' } })
            }
        }
    }
}
</script>
<template>
  <recess-layout-static :size="1700">
    <recess-divider />
    <h1>Mijn notificaties</h1>
    <p>Hieronder ziet u een lijst van uw notificaties. Klik op de notificatie om naar de detailpagina te gaan.</p>
    <recess-divider />
    <my-notifications-table />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import MyNotificationsTable from '../../organisms/Tables/MyNotificationsTable.vue'

export default {
    name: 'MyNotifications',
    components: { MyNotificationsTable }
}
</script>
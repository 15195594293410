<template>
  <div v-if="showTable">
    <h4 class="u-textcolor-primary">Instemming met delen van resultaten</h4>
     
    <learning-result-sharing-matrix 
      data-test="sharing-matrix-component"
      is-bold-label
      :learning-result-categories-to-show="learningResultCategoriesToShow"
      :is-team-leader-enrollment="isTeamLeaderEnrollment"
      :selected-learning-result-options="selectedLearningResultOptions"
    />
  </div>
</template>

<script>
import { computed } from 'vue'
import useStore from '../../../../../composables/useStore'
import learningResultSharingTypes from '../../../../../constants/learningResultSharingTypes'
import learningResultSharingOptions from '../../../../../constants/learningResultSharingOptions'
import { TEAMLEADER } from '../../../../../constants/enrollmentTypes'
import LearningResultSharingMatrix from '../../../LearningResultSharing/LearningResultSharingMatrix.vue'
import LearningResultSharingUtils from '../../../../../utils/LearningResultSharingUtils'

export default {
    components: {
        LearningResultSharingMatrix
    },
    setup() {
        const store = useStore()

        const isTeamLeaderEnrollment = computed(() => {
            return store?.getters['enrollmentModule/enrollmentType'] === TEAMLEADER
        })
        
        const learningResultCategoriesToShow = computed(() => {
            if (isTeamLeaderEnrollment.value) {
                return Object.keys(learningResultSharingTypes)?.reduce((acc, key) => {
                    const mappedLearningResultSharingType = LearningResultSharingUtils.mapLearningResultSharingType(store.state.enrollmentModule.learningResultSharingType)
                    if (key === mappedLearningResultSharingType && key !== learningResultSharingTypes.NONE.key)
                        acc.push(learningResultSharingTypes[key])

                    return acc
                }, [])
            }
              
            return Object.keys(learningResultSharingTypes)?.filter(key => 
                key !== learningResultSharingTypes.NONE.key && key !== learningResultSharingTypes.SELECTEDBYAPPROVER.key
            ).map(key => learningResultSharingTypes[key])

        })

        const learningResultOptionsToShow = computed(() => {
            return Object.keys(learningResultSharingOptions)?.map(key => learningResultSharingOptions[key])
        })

        const showTable = computed(() => {
            return learningResultOptionsToShow.value?.length && learningResultCategoriesToShow.value?.length
        })

        const selectedLearningResultOptions = computed(() => {
            return store?.state?.enrollmentModule?.selectedLearningResultOptions
        })

        return {
            learningResultCategoriesToShow,
            learningResultOptionsToShow,
            isTeamLeaderEnrollment,
            selectedLearningResultOptions,
            showTable
        }
    }
}
</script>
<template>
  <recess-layout-static :size="1700">
    <recess-divider />
    <my-budgets-table />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import MyBudgetsTable from '../../organisms/Tables/MyBudgetsTable.vue'

export default {
    name: 'MyBudgets',
    components: {
        MyBudgetsTable
    }
}
</script>


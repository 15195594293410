<template>
  <div>
    <transition name="fade">
      <loader-component
        v-if="isFetchingUser || isFetchingAccount"
        overlay="white"
        color="primary"
      />
    </transition>

    <top-notifications-accordion class="d-block d-md-none" />

    <div class="u-position-relative">
      <banner-component
        v-if="bannerUrl"
        class="u-position-absolute"
        :x-small-image="bannerUrl"
      />
    </div>
    
    <recess-layout-static>
      <div class="row">
        <div class="col-12">
          <recess-divider :display-small-viewport="false" />
          <recess-divider />
          <home-banner-text-component />
        </div>
        <recess-divider />
      </div>

      <div class="row">
        <div class="col-12 col-md-8">
          <recess-card variant="primary">
            <banner-search-suggestion-component />
            <search-type-component
              styling-variant="secondary"
              class="u-textcolor-white"
              @search-type-changed="val => setSearchFromTeamAssortments(val)"
            />
          </recess-card>
        </div>

        <div
          v-if="showTeamLeaderEnrollmentButton"
          class="col-12 col-md-4 d-inline-flex mt-3 mt-md-0"
        >
          <recess-card
            class="d-flex flex-grow-1 align-items-start align-items-sm-center"
            variant="variant1"
          >
            <recess-button
              :url="{ name: 'enrollment', params: { type: 'teamleader' }}"
              class="w-100"
              data-test="teamleader-enrollment-button"
              title="Teamleden inschrijven"
              variant="secondary"
            />
          </recess-card>
        </div>
      </div>

      <recess-divider  
        :display-small-viewport="false"
      />
          
      <main class="row">
        <section class="col-12 col-md-8">
          <recess-divider
            v-if="!showTeamLeaderEnrollmentButton"
            variant="large"
            :display-small-viewport="true"
            :display-medium-viewport="false"
            :display-large-viewport="false"
          />
          
          <home-banner-tab-navigation />

          <template v-if="$isAllowed('displayAI')">
            <ai-home-education-advisor />
            <ai-home-course-suggestions v-if="aiAdvisor && aiAdvisor.length" class="pt-3" />
            <recess-divider />
          </template>

          <my-activities-component />
          
          <quick-links-carousel data-test="quick-link-carousel" />

          <my-learning-paths-carousel v-if="$isAllowed('displayLearningPath')" data-test="learning-path-carousel" />
              
          <my-budgets-carousel v-if="$isAllowed('displayBudgets')" data-test="budget-carousel" />
      
        </section>
    
        <aside class="d-none d-md-block col-12 col-md-4">
          <top-notifications  />
        </aside>
      
      </main>
    </recess-layout-static>

    <recess-divider />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import BannerComponent from '../../organisms/BannerComponent/BannerComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import BannerSearchSuggestionComponent from '../../organisms/SearchSuggestionsComponent/BannerSearchSuggestionComponent.vue'
import MyActivitiesComponent from '../../organisms/MyActivitiesComponent/MyActivitiesComponent.vue'
import MyBudgetsCarousel from '../../organisms/Carousels/MyBudgetsCarousel.vue'
import topNotifications from '../../organisms/Notifications/TopNotifications.vue'
import topNotificationsAccordion from '../../organisms/Notifications/TopNotificationsAccordion.vue'
import HomeBannerTextComponent from '../../organisms/HomeBannerTextComponent/HomeBannerTextComponent.vue'
import SearchTypeComponent from '../../organisms/SearchSuggestionsComponent/SearchTypeComponent.vue'
import QuickLinksCarousel from '../../organisms/Carousels/QuickLinksCarousel.vue'
import MyLearningPathsCarousel from '../../organisms/Carousels/MyLearningPathsCarousel.vue'
import HomeBannerTabNavigation from '../../organisms/HomeBannerTabNavigation/HomeBannerTabNavigation.vue'
import AiHomeEducationAdvisor from '../../organisms/AI/AiHomeEducationAdvisor.vue'
import AiHomeCourseSuggestions from '../../organisms/AI/AiHomeCourseSuggestions.vue'

export default {
    perimeters: [routePerimeter, featuresPerimeter],
    components: {
        BannerSearchSuggestionComponent,
        BannerComponent,
        MyActivitiesComponent,
        MyBudgetsCarousel,
        topNotifications,
        topNotificationsAccordion,
        LoaderComponent,
        HomeBannerTextComponent,
        SearchTypeComponent,
        QuickLinksCarousel,
        MyLearningPathsCarousel,
        HomeBannerTabNavigation,
        AiHomeEducationAdvisor,
        AiHomeCourseSuggestions
    },
    computed: {
        ...mapState('userModule', ['isFetchingUser']),
        ...mapState('accountModule', ['isFetchingAccount']),
        ...mapGetters('accountModule', ['isAccountEnabled', 'bannerUrl']),
        ...mapGetters('userModule', ['aiAdvisor']),
        showTeamLeaderEnrollmentButton() {
            return this.$isAllowed('routeAsTeamleader') && this.isAccountEnabled
        }
    },
    methods: {      
        ...mapMutations("searchModule", {
            setSearchFromTeamAssortments: "setSearchFromTeamAssortments"
        })
    }
}
</script>

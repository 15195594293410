<template>
  <div v-if="isUserImpersonating">
    <div class="c-impersonation-header">
      Impersonating
      <span class="font-weight-bold">{{ user.firstName }} {{ user.lastName }}</span>.
      <recess-button
        class="u-line-height-initial"
        title="Back to your own account"
        variant="variant4"
        @click="impersonateExit"
      />
    </div>
    <recess-alert
      v-if="showErrorMessage"
      class="col c-impersonation-header__error"
      type="error"
      text="There was a problem signing out the impersonated user, please try again later"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { impersonateSignoutAndRedirect } from '../../../api/AuthenticationClient'

export default {
    data() {
        return {
            showErrorMessage: false,
            timeoutId: null
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapGetters('userModule', ['isUserImpersonating', 'idServerToken']),
        ...mapGetters('oplzConfigurationModule', ['oplzAdminUrl']),
        ...mapGetters('configurationModule', ['oidcConfig'])
    },
    methods: {
        async impersonateExit() {
            try {
                await impersonateSignoutAndRedirect(
                    this.oidcConfig.authority, 
                    this.idServerToken, 
                    this.oplzAdminUrl)
            } catch {
                const me = this
                me.showErrorMessage = true
                if (me.timeoutId) {
                    clearTimeout(me.timeoutId)
                }
                this.timeoutId = setTimeout(() => {
                    me.showErrorMessage = false
                }, 3000)
            }
        }
    }
}
</script>
<template>
  <div class="c-header__small d-flex align-items-center h-100 w-100 d-lg-none">
    <div class="c-mobile-navigation__wrapper">
      <SmallMainNavigation />
    </div>
    <div class="c-header__logo d-flex align-items-center">
      <account-logo-component :logo-url="account.logoUrl" />
    </div>

    <div
      v-if="$isAllowed('displaySearchLink')"
      class="c-header__search"
    >
      <SearchSuggestionsComponent />
    </div>

    <div class="c-header__user-menu">
      <SmallSettingNavigation />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import SearchSuggestionsComponent from '../SearchSuggestionsComponent/SearchSuggestionsComponent.vue'
import SmallMainNavigation from '../NavigationComponent/Small/SmallMainNavigation.vue'
import SmallSettingNavigation from '../NavigationComponent/Small/SmallSettingNavigation.vue'
import AccountLogoComponent from '../../atoms/AccountLogoComponent/AccountLogoComponent.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'

export default {
    perimeters: [featuresPerimeter],
    components: {
        SearchSuggestionsComponent,
        SmallMainNavigation,
        SmallSettingNavigation,
        AccountLogoComponent
    },
    computed: {
        ...mapState('accountModule', ['account'])
    }
}
</script>
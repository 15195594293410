<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div>
    <template v-if="investmentContent">
      <div
        v-for="(content, index) in investmentContent"
        :key="`investmentContent${index}`"
        class="c-course-content__wrapper"
      >
        <h3 v-if="content.heading">{{ content.heading }}</h3>
        <div
          v-if="content.bodyHtml || content.body"
          class="c-course-content__block"
          v-html="content.bodyHtml || content.body"
        ></div>
      </div>
    </template>

    <template v-if="costOverview && price">
      <h3>Kostenoverzicht</h3>
      <recess-list class="u-bg--variant5">
        <recess-list-row class="d-none d-md-flex">
          <recess-list-item>
            <span class="font-weight-bold">Kostensoort</span>
          </recess-list-item>
          <recess-list-item>
            <span class="font-weight-bold">Bedrag</span>
          </recess-list-item>
        </recess-list-row>
        <recess-divider
          :display-small-viewport="false"
          show-line
        />
        <recess-list-row
          v-for="(invoiceItem, index) in invoiceItems"
          :key="index"
        >
          <recess-list-item>{{ invoiceItem.costTypeDisplayValue || invoiceItem.costType }}</recess-list-item>
          <recess-list-item>{{ getInvoiceItemCalculatedPrice(invoiceItem, isVatExempt, displayVatInPrices) || '-' }}</recess-list-item>
        </recess-list-row>
        <recess-divider show-line />
        <recess-list-row>
          <recess-list-item>
            <span class="font-weight-bold">Totaal</span>
            <span
              class="font-italic"
              data-test="vat-text"
            >({{ vatText }})</span>
          </recess-list-item>
          <recess-list-item>{{ formatPrice(price.totalPrice) }}</recess-list-item>
        </recess-list-row>
      </recess-list>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import priceFormatter from '../../../modules/priceFormatter'
import coursePriceHelper from '../../../modules/coursePriceHelper'

export default {
    computed: {
        ...mapState('coursePageModule', ['price', 'isFetchingCoursePrice']),
        ...mapGetters('coursePageModule', ['investmentContent', 'costOverview']),
        ...mapGetters('accountModule', ['displayVatInPrices']),
        invoiceItems() {
            return this.costOverview?.invoiceItems || []
        },
        vatText() {
            return coursePriceHelper.getVatText(this.displayVatInPrices)
        },
        isVatExempt() {
            return this.price?.vatExempt
        }
    },
    methods: {
        getInvoiceItemCalculatedPrice: coursePriceHelper.getInvoiceItemCalculatedPrice,
        formatPrice: priceFormatter.formatPrice
    }
}
</script>
<template>
  <!-- Teamleader assortment selection -->
  <div
    v-if="$isAllowed('routeAsTeamleader')"
    class="d-flex mt-3"
  >
    <recess-radio-input
      name="searchFromTeamAssortments"
      class="mr-4 mr-md-5" 
      :value="false"
      label-text="Voor mezelf"
      data-test="own-course-btn"
      :styling-variant="stylingVariant"
      :disabled="teamLeaderSelfSearchDisabled"
      :default-checked="!searchFromTeamAssortments"
      @change="value => $emit('search-type-changed', value)"
    />
    <recess-radio-input
      name="searchFromTeamAssortments"
      data-test="course-for-my-team"
      :value="true"
      :styling-variant="stylingVariant"
      label-text="Voor mijn team"
      :default-checked="searchFromTeamAssortments"
      @change="value => $emit('search-type-changed', value)"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import routePerimeter from '../../../perimeters/routePerimeter'

export default {
    name: 'SearchTypeComponent',
    perimeters: [routePerimeter],
    props: {
        stylingVariant: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState("searchModule", ["searchFromTeamAssortments"]),
        ...mapGetters("accountModule", ["teamLeaderSelfSearchDisabled"])
    },
    created() {
        if(this.$isAllowed('routeAsTeamleader')) {
            this.setSearchFromTeamAssortments(this.teamLeaderSelfSearchDisabled || this.searchFromTeamAssortments)
        }   
        else this.setSearchFromTeamAssortments(false)       
    },
    methods: {
        ...mapMutations("searchModule", {
            setSearchFromTeamAssortments: "setSearchFromTeamAssortments"
        })
    }
}
</script>

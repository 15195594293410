<!-- TODO: this component can be further improve to make it more readable and easy to maintain -->
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
  <div class="c-enrollment">    
    <enrollment-insufficient-budget-modal />
    <enrollment-warning-duplicate-enrollment v-show="!blockBecauseOfInsufficientBudget && !isLoading" />
    <enrollment-confirmation-modal
      :show-team-leader-enrollment-confirmation-text="showTeamLeaderEnrollmentConfirmationModal"
      :show-student-enrollment-confirmation-text="showStudentEnrollmentConfirmationModal"
      @close="closeConfirmationAndRedirect"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <recess-loader
        v-if="isLoading || isLoadingStartMoments"
        overlay="white"
        color="primary"
        :full-screen="isLoading"
      />
    </transition>

    <template v-if="showEnrollmentFlow">
      <template v-if="!isFinished">
        <h4 class="u-textcolor-primary" v-html="title"></h4>
        <step-indicator
          :steps="totalSteps"
          :current-step="currentStep"
          @goToStep="stepToGo => setCurrentStepCode(stepToGo.code)"
        />
        <recess-divider />

        <div v-show="!isLoading">
          <keep-alive :exclude="['EnrollmentStepSelectCourse','EnrollmentStepSummary']">
            <component
              :is="currentComponent"
              v-if="currentComponent"
              @previous-step="previousStep()"
              @cancel="cancelStep()"
              @next-step="nextStep()"
              @submit="submitEnrollment"
            />
          </keep-alive>
        </div>
      </template>
      <enrollment-thank-you v-if="showThankYouPage" />
    </template>
    <recess-alert
      v-if="isError"
      type="error"
      :text="`${errorMessage} Ga naar home om opnieuw naar een ontwikkelactiviteit te zoeken.`"
      class="mb-3"
    />
    <recess-alert
      v-if="!!courseAccessErrorMessage"
      type="error"
      :text="courseAccessErrorMessage"
      class="mb-3"
    />
    <recess-button
      v-if="$isAllowed('displayHomeLink') && (isError || !userCanEnroll)"
      :url="{ name : 'home' }"
      variant="secondary"
      title="Terug naar home"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import routePerimeter from '../../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import * as enrollmentSteps from '../../../../constants/enrollmentSteps'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import * as roleIds from "../../../../constants/roleIds"

import StepIndicator from '../StepIndicator.vue'

import EnrollmentWarningDuplicateEnrollment from './EnrollmentWarningDuplicateEnrollment.vue'
import EnrollmentInsufficientBudgetModal from './EnrollmentInsufficientBudgetModal.vue'
import EnrollmentStepStartmoments from './EnrollmentStepStartMoments.vue'
import EnrollmentStepSelectStudents from './EnrollmentStepSelectStudents.vue'
import EnrollmentStepTeamLeaders from './EnrollmentStepTeamLeaders.vue'
import EnrollmentStepTeamLeader from './EnrollmentStepTeamLeader.vue'
import EnrollmentStepOptions from './EnrollmentStepOptions.vue'
import EnrollmentStepPersonalData from './EnrollmentStepPersonalData.vue'
import EnrollmentStepBudgets from './EnrollmentStepBudgets.vue'
import EnrollmentStepBillingAddress from './EnrollmentStepBillingAddress.vue'
import EnrollmentStepSelectCourse from './EnrollmentStepSelectCourse.vue'
import EnrollmentStepSummary from './EnrollmentStepSummary.vue'
import EnrollmentStepLearningResultSharing from './EnrollmentStepLearningResultSharing.vue'
import EnrollmentThankYou from './EnrollmentThankYou.vue'
import EnrollmentConfirmationModal from '../EnrollmentConfirmationModal.vue'
import {errorMessages} from '../../../../constants/errorMessages'
import enrollmentClient from '../../../../api/enrollmentClient'
import DateUtils from '../../../../utils/DateUtils'

export default {
    name: 'EnrollmentComponent',
    perimeters: [routePerimeter, featuresPerimeter],
    components: {
        StepIndicator,
        EnrollmentWarningDuplicateEnrollment,
        EnrollmentInsufficientBudgetModal,
        EnrollmentThankYou,
        EnrollmentConfirmationModal
    },
    props: {
        prevRoute: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            pageLeaveMessage:
                'Let op: weet u zeker dat u de inschrijving wilt annuleren? Om een stap terug te gaan in de inschrijving klikt u op de "terug" knop. Klik op "ok" om de inschrijving te annuleren.',
            enrollmentSteps,
            enrollmentTypes,
            showThankYouPage: false,
            showTeamLeaderEnrollmentConfirmationModal: false,
            showStudentEnrollmentConfirmationModal: false
        }
    },
    computed: {
        ...mapState('enrollmentModule', [
            'isError',
            'isFinished',
            'approvalRequired',
            'enrollmentType',
            'userCanEnroll',
            'formData',
            'course'
        ]),
        ...mapGetters('enrollmentModule', [
            'isLoading', 
            'totalSteps', 
            'currentStep', 
            'courseName', 
            'blockBecauseOfInsufficientBudget',
            'errorMessage',
            'isSecondApprovalRequired',
            'keyAccounts',
            'copiedEnrollment',
            'courseAccessErrorMessage'
        ]),
        ...mapState('userModule', ['user']),
        ...mapGetters('userModule', ['legalAgreementAcceptedDate']),
        ...mapGetters('accountModule', ['isBudgetEnabled', 'isSubsidyEnabled', 'teamLeaderEnrollmentConfirmationText', 'studentEnrollmentConfirmationText']),
        ...mapGetters({
            isLoadingStartMoments: 'startMomentsModule/isLoading'
        }),
        showEnrollmentFlow() {
            return this.userCanEnroll && !this.isError && this.totalSteps?.length && this.currentStep
        },
        copiedEnrollmentId() {
            return this.$route.query?.enrollment
        },
        assortmentId() {
            return this.$route.query?.assortment
        },
        courseId() {
            return this.$route.query?.course
        },
        userBudgets() {
            return this.formData?.budgets
        },
        title() {
            let title = `Inschrijven ontwikkelactiviteit: ${this.courseName}`
            if (this.enrollmentType === enrollmentTypes.TEAMLEADER) {
                const subtitle =
                    this.courseName &&
                    this.currentStep.code !== enrollmentSteps.SELECTSTUDENTS.code &&
                    this.currentStep.code !== enrollmentSteps.SELECTCOURSE.code
                        ? `voor ${this.courseName}`
                        : ''
                title = `Inschrijven teamleden ${subtitle}`
            }
            return title
        },
        currentComponent() {
            switch(this.currentStep.code) {
                case enrollmentSteps.STARTMOMENTS.code:
                    return EnrollmentStepStartmoments
                case enrollmentSteps.PERSONALDATA.code:
                    return EnrollmentStepPersonalData
                case enrollmentSteps.BUDGETS.code:
                    return EnrollmentStepBudgets
                case enrollmentSteps.BILLINGADDRESS.code:
                    return EnrollmentStepBillingAddress
                case enrollmentSteps.SELECTSTUDENTS.code:
                    return EnrollmentStepSelectStudents
                case enrollmentSteps.SELECTCOURSE.code:
                    return EnrollmentStepSelectCourse
                case enrollmentSteps.TEAMLEADER.code:
                    if(this.approvalRequired) {
                        if(this.enrollmentType === enrollmentTypes.STUDENT) return EnrollmentStepTeamLeader
                        if(this.enrollmentType === enrollmentTypes.COORDINATOR ) return EnrollmentStepTeamLeaders
                    }
                    return null
                case enrollmentSteps.OPTIONS.code:
                    return EnrollmentStepOptions
                case enrollmentSteps.LEARNINGRESULTS.code:
                    return EnrollmentStepLearningResultSharing
                case enrollmentSteps.SUMMARY.code:
                    return EnrollmentStepSummary
                default:
                    return null
            }
        }
    },
    watch: {
        isFinished(value) {
            if (value) this.removePageLeaveEvent()
        }
    },
    created() {
        this.initialize()
    },
    beforeDestroy() {
        if (this.enrollmentType === enrollmentTypes.TEAMLEADER) this.resetSearchModuleState()
        this.resetEnrollmentModuleState()
        this.resetStartMomentsModuleState()
        this.removePageLeaveEvent()
    },
    mounted() {
        this.addPageLeaveEvent()
    },
    methods: {
        ...mapActions('enrollmentModule', [
            'getCourse',
            'sendEnrollment',
            'sendEnrollments',
            'nextStep',
            'previousStep',
            'getCountries',
            'getCoursePrice',
            'getEnrollmentsForCourse',
            'getUserBudgets',
            'getKeyAccounts',
            'checkApprovalRequired'
        ]),
        ...mapMutations('enrollmentModule', [
            'resetState',
            'setSelectedStartMoment',
            'setUser',
            'setCurrentStepCode',
            'setCourseAssortment',
            'setErrorMessage',
            'setIsError',
            'setFormData',
            'addStudent',
            'selectCompanyTeamleader',
            'setIsFetchingEnrollment',
            'setCopiedEnrollment'
        ]),
        ...mapMutations({ 
            resetEnrollmentModuleState: 'enrollmentModule/resetState',
            resetStartMomentsModuleState: 'startMomentsModule/RESET_STATE',
            resetSearchModuleState: 'searchModule/resetState' 
        }),
        async initializeCopiedEnrollmentData() {
            try {
                this.setIsFetchingEnrollment(true)
                const copiedEnrollment = await enrollmentClient.getEnrollment(this.copiedEnrollmentId)

                if(!copiedEnrollment) {
                    this.setIsError(true)
                    throw new Error("Failed to fetch enrollment to copy.")
                }

                await this.setCopiedEnrollment(copiedEnrollment)
                await this.fetchCopiedEnrollmentData()
                this.setCopiedEnrollmentData()
            } catch (error) {
                throw new Error(error)
            } finally {
                this.setIsFetchingEnrollment(false)
            }
        },
        async initializeEnrollmentData() {
            try {
                let promises = []
                switch(this.enrollmentType) {
                    case enrollmentTypes.TEAMLEADER: 
                        promises = [...promises, ...this.getTeamLeaderEnrollmentDataToFetch()]
                        break
                    case enrollmentTypes.STUDENT: 
                        await this.setStudentEnrollmentData()
                        promises = [...promises, ...this.getStudentEnrollmentDataToFetch()]
                        break
                    case enrollmentTypes.COORDINATOR: 
                        await this.setCoordinatorEnrollmentData()
                        break
                    default:
                        break
                }
    
                await Promise.all(promises)
            } catch (error) {
                throw new Error(error)  
            }
        },
        async initialize() { 
            if(this.copiedEnrollmentId) {
                await this.initializeCopiedEnrollmentData()
            } else {
                await this.initializeEnrollmentData()
            }

            if(!this.currentStep) this.setCurrentStepCode(this.totalSteps?.[0]?.code)
        },
        async setStudentEnrollmentData() {
            await this.initializeCourse()
            this.setUser(this.user)
        },
        async setCoordinatorEnrollmentData() {
            await this.initializeCourse()
        },
        async initializeCourse() {
            this.setCourseAssortment(this.assortmentId)
            const course = await this.getCourse(this.courseId)
            
            if (course?.startMoments?.length) {
                await this.checkApprovalRequired()
            }
            if(course?.deleted) {
                this.setErrorMessage(errorMessages.CourseDeleted)
                this.setIsError(true)
                throw new Error("Course is deleted")
            }
        },
        async fetchCopiedEnrollmentData() {
            await this.initializeCourse()

            const promises = [
                this.getCountries()
            ]
            
            if(this.copiedEnrollment?.roleId === roleIds.TEAMLEADER) promises.push(this.getKeyAccounts())
            if(!this.copiedEnrollment?.roleId) promises.push(this.getCoursePrice())
            if(this.isBudgetEnabled) promises.push(this.getUserBudgets())
    
            await Promise.all(promises)
        },
        getStudentEnrollmentDataToFetch() {
            return [
                this.getCountries(),
                this.getCoursePrice(),
                this.getUserBudgets(), 
                this.getEnrollmentsForCourse(this.courseId)
            ]
        },
        getTeamLeaderEnrollmentDataToFetch() {
            return this.$isAllowed('displayKeyAccounts') ? [this.getKeyAccounts()] : []
        },
        getStudentEnrollmentCopiedFormData() {
            const studentEnrollmentCopiedData = {}

            if (this.copiedEnrollment?.budgetTransactions?.length && this.isBudgetEnabled) {
                studentEnrollmentCopiedData.budgets = this.userBudgets?.map(b => {
                    const matchedUsedBudget = this.copiedEnrollment.budgetTransactions.find(bt => bt.budgetId === b.id)

                    if(matchedUsedBudget) b.selectedAmount = matchedUsedBudget.amount

                    return b
                })
            }

            if(this.approvalRequired) studentEnrollmentCopiedData.assignedApproverId = this.copiedEnrollment.assignedApproverId

            if(this.$isAllowed('displayEnrollReason')) {
                studentEnrollmentCopiedData.enrollReasonCode = this.copiedEnrollment.enrollReasonCode
                studentEnrollmentCopiedData.enrollReasonMessage = this.copiedEnrollment.enrollReasonMessage
            }

            return studentEnrollmentCopiedData
        },
        getTeamLeaderEnrollmentCopiedFormData() {
            const teamLeaderCopiedData = {}
            
            if(this.$isAllowed('displayCostCenter')) teamLeaderCopiedData.costCenter = this.copiedEnrollment.costCenter
            if(this.$isAllowed('displayPONumber')) teamLeaderCopiedData.purchaseOrderNumber = this.copiedEnrollment.purchaseOrderNumber
            if(this.keyAccounts?.length && this.$isAllowed('displayKeyAccounts')) teamLeaderCopiedData.keyAccountId = this.copiedEnrollment.keyAccountId
            if(this.isSubsidyEnabled) teamLeaderCopiedData.isSubsidized = this.copiedEnrollment.isSubsidized
            if(this.course?.startMoments?.length) teamLeaderCopiedData.isStartmomentSelectionEnabled = this.copiedEnrollment.isStartmomentUpdatesEnabled
            if(this.isSecondApprovalRequired) teamLeaderCopiedData.secondApproverId = this.copiedEnrollment.assignedSecondApproverId

            teamLeaderCopiedData.studyContractTemplateId = this.copiedEnrollment.studyContractTemplateId

            return teamLeaderCopiedData
        },
        setCopiedEnrollmentTeamLeaderCompany() {
            // Set team leaders company so that we can show the company name
            if(this.approvalRequired) this.selectCompanyTeamleader({ user: this.copiedEnrollment.assignedApprover , companyId: this.copiedEnrollment.user.companyId })
        },
        setCopiedEnrollmentUser() {
            const user = {
                ...this.copiedEnrollment.user,
                company: {
                    name: this.copiedEnrollment.user?.companyName
                }
            }

            this.addStudent(user)
        },
        setCopiedEnrollmentData() {
            if(!this.copiedEnrollment) return

            let formData = {
                birthday: DateUtils.formatDate(this.copiedEnrollment.birthDay),
                birthplace: this.copiedEnrollment.birthPlace,
                city: this.copiedEnrollment.addressCity,
                costCenter: this.copiedEnrollment.costCenter,
                country: this.copiedEnrollment.addressCountryCode,
                emailAddress: this.copiedEnrollment.email,
                firstName: this.copiedEnrollment.firstName,
                lastName: this.copiedEnrollment.lastName,
                middleName: this.copiedEnrollment.middleName,
                number: this.copiedEnrollment.addressHouseNumber,
                numberAdditive: this.copiedEnrollment.addressHouseNumberAdditive,
                postalCode: this.copiedEnrollment.addressPostalCode,
                street: this.copiedEnrollment.addressStreet,
                telephone: this.copiedEnrollment.phoneNumber,
                variantDayOfWeek: this.copiedEnrollment.dayOfWeek,
                courseVariant: this.copiedEnrollment.courseVariant
            }

            switch(this.copiedEnrollment.roleId) {
                case roleIds.TEAMLEADER: 
                    this.setCopiedEnrollmentUser()
                    this.setCopiedEnrollmentTeamLeaderCompany()
                
                    formData = {
                        ...formData,
                        ...this.getTeamLeaderEnrollmentCopiedFormData()
                    }
                    break
                case roleIds.COORDINATOR:
                    this.setCopiedEnrollmentUser()
                    this.setCopiedEnrollmentTeamLeaderCompany()
                    break
                case null:
                    formData = {
                        ...formData,
                        ...this.getStudentEnrollmentCopiedFormData()
                    }
                    break
                default: 
                    break
            }

            this.setFormData(formData)
        },
        cancelStep() {
            if(!this.prevRoute?.name || this.prevRoute.name === 'course') this.$router.replace({ name: 'course', query: { course: this.courseId, assortment: this.$route?.query?.assortment }})
            else this.$router.replace(this.prevRoute)
        },
        closeConfirmationAndRedirect() {
            this.showTeamLeaderEnrollmentConfirmationModal = false
            this.showStudentEnrollmentConfirmationModal = false
            this.showThankYouPage = true
        },
        async submitEnrollment() {
            let response = false

            if (this.enrollmentType === enrollmentTypes.STUDENT) {
                response = await this.sendEnrollment()
                
                if(this.studentEnrollmentConfirmationText) {
                    this.showStudentEnrollmentConfirmationModal = true
                    return
                }
            } else {
                response = await this.sendEnrollments()
                if(this.enrollmentType === enrollmentTypes.TEAMLEADER && this.teamLeaderEnrollmentConfirmationText) {
                    this.showTeamLeaderEnrollmentConfirmationModal = true
                    return
                }
            }

            if (response) this.showThankYouPage = true
        },
        windowClosePrompt(e) {
            const message = this.pageLeaveMessage
            // This is how the only way of implementing the beforeunload event
            // Gecko + IE
            ;(e || window.event).returnValue = message
            // Gecko + Webkit, Safari, Chrome etc.
            return message
        },
        pageLeavePrompt(to, from, next) {
            const message = this.pageLeaveMessage
            // Only react to a page leave when the enrollment is not finished yet (in other words: as long as we're not on the thank you page)
            // Also check if the user did accept legal agreement so the confirm alert won't affect the redirect
            if (!this.isFinished && to.path !== from.path && this.legalAgreementAcceptedDate) {
                if (this.isSafari()) {
                    // On safari window.confirm always returns false, so we cant prevent routing there
                    next()
                } else {
                    // eslint-disable-next-line no-alert
                    const answer = window.confirm(message)
                    if (answer) {
                        next()
                    } else {
                        next(false)
                    }
                }
            } else {
                next()
            }
        },
        isSafari() {
            // Checks if the lightbox is on a safari browser to make the bottom padding bigger
            if (window && window.navigator && window.navigator.userAgent) {
                return (
                    window.navigator.userAgent.match(/iPad/i) ||
                    window.navigator.userAgent.match(/iPhone/i)
                )
            }
            return false
        },
        addPageLeaveEvent() {
            if (window) {
                window.addEventListener('beforeunload', this.windowClosePrompt)
                // Add a custom beforeHook specific for enrollment
                this.$router.beforeHooks.push(this.pageLeavePrompt)
            }
        },
        removePageLeaveEvent() {
            if (window) {
                window.removeEventListener('beforeunload', this.windowClosePrompt)
                // Remove the beforeHook specific for enrollment
                this.$router.beforeHooks = this.$router.beforeHooks.filter(
                    x => x !== this.pageLeavePrompt
                )
            }
        }
    }
}
</script>

<template>
  <div
    :class="['c-menu-button', { 'is-active': isOpen }]"
    @click="toggleOpen"
  >
    <slot>
      <div class="c-menu-button__icon">
        <div class="c-menu-button__icon-inner"></div>
      </div>
      <span class="c-menu-button__label">{{ buttonLabel }}</span>
    </slot>
  </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        buttonLabel: {
            type: String,
            default: 'Menu'
        }
    },
    data() {
        return {
            mIsOpen: this.isOpen
        }
    },
    watch: {
        isOpen(newValue) {
            this.mIsOpen = newValue
        }
    },
    methods: {
        toggleOpen() {
            this.mIsOpen = !this.mIsOpen

            if (this.mIsOpen) {
                this.$emit('onOpen')
            } else {
                this.$emit('onClose')
            }
        }
    }
}
</script>

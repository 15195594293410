<template>
  <section>
    <recess-accordion 
      v-for="(suggestion, index) in suggestions" 
      :key="`${index} - ${suggestion}`"
      class="mt-2"
      :active="openedAccordionIndex === index"
      :is-disabled="isLoading"
      @toggled="isOpen => fetchCourseRecommendation(suggestion, isOpen, index)"
    >
      <template #accordion-title>
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="index === isFetchingCourseRecommendationIndex"
            color="primary"
            variant="small"
            overlay="white"
          />
        </transition>
        <span>Suggesties voor 
          <router-link
            :to="{ 
              name: 'search',
              query: {
                q: suggestion,
                searchType: SELFSEARCH
              }
            }">
            <strong>{{ suggestion }}</strong>
          </router-link>
        </span>
       
      </template>
      <template #accordion-content>
        <transition
          name="fate"
          mode="out-in">
          <ai-course-suggestions-carousel 
            v-if="courseRecommendations.has(suggestion) && courseRecommendations.get(suggestion).length && openedAccordionIndex === index"
            :courses="courseRecommendations.get(suggestion)"
            @toggle-assign-lp="id => setCourseAndShowAssignLearningPath(id)" 
          />
        </transition>

        <transition
          name="fate"
          mode="out-in">
          <recess-alert 
            v-if="(!courseRecommendations.has(suggestion) || !courseRecommendations.get(suggestion).length) && !isLoading" 
            text="Voor de voorgestelde ontwikkelrichting zijn er op dit moment geen ontwikkelactiviteiten beschikbaar in uw assortiment."
          />
        </transition>
      </template>
    </recess-accordion>

    <assign-learning-path-course-component
      class="u-cursor-default"
      :show-assign-learning-path="showAssignLearningPath"
      :course-id="courseToAddLearningPath"
      @close="closeAssignLearningPath()"
    />
  </section>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from 'vue'
import useStore from '../../../composables/useStore'
import AiCourseSuggestionsCarousel from '../Carousels/AiCourseSuggestionsCarousel.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import AssignLearningPathCourseComponent from '../LearningPath/AssignLearningPathCourseComponent.vue'
import courseClient from '../../../api/courseClient'
import { SELFSEARCH } from '../../../constants/searchTypes'

export default {
    components: { 
        AiCourseSuggestionsCarousel,
        AssignLearningPathCourseComponent,
        LoaderComponent
    },
    setup() {
        const store = useStore()

        const showAssignLearningPath = ref(false)
        const courseToAddLearningPath = ref(null)
        const courseRecommendations = ref(new Map())
        const openedAccordionIndex = ref(0)
        const isFetchingCourseRecommendationIndex = ref(null)
        const isLoading = ref(false)

        const finalStartMomentDate = computed(() => {
            return store.getters['accountModule/finalStartMomentDate']
        })

        const suggestions = computed(() => store.getters['userModule/aiAdvisor'])
        const fetchCourseRecommendation = async (suggestion, isOpen, index) => {
            if(!isOpen && !suggestion) {
                openedAccordionIndex.value = null
                return
            }
            if(courseRecommendations.value.has(suggestion) || !suggestion) {
                openedAccordionIndex.value = index
                return
            }
            try {
                isLoading.value = true
                isFetchingCourseRecommendationIndex.value = index

                const { data } = await courseClient.fetchSearch({
                    query: suggestion,
                    top: 5,
                    excludeFacets: true,
                    skipOleExtras: true,
                    includeFinalStartMomentDateFilter: !!finalStartMomentDate.value
                })

                courseRecommendations.value.set(suggestion, data.results)
            } catch (error) {
                throw new Error("Something went wrong when searching courses", { cause: error })
            } finally {
                isLoading.value = false
                isFetchingCourseRecommendationIndex.value = null
                openedAccordionIndex.value = index
            }
        }
        
        watchEffect(async () => {
            await fetchCourseRecommendation(suggestions.value?.[0], true, 0)
        })

        onMounted(async () => {
            await fetchCourseRecommendation(suggestions.value?.[0], true, 0)
        })
        
        const setCourseAndShowAssignLearningPath = courseId => {
            courseToAddLearningPath.value = courseId
            showAssignLearningPath.value = true
        }
        
        const closeAssignLearningPath = () => {
            showAssignLearningPath.value = false
            courseToAddLearningPath.value = null
        }

        return {
            suggestions,
            showAssignLearningPath,
            courseToAddLearningPath,
            courseRecommendations,
            isLoading,
            openedAccordionIndex,
            isFetchingCourseRecommendationIndex,
            SELFSEARCH,
            setCourseAndShowAssignLearningPath,
            closeAssignLearningPath,
            fetchCourseRecommendation
        }
    }
}
</script>
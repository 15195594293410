<template>
    <recess-layout-static>
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading" overlay="white" color="primary" is-fixed />
        </transition>

        <recess-divider />
        <h1>Wijzigen {{ learningResultText }}</h1>

        <p v-if="isOwnPEReminder">
            Pas op deze pagina uw eerder ingestelde herinnering aan. Als u uw vervaldatum wijzigt
            naar een datum in de toekomst die verder ligt dan de datum van vandaag plus de
            herinneringstermijn, dan ontvangt u pas weer herinneringen zodra de gekozen termijn weer
            bereikt is.
        </p>
        <p v-else>
            Pas op deze pagina de bestaande herinneringen van uw collega's aan. Als u een
            vervaldatum wijzigt naar een datum in de toekomst die verder ligt dan de datum van
            vandaag plus de herinneringstermijn, dan ontvangt uw collega weer herinneringen zodra de
            gekozen termijn weer bereikt is.
        </p>
        <transition name="fade">
            <div
                v-if="
                    $isAllowed('displayPermanentEducationDetails', peUsers) && !isFetchingPeReminder
                "
            >
                <section class="row">
                    <recess-input
                        ref="courseNewNameInput"
                        v-model="courseNewName"
                        :disabled="!$isAllowed('editPermanentEducation', peUsers)"
                        class="col-lg-4 col-md-6 col-12 mb-3 mb-md-0"
                        label-text="Ontwikkelactiviteit *"
                        placeholder-text="Ontwikkelactiviteit"
                        data-test="course-name-input"
                        :error-message="getErrorMessage('courseNewName')"
                        @input="resetAlerts"
                    />
                    <recess-input
                        :value="created"
                        class="col-lg-4 col-md-6 col-12 mb-3 mb-md-0"
                        label-text="Aangemaakt op"
                        data-test="pe-creation-date"
                        disabled
                    />

                    <recess-input
                        :value="peUsers.createdByUserFullName"
                        class="col-lg-4 col-md-6 col-12"
                        label-text="Toegevoegd door"
                        data-test="pe-created-by-user"
                        disabled
                    />

                    <recess-input
                        v-if="!isOwnPEReminder"
                        v-model="email"
                        class="col-lg-4 col-md-6 col-12 mt-3"
                        label-text="Medewerker e-mailadres"
                        disabled
                    />
                </section>

                <recess-divider show-line variant="large" />

                <section
                    v-if="
                        viewItems.length || $isAllowed('displayPermanentEducationDetails', peUsers)
                    "
                    class="mb-3"
                >
                    <h3>Behaalde resultaten</h3>

                    <recess-card variant="variant1">
                        <recess-button
                            data-test="create-sub-record-btn"
                            title="Nieuw resultaat"
                            variant="primary"
                            @click="showUploadCertificate = true"
                        />
                    </recess-card>

                    <recess-divider variant="xsmall" />

                    <recess-modal
                        :show-modal="showUploadCertificate"
                        @close="showUploadCertificate = false"
                    >
                        <template #modal-body>
                            <upload-sub-record-component show-upload @addSubRecord="addSubRecord" />
                        </template>
                    </recess-modal>

                    <template v-if="viewItems.length">
                        <div v-for="(subRecord, index) in items" :key="index">
                            <transition name="fade" mode="out-in">
                                <sub-record-component
                                    v-if="
                                        showItems &&
                                        $isAllowed('displayPEReminderSubRecord', subRecord)
                                    "
                                    :assigned-user-id="peUsers.userId"
                                    :sub-record="subRecord"
                                    :show-popup="true"
                                    :show-save-button="
                                        $isAllowed('editPermanentEducation', {
                                            userId: peUsers.userId,
                                            createdByUserId: subRecord.createdByUserId,
                                            isImported: subRecord.isImported
                                        })
                                    "
                                    :show-upload="
                                        $isAllowed('uploadPEReminderCertificate', {
                                            subRecord,
                                            assignedUserId: peUsers.userId
                                        })
                                    "
                                    :sub-record-index="index"
                                    data-test="pe-sub-record"
                                    @delete-sub-record="removeSubRecord"
                                    @save="updateSubRecord"
                                    @remove-certificate="removeCertificate"
                                />
                            </transition>
                        </div>
                    </template>
                </section>

                <section class="d-flex flex-column flex-md-row justify-content-md-between">
                    <recess-button
                        :url="backUrl"
                        title="Terug"
                        variant="tertiary"
                        class="w-100 w-md-auto"
                    />

                    <recess-button
                        v-if="$isAllowed('editPermanentEducation', peUsers)"
                        title="Opslaan"
                        variant="primary"
                        data-test="submit-btn"
                        class="w-100 w-md-auto mt-3 mt-md-0"
                        @click.native="updatePe"
                    />
                </section>

                <section v-if="isSuccess || errorMessage" class="mt-4">
                    <recess-alert
                        v-if="isSuccess"
                        type="success"
                        text="Uw herinnering is succesvol opgeslagen."
                    />
                    <recess-alert
                        v-if="errorMessage"
                        :class="{ 'mt-4': isSuccess }"
                        :type="errorMessage.type"
                        :text="errorMessage.message"
                    />
                </section>
            </div>
        </transition>
        <recess-divider />
    </recess-layout-static>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { scrollTo } from 'vue-scrollto'
import { errorMessages } from '../../../constants/errorMessages'
import routePerimeter from '../../../perimeters/routePerimeter'
import permanentEducationPerimeter from '../../../perimeters/permanentEducationPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import UploadSubRecordComponent from '../../atoms/UploadSubRecordComponent/UploadSubRecordComponent.vue'
import SubRecordComponent from '../../atoms/SubRecordComponent/SubRecordComponent.vue'
import { validationMessages } from '../../../modules/validationsHelper'
import StringUtils from '../../../utils/StringUtils'
import { allowedTextTags } from '../../../constants/htmlSanitizerConsts'

export default {
    name: 'EditPermanentEducation',
    components: {
        LoaderComponent,
        UploadSubRecordComponent,
        SubRecordComponent
    },
    perimeters: [routePerimeter, permanentEducationPerimeter],
    props: {
        fromActiveCourses: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showUploadCertificate: false,
            isSuccess: false,
            isError: false,
            showNoReminderText: false,
            isFetchingPeReminder: false,
            isEditingPeReminder: false,
            isUploadingDocument: false,
            isPostingSubRecord: false,
            isUpdatingSubRecord: false,
            showUploadError: false,
            showSuccessUploadMessage: false,
            removePESubRecordConfirmationVisibility: false,
            validationMessages,
            courseNewName: '',
            showItems: true
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId']),
        ...mapGetters('peModule', [
            'loading',
            'items',
            'courseName',
            'email',
            'created',
            'peUsers',
            'isPEDuplicate'
        ]),
        learningResultText() {
            return this.items?.length > 1 ? 'leerresultaten' : 'leerresultaat'
        },
        viewItems() {
            return this.showItems ? this.items : []
        },
        isLoading() {
            return (
                this.isFetchingPeReminder ||
                this.isEditingPeReminder ||
                this.isUploadingDocument ||
                this.isPostingSubRecord ||
                this.isUpdatingSubRecord
            )
        },
        isOwnPEReminder() {
            return this.peUsers?.userId === this.userId
        },
        backUrl() {
            if (this.fromActiveCourses) {
                return {
                    name: 'my-development',
                    query: { 'my-development-tab': 'active-courses' }
                }
            }

            if (this.isOwnPEReminder) {
                return {
                    name: 'my-development',
                    query: { 'my-development-tab': 'permanent-educations' }
                }
            }

            return {
                name: 'my-team',
                query: { 'my-team-tab': 'permanent-educations' }
            }
        },
        errorMessage() {
            if (this.isError)
                return {
                    type: 'error',
                    message:
                        'Oeps, er is iets mis gegaan met het opslaan. Probeer het later opnieuw.'
                }
            if (this.showNoReminderText)
                return { type: 'error', message: 'Er zijn (nog) geen leerresultaten ingesteld.' }
            if (this.isPEDuplicate)
                return { type: 'error', message: errorMessages.DuplicatePermanentEducation }

            // If user is not allowed to edit the leerresultaten, then create a message for that
            if (!this.$isAllowed('editPermanentEducation', this.peUsers)) {
                let message = `U bent niet bevoegd deze ${this.learningResultText} aan te passen. `
                // But if the current user is the assigned user for the leerresultaten, then he/she can always upload a certificate
                if (this.userId === this.peUsers?.userId)
                    message += `Wel kunt u uw behaalde diploma/certificaat uploaden en downloaden.`
                return { message }
            }
            return null
        }
    },
    validations() {
        if (!this.$isAllowed('editPermanentEducation', this.peUsers)) return { courseNewName: {} }
        return {
            courseNewName: { required }
        }
    },
    beforeDestroy() {
        this.resetState()
    },
    created() {
        this.fetchPermanentEducationReminder()
    },
    methods: {
        ...mapMutations('peModule', ['resetState', 'setIsPEDuplicate']),
        ...mapActions('peModule', [
            'loadById',
            'updateCourseName',
            'createPEItem',
            'deletePEItem',
            'deleteCertificate',
            'updatePEItem',
            'checkDuplicatePEName'
        ]),
        async updateSubRecord(item) {
            try {
                this.resetAlerts()
                this.isUpdatingSubRecord = true
                this.showItems = false
                await this.updatePEItem(item)
                this.triggerSuccess()
            } catch (error) {
                this.triggerError()
            } finally {
                this.isUpdatingSubRecord = false
                this.showItems = true
            }
        },
        resetAlerts() {
            this.isSuccess = false
            this.isError = false
            this.setIsPEDuplicate(false)
        },
        async addSubRecord(item) {
            try {
                this.resetAlerts()
                this.isPostingSubRecord = true
                this.showItems = false
                await this.createPEItem(item)
                this.triggerSuccess()
            } catch {
                this.triggerError()
            } finally {
                this.isPostingSubRecord = true
                this.showItems = true
            }
        },
        async fetchPermanentEducationReminder() {
            try {
                const peId = this.$route?.params?.id || null
                if (!peId) return
                await this.loadById(peId)
                this.courseNewName = this.courseName
            } catch (err) {
                this.showNoReminderText = true
                throw new Error(err)
            } finally {
                this.isFetchingPeReminder = false
            }
        },
        sanitizeFields() {
            if (this.courseNewName) {
                this.courseNewName = StringUtils.sanitizeHtmlText(
                    this.courseNewName,
                    allowedTextTags
                )
            }
        },
        async updatePe() {
            try {
                this.resetAlerts()
                this.sanitizeFields(this.courseNewName)
                this.$v.$touch()
                if (this.$v.$invalid) {
                    scrollTo(this.$refs.courseNewNameInput)
                    return
                }

                if (this.courseNewName !== this.courseName) {
                    await this.checkDuplicatePEName(this.courseNewName)
                    if (this.isPEDuplicate) return
                    await this.updateCourseName(this.courseNewName)
                }
                this.triggerSuccess()
            } catch (err) {
                this.triggerError()
            }
        },
        async removeSubRecord(id) {
            try {
                this.resetAlerts()
                await this.deletePEItem(id)
                this.triggerSuccess()
            } catch (err) {
                this.triggerError()
            }
        },
        async removeCertificate(cert) {
            try {
                this.resetAlerts()
                await this.deleteCertificate(cert)
                this.triggerSuccess()
            } catch (err) {
                this.triggerError()
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v[fieldName]
            const fieldValue = this[fieldName]
            // Check Required
            if (fieldValidation?.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }
            // Check Invalid
            if (fieldValidation?.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        },
        triggerError() {
            this.isError = true
            this.isSuccess = false
        },
        triggerSuccess() {
            this.isSuccess = true
            this.isError = false
            this.showUploadCertificate = false
        }
    }
}
</script>

import { getAiClient } from './apiClient'

/** @typedef {import('../models').CourseRecommendationRequest} CourseRecommendationRequest */

/**
 * Retrieve ai course recommendation topics
 * 
 * @param {CourseRecommendationRequest} courseRecommendationRequest 
 * @returns {Promise<string>}} 
 */
const getRecommendationTopics = async courseRecommendationRequest => {
    return (await getAiClient())
        .post(`/course-recommendation/topics`, courseRecommendationRequest)
        .then(res => res.data)
}

const getSynonyms = async query => {
    return (await getAiClient())
        .get(`/synonyms?searchTerm=${query}`)
        .then(res => res.data)
}

export default {
    getRecommendationTopics,
    getSynonyms
}
<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
    <div>
        <recess-divider :display-medium-viewport="false" :display-small-viewport="false" />
        <transition name="fade">
            <loader-component v-if="isFetchingActiveCourses" overlay="white" color="primary" />
        </transition>

        <table-filters-component
            :show-reset-filters="showResetButton"
            toggle-button-text="Filter gestarte ontwikkelactiviteiten"
            @reset-filters="resetFilters()"
        >
            <template #right>
                <recess-select
                    data-test="page-size-select"
                    :options="DEFAULT_PAGE_SIZE_OPTIONS"
                    :default-value="take"
                    @input="pageSizeChanged"
                />
            </template>

            <template #filters>
                <recess-input
                    v-for="(filter, key) in tableFilters"
                    :key="key"
                    v-model="filter.value"
                    :data-test="filter.tableConfiguration.dataTest"
                    :label-text="filter.tableConfiguration.labelText"
                    class="mb-3"
                    @keyup.enter.native="updateFilters()"
                />
            </template>
            <template #footer>
                <recess-button
                    title="Filter"
                    variant="primary"
                    data-test="filter-btn"
                    class="col-12 col-sm-auto"
                    @click="updateFilters()"
                />
            </template>
        </table-filters-component>

        <recess-divider />

        <div class="d-flex justify-content-between">
            <recess-tab-navigation name="my-team-active-courses">
                <recess-tab-navigation-item
                    v-for="value in TableTypes"
                    :key="value.key"
                    :tab-key="value.key"
                    :data-test="`my-team-active-courses-${value.key}-tab`"
                >
                    {{ value.text }}
                </recess-tab-navigation-item>
            </recess-tab-navigation>
        </div>

        <recess-divider />

        <transition name="fade">
            <div v-if="data?.length">
                <recess-table>
                    <recess-table-head>
                        <recess-table-row>
                            <recess-table-head-item
                                v-for="header in TABLE_HEADERS_CONFIG"
                                :key="header.key"
                                :sortable="header.sortable"
                                :sort-direction="getSortDirection(header.sortKey)"
                                @click.native="setSorting(header)"
                                >{{ header.displayValue }}
                            </recess-table-head-item>
                        </recess-table-row>
                    </recess-table-head>

                    <recess-table-body>
                        <recess-table-row
                            v-for="(activeCourse, index) in data"
                            :key="index"
                            :data-test="
                                activeCourse.eLearningSubscriptionId
                                    ? `eLearningSubscription-${activeCourse.eduMSCourseId}`
                                    : undefined
                            "
                        >
                            <recess-table-cell data-label="Medewerker">
                                <span>
                                    <b>{{ activeCourse.userName }}</b>
                                    <br />
                                    {{ activeCourse.userEmail }}
                                </span>
                            </recess-table-cell>

                            <recess-table-cell data-label="Ontwikkelactiviteit">
                                <router-link
                                    v-if="!activeCourse.deletedFromAssortment"
                                    :to="{
                                        name: 'course',
                                        query: {
                                            course: activeCourse.eduMSCourseId,
                                            assortment: activeCourse.assortmentId
                                        }
                                    }"
                                >
                                    <span v-html="getCourseNameAndProvider(activeCourse)"></span>
                                </router-link>

                                <span v-else v-html="getCourseNameAndProvider(activeCourse)"></span>
                            </recess-table-cell>

                            <recess-table-cell data-label="Startdatum">
                                {{ format(activeCourse.acceptedStartDate) }}
                                {{ activeCourse.location ? `(${activeCourse.location})` : '' }}
                            </recess-table-cell>

                            <recess-table-cell data-label="Status">
                                {{ getStatusDisplayValue(activeCourse) }}
                            </recess-table-cell>
                        </recess-table-row>
                    </recess-table-body>
                </recess-table>

                <recess-divider />

                <pagination-component
                    :current-page-number="pageIndex"
                    :total-pages="totalPages"
                    @goToPage="paginationClicked"
                />
            </div>
        </transition>

        <recess-alert
            v-if="showNoActiveCourseMessage"
            text="Er zijn op dit moment geen gestarte ontwikkelactiviteiten."
        />
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import PaginationComponent from '../../molecules/PaginationComponent/PaginationComponent.vue'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import enrollmentClient from '../../../api/enrollmentClient'
import eLearningSubscriptionClient from '../../../api/eLearningSubscriptionClient'
import DateUtils from '../../../utils/DateUtils'
import activeCourseStatus from '../../../constants/activeCourseStatus'
import useTableData from '../../../composables/useTableData'
import useRoute from '../../../composables/useRoute'
import useRouter from '../../../composables/useRouter'
import DataQueryBuilder from '../../../api/DataQuery/dataQueryBuilder'
import TableTypes from '../../../constants/myTeamActiveCoursesTypes'
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS
} from '../../../constants/tables/defaultTableSettings'
import {
    DEFAULT_TABLE_SORT,
    TABLE_QUERY_PARAM_KEY,
    TABLE_HEADERS_CONFIG
} from '../../../constants/tables/myTeamActiveCoursesSettings'
import TableFiltersComponent from './TableFiltersComponent.vue'
import TableFilterBuilder from '../../../utils/builders/TableFilterBuilder'
import dataQueryFilterOperator from '../../../api/DataQuery/dataQueryFilterOperator'
import DataQueryFilter from '../../../api/DataQuery/dataQueryFilter'

export default {
    components: { LoaderComponent, PaginationComponent, TableFiltersComponent },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const {
            data,
            skip,
            take,
            sort,
            pageIndex,
            totalPages,
            setSort,
            setTotalCount,
            setPageIndex,
            setPageSize,
            setData,
            setSkip,
            resetPagination,
            getSortDirection,
            toggleSorting
        } = useTableData({
            defaultPageSize: DEFAULT_PAGE_SIZE,
            defaultSort: DEFAULT_TABLE_SORT
        })
        const isFetchingActiveCourses = ref(false)
        const showNoActiveCourseMessage = ref(false)
        const tableFilters = ref(
            new TableFilterBuilder()
                .fullName(dataQueryFilterOperator.CONTAINS, {
                    labelText: 'Zoek op naam van de medewerker',
                    dataTest: 'filter-by-name-input'
                })
                .employeeNumber(dataQueryFilterOperator.CONTAINS, {
                    labelText: 'Zoek op personeelsnummer',
                    dataTest: 'filter-by-employee-number-input'
                })
                .email(dataQueryFilterOperator.CONTAINS, {
                    labelText: 'Zoek op e-mail',
                    dataTest: 'filter-by-email-input'
                })
                .department(dataQueryFilterOperator.CONTAINS, {
                    labelText: 'Zoek op organisatieonderdeel',
                    dataTest: 'filter-by-department-input'
                })
                .courseName(dataQueryFilterOperator.CONTAINS, {
                    labelText: 'Zoek op ontwikkelactiviteit',
                    dataTest: 'filter-by-course-input'
                })
                .build()
        )

        const tableType = computed(() => route.value.query[TABLE_QUERY_PARAM_KEY])
        const isElearningTable = computed(() => tableType.value === TableTypes.Elearnings.key)
        const showResetButton = computed(() =>
            Object.values(tableFilters.value).some((f) => !!f.value)
        )

        const getCourseNameAndProvider = (course) =>
            `<b>${course.courseName}</b><br />${course.providerName}`

        const getStatusDisplayValue = (course) =>
            activeCourseStatus[course.status]?.displayValue ?? ''

        async function fetchActiveCourses() {
            try {
                // Start loading
                isFetchingActiveCourses.value = true
                showNoActiveCourseMessage.value = false

                const dataQueryBuilder = new DataQueryBuilder().setPagination(
                    skip.value,
                    take.value
                )

                if (sort.value?.key && sort.value?.direction) {
                    dataQueryBuilder.setSort(sort.value.key, sort.value.direction)
                }

                const filters = Object.values(tableFilters.value)
                    .filter((f) => !!f.value)
                    .map((f) => new DataQueryFilter(f.key, f.operator, f.value))

                if (filters.length) {
                    dataQueryBuilder.addFilters(filters)
                }

                const dataQuery = dataQueryBuilder.build()
                const activeCoursesResponse = isElearningTable.value
                    ? await eLearningSubscriptionClient.getMyTeamActiveCourses(dataQuery)
                    : await enrollmentClient.getMyTeamActiveCourses(dataQuery)

                const activeCourses = activeCoursesResponse?.items
                setData(activeCourses)
                setTotalCount(activeCoursesResponse?.totalCount)
                setPageIndex(activeCoursesResponse?.pageIndex)
                setPageSize(activeCoursesResponse?.pageSize)

                if (!activeCourses?.length) {
                    showNoActiveCourseMessage.value = true
                }
            } catch (err) {
                throw new Error(err)
            } finally {
                isFetchingActiveCourses.value = false
            }
        }

        function paginationClicked(pageNumber) {
            setSkip(take.value * (pageNumber - 1))
            return fetchActiveCourses()
        }

        function pageSizeChanged(pageSize) {
            resetPagination(pageSize)
            return fetchActiveCourses()
        }

        function setSorting(header) {
            if (!header.sortable) return

            toggleSorting(header.sortKey)
            resetPagination()
            fetchActiveCourses()
        }

        function updateFilters() {
            resetPagination()
            fetchActiveCourses()
        }

        function resetFilters() {
            Object.values(tableFilters.value).forEach((f) => {
                f.value = ''
            })

            updateFilters()
        }

        onMounted(() => {
            if (Object.values(TableTypes).some((x) => x.key === tableType.value)) {
                fetchActiveCourses()
                return
            }

            router.replace({
                path: route.value.path,
                query: {
                    ...route.value.query,
                    [TABLE_QUERY_PARAM_KEY]: TableTypes.Enrollments.key
                }
            })
        })

        watch(tableType, () => {
            setData([])
            setPageIndex(1)
            setSkip(0)
            setSort(DEFAULT_TABLE_SORT)
            fetchActiveCourses()
        })

        return {
            TABLE_HEADERS_CONFIG,
            DEFAULT_PAGE_SIZE_OPTIONS,
            TableTypes,
            isFetchingActiveCourses,
            showNoActiveCourseMessage,
            data,
            take,
            skip,
            isElearningTable,
            pageIndex,
            totalPages,
            tableFilters,
            showResetButton,
            format: (e) => DateUtils.formatDate(e),
            getCourseNameAndProvider,
            getStatusDisplayValue,
            paginationClicked,
            pageSizeChanged,
            getSortDirection,
            setSorting,
            resetFilters,
            updateFilters
        }
    }
}
</script>

import { Perimeter } from 'vue-kindergarten'
import * as roles from '../constants/roles'
import * as approvalTypes from '../constants/approvalNeededTypes'

export default class BasePerimeter extends Perimeter {
    isAccountReporter() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.ACCOUNTREPORTER)
    }

    isCoordinator() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.COORDINATOR)
    }

    isTeamLeader() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.TEAMLEADER)
    }

    isSecondApprover() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.SECONDAPPROVER)
    }

    isLearningAndDevelopmentManager() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.LEARNANDDEVELOPEMENTMANAGER)
    }

    isCompanyManager() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.COMPANYMANAGER)
    }

    isAccountManager() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.ACCOUNTMANAGER)
    }

    isAccountAdmin() {
        return this.child && this.child.user && this.child.user.roles.includes(roles.ACCOUNTADMIN)
    }

    isAssignedSecondApprover(assignedSecondApproverId) {
        return this.child && this.child.user && this.child.user.userId === assignedSecondApproverId
    }

    isOwnEnrollment(userId) {
        return this.child && this.child.user && this.child.user.userId === userId
    }

    isSameCompany(enrollmentCompanyId) {
        return this.child && this.child.user && this.child.user.companyId === enrollmentCompanyId
    }

    isTeamLeaderOfCompany(enrollmentCompanyId) {
        return !!this.child?.user?.companyTeamLeaders?.find(ctl => ctl.companyId === enrollmentCompanyId)
    }

    isSameAccount(enrollmentAccountId) {
        return this.child && this.child.user && this.child.user.accountId === enrollmentAccountId
    }

    isDelegatee() {
        return this.child && this.child.user && this.child.user.activeDelegations && this.child.user.activeDelegations.length > 0
    }

    enrollmentIsDelegatedToUserAsFirstApprover(enrollmentId) {
        return this.child && this.child.userDelegationActions && !!this.child.userDelegationActions.find(da => da.enrollmentId === enrollmentId && da.approvalType === approvalTypes.FIRSTAPPROVAL)
    }

    enrollmentIsDelegatedToUserAsSecondApprover(enrollmentId) {
        return this.child && this.child.userDelegationActions && !!this.child.userDelegationActions.find(da => da.enrollmentId === enrollmentId && da.approvalType === approvalTypes.SECONDAPPROVAL)
    }

    isDevOrTstEnv() {
        return this.child && this.child.environment && (this.child.environment === 'dev' || this.child.environment === 'tst')
    }

    isCapableOfTeamLeading() {
        return this.isCoordinator() || this.isTeamLeader() || this.isSecondApprover() || this.isLearningAndDevelopmentManager()
    }

    isProjectInitiator() {
        return this.child?.user?.roles?.includes(roles.PROJECTINITIATOR)
    }

    isProjectApprover() {
        return this.child?.user?.roles?.includes(roles.PROJECTAPPROVER)
    }

    isAssignedProjectApprover(assignedProjectApproverId) {
        return this.child?.user?.userId === assignedProjectApproverId
    }
}

<template>
  <div>
    <transition name="fade" mode="out-in">
      <loader-component v-if="isLoadingData" color="primary" overlay="white" />
    </transition>

    <slot name="header" :project-request-listing-view="projectRequestListingView"></slot>

    <template v-if="showTabs">
      <project-request-listing-tab />
      <recess-divider />
    </template>

    <project-request-listing-filters
      :key="projectRequestListingView"
      :handle-filters-reset="handleFiltersReset"
      :filters="filters"
      :page-size="pageSize"
      :status-options="projectRequestStatusOptions"
      @on-filter-submit="handleFilterSubmit"
      @input:page-size="setPageSize($event)"
      @input:id="filters.id.value = $event && $event !== 0 ? +$event : ''"
      @input:name="filters.name.value = $event"
      @input:status="filters.status.value = $event"
      @input:user-full-name="filters.userFullName.value = $event"
      @input:user-email="filters.userEmail.value = $event"
    />

    <recess-divider />

    <project-request-listing-table
      v-if="totalCount > 0"
      :table-headers="tableHeaders"
      :is-fetching-project-requests="isFetchingProjectRequests"
      :project-requests="projectRequests"
      :total-pages="totalPages"
      :current-page="pageIndex"
      :listing-view="projectRequestListingView"
      :sort-field="sort"
      @on-pagination-click="handlePaginationClick"
      @on-table-sort="setSort"
    />

    <recess-alert
      v-if="noProjectRequests && !isError"
      text="Momenteel zijn er geen projectaanvragen."
    />
    <recess-alert
      v-if="isError"
      type="error"
      text="Er is een fout opgetreden. Probeer het later opnieuw."
    />
    <recess-divider />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import useRoute from '../../../../composables/useRoute'
import ProjectRequestListingFilters from './subComponents/ProjectRequestListingFilters.vue'
import ProjectRequestListingTable from './subComponents/ProjectRequestListingTable.vue'
import ProjectRequestListingTab from './subComponents/ProjectRequestListingTab.vue'
import useProjectRequestListing from '../../../../composables/useProjectRequestListing'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'

export default {
    components: {
        ProjectRequestListingFilters,
        ProjectRequestListingTable,
        ProjectRequestListingTab,
        LoaderComponent
    },
    setup() {
        const route = useRoute()
        const {
            noProjectRequests,
            projectRequests,
            getProjectRequests,
            projectRequestStatusOptions,
            getProjectRequestStatuses,
            isLoadingData,
            tableHeaders,
            sort,
            setSort,
            filters,
            handleFilterSubmit,
            handleFiltersReset,
            pageSize,
            setPageSize,
            handlePaginationClick,
            totalPages,
            pageIndex,
            totalCount,
            isError,
            showTabs,
            isFetchingProjectRequests,
            projectRequestListingView,
            initializeProjectRequestListingView
        } = useProjectRequestListing()

        onMounted(async () => {
            initializeProjectRequestListingView(route.value.query?.['project-requests-tab'])
            await getProjectRequestStatuses()
            await getProjectRequests()
        })

        return {
            projectRequests,
            filters,
            isLoadingData,
            tableHeaders,
            sort,
            handleFilterSubmit,
            pageSize,
            totalPages,
            pageIndex,
            setPageSize,
            handlePaginationClick,
            totalCount,
            isError,
            noProjectRequests,
            showTabs,
            projectRequestStatusOptions,
            isFetchingProjectRequests,
            projectRequestListingView,
            handleFiltersReset,
            setSort
        }
    }
}
</script>

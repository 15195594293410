export const postHints = [
    "Selecteer de juiste 'tags' zodat het duidelijk is in welke categorie uw bijdrage valt;",
    "Maak een korte duidelijke titel;",
    "Maak uw bijdrage zo informatief en begrijpelijk mogelijk zonder er een epistel van te maken;",
    "Geef een hint over het soort antwoord u zou willen krijgen;",
    "Indien uw bijlagen hebt (Ms Office) upload ze dan en/of voeg een link naar een audio of video fragment (indien van toepassing)."
]

export const postHintsTitle = "Hoe maakt u een veel gevonden bijdrage?"

export default {
    postHints,
    postHintsTitle
}
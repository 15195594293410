import axios from "axios"
import { YEARANDMONTH } from "../../constants/facetKeys"
import { ALLSTARTMOMENTSWITHDISTINCTION } from '../../constants/displayStartMomentsTypes'
import courseClient from "../../api/courseClient"
import startMomentHelper from "../../modules/startMomentHelper"

import SearchQueryBuilder from '../../api/OData/searchQueryBuilder'
import ODataQueryFilter from '../../api/OData/odataQueryFilter'
import ODataFilterOperations from '../../api/OData/odataFilterOperations'
import facetFilterHelper from '../../modules/facetFilterHelper'
import DateUtils from '../../utils/DateUtils'

const startMomentsModule = {
    namespaced: true,

    state() {
        return {
            isSearchingStartMoments: false,
            startMoments: [],
            orders: [],
            facets: [],
            top: 20,
            skip: 0,
            totalCount: 0,
            pageNumber: 1,
            courseId: null,
            assortmentId: null,
            selectedFacets: {},
            showNoStartMomentsMessage: false
        }
    },

    getters: {
        startMoments: state => state.startMoments,
        facets: state => state.facets,
        isLoading: state => state.isSearchingStartMoments,
        orders: state => state.orders,
        top: state => state.top,
        skip: state => state.skip,
        totalCount: state => state.totalCount,
        pageNumber: state => state.pageNumber,
        assortmentId: state => state.assortmentId,
        courseId: state => state.courseId,
        showNoStartMomentsMessage: state => state.showNoStartMomentsMessage
    },

    mutations: {
        RESET_STATE(state) {
            state.isSearchingStartMoments = false
            state.startMoments = []
            state.orders = []
            state.facets = []
            state.courseId = null
            state.assortmentId = null
            state.totalCount = 0
            state.selectedFacets = {}
            state.pageNumber = 1
            state.top = 20
            state.skip = 0
            state.totalCount = 0
        },
        SET_SHOW_NO_START_MOMENTS_MESSAGE(state, payload) {
            state.showNoStartMomentsMessage = payload
        },
        SET_TOP(state, payload) {
            state.top = payload
        },
        SET_SKIP(state, payload) {
            state.skip = payload
        },
        SET_PAGE_NUMBER(state, payload) {
            state.pageNumber = payload
        },
        SET_SELECTED_FACETS(state, payload) {
            state.selectedFacets = payload
        },
        SET_IS_SEARCHING_START_MOMENTS(state, payload) {
            state.isSearchingStartMoments = payload
        },
        SET_FACETS(state, payload) {
            state.facets = payload
        },
        SET_COURSE_ID(state, payload) {
            state.courseId = payload
        },
        SET_ASSORTMENT_ID(state, payload) {
            state.assortmentId = payload
        },
        SET_TOTAL_COUNT(state, payload) {
            state.totalCount = payload
        },
        SET_START_MOMENTS(state, payload) {
            state.startMoments = payload
        },
        SET_ORDERS(state, payload) {
            state.orders = payload
        },
        SET_ORDERS_PER_START_MOMENT(state, payload) {
            if(!state.startMoments?.length) return
            state.startMoments = startMomentHelper.addOrderTotalsToStartMoments(state.startMoments, payload)
        }
    },

    actions: {
        async initializeStartMomentsAndOrders({ commit, dispatch }, { courseId, assortmentId, shouldFetchOrders}) {
            await commit('SET_COURSE_ID', courseId )
            await commit('SET_ASSORTMENT_ID', assortmentId )

            await dispatch('searchStartMomentsAndOrders', shouldFetchOrders)
        },
        async goToPage({ getters, commit, dispatch }, { pageNumber, shouldFetchOrders }) {
            if(pageNumber) {
                const top = getters.top
                await commit('SET_PAGE_NUMBER', pageNumber)
                await commit('SET_SKIP', top * (pageNumber - 1))
            }

            await dispatch('searchStartMomentsAndOrders', shouldFetchOrders)
        },
        async searchStartMomentsAndOrders({ commit, dispatch }, shouldFetchOrders) {

            commit('SET_IS_SEARCHING_START_MOMENTS', true)

            await dispatch('searchStartMoments')

            if (shouldFetchOrders) {
                await dispatch('fetchOrdersForStartMoments')
            }

            commit('SET_IS_SEARCHING_START_MOMENTS', false)
        },
        async setFacetsAndSearchStartMoments({ commit, dispatch }, { selectedFacets, shouldFetchOrders }) {
         
            await commit('SET_PAGE_NUMBER', 1)
            await commit('SET_SKIP', 0)

            await commit('SET_SELECTED_FACETS', selectedFacets)
           
            await dispatch('searchStartMomentsAndOrders', shouldFetchOrders)
        },
        async searchStartMoments({ commit, rootGetters, state }) {
            try {
                if (!state.courseId && !state.assortmentId) return 
                commit('SET_SHOW_NO_START_MOMENTS_MESSAGE', false)
                
                courseClient.cancelSearchStartMoments()

                const dataQuery = new SearchQueryBuilder()
                    .setFacets([`yearAndMonth, count:9999, sort:value`, `city, count:9999, sort:value`])
                    .addFilter(new ODataQueryFilter('productReference', ODataFilterOperations.EQUALS, `course:${state.courseId}`))
                    .addFilter(new ODataQueryFilter('assortmentId', ODataFilterOperations.EQUALS, state.assortmentId))
                    .addDynamicFilter(`fullDate ge ${DateUtils.parseToISOString(DateUtils.today)}`)
                    .setTop(state.top)
                    .setSkip(state.skip)

                const orderBys = ['fullDate asc']

                const displayAllStartMomentsWithDistinction = rootGetters['accountModule/displayStartMomentsType'] === ALLSTARTMOMENTSWITHDISTINCTION
                if (displayAllStartMomentsWithDistinction) orderBys.unshift('startGuaranteed desc')
                
                dataQuery.setOrderBy(orderBys)

                const finalStartMomentDate = rootGetters['accountModule/finalStartMomentDate']
                if (finalStartMomentDate) {
                    const formattedFinalStartMomentDate = DateUtils.parseToISOString(DateUtils.formatDateTime(finalStartMomentDate, true))
                    dataQuery.addDynamicFilter(` fullDate lt ${formattedFinalStartMomentDate}`)
                }
                
                // Add facet filter 
                if(Object.keys(state.selectedFacets)?.length) {
                    Object.keys(state.selectedFacets).forEach(key => {
                        state.selectedFacets[key]?.forEach(selectedFacet => {
                            dataQuery
                                .addFilter(new ODataQueryFilter(
                                    key,
                                    ODataFilterOperations.EQUALS,
                                    key === YEARANDMONTH ? +selectedFacet.key : selectedFacet.key
                                ))
                        })
                    })
                }

                const response = await courseClient.searchStartMoments(dataQuery.build())

                commit('SET_TOTAL_COUNT', response?.count)
                commit('SET_START_MOMENTS', response?.results)
                
                const facetsWithTooltip = facetFilterHelper.addTooltipToFacets(response?.facets)
                commit('SET_FACETS', facetsWithTooltip)

                if(!response?.count) commit('SET_SHOW_NO_START_MOMENTS_MESSAGE', true)
            } catch (error) {
                if (!axios.isCancel(error)) {
                    commit('SET_SHOW_NO_START_MOMENTS_MESSAGE', true)
                    throw new Error(`Failed to search start moments with error: ${error}`)
                }
            }
        },
        async fetchOrdersForStartMoments({ commit, state }) {
            try {
               
                const response = await courseClient.getOrdersForCourse(state.courseId)
                const orders = response?.results?.map(x => x.document)

                commit('SET_ORDERS_PER_START_MOMENT', orders)
                commit('SET_ORDERS', orders)
            } catch (error) {
                throw new Error(`Failed to get orders per start moments with error: ${error}`)
            } 
        }
    }
}

export default startMomentsModule

<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <loader-component
      v-if="isFetchingLearningPaths"
      overlay="white"
      color="primary"
    />

    <div class="p-0 pl-md-4 col-12 col-md-8">
      <p>
        Op deze pagina kunt u leerpaden aanmaken, of reeds eerder aangemaakte leerpaden toewijzen aan uw teamleden.
      </p>

      <recess-button
        title="Maak nieuw leerpad"
        variant="primary"
        data-test="create-lp-btn"
        @click="openCreateLearningPathModal()"
      />
    </div>

    <recess-divider />

    <transition name="fade">
      <template v-if="learningPaths && learningPaths.length">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item
                v-for="(header,index) in headers"
                :key="index"
                :sortable="header.isSortable"
                :sort-direction="getSortDirection(header)"
                @click.native="setSorting(header)"
              >
                {{ header.title }}
              </recess-table-head-item>
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="learningPath in learningPaths"
              :key="learningPath.id"
            >
              <recess-table-cell :data-label="headers[0].title">
                <router-link
                  :to="{ name: 'edit-learning-path', params: {id: learningPath.id}}"
                >
                  {{ learningPath.title }}
                </router-link>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[1].title">
                <span>{{ learningPath.description ? cutOff(learningPath.description, 20) : "-" }}</span>
              </recess-table-cell>
              <recess-table-cell :data-label="headers[2].title">
                <span>{{ learningPath.utcDateCreated ? formatDate(learningPath.utcDateCreated) : "-" }}</span>
              </recess-table-cell>
              <recess-table-cell
                v-if="isCoordinator"
                :data-label="headers[3].title"
              >
                <span>{{ learningPath.createdByFullName }}</span>
              </recess-table-cell>
              <recess-table-cell class="d-flex align-items-center flex-column flex-md-row">
                <recess-button
                  variant="primary"
                  title="Toewijzen"
                  class="qa-assign-user-button u-min-width-140 w-100 w-md-auto"
                  @click.native="showAssignPathModal(learningPath.id, learningPath.hasRunningBackgroundProcess)"
                />
             
                <div class="d-flex mt-3 mt-md-0 pl-md-4">
                  <recess-icon
                    icon="cog"
                    class="qa-automate-icon u-z-index-1"
                    color="primary"   
                    @click="showAutomatePathModal(learningPath.id)"
                  />
                  <recess-divider
                    variant="medium"
                    vertical
                    show-line
                  />
                  <recess-icon
                    icon="edit"
                    class="qa-edit-icon u-z-index-1"
                    @click="$router.replace({ name: 'edit-learning-path', params: {id: learningPath.id}})"
                  />

                  <recess-divider
                    variant="medium"
                    vertical
                    show-line
                  />
                  <recess-icon
                    icon="bin"
                    class="qa-bin-icon u-z-index-1"
                    @click="showDeletePathModal(learningPath)"
                  />
                </div>
              </recess-table-cell>
              <!-- Empty cell to align the table on large viewPort -->
              <recess-table-cell class="d-none d-md-flex" />
            </recess-table-row>
          </recess-table-body>
        </recess-table>
      </template>
    </transition>
 
    <recess-alert
      v-if="showNoLearningPathMessage"
      text="Er zijn op dit moment geen leerpaden."
    />

    <assign-learning-path-user-component
      :show-modal="assignPath"
      :selected-path="assignedPathId"
      :assignment-blocked="assignmentBlocked"
      @update:showModal="assignPath = $event"
      @assigned="fetchLearningPaths()"
    />
    <delete-learning-path-confirmation
      :show-confirmation-message="showDeleteLearningPathModal"
      :learning-path="learningPathToDelete"
      @close:first-confirmation="showDeleteLearningPathModal = false"
      @deleted="closeModalAndFetch()"
    />
    <automate-learning-path-component 
      :show-modal="automatePath"
      :selected-path="automatePathId"
      @update:showModal="automatePath = $event"
    />
    <create-learning-path-component
      @close="fetchLearningPaths"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import AssignLearningPathUserComponent from '../LearningPath/AssignLearningPathUserComponent.vue'
import DeleteLearningPathConfirmation from '../LearningPath/DeleteLearningPathConfirmation.vue'
import AutomateLearningPathComponent from '../LearningPath/AutomateLearningPathComponent.vue'
import CreateLearningPathComponent from "../LearningPath/CreateLearningPathComponent.vue"
import { searchLearningPathsBasicInfo } from '../../../api/learningPathClient'
import DateUtils from '../../../utils/DateUtils'
import { cutOff } from '../../../modules/textFormatter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataSortingDirections from '../../../api/OData/odataSortingDirections'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import { COORDINATOR } from '../../../constants/roleIds'

export default {
    components: {
        AssignLearningPathUserComponent,
        LoaderComponent,
        DeleteLearningPathConfirmation,
        AutomateLearningPathComponent,
        CreateLearningPathComponent
    },
    data() {
        return {
            assignPath: false,
            assignedPathId: null,
            assignmentBlocked: false,
            automatePath: false,
            automatePathId: null,
            learningPaths: null,
            showNoLearningPathMessage: false,
            isFetchingLearningPaths: false,
            showDeleteLearningPathModal: false,
            sorting: {
                key: 'utcDateCreated',
                direction: ODataSortingDirections.DESC
            },
            learningPathToDelete: null
        }
    },
    computed: {
        ...mapGetters('userModule', ['userId', 'isCoordinator']),
        ...mapGetters('accountModule', ['accountId']),
        headers() {
            const headers = [
                { key: 'title', title: 'Naam', isSortable: true },
                { key: 'decription', title: 'Omschrijving' },
                { key: 'utcDateCreated', title: 'Aanmaakdatum', isSortable: true },
                {},
                {}
            ]

            if(this.isCoordinator) headers.splice(3, 0, { key: 'createdByFullName', title: 'Gemaakt door'})

            return headers
        }
    },
    created() {
        this.fetchLearningPaths()
    },
    methods: {
        buildDataQuery() {
            const dataQuery = new ODataQueryBuilder()
                .setSort(this.sorting.key, this.sorting.direction)
                .addFilter(
                    new ODataQueryFilter(
                        'createdById',
                        ODataFilterOperations.EQUALS,
                        this.userId
                    )
                )
                .addFilter(
                    new ODataQueryFilter(
                        'isPersonal',
                        ODataFilterOperations.EQUALS,
                        false
                    )
                )
          
            if(this.isCoordinator) {
                dataQuery.or()
                    .addFilter(
                        new ODataQueryFilter(
                            'onlyManageableByTheCreator',
                            ODataFilterOperations.EQUALS,
                            false
                        )
                    )
                    .addFilter(
                        new ODataQueryFilter(
                            'createdBy/accountId',
                            ODataFilterOperations.EQUALS,
                            this.accountId
                        )
                    )
                    .addDynamicFilter(`createdBy/userRoles/any(r: r/role/roleId eq ${COORDINATOR})`)
            }
                 
            return dataQuery.build()
        },
        async fetchLearningPaths() {
            try {
                this.isFetchingLearningPaths = true
                if (!this.userId) {
                    return
                }

                const dataQuery = this.buildDataQuery()
              
                this.showNoLearningPathMessage = false
                this.learningPaths = await searchLearningPathsBasicInfo(dataQuery)

                if(!this.learningPaths?.length) {
                    this.showNoLearningPathMessage = true
                }
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isFetchingLearningPaths = false
            }
        },
        setSorting(header) {
            if (!header.isSortable) return

            if (this.sorting.key === header.key) {
                this.sorting.direction =
                    this.sorting.direction === ODataSortingDirections.ASC
                        ? ODataSortingDirections.DESC
                        : ODataSortingDirections.ASC
            } else {
                this.sorting.direction = ODataSortingDirections.DESC
            }
            this.sorting.key = header.key
            this.fetchLearningPaths()
        },
        getSortDirection(header) {
            if (!header.key || !header.isSortable) return null

            if (this.sorting.key !== header.key) {
                return null
            }

            return this.sorting.direction ? this.sorting.direction.toLowerCase() : null
        },
        showAssignPathModal(pathId, hasRunningBackgroundProcess) {
            this.assignPath = true
            this.assignedPathId = pathId
            this.assignmentBlocked = hasRunningBackgroundProcess
        },
        showAutomatePathModal(pathId) {
            this.automatePath = true
            this.automatePathId = pathId
        },
        showDeletePathModal(learningPath) {
            this.showDeleteLearningPathModal = true
            this.learningPathToDelete = learningPath
        },
        async closeModalAndFetch() {
            this.showDeleteLearningPathModal = false
            await this.fetchLearningPaths()
        },
        async openCreateLearningPathModal() {
            await this.$router.replace({
                query: {...this.$route.query, open: 'nieuw-leerpad' },
                params: { isPersonal: false }
            })
        },
        formatDate: e => DateUtils.formatDate(e),
        cutOff
    }
}
</script>


<template>
  <div>
    <recess-divider />
    <recess-layout-static :size="1200">
      <transition
        name="fade"
        mode="out-in"
      >
        <loader-component
          v-if="isFetchingFaqCategories"
          overlay="white"
          color="primary"
        />
      </transition>
      <h1>Veelgestelde vragen</h1>
      <p>Hieronder zie je de veelgestelde vragen over studeren bij Opleiding zakelijk. Staat jouw vraag er niet bij? Neem dan contact met ons op via het telefoonnummer in de footer of mail ons.</p>
      
      <!-- Account specific questions -->
      <recess-card
        v-if="accountSpecificQuestions && accountSpecificQuestions.length"
        variant="variant1"
        class="mb-5"
        data-test="faq-account-specific"
      >
        <h2 class="mb-0">{{ account.name }} specifieke vragen</h2>
        <recess-divider show-line />
        <div
          class="row"
        >
          <div
            v-for="faqCategory in accountSpecificQuestions"
            :key="faqCategory.id"
            class="col-12 col-md-6"
          >
            <h3>{{ faqCategory.name }}</h3>
            <template v-if="faqCategory.frequentlyAskedQuestions">
              <div
                v-for="faq in faqCategory.frequentlyAskedQuestions"
                :key="faq.id"
              >
                <title-text-toggle-component
                  class="py-1"
                  :title="faq.question"
                  :text="faq.answer"
                />
              </div>
            </template>
            <recess-divider />
          </div>
        </div>
      </recess-card>
      <!-- Generic questions -->
      <recess-card
        v-if="genericQuestions && genericQuestions.length"
        variant="variant1"
        data-test="faq-generic"
      >
        <h2 class="mb-0">Algemene vragen</h2>
        <recess-divider show-line />
        <div
          class="row"
        >
          <div
            v-for="faqCategory in genericQuestions"
            :key="faqCategory.id"
            class="col-12 col-md-6"
          >
            <h3>{{ faqCategory.name }}</h3>
            <template v-if="faqCategory.frequentlyAskedQuestions">
              <div
                v-for="faq in faqCategory.frequentlyAskedQuestions"
                :key="faq.id"
              >
                <title-text-toggle-component
                  class="py-1"
                  :title="faq.question"
                  :text="faq.answer"
                />
              </div>
            </template>
            <recess-divider />
          </div>
        </div>
      </recess-card>
      
      <recess-alert
        v-show="showErrorMessage"
        type="error"
        text="Er zijn op dit moment geen veelgestelde vragen bij ons bekend."
      />
    </recess-layout-static>
    <recess-divider />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import faqCategoryClient from '../../../api/faqCategoryClient'
import TitleTextToggleComponent from '../../organisms/TitleTextToggleComponent/TitleTextToggleComponent.vue'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'

export default {
    name: 'Faq',
    components: {
        TitleTextToggleComponent,
        LoaderComponent
    },
    data() {
        return {
            faqCategories: [],
            isFetchingFaqCategories: false,
            showErrorMessage: false
        }
    },
    computed: {
        ...mapGetters('accountModule', ['account']),
        genericQuestions() {
            return this.faqCategories.filter(x => !x.accountId)
        },
        accountSpecificQuestions() {
            return this.faqCategories.filter(x => x.accountId === this.account?.accountId)
        }
    },
    mounted() {
        this.getFaqCategories()
    },
    methods: {
        getFaqCategories() {
            this.isFetchingFaqCategories = true

            const searchRequest = new ODataQueryBuilder()
                .addFilter(new ODataQueryFilter('accountId', ODataFilterOperations.EQUALS, [this.account?.accountId || null, null], null, 'or'))
                .build()

            faqCategoryClient
                .getFaqCategories(searchRequest)
                .then(response => {
                    this.faqCategories = response
                })
                .catch(() => {
                    this.showErrorMessage = true
                })
                .then(() => {
                    this.isFetchingFaqCategories = false
                })
        }
    }
}
</script>
import BasePerimeter from './BasePerimeter'

export default new BasePerimeter({
    purpose: 'delegate',

    can: {
        assignDelegatee() {
            return (
                (this.isTeamLeader() || this.isSecondApprover()) &&
              this.child?.accountSettings?.isDelegationEnabled
            )
        },
        checkDelegatedActions() {
            return this.isDelegatee() && this.child?.accountSettings?.isDelegationEnabled
        }
    }
})

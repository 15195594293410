import socialLearningClient from '../../api/socialLearningClient'

const socialLearningPostModule = {
    namespaced: true,
    state() {
        return {
            isFetchingPost: false,
            post: null
        }
    },
    getters: {
        post: state => state.post,
        isFetchingPost: state => state.isFetchingPost
    },
    mutations: {
        resetState(state) {
            state.isFetchingPost = false
            state.post = null
        }, 
        setIsFetchingPost(state, isFetchingPost) {
            state.isFetchingPost = isFetchingPost
        },
        setPost(state, post) {
            state.post = post
        }
    },
    actions: {
        async fetchPost({ commit }, id) {
            try {
                commit('setIsFetchingPost', true)
                const postDetails = await socialLearningClient.getPostById(id)                
                commit('setPost', postDetails)
                return postDetails
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('setIsFetchingPost', false)
            }
        }
    }
}

export default socialLearningPostModule

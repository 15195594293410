export default {
    NONE: {
        key: "NONE",
        displayValue: "Niet verplicht"
    },
    LEGAL: {
        key: "LEGAL",
        displayValue: "Wettelijk verplicht"
    },
    EMPLOYER: {
        key:  "EMPLOYER",
        displayValue: "Verplicht door werkgever"
    },
    SELECTEDBYAPPROVER: {
        key:"SELECTEDBYAPPROVER",
        displayValue: ""
    }
}
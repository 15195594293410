import { getOplzClient } from './apiClient'

/** @typedef {import('../models').PERequest} PERequest */
/** @typedef {import('../models').PEItemRequest} PEItemRequest */
/** @typedef {import('../models').PECertificateRequest} PECertificateRequest */
/** @typedef {import('../models').PermanentEducation} PermanentEducation */
/** @typedef {import('../models').PEItem} PEItem */
/** @typedef {import('../models').PaginationResult<PermanentEducation>} PESearchResult */

const baseUrl = '/permanent-educations'

/**
 * 
 * @param {any} res 
 * @returns 
 */
const processData = res => res?.data

/**
 * Create a new PE
 * 
 * @param {PERequest} permanentEducation 
 * @returns {Promise<PermanentEducation>}} 
 */
export const createPermanentEducation = async permanentEducation => {
    return (await getOplzClient())
        .post(`${baseUrl}`, permanentEducation)
        .then(processData)
}

/**
 * Create a new PEItem for an PE
 * 
 * @param {number} id 
 * @param {PEItemRequest} item 
 * @returns {Promise<PEItem>}
 */
export const createPermanentEducationItem = async (id, item) => {
    return (await getOplzClient())
        .post(`${baseUrl}/${id}/item`, item)
        .then(processData)
}

/**
 * Search for PEs using odata query
 * 
 * @param {string} dataQuery 
 * @returns {Promise<PESearchResult>}
 */
export const searchPermanentEducation = async dataQuery => {
    return (await getOplzClient())
        .post(`${baseUrl}/search`, dataQuery)
        .then(processData)
}

/**
 * return a PE for an id
 * 
 * @param {number} id 
 * @returns {Promise<PermanentEducation>}
 */
export const getPermanentEducationById = async id => {
    return (await getOplzClient())
        .get(`${baseUrl}/${id}`)
        .then(processData)
}

/**
 * Delete a PE and its associated items
 * 
 * @param {number} id 
 * @returns {Promise<boolean>} true, if success
 */
export const deletePermanentEducation = async id => {
    return (await getOplzClient())
        .delete(`${baseUrl}/${id}`)
        .then(processData)
}

/**
 * delete a PEItem
 * 
 * @param {number} itemId 
 * @returns {Promise<boolean>} true, if success
 */
export const deletePEItem = async itemId => {
    return (await getOplzClient())
        .delete(`${baseUrl}/item/${itemId}`)
        .then(processData)
}

/**
 * delete a PEItem Certificate
 * 
 * @param {number} itemId 
 * @param {string} filePath 
 * @returns {Promise<boolean>} true, if success
 */
export const deletePECertificate = async (itemId, filePath) => {
    return (await getOplzClient())
        .delete(`${baseUrl}/${itemId}/certification?path=${filePath}`)
        .then(processData)
}

/**
 * upload a certificate for an PEItem
 * 
 * @param {number} itemId 
 * @param {PECertificateRequest} fileUpload 
 * @returns 
 */
export const uploadCertificateForPEItem = async (itemId, fileUpload) => {
    return (await getOplzClient())
        .post(`${baseUrl}/${itemId}/certification`, fileUpload)
        .then(processData)
}

/**
 * update a PE
 * 
 * @param {PERequest} pe
 * @returns {Promise<PermanentEducation>}
 */
export const updatePermanentEducation = async pe => {
    const id = pe.permanentEducationId
    if (!id) throw new Error('id is required')
    return (await getOplzClient())
        .put(`${baseUrl}/${id}`, pe)
        .then(processData)
}

/**
 * update a PEItem
 * 
 * @param {PEItemRequest} peItem 
 * @returns {Promise<PEItem>}
 */
export const updatePEItem = async peItem => {
    const id = peItem.permanentEducationItemId
    if (!id) throw new Error('id is required')
    return (await getOplzClient())
        .put(`${baseUrl}/item/${id}`, peItem)
        .then(processData)
}

/**
 * toggle emails for leerresultaten
 * 
 * @param {number} id 
 * @returns {Promise<boolean>} true, if success
 */
export const togglePermanentEducationEmail = async id => {
    const client = await getOplzClient()
    const url = `${baseUrl}/${id}/toggleEmail`
    const res = await client.post(url, {})
    return res?.data
}

export default {
    createPermanentEducation,
    createPermanentEducationItem,
    searchPermanentEducation,
    getPermanentEducationById,
    deletePermanentEducation,
    deletePEItem,
    deletePECertificate,
    uploadCertificateForPEItem,
    updatePermanentEducation,
    updatePEItem,
    togglePermanentEducationEmail
}

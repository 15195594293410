<template>
  <div>
    <recess-modal 
      :show-modal="showEditLearningResultSharingConsentsModal"     
      @close="$emit('close')"
    >
      <template slot="modal-title">
        <h3>Instemming met delen van resultaten wijzigen</h3>
      </template>
      <template slot="modal-body">        
        <recess-divider variant="xsmall" />

        <learning-result-sharing-choice
          :allow-sharing="initialAgreesToShare"
          @change-sharing-consent-choice="value => agreesToShare = value"
        />

        <transition
          name="fade"
        >
          <div v-if="agreesToShare">
            <recess-divider variant="xsmall" />

            <learning-result-sharing-item
              v-for="(learningResultCategory, index) in learningResultCategories"
              :key="index"
              :default-options="selectedLearningResultOptions"
              :learning-result="learningResultCategory" 
              @input="(selectedOption) => setOptionValue(selectedOption)"
            />
          </div>
        </transition>

        <recess-divider />

        <div class="d-flex flex-column">
          <span class="u-text-small pb-2 pb-md-0"><strong>*</strong> {{ learningResultSharingConsentInfoText }}</span>
          <span class="u-text-small"><strong>**</strong> {{ learningResultSharingConsentsWarningText }}</span>
        </div>
      </template>
      <div slot="modal-footer"
      >
        <recess-button
          class="mr-6 mb-3 mb-md-0 col-12 col-md-auto"
          title="Annuleren"
          variant="tertiary"
          data-test="cancel-learning-result-consents-edit-button"
          @click.native="$emit('close')"
        />

        <recess-button
          variant="primary"
          title="Opslaan"
          data-test="update-learning-result-consents-button"
          class="u-position-right mb-3 mb-md-0 col-12 col-md-auto"
          @click.native="updateLearningResultSharingConsents"
        />

        <recess-alert
          v-if="showError"
          class="mt-3"
          type="error"
          text="Oeps, er is iets mis gegaan met het opslaan. Probeer het later opnieuw."
        />
      </div>
    </recess-modal>
  </div>
</template>

<script>

import { computed, onMounted, ref, watch } from 'vue'
import LearningResultSharingItem from './LearningResultSharingItem.vue'
import enrollmentClient from '../../../api/enrollmentClient'
import enrollmentLearningResultSharingOptions from '../../../constants/enrollmentLearningResultSharingOptions'
import * as enrollmentStatus from '../../../constants/enrollmentStatus'
import LearningResultSharingUtils from '../../../utils/LearningResultSharingUtils'
import LearningResultSharingChoice from '../../molecules/LearningResultSharing/LearningResultSharingChoice.vue'
import { learningResultSharingConsentInfo, learningResultSharingConsentWarning }  from '../../../constants/learningResultSharingConsentsWarningMessage'

export default {
    components: {
        LearningResultSharingItem,
        LearningResultSharingChoice
    },
    props: {
        showEditLearningResultSharingConsentsModal: {
            default: false,
            type: Boolean
        },
        enrollment: {
            default: null,
            type: Object
        }
    },
    setup(props, { emit }) {
        const learningResultSharingConsentInfoText = ref(learningResultSharingConsentInfo)
        const learningResultSharingConsentsWarningText = ref(learningResultSharingConsentWarning)
        const initialAgreesToShare = ref(props.enrollment?.enrollmentLearningResult?.agreesToShare)
        const agreesToShare = ref(undefined)
        const editedOptions = ref(null)
        const showError = ref(false)
        const mShowEditLearningResultSharingConsentsModal = ref(props.showEditLearningResultSharingConsentsModal)
        const mEnrollment = ref(props.enrollment)

        const allLearningResultSharingCategories = ref([
            enrollmentLearningResultSharingOptions.LEGAL,
            enrollmentLearningResultSharingOptions.EMPLOYER
        ])

        const enrollmentLearningResult = computed(() => {
            return mEnrollment.value?.enrollmentLearningResult
        })

        const learningResultSharingType = computed(() => {
            return LearningResultSharingUtils.mapLearningResultSharingType(mEnrollment.value?.enrollmentTeamLeaderLearningResult?.learningResultSharingType)
        })

        const showConsentsOnAllCategories = computed(() => {
            return mEnrollment.value?.statusId === enrollmentStatus.PENDINGAPPROVAL && learningResultSharingType.value === null
        })

        const learningResultCategories = computed(() => {
            if (showConsentsOnAllCategories.value)
                return allLearningResultSharingCategories.value

            return [ enrollmentLearningResultSharingOptions?.[learningResultSharingType.value] ]
        })

        const selectedLearningResultOptions = computed(() => {
            if (!initialAgreesToShare.value) return editedOptions.value

            if (showConsentsOnAllCategories.value) 
                return LearningResultSharingUtils.getStudentSelectedLearningResultSharingConsents(enrollmentLearningResult.value)

            return LearningResultSharingUtils.getStudentSelectedLearningResultSharingByType(learningResultSharingType.value, enrollmentLearningResult.value)
        })

        const isDirty = computed(() => {
            return initialAgreesToShare.value !== agreesToShare.value || Object.entries(editedOptions.value)?.some(([key, value]) => {
                return enrollmentLearningResult.value[key] !== value})
        })

        const updateLearningResultSharingConsents = async () => {
            try {
                showError.value = false
                emit('is-loading', true)

                if (isDirty.value) {
                    const  patchArray = [ ]
                    // If all of the consents for a category are false, then consider it as "No agreement to share"
                    if (agreesToShare.value && Object.values(editedOptions.value).every(value => !value)) {
                        agreesToShare.value = false
                    }

                    patchArray.push({
                        op: !enrollmentLearningResult.value ? 'add' : 'replace',
                        path: '/agreesToShare',
                        value: agreesToShare.value
                    })

                    if (!enrollmentLearningResult.value) {
                        Object.keys(editedOptions.value).forEach(key => {
                            patchArray.push({
                                op: 'add',
                                path: `/${key}`,
                                value: editedOptions.value[key]
                            })
                        })
                    }
                    else {
                        Object.keys(editedOptions.value).forEach(key => {
                            if(editedOptions.value[key] !== enrollmentLearningResult.value[key]) {
                                patchArray.push({
                                    op: 'replace',
                                    path: `/${key}`,
                                    value: editedOptions.value[key]
                                })
                            }
                        })
                    }                   
    
                    if (patchArray.length) {
                        await enrollmentClient.updateLearningResultSharingConsents(mEnrollment.value.enrollmentId, patchArray)
                        emit('reload')
                    }
                }
                
                emit('close')
            } catch (error) {
                showError.value = true
                throw new Error(`Something went wrong when updating consent with error: ${error}`)
            } finally {
                emit('is-loading', false)
            }
        }

        const setOptionValue = selectedOption => {
            editedOptions.value[selectedOption.optionKey] = selectedOption.isOptionChecked
        }
        
        const setDefaultLearningConsent = () => {
            editedOptions.value = showConsentsOnAllCategories.value ?
                LearningResultSharingUtils.getStudentSelectedLearningResultSharingConsents(enrollmentLearningResult.value) :
                LearningResultSharingUtils.getStudentSelectedLearningResultSharingByType(learningResultSharingType.value, enrollmentLearningResult.value)
        }
        
        const setAllSharingConsents = value => {
            Object.keys(editedOptions.value).forEach(key => {
                editedOptions.value[key] = value
            })
        }

        watch(
            agreesToShare,
            async newVal  => {
                if (newVal && !initialAgreesToShare.value) setAllSharingConsents(true)
                else if (newVal && initialAgreesToShare.value) setDefaultLearningConsent()
                else setAllSharingConsents(false)
            }
        )
        
        onMounted(() => {
            setDefaultLearningConsent()
        })

        return {
            learningResultSharingConsentInfoText,
            learningResultSharingConsentsWarningText,
            mShowEditLearningResultSharingConsentsModal,
            mEnrollment,
            initialAgreesToShare,
            agreesToShare,
            enrollmentLearningResult,
            learningResultSharingType,
            learningResultCategories,
            selectedLearningResultOptions,
            setOptionValue,
            updateLearningResultSharingConsents,
            editedOptions,
            showConsentsOnAllCategories,
            showError
        }
    }
}
</script>

<template>
  <div></div>
</template>

<script>
import { scrollTo } from 'vue-scrollto'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PEERFILTERS } from '../../../constants/facetKeys'
import * as searchTypes from '../../../constants/searchTypes'
import { decodeBase64, encodeBase64 } from "../../../modules/base64Helper"
import routePerimeter from '../../../perimeters/routePerimeter'

export default {
    perimeters: [ routePerimeter ],
    computed: {
        ...mapGetters("searchModule", ["searchFromTeamAssortments", "facets"]),
        ...mapGetters("accountModule", ["teamLeaderSelfSearchDisabled"])
    },
    watch: {
        facets: {
            handler(newVal) {
                // Make sure selected facets from the url exists in the current facets
                if(newVal?.length) {
                    const existingSelectedFacet = this.getDecodedFacetFiltersFromUrl()

                    existingSelectedFacet.forEach(ef => {
                        const updatedFacet = newVal.find(x => x.key === ef.key)
                        // Remove not existing selected facet from the url
                        ef.values = ef.values?.filter(x => updatedFacet.values?.some(uf => uf.key.toLowerCase() === x.toLowerCase()))
                    })

                    this.$router.replace({query: {...this.$route.query, facetFilters: encodeBase64(existingSelectedFacet) }})
                }
            },
            deep: true
        },
        $route(to, from) {
            if (
                to.query?.q !== from.query?.q ||
                to.query?.facetFilters !== from.query?.facetFilters
            ) {
                const searchFromTeamAssortments = this.searchFromTeamAssortments

                // reset the smart search when the search query changes
                const resetSmartSearch = to.query?.q !== from.query?.q
                this.resetState(resetSmartSearch)
                this.setSearchFromTeamAssortments(searchFromTeamAssortments)
                this.setQuery(to.query.q)
                this.addFacetFilters()
                this.fetchResults()
                scrollTo('body')
            }
        }
    },
    created() {
        this.initialize()
    },
    mounted() {
        this.initializeSessionProps()
    },
    beforeDestroy() {
        this.resetState()
    },
    methods: {
        ...mapActions('searchModule', ['fetchResults', 'fetchELearningLocations']),
        ...mapActions('userModule', ['fetchAssortments']),
        ...mapMutations('searchModule', [
            'setQuery',
            'resetState',
            'addFilter',
            'initializeSessionProps',
            'setSearchFromTeamAssortments',
            'setSelectedPeersFilter'            
        ]),
        getDecodedFacetFiltersFromUrl() {
            const encodedFacetFilters = this.$route.query.facetFilters

            if (!encodedFacetFilters?.length) return []
     
            return decodeBase64(encodedFacetFilters)
        },
        addFacetFilters() {
            try {
                const decodedFacetFilters = this.getDecodedFacetFiltersFromUrl()
        
                decodedFacetFilters?.map(filter => {
                    return filter.values.map(filterValue => {
                        // Peers facet is not showing when searching from team assortment so set them only when search from own assortment
                        if(filter.key === PEERFILTERS && !this.searchFromTeamAssortments) return this.setSelectedPeersFilter(filterValue)

                        return this.addFilter({
                            facetName: filter.key,
                            filterValue
                        })
                    })
                })
          
            } catch (error) {
                console.error(error)
                // Clear facet filter if invalid base64
                this.$router.replace({query: {...this.$route.query, facetFilters: undefined }})
            }
        },
        setSearchType() {
            // If teamleader self search is disabled the search from team assortment should always be true
            if(!this.teamLeaderSelfSearchDisabled) {
                const searchType = this.$route?.query?.searchType
                this.setSearchFromTeamAssortments(searchType === searchTypes.TEAMSEARCH)
            } else {  
                this.setSearchFromTeamAssortments(true)
            }
        },
        async initialize() {
            if(this.$isAllowed('routeAsTeamleader')) this.setSearchType()
            this.setQuery(this.$route.query.q)
            this.addFacetFilters()
            await Promise.all([this.fetchAssortments(), this.fetchResults(), this.fetchELearningLocations()])
        }
    }
}
</script>

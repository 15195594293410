<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
    <div v-if="enrollment">
        <h4 class="u-textcolor-primary">Gegevens inschrijving</h4>
        <table aria-label="Course name" class="w-100">
            <tbody>
                <tr>
                    <th scope="row" class="text-left pb-3">Ontwikkelactiviteit</th>
                    <td class="w-50 pb-3">
                        <span v-html="enrollment.courseName"></span>
                    </td>
                </tr>

                <tr v-if="!!enrollment.projectRequestId" data-test="project-request-name-id">
                    <th scope="row" class="text-left pb-3">Project</th>
                    <td class="w-50 pb-3"><span v-html="projectRequestNameId"></span></td>
                </tr>
            </tbody>
        </table>

        <enrollment-price-component
            v-if="$isAllowed('displayPricingInformation')"
            :enrollment="enrollment"
        />

        <table aria-label="Course detail" class="w-100">
            <tbody>
                <tr>
                    <th scope="row" class="text-left d-flex align-items-center">
                        <span>Eerste keuze</span>
                        <recess-tooltip v-if="!!firstChoice && !firstChoice.isValidStartMoment">
                            <recess-icon icon="danger" color="red" />
                            <template #content>
                                <span class="d-block u-max-width-500"
                                    >Dit startmoment bestaat niet (meer).</span
                                >
                            </template>
                        </recess-tooltip>
                    </th>
                    <td
                        :class="[
                            'w-50 py-3',
                            {
                                'u-textcolor-grey-medium':
                                    !!firstChoice && !firstChoice.isValidStartMoment
                            }
                        ]"
                    >
                        <template v-if="firstChoice">
                            <div>
                                {{ firstChoice.displayDate }}
                                <span v-if="firstChoice.displayDay"
                                    >({{ firstChoice.displayDay }})</span
                                >
                            </div>
                            <div>{{ firstChoice.location || '-' }}</div>
                        </template>
                        <span v-else>-</span>
                    </td>
                </tr>
                <tr v-if="firstChoice && firstChoice.firstEventDate">
                    <th scope="row" class="text-left pb-3">Eerste bijeenkomst</th>
                    <td class="pb-3">
                        <span>{{ firstChoice.firstEventDate }}</span>
                    </td>
                </tr>
                <tr v-if="firstChoice && firstChoice.lastEventDate">
                    <th scope="row" class="text-left pb-3">Laatste bijeenkomst</th>
                    <td class="pb-3">
                        {{ firstChoice.lastEventDate }}
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-left d-flex align-items-center">
                        <span>Tweede keuze</span>
                        <recess-tooltip v-if="!!secondChoice && !secondChoice.isValidStartMoment">
                            <recess-icon icon="danger" color="red" />
                            <template #content>
                                <span class="d-block u-max-width-500"
                                    >Dit startmoment bestaat niet (meer).</span
                                >
                            </template>
                        </recess-tooltip>
                    </th>
                    <td
                        :class="[
                            'pb-3',
                            {
                                'u-textcolor-grey-medium':
                                    !!secondChoice && !secondChoice.isValidStartMoment
                            }
                        ]"
                    >
                        <template v-if="secondChoice">
                            <div>{{ secondChoice.displayDate }}</div>
                            <div>
                                {{ secondChoice.displayDate }}
                                <span v-if="firstChoice.displayDay"
                                    >({{ secondChoice.displayDay }})</span
                                >
                            </div>
                            <div>{{ secondChoice.location || '-' }}</div>
                        </template>
                        <span v-else>-</span>
                    </td>
                </tr>
                <template v-if="enrollment.courseDetails">
                    <tr>
                        <th scope="row" class="text-left py-3">Opleider</th>
                        <td class="pb-3">{{ enrollment.courseDetails.providerName }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-left pb-3">Duur van de ontwikkelactiviteit</th>
                        <td class="pb-3">
                            {{ enrollment.courseDetails.durationValue }}
                            {{ enrollment.courseDetails.durationUnit }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-left pb-3">Duur van de studielast</th>
                        <td class="pb-3">
                            {{ courseStudyLoadDisplayValue }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-left pb-3">Leermethode</th>
                        <td class="pb-3">
                            {{ enrollment.courseDetails.learningMethodFormat }}
                            <span v-if="enrollment.courseDetails.learningMethodType">
                                ({{ enrollment.courseDetails.learningMethodType }})</span
                            >
                        </td>
                    </tr>
                </template>
                <tr>
                    <th scope="row" class="text-left pb-3">Laatste statuswijziging</th>
                    <td class="pb-3">
                        <template v-if="enrollment.currentStatus">
                            <div data-test="status-name">
                                {{ enrollment.currentStatus.displayValue }}
                            </div>
                            <div v-if="enrollment.currentStatus.dateTimeChanged">
                                {{ format(enrollment.currentStatus.dateTimeChanged) }}
                            </div>
                            <div v-if="enrollment.currentStatus.changedByFullName">
                                {{ enrollment.currentStatus.changedByFullName }}
                            </div>
                        </template>
                        <span v-else>-</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <recess-divider />

        <!-- Persoonlijke gegevens -->
        <h4 class="u-textcolor-primary">Persoonlijke gegevens</h4>
        <table aria-label="Personal detail" class="w-100">
            <tbody>
                <tr>
                    <th scope="row" class="text-left">Voornaam</th>
                    <td class="w-50" data-test="firstName">
                        {{ enrollment.firstName }}
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-left">Tussenvoegsel(s)</th>
                    <td data-test="middleName">{{ enrollment.middleName || '-' }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-left">Achternaam</th>
                    <td data-test="lastName">{{ enrollment.lastName }}</td>
                </tr>
                <template v-if="$isAllowed('displayEnrollmentPersonalData', enrollment)">
                    <tr v-if="$isAllowed('displayBirthDateField')">
                        <th scope="row" class="text-left">Geboortedatum</th>
                        <td data-test="birthDay">{{ format(enrollment.birthDay) }}</td>
                    </tr>
                    <tr v-if="$isAllowed('displayBirthPlaceField')">
                        <th scope="row" class="text-left">Geboorteplaats</th>
                        <td data-test="birthPlace">{{ enrollment.birthPlace }}</td>
                    </tr>
                </template>
            </tbody>
        </table>

        <recess-divider />

        <!-- Contactgegevens -->
        <h4 class="u-textcolor-primary">Contactgegevens</h4>

        <table aria-label="Contact detail" class="w-100">
            <tbody>
                <tr>
                    <th scope="row" class="text-left">E-mailadres</th>
                    <td class="w-50" data-test="email">
                        {{ enrollment.email }}
                    </td>
                </tr>
                <template v-if="$isAllowed('displayEnrollmentPersonalData', enrollment)">
                    <tr>
                        <th class="text-left" scope="row">Telefoonnummer</th>
                        <td data-test="phoneNumber">{{ enrollment.phoneNumber }}</td>
                    </tr>
                    <tr>
                        <th class="text-left" scope="row">Postcode</th>
                        <td data-test="postCode">{{ enrollment.addressPostalCode }}</td>
                    </tr>
                    <tr>
                        <th class="text-left" scope="row">Huisnummer</th>
                        <td data-test="houseNumber">
                            {{ enrollment.addressHouseNumber }}
                            {{ enrollment.addressHouseNumberAdditive }}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left" scope="row">Straat</th>
                        <td data-test="street">{{ enrollment.addressStreet }}</td>
                    </tr>
                    <tr>
                        <th class="text-left" scope="row">Plaats</th>
                        <td data-test="city">{{ enrollment.addressCity }}</td>
                    </tr>
                    <tr>
                        <th class="text-left" scope="row">Land</th>
                        <td data-test="country">{{ countryDisplayValue }}</td>
                    </tr>
                </template>
            </tbody>
        </table>

        <section v-if="$isAllowed('displayEnrollReason') && enrollReasonDisplayValue">
            <recess-divider />
            <enrollment-reason-summary
                :enroll-reason-display-value="enrollReasonDisplayValue"
                :enroll-reason-message="enrollment.enrollReasonMessage"
            />
        </section>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import userClient from '../../../../api/userClient'
import DateUtils from '../../../../utils/DateUtils'
import startMomentHelper from '../../../../modules/startMomentHelper'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import EnrollmentPriceComponent from '../EnrollmentPriceComponent/EnrollmentPriceComponent.vue'
import EnrollmentReasonSummary from '../../../atoms/EnrollmentReasonSummaryComponent/EnrollmentReasonSummary.vue'

export default {
    components: {
        EnrollmentPriceComponent,
        EnrollmentReasonSummary
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            countries: null
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollment', 'course', 'enrollReasons']),
        countryDisplayValue() {
            const country = this.countries?.find(
                (m) => m.value?.toLowerCase() === this.enrollment?.addressCountryCode?.toLowerCase()
            )
            return country ? country.displayValue : '-'
        },
        firstChoice() {
            if (!this.enrollment.eduMSFirstStartMomentId) return null

            if (this.course?.startMoments?.length) {
                const firstStartMoment = this.course?.startMoments?.find(
                    (sm) => sm?.externalId === this.enrollment.eduMSFirstStartMomentId
                )
                if (firstStartMoment) {
                    const mappedStartMoment = {
                        displayDate: this.getStartDate(firstStartMoment),
                        displayDay: firstStartMoment?.displayDayOfWeek,
                        location: firstStartMoment?.city,
                        isValidStartMoment: true
                    }

                    const sortedEvents = firstStartMoment.modules[0]?.events?.sort((a, b) =>
                        DateUtils.compare(a.eventDate, b.eventDate)
                    )
                    if (sortedEvents?.length) {
                        const firstEvent = sortedEvents[0]?.eventDate
                        const lastEvent =
                            sortedEvents.length > 1
                                ? sortedEvents[sortedEvents.length - 1]?.eventDate
                                : null

                        mappedStartMoment.firstEventDate = this.formatDateTime(firstEvent)
                        mappedStartMoment.lastEventDate = this.formatDateTime(lastEvent)
                    }

                    return mappedStartMoment
                }
            }

            return {
                displayDate: this.enrollment.courseDetails?.firstStartMomentDisplayDate || '-',
                displayDay: this.enrollment.courseDetails?.firstStartMomentDisplayDayOfWeek,
                location: this.enrollment.courseDetails?.firstStartMomentCity,
                firstEventDate: this.formatDateTime(this.enrollment.courseDetail?.firstEventDate),
                lastEventDate: this.formatDateTime(this.enrollment.courseDetail?.lastEventDate)
            }
        },
        secondChoice() {
            if (!this.enrollment.eduMSSecondStartMomentId) return null

            if (this.course?.startMoments?.length) {
                const secondStartMoment = this.course?.startMoments?.find(
                    (sm) => sm?.externalId === this.enrollment.eduMSSecondStartMomentId
                )
                if (secondStartMoment) {
                    return {
                        displayDate: this.getStartDate(secondStartMoment),
                        displayDay: secondStartMoment?.displayDayOfWeek,
                        location: secondStartMoment?.city,
                        isValidStartMoment: true
                    }
                }
            }

            return {
                displayDate: this.enrollment.courseDetails?.secondStartMomentDisplayDate || '-',
                displayDay: this.enrollment.courseDetails?.secondStartMomentDisplayDayOfWeek,
                location: this.enrollment.courseDetails?.secondStartMomentCity
            }
        },
        enrollReasonDisplayValue() {
            return this.enrollReasons?.find((x) => x.value === this.enrollment?.enrollReasonCode)
                ?.displayText
        },
        projectRequestNameId() {
            return `${this.enrollment?.projectRequestName} (${this.enrollment?.projectRequestId})`
        },
        courseStudyLoadDisplayValue() {
            if (!this.course?.learningMethod?.studyLoad?.value) return '-'

            return `${this.course.learningMethod.studyLoad.value} ${this.course.learningMethod.studyLoad.unitDisplayValue?.toLowerCase()}`
        }
    },
    async mounted() {
        const promises = [this.fetchCountries()]
        if (this.$isAllowed('displayEnrollReason')) promises.push(this.getEnrollReasons())
        await Promise.all(promises)
    },
    methods: {
        ...mapActions('enrollmentModule', ['getEnrollReasons']),

        async fetchCountries() {
            try {
                const coutriesResponse = await userClient.getCountries()

                if (coutriesResponse) this.countries = coutriesResponse
            } catch (error) {
                throw new Error(error)
            }
        },
        format: (d) => DateUtils.formatDate(d),
        formatDateTime: (d) => DateUtils.formatDateTime(d),
        getStartDate: startMomentHelper.getStartDate
    }
}
</script>

<template>
  <recess-modal
    data-test="enrollment-verification-thank-you-modal"
    :show-modal="thankYouModalVisibility"
    :show-close-icon="false"
  >
    <template slot="modal-title">
      <h3>Uw inschrijving is bevestigd!</h3>
    </template>
    <template slot="modal-body">
      <p>
        Dank u wel voor het controleren en/of aanvullen van uw gegevens! 
        Nu uw gegevens volledig en correct zijn kan de inschrijving (na eventuele interne 
        goedkeuring en/of goedkeuring op een eventuele studieovereenkomst) naar de opleider.
      </p>
    </template>
    <div
      slot="modal-footer"
      class="d-flex flex-column"
    >
      <div>
        <recess-button
          v-if="$isAllowed('displayHomeLink')"
          :url="{ name : 'home' }"
          variant="secondary"
          class="mr-0 mr-sm-3 d-block d-sm-inline-block"
          title="Terug naar home"
        />

        <recess-button
          v-if="$isAllowed('displayMyDevelopmentLink')"
          :url="{ name: 'my-development', query: { 'my-development-tab': 'enrollments' } }"
          variant="primary"
          title="Naar mijn inschrijvingen"
          class="d-block d-sm-inline-block mt-3 mt-sm-0"
        />
      </div>
    </div>
  </recess-modal>
</template>

<script>
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'

export default {
    perimeters: [featuresPerimeter],
    props: {
        thankYouModalVisibility: {
            type: Boolean,
            default: false
        }
    }
}
</script>
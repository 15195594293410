<template>
  <svg
    class="c-participants__participant"
    viewBox="0 0 46 116"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill-rule="evenodd"
    >
      <g id="participant">
        <path
          id="Shape"
          :class="['c-participants__icon', { 'filled' : filled }]"
          d="M23.096,0.5859 C17.888,0.5859 13.672,4.805 13.672,10.012 C13.673,15.215 17.889,19.436 23.096,19.436 C28.298,19.436 32.518,15.215 32.518,10.012 C32.518,4.804 28.298,0.5859 23.096,0.5859 L23.096,0.5859 Z M12.686,21.8539 C6.014,21.8539 0.555,27.2609 0.555,33.9239 L0.555,63.1539 C0.555,65.4289 2.346,67.2769 4.625,67.2769 C6.905,67.2769 8.752,65.4309 8.752,63.1539 L8.752,36.7989 L10.854,36.7989 C10.854,36.7989 10.902,105.6099 10.902,110.1299 C10.902,113.1799 13.38,115.6599 16.434,115.6599 C19.486,115.6599 21.959,113.1799 21.959,110.1299 L21.959,67.5489 L24.229,67.5489 L24.229,110.1299 C24.229,113.1799 26.702,115.6599 29.76,115.6599 C32.814,115.6599 35.309,113.1799 35.309,110.1299 L35.309,36.7989 L37.436,36.7989 L37.436,63.1539 C37.436,65.4289 39.286,67.2769 41.562,67.2769 C43.842,67.2769 45.635,65.4309 45.635,63.1539 L45.635,33.9239 C45.635,27.2609 40.172,21.8539 33.506,21.8539 L12.686,21.8539 L12.686,21.8539 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    props: {
        filled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
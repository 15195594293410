<template>
  <div data-test="alternative-billing-address">
    <table
      v-for="info of viewData"
      :key="info.label"
      :aria-label="info.label"
      class="c-enrollment-confirmation__table"
    >
      <tbody>
        <tr
          v-for="row of info.data"
          :key="row[0]"
        >
          <th
            scope="row"
            class="c-enrollment-confirmation__column-label"
          >
            {{ row[0] }}
          </th>
          <td class="c-enrollment-confirmation__column-value">{{ row[1] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props: {
        formData: { type: Object, required: true }
    },
    computed: {
        personalInfo() {
            return [
                ['Voornaam', this.formData.firstName],
                ['Tussenvoegsel(s)', this.formData.middleName],
                ['Achternaam', this.formData.lastName]
            ]
        },
        contactInfo() {
            return [
                ['E-mailadres', this.formData.emailAddress],
                ['Telefoonnummer', this.formData.phoneNumber]
            ]
        },
        billingAddress() {
            return [
                ['Bedrijfsnaam', this.formData.companyName],
                ['Postcode', this.formData.postalCode],
                ['Straat', this.formData.street],
                ['Huisnummer', this.formData.number],
                ['Huisnummer toevoeging', this.formData.numberAdditive],
                ['Plaats', this.formData.city],
                ['Land', this.formData.country]
            ]
        },
        viewData() {
            return [{
                label: 'Persoonlijke informatie',
                data: this.personalInfo
            }, {
                label: 'Contact informatie',
                data: this.contactInfo
            }, {
                label: 'Factuuradres',
                data: this.billingAddress
            }]
        }
    }
}
</script>

import get from 'lodash/get'
import { getOplzClient } from './apiClient'

const baseUrl = '/report'

const generateReport = async reportType => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${reportType}`).then(response => {
        return get(response, 'data', null)
    })
}

const refreshStatus = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/status`).then(res => res.data)
}

export default {
    generateReport,
    refreshStatus
}

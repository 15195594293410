<template>
  <figure v-if="(providerLogoUrl || providerName)">
    <img
      v-if="providerLogoUrl"
      alt="Provider logo"
      class="c-search-result__item-provider-logo"
      :src="providerLogoUrl"
      data-test="provider-logo"
    />
    <figcaption
      v-else
      data-test="provider-name"
      class="c-search-result__item-provider-name u-text-small font-weight-bold"
    >
      {{ cutOff(providerName, 20) }}
    </figcaption>
  </figure>
</template>

<script>
import { cutOff } from '../../../../modules/textFormatter'

export default {
    props: {
        providerLogoUrl: {
            type: String,
            default: null
        },
        providerName: {
            type: String,
            default: null
        }
    },
    setup() {
        return {
            cutOff
        }
    }
}
</script>
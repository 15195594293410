<template>
  <recess-layout-static v-if="$isAllowed('displayProjectRequest') && $isAllowed('routeProjectRequestsPage')" :size="1700">
    <recess-divider />
    <project-request-listing>
      <template #header="{ projectRequestListingView }">
        <template v-if="projectRequestListingView == projectRequestListingViews.PROJECTINITIATOR">
          
          <h1>Mijn projectaanvragen</h1>
          <p>Op deze pagina ziet u alle projectaanvragen. Via de knop 'Maak een nieuwe projectaanvraag' kunt u een nieuwe projectaanvraag toevoegen.</p>
          
          <recess-button
            :url="{ name: 'create-project-request' }"
            title="Maak een nieuwe projectaanvraag"
            variant="primary"
            class="u-min-width-140"
          />

          <recess-divider />
        </template>
        <template v-else-if="projectRequestListingView == projectRequestListingViews.PROJECTAPPROVER">

          <h1>Projectaanvragen</h1>
          <p>Op deze pagina ziet u alle ingediende projectaanvragen die beoordeeld dienen te worden en/of al beoordeeld zijn.</p>
        
        </template>
      </template>
    </project-request-listing>
  </recess-layout-static>
</template>
  
<script>
import projectRequestPerimeter from '../../../perimeters/projectRequestPerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import ProjectRequestListing from "../../organisms/ProjectRequests/ProjectRequestListing/ProjectRequestListing.vue"
import projectRequestListingViews from '../../../constants/projectRequestListingViews'

export default {
    components: {
        ProjectRequestListing
    },
    perimeters: [featuresPerimeter, projectRequestPerimeter, routePerimeter],
    setup() {
        return {
            projectRequestListingViews
        }
    }
}
</script>
  
  
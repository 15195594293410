import { getOplzClient } from './apiClient'

const baseRoute = '/budgets'

export const createBudget = async budget => {
    const client = await getOplzClient()
    return client.post(baseRoute, budget).then(response => response?.data)
}

export const getBudgetById = async budgetId => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/${budgetId}`).then(response => response?.data)
}

export const searchBudgets = async oDataQuery => {
    const client = await getOplzClient()
    return client.post(`${baseRoute}/search`, oDataQuery).then(response => response?.data)
}

export const editBudget = async (jsonPatchDocument, budgetId) => {
    const client = await getOplzClient()
    return client.patch(`${baseRoute}/${budgetId}`, jsonPatchDocument).then(response => response?.data)
}

export const deleteBudget = async budgetId => {
    const client = await getOplzClient()
    return client.delete(`${baseRoute}/${budgetId}`).then(response => response?.data)
}

export const getBudgetChanges = async budgetId => {
    const client = await getOplzClient()
    return client.get(`${baseRoute}/${budgetId}/budget-changes`).then(response => response?.data)
}

export default {
    createBudget,
    getBudgetById,
    searchBudgets,
    editBudget,
    deleteBudget,
    getBudgetChanges
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._l((_vm.suggestions),function(suggestion,index){return _c('recess-accordion',{key:`${index} - ${suggestion}`,staticClass:"mt-2",attrs:{"active":_vm.openedAccordionIndex === index,"is-disabled":_vm.isLoading},on:{"toggled":isOpen => _vm.fetchCourseRecommendation(suggestion, isOpen, index)},scopedSlots:_vm._u([{key:"accordion-title",fn:function(){return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(index === _vm.isFetchingCourseRecommendationIndex)?_c('loader-component',{attrs:{"color":"primary","variant":"small","overlay":"white"}}):_vm._e()],1),_c('span',[_vm._v("Suggesties voor "),_c('router-link',{attrs:{"to":{ 
            name: 'search',
            query: {
              q: suggestion,
              searchType: _vm.SELFSEARCH
            }
          }}},[_c('strong',[_vm._v(_vm._s(suggestion))])])],1)]},proxy:true},{key:"accordion-content",fn:function(){return [_c('transition',{attrs:{"name":"fate","mode":"out-in"}},[(_vm.courseRecommendations.has(suggestion) && _vm.courseRecommendations.get(suggestion).length && _vm.openedAccordionIndex === index)?_c('ai-course-suggestions-carousel',{attrs:{"courses":_vm.courseRecommendations.get(suggestion)},on:{"toggle-assign-lp":id => _vm.setCourseAndShowAssignLearningPath(id)}}):_vm._e()],1),_c('transition',{attrs:{"name":"fate","mode":"out-in"}},[((!_vm.courseRecommendations.has(suggestion) || !_vm.courseRecommendations.get(suggestion).length) && !_vm.isLoading)?_c('recess-alert',{attrs:{"text":"Voor de voorgestelde ontwikkelrichting zijn er op dit moment geen ontwikkelactiviteiten beschikbaar in uw assortiment."}}):_vm._e()],1)]},proxy:true}],null,true)})}),_c('assign-learning-path-course-component',{staticClass:"u-cursor-default",attrs:{"show-assign-learning-path":_vm.showAssignLearningPath,"course-id":_vm.courseToAddLearningPath},on:{"close":function($event){return _vm.closeAssignLearningPath()}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
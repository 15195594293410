<template>
  <div  v-if="currentComponent"   >
    <component 
      :is="currentComponent"
      v-on="{ ...$listeners }" 
    />
  </div>
</template>

<script>
import { computed } from 'vue'
import useStore from '../../../../composables/useStore'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import EnrollmentStepLearningResultSharingStudent from './LearningResultSharing/EnrollmentStepLearningResultSharingStudent.vue'
import EnrollmentStepLearningResultSharingTeamLeader from './LearningResultSharing/EnrollmentStepLearningResultSharingTeamLeader.vue'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'

export default {
    components: {
        EnrollmentStepButtons
    },
    setup() {
        const store = useStore()

        const currentComponent = computed(() => {
            if(store?.getters?.['enrollmentModule/enrollmentType'] === enrollmentTypes.TEAMLEADER)
                return EnrollmentStepLearningResultSharingTeamLeader
            if(store?.getters?.['enrollmentModule/enrollmentType'] === enrollmentTypes.STUDENT)
                return EnrollmentStepLearningResultSharingStudent
                
            return null
        })

        return {
            currentComponent
        }
    }
}
</script>
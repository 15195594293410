<template>
  <div>
    <template v-if="showCoursePage">
      <recess-button
        variant="tertiary"
        title="Terug naar zoekresultaten"
        class="mr-3 mb-3 mb-md-0 col-12 col-md-auto"
        @click.native="goBackToSearchResults()"
      />
      <recess-divider />
      <course-details
        @course-selected="setSelectedCourse"
        @next-step="$emit('next-step')"
      />
    </template>
    <template v-else>
      <course-search-results @course-selected="initializeCourse" />
      <enrollment-step-buttons :show-next-button="false" v-on="$listeners" />
    </template>
    <recess-divider />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import CourseDetails from './Courses/CourseDetails.vue'
import CourseSearchResults from './Courses/CourseSearchResults.vue'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'
import * as courseLearningMethodTypes from '../../../../constants/courseLearningMethodTypes'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import EnrollmentSearchStartMomentsMixin from '../../../../mixins/enrollment-search-start-moments-mixin'

export default {
    name: "EnrollmentStepSelectCourse",
    components: { 
        CourseSearchResults, 
        CourseDetails,
        EnrollmentStepButtons
    },
    mixins: [ EnrollmentSearchStartMomentsMixin ],
    data() {
        return {
            showCoursePage: false
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollmentType', 'course', 'courseAssortmentId'])
    },
    watch: {
        $route(to, from) {
            if (to.query.course !== from.query.course) {
                this.showCoursePage = true
            }

            if (!to.query.course) {
                this.showCoursePage = false
            }
        }
    },
    created() {
        this.renderCoursePage()
    },
    mounted() {
        if (this.enrollmentType === enrollmentTypes.TEAMLEADER)
            this.$gtag.pageview({ page_path: '/inschrijven-teamlead-cursus' })
    },
    methods: {
        ...mapMutations({
            resetStartMomentsState: 'startMomentsModule/RESET_STATE'
        }),
        ...mapActions('enrollmentModule', ['getCourse', 'checkApprovalRequired']),
        ...mapMutations('enrollmentModule', ['setCourse', 'clearStartMoments', 'setIsELearning']),
        async initializeCourse(id, assortmentId) {
            try {
                await this.getCourse(id)
                this.$emit('next-step')
                await this.initializeStartMoments()
                this.$router.replace({
                    query: {
                        ...this.$route.query, 
                        course: id, 
                        assortment: assortmentId 
                    } 
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        async setSelectedCourse(course) {
            if (this.course && this.course?.id !== course.id) {
                this.resetStartMomentsState()
                this.clearStartMoments()
            }
            
            this.setIsELearning(course?.learningMethod?.type?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED)
            this.setCourse(course)
            if (!course?.startMoments?.length) {
                await this.checkApprovalRequired()
            }
            
            this.$emit('next-step')
        },
        renderCoursePage() {
            if (this.$route.query.course) this.showCoursePage = true
        },
        goBackToSearchResults() {
            this.clearStartMoments()
            this.resetStartMomentsState()

            this.$router.replace({ query: { course: undefined } })
        }
    }
}
</script>

import perimeterHelper from './perimeterHelper'
import featuresPerimeter from '../perimeters/featuresPerimeter'
import enrollmentPerimeter from '../perimeters/enrollmentPerimeter'

/**
 * Get price key
 * 
 * @param {boolean} useNetPrice  
 * @returns {'TotalAmount' | 'TotalAmountWithoutVAT'} 
 */
const totalAmountWithOrWithoutVatKey = useNetPrice => {
    return useNetPrice ? 'TotalAmount' : 'TotalAmountWithoutVAT'
}

/**
 * Check if header cell should be displayed
 * 
 * @param {string} headerKey  
 * @returns {boolean} 
 */
const displayHeaderCell = (headerKey, useNetPrice) => {
    const sandbox = perimeterHelper.sandboxFactory([featuresPerimeter])
    return headerKey === totalAmountWithOrWithoutVatKey(useNetPrice) ? sandbox.isAllowed('displayPricingInformation') : true
}

/**
 * Check if approve button should be displayed
 * 
 * @param {import('../models').enrollment} enrollment   
 * @returns {boolean} 
 */
const showApproveButton = enrollment => {
    const sandbox = perimeterHelper.sandboxFactory([enrollmentPerimeter])
    return sandbox.isAllowed('approveEnrollmentAsFirstApprover', enrollment) || sandbox.isAllowed('approveEnrollmentAsSecondApprover', enrollment)
}

export default {
    displayHeaderCell,
    showApproveButton,
    totalAmountWithOrWithoutVatKey
}
<template>
  <article class="c-search-result__actions d-flex">
    <section class="c-search-result__actions-item" @click.prevent.stop>
      <wish-list-toggle-component
        :course-id="course.id"
        :is-on-wish-list="isOnWishlist"
        :is-column-view="isColumnView"
        :is-small-view="isSmallView"
        @wish-list-toggled="updateUserWishList"
      />
    </section>

    <section
      v-if="$isAllowed('displayLearningPath')"
      class="c-search-result__actions-item"
      data-test="add-learning-path-btn"
      @click.prevent.stop
      @click="toggleAssignLearningPath()"
    >
      <recess-icon
        has-hover
        icon="plus"
        class="c-search-result__actions-item__icon"
      >
        <span class="u-text-small c-search-result__actions-item__icon--label">Leerpad</span>
      </recess-icon>
    </section>

    <section
      v-if="$isAllowed('displayShareCourse') && showShareAction"
      class="c-search-result__actions-item"
      data-test="recommend-course-btn"
      @click.prevent.stop
      @mouseover="showEmptyShareIcon = false"
      @mouseleave="showEmptyShareIcon = true"
      @touchstart.prevent="$emit('toggle-course-recommendation')"
      @click="$emit('toggle-course-recommendation')"
    >
      <recess-icon
        has-hover
        :icon="showEmptyShareIcon ? 'share-empty' : 'share-full'"
        class="c-search-result__actions-item__icon"
      >
        <span class="u-text-small c-search-result__actions-item__icon--label">Delen</span>
      </recess-icon>
    </section>

    <section 
      v-if="isColumnView && !isEnrollmentFlow && showCompareAction" 
      class="d-none d-md-block"  
      @click.stop
    >
      <recess-checkbox-input
        class="d-none d-md-block pl-3 pr-1 pt-1"
        :default-checked="isCourseToCompareChecked"
        data-test="compare-course-input"
        :disabled="!isCourseToCompareChecked && coursesToCompareCount >= 3"
        @input="() => toggleCourseIdToCompare()"
      />
      <span class="u-text-small c-search-result__actions-item__icon--label">Vergelijk</span>
    </section>

  </article>
</template>

<script>
import { ref } from 'vue'
import useCourseSearchItem from '../../../../composables/useCourseSearchItem'
import WishListToggleComponent from '../../WishListToggleComponent/WishListToggleComponent.vue'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'

export default {
    perimeters: [ featuresPerimeter ],
    components: {
        WishListToggleComponent
    },
    props: {
        course: {
            type: Object,
            default: () => {}
        },
        showShareAction: {
            type: Boolean,
            default: true
        },
        showCompareAction: {
            type: Boolean,
            default: true
        },
        isSmallView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const {
            isOnWishlist,
            isColumnView,
            isEnrollmentFlow,
            isCourseToCompareChecked,
            coursesToCompareCount,
            updateUserWishList,
            toggleCourseIdToCompare
        } = useCourseSearchItem(props.course)

        const showEmptyShareIcon = ref(false)

        return {
            isOnWishlist,
            isColumnView,
            showEmptyShareIcon,
            isEnrollmentFlow,
            isCourseToCompareChecked,
            coursesToCompareCount,
            updateUserWishList,
            toggleCourseIdToCompare
        }
    },
    methods: {
        toggleAssignLearningPath() {
            this.$gtag.event('add_to_learning_path', {
                course_id: this.course.id,
                course_name: this.course.title,
                external_id: this.course.externalId
            })
            
            this.$emit('toggle-assign-lp', this.course.id)
        }
      
    }
}
</script>
<template>
  <recess-layout-static v-if="$isAllowed('displayBudgets')">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <recess-divider />

    <h1>Toewijzen budget</h1>
    <p>Op deze pagina kunt u een nieuw budget toewijzen aan een gebruiker. De velden met een * zijn verplicht.</p>

    <div
      class="row"
    >
      <div class="col-lg-4 col-md-6 col-12 mb-md-3">
        <recess-autocomplete
          v-model="budgetUserEmail"
          placeholder="Naam of e-mail"
          data-test="user-email"
          label-text="Werknemer *"
          item-text="email"
          item-sub-text="name"
          :error-message="$v.budgetUserEmail.$error ? 'Start met typen en kies een gebruiker uit de lijst.' : ''"
          :on-query="queryBudgetUser"
        />
      </div>

      <recess-input
        v-model="budget.name"
        class="col-lg-4 col-md-6 col-12"
        placeholder-text="Naam budget"
        label-text="Naam budget *"
        data-test="name-input"
        :error-message="getErrorMessage('name')"
        @input="resetAlerts"
      />

      <recess-input
        v-model="budget.startAmount"
        class="col-lg-4 col-md-6 col-12"
        placeholder-text="Te besteden bedrag"
        type="number"
        label-text="Te besteden bedrag *"
        data-test="amount-input"
        :error-message="getErrorMessage('startAmount')"
        @input="resetAlerts"
      />

      <recess-date-picker
        v-model="budget.startDate"
        :default-value="budget.startDate"
        placeholder="dd-mm-jjjj"
        data-test="start-date-input"
        class="mb-3 col-lg-4 col-md-6 col-12"
        :error-message="$v.budget.startDate.$invalid ? 'Datum van ingang heeft geen geldige waarde' : ''"
        label-text="Geef een datum van ingang op *"
      />

      <recess-date-picker
        v-model="budget.endDate"
        :default-value="budget.endDate"
        placeholder="dd-mm-jjjj"
        data-test="end-date-input"
        class="mb-3 col-lg-4 col-md-6 col-12"
        :error-message="$v.budget.endDate.$invalid ? 'Einddatum moet in de toekomst en na de datum van ingang zijn' : ''"
        label-text="Geef een einddatum op"
      />

      <div class="col-12 mt-3">
        <recess-toggle-input
          v-model="budget.approvalRequired"
          data-test="approval-required-input"
          label-text="Verplichte goedkeuring van leidinggevende bij gebruik van budget"
        />
      </div>

      <div class="col-sm-12 d-flex justify-content-between">
        <recess-button
          :url="{ name: 'my-team' , query: { 'my-team-tab': 'budgets' }}"
          title="Terug"
          variant="tertiary"
          class="mt-4 u-min-width-140"
        />

        <recess-button
          title="Opslaan"
          data-test="submit-btn"
          variant="primary"
          class="mt-4 u-min-width-140"
          @click.native="createBudget"
        />
      </div>
    </div>
    <recess-alert
      v-if="isSuccess"
      class="mt-5"
      type="success"
      text="Het budget is succesvol toegewezen."
    />
    <recess-alert
      v-if="isError"
      class="mt-5"
      type="error"
      text="Oeps, er is iets mis gegaan met het opslaan. Probeer het later opnieuw."
    />
    <recess-divider />
  </recess-layout-static>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import { createBudget } from '../../../api/budgetClient'
import userClient from '../../../api/userClient'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import { validationMessages, isValidDate, isFuture, isLaterThanStartDate } from '../../../modules/validationsHelper'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import DateUtils from '../../../utils/DateUtils'

const matchesWithAPotentialBudgetUser = (value, vm) => {
    return vm.potentialBudgetUsers.some(x => x.emailAddress === value)
}

export default {
    name: 'CreateBudget',
    components: {
        LoaderComponent
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            validationMessages,
            budget: {
                startDate: this.getTodaysDate(),
                endDate: this.getCurrentYearLastDate()
            },
            budgetUserEmail: null,
            isSuccess: false,
            isError: false,
            isCreatingBudget: false,
            potentialBudgetUsers: []
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        isLoading() {
            return this.isCreatingBudget
        }
    },
    validations: {
        budget: {
            name: { required },
            startAmount: { required, decimal, minValue: minValue(0) },
            startDate: { required },
            endDate: { isValidDate, isLaterThanStartDate, isFuture }
        },
        budgetUserEmail: { 
            required,
            matchesWithAPotentialBudgetUser
        }
    },
    methods: {
        resetAlerts() {
            this.isSuccess = false
            this.isError = false
        },
        async createBudget() {
            this.resetAlerts()
            this.$v.$touch()
            if (this.$v.$invalid) return

            try {
                this.isCreatingBudget = true
                
                const budget = {
                    userId: this.getUserIdFromEmail(this.budgetUserEmail),
                    name: this.budget?.name,
                    startAmount: this.budget?.startAmount,
                    approvalRequired: this.budget?.approvalRequired,
                    startDate: DateUtils.parseToISOString(this.budget?.startDate),
                    endDate:DateUtils.parseToISOString(this.budget?.endDate)
                }

                await createBudget(budget)
                    .then(() => {
                        this.isSuccess = true
                    })
            } catch (err) {
                this.isError = true
                throw new Error(err)
            } finally {
                this.isCreatingBudget = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.budget[fieldName]
            const fieldValue = this.budget[fieldName]
            // Check Required
            if (fieldValidation?.$error && !fieldValidation.required) {
                return this.validationMessages.required[fieldName]
            }
            // Check Invalid
            if (fieldValidation?.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        },
        getTodaysDate() {
            return DateUtils.formatDate(DateUtils.now)
        },
        getCurrentYearLastDate() {
            return DateUtils.formatDate(DateUtils.endOfYear)
        },
        getUserIdFromEmail(emailAddress) {
            const user = this.potentialBudgetUsers.find(x => x.emailAddress === emailAddress)
            return user?.userId
        },
        async queryBudgetUser(query) {
            userClient.cancelFetchSearch()
            if (!query) return []

            const filters = [
                {
                    keys: ['firstName', 'middleName', 'lastName', 'emailAddress', 'employeeNumber'],
                    operator: ODataFilterOperations.CONTAINS,
                    value: query,
                    keyCombination: 'or'
                },
                {
                    keys: 'accountId',
                    operator: ODataFilterOperations.EQUALS,
                    value: this.user.accountId
                },
                {
                    keys: 'isEnabled',
                    operator: ODataFilterOperations.EQUALS,
                    value: true
                }
            ]

            const oDataFilters = filters.map(f => {
                return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
            })

            let dataQuery = new ODataQueryBuilder()
                .setSort('firstName', 'asc')
                .setPagination(0, 5)
                .addFilters(oDataFilters)

            dataQuery = dataQuery.build()

            try {
                this.potentialBudgetUsers = (await userClient.searchUsersBasicInfo(dataQuery)).items
                return (this.potentialBudgetUsers ?? []).map(e => ({
                    id: e.userId,
                    email: e.emailAddress,
                    name: `${e.fullName} ${e.employeeNumber ? `- ${e.employeeNumber}` : ''}`
                }))
            } catch (err) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(err)) {
                    console.error(err)
                }
                return []
            }
        }
    }
}
</script>

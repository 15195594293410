export default class DataQuery {
    constructor(sortField, sortDirection, skip, take, filters, expands) {
        this.sortField = sortField
        this.sortDirection = sortDirection
        this.skip = skip || 0
        this.take = take || 10
        this.filters = filters || []
        this.expands = expands || []
    }

    setSort(field, direction) {
        this.sortField = field
        this.sortDirection = direction
    }

    setPagination(skip, take) {
        this.skip = skip
        this.take = take
    }
}

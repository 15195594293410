import { getOplzClient } from './apiClient'

const baseUrl = '/project-request-status'

const get = async () => {
    const client = await getOplzClient()

    return client.get(baseUrl).then(res => res?.data)
}

const getCancellationReasons = async () => {
    const client = await getOplzClient()

    return client.get(`${baseUrl}/cancellation-reasons`)
        .then(res => {
            return res?.data?.map(({ name, description }) => {
                return {
                    value: name,
                    displayText: description
                }
            })
        })
}

export default {
    get,
    getCancellationReasons
}
<template>
  <div
    :class="['u-position-relative', { 'u-min-height-100' : isFetchingCoursePrice }]"
  >
    <transition name="fade">
      <loader-component
        v-if="isFetchingCoursePrice"
        overlay="white"
        color="primary"
      />
    </transition>
    <template v-if="price && showPrice">
      <recess-list class="p-0">
        <!-- List price -->
        <recess-list-row v-if="price.listPrice && (price.discount || price.vat)">
          <span>Prijs:</span>
          <span data-test="list-price">{{ price.listPrice || price.listPrice == 0 ? formatPrice(price.listPrice) : 'Onbekend' }}</span>
        </recess-list-row>
        <!-- Discount -->
        <recess-list-row v-if="price.discount">
          <span>Korting:</span>
          <span data-test="price-discount">{{ price.discount ? formatPrice(price.discount) : null }}</span>
        </recess-list-row>
        <!-- VAT -->
        <recess-list-row v-if="price.vat">
          <span>BTW:</span>
          <span data-test="price-vat">{{ price.vat ? formatPrice(price.vat) : null }}</span>
        </recess-list-row>
        <!-- Total -->
        <recess-list-row class="pb-0">
          <h5 class="mb-0">{{ totalText }}:</h5>
          <h5
            data-test="total-price"
            class="mb-0"
          >
            {{ price.totalPrice || price.totalPrice == 0 ? formatPrice(price.totalPrice) : 'Onbekend' }}
          </h5>
        </recess-list-row>
        <recess-list-row class="pt-0">
          <span
            v-if="price.totalPrice"
            data-test="total-price-text"
            class="font-italic d-flex justify-content-end flex-grow-1"
          >({{ vatText }})</span>
        </recess-list-row>
      </recess-list>
    </template>
    <template v-else-if="price && ( price.totalPrice || price.totalPrice == 0) && !showPrice">
      <recess-list class="p-0">
        <recess-list-row class="pb-0 align-items-baseline">
          <h5 class="mb-0">Totaal:</h5>
          <div class="d-flex">
            <h5 class="d-flex align-items-center pr-3 u-textcolor-grey-medium">
              <del data-test="previous-total-price">
                {{ formatPrice(price.totalPrice) }}
              </del>
            </h5>
            <h5
              data-test="updated-total-price"
              class="mb-0"
            >
              € 0,-
            </h5>
          </div>
        </recess-list-row>
      </recess-list>
    </template>
  </div>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import priceFormatter from '../../../modules/priceFormatter'
import coursePriceHelper from '../../../modules/coursePriceHelper'

export default {
    name: "PriceSummaryComponent",
    components: {
        LoaderComponent
    },
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        ...mapState('coursePageModule', ['price', 'isFetchingCoursePrice', 'course']),
        ...mapGetters('accountModule', ['displayVatInPrices']),
        ...mapGetters('oleProvidersModule', ['showProviderPrice']),
        vatText() {
            return coursePriceHelper.getVatText(this.displayVatInPrices)
        },
        showPrice() {
            return this.showProviderPrice(this.course)
        },
        totalText() {
            // If the listprice is there, we want to show the list price and total price seperately.
            // If the listprice isn't there, we only show the total price, so this total text changes into 'Prijs'
            return this.price.listPrice && (this.price.discount || this.price.vat)
                ? 'Totaal'
                : 'Prijs'
        }
    },

    methods: {
        formatPrice: priceFormatter.formatPrice
    }
}
</script>
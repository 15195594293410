<template>
  <div class="c-background">
    <picture class="c-background__picture">
      <source
        v-if="xSmallImage"
        :media="mediaQueries.xSmall"
        :srcset="xSmallImage"
      />
      <source
        v-if="smallImage"
        :media="mediaQueries.small"
        :srcset="smallImage"
      />
      <source
        v-if="mediumImage"
        :media="mediaQueries.medium"
        :srcset="mediumImage"
      />
      <source
        v-if="largeImage"
        :media="mediaQueries.large"
        :srcset="largeImage"
      />
      <source
        v-if="xLargeImage"
        :media="mediaQueries.xLarge"
        :srcset="xLargeImage"
      />
      <img
        :src="imageFallback"
        :class="imageAlignmentClass"
        alt
      />
    </picture>
  </div>
</template>

<script>
import { cssMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'

export default {
    mixins: [cssMediaQueriesMixin],
    props: {
        imageAlignment: {
            type: String,
            default: 'centered',
            validator(value) {
                const allowedAlignments = ['left', 'right', 'centered']
                return allowedAlignments.indexOf(value) > -1
            }
        },
        xSmallImage: {
            type: String,
            default: null
        },
        smallImage: {
            type: String,
            default: null
        },
        mediumImage: {
            type: String,
            default: null
        },
        largeImage: {
            type: String,
            default: null
        },
        xLargeImage: {
            type: String,
            default: null
        }
    },
    computed: {
        imageAlignmentClass() {
            const imageAlignment = this.imageAlignment.toLowerCase()
            return `c-background__image-${imageAlignment}`
        },
        imageFallback() {
            return (
                this.xLargeImage ||
                this.largeImage ||
                this.mediumImage ||
                this.smallImage ||
                this.xSmallImage ||
                ''
            )
        }
    }
}
</script>
